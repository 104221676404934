import React, { Fragment } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { RippleButton } from '../../../../../components';
import { PopUp, PopUpContent } from '../../../../../components/popup/common';
import { Overlay } from '../../../../../containers/OverlayContainer';
import { changeOrderStatus } from '../../../../../redux/ducks/applications/store-front/actions/store';
import { colors } from '../../../../../styles';
import { toTitleCase } from '../../../../../utils/toTitleCase';
import { Text } from '../../storeFront';

import { ButtonWrapper } from './orderDetails';

const ContentWrapper = styled.div`
    padding: 24px 16px 24px 17px;
`;

const TextSpan = styled.span`
    font-size: 14px;
    font-weight: 700;
    color: ${colors.themeTxtColor10};
`;

const StatusPopup = ({ open, setOpen, order, store, status, cancel }) => {
    const dispatch = useDispatch();

    const handleCancel = (storeId, orderId, status) => {
        dispatch(changeOrderStatus(storeId, orderId, status));
        setOpen();
    };

    const handleDeliver = (storeId, orderId, status) => {
        dispatch(changeOrderStatus(storeId, orderId, status));
        setOpen();
    };

    return (
        <Fragment>
            <Overlay bgc="rgba(0, 0, 0, 0.4)" zIndex={'100000'}>
                <PopUp padding="0" open={open} zIndex={'10000'}>
                    <PopUpContent radius="8px 8px 0px 0px">
                        <ContentWrapper>
                            <Text
                                align={'left'}
                                size={'20px'}
                                weight={'700'}
                                color={colors.themeTxtColor10}
                                bottom={'16px'}
                            >
                                {cancel ? 'Cancel Order' : 'Mark as delievered'}
                            </Text>
                            {cancel ? (
                                <Text align={'left'} size={'14px'} weight={'400'} color={colors.themeTxtColor10}>
                                    Are you sure you want to cancel order from
                                    <TextSpan>{` ${toTitleCase(order.fullName)}?`}</TextSpan>
                                </Text>
                            ) : (
                                <Text align={'left'} size={'14px'} weight={'400'} color={colors.themeTxtColor10}>
                                    You are agreeing that you’ve delivered
                                    <TextSpan>{` ${toTitleCase(order.fullName)}'s orders?`}</TextSpan>
                                </Text>
                            )}
                            <ButtonWrapper top={'24px'}>
                                <RippleButton
                                    onClick={() => setOpen()}
                                    top={'0'}
                                    right={'8px'}
                                    height={'45px'}
                                    backgroundColor={colors.white}
                                    border={'1px solid #CBD6E0'}
                                    color={colors.themeTxtColor10}
                                >
                                    Cancel
                                </RippleButton>
                                <RippleButton
                                    top={'0'}
                                    height={'45px'}
                                    onClick={() =>
                                        cancel
                                            ? handleCancel(store.id, order.id, status.cancel)
                                            : handleDeliver(store.id, order.id, status.delivered)
                                    }
                                >
                                    Confirm
                                </RippleButton>
                            </ButtonWrapper>
                        </ContentWrapper>
                    </PopUpContent>
                </PopUp>
            </Overlay>
        </Fragment>
    );
};

export default StatusPopup;
