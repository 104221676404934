import React, { Fragment } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TopHeader } from '../../../../../components';
import { Close } from '../../../../../containers/HeaderContainer';
import { List } from '../../../../../containers/ListContainer';
import { SlidingOverlay } from '../../../../../containers/OverlayContainer';
import { ScreenContainer, FlexContainer } from '../../../../../containers/ScreenContainer';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import { Space, Divider, Text, RoundDetailsSection } from '../../styles';
import { sortCartItems } from '../../utils/sortCartItems';
import { ReactComponent as GroupBuyIcon } from '../assets/merch_buy.svg';
//import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
    padding: 0 16px;
`;

const ProductDetailsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 10px 16px;
    align-items: center;
`;

const CommissionBreakdown = ({ open, setOpen }) => {
    const cartItems = useSelector((state) => state.applications.merchbuy.shopCart);

    const calculateCommission = (item) => {
        const total = (item.cost / item.quantity) * (item?.merchBuyCommissions.merchBuyAgentCommission || 0);
        return total;
    };

    const calculateTotalCommission = (input) => {
        const total = [...input].reduce(
            (accum, current) =>
                Number(accum) +
                Number(current.cost || 0) * Number(current.merchBuyCommissions?.merchBuyAgentCommission || 0),
            0,
        );
        return total;
    };

    return (
        open && (
            <SlidingOverlay>
                <Fragment>
                    <TopHeader title={'Commission Breakdown'} noArrow={true} withSpacesHeader>
                        <Close
                            left={'true'}
                            onClick={() => {
                                setOpen(!open);
                            }}
                        />
                    </TopHeader>
                    <ScreenContainer top="120px" padding={'1em 1em 55px'}>
                        <Text color="#56636d" fontWeight="400" font="14px">
                            This breakdown shows the commissions you’ve made for products that have reached the
                            delivered status.
                        </Text>
                        <List fullScreen>
                            <Space height={'16px'} />
                            <Wrapper>
                                {cartItems &&
                                    cartItems.length > 0 &&
                                    sortCartItems(cartItems).map((shop, i) => {
                                        return (
                                            <Fragment key={i}>
                                                <Text font="10px" padding="0 0 8px 0" fontWeight="500" color="#253246">
                                                    {shop.shopName}
                                                </Text>
                                                {shop.items &&
                                                    shop.items.map((item, j) => {
                                                        return (
                                                            <RoundDetailsSection key={j} margin="0 0 16px 0">
                                                                <Fragment>
                                                                    <ProductDetailsWrapper>
                                                                        <Text
                                                                            font="12px"
                                                                            fontWeight="400"
                                                                            width="256px"
                                                                            color="#212c3d"
                                                                        >
                                                                            {item.product.name}
                                                                        </Text>
                                                                        {item.groupBuyId && <GroupBuyIcon />}
                                                                    </ProductDetailsWrapper>
                                                                    <ProductDetailsWrapper>
                                                                        <Text
                                                                            font="12px"
                                                                            fontWeight="400"
                                                                            color="#90979c"
                                                                        >
                                                                            Quantity:{' '}
                                                                            <span
                                                                                style={{
                                                                                    fontSize: '500',
                                                                                    color: '#636363',
                                                                                }}
                                                                            >{`${item.quantity}`}</span>
                                                                        </Text>
                                                                        <Text
                                                                            font="12px"
                                                                            fontWeight="500"
                                                                            color="#636363"
                                                                        >
                                                                            {formatPrice(item.cost || 0)}
                                                                        </Text>
                                                                    </ProductDetailsWrapper>
                                                                    <Divider top={'0px'} bottom={'0px'} />
                                                                    <ProductDetailsWrapper>
                                                                        <Text
                                                                            font="12px"
                                                                            fontWeight="400"
                                                                            color="#90979c"
                                                                        >
                                                                            {' '}
                                                                            Commission:{' '}
                                                                        </Text>
                                                                        <Text
                                                                            font="12px"
                                                                            fontWeight="500"
                                                                            color="#636363"
                                                                        >
                                                                            {`${formatPrice(
                                                                                calculateCommission(item),
                                                                            )} per unit`}
                                                                        </Text>
                                                                    </ProductDetailsWrapper>
                                                                    <Divider top={'0px'} bottom={'0px'} />
                                                                    <ProductDetailsWrapper>
                                                                        <Text
                                                                            font="12px"
                                                                            fontWeight="400"
                                                                            color="#90979c"
                                                                        >
                                                                            {' '}
                                                                            What you make:
                                                                        </Text>
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                alignItems: 'flex-end',
                                                                            }}
                                                                        >
                                                                            <Text
                                                                                font="10px"
                                                                                fontWeight="400"
                                                                                color="#90979c"
                                                                            >
                                                                                {`${formatPrice(
                                                                                    calculateCommission(item),
                                                                                )} x ${item.quantity}`}
                                                                            </Text>
                                                                            <Text
                                                                                style={{ marginTop: '4px' }}
                                                                                font="12px"
                                                                                fontWeight="500"
                                                                                color="#4e9700"
                                                                            >
                                                                                {formatPrice(
                                                                                    Number(item.cost || 0) *
                                                                                        Number(
                                                                                            item.merchBuyCommissions
                                                                                                ?.merchBuyAgentCommission ||
                                                                                                0,
                                                                                        ),
                                                                                )}
                                                                            </Text>
                                                                        </div>
                                                                    </ProductDetailsWrapper>
                                                                </Fragment>
                                                            </RoundDetailsSection>
                                                        );
                                                    })}
                                            </Fragment>
                                        );
                                    })}
                            </Wrapper>
                        </List>
                        <FlexContainer top={'10px'}>
                            <Text color="#212c3d" fontWeight="500" font="12px">
                                TOTAL COMMISSION
                            </Text>
                            <Text color="#212c3d" fontWeight="500" font="14px">
                                {formatPrice(calculateTotalCommission(cartItems))}
                            </Text>
                        </FlexContainer>
                        <Divider top={'16px'} bottom={'16px'} />
                    </ScreenContainer>
                </Fragment>
            </SlidingOverlay>
        )
    );
};

export default CommissionBreakdown;
