import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from '../../../styles';
import { ReactComponent as EmailIcon } from '../assets/email-contact.svg';
import { ReactComponent as FaceBookIcon } from '../assets/facebook-contact.svg';
import { ReactComponent as InstagramIcon } from '../assets/instagram-contact.svg';
import { ReactComponent as PhoneIcon } from '../assets/phone-contact.svg';
import SabiLogo from '../assets/Sabi.svg';
import { ReactComponent as TwitterIcon } from '../assets/twitter-contact.svg';
import { ReactComponent as WhatsAppIcon } from '../assets/whatsapp-contact.svg';

const StyledContainer = styled.div`
    display: block;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 425px) {
        max-width: 100%;
    }
    @media (min-width: 576px) {
        max-width: 540px;
    }
    @media (min-width: 768px) {
        max-width: 720px;
    }
    @media (min-width: 992px) {
        max-width: 960px;
    }
    @media (min-width: 1200px) {
        max-width: 1140px;
    }
    @media (min-width: 1366px) {
        max-width: 1248px;
    }
`;
const Row = styled.div`
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
`;
const StyledTopFooter = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${colors.white};
    font-size: 20px;
    font-weight: 400;
    color: ${colors.boldDark};
    width: 100%;
    padding: 24px 0;
    @media (max-width: 992px) {
        flex-direction: column;
    }
    @media (max-width: 768px) {
        padding: 24px 25px;
        text-align: left;
    }
`;
const FooterLogoWrap = styled.div`
    display: flex;
    justify-content: flex-start;
    white-space: nowrap;
    height: 34px;
    @media (max-width: 992px) {
        width: 100%;
        margin-bottom: 30px;
    }
    @media (max-width: 768px) {
        height: 24px;
    }
`;
const StyleLogo = styled.img`
    height: 100%;
    z-index: 1;
`;
const FooterContactWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;

    @media (max-width: 992px) {
        width: 100%;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
    }
`;
const FooterContactLink = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
    }
`;
const ContactLabelLink = styled.a`
    display: flex;
    align-items: center;
    color: ${colors.boldDark};
    cursor: pointer;
    outline: 0 none;
    margin-left: 20px;
    margin-right: 20px;
    text-decoration: none;

    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        color: ${colors.deepBlue};
    }
    @media (max-width: 992px) {
        margin-left: 10px;
        margin-right: 10px;
    }
    @media (max-width: 768px) {
        display: flex;
        margin: 0;
        margin-bottom: 24px;

        &:first-child {
            margin-right: 0px;
        }
    }
`;

const LinkIcon = styled.div`
    display: flex;
    align-items: ${({ items }) => items || null};
    margin-right: ${({ right }) => right || '10px'};
    height: ${({ height }) => height || '18px'};
    width: ${({ width }) => width || '18px'};
    @media (max-width: 768px) {
        display: flex;
        margin-right: ${({ mright }) => mright || null};
    }
`;
const LinkText = styled.p`
    font-size: 17px;
    font-weight: 400;
    color: ${colors.boldDark};
    margin: 0;
    @media (max-width: 992px) {
        font-size: 15px;
    }
    @media (max-width: 768px) {
        display: flex;
        margin: 0;
    }
`;
const FooterSocialLink = styled.div`
    display: flex;
    align-items: center;
    margin-left: 60px;
    @media (max-width: 992px) {
        margin-left: 25px;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin: 0;
    }
`;

const SocialLabelLink = styled.a`
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: 0 none;
    text-decoration: none;
`;

const StyledFooter = styled.footer`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    background: ${colors.white};
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 60px;
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    @media (max-width: 768px) {
        padding: 5px 25px 15px;
        text-align: center;
    }
`;
const FooterHr = styled.div`
    position: relative;
    display flex;
    width: 100%;
    padding-top: 24px;
    border-top: 1px solid ${colors.lightGray};
`;
const FooterColumnText = styled.div`
    position: relative;
    display: flex;
    max-width: 100%;
    font-size: 14px;
    line-height: 24px;
    order: 1;
    @media (max-width: 768px) {
        flex: 0 0 100%;
        width: 100%;
        right: 0;
        text-align: left;
        order: 2;
    }
`;
const FooterColumnLink = styled.div`
    position: relative;
    display: flex;
    max-width: 100%;
    font-size: 14px;
    justify-content: flex-end;
    line-height: 24px;
    order: 2;
    @media (max-width: 768px) {
        display: block;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        width: 100%;
        left: 0;
        text-align: left;
        order: 1;
    }
`;
const Copyright = styled.p`
    color: ${colors.boldDark};
    margin: 0;

    @media (max-width: 768px) {
        color: ${colors.lightDark};
    }
    @media (max-width: 576px) {
        text-align: left;
        padding-bottom: 10px;
    }
`;
const FooterLink = styled(Link)`
    color: ${colors.boldDark};
    cursor: pointer;
    outline: 0 none;
    margin-left: 31px;
    margin-right: 31px;

    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        color: ${colors.deepBlue};
    }
    @media (max-width: 768px) {
        display: inline-block;
        color: ${colors.boldDark};
        margin: 0;
        margin-bottom: 15px;

        &:first-child {
            margin-right: 28px;
        }
    }
`;

export const Footer = () => {
    return (
        <StyledContainer>
            <Row>
                <StyledTopFooter>
                    <FooterLogoWrap>
                        <StyleLogo src={SabiLogo} alt="Sabi Logo" />
                    </FooterLogoWrap>
                    <FooterContactWrap>
                        <FooterContactLink>
                            <ContactLabelLink
                                href="https://wa.me/2349073015987?text=Good%20day%20Sabi,"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <LinkIcon items="flex-end">
                                    <WhatsAppIcon />
                                </LinkIcon>
                                <LinkText>+234 907 301 5987</LinkText>
                            </ContactLabelLink>
                            <ContactLabelLink href="tel:+234018889269" rel="noopener noreferrer">
                                <LinkIcon items="flex-end">
                                    <PhoneIcon />
                                </LinkIcon>
                                <LinkText>+234 01 888 92 69</LinkText>
                            </ContactLabelLink>
                            <ContactLabelLink
                                href="mailto:usersupport@sabi.am"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <LinkIcon items="flex-end">
                                    <EmailIcon />
                                </LinkIcon>
                                <LinkText>usersupport@sabi.am</LinkText>
                            </ContactLabelLink>
                        </FooterContactLink>
                        <FooterSocialLink>
                            <SocialLabelLink
                                href="https://www.facebook.com/Sabi-102086332239689"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <LinkIcon height="24px" right="24px" mright="30px">
                                    <FaceBookIcon />
                                </LinkIcon>
                            </SocialLabelLink>

                            <SocialLabelLink
                                href="https://twitter.com/sabiam_africa"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <LinkIcon right="24px" mright="30px">
                                    <TwitterIcon />
                                </LinkIcon>
                            </SocialLabelLink>

                            <SocialLabelLink
                                href="https://www.instagram.com/sabiam_africa/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <LinkIcon height="24px">
                                    <InstagramIcon />
                                </LinkIcon>
                            </SocialLabelLink>
                        </FooterSocialLink>
                    </FooterContactWrap>
                </StyledTopFooter>
                <StyledFooter>
                    <FooterHr />
                    <FooterColumnLink>
                        <FooterLink to="/privacyPolicy">Privacy Policy</FooterLink>
                        <FooterLink to="/termsAndConditions">Terms and Conditions</FooterLink>
                    </FooterColumnLink>
                    <FooterColumnText>
                        <Copyright>© 2022 Sabi Inc. All rights reserved.</Copyright>
                    </FooterColumnText>
                </StyledFooter>
            </Row>
        </StyledContainer>
    );
};
