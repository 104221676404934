import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from '../assets/arrow.svg';
import { colors } from '../styles';

export const MenuOptions = styled.ul`
    display: flex;
    flex-direction: column;
    border-top: ${({ withTitle }) => withTitle && `1px solid ${colors.border.top}`};
    margin-left: 15px;
    margin-right: 15px;
    background-color: ${({ bgColor }) => bgColor};
    margin-bottom: ${({ bottom }) => bottom};
`;

export const MenuOptionLogo = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-image: url(${({ icon }) => icon});
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 13px;
`;

export const OptionName = styled.h4`
    font-weight: normal;
    color: ${colors.themeTextColor3};
    font-size: 14px;
    margin: auto 16px;
`;

export const MenuOption = styled.li`
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background-color: white;

    &:not(:last-of-type) {
        margin: ${({ margin }) => margin};
        border-top: 1px solid ${colors.border.top};
    }

    &:not(:first-of-type) {
        border-top: 1px solid ${colors.border.top};
    }

    &:last-of-type {
        border-bottom: 1px solid ${colors.border.bottom};
    }

    & > a,
    & > div {
        display: flex;
        flex-direction: row;
        position: static;

        &:focus::after {
            display: block;
        }

        &,
        &:hover {
            color: unset;
            transition: unset;
            text-decoration: none;
        }
    }
`;

export const ArrowForward = styled(ArrowIcon)`
    position: absolute;
    top: ${({ top }) => top || '22px'};
    right: 22px;
`;
