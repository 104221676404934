import React, { Fragment } from 'react';

import { RippleButton, PageLogo } from '../../../../../components';
import { Title, SubTitle } from '../../../../../containers/MessageContainer';
import { FlexCenteredBlock, ScreenContainer } from '../../../../../containers/ScreenContainer';
import { colors } from '../../../../../styles';
import History from '../../../../../utils/History';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';

import background from './assets/background.svg';
import AddedToCartIcon from './assets/success.svg';

const VerificationInitiated = () => {
    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <ScreenContainer
                    style={{
                        minHeight: '100vh',
                        background: ` no-repeat center/cover url(${background}), rgba(34, 126, 255, .9)`,
                    }}
                    top="0"
                >
                    <FlexCenteredBlock top={'50px'} mtop="50px">
                        <PageLogo
                            width={'140px'}
                            height={'140px'}
                            iconWidth={'95px'}
                            iconHeight={'95px'}
                            background={colors.bgBlue}
                            Icon={AddedToCartIcon}
                            top="50px"
                        />
                        <Title size="24px" color={colors.white} weight="700" lineHeight="36px" top="23px" bottom="9px">
                            Verification Initiated
                        </Title>
                        <SubTitle
                            color={colors.aliceBlue}
                            weight="400"
                            lineHeight="21px"
                            textAlign="center"
                            font="14px"
                        >
                            Your store verification is currently in progress, and should take 1 - 2 working days.
                        </SubTitle>

                        <RippleButton
                            top={'180px'}
                            type="button"
                            height="56px"
                            backgroundColor={colors.white}
                            color={colors.deepBlue}
                            onClick={() => History.push('/user/shop_settings')}
                        >
                            Back to Settings
                        </RippleButton>
                    </FlexCenteredBlock>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default VerificationInitiated;
