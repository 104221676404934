export const SHOP_UPDATE_SALE = 'Update Sale';
export const SHOP_DELETE_SALE = 'Delete Sale';
export const SHOP_UPDATE_PRODUCT = 'Update Product';
export const SHOP_DELETE_PRODUCT = 'Delete Product';
export const SHOP_ADD_CUSTOMER = 'Add Customer';
export const SHOP_DELETE_CUSTOMER = 'Delete Customer';
export const SETTINGS_PERSONAL_DETAILS = 'Update Personal Details';
export const SETTINGS_KYC = 'Update Kyc';
export const SETTINGS_BANK_DETAILS = 'Update Bank Details';
export const SETTINGS_UPDATE_PIN = 'Update Pin';

export const APP_LAUNCH = 'App Launch';

export const AUTH_ENTER_PHONE_NUMBER = 'Auth Enter Phone Number';
export const AUTH_ENTER_VALID_OTP = 'Auth Enter Valid Otp';
export const AUTH_PROVIDE_ACCOUNT_INFO = 'Auth Provide Account Info';
export const AUTH_PROVIDE_BUSINESS = 'Auth Provide Business';
export const AUTH_PASSWORD_SETUP = 'Auth Password Setup';
export const AUTH_USER_LOGIN = 'Auth User Login';
export const AUTH_LOGOUT = 'Auth Logout';

export const SETTINGS_MERCHANT_UPGRADE = 'Settings Merchant Upgrade';
export const SETTINGS_MERCHANT_UPGRADE_COMPLETE = 'Settings Merchant Upgrade Complete';

export const MODULE_INTERACTION = 'Module Interaction';

export const SHOP_CREATE_SHOP = 'Shop Create Shop';
export const SHOP_UPDATE_SHOP = 'Shop Update Shop';

export const SHOP_START_CREATE_PRODUCT = 'Shop Start Create Product';
export const SHOP_CREATE_PRODUCT = 'Shop Create Product';
export const SHOP_UPDATE_PRODUCT_QUANTITY = 'Shop Update Product Quantity';

export const MY_SHOP_HOME_INTERACTION = 'My Shop Home Interaction';
export const MERCHBUY_INTERACTION = 'Merchbuy Interaction';

export const SHOP_START_CREATE_SALE = 'Shop Start Create Sale';
export const SHOP_SALE_CREATE = 'Shop Create Sale';
export const SHARE_SALES_RECEIPT = 'Share Sales Receipt';

export const SHOP_START_FULFILLING_ORDER = 'Shop Start Fulfilling order';
export const SHOP_COMPLETE_SELF_FULFILMENT = 'Shop Complete Self Fulfilment';

export const CHECKOUT_WITH_WALLET = 'Process payment';
export const CHECKOUT_WITH_WALLET_SUCCESS = 'Successful checkout';

export const START_UPDATING_SHOP_PROFILE = 'Start Updating Shop Profile';
export const COMPLETE_UPDATING_SHOP_PROFILE = 'Complete Updating Shop Profile';

export const START_SEARCH_EDUCATION = 'Start Search Education';
export const FINISH_SEARCH_EDUCATION = 'Finish Search Education';

export const INITIATE_SEARCH = 'Initiate Search';
export const COMPLETE_SEARCH = 'Complete Search';

export const INITIATE_ADD_TO_CART = 'Initiate add to cart';
export const COMPLETE_ADD_TO_CART = 'Complete add to cart';
export const CART_OPEN = 'Cart open';

export const START_CHECKOUT = 'Start checkout';
export const PROCESS_PAYMENT = 'Process payment';
export const SUCCESSFUL_CHECKOUT = 'Successful checkout';

export const PRODUCT_DETAILS = 'Product details';
export const PRODUCT_DETAILS_PAGE = 'Product details page';
export const PRODUCT_DESCRIPTION_POPUP = 'Product description popup';
export const CATEGORY_DETAILS = 'Product category details';
export const CATEGORY_LIST = 'Product categories list';

export const NOTIFY_OUT_OF_STOCK = 'Notify for back in stock';
export const VIEW_SAVED_ITEMS = 'View saved Items';

export const NOTIFICATION_TRAY = 'Notification tray';

export const SALES_REPORT = 'Sales report';
export const PROFIT_REPORT = 'Profit report';
export const INVENTORY_REPORT = 'Inventory report';

export const SELECT_ACCOUNT_TYPE = 'Select account type';

export const CREATE_PAYMENT_LINK = 'Create payment link';
export const VIEW_REMITTANCE_HISTORY = 'Views remittance history';
export const REMITTANCE_SINGLE_ORDER_START = 'Remittance for single order start';
export const REMITTANCE_SINGLE_ORDER_FINISH = 'Remittance for single order finish';
export const REMITTANCE_MULTIPLE_ORDER_START = 'Remittance for multiple orders start';
export const REMITTANCE_MULTIPLE_ORDER_FINISH = 'Remittance for multiple order finish';

export const ORDER_HISTORY_TRACKING = 'Order history tracking';
export const MERCHBUY_LANDING_PAGE = 'Merchbuy landing page';
export const MYSHOP_LANDING_PAGE = 'Myshop landing page';
export const WALLET_LANDING_PAGE = 'Wallet landing page';
export const MORE_SECTION = 'More section';

export const INITIATE_INSTANT_PURCHASE = 'Initiate instant purchase';
export const COMPLETE_INSTANT_PURCHASE = 'Complete instant purchase';
export const REORDER_START = 'Reorder start';
export const REORDER_COMPLETE = 'Reorder complete';
export const LOG_COMPLAINT_START = 'Log complaint start';
export const LOG_COMPLAINT_COMPLETE = 'Log complaint complete';
export const ORDER_DETAILS = 'Order details';

export const INITIATE_BANK_TRANSFER = 'Initiate bank transfer';
export const COMPLETE_BANK_TRANSFER = 'Complete bank transfer';
