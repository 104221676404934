export const sortCartItems = (items) => {
    const cartItems = items ? [...items] : [];
    let sortedItems = [];
    let noShop = {
        shopName: 'No Shop',
        items: [],
    };

    for (let i = 0; i < cartItems.length; i++) {
        if (cartItems[i].shopData !== undefined) {
            const current = [...sortedItems].find((x) => x.shopName === cartItems[i].shopData.shopName);
            const index = [...sortedItems].findIndex((x) => x.shopName === cartItems[i].shopData.shopName);
            if (current) {
                current.items.push({
                    cost: cartItems[i].cost,
                    quantity: cartItems[i].quantity,
                    product: cartItems[i].product,
                    groupBuyId: cartItems[i].groupBuyId,
                    merchBuyCommissions: cartItems[i].merchBuyCommissions,
                });
                sortedItems[index] = current;
            } else {
                const init = cartItems[i].shopData;
                init.items = [];
                init.items.push({
                    cost: cartItems[i].cost,
                    quantity: cartItems[i].quantity,
                    product: cartItems[i].product,
                    groupBuyId: cartItems[i].groupBuyId,
                    merchBuyCommissions: cartItems[i].merchBuyCommissions,
                });
                sortedItems.push(init);
            }
        } else {
            noShop.items.push({
                cost: cartItems[i].cost,
                quantity: cartItems[i].quantity,
                product: cartItems[i].product,
                groupBuyId: cartItems[i].groupBuyId,
                merchBuyCommissions: cartItems[i].merchBuyCommissions,
            });
        }
    }
    if (noShop.items.length) sortedItems.push(noShop);

    return sortedItems;
};

const formatText = (arr) => {
    // const joinedText = new Intl.ListFormat().format(arr).trim();
    let joinedText = '';
    for (let i = 0; i <= arr.length - 1; i++) {
        joinedText = `${joinedText}${i !== 0 && arr.length > 2 ? ',' : ''}${
            i === arr.length - 1 && arr.length > 1 ? ' and' : ''
        } ${arr[i]}`;
    }
    joinedText = joinedText.trim();
    return `${joinedText.charAt(0).toUpperCase()}${joinedText.slice(1)}`;
};

export const getProductDetails = (arr) => {
    const merchbuyItems = {
        quantity: 0,
        cost: 0,
        productsName: [],
    };
    const items = {
        quantity: 0,
        cost: 0,
        productsName: [],
    };
    let productsName = [];
    arr.forEach((x) => {
        const current = x.product.name.toLowerCase();
        if (x.groupBuyId) {
            merchbuyItems.quantity += x.quantity;
            merchbuyItems.cost += x.cost;
            merchbuyItems.productsName.push(current);
            productsName.push(current);
        } else {
            items.quantity += x.quantity;
            items.cost += x.cost;
            items.productsName.push(current);
            productsName.push(current);
        }
    });
    merchbuyItems.productsName = formatText(merchbuyItems.productsName);
    items.productsName = formatText(items.productsName);
    productsName = formatText(productsName);
    return { productsName, merchbuyItems, items };
};
