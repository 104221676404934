import * as Yup from 'yup';

import { containsLettersRegExp } from '../../../../utils/regexp/containsLettersRegExp';
import { phoneRegExp } from '../../../../utils/regexp/phoneRegExp';

export const ValidationSchema = Yup.object().shape({
    phone: Yup.string()
        .matches(containsLettersRegExp, 'Cannot contain letters')
        .when('fullPay', {
            is: false,
            then: Yup.string().matches(phoneRegExp, 'Phone Number is not valid').required('Required'),
            otherwise: Yup.string().when(['customerName', 'customerPhoneNumber'], {
                is: (customerName, customerPhoneNumber) => customerName && !customerPhoneNumber,
                then: Yup.string().required('Customer phone is required'),
            }),
        })
        .test('case 1', 'Phone number is not valid!', (value) => {
            if (value && value.trim().startsWith('0') && value.trim().length !== 11) {
                return false;
            } else return true;
        })
        .test('case 2', 'Phone number is not valid!', (value) => {
            if (
                value &&
                !value.trim().startsWith('234') &&
                !value.trim().startsWith('0') &&
                value.trim().length !== 10
            ) {
                return false;
            } else return true;
        })
        .test('case 3', 'Phone number is not valid!', (value) => {
            if (value && value.trim().startsWith('234') && value.trim().length !== 13) {
                return false;
            } else return true;
        }),
    fullName: Yup.string().required('Full name is a required field'),
    state: Yup.string().required('State is a required field'),
    city: Yup.string().required('City is a required field'),
    address: Yup.string().required('Address is a required field'),
});
