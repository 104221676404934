import React, { Fragment, useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { NewTextAreaWithLabel, SelectBox, RippleButton, SuccessPage, TopHeader } from '../../../../components';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { getSecurityQuestions, sendSecurityAnswer } from '../../../../redux/ducks/account/wallet/actions';
import { colors } from '../../../../styles';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';

import { questionValidationSchema } from './questionValidationSchema';

const FloatingBottomSection = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0px;
    padding: 0px 16px 40px 16px;
    background-color: ${colors.switch.noob};
`;

const SecurityHeader = styled.div`
    font-size: 16px;
    font-weight: 700;
    align-items: 'inherit';
    text-align: 'inherit';
    color: ${colors.themeTxtColor10};
    margin-top: -20px;
`;
const SubText = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: ${colors.themeTextColor11};
    align-items: 'inherit';
    text-align: 'inherit';
    padding: 6px 0px 24px 0px;
`;
const Container = styled.div`
    padding-top: 16px;
    padding-bottom: 91px;
`;
const CallButtonSection = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 100px;
`;

const NewSecurityQuestion = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isLoading = useSelector((state) => state.account.wallet.isLoading);
    const [isSelected, setIsSelected] = useState(false);
    const [questionId, setQuestionId] = useState({});
    const [showSuccessPage, setShowSucessPage] = useState(false);
    const [questionStatus, setQuestionStatus] = useState('');
    const [securityQuestions, setSecurityQuestions] = useState({});

    useEffect(() => {
        dispatch(getSecurityQuestions()).then((res) => {
            res && setSecurityQuestions(res);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitForm = async (values) => {
        const isQuestionCreated = await dispatch(sendSecurityAnswer(questionId, values.answerDetails));
        setShowSucessPage(true);
        setQuestionStatus(isQuestionCreated);
    };

    const onSuccessDoneClick = () => {
        if (questionStatus) {
            history.push('/user/wallet_index');
        } else {
            history.push('/user/wallet_index');
        }
    };

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Add Security Question'} />
                <ScreenContainer padding="45px 16px 0 16px" top="64px">
                    <Formik
                        initialValues={{
                            securityQuestion: '',
                            answerDetails: '',
                        }}
                        validationSchema={questionValidationSchema}
                        onSubmit={onSubmitForm}
                    >
                        {({ values, valid, errors, touched, setFieldValue, initialValues }) => (
                            <Form>
                                <SecurityHeader>Security Question</SecurityHeader>
                                <SubText>
                                    This helps to identify you when you're trying to regain access to your wallet if
                                    blocked.
                                </SubText>
                                <SelectBox
                                    placeholder={'Select a Security Question'}
                                    value={values.securityQuestion}
                                    padding="16px"
                                    showSearchBar={false}
                                    showCancelIcon={true}
                                    left={'-10px'}
                                    buttonText={' '}
                                    height="90%"
                                    title="Select a Security Question"
                                    alignItems={'inherit'}
                                    textAlign={'inherit'}
                                    paddingLeft={'100px'}
                                    isSelected={isSelected}
                                    setIsSelected={setIsSelected}
                                    options={securityQuestions}
                                    handleChange={(value) => {
                                        setFieldValue('securityQuestion', value.question);
                                        setQuestionId(value.id);
                                    }}
                                    valid={`${!touched.securityQuestion && !errors.securityQuestion}`}
                                    errors={touched && touched.securityQuestion && errors && errors.securityQuestion}
                                />
                                <Container>
                                    <NewTextAreaWithLabel
                                        color={colors.themeTxtColor10}
                                        weight={'500'}
                                        size={'14px'}
                                        label={'Answer to Selected Security Question'}
                                        name={'answerDetails'}
                                        placeholder={'Answer to Selected Security Question'}
                                        height={'53px'}
                                        padding={'16px 16px'}
                                    />
                                </Container>
                                <FloatingBottomSection>
                                    <RippleButton
                                        type="submit"
                                        top={'2px'}
                                        backgroundColor={colors.deepBlue}
                                        disabled={isLoading && !values.answerDetails}
                                    >
                                        Continue
                                    </RippleButton>
                                </FloatingBottomSection>
                            </Form>
                        )}
                    </Formik>
                </ScreenContainer>
                {showSuccessPage && (
                    <SuccessPage
                        title={`${questionStatus ? 'Update Was Successful' : 'Update Was Not Successful'}`}
                        subtitle={`${
                            questionStatus
                                ? 'Security Question has been added to your wallet profile'
                                : 'We are currently unable to update your wallet profile. Please try again or reach out to our customer support'
                        }`}
                        doneText={questionStatus ? 'Got it' : 'Try Again'}
                        successfull={questionStatus}
                        onDoneClick={onSuccessDoneClick}
                    >
                        {!questionStatus && (
                            <CallButtonSection>
                                <a href="tel:01 888 5070">
                                    <RippleButton backgroundColor="transparent">Reach out to support</RippleButton>
                                </a>
                            </CallButtonSection>
                        )}
                    </SuccessPage>
                )}
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default NewSecurityQuestion;
