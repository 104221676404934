import CopyReferralBadge from './copy-referral-badge';
import ErrorBadge from './error-badge';
import InfoBadge from './info-badge';
import MerchantsBadge from './merchants-badge';
import ReferralBadge from './referral-badge';
import SuccessBadge from './success-badge';
import WalletBadge from './wallet-badge';
import WalletBadge2 from './wallet-badge2';

export {
    WalletBadge,
    ReferralBadge,
    MerchantsBadge,
    CopyReferralBadge,
    WalletBadge2,
    ErrorBadge,
    SuccessBadge,
    InfoBadge,
};
