import GhanaFlag from '../../../assets/flags/ghana.svg';

export const GhanaInfo = {
    name: 'Ghana',
    code: '+233',
    flag: GhanaFlag,
    currency: {
        sign: 'GH₵',
        code: 'GHS',
    },
};
