export const localAreas = [
    {
        state: 'Abia',
        alias: 'abia',
        lgas: [
            'Aba North',
            'Aba South',
            'Arochukwu',
            'Bende',
            'Ikwuano',
            'Isiala Ngwa North',
            'Isiala Ngwa South',
            'Isuikwuato',
            'Obi Ngwa',
            'Ohafia',
            'Osisioma',
            'Ugwunagbo',
            'Ukwa East',
            'Ukwa West',
            'Umu Nneochi',
            'Umuahia North',
            'Umuahia South',
        ],
    },
    {
        state: 'Adamawa',
        alias: 'adamawa',
        lgas: [
            'Demsa',
            'Fufure',
            'Ganye',
            'Gayuk',
            'Gombi',
            'Grie',
            'Hong',
            'Jada',
            'Larmurde',
            'Madagali',
            'Maiha',
            'Mayo Belwa',
            'Michika',
            'Mubi North',
            'Mubi South',
            'Numan',
            'Shelleng',
            'Song',
            'Toungo',
            'Yola North',
            'Yola South',
        ],
    },
    {
        state: 'Akwa Ibom',
        alias: 'akwa_ibom',
        lgas: [
            'Abak',
            'Eastern Obolo',
            'Eket',
            'Esit Eket',
            'Essien Udim',
            'Etim Ekpo',
            'Etinan',
            'Ibeno',
            'Ibesikpo Asutan',
            'Ibiono-Ibom',
            'Ika',
            'Ikono',
            'Ikot Abasi',
            'Ikot Ekpene',
            'Ini',
            'Itu',
            'Mbo',
            'Mkpat-Enin',
            'Nsit-Atai',
            'Nsit-Ibom',
            'Nsit-Ubium',
            'Obot Akara',
            'Okobo',
            'Onna',
            'Oron',
            'Oruk Anam',
            'Udung-Uko',
            'Ukanafun',
            'Uruan',
            'Urue-Offong/Oruko',
            'Uyo',
        ],
    },
    {
        state: 'Anambra',
        alias: 'anambra',
        lgas: [
            'Aguata',
            'Anambra East',
            'Anambra West',
            'Anaocha',
            'Awka North',
            'Awka South',
            'Ayamelum',
            'Dunukofia',
            'Ekwusigo',
            'Idemili North',
            'Idemili South',
            'Ihiala',
            'Njikoka',
            'Nnewi North',
            'Nnewi South',
            'Ogbaru',
            'Onitsha North',
            'Onitsha South',
            'Orumba North',
            'Orumba South',
            'Oyi',
        ],
    },
    {
        state: 'Ogun',
        alias: 'ogun',
        lgas: [
            'Abeokuta North',
            'Abeokuta South',
            'Ado-Odo/Ota',
            'Egbado North',
            'Egbado South',
            'Ewekoro',
            'Ifo',
            'Ijebu East',
            'Ijebu North',
            'Ijebu North East',
            'Ijebu Ode',
            'Ikenne',
            'Imeko Afon',
            'Ipokia',
            'Obafemi Owode',
            'Odeda',
            'Odogbolu',
            'Ogun Waterside',
            'Remo North',
            'Shagamu',
        ],
    },
    {
        state: 'Ondo',
        alias: 'ondo',
        lgas: [
            'Akoko North-East',
            'Akoko North-West',
            'Akoko South-East',
            'Akoko South-West',
            'Akure North',
            'Akure South',
            'Ese Odo',
            'Idanre',
            'Ifedore',
            'Ilaje',
            'Ile Oluji/Okeigbo',
            'Irele',
            'Odigbo',
            'Okitipupa',
            'Ondo East',
            'Ondo West',
            'Ose',
            'Owo',
        ],
    },
    {
        state: 'Rivers',
        alias: 'rivers',
        lgas: [
            'Abua/Odual',
            'Ahoada East',
            'Ahoada West',
            'Andoni',
            'Akuku-Toru',
            'Asari-Toru',
            'Bonny',
            'Degema',
            'Eleme',
            'Emuoha',
            'Etche',
            'Gokana',
            'Ikwerre',
            'Khana',
            'Obio/Akpor',
            'Ogba/Egbema/Ndoni',
            'Ogu/Bolo',
            'Okrika',
            'Omuma',
            'Opobo/Nkoro',
            'Oyigbo',
            'Port Harcourt',
            'Tai',
        ],
    },
    {
        state: 'Bauchi',
        alias: 'bauchi',
        lgas: [
            'Alkaleri',
            'Bauchi',
            'Bogoro',
            'Damban',
            'Darazo',
            'Dass',
            'Gamawa',
            'Ganjuwa',
            'Giade',
            'Itas/Gadau',
            "Jama'are",
            'Katagum',
            'Kirfi',
            'Misau',
            'Ningi',
            'Shira',
            'Tafawa Balewa',
            'Toro',
            'Warji',
            'Zaki',
        ],
    },
    {
        state: 'Benue',
        alias: 'benue',
        lgas: [
            'Agatu',
            'Apa',
            'Ado',
            'Buruku',
            'Gboko',
            'Guma',
            'Gwer East',
            'Gwer West',
            'Katsina-Ala',
            'Konshisha',
            'Kwande',
            'Logo',
            'Makurdi',
            'Obi',
            'Ogbadibo',
            'Ohimini',
            'Oju',
            'Okpokwu',
            'Oturkpo',
            'Tarka',
            'Ukum',
            'Ushongo',
            'Vandeikya',
        ],
    },
    {
        state: 'Borno',
        alias: 'borno',
        lgas: [
            'Abadam',
            'Askira/Uba',
            'Bama',
            'Bayo',
            'Biu',
            'Chibok',
            'Damboa',
            'Dikwa',
            'Gubio',
            'Guzamala',
            'Hawul',
            'Gwoza',
            'Jere',
            'Kaga',
            'Kala/Balge',
            'Konduga',
            'Kukawa',
            'Kwaya Kusar',
            'Mafa',
            'Magumeri',
            'Maiduguri',
            'Marte',
            'Mobbar',
            'Monguno',
            'Ngala',
            'Nganzai',
            'Shani',
        ],
    },
    {
        state: 'Bayelsa',
        alias: 'bayelsa',
        lgas: ['Brass', 'Ekeremor', 'Kolokuma/Opokuma', 'Nembe', 'Ogbia', 'Sagbama', 'Southern Ijaw', 'Yenagoa'],
    },
    {
        state: 'Cross River',
        alias: 'cross_river',
        lgas: [
            'Abi',
            'Akamkpa',
            'Akpabuyo',
            'Bakassi',
            'Bekwarra',
            'Biase',
            'Boki',
            'Calabar Municipal',
            'Calabar South',
            'Etung',
            'Ikom',
            'Obanliku',
            'Obubra',
            'Obudu',
            'Odukpani',
            'Ogoja',
            'Yakuur',
            'Yala',
        ],
    },
    {
        state: 'Delta',
        alias: 'delta',
        lgas: [
            'Aniocha North',
            'Aniocha South',
            'Bomadi',
            'Burutu',
            'Ethiope East',
            'Ethiope West',
            'Ika North East',
            'Ika South',
            'Isoko North',
            'Isoko South',
            'Ndokwa East',
            'Ndokwa West',
            'Okpe',
            'Oshimili North',
            'Oshimili South',
            'Patani',
            'Sapele',
            'Udu',
            'Ughelli North',
            'Ughelli South',
            'Ukwuani',
            'Uvwie',
            'Warri North',
            'Warri South',
            'Warri South West',
        ],
    },
    {
        state: 'Ebonyi',
        alias: 'ebonyi',
        lgas: [
            'Abakaliki',
            'Afikpo North',
            'Afikpo South',
            'Ebonyi',
            'Ezza North',
            'Ezza South',
            'Ikwo',
            'Ishielu',
            'Ivo',
            'Izzi',
            'Ohaozara',
            'Ohaukwu',
            'Onicha',
        ],
    },
    {
        state: 'Edo',
        alias: 'edo',
        lgas: [
            'Akoko-Edo',
            'Egor',
            'Esan Central',
            'Esan North-East',
            'Esan South-East',
            'Esan West',
            'Etsako Central',
            'Etsako East',
            'Etsako West',
            'Igueben',
            'Ikpoba Okha',
            'Oredo',
            'Orhionmwon',
            'Ovia North-East',
            'Ovia South-West',
            'Owan East',
            'Owan West',
            'Uhunmwonde',
        ],
    },
    {
        state: 'Ekiti',
        alias: 'ekiti',
        lgas: [
            'Ado Ekiti',
            'Efon',
            'Ekiti East',
            'Ekiti South-West',
            'Ekiti West',
            'Emure',
            'Gbonyin',
            'Ido Osi',
            'Ijero',
            'Ikere',
            'Ikole',
            'Ilejemeje',
            'Irepodun/Ifelodun',
            'Ise/Orun',
            'Moba',
            'Oye',
        ],
    },
    {
        state: 'Enugu',
        alias: 'enugu',
        lgas: [
            'Awgu',
            'Aninri',
            'Enugu East',
            'Enugu North',
            'Enugu South',
            'Ezeagu',
            'Igbo Etiti',
            'Igbo Eze North',
            'Igbo Eze South',
            'Isi Uzo',
            'Nkanu East',
            'Nkanu West',
            'Nsukka',
            'Oji River',
            'Udenu',
            'Udi',
            'Uzo Uwani',
        ],
    },
    {
        state: 'Federal Capital Territory',
        alias: 'abuja',
        lgas: ['Abaji', 'Bwari', 'Gwagwalada', 'Kuje', 'Kwali', 'Municipal Area Council'],
    },
    {
        state: 'Gombe',
        alias: 'gombe',
        lgas: [
            'Akko',
            'Balanga',
            'Billiri',
            'Dukku',
            'Funakaye',
            'Gombe',
            'Kaltungo',
            'Kwami',
            'Nafada',
            'Shongom',
            'Yamaltu/Deba',
        ],
    },
    {
        state: 'Jigawa',
        alias: 'jigawa',
        lgas: [
            'Auyo',
            'Babura',
            'Biriniwa',
            'Birnin Kudu',
            'Buji',
            'Dutse',
            'Gagarawa',
            'Garki',
            'Gumel',
            'Guri',
            'Gwaram',
            'Gwiwa',
            'Hadejia',
            'Jahun',
            'Kafin Hausa',
            'Kaugama',
            'Kazaure',
            'Kiri Kasama',
            'Kiyawa',
            'Maigatari',
            'Malam Madori',
            'Miga',
            'Ringim',
            'Roni',
            'Sule Tankarkar',
            'Taura',
            'Yankwashi',
        ],
    },
    {
        state: 'Oyo',
        alias: 'oyo',
        lgas: [
            'Afijio',
            'Akinyele',
            'Atiba',
            'Atisbo',
            'Egbeda',
            'Ibadan North',
            'Ibadan North-East',
            'Ibadan North-West',
            'Ibadan South-East',
            'Ibadan South-West',
            'Ibarapa Central',
            'Ibarapa East',
            'Ibarapa North',
            'Ido',
            'Irepo',
            'Iseyin',
            'Itesiwaju',
            'Iwajowa',
            'Kajola',
            'Lagelu',
            'Ogbomosho North',
            'Ogbomosho South',
            'Ogo Oluwa',
            'Olorunsogo',
            'Oluyole',
            'Ona Ara',
            'Orelope',
            'Ori Ire',
            'Oyo',
            'Oyo East',
            'Saki East',
            'Saki West',
            'Surulere Oyo State',
        ],
    },
    {
        state: 'Imo',
        alias: 'imo',
        lgas: [
            'Aboh Mbaise',
            'Ahiazu Mbaise',
            'Ehime Mbano',
            'Ezinihitte',
            'Ideato North',
            'Ideato South',
            'Ihitte/Uboma',
            'Ikeduru',
            'Isiala Mbano',
            'Isu',
            'Mbaitoli',
            'Ngor Okpala',
            'Njaba',
            'Nkwerre',
            'Nwangele',
            'Obowo',
            'Oguta',
            'Ohaji/Egbema',
            'Okigwe',
            'Orlu',
            'Orsu',
            'Oru East',
            'Oru West',
            'Owerri Municipal',
            'Owerri North',
            'Owerri West',
            'Unuimo',
        ],
    },
    {
        state: 'Kaduna',
        alias: 'kaduna',
        lgas: [
            'Birnin Gwari',
            'Chikun',
            'Giwa',
            'Igabi',
            'Ikara',
            'Jaba',
            "Jema'a",
            'Kachia',
            'Kaduna North',
            'Kaduna South',
            'Kagarko',
            'Kajuru',
            'Kaura',
            'Kauru',
            'Kubau',
            'Kudan',
            'Lere',
            'Makarfi',
            'Sabon Gari',
            'Sanga',
            'Soba',
            'Zangon Kataf',
            'Zaria',
        ],
    },
    {
        state: 'Kebbi',
        alias: 'kebbi',
        lgas: [
            'Aleiro',
            'Arewa Dandi',
            'Argungu',
            'Augie',
            'Bagudo',
            'Birnin Kebbi',
            'Bunza',
            'Dandi',
            'Fakai',
            'Gwandu',
            'Jega',
            'Kalgo',
            'Koko/Besse',
            'Maiyama',
            'Ngaski',
            'Sakaba',
            'Shanga',
            'Suru',
            'Wasagu/Danko',
            'Yauri',
            'Zuru',
        ],
    },
    {
        state: 'Kano',
        alias: 'kano',
        lgas: [
            'Ajingi',
            'Albasu',
            'Bagwai',
            'Bebeji',
            'Bichi',
            'Bunkure',
            'Dala',
            'Dambatta',
            'Dawakin Kudu',
            'Dawakin Tofa',
            'Doguwa',
            'Fagge',
            'Gabasawa',
            'Garko',
            'Garun Mallam',
            'Gezawa',
            'Gaya',
            'Gwale',
            'Gwarzo',
            'Kabo',
            'Kano Municipal',
            'Karaye',
            'Kibiya',
            'Kiru',
            'Kumbotso',
            'Kunchi',
            'Kura',
            'Madobi',
            'Makoda',
            'Minjibir',
            'Nasarawa',
            'Rano',
            'Rimin Gado',
            'Rogo',
            'Shanono',
            'Sumaila',
            'Takai',
            'Tarauni',
            'Tofa',
            'Tsanyawa',
            'Tudun Wada',
            'Ungogo',
            'Warawa',
            'Wudil',
        ],
    },
    {
        state: 'Kogi',
        alias: 'kogi',
        lgas: [
            'Adavi',
            'Ajaokuta',
            'Ankpa',
            'Bassa',
            'Dekina',
            'Ibaji',
            'Idah',
            'Igalamela Odolu',
            'Ijumu',
            'Kabba/Bunu',
            'Kogi',
            'Lokoja',
            'Mopa Muro',
            'Ofu',
            'Ogori/Magongo',
            'Okehi',
            'Okene',
            'Olamaboro',
            'Omala',
            'Yagba East',
            'Yagba West',
        ],
    },
    {
        state: 'Osun',
        alias: 'osun',
        lgas: [
            'Aiyedaade',
            'Aiyedire',
            'Atakunmosa East',
            'Atakunmosa West',
            'Boluwaduro',
            'Boripe',
            'Ede North',
            'Ede South',
            'Egbedore',
            'Ejigbo',
            'Ife Central',
            'Ife East',
            'Ife North',
            'Ife South',
            'Ifedayo',
            'Ila',
            'Ifelodun',
            'Ilesa East',
            'Ilesa West',
            'Irepodun',
            'Irewole',
            'Isokan',
            'Iwo',
            'Obokun',
            'Odo Otin',
            'Ola Oluwa',
            'Olorunda',
            'Oriade',
            'Orolu',
            'Osogbo',
        ],
    },
    {
        state: 'Sokoto',
        alias: 'sokoto',
        lgas: [
            'Binji',
            'Bodinga',
            'Dange Shuni',
            'Gada',
            'Goronyo',
            'Gudu',
            'Gwadabawa',
            'Illela',
            'Isa',
            'Kebbe',
            'Kware',
            'Rabah',
            'Sabon Birni',
            'Shagari',
            'Silame',
            'Sokoto North',
            'Sokoto South',
            'Tambuwal',
            'Tangaza',
            'Tureta',
            'Wamako',
            'Wurno',
            'Yabo',
        ],
    },
    {
        state: 'Plateau',
        alias: 'plateau',
        lgas: [
            'Bokkos',
            'Barkin Ladi',
            'Bassa',
            'Jos East',
            'Jos North',
            'Jos South',
            'Kanam',
            'Kanke',
            'Langtang South',
            'Langtang North',
            'Mangu',
            'Mikang',
            'Pankshin',
            "Qua'an Pan",
            'Riyom',
            'Shendam',
            'Wase',
        ],
    },
    {
        state: 'Taraba',
        alias: 'taraba',
        lgas: [
            'Ardo Kola',
            'Bali',
            'Donga',
            'Gashaka',
            'Gassol',
            'Ibi',
            'Jalingo',
            'Karim Lamido',
            'Kumi',
            'Lau',
            'Sardauna',
            'Takum',
            'Ussa',
            'Wukari',
            'Yorro',
            'Zing',
        ],
    },
    {
        state: 'Yobe',
        alias: 'yobe',
        lgas: [
            'Bade',
            'Bursari',
            'Damaturu',
            'Fika',
            'Fune',
            'Geidam',
            'Gujba',
            'Gulani',
            'Jakusko',
            'Karasuwa',
            'Machina',
            'Nangere',
            'Nguru',
            'Potiskum',
            'Tarmuwa',
            'Yunusari',
        ],
    },
    {
        state: 'Zamfara',
        alias: 'zamfara',
        lgas: [
            'Anka',
            'Birnin Magaji/Kiyaw',
            'Bakura',
            'Bukkuyum',
            'Bungudu',
            'Gummi',
            'Gusau',
            'Kaura Namoda',
            'Maradun',
            'Maru',
            'Shinkafi',
            'Talata Mafara',
            'Tsafe',
            'Zurmi',
        ],
    },
    {
        state: 'Lagos',
        alias: 'lagos',
        lgas: [
            'Agege',
            'Ajeromi-Ifelodun',
            'Alimosho',
            'Amuwo-Odofin',
            'Badagry',
            'Apapa',
            'Epe',
            'Eti Osa',
            'Ibeju-Lekki',
            'Ifako-Ijaiye',
            'Ikeja',
            'Ikorodu',
            'Kosofe',
            'Lagos Island',
            'Lagos Mainland',
            'Mushin',
            'Ojo',
            'Oshodi-Isolo',
            'Shomolu',
            'Surulere Lagos State',
        ],
    },
    {
        state: 'Katsina',
        alias: 'katsina',
        lgas: [
            'Bakori',
            'Batagarawa',
            'Batsari',
            'Baure',
            'Bindawa',
            'Charanchi',
            'Danja',
            'Dandume',
            'Dan Musa',
            'Daura',
            'Dutsi',
            'Dutsin Ma',
            'Faskari',
            'Funtua',
            'Ingawa',
            'Jibia',
            'Kafur',
            'Kaita',
            'Kankara',
            'Kankia',
            'Katsina',
            'Kurfi',
            'Kusada',
            "Mai'Adua",
            'Malumfashi',
            'Mani',
            'Mashi',
            'Matazu',
            'Musawa',
            'Rimi',
            'Sabuwa',
            'Safana',
            'Sandamu',
            'Zango',
        ],
    },
    {
        state: 'Kwara',
        alias: 'kwara',
        lgas: [
            'Asa',
            'Baruten',
            'Edu',
            'Ekiti Kwara State',
            'Ifelodun',
            'Ilorin East',
            'Ilorin South',
            'Ilorin West',
            'Irepodun',
            'Isin',
            'Kaiama',
            'Moro',
            'Offa',
            'Oke Ero',
            'Oyun',
            'Pategi',
        ],
    },
    {
        state: 'Nasarawa',
        alias: 'nasarawa',
        lgas: [
            'Akwanga',
            'Awe',
            'Doma',
            'Karu',
            'Keana',
            'Keffi',
            'Kokona',
            'Lafia',
            'Nasarawa Egon',
            'Nasarawa',
            'Obi',
            'Toto',
            'Wamba',
        ],
    },
    {
        state: 'Niger',
        alias: 'niger',
        lgas: [
            'Agaie',
            'Agwara',
            'Bida',
            'Borgu',
            'Bosso',
            'Chanchaga',
            'Edati',
            'Gbako',
            'Gurara',
            'Katcha',
            'Kontagora',
            'Lapai',
            'Lavun',
            'Magama',
            'Mariga',
            'Mashegu',
            'Mokwa',
            'Moya',
            'Paikoro',
            'Rafi',
            'Rijau',
            'Shiroro',
            'Suleja',
            'Tafa',
            'Wushishi',
        ],
    },
];
