import React, { Fragment, useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import {
    TopHeader,
    PageLogo,
    RippleButton,
    PaymentConfirmation,
    InputWithLabel,
    Loader,
    SelectBox,
} from '../../../../components';
import ValidatePasswordModal from '../../../../components/validate-password';
import { InputBlock } from '../../../../containers/InputContainer';
import { SubTitle } from '../../../../containers/MessageContainer';
import { ScreenContainer, FlexCenteredBlock } from '../../../../containers/ScreenContainer';
import * as actions from '../../../../redux/ducks/applications/bill-payments/actions/entertainment';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import { SuccessPage } from '../../../home';

import { EntertainmentPayValidationSchema } from './EntertainmentPayValidationSchema';

const EntertainmentPay = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.applications.billPayments.isLoading);
    const allPlans = useSelector((state) => state.applications.billPayments.allProvidersItems);

    const [selectedProvider] = useState(location.state);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [openPasswordModal, setOpenPasswordModal] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [transactionData, setTransactionData] = useState({});
    const { billerId, logoUrl, subChargeFee, name, billCategoryId, type } =
        (selectedProvider && selectedProvider) || {};
    const { paymentItemCode, fullName, decoderNumber, amount } = transactionData;

    if (!billerId) history.push('/actions/entertainment');

    useEffect(() => {
        dispatch(actions.getSubscriptionPlansByProviderId(billerId));
    }, [billerId, dispatch]);

    const selectOptions = allPlans
        ? allPlans.map((providerItem) => ({ value: providerItem.name, label: providerItem.name }))
        : [];

    return isLoading ? (
        <Loader />
    ) : (
        <>
            {paymentSuccess ? (
                <SuccessPage
                    header={'Successful transaction'}
                    body={`Your ${name} Purchase of ${formatPrice(amount)} was successful`}
                />
            ) : (
                <Fragment>
                    {openPasswordModal ? (
                        <ValidatePasswordModal
                            title={'Entertainment'}
                            isLoading={isLoading}
                            setOpen={setOpenPasswordModal}
                            open={openPasswordModal}
                            next={async () => {
                                const response = await dispatch(
                                    actions.cabelSubscriptionPurchase(amount, billerId, paymentItemCode, decoderNumber),
                                );
                                if (response === 200) {
                                    setPaymentSuccess(true);
                                }
                            }}
                        />
                    ) : (
                        <Fragment>
                            <TopHeader title={'Entertainment'} />
                            <ScreenContainer>
                                <FlexCenteredBlock top={'56px'}>
                                    <PageLogo Icon={logoUrl} width={'48px'} height={'48px'} top={'8px'} />
                                    <SubTitle top={'8px'}>{type}</SubTitle>
                                    <Formik
                                        initialValues={{
                                            subscriptionPlan: '',
                                            amount: 0,
                                            decoderNumber: '',
                                        }}
                                        validationSchema={EntertainmentPayValidationSchema}
                                        onSubmit={async (values, { setErrors }) => {
                                            const plan = allPlans.find((item) => item.name === values.subscriptionPlan);

                                            const customerValid = await dispatch(
                                                actions.validateCabelCustomer(
                                                    billerId,
                                                    plan && plan.paymentItemCode,
                                                    values.decoderNumber,
                                                    setErrors,
                                                ),
                                            );

                                            if (!customerValid) return;
                                            const updatedTransactionData = {
                                                ...plan,
                                                amount: Number(plan.amount),
                                                decoderNumber: values.decoderNumber,
                                                fullName: customerValid.fullName,
                                            };

                                            setTransactionData(updatedTransactionData);
                                            setOpenConfirmation(!openConfirmation);
                                        }}
                                    >
                                        {({ errors, touched, setFieldValue, values, initialValues, handleChange }) => (
                                            <Form style={{ width: '100%' }}>
                                                <InputBlock top={'24px'}>
                                                    <SelectBox
                                                        name={'subscriptionPlan'}
                                                        placeholder={
                                                            allPlans && allPlans.length ? 'Plans' : 'No plans found'
                                                        }
                                                        value={values.subscriptionPlan}
                                                        options={selectOptions}
                                                        handleChange={handleChange}
                                                        valid={`${
                                                            !touched.subscriptionPlan && !errors.subscriptionPlan
                                                        }`}
                                                        error={
                                                            touched &&
                                                            touched.subscriptionPlan &&
                                                            errors &&
                                                            errors.subscriptionPlan
                                                        }
                                                    />
                                                    <InputWithLabel
                                                        label={'Decoder Number (ICU)'}
                                                        type={'number'}
                                                        value={values.decoderNumber}
                                                        placeholder={'Decoder Number (ICU)'}
                                                        name="decoderNumber"
                                                        inputMode={'decimal'}
                                                        valid={`${touched.decoderNumber && !errors.decoderNumber}`}
                                                        errors={
                                                            touched &&
                                                            touched.decoderNumber &&
                                                            errors &&
                                                            errors.decoderNumber
                                                        }
                                                        setFieldValue={setFieldValue}
                                                        initialValues={initialValues}
                                                    />
                                                    <RippleButton
                                                        type="submit"
                                                        disabled={
                                                            Object.values(values).some((value) => value.length === 0) ||
                                                            isLoading
                                                        }
                                                    >
                                                        Continue
                                                    </RippleButton>
                                                </InputBlock>
                                                {openConfirmation && (
                                                    <PaymentConfirmation
                                                        open={openConfirmation}
                                                        close={setOpenConfirmation}
                                                        confirm={setOpenPasswordModal}
                                                        transactionDetails={{
                                                            fullName,
                                                            billCategoryId,
                                                            customer: decoderNumber.toString(),
                                                            amount,
                                                            subChargeFee,
                                                            paymentItemCode,
                                                            providerLogo: logoUrl,
                                                            biller_name: name,
                                                            billerId,
                                                            subHeading: `Sub charge: ${formatPrice(
                                                                parseInt(subChargeFee, 10),
                                                            )}`,
                                                        }}
                                                    />
                                                )}
                                            </Form>
                                        )}
                                    </Formik>
                                </FlexCenteredBlock>
                            </ScreenContainer>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </>
    );
};

export default EntertainmentPay;
