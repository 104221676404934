import styled from 'styled-components';

import { colors } from '../../styles';

export const DescArea = styled.div`
    display: flex;
    background-color: ${colors.aquamarine};
    color: #579fd7;
    border-radius: 10px;
    height: 48px;
    width: 100%;
    align-items: center;
    padding: 10px;
    margin: 5px 0;
`;

export const IconCircle = styled.div`
    border-radius: 50%;
    height: 32px;
    width: 32px;
    background-color: #fff;
    padding: 6px;
    margin: 0 10px;
`;

export const DescContentArea = styled.div`
    flex: 1;
`;

export const DescText = styled.div`
    font-size: ${({ size }) => size || '10px'};
    padding: 2px;
    color: ${({ color }) => color || 'inherit'};
    font-weight: ${({ weight }) => weight || '500'};
`;

export const ErrorBox = styled.span`
    position: absolute;
    top: 55px;
    font-size: 10px;
    color: #e02020;
`;

export const ContentArea = styled.div`
    height: 346px;
    border-radius: 13px;
    border: 1px solid #eeeeee;
    padding: 24px;
    align-content: center;
    text-align: center;
    margin: 7px 0;
`;
export const AmountBox = styled.div`
    color: #579fd7;
    font-size: 28px;
`;
export const Narration = styled.div`
    min-height: 49px;
    border-radius: 10px;
    background: #eeeeee;
    color: #56636d;
    opacity: 50%;
    text-align: left;
    padding: 14px;
`;

export const TransactionFeeBox = styled.div`
    height: 24px;
    background: ${colors.tomatoFaded};
    border-radius: 13px;
    font-size: 13px;
    width: 207px;
    padding: 2px 10px;
    display: flex;
    align-items: center;
    margin: 0 auto;
`;

export const TransactionFee = styled.div`
    padding: 0 7px;
    color: #e02020;
`;

export const Space = styled.div`
    height: ${({ height }) => height || '10px'};
`;
