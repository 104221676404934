import { push, goBack } from 'connected-react-router';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import { axios, currentAPI } from '../../../../../../config';
import {
    SHOP_CREATE_SHOP,
    SHOP_UPDATE_SHOP,
    SHOP_CREATE_PRODUCT,
    SHOP_UPDATE_PRODUCT,
    SHOP_UPDATE_PRODUCT_QUANTITY,
    SHOP_DELETE_PRODUCT,
    COMPLETE_UPDATING_SHOP_PROFILE,
} from '../../../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../../../utils/mix-panel/mixPanel';
import { addPendingRequest } from '../../../../offline/actions';
import {
    REMOVE_PENDING_REQUEST,
    UPDATE_PENDING_REQUEST,
    UPDATE_PENDING_SALE_INVENTORY_PRODUCT,
} from '../../../../offline/constants';
import { reloginUser } from '../../../../user/actions';
import { getAgentActivationStatus } from '../../../agents/actions';
import {
    FETCH_SHOP_INVENTRY_DATA,
    SAVE_SHOP,
    UPDATE_SHOP,
    DELETE_SHOP,
    ADD_PRODUCT,
    UPDATE_PRODUCT,
    DELETE_PRODUCT,
    UPDATE_PRODUCT_QUANTITY,
    UPDATE_PRODUCT_ID,
    UPDATE_INVENTORY_PRODUCT_ID,
    UPDATE_PRODUCT_IMAGE,
    LOADING_SHOP,
    STOP_LOADING,
    MY_SHOP_SUCCESS,
    MY_SHOP_ERROR,
    MY_SHOP_CATEGORIES,
    FETCH_PRODUCTS_MASTER_LIST,
    NO_PRODUCTS_ON_MASTER_LIST,
    TOGGLE_MERCH_VISIBLE,
    MY_SHOP_PRODUCT_CATEGORIES,
    ADD_INVENTORY,
    BULK_PRICE_UPDATE,
    UPDATE_BANK_ACCOUNT_DETAILS,
    TOGGLE_PAYMENT_OPTIONS,
} from '../../constants';
import { getSales } from '../sales';

export const getCitiesByLga = (lga) => async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_SHOP });

    try {
        const getCitiesByLgaResponse = await axios.get(`${currentAPI}/api/warenext/delivery/cities/${lga}`);

        if (getCitiesByLgaResponse.status === 200) {
            dispatch({ type: MY_SHOP_SUCCESS });
            return getCitiesByLgaResponse.data;
        } else {
            dispatch({ type: MY_SHOP_ERROR, payload: '' });
        }
    } catch (error) {
        dispatch({ type: MY_SHOP_ERROR, payload: error.message });
    }
};
export const getShops = () => async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_SHOP });

    try {
        const getShopsResponse = await axios.get(`${currentAPI}/api/shops`);

        if (getShopsResponse.status === 200) {
            const retrievedShops = getShopsResponse.data;

            if (retrievedShops.length) {
                dispatch({ type: SAVE_SHOP, payload: retrievedShops });
                dispatch(getSales(retrievedShops[0].branchId));
                dispatch(getShopInfoFromMerchapp(retrievedShops[0].id, retrievedShops[0].branchId));
            }
        } else {
            dispatch({ type: MY_SHOP_ERROR, payload: '' });
        }
    } catch (error) {
        dispatch({ type: MY_SHOP_ERROR, payload: error.message });
    } finally {
        dispatch({ type: MY_SHOP_SUCCESS });
    }
};

export const getShopInfoFromMerchapp = (shopId, branchId) => async (dispatch) => {
    if (!navigator.onLine) return;

    //dispatch({type: FETCH_SHOP_DATA});

    try {
        const getShopInfoResponse = await axios.get(`${currentAPI}/api/merchantAppIntegration/branchInfo/${branchId}`);

        const getShopDynamicLinkResponse = await axios.get(
            `${currentAPI}/api/merchantAppIntegration/branchMerchantDetails/${branchId}`,
        );

        if (getShopInfoResponse.status === 200 && getShopDynamicLinkResponse.status === 200) {
            const shopInfo = getShopInfoResponse.data.data;
            const { dynamicLink } = getShopDynamicLinkResponse.data || {};

            dispatch({
                type: UPDATE_SHOP,
                payload: {
                    shopId,
                    newData: {
                        shopName: shopInfo.name,
                        businessCategories: shopInfo.businessCategories,
                        //businessCategoryNames: shopInfo.businessCategoryNames,
                        //productCategories: shopInfo.productCategories,
                        //available: shopInfo.available,
                        details: shopInfo.details,
                        avatar: shopInfo.imageUrl,
                        base64BranchImageString: shopInfo.base64BranchImageString,
                        slug: shopInfo.slug,
                        businessSlug: shopInfo.businessSlug,
                        location: shopInfo.location,
                        listingOptions: shopInfo.listingOptions,
                        accountDetails: shopInfo.accountDetails,
                        paymentOption: shopInfo.paymentOption,
                        merchbuyLink: dynamicLink && dynamicLink,
                        updatedAt: shopInfo.modifiedDate,
                    },
                },
            });
            setTimeout(function () {
                dispatch({ type: MY_SHOP_SUCCESS });
            }, 3000);
        }
    } catch (error) {
        dispatch({ type: MY_SHOP_ERROR, payload: error.message });
    }
};

//Looks depreciated
export const getShopFromMerchapp = (phoneNumber) => async () => {
    try {
        await axios.post(`${currentAPI}/api/shops/sync/${phoneNumber}`, null);
    } catch (error) {
        //console.error(error);
        if (error.response && error.response.status === 400) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.message);
        }
    }
};

export const createShop = (shopInfo, setErrors, setNetworkError) => async (dispatch, getState) => {
    if (!navigator.onLine) {
        toast.error('You can not perform this action offline...');
        return;
    }

    dispatch({ type: LOADING_SHOP });

    try {
        const validate = await dispatch(reloginUser(shopInfo.password));
        if (validate.status === 400) {
            dispatch({ type: STOP_LOADING });
            toast.error('incorrect password');
        }

        if (validate.status === 200) {
            const ShopDTO = { ...shopInfo };

            const createShopResponse = await axios.post(`${currentAPI}/api/shops`, JSON.stringify(ShopDTO));

            if (createShopResponse.status === 200) {
                ShopDTO.id = createShopResponse.data.id;
                ShopDTO.merchantId = createShopResponse.data.merchantId;

                const role = getState().user.role;
                const userId = getState().user.userId;
                mixPanel.track(SHOP_CREATE_SHOP, {
                    'User ID': userId,
                    Role: role === 'ROLE_USER' ? 'Merchant' : 'Agent',
                    'Shop ID': ShopDTO.id,
                    'Business categories': ShopDTO.businessCategories,
                    LGA: ShopDTO.lga,
                    state: ShopDTO.countryState,
                });
                dispatch({ type: SAVE_SHOP, payload: ShopDTO });
                dispatch(push('/'));
                toast.success(`Your shop was successfully created`);
            }
        }
    } catch (error) {
        dispatch({ type: MY_SHOP_ERROR, payload: error.message });

        if (error.message === 'Network Error') {
            setNetworkError(true);
        } else if (error.response && error.response.status === 409) {
            setErrors({ shopName: 'Shop name is already taken' });
            toast.error('Shop name is already taken');
        }
    }
};

export const deleteShop = (id) => async (dispatch) => {
    if (!navigator.onLine) {
        toast.error('You can not perform this action offline...');
        return;
    }

    dispatch({ type: LOADING_SHOP });

    try {
        const deleteShopResponse = await axios.delete(`${currentAPI}/api/shops/${id}`);

        if (deleteShopResponse.status === 200) {
            dispatch({ type: DELETE_SHOP, payload: id });
            dispatch({ type: MY_SHOP_SUCCESS });
            dispatch(push('/actions/shop'));
        }
    } catch (error) {
        dispatch({ type: MY_SHOP_ERROR, payload: error.message });
        if (error.response && error.response.status === 400) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.message);
        }
    }
};

export const updateShop = (shopData) => async (dispatch) => {
    try {
        const updateMerchAppResponse = await axios.put(
            `${currentAPI}/api/merchantAppIntegration/public/updateShopDetails`,
            JSON.stringify(shopData),
        );
        const updateShopResponse = await axios.put(
            `${currentAPI}/api/shops/${shopData.shopId}`,
            JSON.stringify({ avatar: shopData.base64BranchImageString, shopName: shopData.name }),
        );

        if (updateShopResponse.status === 200 && updateMerchAppResponse.status === 200) {
            mixPanel.track(SHOP_UPDATE_SHOP, {
                'Shop ID': shopData.id,
            });
            return updateMerchAppResponse.data;
        }
    } catch (error) {
        dispatch({ type: MY_SHOP_ERROR, payload: error.message });
        if (error.response && error.response.status === 400) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.message);
        }
    }
};

export const editShop = (data) => async (dispatch) => {
    if (!navigator.onLine) {
        toast.error('You can not perform this action offline...');
        return;
    }

    dispatch({ type: LOADING_SHOP });
    const shopData = {
        ...data,
        id: data.branchId,
        shopId: data.id,
        businessId: data.businessId,
    };
    const resp = await dispatch(updateShop(shopData));
    if (resp) {
        dispatch(getShopInfoFromMerchapp(data.id, data.branchId));
        dispatch({ type: MY_SHOP_SUCCESS });
        mixPanel.track(COMPLETE_UPDATING_SHOP_PROFILE, {
            'Shop Id': data.id,
            Time: new Date().toLocaleString(),
        });
        dispatch(goBack());
        toast.success('Successfully updated your shop details');
    }
};

export const toggleListingOptionsVisibility = (shopId, isOnMerchBuy, isOnMerchList) => async (dispatch, getState) => {
    if (!navigator.onLine) {
        toast.error('You can not perform this action offline...');
        return;
    }

    dispatch({ type: LOADING_SHOP });

    const shop = getState().applications.myShop.shops.find((shop) => shop.id === shopId);
    const shopData = {
        id: shop.branchId,
        businessId: shop.businessId,
        name: shop.shopName,
        listingOptions: {
            isOnMerchBuy: isOnMerchBuy,
            isOnMerchList: isOnMerchList,
        },
    };

    const resp = await dispatch(updateShop(shopData));
    if (resp) {
        dispatch({
            type: TOGGLE_MERCH_VISIBLE,
            payload: {
                shopId,
                isOnMerchBuy: resp.data.listingOptions.isOnMerchBuy,
                isOnMerchList: resp.data.listingOptions.isOnMerchList,
            },
        });
        dispatch({ type: MY_SHOP_SUCCESS });
        toast.success('Successfully updated your shop details');
    }
};

export const togglePaymentOptions = (shopId, paymentOption) => async (dispatch, getState) => {
    if (!navigator.onLine) {
        toast.error('You can not perform this action offline...');
        return;
    }

    dispatch({ type: LOADING_SHOP });

    const shop = getState().applications.myShop.shops.find((shop) => shop.id === shopId);
    const shopData = {
        id: shop.branchId,
        businessId: shop.businessId,
        name: shop.shopName,
        paymentOption: paymentOption,
    };
    const resp = await dispatch(updateShop(shopData));
    if (resp) {
        dispatch({
            type: TOGGLE_PAYMENT_OPTIONS,
            payload: {
                shopId,
                paymentOption: resp.data.paymentOption,
            },
        });
        dispatch({ type: MY_SHOP_SUCCESS });
        toast.success('Successfully updated your settlement account');
    }
};

export const updateBankAccountDetails =
    (shopId, accountDetails, paymentOption = null) =>
    async (dispatch, getState) => {
        if (!navigator.onLine) {
            toast.error('You can not perform this action offline...');
            return;
        }

        dispatch({ type: LOADING_SHOP });

        const shop = getState().applications.myShop.shops.find((shop) => shop.id === shopId);
        const shopData = {
            id: shop.branchId,
            businessId: shop.businessId,
            name: shop.shopName,
            accountDetails: accountDetails,
            paymentOption: paymentOption ? paymentOption : 'BANK',
        };
        const resp = await dispatch(updateShop(shopData));
        if (resp) {
            dispatch({
                type: UPDATE_BANK_ACCOUNT_DETAILS,
                payload: {
                    shopId,
                    accountDetails: resp.data.accountDetails,
                    paymentOption: resp.data.paymentOption,
                },
            });
            dispatch({ type: MY_SHOP_SUCCESS });
            toast.success('Successfully updated your shop details');
        }
    };

export const addProduct = (shopId, productInfo, setErrors) => async (dispatch, getState) => {
    dispatch({ type: LOADING_SHOP });
    const productData = { ...productInfo };

    if (navigator.onLine) {
        try {
            const addProductResponse = await axios.post(
                `${currentAPI}/api/shops/${shopId}/product`,
                JSON.stringify(productData),
            );

            if (addProductResponse.status === 200) {
                productData.id = addProductResponse.data.id;
                productData.inventoryProductId = addProductResponse.data.inventoryProductId;
                productData.base64ProductImageString = addProductResponse.data.images.baseImageUrl;

                mixPanel.track(SHOP_CREATE_PRODUCT, {
                    ProductId: productData.id,
                    'Product Name': productData.productName,
                    'Cost Price': productData.costPrice,
                    'Product Image': productData.base64ProductImageString !== '',
                    'Selling Price': productData.retailUnitPrice,
                    'Product category': productData.productCategory,
                    'Active for Merchbuy': productData.availableAtWholesalePrice,
                    'Active for merchlist': productData.availableAtRetailPrice,
                });

                dispatch({
                    type: ADD_PRODUCT,
                    payload: { shopId, productData },
                });
                toast.success(`${productData.productName} was successfully created`);
                dispatch({ type: MY_SHOP_SUCCESS });
                dispatch(goBack());
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                dispatch({ type: MY_SHOP_ERROR, payload: error.message });
                setErrors({
                    productName: 'You already have a product with this name',
                });
            } else {
                const inventory = getState().applications.myShop.shops.find((shop) => shop.id === shopId).inventory;
                const isInInventory = inventory.find((product) => product.productName === productData.productName);

                if (isInInventory) {
                    setErrors({
                        productName: 'You already have a product with this name',
                    });
                    dispatch({ type: MY_SHOP_ERROR, payload: error.message });
                } else {
                    dispatch(
                        addPendingRequest('POST', `${currentAPI}/api/shops/${shopId}/product`, productData, [
                            UPDATE_PRODUCT_ID,
                            UPDATE_INVENTORY_PRODUCT_ID,
                            UPDATE_PENDING_SALE_INVENTORY_PRODUCT,
                            UPDATE_PRODUCT_IMAGE,
                        ]),
                    );

                    productData.id = uuid();
                    productData.inventoryProductId = uuid();

                    dispatch({
                        type: ADD_PRODUCT,
                        payload: { shopId, productData },
                    });
                    dispatch({ type: MY_SHOP_SUCCESS });
                    toast.success(`${productData.productName} was successfully created`);
                    dispatch(goBack());
                }
            }
        }
    } else {
        const inventory = getState().applications.myShop.shops.find((shop) => shop.id === shopId).inventory;
        const isInInventory = inventory.find((product) => product.productName === productData.productName);

        if (isInInventory) {
            setErrors({
                productName: 'You already have a product with this name',
            });
            dispatch({ type: MY_SHOP_ERROR, payload: 'Product Name Exist' });
        } else {
            dispatch(
                addPendingRequest('POST', `${currentAPI}/api/shops/${shopId}/product`, productData, [
                    UPDATE_PRODUCT_ID,
                    UPDATE_INVENTORY_PRODUCT_ID,
                    UPDATE_PENDING_SALE_INVENTORY_PRODUCT,
                    UPDATE_PRODUCT_IMAGE,
                ]),
            );

            productData.id = uuid();
            productData.inventoryProductId = uuid();

            dispatch({ type: ADD_PRODUCT, payload: { shopId, productData } });
            dispatch({ type: MY_SHOP_SUCCESS });
            toast.success(`${productData.productName} was successfully created`);
            dispatch(goBack());
        }
    }
};

export const addProductV2 = (shopId, productInfo, setErrors) => async (dispatch, getState) => {
    dispatch({ type: LOADING_SHOP });
    const productData = { ...productInfo };

    if (navigator.onLine) {
        try {
            const addProductResponse = await axios.post(
                `${currentAPI}/api/shops/${shopId}/product`,
                JSON.stringify(productData),
            );

            if (addProductResponse.status === 200) {
                const { id, inventoryProductId, images } = addProductResponse.data || {};
                const { baseImageUrl, others } = images || {};

                productData.id = id;
                productData.inventoryProductId = inventoryProductId;
                productData.base64ProductImageString = baseImageUrl;
                productData.otherImagesBase64String = others;
                productData.images.baseImageUrl = baseImageUrl;
                productData.images.others = others;

                mixPanel.track(SHOP_CREATE_PRODUCT, {
                    ProductId: productData.id,
                    'Product Name': productData.productName,
                    'Cost Price': productData.costPrice,
                    'Product Image': productData.base64ProductImageString !== '',
                    'Selling Price': productData.retailUnitPrice,
                    'Product category': productData.productCategory,
                    'Active for Merchbuy': productData.availableAtWholesalePrice,
                    'Active for merchlist': productData.availableAtRetailPrice,
                });

                dispatch({
                    type: ADD_PRODUCT,
                    payload: { shopId, productData },
                });
                dispatch({ type: MY_SHOP_SUCCESS });
                return {
                    status: true,
                    productData,
                };
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                dispatch({ type: MY_SHOP_ERROR, payload: error.message });
                toast.error(`${productData.productName} already exist`);
                //setErrors({"productName": 'You already have a product with this name'});
            } else {
                const inventory = getState().applications.myShop.shops.find((shop) => shop.id === shopId).inventory;
                const isInInventory = inventory.find((product) => product.productName === productData.productName);

                if (isInInventory) {
                    toast.error(`${productData.productName} already exist`);
                    //setErrors({ "productName": 'You already have a product with this name' });
                    dispatch({ type: MY_SHOP_ERROR, payload: error.message });
                } else {
                    dispatch(
                        addPendingRequest('POST', `${currentAPI}/api/shops/${shopId}/product`, productData, [
                            UPDATE_PRODUCT_ID,
                            UPDATE_INVENTORY_PRODUCT_ID,
                            UPDATE_PENDING_SALE_INVENTORY_PRODUCT,
                            UPDATE_PRODUCT_IMAGE,
                        ]),
                    );

                    productData.id = uuid();
                    productData.inventoryProductId = uuid();

                    dispatch({
                        type: ADD_PRODUCT,
                        payload: { shopId, productData },
                    });
                    dispatch({ type: MY_SHOP_SUCCESS });
                    return {
                        status: true,
                        productData,
                    };
                }
            }
        }
    } else {
        const inventory = getState().applications.myShop.shops.find((shop) => shop.id === shopId).inventory;
        const isInInventory = inventory.find((product) => product.productName === productData.productName);

        if (isInInventory) {
            toast.error(`${productData.productName} already exist`);
            //setErrors({ "productName": 'You already have a product with this name' });
            dispatch({ type: MY_SHOP_ERROR, payload: 'Product Name Exist' });
        } else {
            dispatch(
                addPendingRequest('POST', `${currentAPI}/api/shops/${shopId}/product`, productData, [
                    UPDATE_PRODUCT_ID,
                    UPDATE_INVENTORY_PRODUCT_ID,
                    UPDATE_PENDING_SALE_INVENTORY_PRODUCT,
                    UPDATE_PRODUCT_IMAGE,
                ]),
            );

            productData.id = uuid();
            productData.inventoryProductId = uuid();

            dispatch({ type: ADD_PRODUCT, payload: { shopId, productData } });
            dispatch({ type: MY_SHOP_SUCCESS });
            return {
                status: true,
                productData,
            };
        }
    }
};

export const addProductOnTheFly = (shopId, productInfo) => async (dispatch, getState) => {
    dispatch({ type: LOADING_SHOP });

    const productData = {
        availableAtRetailPrice: false,
        availableAtWholesalePrice: false,
        cost: productInfo.productUnit,
        costPrice: productInfo.productUnit,
        base64ProductImageString: productInfo.base64ProductImageString || '',
        moq: 0,
        productCategory: '',
        productDescription: '',
        productName: productInfo.productName,
        productUnit: '',
        quantity: productInfo.quantity,
        retailUnitPrice: productInfo.productUnit,
        wholesaleUnitPrice: 0,
        bulkPrices: [],
        images: productInfo?.images,
        localSettings: {
            hasInventory: false,
        },
    };

    if (navigator.onLine) {
        try {
            const addProductOnTheFlyResponse = await axios.post(
                `${currentAPI}/api/shops/${shopId}/product`,
                JSON.stringify(productData),
            );

            if (addProductOnTheFlyResponse.status === 200) {
                mixPanel.track(SHOP_CREATE_PRODUCT, {
                    ProductId: productData.id,
                    'Product Name': productData.productName,
                    'Cost Price': productData.costPrice,
                    'Product Image': productData.base64ProductImageString !== '',
                    'Selling Price': productData.retailUnitPrice,
                    'Product category': productData.productCategory,
                    'Active for Merchbuy': productData.availableAtWholesalePrice,
                    'Active for merchlist': productData.availableAtRetailPrice,
                });
                productData.id = addProductOnTheFlyResponse.data.id;
                productData.inventoryProductId = addProductOnTheFlyResponse.data.inventoryProductId;
                productData.base64ProductImageString = addProductOnTheFlyResponse.data.base64ProductImageString;

                dispatch({
                    type: ADD_PRODUCT,
                    payload: { shopId, productData },
                });
                dispatch({ type: MY_SHOP_SUCCESS });

                return {
                    status: true,
                    product: productData,
                };
            }
        } catch (error) {
            if (error.message === 'Network Error') {
                const inventory = getState().applications.myShop.shops.find((shop) => shop.id === shopId).inventory;
                const isInInventory =
                    inventory && inventory.find((product) => product.productName === productData.productName);

                if (isInInventory) {
                    dispatch({
                        type: MY_SHOP_ERROR,
                        payload: 'Product Name Exist',
                    });
                    return {
                        status: false,
                        product: 'Product Exist In Your Inventory',
                    };
                } else {
                    dispatch(
                        addPendingRequest('POST', `${currentAPI}/api/shops/${shopId}/product`, productData, [
                            UPDATE_PRODUCT_ID,
                            UPDATE_INVENTORY_PRODUCT_ID,
                            UPDATE_PENDING_SALE_INVENTORY_PRODUCT,
                            UPDATE_PRODUCT_IMAGE,
                        ]),
                    );

                    productData.id = uuid();
                    productData.inventoryProductId = uuid();

                    dispatch({
                        type: ADD_PRODUCT,
                        payload: { shopId, productData },
                    });
                    dispatch({ type: MY_SHOP_SUCCESS });
                    return {
                        status: true,
                        product: productData,
                    };
                }
            } else if (error.response && error.response.status === 400) {
                dispatch({ type: MY_SHOP_ERROR, payload: error.message });
                const { data } = (error && error.response) || {};

                return {
                    status: false,
                    product: data.message && error.response.data.message,
                };
            } else {
                dispatch({ type: MY_SHOP_ERROR, payload: error.message });
            }
        }
    } else {
        const inventory = getState().applications.myShop.shops.find((shop) => shop.id === shopId).inventory;
        const isInInventory = inventory && inventory.find((product) => product.productName === productData.productName);

        if (isInInventory) {
            dispatch({ type: MY_SHOP_ERROR, payload: 'Product Name Exist' });
            return {
                status: false,
                product: 'Product Exist In Your Inventory',
            };
        } else {
            dispatch(
                addPendingRequest('POST', `${currentAPI}/api/shops/${shopId}/product`, productData, [
                    UPDATE_PRODUCT_ID,
                    UPDATE_INVENTORY_PRODUCT_ID,
                    UPDATE_PENDING_SALE_INVENTORY_PRODUCT,
                    UPDATE_PRODUCT_IMAGE,
                ]),
            );

            productData.id = uuid();
            productData.inventoryProductId = uuid();

            dispatch({ type: ADD_PRODUCT, payload: { shopId, productData } });
            dispatch({ type: MY_SHOP_SUCCESS });
            return {
                status: true,
                product: productData,
            };
        }
    }
};

export const getInventory = (shopId) => async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch({
        type: FETCH_SHOP_INVENTRY_DATA,
        payload: shopId,
    });

    try {
        const getInventoryResponse = await axios.get(`${currentAPI}/api/shops/${shopId}/product`);

        if (getInventoryResponse.status === 200) {
            dispatch({
                type: ADD_INVENTORY,
                payload: {
                    shopId,
                    inventory: getInventoryResponse.data,
                },
            });
            dispatch({ type: MY_SHOP_SUCCESS });
        }
    } catch (error) {
        dispatch({
            type: MY_SHOP_ERROR,
            payload: error.message,
        });
    }
};

export const bulkProductPriceUpdate = (shopId, allInventoriesUpdated) => async (dispatch, getState) => {
    dispatch({ type: LOADING_SHOP });
    if (navigator.onLine) {
        try {
            const updateProductResponse = await axios.put(
                `${currentAPI}/api/inventoryServiceIntegration/product/bulk/pricequantity`,
                JSON.stringify(allInventoriesUpdated),
            );

            if (updateProductResponse.status === 200) {
                await dispatch(getInventory(shopId));
                dispatch({ type: MY_SHOP_SUCCESS });
                dispatch(goBack());
                toast.success('Product updated successfully');
                return true;
            }
        } catch (error) {
            if (error.message === 'Network Error') {
                const findPendingBulkPriceUpdateRequest = getState()
                    .offline.pendingRequests.filter((request) => request.method === 'PUT')
                    .find(
                        (request) =>
                            request.url === `${currentAPI}/api/inventoryServiceIntegration/product/bulk/pricequantity`,
                    );

                if (findPendingBulkPriceUpdateRequest) {
                    const oldRequest = [...findPendingBulkPriceUpdateRequest.payload] || [];
                    allInventoriesUpdated.forEach((element) => {
                        const idx = oldRequest.findIndex((x) => x.productId === element.productId);
                        if (idx === -1) {
                            oldRequest.push(element);
                        } else {
                            oldRequest.splice(idx, 1, element);
                        }
                    });
                    dispatch({
                        type: UPDATE_PENDING_REQUEST,
                        payload: {
                            requestId: findPendingBulkPriceUpdateRequest.id,
                            payload: oldRequest,
                        },
                    });
                    dispatch({
                        type: BULK_PRICE_UPDATE,
                        payload: {
                            shopId,
                            payload: oldRequest,
                        },
                    });
                } else {
                    dispatch(
                        addPendingRequest(
                            'PUT',
                            `${currentAPI}/api/inventoryServiceIntegration/product/bulk/pricequantity`,
                            allInventoriesUpdated,
                        ),
                    );
                    dispatch({
                        type: BULK_PRICE_UPDATE,
                        payload: {
                            shopId,
                            payload: allInventoriesUpdated,
                        },
                    });
                }
                dispatch({ type: MY_SHOP_SUCCESS });
                dispatch(goBack());
                toast.success('Product updated successfully');
                return true;
            } else {
                dispatch({
                    type: MY_SHOP_ERROR,
                    payload: error.response.data.message,
                });
                if (error.response && error.response.status === 400) {
                    const { data } = (error && error.response) || {};
                    data.message && toast.error(error.response.data.message);
                }
                return false;
            }
        }
    } else {
        const findPendingBulkPriceUpdateRequest = getState()
            .offline.pendingRequests.filter((request) => request.method === 'PUT')
            .find(
                (request) => request.url === `${currentAPI}/api/inventoryServiceIntegration/product/bulk/pricequantity`,
            );

        if (findPendingBulkPriceUpdateRequest) {
            const oldRequest = [...findPendingBulkPriceUpdateRequest.payload] || [];
            allInventoriesUpdated.forEach((element) => {
                const idx = oldRequest.findIndex((x) => x.productId === element.productId);
                if (idx === -1) {
                    oldRequest.push(element);
                } else {
                    oldRequest.splice(idx, 1, element);
                }
            });
            dispatch({
                type: UPDATE_PENDING_REQUEST,
                payload: {
                    requestId: findPendingBulkPriceUpdateRequest.id,
                    payload: oldRequest,
                },
            });
            dispatch({
                type: BULK_PRICE_UPDATE,
                payload: {
                    shopId,
                    payload: oldRequest,
                },
            });
        } else {
            dispatch(
                addPendingRequest(
                    'PUT',
                    `${currentAPI}/api/inventoryServiceIntegration/product/bulk/pricequantity`,
                    allInventoriesUpdated,
                ),
            );
            dispatch({
                type: BULK_PRICE_UPDATE,
                payload: {
                    shopId,
                    payload: allInventoriesUpdated,
                },
            });
        }
        dispatch({ type: MY_SHOP_SUCCESS });
        dispatch(goBack());
        toast.success('Product updated successfully');
        return true;
    }
};

export const updateProduct =
    (shopId, productId, productDTO, goBack = true, showMessage = true, optimisticUpdate = false) =>
    async (dispatch, getState) => {
        dispatch({ type: LOADING_SHOP });
        optimisticUpdate &&
            dispatch({
                type: UPDATE_PRODUCT,
                payload: { shopId, productId, productDTO },
            });

        const role = getState().user.role;
        const userId = getState().user.userId;

        if (navigator.onLine) {
            try {
                const updateProductResponse = await axios.put(
                    `${currentAPI}/api/shops/${shopId}/product/${productId}`,
                    JSON.stringify(productDTO),
                );

                if (updateProductResponse.status === 200) {
                    if (productDTO.quantity) {
                        dispatch({
                            type: UPDATE_PRODUCT_QUANTITY,
                            payload: {
                                shopId,
                                productId,
                                quantity: productDTO.quantity,
                            },
                        });
                    }

                    productDTO.base64ProductImageString = updateProductResponse.data.base64ProductImageString;

                    dispatch({
                        type: UPDATE_PRODUCT,
                        payload: { shopId, productId, productDTO },
                    });

                    mixPanel.track(SHOP_UPDATE_PRODUCT, {
                        'User ID': userId,
                        Role: role,
                        'Shop ID': shopId,
                        Online: true,
                        'Product ID': productId,
                    });

                    dispatch({ type: MY_SHOP_SUCCESS });
                    goBack && dispatch(goBack());
                    showMessage && toast.success('Product updated successfully');
                    return true;
                }
            } catch (error) {
                if (error.message === 'Network Error') {
                    const findNonCreatedProductRequest = getState()
                        .offline.pendingRequests.filter((request) => request.method === 'POST')
                        .find((request) => request.payload.id === productId);

                    const findPendingUpdateRequest = getState()
                        .offline.pendingRequests.filter((request) => request.method === 'PUT')
                        .find((request) => request.payload.id === productId);

                    if (findNonCreatedProductRequest) {
                        dispatch({
                            type: UPDATE_PENDING_REQUEST,
                            payload: {
                                requestId: findNonCreatedProductRequest.id,
                                payload: productDTO,
                            },
                        });
                    } else if (findPendingUpdateRequest) {
                        dispatch({
                            type: UPDATE_PENDING_REQUEST,
                            payload: {
                                requestId: findPendingUpdateRequest.id,
                                payload: productDTO,
                            },
                        });
                    } else {
                        dispatch(
                            addPendingRequest(
                                'PUT',
                                `${currentAPI}/api/shops/${shopId}/product/${productId}`,
                                productDTO,
                            ),
                        );
                    }
                    dispatch({
                        type: UPDATE_PRODUCT,
                        payload: { shopId, productId, productDTO },
                    });
                    dispatch({ type: MY_SHOP_SUCCESS });
                    goBack && dispatch(goBack());
                    showMessage && toast.success('Product updated successfully');
                    return true;
                } else {
                    dispatch({
                        type: MY_SHOP_ERROR,
                        payload: error.response?.data?.message || '',
                    });
                    if (error.response && error.response.status === 400) {
                        const { data } = (error && error.response) || {};
                        data.message && toast.error(error.response.data.message);
                    }
                    return false;
                }
            }
        } else {
            const findNonCreatedProductRequest = getState()
                .offline.pendingRequests.filter((request) => request.method === 'POST')
                .find((request) => request.payload.id === productId);

            const findPendingUpdateRequest = getState()
                .offline.pendingRequests.filter((request) => request.method === 'PUT')
                .find((request) => request.payload.id === productId);

            if (findNonCreatedProductRequest) {
                dispatch({
                    type: UPDATE_PENDING_REQUEST,
                    payload: {
                        requestId: findNonCreatedProductRequest.id,
                        payload: productDTO,
                    },
                });
            } else if (findPendingUpdateRequest) {
                dispatch({
                    type: UPDATE_PENDING_REQUEST,
                    payload: {
                        requestId: findPendingUpdateRequest.id,
                        payload: productDTO,
                    },
                });
            } else {
                dispatch(
                    addPendingRequest('PUT', `${currentAPI}/api/shops/${shopId}/product/${productId}`, productDTO),
                );
            }
            dispatch({
                type: UPDATE_PRODUCT,
                payload: { shopId, productId, productDTO },
            });
            dispatch({ type: MY_SHOP_SUCCESS });
            goBack && dispatch(goBack());
            showMessage && toast.success('Product updated successfully');
            return true;
        }
    };

export const updateProductV2 =
    (shopId, productId, productInfo, showMessage = true) =>
    async (dispatch, getState) => {
        dispatch({ type: LOADING_SHOP });

        const role = getState().user.role;
        const userId = getState().user.userId;

        let productDTO = { ...productInfo };
        let modifiedProductDto = { ...productInfo };

        const baseImage = productInfo.base64ProductImageString;
        const otherImages = productInfo.otherImagesBase64String;

        const base64 = baseImage && baseImage.startsWith('data:image/jpeg;base64') ? baseImage : '';
        const other0 =
            otherImages && otherImages[0] && otherImages[0].startsWith('data:image/jpeg;base64') ? otherImages[0] : '';
        const other1 =
            otherImages && otherImages[1] && otherImages[1].startsWith('data:image/jpeg;base64') ? otherImages[1] : '';

        modifiedProductDto = {
            ...modifiedProductDto,
            base64ProductImageString: base64 || '',
            otherImagesBase64String: [other0, other1],
            images: {
                baseImageUrl: base64 || productDTO.images.baseImageUrl || '',
                others: [other0, other1],
            },
        };

        if (navigator.onLine) {
            try {
                const updateProductResponse = await axios.put(
                    `${currentAPI}/api/shops/${shopId}/product/${productId}`,
                    JSON.stringify(modifiedProductDto),
                );

                if (updateProductResponse.status === 200) {
                    if (productDTO.quantity) {
                        dispatch({
                            type: UPDATE_PRODUCT_QUANTITY,
                            payload: {
                                shopId,
                                productId,
                                quantity: productDTO.quantity,
                            },
                        });
                    }

                    productDTO = {
                        ...productDTO,
                        base64ProductImageString: base64 || '',
                        otherImagesBase64String: [other0, other1],
                        images: {
                            baseImageUrl: base64 || productDTO.images.baseImageUrl || '',
                            others: [other0, other1],
                        },
                    };

                    mixPanel.track(SHOP_UPDATE_PRODUCT, {
                        'User ID': userId,
                        Role: role,
                        'Shop ID': shopId,
                        Online: true,
                        'Product ID': productId,
                    });

                    dispatch({
                        type: UPDATE_PRODUCT,
                        payload: { shopId, productId, productDTO },
                    });
                    dispatch({ type: MY_SHOP_SUCCESS });
                    showMessage && toast.success('Product updated successfully');
                    return true;
                }
            } catch (error) {
                if (error.message === 'Network Error') {
                    const findNonCreatedProductRequest = getState()
                        .offline.pendingRequests.filter((request) => request.method === 'POST')
                        .find((request) => request.payload.id === productId);

                    const findPendingUpdateRequest = getState()
                        .offline.pendingRequests.filter((request) => request.method === 'PUT')
                        .find((request) => request.payload.id === productId);

                    if (findNonCreatedProductRequest) {
                        dispatch({
                            type: UPDATE_PENDING_REQUEST,
                            payload: {
                                requestId: findNonCreatedProductRequest.id,
                                payload: productDTO,
                            },
                        });
                    } else if (findPendingUpdateRequest) {
                        dispatch({
                            type: UPDATE_PENDING_REQUEST,
                            payload: {
                                requestId: findPendingUpdateRequest.id,
                                payload: modifiedProductDto,
                            },
                        });
                    } else {
                        dispatch(
                            addPendingRequest(
                                'PUT',
                                `${currentAPI}/api/shops/${shopId}/product/${productId}`,
                                modifiedProductDto,
                            ),
                        );
                    }
                    dispatch({
                        type: UPDATE_PRODUCT,
                        payload: { shopId, productId, productDTO },
                    });
                    dispatch({ type: MY_SHOP_SUCCESS });
                    showMessage && toast.success('Product updated successfully');
                    return true;
                } else {
                    dispatch({
                        type: MY_SHOP_ERROR,
                        payload: error.response?.data?.message || '',
                    });
                    if (error.response && error.response.status === 400) {
                        const { data } = (error && error.response) || {};
                        data.message && toast.error(error.response.data.message);
                    }
                    return false;
                }
            }
        } else {
            const findNonCreatedProductRequest = getState()
                .offline.pendingRequests.filter((request) => request.method === 'POST')
                .find((request) => request.payload.id === productId);

            const findPendingUpdateRequest = getState()
                .offline.pendingRequests.filter((request) => request.method === 'PUT')
                .find((request) => request.payload.id === productId);

            if (findNonCreatedProductRequest) {
                dispatch({
                    type: UPDATE_PENDING_REQUEST,
                    payload: {
                        requestId: findNonCreatedProductRequest.id,
                        payload: productDTO,
                    },
                });
            } else if (findPendingUpdateRequest) {
                dispatch({
                    type: UPDATE_PENDING_REQUEST,
                    payload: {
                        requestId: findPendingUpdateRequest.id,
                        payload: modifiedProductDto,
                    },
                });
            } else {
                dispatch(
                    addPendingRequest(
                        'PUT',
                        `${currentAPI}/api/shops/${shopId}/product/${productId}`,
                        modifiedProductDto,
                    ),
                );
            }
            dispatch({
                type: UPDATE_PRODUCT,
                payload: { shopId, productId, productDTO },
            });
            dispatch({ type: MY_SHOP_SUCCESS });
            showMessage && toast.success('Product updated successfully');
            return true;
        }
    };

export const deleteProduct =
    (shopId, productId, shdGoBack = true) =>
    async (dispatch, getState) => {
        dispatch({ type: LOADING_SHOP });

        const role = getState().user.role;
        const userId = getState().user.userId;

        if (navigator.onLine) {
            try {
                const deleteProductResponse = await axios.delete(
                    `${currentAPI}/api/shops/${shopId}/product/${productId}`,
                );

                if (deleteProductResponse.status === 200 || deleteProductResponse.status === 202) {
                    dispatch({
                        type: DELETE_PRODUCT,
                        payload: { shopId, productId },
                    });
                    mixPanel.track(SHOP_DELETE_PRODUCT, {
                        'User ID': userId,
                        Role: role,
                        'Shop ID': shopId,
                        Online: true,
                        'Product ID': productId,
                    });
                    dispatch({ type: MY_SHOP_SUCCESS });
                    shdGoBack && dispatch(goBack());
                    toast.success('Product was deleted successfully');
                    return true;
                }
            } catch (error) {
                if (error.message === 'Network Error') {
                    const pendingRequests = getState().offline.pendingRequests;
                    const pendingProductRequest = pendingRequests.find(
                        (request) =>
                            request.payload.id === productId || productId === new URL(request.url).href.split('/')[5],
                    );

                    if (pendingProductRequest) {
                        dispatch({
                            type: REMOVE_PENDING_REQUEST,
                            payload: { id: pendingProductRequest.id },
                        });
                    } else {
                        dispatch(addPendingRequest('DELETE', `${currentAPI}/api/shops/${shopId}/product/${productId}`));
                    }

                    dispatch({
                        type: DELETE_PRODUCT,
                        payload: { shopId, productId },
                    });
                    dispatch({ type: MY_SHOP_SUCCESS });
                    shdGoBack && dispatch(goBack());
                    toast.success('Product was deleted successfully');
                    return true;
                } else {
                    dispatch({
                        type: MY_SHOP_ERROR,
                        payload: error.response?.data?.message || '',
                    });
                    if (error.response && error.response.status === 400) {
                        const { data } = (error && error.response) || {};
                        data.message && toast.error(error.response.data.message);
                    }
                    return false;
                }
            }
        } else {
            const pendingRequests = getState().offline.pendingRequests;
            const pendingProductRequest = pendingRequests.find(
                (request) => request.payload.id === productId || productId === new URL(request.url).href.split('/')[5],
            );

            if (pendingProductRequest) {
                dispatch({
                    type: REMOVE_PENDING_REQUEST,
                    payload: { id: pendingProductRequest.id },
                });
            } else {
                dispatch(addPendingRequest('DELETE', `${currentAPI}/api/shops/${shopId}/product/${productId}`));
            }

            dispatch({ type: DELETE_PRODUCT, payload: { shopId, productId } });
            dispatch({ type: MY_SHOP_SUCCESS });
            shdGoBack && dispatch(goBack());
            toast.success('Product was deleted successfully');
            return true;
        }
    };

export const updateProductQuantity =
    (shopId, productId, quantity, qtyChng, updateType, productName) => async (dispatch, getState) => {
        dispatch({ type: LOADING_SHOP });

        if (navigator.onLine) {
            try {
                const updateQuantityResponse = await axios.put(
                    `${currentAPI}/api/shops/${shopId}/product/${productId}/quantity`,
                    JSON.stringify({ quantity }),
                );

                if (updateQuantityResponse.status === 200) {
                    mixPanel.track(SHOP_UPDATE_PRODUCT_QUANTITY, {
                        'Product Name': productName,
                        'Product ID': productId,
                        'Quantity Change': qtyChng,
                        'Update Type': updateType,
                    });
                    dispatch({
                        type: UPDATE_PRODUCT_QUANTITY,
                        payload: { shopId, productId, quantity },
                    });
                    dispatch({ type: MY_SHOP_SUCCESS });
                }
            } catch (error) {
                if (error.message === 'Network Error') {
                    const findNonCreatedProductRequest = getState()
                        .offline.pendingRequests.filter((request) => request.method === 'POST')
                        .find((request) => request.payload.id === productId);

                    const findPendingUpdateRequest = getState()
                        .offline.pendingRequests.filter((request) => request.method === 'PUT')
                        .find((request) => request.payload.id === productId);

                    const findPendingQuantityRequest = getState()
                        .offline.pendingRequests.filter((request) => request.method === 'PUT')
                        .find((request) => productId === new URL(request.url).pathname.split('/')[5]);

                    if (findNonCreatedProductRequest) {
                        const pendingProduct = getState()
                            .applications.myShop.shops.find((shop) => shop.id === shopId)
                            .inventory.find((product) => product.id === productId);

                        dispatch({
                            type: UPDATE_PENDING_REQUEST,
                            payload: {
                                requestId: findNonCreatedProductRequest.id,
                                payload: { ...pendingProduct, quantity },
                            },
                        });
                    } else if (findPendingUpdateRequest) {
                        const pendingProduct = getState()
                            .applications.myShop.shops.find((shop) => shop.id === shopId)
                            .inventory.find((product) => product.id === productId);

                        dispatch({
                            type: UPDATE_PENDING_REQUEST,
                            payload: {
                                requestId: findPendingUpdateRequest.id,
                                payload: { ...pendingProduct, quantity },
                            },
                        });
                    } else if (findPendingQuantityRequest) {
                        dispatch({
                            type: UPDATE_PENDING_REQUEST,
                            payload: {
                                requestId: findPendingQuantityRequest.id,
                                payload: { quantity },
                            },
                        });
                    } else {
                        dispatch(
                            addPendingRequest(
                                'PUT',
                                `${currentAPI}/api/shops/${shopId}/product/${productId}/quantity`,
                                { quantity },
                            ),
                        );
                    }

                    dispatch({
                        type: UPDATE_PRODUCT_QUANTITY,
                        payload: { shopId, productId, quantity },
                    });
                    dispatch({ type: MY_SHOP_SUCCESS });
                } else {
                    dispatch({
                        type: MY_SHOP_ERROR,
                        payload: error.response?.data?.message || '',
                    });
                    if (error.response && error.response.status === 400) {
                        const { data } = (error && error.response) || {};
                        data.message && toast.error(error.response.data.message);
                    }
                }
            }
        } else {
            const findNonCreatedProductRequest = getState()
                .offline.pendingRequests.filter((request) => request.method === 'POST')
                .find((request) => request.payload.id === productId);

            const findPendingUpdateRequest = getState()
                .offline.pendingRequests.filter((request) => request.method === 'PUT')
                .find((request) => request.payload.id === productId);

            const findPendingQuantityRequest = getState()
                .offline.pendingRequests.filter((request) => request.method === 'PUT')
                .find((request) => productId === new URL(request.url).pathname.split('/')[5]);

            if (findNonCreatedProductRequest) {
                const pendingProduct = getState()
                    .applications.myShop.shops.find((shop) => shop.id === shopId)
                    .inventory.find((product) => product.id === productId);

                dispatch({
                    type: UPDATE_PENDING_REQUEST,
                    payload: {
                        requestId: findNonCreatedProductRequest.id,
                        payload: { ...pendingProduct, quantity },
                    },
                });
            } else if (findPendingUpdateRequest) {
                const pendingProduct = getState()
                    .applications.myShop.shops.find((shop) => shop.id === shopId)
                    .inventory.find((product) => product.id === productId);

                dispatch({
                    type: UPDATE_PENDING_REQUEST,
                    payload: {
                        requestId: findPendingUpdateRequest.id,
                        payload: { ...pendingProduct, quantity },
                    },
                });
            } else if (findPendingQuantityRequest) {
                dispatch({
                    type: UPDATE_PENDING_REQUEST,
                    payload: {
                        requestId: findPendingQuantityRequest.id,
                        payload: { quantity },
                    },
                });
            } else {
                dispatch(
                    addPendingRequest('PUT', `${currentAPI}/api/shops/${shopId}/product/${productId}/quantity`, {
                        quantity,
                    }),
                );
            }

            dispatch({
                type: UPDATE_PRODUCT_QUANTITY,
                payload: { shopId, productId, quantity },
            });
            dispatch({ type: MY_SHOP_SUCCESS });
        }
    };

export const updateProductData =
    (shopId, previousId, currentId, previousInventoryProductId, currentInventoryProductId, currentImage) =>
    (dispatch) => {
        dispatch({
            type: UPDATE_PRODUCT_ID,
            payload: {
                shopId,
                previousId,
                currentId,
            },
        });

        dispatch({
            type: UPDATE_INVENTORY_PRODUCT_ID,
            payload: {
                shopId,
                previousInventoryProductId,
                currentInventoryProductId,
            },
        });

        dispatch({
            type: UPDATE_PENDING_SALE_INVENTORY_PRODUCT,
            payload: {
                previousInventoryProductId,
                currentProductId: currentId,
                currentInventoryProductId,
            },
        });

        dispatch({
            type: UPDATE_PRODUCT_IMAGE,
            payload: {
                shopId,
                currentId,
                currentImage,
            },
        });
    };

export const createAgentShop = (shopInfo, setErrors, setNetworkError) => async (dispatch, getState) => {
    if (!navigator.onLine) {
        toast.error('You can not perform this action offline...');
        return;
    }

    dispatch({ type: LOADING_SHOP });

    try {
        const ShopDTO = { ...shopInfo };

        const createShopResponse = await axios.post(
            `${currentAPI}/api/shops/create/agent/shop`,
            JSON.stringify(ShopDTO),
        );

        if (createShopResponse.status === 200) {
            ShopDTO.id = createShopResponse.data.id;
            ShopDTO.merchantId = createShopResponse.data.merchantId;

            const role = getState().user.role;
            const userId = getState().user.userId;
            mixPanel.track(SHOP_CREATE_SHOP, {
                'User ID': userId,
                Role: role === 'ROLE_USER' ? 'Merchant' : 'Agent',
                'Shop ID': ShopDTO.id,
                'Business categories': ShopDTO.businessCategories,
                LGA: ShopDTO.localGovt,
                State: ShopDTO.state,
            });
            dispatch(getShops());
            dispatch({ type: SAVE_SHOP, payload: ShopDTO });
            await dispatch(getAgentActivationStatus());
            dispatch(push('/'));
            dispatch({ type: MY_SHOP_SUCCESS });
            toast.success(`${shopInfo.name} was successfully created`);
        }
    } catch (error) {
        dispatch({ type: MY_SHOP_ERROR, payload: error.message });
        if (error.message === 'Network Error') {
            setNetworkError(true);
        } else if (error.response && error.response.status === 409) {
            setErrors({ shopName: 'Shop name is already taken' });
            toast.error('Shop name is already taken');
        }
    }
};

export const getCategories = () => async (dispatch) => {
    if (!navigator.onLine) return;

    try {
        const getCategoriesResponse = await axios.get(
            `${currentAPI}/api/merchantAppIntegration/public/categories/business/all`,
        );

        if (getCategoriesResponse.status === 200) {
            dispatch({
                type: MY_SHOP_CATEGORIES,
                payload: getCategoriesResponse.data,
            });
        }
    } catch (error) {
        console.error(error);
    }
};

export const searchProductsOnMasterList = (branchId, name) => async (dispatch) => {
    if (!navigator.onLine) return;

    try {
        const MasterList = await axios.get(
            `${currentAPI}/api/inventoryServiceIntegration/masterlist/search-product/${name}?branchId=${branchId}`,
        );
        if (MasterList.status === 200) {
            dispatch({
                type: FETCH_PRODUCTS_MASTER_LIST,
                payload: MasterList.data.data.slice(0, 10),
            });
        }
    } catch (error) {
        dispatch({ type: NO_PRODUCTS_ON_MASTER_LIST, payload: error.message });
    }
};

export const clearProductsOnMasterList = () => async (dispatch) => {
    dispatch({
        type: FETCH_PRODUCTS_MASTER_LIST,
        payload: [],
    });
};

export const getProductCategories = () => async (dispatch) => {
    if (!navigator.onLine) return;

    try {
        const getCategoriesResponse = await axios.get(
            `${currentAPI}/api/merchantBuyIntegration/branches/all/category?page=1&size=10`,
        );
        if (getCategoriesResponse.status === 200) {
            dispatch({
                type: MY_SHOP_PRODUCT_CATEGORIES,
                payload: getCategoriesResponse.data.data,
            });
        }
    } catch (error) {
        console.error(error);
    }
};

export const verifyStoreLocation = (shopId, params) => async () => {
    try {
        const verifyStoreResponse = await axios.post(
            `${currentAPI}/api/shops/${shopId}/verifyLocation`,
            JSON.stringify(params),
        );
        if (verifyStoreResponse.status === 200 || verifyStoreResponse.status === 201) {
            return true;
        }
    } catch (error) {
        if (error.response && error.response.status === 400) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.message);
        }
        return false;
    }
};
