import React, { Fragment, useState, useEffect } from 'react';

import { Formik, Form } from 'formik';
import { string, func, bool } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Loader, TopHeader, RippleButton, SelectBox, TextInputWithLabel } from '../../../../../../components';
import { Close } from '../../../../../../containers/HeaderContainer';
import { InputBlock } from '../../../../../../containers/InputContainer';
import { ScreenContainer } from '../../../../../../containers/ScreenContainer';
import { flags } from '../../../../../../data/countries';
import { merchbuyActions } from '../../../../../../redux/ducks/applications/merchbuy/actions';
import * as actions from '../../../../../../redux/ducks/applications/my-shop/actions/customers';
import { toTitleCase, upperCaseToTitleCase } from '../../../../../../utils/toTitleCase';
import ConstantInfo from '../../../assets/constant-info.svg';
import { ReactComponent as ShopIcon } from '../../../assets/shop_icon_blue.svg';
import { Space } from '../../../styles';
import SimpleMap from '../../google-map/simpleMap';

import { UpdateDeliveryDetailsValidationSchema } from './UpdateDeliveryDetailsValidationSchema';

const Padding = styled.div`
    padding: 0 1em;
`;
const Container = styled.div`
    background-color: #f9fafc;
    width: 343px;
    height: ${({ height }) => height || '204px'};
    border-radius: 4px;
    border: 1px solid #e2e9f0;
    margin-top: 8px;
`;
const InLineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: ${({ width }) => width || null};
`;
const InfoText = styled.div`
    font-size: 10px;
    color: #5c6f7f;
    font-weight: 400;
    margin: 4px 0 6px 5px;
`;

const MiniTitle = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #7a7978;
    margin-top: 12px;
    padding-left: 16px;
    width: ${({ width }) => width || null};
`;
const MiniRight = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #7a7978;
    width: ${({ width }) => width || null};
    margin: ${({ margin }) => margin || '4px 0 0 100px'};
    padding-left: 16px;
`;

const MiniContent = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #071827;
    width: ${({ width }) => width || null};
    margin-top: 4px;
    padding-left: 16px;
`;
const MiniContentRight = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #071827;
    margin: ${({ margin }) => margin || '4px 0 0 100px'};
    width: ${({ width }) => width || null};
    padding-left: 16px;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 14px;
    color: #071827;
    padding-left: ${({ left }) => left || null};
    margin-bottom: ${({ bottom }) => bottom || null};
`;

const InnerTitle = styled.div`
    font-weight: 700;
    font-size: 12px;
    color: ${({ color }) => color || '#071827'};
    margin: 8px 0 5px 16px;
`;

const FullAddressHolder = styled.div`
    background: #ffffff;
    border: 1px solid #edf2f7;
    box-sizing: border-box;
    box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 12px;
    margin: 16px;
`;

const ShopAddressInfo = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #718596;
    margin-left: ${({ left }) => left || '0px'};
`;

const Divider = styled.div`
    border: 1px solid #edf2f7;
    margin: 8px 0;
`;

const ShopAddressWrapper = styled.div`
    display: flex;
`;

const CountryFlag = styled.img`
    width: 24px;
    height: 24px;
    margin: 4px 0 0 19px;
`;

const checkRequiredFields = (address, lga, phoneNumber, state, city) => {
    return !address || !lga || !phoneNumber || !state;
};

export const NewEditDeliveryAddressDetails = ({
    open,
    cancel,
    deliveryLocation,
    getDeliveryDetails,
    isEditingContactAddress,
    handleSelectedContactAddress,
    setSelectedDeliveryInformation,
    setShowNewEditPopup,
    close,
    selectedContact,
}) => {
    const dispatch = useDispatch();
    const shopInfo = useSelector((state) => state.applications.myShop.shops[0]);

    const user = useSelector((state) => state.user);
    const [cities, setCities] = useState([]);
    const [selectedLga, setSelectedLga] = useState('');
    const [lgaChanged, setLgaChanged] = useState(false);
    const isLoading = useSelector((state) => state.applications.myShop.isLoading);

    const AgentDeliveryLocation =
        localStorage.getItem('deliveryLocation') && JSON.parse(localStorage.getItem('deliveryLocation'));
    const { state: AgentdeliveryState } = AgentDeliveryLocation || {};

    const { businessPhoneNumber } = shopInfo || {};
    const listOfAvailableStatesAndLga = useSelector((state) => state.applications.merchbuy.availableStates);

    const lgas = useSelector((state) => state.applications.merchbuy.lga);
    const [isSelected, setIsSelected] = useState(false);
    const [coordinates, setCoordinates] = useState(deliveryLocation?.cordinates || { longitude: 0, latitude: 0 });
    // const deliveryAddressEditLimit = useSelector((state) => state.applications.merchbuy.deliveryAddressEditLimit);

    const checkDeliveryLocation = (state) => String(AgentdeliveryState).toLowerCase() === String(state).toLowerCase();

    const getCities = async () => {
        if (lgaChanged) {
            const response = await dispatch(merchbuyActions.getAllCities(selectedContact?.state, selectedLga));
            response && setCities(response?.data?.content);
        } else {
            const res = await dispatch(
                merchbuyActions.getAllCities(selectedContact?.state, selectedContact?.lga || deliveryLocation?.lga),
            );
            res && setCities(res?.data?.content);
        }
    };

    const editedPhoneNumber = [
        selectedContact?.phoneNumber?.slice(0, 4),
        ' ',
        selectedContact?.phoneNumber?.slice(4, 7),
        ' ',
        selectedContact?.phoneNumber?.slice(7) || deliveryLocation?.businessPhoneNumber?.slice(0, 4),
        ' ',
        deliveryLocation?.businessPhoneNumber?.slice(4, 7),
        ' ',
        deliveryLocation?.businessPhoneNumber?.slice(7),
    ].join('');

    useEffect(() => {
        getCities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectSelfAddress = (userInfo) => {
        const { address, lga, phoneNumber, state, city, shopName } = userInfo || deliveryLocation || {};

        const isRequiredDetailsField = checkRequiredFields(address, lga, phoneNumber, state, city, shopName);

        const info = {
            ...deliveryLocation,
            contactType: 'SELF',
        };

        if (!checkDeliveryLocation(state)) {
            return toast.error('State does not match shopping location, update state to your shopping location');
        }

        if (isRequiredDetailsField || !checkDeliveryLocation(state)) {
            setShowNewEditPopup && setShowNewEditPopup(true);
            setSelectedDeliveryInformation && setSelectedDeliveryInformation(info);
        } else {
            setSelectedDeliveryInformation && setSelectedDeliveryInformation(info);
        }
        close();
        getDeliveryDetails(info);
    };

    const onSubmit = (values) => {
        let payload = {
            ...values,
            deliveryInstructions: deliveryLocation?.deliveryInstruction,
            city: values.city,
            cordinates: {
                longitude: coordinates?.longitude?.toString() || '0',
                latitude: coordinates?.latitude?.toString() || '0',
            },
        };
        const subPayload = {
            ...values,
            city: values.city,
            deliveryInstructions: deliveryLocation?.deliveryInstruction,
            cordinates: {
                longitude: coordinates?.longitude.toString() || '0',
                latitude: coordinates?.latitude.toString() || '0',
            },
        };
        if (!deliveryLocation?.shopName) {
            payload = {
                ...values,
                deliveryInstructions: values.deliveryInstruction,
                agentCustomer: {
                    city: selectedContact?.city || values.city,
                    state: selectedContact?.state,
                    cordinates: coordinates,
                    customerId: selectedContact?.id || deliveryLocation?.userId,
                    deliveryInstructions: values.deliveryInstruction,
                    firstName: selectedContact?.firstName || deliveryLocation?.firstName,
                    lastName: selectedContact?.lastName || deliveryLocation?.lastName,
                    lga: values.lga,
                    middleName: '',
                    shopName: values.shopName,
                    phoneNumber: selectedContact?.phoneNumber || deliveryLocation?.businessPhoneNumber,
                    shopAddress: values.shopAddress,
                    shopNumber: values.shopNumber,
                    businessPhoneNumber: selectedContact?.phoneNumber || deliveryLocation?.businessPhoneNumber,
                },
                businessCategories: [],
            };
            dispatch(merchbuyActions.createAccountForMerchant(payload));
        }
        dispatch(merchbuyActions.createAccountForMerchant(payload));
        dispatch(actions.editSabiCustomers(payload, deliveryLocation?.userId || selectedContact?.id));

        if (deliveryLocation && deliveryLocation?.contactType === 'SELF') {
            payload = {
                customerId: selectedContact?.id || deliveryLocation?.userId,
                firstName: selectedContact?.firstName || deliveryLocation?.firstName,
                lastName: selectedContact?.lastName || deliveryLocation?.lastName,
                lga: values?.lga,
                middleName: '',
                phoneNumber: selectedContact?.phoneNumber || deliveryLocation?.businessPhoneNumber,
                shopAddress: values?.shopAddress,
                shopName: values?.shopName || deliveryLocation?.shopName || selectedContact.shopName,
                shopNumber: values?.shopNumber,
                state: values?.state,
            };
            handleSelectSelfAddress(payload);
            return dispatch(actions.updateMerchantDelivery(user?.userId, payload, deliveryLocation?.contactType));
        } else if (deliveryLocation?.contactType === 'CUSTOMER') {
            handleSelectedContactAddress(subPayload);
            return dispatch(actions.editSabiCustomers(subPayload, deliveryLocation?.userId || selectedContact?.id));
        } else {
            handleSelectedContactAddress(subPayload);
            return dispatch(actions.addSabiCustomers(subPayload));
        }
    };

    const handleSubmit = (values, setErrors) => {
        setTimeout(() => {
            if (businessPhoneNumber) {
                const isUserPhoneNumber = businessPhoneNumber === values.msisdn;
                if (
                    (deliveryLocation && deliveryLocation.contactType !== 'SELF' && isUserPhoneNumber) ||
                    (!deliveryLocation && isUserPhoneNumber)
                ) {
                    return setErrors({
                        msisdn: 'Sorry, you cannot use your number for a customer!',
                    });
                }
            }
            onSubmit(values);
            getDeliveryDetails(values);
            cancel();
        }, 200);
    };

    const initialFormValues = {
        middleName: '',
        country: 'NG',
        firstName: deliveryLocation?.firstName || selectedContact?.firstName || user?.firstName || '',
        lastName: deliveryLocation?.lastName || selectedContact?.lastName || user?.lastName || '',
        state: AgentdeliveryState || selectedContact?.state || '',
        lga:
            AgentdeliveryState || ''
                ? deliveryLocation?.lga || deliveryLocation?.localGovt || selectedContact?.lga || ''
                : '',
        city:
            AgentdeliveryState === selectedContact?.state || deliveryLocation?.state
                ? selectedContact?.city || deliveryLocation?.city || deliveryLocation?.city
                : '',
        customerId: selectedContact?.id || deliveryLocation?.userId || deliveryLocation?.customerId || '',
        businessPhoneNumber: selectedContact?.phoneNumber || deliveryLocation?.businessPhoneNumber,
        msisdn: selectedContact?.phoneNumber || deliveryLocation?.businessPhoneNumber || businessPhoneNumber || '',
        phoneNumber: selectedContact?.phoneNumber || deliveryLocation?.businessPhoneNumber || '',
        shopAddress:
            selectedContact?.shopAddress || deliveryLocation?.shopAddress || deliveryLocation?.streetAddress || '',
        shopNumber: selectedContact?.shopNumber || deliveryLocation?.shopNumber || '',
        shopName: '' || deliveryLocation?.shopName,
    };

    return isLoading ? (
        <Loader />
    ) : (
        open && (
            <Fragment>
                {!isSelected && (
                    <TopHeader title={'Edit Merchant Information'} noArrow noBorderBottom withSpacesHeader>
                        <Close left={'16px'} onClick={cancel} />
                    </TopHeader>
                )}
                <ScreenContainer top="0" padding="0">
                    {!isSelected && (
                        <Fragment>
                            <Space height="71px" />
                        </Fragment>
                    )}

                    {!deliveryLocation.shopName ? (
                        <Fragment>
                            {!isSelected && (
                                <Fragment>
                                    <Padding>
                                        <Title>Merchant’s Information</Title>
                                        <Container height="141px">
                                            <InLineContainer width="100%">
                                                <MiniTitle width="50%">First Name</MiniTitle>
                                                <MiniRight width="50%" margin="2% 0 0 7%">
                                                    Last Name
                                                </MiniRight>
                                            </InLineContainer>
                                            <InLineContainer width="100%">
                                                <MiniContent width="50%">
                                                    {deliveryLocation?.firstName || selectedContact?.firstName}
                                                </MiniContent>
                                                <MiniContentRight width="50%" margin="2% 0 0 7%">
                                                    {deliveryLocation?.lastName || selectedContact.lastName}
                                                </MiniContentRight>
                                            </InLineContainer>
                                            <MiniTitle>Phone Number</MiniTitle>
                                            <InLineContainer>
                                                <CountryFlag
                                                    src={
                                                        flags.filter((flag) => flag?.customAbbreviation === 'NG')[0]
                                                            ?.value
                                                    }
                                                    alt={
                                                        flags.filter((flag) => flag.customAbbreviation === 'NG')[0]
                                                            .value
                                                    }
                                                />
                                                <MiniContent>{editedPhoneNumber}</MiniContent>
                                            </InLineContainer>
                                        </Container>
                                        <div
                                            style={{
                                                border: '0.5px solid #EDF2F7',
                                                margin: '24px 0px',
                                                width: '341px',
                                            }}
                                        />
                                    </Padding>
                                    <Padding></Padding>
                                </Fragment>
                            )}
                            <Formik
                                initialValues={initialFormValues}
                                validationSchema={UpdateDeliveryDetailsValidationSchema}
                                onSubmit={(values, { setErrors }) => {}}
                            >
                                {({
                                    errors,
                                    touched,
                                    values,
                                    handleChange,
                                    setFieldValue,
                                    initialValues,
                                    setErrors,
                                }) => {
                                    return (
                                        <Form>
                                            <InputBlock>
                                                <Title left="16px" bottom="16px">
                                                    Update Merchant Information
                                                </Title>
                                                <Padding>
                                                    <TextInputWithLabel
                                                        label={'Shop Name'}
                                                        width="343px"
                                                        height="56px"
                                                        type={'text'}
                                                        value={values.shopName}
                                                        placeholder={'Shop Name'}
                                                        bottom="24px"
                                                        top="16px"
                                                        name="shopName"
                                                        valid={`${touched.shopName && !errors.shopName}`}
                                                        errors={
                                                            touched && touched.shopName && errors && errors.shopName
                                                        }
                                                        setFieldValue={setFieldValue}
                                                        initialValues={initialValues}
                                                    />
                                                </Padding>
                                                <Title left="16px">{'Update Customer Address'}</Title>
                                                <Space height="16px" />
                                                <InnerTitle color={'#718596'}>ENTER ADDRESS DETAILS</InnerTitle>
                                                <Padding>
                                                    <SelectBox
                                                        placeholder={'State'}
                                                        value={upperCaseToTitleCase(values.state)}
                                                        top="0px"
                                                        title="Select State"
                                                        height="90%"
                                                        disabled
                                                        isSelected={isSelected}
                                                        setIsSelected={setIsSelected}
                                                        options={listOfAvailableStatesAndLga}
                                                        handleChange={(selected) => {
                                                            setFieldValue('state', selected.label);
                                                            setFieldValue('lga', '');
                                                            setFieldValue('city', '');
                                                        }}
                                                        valid={`${!touched.state && !errors.state}`}
                                                        errors={touched && touched.state && errors && errors.state}
                                                    />
                                                    <InLineContainer>
                                                        <img
                                                            src={ConstantInfo}
                                                            alt=""
                                                            style={{ margin: '4px 0 6px 16px' }}
                                                            width="14px"
                                                            height="14px"
                                                        />
                                                        <InfoText>
                                                            Delivery state is fixed to your current shopping location
                                                        </InfoText>
                                                    </InLineContainer>
                                                </Padding>
                                                <Padding>
                                                    <SelectBox
                                                        placeholder={'Local government'}
                                                        value={values.lga}
                                                        top="8px"
                                                        title="Select L.G.A"
                                                        height="90%"
                                                        isSelected={isSelected}
                                                        setIsSelected={setIsSelected}
                                                        options={lgas}
                                                        handleChange={(selected) => {
                                                            setFieldValue('lga', selected.name);
                                                            setSelectedLga(selected.name);
                                                            setLgaChanged(true);
                                                        }}
                                                        valid={`${!touched.lga && !errors.lga}`}
                                                        errors={touched && touched.lga && errors && errors.lga}
                                                    />
                                                </Padding>
                                                <Padding>
                                                    <SelectBox
                                                        name={'city'}
                                                        placeholder={'Select City'}
                                                        value={upperCaseToTitleCase(values.city)}
                                                        top="8px"
                                                        height="90%"
                                                        title="Select City"
                                                        isSelected={isSelected}
                                                        setIsSelected={setIsSelected}
                                                        options={cities}
                                                        handleChange={handleChange}
                                                        valid={`${!touched.city && !errors.city}`}
                                                        errors={touched && touched.city && errors && errors.city}
                                                    />
                                                </Padding>
                                                <Padding style={{ marginTop: '8px' }}>
                                                    <TextInputWithLabel
                                                        label={'Street Address'}
                                                        type={'text'}
                                                        value={toTitleCase(values.shopAddress)}
                                                        placeholder={'Street Address'}
                                                        bottom="8px"
                                                        minHeight={'0px'}
                                                        name="shopAddress"
                                                        valid={`${touched.shopAddress && !errors.shopAddress}`}
                                                        errors={
                                                            touched &&
                                                            touched.shopAddress &&
                                                            errors &&
                                                            errors.shopAddress
                                                        }
                                                        setFieldValue={setFieldValue}
                                                        initialValues={initialValues}
                                                    />
                                                </Padding>

                                                <FullAddressHolder>
                                                    <ShopAddressWrapper>
                                                        <ShopIcon />
                                                        <ShopAddressInfo left="6px">Your Shop address</ShopAddressInfo>
                                                    </ShopAddressWrapper>
                                                    <Divider />
                                                    <ShopAddressInfo>
                                                        {`
                                                    ${values?.shopNumber}
                                                    ${`${upperCaseToTitleCase(values?.shopAddress)} `}
                                                    ${`${values?.city.toLowerCase()}, `}
                                                    ${`${values?.lga.toLowerCase()} LGA,`}
                                                    ${`${values?.state} sta. `}
                                         `}
                                                    </ShopAddressInfo>
                                                </FullAddressHolder>
                                                <InnerTitle>Drag the marker to the exact location</InnerTitle>
                                                <Padding>
                                                    <SimpleMap
                                                        coordinates={coordinates}
                                                        setCoordinates={setCoordinates}
                                                        address={`${values.shopNumber} ${values.shopAddress} ${values.city} ${values.lga} ${values.state}`}
                                                    />
                                                </Padding>
                                                <Padding>
                                                    <RippleButton
                                                        onClick={() => handleSubmit(values, setErrors)}
                                                        height={'56px'}
                                                        size={'16px'}
                                                    >
                                                        Update
                                                    </RippleButton>
                                                </Padding>
                                            </InputBlock>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </Fragment>
                    ) : (
                        <Fragment>
                            {!isSelected && (
                                <Fragment>
                                    <Padding>
                                        <Title>Merchant’s Information</Title>
                                        <Container height="204px">
                                            <MiniTitle width="50%">Shop Name</MiniTitle>
                                            <MiniContent width="50%">
                                                {toTitleCase(deliveryLocation?.shopName)}
                                            </MiniContent>
                                            <InLineContainer width="100%">
                                                <MiniTitle width="50%">First Name</MiniTitle>
                                                <MiniRight width="50%" margin="2% 0 0 10%">
                                                    Last Name
                                                </MiniRight>
                                            </InLineContainer>
                                            <InLineContainer width="100%">
                                                <MiniContent width="50%">
                                                    {toTitleCase(deliveryLocation?.firstName)}
                                                </MiniContent>
                                                <MiniContentRight width="50%" margin="2% 0 0 10%">
                                                    {toTitleCase(deliveryLocation?.lastName)}
                                                </MiniContentRight>
                                            </InLineContainer>
                                            <MiniTitle>Phone Number</MiniTitle>
                                            <InLineContainer>
                                                <CountryFlag
                                                    src={
                                                        flags.filter((flag) => flag?.customAbbreviation === 'NG')[0]
                                                            ?.value
                                                    }
                                                    alt={
                                                        flags.filter((flag) => flag.customAbbreviation === 'NG')[0]
                                                            .value
                                                    }
                                                />
                                                <MiniContent>{editedPhoneNumber}</MiniContent>
                                            </InLineContainer>
                                        </Container>
                                        <div
                                            style={{
                                                border: '0.5px solid #EDF2F7',
                                                margin: '24px 0px',
                                                width: '341px',
                                            }}
                                        />
                                    </Padding>
                                    <Padding></Padding>
                                </Fragment>
                            )}
                            <Formik
                                initialValues={initialFormValues}
                                validationSchema={UpdateDeliveryDetailsValidationSchema}
                                onSubmit={(values, { setErrors }) => {}}
                            >
                                {({
                                    errors,
                                    touched,
                                    values,
                                    handleChange,
                                    setFieldValue,
                                    initialValues,
                                    setErrors,
                                }) => {
                                    return (
                                        <Form>
                                            <InputBlock>
                                                <Title left="16px">{'Update Customer Address'}</Title>
                                                <Space height="16px" />
                                                <InnerTitle color={'#718596'}>ENTER ADDRESS DETAILS</InnerTitle>
                                                <Padding>
                                                    <TextInputWithLabel
                                                        placeholder={'State'}
                                                        value={upperCaseToTitleCase(values.state)}
                                                        top="0px"
                                                        title="Select State"
                                                        height="90%"
                                                        disabled
                                                        isSelected={isSelected}
                                                        setIsSelected={setIsSelected}
                                                        options={listOfAvailableStatesAndLga}
                                                        valid={`${!touched.state && !errors.state}`}
                                                        errors={touched && touched.state && errors && errors.state}
                                                    />
                                                    <InLineContainer>
                                                        <img
                                                            src={ConstantInfo}
                                                            alt=""
                                                            style={{ margin: '4px 0 0 16px' }}
                                                        />
                                                        <InfoText>
                                                            Delivery state is fixed to your current shopping location
                                                        </InfoText>
                                                    </InLineContainer>
                                                </Padding>
                                                <Padding>
                                                    <SelectBox
                                                        placeholder={'Local government'}
                                                        value={values.lga}
                                                        top="8px"
                                                        title="Select L.G.A"
                                                        height="90%"
                                                        isSelected={isSelected}
                                                        setIsSelected={setIsSelected}
                                                        options={lgas}
                                                        handleChange={(selected) => {
                                                            setFieldValue('lga', selected.name);
                                                            setSelectedLga(selected.name);
                                                            setLgaChanged(true);
                                                        }}
                                                        valid={`${!touched.lga && !errors.lga}`}
                                                        errors={touched && touched.lga && errors && errors.lga}
                                                    />
                                                </Padding>
                                                <Padding>
                                                    <SelectBox
                                                        name={'city'}
                                                        placeholder={'Select City'}
                                                        value={upperCaseToTitleCase(values.city)}
                                                        top="8px"
                                                        height="90%"
                                                        title="Select City"
                                                        isSelected={isSelected}
                                                        setIsSelected={setIsSelected}
                                                        options={cities}
                                                        handleChange={handleChange}
                                                        valid={`${!touched.city && !errors.city}`}
                                                        errors={touched && touched.city && errors && errors.city}
                                                    />
                                                </Padding>
                                                <Padding style={{ marginTop: '8px' }}>
                                                    <TextInputWithLabel
                                                        label={'Street Address'}
                                                        type={'text'}
                                                        value={toTitleCase(values.shopAddress)}
                                                        placeholder={'Street Address'}
                                                        bottom="8px"
                                                        top="8px"
                                                        name="shopAddress"
                                                        valid={`${touched.shopAddress && !errors.shopAddress}`}
                                                        errors={
                                                            touched &&
                                                            touched.shopAddress &&
                                                            errors &&
                                                            errors.shopAddress
                                                        }
                                                        setFieldValue={setFieldValue}
                                                        initialValues={initialValues}
                                                    />
                                                </Padding>

                                                <FullAddressHolder>
                                                    <ShopAddressWrapper>
                                                        <ShopIcon />
                                                        <ShopAddressInfo left="6px">Your Shop address</ShopAddressInfo>
                                                    </ShopAddressWrapper>
                                                    <Divider />
                                                    <ShopAddressInfo>
                                                        {`
                                                    ${values?.shopNumber}
                                                    ${`${upperCaseToTitleCase(values?.shopAddress)} `}
                                                    ${`${values?.city.toLowerCase()}, `}
                                                    ${`${values?.lga.toLowerCase()} LGA,`}
                                                    ${`${values?.state} sta. `}
                                         `}
                                                    </ShopAddressInfo>
                                                </FullAddressHolder>
                                                <InnerTitle>Drag the marker to the exact location</InnerTitle>
                                                <Padding>
                                                    <SimpleMap
                                                        coordinates={coordinates}
                                                        setCoordinates={setCoordinates}
                                                        address={`${values.shopNumber} ${values.shopAddress} ${values.city} ${values.lga} ${values.state}`}
                                                    />
                                                </Padding>
                                                <Padding>
                                                    <RippleButton
                                                        onClick={() => handleSubmit(values, setErrors)}
                                                        height={'56px'}
                                                        size={'16px'}
                                                    >
                                                        Update
                                                    </RippleButton>
                                                </Padding>
                                            </InputBlock>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </Fragment>
                    )}
                </ScreenContainer>
            </Fragment>
        )
    );
};

NewEditDeliveryAddressDetails.propTypes = {
    open: bool,
    cancel: func,
    receiverName: string,
    shopName: string,
    streetAddress: string,
    state: string,
    lga: string,
    instruction: string,
    msisdn: string,
    getDeliveryDetails: func,
};
