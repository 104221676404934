import React, { useEffect, useState } from 'react';

import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { TopHeader } from '../../components';
import { Container } from '../../containers/ScreenContainer';
import DesktopBackgroundLayout from '../DesktopBackgroundLayout';

const List = styled.ul`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    margin-top: 76px;
`;

const Card = styled.div`
    display: grid;
    width: 100%;
    gap: 10px;
`;

const CardImage = styled.img`
    width: 100%;
    height: 100px;
`;

const CardImagePlaceHolder = styled.div`
    width: 100%;
    height: 100px;
`;

const CardText = styled.p`
    color: black;
    margin: 0;
    padding: 0;
`;

const CardTitle = styled.h3`
    color: black;
    margin: 0;
    padding: 0;
`;

const SkeletonCard = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timeOut = setTimeout(() => setLoading(false), 3000);

        return () => {
            clearTimeout(timeOut);
        };
    }, []);

    return (
        <DesktopBackgroundLayout>
            <TopHeader title={'MerchBuy'} right={'16px'} withSpacesHeader />
            <Container>
                <List>
                    {Array(9)
                        .fill()
                        .map((_item, index) => (
                            <Card key={index}>
                                {loading ? (
                                    <CardImagePlaceHolder>
                                        <Skeleton width={`100%`} height={`100%`} />
                                    </CardImagePlaceHolder>
                                ) : (
                                    <CardImage src="https://images.unsplash.com/photo-1508138221679-760a23a2285b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cmFuZG9tfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80" />
                                )}

                                {loading ? <Skeleton width={`90%`} height={10} /> : <CardTitle>Peak Milk</CardTitle>}
                                {loading ? (
                                    <Skeleton width={`90%`} height={10} />
                                ) : (
                                    <CardText>Yo! The best milk in the universe today</CardText>
                                )}
                            </Card>
                        ))}
                </List>
            </Container>
        </DesktopBackgroundLayout>
    );
};
export default SkeletonCard;
