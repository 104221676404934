import React from 'react';

import styled from 'styled-components';

import { ReactComponent as BackArrowWhite } from '../../assets/arrow-back-white.svg';
import { ReactComponent as SpacesIcon } from '../../assets/group-white.svg';
import History from '../../utils/History';

const Header = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    padding: 16px;
    width: 100%;
    background: #3ea8ff;
    z-index: 100;
    height: 7.2vh;
    box-sizing: border-box;
`;

const HeaderButton = styled.div`
    background: #53bbff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    min-width: 60px;
    border-radius: 30px;
    height: 24px;
    cursor: pointer;

    &:active {
        opacity: 0.5;
    }
`;

const HeaderText = styled.h4`
    margin: 0 0 0 8px;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
`;

const Placeholder = styled.div`
    height: 7.2vh;
    width: 100%;
    display: none;
`;

export const SpacesHeader = ({ action, newHeader = false }) =>
    newHeader ? (
        <Placeholder>
            <Header>
                <HeaderButton onClick={() => (action ? action() : History.push('/my-shop'))}>
                    <BackArrowWhite />
                    <SpacesIcon style={{ margin: '0 0 0 10px' }} />
                    <HeaderText>APPS</HeaderText>
                </HeaderButton>
            </Header>
        </Placeholder>
    ) : (
        <Placeholder>
            <Header>
                <HeaderButton onClick={() => (action ? action() : History.push('/my-shop'))}>
                    <BackArrowWhite />
                    <SpacesIcon style={{ margin: '0 0 0 10px' }} />
                </HeaderButton>
            </Header>
        </Placeholder>
    );
