import React, { Fragment, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { SearchHeader } from '../../../../../components';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import { Main, Container } from '../../styles';

const CustomTable = styled.div`
    width: 100%;
    margin: auto;
`;

const TableHeader = styled.div`
    display: flex;
`;

const TableBody = styled.div`
    display: flex;
    background-color: ${({ background }) => (background === 0 ? '#f5f8fd' : '#ffffff')}};
`;

const TableCell = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: ${({ cell }) => (cell === 'category' ? '400' : '500')}};
    color: ${({ cell }) => (cell === 'category' ? '#1b86cc' : '#3e3d3d')}};
    flex:1;
    padding: 16px 10px 16px 10px;
`;

const HeaderCell = styled(TableCell)`
    font-size: 10px;
    color: #9b9b9b;
    text-align: center;
    font-weight: 500;
    background-color: #ffffff;
`;

const CommissionTable = () => {
    const productCategeries = useSelector((state) => state.applications.merchbuy.merchbuyProductCategeries);
    const [searchValue, setSearchValue] = useState('');
    const [categories, setCategories] = useState(productCategeries);

    const tableHeaders = ['CATEGORY', 'COMMISSION'];

    const tableData = categories
        ? categories.map((category, index) => ({
              category: category.name || '',
              commission: category.merchbuytotalcommission || 0,
          }))
        : [];

    useEffect(() => {
        productCategeries &&
            setCategories(
                productCategeries.filter((data) => data.name.toLowerCase().includes(searchValue.toLowerCase())),
            );
    }, [searchValue, productCategeries]);

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <SearchHeader
                    title={'Commisions Table'}
                    right={'16px'}
                    sticky
                    placeholder={'Search...'}
                    handleSearch={setSearchValue}
                    withSpacesHeader
                />
                <Main>
                    <Container style={{ width: '100%' }} top="16px">
                        <CustomTable>
                            <TableHeader>
                                {tableHeaders.map((cell) => (
                                    <HeaderCell key={cell}>{cell}</HeaderCell>
                                ))}
                            </TableHeader>
                            {tableData.map((el, index) => (
                                <TableBody background={index % 2} key={index}>
                                    {Object.keys(el).map((val) => (
                                        <TableCell cell={val} key={val}>
                                            {el[val]}
                                            {val === 'commission' && <span>%</span>}
                                        </TableCell>
                                    ))}
                                </TableBody>
                            ))}
                        </CustomTable>
                    </Container>
                </Main>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default CommissionTable;
