import React from 'react';

import { string, func, object, oneOfType, bool } from 'prop-types';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { colors } from '../../styles';
import { PageLogo } from '../logo';

import { ReactComponent as AddIcon } from './assets/add.svg';
import { ReactComponent as BinIcon } from './assets/bin.svg';
import { ReactComponent as CancelIcon } from './assets/cancel.svg';
import DefaultIcon from './assets/default_product.svg';
import UserImage from './assets/user.svg';

const UploadForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: ${({ margin }) => margin || '10px 0'};
    & > img {
        width: 4.5em;
        height: 4.5em;
        border-radius: 50%;
        object-fit: cover;
    }

    @media (min-width: 576px) {
        margin-top: ${({ mtop }) => mtop || null};
    }
`;

const UploadButton = styled.label`
    margin-top: 24px;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: ${colors.deepBlue};
    cursor: pointer;
    border: 1px solid ${({ borderColor }) => borderColor || '#227EFF'};
    border-radius: 4px;
    padding: 12px 8px;
    margin-bottom: 25px;
    width: 246px;
    & > input {
        height: 0;
        width: 0;
        overflow: hidden;
        background: transparent;
    }
`;

const UploadForm2 = styled.div`
    width: 72px;
    height: 72px;
    display: flex;
    margin: ${({ margin }) => margin || '0 8px'};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;

const UploadView = styled.div`
    position: relative;
    width: 64px;
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    & > img {
        width: 64px;
        height: 64px;
        border-radius: 4px;
        object-fit: cover;
    }
`;

const UploadButton2 = styled.label`
    position: relative;
    width: 72px;
    height: 72px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    border: dashed 1px #22a8ff;
    border-radius: 4px;
    background-color: rgba(34, 168, 255, 0.1);
    & > input {
        height: 0;
        width: 0;
        overflow: hidden;
        background: transparent;
    }
`;

const Add = styled(AddIcon)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const CancelContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    top: 4px;
    right: 4px;
`;

const BinIconStyle = styled(BinIcon)`
    height: 10px;
    width: 10px;
`;

export const UploadPicture = ({
    text,
    picture,
    pictureAction,
    defaultPicture,
    width,
    height,
    formName = '',
    showCropper = false,
    version2 = false,
    disabled = false,
    removeImage,
    changeCancelIcon,
    logoRadius,
    logoBackground,
    formMargin,
    borderColor,
    margin,
}) => {
    const handleChangeButton2 = (event) => {
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
            const image = event.target.result;
            if (!showCropper) {
                formName && pictureAction(formName, image);
            }
        });

        if (event.target.files[0]) {
            const file = event.target.files[0];

            if (file.size > 1024 * 1024 * 2) {
                toast.error('File size cannot be more than 2MB');
                return;
            } else {
                !formName && pictureAction(file);
                reader.readAsDataURL(file);
            }
        } else return;
    };

    const handleChangeButton = (event) => {
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
            const image = event.target.result;
            if (!showCropper) {
                document.querySelector('#picture').setAttribute('src', image);

                formName && pictureAction(formName, image);
            }
        });

        if (event.target.files[0]) {
            const file = event.target.files[0];

            if (file.size >= 10485760) {
                toast.error('File size cannot be more than 10MB');
                return;
            } else {
                !formName && pictureAction(file);
                reader.readAsDataURL(file);
            }
        } else return;
    };

    return version2 ? (
        <UploadForm2 id="avatarForm" disabled={disabled} mtop={'20px'} margin={margin}>
            {picture && picture !== 'Not Set' ? (
                <UploadView>
                    <CancelContainer
                        onClick={() => {
                            formName && removeImage(formName);
                        }}
                    >
                        {changeCancelIcon ? <BinIconStyle /> : <CancelIcon />}
                    </CancelContainer>
                    <img
                        src={picture}
                        onError={(e) => {
                            e.target.src = DefaultIcon;
                        }}
                        id="picture"
                        alt="New Avatar"
                    />
                </UploadView>
            ) : (
                <UploadButton2>
                    <input type="file" accept="image/*" onChange={handleChangeButton2} />
                    <Add />
                </UploadButton2>
            )}
        </UploadForm2>
    ) : (
        <UploadForm id="avatarForm" mtop={'20px'} margin={margin}>
            {picture && picture !== 'Not Set' ? (
                <img
                    src={picture}
                    id="picture"
                    alt="New Avatar"
                    style={{
                        width: '72px',
                        height: '72px',
                        borderRadius: '50%',
                        objectFit: 'cover',
                        background: '#EDE6FE',
                    }}
                />
            ) : (
                <PageLogo
                    id="picture"
                    Icon={defaultPicture || UserImage}
                    width="72px"
                    height="72px"
                    borderRadius={'50%'}
                    iconHeight={height || '72px'}
                    iconWidth={width || '72px'}
                />
            )}

            <UploadButton>
                {text || 'Upload profile picture'}
                <input type="file" accept="image/*" onChange={handleChangeButton} />
            </UploadButton>
        </UploadForm>
    );
};

UploadPicture.propTypes = {
    text: string,
    picture: oneOfType([string, object]),
    pictureAction: func,
    defaultPicture: oneOfType([string, object]),
    width: string,
    height: string,
    saveImageToForm: bool,
    showCropper: bool,
    background: string,
    borderColor: string,
    changeCancelIcon: bool,
};
