import React, { useState } from 'react';

import { string, array } from 'prop-types';
import styled from 'styled-components';

const View = styled.div`
    position: absolute;
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    z-index: ${({ index }) => -index || null};
    background-color: ${({ background }) => background || '#f4f4f4'};
    top: 0px;
    left: ${({ left }) => left || null};

    & > img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: cover;
    }
`;

const ImageWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 8px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Text = styled.h3`
    font-weight: 400;
    font-size: 9px;
    color: #22a8ff;
    white-space: nowrap;
    margin-left: 8px;
`;

export const ImageStack = ({ arrayImage, defaultIcon, text }) => {
    const [noImage] = useState(['#e9eaec', '#f4f4f4', '#fafafa']);
    const calHideFraction = (index) => {
        var res = index * 24;
        if (res > 0) {
            res = res - 8 * index;
        }
        return `${res}px`;
    };

    return (
        <Wrapper>
            <ImageWrapper>
                {arrayImage && arrayImage.length > 0
                    ? arrayImage.map((image, index) => (
                          <View key={index} index={index} left={calHideFraction(index)}>
                              <img
                                  src={image}
                                  onError={(e) => {
                                      e.target.src = defaultIcon;
                                  }}
                                  id={`picture${index}`}
                                  alt={`${index}`}
                              />
                          </View>
                      ))
                    : noImage.map((color, index) => (
                          <View key={index} index={index} background={color} left={calHideFraction(index)} />
                      ))}
            </ImageWrapper>
            <Text>{`${(arrayImage && arrayImage.length) || 0} ${text}`}</Text>
        </Wrapper>
    );
};

ImageStack.propTypes = {
    arrayImage: array.isRequired,
    defaultIcon: string,
    text: string,
};
