import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ActionBlock, Overlay } from '../../../containers/OverlayContainer';
import { colors } from '../../../styles';
import { formatPrice } from '../../../utils/currency/formatPriceWithComma';
import { is_url } from '../../../utils/urls/isUrl';
import { PopUp, PopUpContent, CancelButton, ConfirmButton } from '../common';
import { ReactComponent as AddIcon } from '../merchbuy-add-to-cart-popup/assets/add.svg';
import FallbackProductImage from '../merchbuy-add-to-cart-popup/assets/productImage.svg';
import { ReactComponent as SubstractIcon } from '../merchbuy-add-to-cart-popup/assets/substract.svg';

const ProductBlock = styled.div`
    margin: 20px 0;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
`;

const ProductImage = styled.img`
    width: 82px;
    height: 82px;
    margin-top: 0;
    margin-left: 10px;
    border-radius: 20%;
    object-fit: cover;
    padding: 10px 0;
    background-color: #f2f5fa;
`;

const ProductInfo = styled.div`
    flex: 1;
    font-size: 14px;
    padding: 5px 15% 5px 10px;
`;
const ModifiedSubstractIcon = styled(SubstractIcon)`
    margin: 0 20px;
`;
const ModifiedAddIcon = styled(AddIcon)`
    margin: 0 20px;
`;
const Info = styled.div`
    margin: auto;
    margin-bottom: 25px;
    font-size: 12px;
    color: #56636d;
    max-width: 80%;
`;
const Title = styled.div`
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
`;

const ProductDesc = styled.div`
    font-size: 14px;
    text-align: left;
`;

const UpdateCalculatorBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    & > svg {
        cursor: pointer;
    }
`;

const QuantityDisplay = styled.div`
    border-radius: 8px;
    width: 82px;
    height: 48px;
    background-color: ${colors.themeColor3};
    text-align: center;
`;

const CurrentQuantity = styled.input`
    position: relative;
    top: calc(50% - 8px);
    font-weight: 100;
    font-size: 14px;
    font-weight: 500;
    color: ${({ color }) => color || colors.themeTextColor1};
    margin-top: ${({ top }) => top || null};
    margin-left: ${({ left }) => left || null};
    width: 60%;
    background-color: inherit;
    border: none;
    text-align: center;
    &:focus {
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
`;

const MoqParagraph = styled.div`
    margin: 10px 0;
    font-size: 10px;
    color: #56636d;
    margin-bottom: 30px;
`;
const Table = styled.table`
    border: none;
    border-collapse: collapse;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    color: #56636d;
    font-size: 12px;
`;

const TH = styled.th`
    border: none;
    border-collapse: collapse;
    font-weight: 400;
    padding: 10px;
    width: 50%;
`;

const TD = styled.td`
    border: none;
    border-collapse: collapse;
    padding: 10px;
`;

const TR = styled.tr`
    &.active {
        color: #62a9dc;
    }
`;
export const GroupBuyPopup = ({ open, cancel, data }) => {
    const [selectedMoq, setSelectedMoq] = useState(0);
    const [bulkIndex, setBulkIndex] = useState(0);
    const history = useHistory();

    useEffect(() => {
        const { bulkPrices } = data || {};
        const bulk =
            bulkPrices &&
            bulkPrices.find((price, index) => {
                if (!bulkPrices[index + 1]) {
                    return selectedMoq >= price.moq;
                }
                return selectedMoq < bulkPrices[index + 1].moq;
            });
        bulkPrices && setBulkIndex(bulkPrices.indexOf(bulk));
    }, [selectedMoq, data]);

    useEffect(() => {
        data && setSelectedMoq(data.moq);
    }, [data]);

    const onConfirm = () => {
        history.push({
            pathname: `${history.location.pathname}/groupBuy`,
            state: { product: data, quantity: selectedMoq, bulkPrice: data.bulkPrices[bulkIndex].price },
        });
    };

    return (
        <PopUp open={open}>
            <Overlay onClick={cancel} />
            <PopUpContent>
                <ProductBlock>
                    <ProductImage
                        src={
                            is_url(data.images ? data.images.baseImageUrl : '')
                                ? data.images.baseImageUrl
                                : FallbackProductImage
                        }
                        alt="product image"
                    />
                    <ProductInfo>
                        <ProductDesc>
                            {data.name}
                            <br />{' '}
                            {data.bulkPrices &&
                                (formatPrice(data.bulkPrices[0].price || 0) + ('/' + data.unitValue) || '/Unit')}
                        </ProductDesc>
                    </ProductInfo>
                </ProductBlock>
                <Title>Quantity</Title>
                <Info>Please add the total quantity that you want to share with your friends.</Info>
                <UpdateCalculatorBlock>
                    <ModifiedSubstractIcon
                        onClick={() => {
                            if (selectedMoq <= data.bulkPrices[0].moq) return setSelectedMoq(data.bulkPrices[0].moq);
                            setSelectedMoq(parseInt(selectedMoq) - 1);
                        }}
                    />
                    <QuantityDisplay>
                        <CurrentQuantity
                            value={selectedMoq}
                            type={'number'}
                            onChange={(e) => {
                                setSelectedMoq(
                                    e.target.value <= data.bulkPrices[0].moq
                                        ? data.bulkPrices[0].moq
                                        : e.target.value && parseInt(e.target.value),
                                );
                            }}
                        />
                    </QuantityDisplay>
                    <ModifiedAddIcon onClick={() => setSelectedMoq(parseInt(selectedMoq) + 1)} />
                </UpdateCalculatorBlock>
                <MoqParagraph>Minimum Order Quantity: {`${data.moq} ${data.unitValue || 'Units'}`}</MoqParagraph>

                <Table>
                    <thead>
                        <TR>
                            <TH>Qty</TH>
                            <TH>Price/{data.unitValue || 'Unit'}</TH>
                        </TR>
                    </thead>
                    <tbody>
                        {data.bulkPrices &&
                            data.bulkPrices.map((item, index) => (
                                <TR
                                    key={index}
                                    onClick={() => {
                                        setSelectedMoq(item.moq);
                                        setBulkIndex(index);
                                    }}
                                    className={bulkIndex === index ? 'active' : ''}
                                >
                                    <TD>
                                        {data.bulkPrices[index + 1]
                                            ? item.moq
                                            : item.moq + (' ' + data.unitValue + '+') || 'Units+'}
                                        {data.bulkPrices[index + 1]
                                            ? ' - ' +
                                                  Number(data.bulkPrices[index + 1].moq - 1) +
                                                  (' ' + data.unitValue) || ' Units'
                                            : ''}
                                    </TD>
                                    <TD>{formatPrice(item.price || 0)}</TD>
                                </TR>
                            ))}
                    </tbody>
                </Table>

                <ActionBlock direction={'row'} top={'16px'}>
                    <CancelButton type={'button'} onClick={cancel}>
                        Cancel
                    </CancelButton>
                    <ConfirmButton onClick={onConfirm}>Continue</ConfirmButton>
                </ActionBlock>
            </PopUpContent>
        </PopUp>
    );
};
