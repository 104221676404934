import { toast } from 'react-toastify';

import { axios, currentAPI } from '../../../../../../config';
// import { countriesMap } from "../../../../../../data/countries";
// import { insertZero } from '../../../../../../utils/inputs/formatPhoneNumber';
import { formatPrice } from '../../../../../../utils/currency/formatPriceWithComma';
import { raveErrorsTypes } from '../../../../../../utils/errors/raveErrorTypes';
import { thirdPartyError } from '../../../../../../utils/errors/thirdPartyError';
import {
    UPDATE_WALLET_STATUS,
    UPDATE_WALLET_BALANCE,
    SENDING_WALLET_DATA,
    USER_WALLET_SUCCESS,
    USER_WALLET_FAILED,
} from '../../constants';
import { getUserWalletData, updateWalletStatus, saveWalletFundAmount } from '../index';

export const createSpacesWallet = () => async (dispatch) => {
    try {
        const createSpacesWallet = await axios.post(`${currentAPI}/api/wallet/create/spaces/internal`, null);

        if (createSpacesWallet.status === 200) {
            dispatch({ type: UPDATE_WALLET_STATUS, payload: createSpacesWallet.data.status });
            dispatch({ type: UPDATE_WALLET_BALANCE, payload: createSpacesWallet.data.balance });
        }
    } catch (error) {
        console.error(error);
    }
};

export const createInactiveRaveWallet = () => async () => {
    try {
        const createRaveWallet = await axios.post(`${currentAPI}/api/wallet/create/rave/internal`, null);

        if (createRaveWallet.status === 200) {
            return createRaveWallet.data;
        }
    } catch (error) {
        console.error(error);
    }
};

export const checkKYC = (сustomerInfo) => async (dispatch, getState) => {
    dispatch({ type: SENDING_WALLET_DATA });

    const bankDataDTO = {
        bvn: сustomerInfo.bvn,
        dob: сustomerInfo.dob,
    };

    try {
        const sendUserBankDataResponse = await axios.post(
            `${currentAPI}/api/wallet/checkKYC`,
            JSON.stringify(bankDataDTO),
        );

        if (sendUserBankDataResponse.status === 200) {
            dispatch({ type: USER_WALLET_SUCCESS });
            return true;
        }
        return false;
    } catch (error) {
        dispatch({
            type: USER_WALLET_FAILED,
            payload: '',
        });
        toast.error(
            error.response.data.message ||
                raveErrorsTypes[error.response.data.message] ||
                thirdPartyError(error, ': ', 'error').message,
        );
        return false;
    }
};

export const validateKYC = (data) => async (dispatch) => {
    dispatch({ type: SENDING_WALLET_DATA });

    try {
        const sendOTPResponse = await axios.post(`${currentAPI}/api/wallet/validateKYC?code=${data.code}`);

        if (sendOTPResponse.status === 200) {
            dispatch({ type: USER_WALLET_SUCCESS });
            return true;
        }
        return false;
    } catch (error) {
        dispatch({
            type: USER_WALLET_FAILED,
            payload: '',
        });
        toast.error(error.response.data.message || 'OTP could not be validated!');
        return false;
    }
};

export const createActivatedRaveWalletOnSpaces = (customerDTO) => async (dispatch) => {
    dispatch({ type: SENDING_WALLET_DATA });

    try {
        const createSpacesWalletResponse = await axios.post(
            `${currentAPI}/api/wallet/create/rave/external`,
            JSON.stringify(customerDTO),
        );

        if (createSpacesWalletResponse.status === 200) {
            dispatch(updateWalletStatus('ACTIVE'));
            dispatch(updateRaveBalance());
            dispatch({ type: USER_WALLET_SUCCESS });
            return createSpacesWalletResponse.data;
        }
    } catch (error) {
        dispatch({
            type: USER_WALLET_FAILED,
            payload: error.response.data.message,
        });

        toast.error('We are unable to verify the BVN. Submit correct information to proceed');
    }
};

export const findCustomerOnRave = (type, value) => async () => {
    try {
        const findCustomerResponse = await axios.post(
            `${currentAPI}/api/raveWalletExternal/findCustomer`,
            JSON.stringify({ type, value }),
        );

        if (findCustomerResponse.status === 200) {
            return findCustomerResponse.data;
        }
    } catch (error) {
        //toast.error(error.message);
        // if (error.response && error.response.status === 400) {
        //     toast.error(error.response.data.message);
        // }
    }
};

export const topUpRaveBalance = (amount) => async (dispatch) => {
    dispatch({ type: SENDING_WALLET_DATA });

    try {
        const raveTopUpResponse = await axios.post(`${currentAPI}/api/transfersRave/credit?amount=${amount}`, null);

        if (raveTopUpResponse.status === 200) {
            await dispatch(getUserWalletData());
            dispatch({ type: USER_WALLET_SUCCESS });
            dispatch(saveWalletFundAmount(amount, 'success'));
        }
    } catch (error) {
        dispatch({
            type: USER_WALLET_FAILED,
            payload: error.message,
        });
    }
};

export const createRaveCustomer = (customerDTO) => async (dispatch) => {
    dispatch({ type: SENDING_WALLET_DATA });

    try {
        const createRaveCustomerResponse = await axios.post(
            `${currentAPI}/api/raveWalletExternal/createCustomer`,
            JSON.stringify(customerDTO),
        );

        if (createRaveCustomerResponse.status === 200) {
            dispatch({ type: USER_WALLET_SUCCESS });
            return createRaveCustomerResponse.data;
        }
    } catch (error) {
        toast.error(thirdPartyError(error).response_message);

        dispatch({
            type: USER_WALLET_FAILED,
            payload: error.message,
        });
    }
};

export const updateRaveBalance = () => async (dispatch, getState) => {
    try {
        const updateRaveBalanceResponse = await axios.get(`${currentAPI}/api/wallet/rave/wallet/balance`);

        if (updateRaveBalanceResponse.status === 200) {
            dispatch({
                type: UPDATE_WALLET_BALANCE,
                payload: formatPrice(updateRaveBalanceResponse.data),
            });
        }
    } catch (error) {
        //
    }
};
