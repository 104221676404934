import React from 'react';

import { ReactComponent as PieChart } from '../assets/piechart.svg';
import { NoDataContainer, PieChartBox } from '../reports.styles';

export const NoData = () => {
    return (
        <NoDataContainer>
            <PieChartBox>
                <PieChart />
                <div>No data to display.</div>
                <span>Try a different time period.</span>
            </PieChartBox>
        </NoDataContainer>
    );
};
