import React, { Fragment, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Loader, RippleButton, TopHeader } from '../../../../../../components';
import { SearchInputWithCancel } from '../../../../../../components/forms/input/search-input/SearchInput';
import { MerchantLoader } from '../../../../../../components/loader';
import { BlurBackground } from '../../../../../../containers/BlurBackground';
import { List } from '../../../../../../containers/ListContainer';
import { Title } from '../../../../../../containers/MessageContainer';
import { ScreenContainer } from '../../../../../../containers/ScreenContainer';
import { flags } from '../../../../../../data/countries';
import {
    getSabiCustomers,
    fetchDeliveryDetails,
    updatedDeliveryDetails,
} from '../../../../../../redux/ducks/applications/my-shop/actions/customers';
import * as actions from '../../../../../../redux/ducks/applications/my-shop/actions/customers';
import { colors } from '../../../../../../styles';
import { delay, toAbbr, toTitleCase, upperCaseToTitleCase } from '../../../../../../utils/toTitleCase';
import { ReactComponent as SearchIcon } from '../../../assets//search.svg';
import { ReactComponent as AddIcon } from '../../../assets/add_icon_white.svg';
import { ReactComponent as ContactIcon } from '../../../assets/contact_empty.svg';
import { ReactComponent as ContactIdeaIcon } from '../../../assets/contactIdea.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/goto_arrow.svg';
import { ReactComponent as NewInfoIcon } from '../../../assets/new-info-icon.svg';
import SyncImage from '../../../assets/sync.svg';
import { Container, Divider } from '../../../styles';
import { NewEditDeliveryAddressDetails } from '../delivery-address-edit-new';
import { SearchForSabiMerchant } from '../delivery-address-search-sabi-user';
import { EditDeliveryAddressDetails } from '../delivery-details-edit';

import InfoBadge from './../../../../../../components/badges/info-badge/index';

const ModifiedContainer = styled(Container)`
    width: 100%;
`;

const HeadingWrapper = styled.div`
    margin: ${({ margin }) => margin || '20% 5% 0 5%'};
    padding: 0 16px;
    background: ${colors.aliceBlue};
    box-shadow: 0px 15px 64px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const HeadingInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const AddressText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-left: 8px;
    color: ${colors.deepBlue};
`;

const DetailsSection = styled.div`
    padding: ${({ padding }) => padding || null};
    margin: ${({ margin }) => margin || '0'};
    min-height: ${({ minHeight }) => minHeight || '131px'};
    height: ${({ height }) => height || null};
    background-color: ${({ background }) => background || colors.white};
`;

const FlexContent = styled.div`
    display: flex;
`;

const ContentWrapper = styled(FlexContent)`
    display: flex;
    padding: 16px 1em;
    justify-content: space-between;
    align-items: flex-start;
    height: ${({ height }) => height || null};
`;

const Text = styled.p`
    margin: ${({ margin }) => margin || '0'};
    font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
    font-size: ${({ font }) => font || '12px'};
    color: ${({ color }) => color || null};
    align-items: ${({ alignItems }) => alignItems || null};
    width: ${({ width }) => width || null};
    padding-top: ${({ top }) => top || null};
    text-align: ${({ align }) => align || null};
    line-height: ${({ lineHeight }) => lineHeight || null};
`;

const DetailWrapper = styled.div`
    display: grid;
    gap: 6px;
`;

const DividerWrapper = styled.div`
    margin: 0 1em;
    margin-bottom: ${({ bottom }) => bottom || null};
`;

const Holder = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${({ padding }) => padding || null};
`;

const ContactWrapper = styled.div`
    width: 343px;
    height: 289px;
    border-radius: 8px;
    background: ${colors.white};
    margin: 0 1em 16px 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const InLineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;
const UserAcronymn = styled.div`
    background-color: ${colors.newPurple};
    border-radius: 50%;
    color: ${colors.purple};
    width: 32px;
    font-size: 10px;
    height: 32px;
    padding: 12px 5px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    margin: 0 0 10.5px 0px;
    font-weight: 500;
`;

const CardShopName = styled.div`
    position: absolute;
    color: ${colors.themeTxtColor10};
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    margin-right: 10px;
    left: 19%;
    float: left;
    margin-top: -6%;
`;

const CardUserName = styled.div`
    position: absolute;
    color: ${colors.themeTextColor11};
    font-weight: bold;
    font-size: 10px;
    line-height: 24px;
    font-weight: 400;
    margin: ${({ margin }) => margin || '5% 0 0 11%'};
`;

const ButtonContainer = styled.div`
    padding: 0px 16px 12px;
    margin: 28px 0px 12px;
`;

const ModalContainer = styled.div`
    height: ${({ height }) => height || 'calc(236px + 66px)'};
    width: 100%;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 8px 8px 0 0;
    @media (max-width: 576px) {
        height: ${({ minHeight }) => minHeight || 'auto'};
    }
    padding: 24px 0px 0px;
`;
const Backdrop = styled.div`
    height: 100%;
    width: 100%;
`;
const StyledSearchIcon = styled(SearchIcon)`
    position: absolute;
    right: 10%;
`;

const SearchContainer = styled.div`
    margin: 20px;
    margin-bottom: 0px;
`;

const SearchLengthText = styled.span`
    font-weight: 500;
    color: ${colors.black};
`;

const AddressContainer = styled.div`
    background-color: #f9fafc;
    width: 95%;
    height: 132px;
    border-radius: 4px;
    border: 1px solid #e2e9f0;
    margin: auto;
    @media (max-width: 576px) {
        height: ${({ minHeight }) => minHeight || 'auto'};
    }
`;
const CountryFlag = styled.img`
    width: 24px;
    height: 24px;
    margin: 2% 0 0 15%;
`;

const MiniTitle = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #7a7978;
    margin-top: 12px;
    padding-left: 16px;
    width: ${({ width }) => width || null};
`;
const MiniContent = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #071827;
    margin-top: 12px;
    width: ${({ width }) => width || null};
    margin-left: ${({ left }) => left || '5%'};
`;

const SyncButtonWrapper = styled.div`
    background-color: white;
    background-repeat: no-repeat;
    border: 0.5px solid #64a4ff;
    align-items: center;
    text-align: center;
    height: 46px;
    width: 343px;
    margin: auto;
    border-radius: 4px;
`;
const SyncButton = styled.div`
    font-weight: 500;
    font-size: 16px;
    color: #227eff;
    margin-left: 3%;
    padding-top: 10px;
`;

export const DeliveryAddressList = ({ close, getDeliveryDetails, merchbuyActions, getAnyNewShopName }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const userDeliveryDetails = useSelector((state) => state.applications.myShop.deliveryDetails);

    const isLoading = useSelector((state) => state.applications.myShop.isLoading);
    const contacts = useSelector((state) => state.applications.myShop.customersAndReferrals);
    const deliveryAddressEditLimit = useSelector((state) => state.applications.merchbuy.deliveryAddressEditLimit);
    const [deliveryContacts, setDeliveryContacts] = useState();
    const [updatedInfo, setUpdatedInfo] = useState({});
    const [searchedOptions, setSearchedOptions] = useState(deliveryContacts);
    const [searchValue, setSearchValue] = useState('');
    const [isEditingContactAddress, setIsEditingContactAddress] = useState(false);
    const [showNewAddressPopup, setShowNewAddressPopup] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [showNewEditPopup, setShowNewEditPopup] = useState(false);
    const [showUsedUpAccessPopup, setShowUsedUpAccessPopup] = useState(false);
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [showIncompletInfoPopUp, setShowIncompletInfoPopUp] = useState(false);
    const [showFirstIncompletInfoPopUp, setShowFirstIncompletInfoPopUp] = useState(false);
    const [showNewInfoPopUp, setShowNewInfoPopUp] = useState(false);
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [selectedContact, setSelectedContact] = useState({});
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [middleName, setMiddleName] = useState('');
    const [deliveryInstruction, setDeliveryInstruction] = useState('');
    const [savedShopName, setSavedShopName] = useState('');

    const getUpdatedDetail = async (phoneNumber) => {
        setShowFirstIncompletInfoPopUp(true);
        setLoadingInfo(true);
        const res = await dispatch(updatedDeliveryDetails(phoneNumber));
        res && setUpdatedInfo(res);
        await delay(1000);
        setLoadingInfo(false);
        setShowFirstIncompletInfoPopUp(false);
        setShowIncompletInfoPopUp(false);
        return res;
    };

    const noUpdatedDetail = () => {
        setShowFirstIncompletInfoPopUp(true);
        setLoadingInfo(true);
        setShowNewInfoPopUp(false);
        setLoadingInfo(false);
        setShowFirstIncompletInfoPopUp(false);
        setShowIncompletInfoPopUp(true);
    };

    const newInfo = async () => {
        setShowNewInfoPopUp(false);
        const res = await dispatch(updatedDeliveryDetails(selectedContact.phoneNumber));
        let payload;
        payload = {
            city: selectedContact.city,
            cordinates: {
                latitude: latitude,
                longitude: longitude,
            },
            customerId: selectedContact.id || deliveryLocation.id,
            deliveryInstructions: deliveryInstruction,
            firstName: selectedContact.firstName,
            lastName: selectedContact.lastName,
            lga: selectedContact.lga,
            middleName: middleName,
            phoneNumber: selectedContact.phoneNumber,
            shopAddress: selectedContact.shopAddress,
            shopName: res?.shops[0]?.details?.shopName || savedShopName,
            shopNumber: selectedContact.shopName,
            state: selectedContact.state,
        };
        handleSelectedContactAddress(payload);
        dispatch(actions.editSabiCustomers(payload, selectedContact.id));
    };

    useEffect(() => {
        dispatch(getSabiCustomers());
        dispatch(fetchDeliveryDetails());
        setLoadingInfo(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        setDeliveryContacts(contacts);
        if (deliveryContacts && deliveryContacts.length > 0 && searchValue) {
            const searchResults = deliveryContacts.filter(
                (x) =>
                    String(x.fullName).toLowerCase().includes(searchValue.toLowerCase()) ||
                    String(x.firstName).toLowerCase().includes(searchValue.toLowerCase()) ||
                    String(x.lastName).toLowerCase().includes(searchValue.toLowerCase()) ||
                    String(x.shopName).toLowerCase().includes(searchValue.toLowerCase()) ||
                    String(x.phoneNumber).includes(searchValue),
            );
            setSearchedOptions(searchResults);
        } else {
            setSearchedOptions(deliveryContacts);
        }
    }, [contacts, searchValue, deliveryContacts]);

    const [selectedDeliveryInformation, setSelectedDeliveryInformation] = useState(null);

    const checkRequiredFields = (address, lga, phoneNumber, state, shopName) => {
        return !address || !lga || !phoneNumber || !state || !shopName;
    };

    const deliveryLocation =
        localStorage.getItem('deliveryLocation') && JSON.parse(localStorage.getItem('deliveryLocation'));
    const { state: deliveryState } = deliveryLocation || {};

    const checkDeliveryLocation = (state) => String(deliveryState).toLowerCase() === String(state).toLowerCase();

    const handleSelectSelfAddress = (userInfo) => {
        const { address, lga, phoneNumber, state, city, shopName } = userInfo || userDeliveryDetails || {};
        const isRequiredDetailsField = checkRequiredFields(address, lga, phoneNumber, state, city, shopName);
        const info = {
            ...userDeliveryDetails,
            firstName: user.firstName,
            lastName: user.lastName,
            receiverName: `${user.firstName} ${user.lastName}`,
            contactType: 'SELF',
        };

        if (!checkDeliveryLocation(state)) {
            return toast.error('State does not match shopping location, update state to your shopping location');
        }

        if (isRequiredDetailsField) {
            return toast.error('Sorry your delivery information is incomplete');
        }

        setSelectedDeliveryInformation(info);
        getDeliveryDetails(info);
        close();
    };

    const handleSelectedContactAddress = (contact) => {
        const { shopAddress, lga, state, phoneNumber, city, shopName } = contact || {};
        const isRequiredDetailsField = checkRequiredFields(shopAddress, lga, phoneNumber, state, city, shopName);

        const info = {
            ...contact,
            contactType: 'CONTACT',
        };

        if (!checkDeliveryLocation(state)) {
            return toast.error("Sorry the customer's state does not match your shopping location");
        }

        if (isRequiredDetailsField) {
            return toast.error("Sorry the customer's information is incomplete");
        }

        setSelectedDeliveryInformation(info);
        getDeliveryDetails(info);
        close();
    };

    const setSabiNewEdit = (info) => {
        setSelectedDeliveryInformation(info);
        setShowNewAddressPopup(false);
        setShowNewEditPopup(true);
        setShowEditPopup(false);
        setShowIncompletInfoPopUp(false);
    };

    const setSabiUserUse = (info) => {
        getDeliveryDetails(info);
        handleSelectedContactAddress(info);
        dispatch(actions.addSabiCustomers(info));
        setShowNewAddressPopup(false);
        close();
    };

    if (isLoading) {
        return <Loader />;
    } else
        return (
            <Fragment>
                {showEditPopup && (
                    <EditDeliveryAddressDetails
                        open={showEditPopup}
                        close={close}
                        cancel={() => setShowEditPopup(false)}
                        deliveryLocation={selectedDeliveryInformation}
                        getDeliveryDetails={(values) => {
                            getDeliveryDetails(values);
                            setSelectedDeliveryInformation(values);
                            dispatch(merchbuyActions.updateDeliveryLocation(values));
                        }}
                        setSelectedDeliveryInformation={setSelectedDeliveryInformation}
                        handleSelectedContactAddress={handleSelectedContactAddress}
                        isEditingContactAddress={isEditingContactAddress}
                        setSabiNewEdit={setSabiNewEdit}
                        getAnyNewShopName={getAnyNewShopName}
                    />
                )}

                {showUsedUpAccessPopup && (
                    <BlurBackground position="fixed">
                        <Backdrop onClick={() => setShowUsedUpAccessPopup(false)}>
                            <ModalContainer>
                                <Title
                                    left="16px"
                                    textAlign="left"
                                    size="20px"
                                    lineHeight="30px"
                                    bottom="24px"
                                    weight="700"
                                >
                                    Edit Address
                                </Title>
                                <InfoBadge
                                    icon={<NewInfoIcon />}
                                    title="You have used up your address edit access."
                                    message="Contact support for further clarification"
                                    backgroundColor={'#FFF1F0'}
                                    margin="0 16px 24px"
                                    weight="400"
                                    marginTop="4px"
                                    color="#A31300"
                                    bottom="4px"
                                    size="10px"
                                    height="16px"
                                />

                                <ButtonContainer>
                                    <RippleButton
                                        top="0px"
                                        bottom="0px"
                                        height="56px"
                                        onClick={() => {
                                            setShowUsedUpAccessPopup(false);
                                        }}
                                    >
                                        Cancel
                                    </RippleButton>
                                </ButtonContainer>
                            </ModalContainer>
                        </Backdrop>
                    </BlurBackground>
                )}
                {showNewEditPopup && (
                    <NewEditDeliveryAddressDetails
                        open={showNewEditPopup}
                        close={close}
                        cancel={() => setShowNewEditPopup(false)}
                        deliveryLocation={selectedDeliveryInformation}
                        getDeliveryDetails={(values) => {
                            getDeliveryDetails(values);
                            setSelectedDeliveryInformation(values);
                            dispatch(merchbuyActions.updateDeliveryLocation(values));
                        }}
                        setSelectedDeliveryInformation={setSelectedDeliveryInformation}
                        handleSelectedContactAddress={handleSelectedContactAddress}
                        isEditingContactAddress={isEditingContactAddress}
                        selectedContact={selectedContact}
                    />
                )}

                {showNewAddressPopup && (
                    <SearchForSabiMerchant
                        open={showNewAddressPopup}
                        cancel={() => setShowNewAddressPopup(false)}
                        setSabiUserUse={setSabiUserUse}
                        setSabiUserEdit={(info) => {
                            setSelectedDeliveryInformation(info);
                            setShowNewAddressPopup(false);
                            setShowEditPopup(true);
                        }}
                        setShowNewAddressPopup={setShowNewAddressPopup}
                        setSabiNewEdit={setSabiNewEdit}
                    />
                )}

                {showFirstIncompletInfoPopUp && (
                    <BlurBackground position="fixed" zIndex="2000">
                        <Backdrop>
                            <ModalContainer minHeight="20%" height="calc(200px + 50px)">
                                {loadingInfo && (
                                    <MerchantLoader
                                        bg="#FFFFFF"
                                        width="100%"
                                        height="0"
                                        margin="10% 0 0 0%"
                                        padding="0"
                                        size="10px"
                                    />
                                )}
                            </ModalContainer>
                        </Backdrop>
                    </BlurBackground>
                )}

                {showNewInfoPopUp && (
                    <BlurBackground position="fixed" zIndex="2000">
                        <Backdrop>
                            <ModalContainer height="calc(320px + 96px)">
                                <Title
                                    left="16px"
                                    textAlign="left"
                                    size="20px"
                                    lineHeight="30px"
                                    bottom="24px"
                                    weight="700"
                                >
                                    Merchant Update Found
                                </Title>
                                <AddressContainer minHeight="142px">
                                    <Text fontWeight="700" color="#5C6F7F" font="14px" margin="16px 0 24px 16px">
                                        New Information
                                    </Text>
                                    <InLineContainer>
                                        <MiniTitle width="40%">Phone Number</MiniTitle>
                                        <CountryFlag
                                            src={flags.filter((flag) => flag?.customAbbreviation === 'NG')[0]?.value}
                                            alt={flags.filter((flag) => flag.customAbbreviation === 'NG')[0].value}
                                        />
                                        <MiniContent width="50%">{`+234 ${
                                            updatedInfo?.data?.deliverInformation?.phoneNumber ||
                                            selectedContact.phoneNumber
                                        }`}</MiniContent>
                                    </InLineContainer>
                                    <InLineContainer>
                                        <MiniTitle width="50%">Shop Name</MiniTitle>
                                        <MiniContent left="20%" width="50%">
                                            {toTitleCase(updatedInfo?.shops[0]?.details?.shopName || savedShopName)}
                                        </MiniContent>
                                    </InLineContainer>
                                </AddressContainer>
                                <Text
                                    lineHeight="18px"
                                    size="12px"
                                    fontWeight="400"
                                    color="#5C6F7F"
                                    align="center"
                                    alignItems="center"
                                    margin="24px 0 10px 0"
                                >
                                    Merchant has updated details. Sync to update <br />
                                    your address book
                                </Text>
                                <SyncButtonWrapper
                                    onClick={() => {
                                        newInfo();
                                    }}
                                >
                                    <InLineContainer>
                                        <img src={SyncImage} alt="" style={{ marginLeft: '35%', paddingTop: '10px' }} />
                                        <SyncButton>Sync and use</SyncButton>
                                    </InLineContainer>
                                </SyncButtonWrapper>
                            </ModalContainer>
                        </Backdrop>
                    </BlurBackground>
                )}

                {showIncompletInfoPopUp && (
                    <BlurBackground position="fixed" zIndex="2000">
                        <Backdrop>
                            <ModalContainer height="calc(236px + 86px)">
                                <Title
                                    left="16px"
                                    textAlign="left"
                                    size="20px"
                                    lineHeight="30px"
                                    bottom="24px"
                                    weight="700"
                                >
                                    Missing Merchant Information
                                </Title>
                                <InfoBadge
                                    title="Shop name is incomplete"
                                    message="We could not find any shop. Please provide shop details."
                                    backgroundColor={'#FFF7E6'}
                                    margin="0 16px 24px"
                                    weight="700"
                                    marginTop="4px"
                                    color="black"
                                    titleColor="black"
                                    bottom="4px"
                                    size="12px"
                                />
                                <ButtonContainer>
                                    <RippleButton
                                        top="0px"
                                        bottom="0px"
                                        height="56px"
                                        onClick={() => setSabiNewEdit && setSabiNewEdit(updatedInfo)}
                                    >
                                        Edit Merchant Information
                                    </RippleButton>
                                </ButtonContainer>
                            </ModalContainer>
                        </Backdrop>
                    </BlurBackground>
                )}

                {!showEditPopup && !showNewAddressPopup && !showNewEditPopup && (
                    <Fragment>
                        {!showSearchInput ? (
                            <TopHeader title="Address book" size="14px" color={colors.themeTxtColor10} withCancelIcon>
                                <StyledSearchIcon
                                    onClick={() => {
                                        setShowSearchInput(true);
                                    }}
                                />
                            </TopHeader>
                        ) : (
                            <SearchContainer>
                                <SearchInputWithCancel
                                    placeholder="Search Address"
                                    value={searchValue}
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);
                                    }}
                                    onCancel={() => {
                                        setSearchValue('');
                                        setShowSearchInput(false);
                                    }}
                                    noArrow={false}
                                    showCancelOnInteract
                                    onCancelInputValue={() => setSearchValue('')}
                                />
                            </SearchContainer>
                        )}
                        <ScreenContainer top="0" color={colors.lightestGray} padding={'0'}>
                            <ModifiedContainer>
                                {!searchValue && (
                                    <List noBorderBottom={true} fullScreen={false}>
                                        {showSearchInput ? (
                                            <HeadingWrapper
                                                margin="5% 5% 0 5%"
                                                onClick={() => {
                                                    setShowNewAddressPopup(true);
                                                }}
                                                minHeight="80px"
                                                borderColor={colors.background.walletBadge}
                                            >
                                                <HeadingInnerWrapper>
                                                    <AddIcon />
                                                    <AddressText>Add Merchant Address</AddressText>
                                                </HeadingInnerWrapper>
                                                <ArrowIcon />
                                            </HeadingWrapper>
                                        ) : (
                                            <HeadingWrapper
                                                onClick={() => {
                                                    setShowNewAddressPopup(true);
                                                }}
                                                minHeight="80px"
                                                borderColor={colors.background.walletBadge}
                                            >
                                                <HeadingInnerWrapper>
                                                    <AddIcon />
                                                    <AddressText>Add Merchant Address</AddressText>
                                                </HeadingInnerWrapper>
                                                <ArrowIcon />
                                            </HeadingWrapper>
                                        )}
                                        <Text margin="19px 0 8px 16px" color={colors.themeTextColor11} fontWeight="700">
                                            YOUR ADDRESS
                                        </Text>
                                    </List>
                                )}
                                {!searchValue && (
                                    <Fragment>
                                        <List noBorderBottom={true}>
                                            <DetailsSection margin="4px 16px 0 16px" height="146px">
                                                <ContentWrapper>
                                                    <FlexContent>
                                                        <DetailWrapper>
                                                            {userDeliveryDetails?.receiverName && (
                                                                <Text
                                                                    color={colors.boldDark}
                                                                    fontWeight="700"
                                                                    font="14px"
                                                                    margin="7px 0 0 0"
                                                                >
                                                                    {`${user.firstName} ${user.lastName}`}
                                                                </Text>
                                                            )}
                                                            {userDeliveryDetails?.address && (
                                                                <Text
                                                                    color={colors.lightDark}
                                                                    font="12px"
                                                                    fontWeight="400"
                                                                >
                                                                    {`${
                                                                        userDeliveryDetails.shopNumber
                                                                            ? userDeliveryDetails.shopNumber + ', '
                                                                            : ''
                                                                    } 
                                                                     ${userDeliveryDetails.address || ''}`}
                                                                </Text>
                                                            )}
                                                            {userDeliveryDetails?.lga && (
                                                                <Text color={colors.lightDark} font="12px">
                                                                    {`${userDeliveryDetails.lga} L.G.A,`}
                                                                </Text>
                                                            )}
                                                            {userDeliveryDetails?.city && (
                                                                <Text color={colors.lightDark} font="12px">
                                                                    {`${upperCaseToTitleCase(
                                                                        userDeliveryDetails?.city,
                                                                    )} City,`}
                                                                </Text>
                                                            )}
                                                            {userDeliveryDetails?.state && (
                                                                <Text
                                                                    color={colors.lightDark}
                                                                    font="12px"
                                                                    fontWeight="400"
                                                                >
                                                                    {userDeliveryDetails.state}
                                                                </Text>
                                                            )}
                                                            {userDeliveryDetails?.msisdn && (
                                                                <Text
                                                                    color={colors.lightDark}
                                                                    font="12px"
                                                                    fontWeight="400"
                                                                >
                                                                    {userDeliveryDetails.msisdn}
                                                                </Text>
                                                            )}
                                                        </DetailWrapper>
                                                    </FlexContent>

                                                    <Text
                                                        onClick={() => handleSelectSelfAddress(userDeliveryDetails)}
                                                        align="center"
                                                        color={colors.deepBlue}
                                                        fontWeight="bold"
                                                        font="14px"
                                                        lineHeight="21px"
                                                        margin="3px 0 0 0"
                                                    >
                                                        Select
                                                    </Text>
                                                </ContentWrapper>
                                                <DividerWrapper bottom={'24px'}>
                                                    <Divider top={'0px'} bottom={'6px'} />
                                                    <Holder>
                                                        <Text
                                                            onClick={() => {
                                                                if (
                                                                    userDeliveryDetails.changeDeliveryAddressCount >=
                                                                    deliveryAddressEditLimit
                                                                ) {
                                                                    setShowUsedUpAccessPopup(true);
                                                                } else {
                                                                    setSelectedDeliveryInformation({
                                                                        ...userDeliveryDetails,
                                                                        firstName: user.firstName,
                                                                        lastName: user.lastName,
                                                                        contactType: 'SELF',
                                                                    });
                                                                    setShowEditPopup(true);
                                                                    setIsEditingContactAddress(false);
                                                                }
                                                            }}
                                                            color={'#5C6F7F'}
                                                            fontWeight="700"
                                                        >
                                                            Edit
                                                        </Text>
                                                        <Text
                                                            color={'#5C6F7F'}
                                                            fontWeight="400"
                                                            font="10px"
                                                            lineHeight="16px"
                                                        >
                                                            {`Edited ${
                                                                userDeliveryDetails?.changeDeliveryAddressCount || 0
                                                            } of ${deliveryAddressEditLimit} times`}
                                                        </Text>
                                                    </Holder>
                                                </DividerWrapper>
                                            </DetailsSection>
                                        </List>
                                        <Text margin="32px 0 8px 16px" color={colors.subGrey} fontWeight="700">
                                            YOUR CONTACTS
                                        </Text>
                                    </Fragment>
                                )}

                                {(!searchedOptions || searchedOptions.length < 1) && (
                                    <ContactWrapper>
                                        <ContactIcon />
                                        <Text margin="16px 0" color={colors.boldDark} fontWeight="bold" font="14px">
                                            You don't have any contacts
                                        </Text>
                                        <ContactIdeaIcon />
                                        <Text margin="16px 0" align="center" width="296px" color={colors.lightDark}>
                                            Try adding new contacts or updating your contact’s address information so it
                                            can appear here
                                        </Text>
                                    </ContactWrapper>
                                )}
                                {searchValue && searchValue.length > 0 && (
                                    <Text
                                        margin="5emx 1.5em 16px 1.5em"
                                        color={colors.subGrey}
                                        fontWeight="normal"
                                        font="12px"
                                        top="35px"
                                        align="center"
                                    >
                                        Showing{' '}
                                        <SearchLengthText>{searchedOptions && searchedOptions.length}</SearchLengthText>{' '}
                                        {searchedOptions && searchedOptions.length === 1 ? 'result' : 'results'}
                                    </Text>
                                )}
                                <List noBorderBottom={true}>
                                    {searchedOptions &&
                                        searchedOptions?.length > 0 &&
                                        searchedOptions?.map((contact) => {
                                            const {
                                                id,
                                                cordinates,
                                                deliveryInstructions,
                                                firstName,
                                                lastName,
                                                shopNumber,
                                                shopAddress,
                                                lga,
                                                changeAddressCount,
                                                city,
                                                shopName,
                                                phoneNumber,
                                                middleName,
                                                state,
                                            } = contact || {};

                                            return (
                                                <DetailsSection margin="4px 16px 12px 16px" key={id}>
                                                    <ContentWrapper height="141px">
                                                        <FlexContent>
                                                            {contact && (
                                                                <DetailWrapper>
                                                                    <InLineContainer>
                                                                        {shopName && (
                                                                            <UserAcronymn>{`${toAbbr(
                                                                                shopName,
                                                                            )}`}</UserAcronymn>
                                                                        )}
                                                                        {shopName && (
                                                                            <CardShopName>
                                                                                {`${toTitleCase(shopName)}`}
                                                                            </CardShopName>
                                                                        )}
                                                                    </InLineContainer>
                                                                    {shopName ? (
                                                                        <CardUserName>
                                                                            {`${firstName?.toUpperCase() || ''} ${
                                                                                lastName?.toUpperCase() || ''
                                                                            }`}
                                                                        </CardUserName>
                                                                    ) : (
                                                                        <CardUserName margin="5% 0 0 0">
                                                                            {`${firstName?.toUpperCase() || ''} ${
                                                                                lastName?.toUpperCase() || ''
                                                                            }`}
                                                                        </CardUserName>
                                                                    )}
                                                                    {shopName ? (
                                                                        <Text color={colors.lightDark} font="12px">
                                                                            {`${shopNumber ? shopNumber + ', ' : ''} 
                                                                        ${shopAddress || ''}`}
                                                                        </Text>
                                                                    ) : (
                                                                        <Text
                                                                            color={colors.lightDark}
                                                                            font="12px"
                                                                            style={{ marginTop: 40 }}
                                                                        >
                                                                            {`${shopNumber ? shopNumber + ', ' : ''} 
                                                                        ${shopAddress || ''}`}
                                                                        </Text>
                                                                    )}
                                                                    {lga && city && (
                                                                        <Text color={colors.lightDark} font="12px">
                                                                            {`${upperCaseToTitleCase(
                                                                                city,
                                                                            )}, ${lga} L.G.A.`}
                                                                        </Text>
                                                                    )}
                                                                </DetailWrapper>
                                                            )}
                                                        </FlexContent>

                                                        <Text
                                                            onClick={async () => {
                                                                setSelectedContact(contact);
                                                                setDeliveryInstruction(deliveryInstructions);
                                                                setLatitude(cordinates?.latitude);
                                                                setLongitude(cordinates?.longitude);
                                                                setMiddleName(middleName);
                                                                setSavedShopName(shopName);

                                                                const res = await getUpdatedDetail(phoneNumber);
                                                                if (
                                                                    res?.shops[0]?.details?.shopName &&
                                                                    shopName !== res?.shops[0]?.details?.shopName
                                                                ) {
                                                                    setShowNewInfoPopUp(true);
                                                                } else if (deliveryState !== state) {
                                                                    toast.error(
                                                                        `${`The user's state does not match your shopping location.`}`,
                                                                    );
                                                                } else if (
                                                                    !Object.prototype.hasOwnProperty.call(
                                                                        contact,
                                                                        'shopName',
                                                                    ) ||
                                                                    shopName === ''
                                                                ) {
                                                                    noUpdatedDetail(phoneNumber);
                                                                } else {
                                                                    handleSelectedContactAddress(contact);
                                                                }
                                                            }}
                                                            align="center"
                                                            color={colors.deepBlue}
                                                            fontWeight="bold"
                                                            font="14px"
                                                            lineHeight="21px"
                                                        >
                                                            Select
                                                        </Text>
                                                    </ContentWrapper>
                                                    <DividerWrapper>
                                                        <Divider top={'-10%'} bottom={'6px'} />
                                                        <Holder padding="6px">
                                                            <Text
                                                                onClick={() => {
                                                                    if (
                                                                        changeAddressCount >= deliveryAddressEditLimit
                                                                    ) {
                                                                        setShowUsedUpAccessPopup(true);
                                                                    } else {
                                                                        setSelectedDeliveryInformation({
                                                                            ...contact,
                                                                            contactType: 'CUSTOMER',
                                                                        });
                                                                        setShowEditPopup(true);
                                                                        setIsEditingContactAddress(true);
                                                                    }
                                                                }}
                                                                color={'#5C6F7F'}
                                                                fontWeight="700"
                                                            >
                                                                Edit
                                                            </Text>
                                                            <Text
                                                                color={'#5C6F7F'}
                                                                fontWeight="400"
                                                                font="10px"
                                                                lineHeight="16px"
                                                            >
                                                                {`Edited ${
                                                                    changeAddressCount || 0
                                                                } of ${deliveryAddressEditLimit} times`}
                                                            </Text>
                                                        </Holder>
                                                    </DividerWrapper>
                                                </DetailsSection>
                                            );
                                        })}
                                </List>
                            </ModifiedContainer>
                        </ScreenContainer>
                    </Fragment>
                )}
            </Fragment>
        );
};
