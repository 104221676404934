import React, { Fragment, useState, useRef, useEffect } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Switch from 'react-switch';
import styled from 'styled-components';

import { RippleButton, TopHeader, InputWithOnchange, UserAvatar } from '../../../../../../components';
import { ScreenContainer } from '../../../../../../containers/ScreenContainer';
import { getSpacesUserName } from '../../../../../../redux/ducks/account/wallet/actions';
import { colors } from '../../../../../../styles';
import { formatPrice } from '../../../../../../utils/currency/formatPriceWithComma';
import { formatCurrency } from '../../../../../../utils/currency/parseBalance';
import DesktopBackgroundLayout from '../../../../../DesktopBackgroundLayout';
import Spinner from '../assets/loader.gif';
import { ReactComponent as ValidUserSvg } from '../assets/success_mark.svg';

import { WalletToWalletValidationSchema } from './WalletToWalletValidationSchema';

const Title = styled.h2`
    font-size: 16px;
    font-weight: 700;
    color: ${({ color }) => color || '#071827'};
    text-align: left;
    margin: 16px 0px 6px;
`;

const Message = styled.h2`
    font-size: ${({ size }) => size || '14px'};
    font-weight: 400;
    color: ${({ color }) => color || '#718596'};
    text-align: left;
    line-height: 21px;
    margin-bottom: 6px;
    margin-left: ${({ left }) => left || '0px'};
    margin-top: ${({ top }) => top || null};
`;

const Count = styled(Message)`
    font-size: 10px;
    text-align: right;
    margin-right: 16px;
`;

const SwitchTrigger = styled(Switch)`
    & > .react-switch-bg {
        & > div {
            & > svg {
                display: none;
            }
        }
    }
`;

const VerticalDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

const SaveBeneficiaryWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 38px;
    width: 100%;
    align-items: center;
`;

const InputBlock = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const FlexContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    height: 85%;
    padding-bottom: 16px;
    justify-content: space-between;
`;

const WalletToWalletTransfer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const formRef = useRef();
    const { phoneNumber } = location.state || {};
    const walletBalance = useSelector((state) => state.account.wallet.balance || 0);
    const customers = useSelector((state) => state.applications.myShop.customers);
    const [narrationCount, setNarrationCount] = useState(0);
    const [receiverNumber, setReceiverNumber] = useState(phoneNumber);
    const [receiverName, setReceiverName] = useState('');
    const [saveBeneficiary, setSaveBeneficiary] = useState(false);

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (receiverNumber && receiverNumber.length === 11) {
                const res = await dispatch(getSpacesUserName(receiverNumber));
                if (res) {
                    setReceiverName(`${res.firstName} ${res.lastName}`);
                } else {
                    setReceiverName('');
                    // eslint-disable-next-line no-unused-expressions
                    formRef.current?.setTouched({ receiverPhoneNumber: true }, true);
                    return formRef.current?.setErrors({
                        receiverPhoneNumber: 'User does not exist',
                    });
                }
            } else {
                setReceiverName(undefined);
                setSaveBeneficiary(false);
            }
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [dispatch, receiverNumber]);

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Wallet Transfer'} />
                <ScreenContainer height="100%" top="64px">
                    <Title>Who would you like to transfer to?</Title>
                    <Message top={'4px'}>Kindly provide the details of the user you will be transferring to</Message>

                    <Formik
                        innerRef={formRef}
                        initialValues={{
                            receiverPhoneNumber: phoneNumber || '',
                            amount: '',
                            narration: '',
                        }}
                        validationSchema={WalletToWalletValidationSchema}
                        onSubmit={(values, { setErrors }) => {
                            history.push({
                                pathname: '/user/wallet_to_wallet_transfer_pin',
                                state: {
                                    ...values,
                                    name: receiverName,
                                    saveBeneficiary: saveBeneficiary,
                                },
                            });
                        }}
                    >
                        {({ values, errors, touched, setFieldValue, initialValues }) => (
                            <FlexContainer>
                                <InputBlock>
                                    <InputWithOnchange
                                        label={'Phone Number'}
                                        type={'text'}
                                        value={values.receiverPhoneNumber}
                                        placeholder={'Phone Number'}
                                        name="receiverPhoneNumber"
                                        valid={`${!touched.receiverPhoneNumber && !errors.receiverPhoneNumber}`}
                                        autoComplete={'off'}
                                        errors={
                                            touched &&
                                            touched.receiverPhoneNumber &&
                                            errors &&
                                            errors.receiverPhoneNumber
                                        }
                                        bottom="0px"
                                        height="56px"
                                        top="24px"
                                        maxLength={11}
                                        background="#F2F5FA"
                                        onChange={(e) => {
                                            setReceiverNumber(e.target.value);
                                            setFieldValue('receiverPhoneNumber', e.target.value);
                                        }}
                                        onKeyUp={(e) => {
                                            e.target.value = e.target.value.replace(/\s/g, '');
                                        }}
                                        onFocus={(e) => {}}
                                        noClearButton={true}
                                        initialValues={initialValues}
                                    >
                                        {values.receiverPhoneNumber.length === 11 && receiverName === undefined ? (
                                            <UserAvatar avatar={Spinner} width={'42px'} height={'42px'} />
                                        ) : null}
                                        {receiverName && <ValidUserSvg height="42px" />}
                                    </InputWithOnchange>
                                    {receiverName && (
                                        <Message left="16px" size={'10px'}>
                                            {receiverName}
                                        </Message>
                                    )}
                                    <InputWithOnchange
                                        label={'Amount'}
                                        type={'text'}
                                        value={formatCurrency(values.amount, 'NG')}
                                        placeholder={'Amount'}
                                        name="amount"
                                        valid={`${!touched.amount && !errors.amount}`}
                                        autoComplete={'off'}
                                        errors={touched && touched.amount && errors && errors.amount}
                                        bottom="0px"
                                        height="56px"
                                        top="16px"
                                        background="#F2F5FA"
                                        onChange={(e) => {
                                            const {
                                                target: { value: inputValue = 0 },
                                            } = e;
                                            const value = parseInt(inputValue.replace(/\D/g, '') || 0, 10);
                                            setFieldValue('amount', value);
                                        }}
                                        onKeyUp={(e) => {}}
                                        onFocus={(e) => {}}
                                        noClearButton={true}
                                        initialValues={initialValues}
                                    />
                                    <Message left="16px" size={'10px'}>{`Wallet Balance: ${formatPrice(
                                        walletBalance || 0,
                                    )}`}</Message>

                                    <InputWithOnchange
                                        label={'Narration'}
                                        type={'text'}
                                        value={values.narration}
                                        placeholder={'Narration'}
                                        name="narration"
                                        valid={`${!touched.narration && !errors.narration}`}
                                        autoComplete={'off'}
                                        errors={touched && touched.narration && errors && errors.narration}
                                        bottom="0px"
                                        height="56px"
                                        top="16px"
                                        maxLength={40}
                                        background="#F2F5FA"
                                        onChange={(e) => {
                                            setFieldValue('narration', e.target.value);
                                            setNarrationCount(e.target.value.length);
                                        }}
                                        onKeyUp={(e) => {}}
                                        onFocus={(e) => {}}
                                        noClearButton={true}
                                        initialValues={initialValues}
                                    />
                                    <Count>{`${parseInt(narrationCount)}/40`}</Count>

                                    {receiverNumber &&
                                        receiverNumber.length === 11 &&
                                        customers &&
                                        !customers.find((customer) => customer.phoneNumber === receiverNumber) && (
                                            <SaveBeneficiaryWrapper>
                                                <VerticalDiv>
                                                    <Message color={'#0B0C0E'}>Save as beneficiary</Message>
                                                    <Message size={'12px'}>
                                                        Send money to your friends with ease.
                                                    </Message>
                                                </VerticalDiv>
                                                <SwitchTrigger
                                                    onChange={() => setSaveBeneficiary(!saveBeneficiary)}
                                                    checked={saveBeneficiary}
                                                    onColor={'#227EFF'}
                                                    height={21}
                                                    width={43}
                                                    offColor={'#BBC0C9'}
                                                />
                                            </SaveBeneficiaryWrapper>
                                        )}
                                </InputBlock>
                                <RippleButton
                                    type="submit"
                                    top={'2px'}
                                    backgroundColor={colors.deepBlue}
                                    disabled={!values.amount || !values.receiverPhoneNumber || !receiverName}
                                >
                                    Continue
                                </RippleButton>
                            </FlexContainer>
                        )}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default WalletToWalletTransfer;
