import { USER_LOGOUT } from '../../user/constants';

import { USER_KYC_UPLOADING } from './constants';

const initialState = {
    isLoading: false,
};

export default function accountKyc(state = initialState, action) {
    switch (action.type) {
        case USER_KYC_UPLOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }

        case USER_LOGOUT: {
            return {
                isLoading: false,
            };
        }

        default: {
            return state;
        }
    }
}
