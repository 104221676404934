import React from 'react';

import { Help, HelpLogComplaint, ComplaintHistory, FAQPage } from '../../views';

export const helpRoutes = [
    {
        path: '/help',
        exact: true,
        main: () => <Help />,
        public: false,
    },
    {
        path: '/help/log_complaint',
        exact: true,
        main: () => <HelpLogComplaint />,
        public: false,
    },
    {
        path: '/help/complaint_history',
        exact: true,
        main: () => <ComplaintHistory />,
        public: false,
    },
    {
        path: '/help/FAQ',
        exact: true,
        main: () => <FAQPage />,
        public: false,
    },
];

export const helpRoutesWithHeader = helpRoutes;
