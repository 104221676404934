export const FilterComplaint = (type, complaints, setComplaint, faqFilter) => {
    const newComplaintsArray = [...complaints];
    if (type === 'All types') {
        setComplaint(complaints);
    } else {
        const newFaq = faqFilter.find((faq) => faq.translations[0].name === type);

        setComplaint(newComplaintsArray.filter((data) => data.category === newFaq.translations[0].name));
    }
};
