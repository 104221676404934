import React, { Fragment, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Loader, RippleButton, TopHeader } from '../../../components';
import { Message } from '../../../containers/MessageContainer';
import { ScreenContainer } from '../../../containers/ScreenContainer';
import { getUserPublicData } from '../../../redux/ducks/auth/login/actions';
import { colors } from '../../../styles';
import DesktopBackgroundLayout from '../../DesktopBackgroundLayout';

import { ReactComponent as LockedIcon } from './assets/locked_logo.svg';

const LockedContainer = styled.div`
    margin: 8px 16px 0 16px;
    padding-top: 103px;
    text-align: center;
`;

const LockedAccount = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);
    const userId = user && user.userId;

    useEffect(() => {
        (async () => {
            const data = await dispatch(getUserPublicData(userId));
            !data.barred && history.push('/');
            setLoading(false);
        })();
    }, [dispatch, history, userId]);

    return loading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader noTitle noBorderBottom />
                <ScreenContainer>
                    <LockedContainer>
                        <LockedIcon />
                        <Message bottom={'8px'} top="45px" weight="500" color="#253246" size="24px">
                            Your Account is Locked
                        </Message>
                        <Message size="14px" top="0">
                            Due to unusual activities that violate our{' '}
                            <strong style={{ color: colors.deepBlue }}> Terms</strong> and{' '}
                            <strong style={{ color: colors.deepBlue }}> Privacy Policy</strong> your account has been
                            locked.
                        </Message>
                        <a href="tel:01 888 5070">
                            <RippleButton>Contact Customer Support</RippleButton>
                        </a>
                    </LockedContainer>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default LockedAccount;
