import React, { Fragment, useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
    TopHeader,
    UserAvatar,
    RippleLink,
    Loader,
    CopyReferralBadge,
    SharePopup,
    ButtonWithShareIcon,
    QrShare,
    OptionsPopupDialog,
} from '../../../../components';
import { currentHost } from '../../../../config/API';
import { List, ListHeading, ListSubHeading } from '../../../../containers/ListContainer';
import { PageSeeAll } from '../../../../containers/MessageContainer';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { MODULE_INTERACTION } from '../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../utils/mix-panel/mixPanel';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import {
    PageTitle,
    StepsBlock,
    Step,
    StepText,
    StepHeading,
    StepDetails,
    BrokenLine,
    InfoIntroduction,
    SubInfoIntroduction,
    ModifiedListItem,
    HorizontalDiv,
    VerticalDiv,
    IconBlock,
} from '../styles';
import { getDate } from '../utils/date';

import { ReactComponent as AddIcon } from './assets/add.svg';
import { ReactComponent as CartIcon } from './assets/cart.svg';
import { ReactComponent as EarnIcon } from './assets/earn-more.svg';
import { ReactComponent as LinkIcon } from './assets/link.svg';
import { ReactComponent as QrIcon } from './assets/qr-icon.svg';
import { ReactComponent as ShareIcon } from './assets/share.svg';

const Container = styled.div`
    padding: 1em 0;
`;

const PageHeading = styled.div`
    position: relative;
    margin-top: ${({ top }) => top || '24px'};
    margin-bottom: 16px;
`;

const ReferralWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${({ top }) => top || '64px'};
`;

const BoldText = styled.span`
    font-weight: 700;
    color: #071827;
`;
const ReferralCodeTitle = styled.p`
    color: #253246;
    font-size: 14px;
    font-weight: 700;
    margin-top: 35px;
    margin-bottom: 0;
`;

const MerchantReferrals = () => {
    const isLoading = useSelector((state) => state.applications.merchants.isLoading);
    const referrals = useSelector((state) => state.applications.merchants.merchantsReferral);
    const agentCodeToShare = useSelector((state) => state.user.agentCodeToShare);
    const avatar = useSelector((state) => state.user.avatar);
    const firstName = useSelector((state) => state.user.firstName);
    const lastName = useSelector((state) => state.user.lastName);
    const url = `${currentHost}/launch?referralCode=${agentCodeToShare}`;
    const marketingMessage = `Hello, \n\nI am inviting you to join spaces, where you can create, manage and grow your store. \n\nVisit ${currentHost}/launch?referralCode=${agentCodeToShare}. \n\nKindly use my referral code ${agentCodeToShare} while signing up.`;
    const [openShareLinks, setOpenShareLinks] = useState(false);
    const [openOptions, setOpenOptions] = useState(false);
    const [openQrShare, setOpenQrShare] = useState(false);

    useEffect(() => {
        mixPanel.track(MODULE_INTERACTION, {
            'Icon Name': 'My Referrals',
            Time: new Date().toLocaleString(),
        });
    }, []);

    if (isLoading) {
        return <Loader />;
    } else {
        return (
            <Fragment>
                <DesktopBackgroundLayout>
                    {!openQrShare && (
                        <TopHeader
                            size="14px"
                            weight="400"
                            color="#071827"
                            noBorderBottom
                            title={'Refer & Earn'}
                            backLink="/more"
                        />
                    )}
                    <ScreenContainer padding={'16px'}>
                        {referrals && referrals.length > 0 ? (
                            <ReferralWrapper top="16px">
                                <PageHeading top="0">
                                    <HorizontalDiv>
                                        <PageTitle color="#253246">Your Referrals</PageTitle>
                                        <RippleLink
                                            to={{ pathname: '/actions/merchant_referrals_list', state: referrals }}
                                        >
                                            <PageSeeAll color="#227EFF" size="14px">
                                                See All
                                            </PageSeeAll>
                                        </RippleLink>
                                    </HorizontalDiv>
                                </PageHeading>
                                <List top={'16px'} noBorderBottom fullScreen>
                                    {referrals &&
                                        referrals.slice(0, 4).map((referral, index) => (
                                            <ModifiedListItem key={index} height={'48px'} bottom={'8px'}>
                                                <UserAvatar avatar={referral.avatar} width={'32px'} height={'32px'} />
                                                <VerticalDiv left={'16px'}>
                                                    <ListHeading color="#071827">{`${referral.firstName} ${referral.lastName}`}</ListHeading>
                                                    <ListSubHeading
                                                        style={{ fontSize: '12px' }}
                                                        color="#718596"
                                                        fontWeight="400"
                                                        maxWidth="100%"
                                                    >
                                                        Date Added: {getDate(referral.createdAt)}
                                                    </ListSubHeading>
                                                </VerticalDiv>
                                            </ModifiedListItem>
                                        ))}
                                </List>
                                <ReferralCodeTitle>My Referral Code</ReferralCodeTitle>
                            </ReferralWrapper>
                        ) : (
                            <Container>
                                <InfoIntroduction margin="0" align="left">
                                    You can <BoldText>add a merchant</BoldText> using your referral code or link.
                                </InfoIntroduction>
                                <SubInfoIntroduction font="16px">How it works</SubInfoIntroduction>
                                <StepsBlock>
                                    <Step>
                                        <IconBlock bgColor="#FDEDE7">
                                            <AddIcon />
                                        </IconBlock>
                                        <StepText>
                                            <StepHeading>Invite a Merchant</StepHeading>
                                            <StepDetails>Share your referral link or code.</StepDetails>
                                        </StepText>
                                    </Step>
                                    <BrokenLine height="5px" />
                                    <BrokenLine />
                                    <BrokenLine />
                                    <BrokenLine />
                                    <BrokenLine height="5px" />

                                    <Step>
                                        <IconBlock bgColor="#EDE6FE">
                                            <CartIcon />
                                        </IconBlock>
                                        <StepText>
                                            <StepHeading>Make sure they're active</StepHeading>
                                            <StepDetails>Send them activation reminders.</StepDetails>
                                        </StepText>
                                    </Step>
                                    <BrokenLine height="5px" />
                                    <BrokenLine />
                                    <BrokenLine />
                                    <BrokenLine />
                                    <BrokenLine height="5px" />

                                    <Step>
                                        <IconBlock bgColor="#D3FDE9">
                                            <EarnIcon />
                                        </IconBlock>
                                        <StepText>
                                            <StepHeading>Earn More!</StepHeading>
                                            <StepDetails>Earn commissions from merchant activities!</StepDetails>
                                        </StepText>
                                    </Step>
                                </StepsBlock>
                            </Container>
                        )}
                        <CopyReferralBadge top={'24px'} referralCode={agentCodeToShare} />
                        <ButtonWithShareIcon
                            icon={<ShareIcon />}
                            top="24px"
                            backgroundColor="#fff"
                            size="16px"
                            color="#227EFF"
                            onClick={() => setOpenOptions(!openOptions)}
                        />
                        <OptionsPopupDialog
                            open={openOptions}
                            title={'Options'}
                            headerPadding="16px 16px 24px"
                            popupPadding="16px"
                            cancel={() => setOpenOptions(!openOptions)}
                            items={[
                                {
                                    Icon: QrIcon,
                                    title: 'QR Code',
                                    more: false,
                                    click: () => {
                                        setOpenOptions(!openOptions);
                                        setOpenQrShare(!openQrShare);
                                    },
                                },
                                {
                                    Icon: LinkIcon,
                                    title: 'Share App Link',
                                    more: false,
                                    click: () => {
                                        setOpenOptions(!openOptions);
                                        setOpenShareLinks(!openShareLinks);
                                    },
                                },
                            ]}
                        />
                        {openShareLinks && (
                            <SharePopup
                                url={url}
                                marketingMessage={marketingMessage}
                                open={openShareLinks}
                                setOpen={setOpenShareLinks}
                            />
                        )}
                        {openQrShare && (
                            <QrShare
                                open={openQrShare}
                                cancel={() => setOpenQrShare(!openQrShare)}
                                referralCode={agentCodeToShare}
                                backLink="/actions/merchant_referrals"
                                avatar={avatar}
                                fullName={`${firstName} ${lastName}`}
                            />
                        )}
                    </ScreenContainer>
                </DesktopBackgroundLayout>
            </Fragment>
        );
    }
};

export default MerchantReferrals;
