import React, { Fragment, useState } from 'react';

import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { TopHeader, RippleButton, InputWithLabel, UploadPicture, Loader } from '../../../components';
import { ImageCropper } from '../../../components/popup/image-cropper';
import { InputBlock } from '../../../containers/InputContainer';
import { ScreenContainer } from '../../../containers/ScreenContainer';
import { checkExistingEmail } from '../../../redux/ducks/auth/check/actions';
import { updateUserData } from '../../../redux/ducks/auth/update-user-data/actions';
import { sendAvatar } from '../../../redux/ducks/user/actions/avatar';
import DesktopBackgroundLayout from '../../DesktopBackgroundLayout';

import { UpdatePersonalDetailsValidationSchema } from './UpdatePersonalDetailsValidationSchema';

const UpdateUserDataContainer = styled.div`
    margin-top: 25px;
`;

const UpdatePersonalDetails = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.auth.updateUserData.isLoading);
    const avatar = useSelector((state) => state.user.avatar);
    const firstName = useSelector((state) => state.user.firstName);
    const lastName = useSelector((state) => state.user.lastName);
    const msisdn = useSelector((state) => state.user.msisdn);
    const houseAddress = useSelector((state) => state.user.houseAddress);
    const email = useSelector((state) => state.user.email);
    const [showCropper, setShowCropper] = useState(false);
    const [picture, setPicture] = useState(avatar);
    const walletStatus = useSelector((state) => state.account.wallet.status);

    const onCropperCancel = () => {
        setShowCropper(false);
        setPicture(avatar);
    };
    const onCropperSave = async (url) => {
        if (url) {
            setShowCropper(false);
            setPicture(url);
            dispatch(sendAvatar(url));
        } else {
            toast.success('Image is still processing, wait for a sec...');
        }
    };

    const pAction = (image) => {
        const url = URL.createObjectURL(image);
        setShowCropper(true);
        setPicture(url);
    };

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                {showCropper && <ImageCropper avatar={picture} onCancel={onCropperCancel} onSave={onCropperSave} />}
                <TopHeader title={'Personal Details'} />

                <ScreenContainer>
                    <UpdateUserDataContainer>
                        <UploadPicture
                            text={'Tap to change profile picture'}
                            pictureAction={pAction}
                            showCropper={showCropper}
                            picture={picture}
                        />
                        <Formik
                            initialValues={{
                                firstName: firstName || '',
                                lastName: lastName || '',
                                houseAddress: houseAddress || '',
                                email: email || '',
                                msisdn: msisdn || '',
                            }}
                            validationSchema={UpdatePersonalDetailsValidationSchema}
                            onSubmit={async (values, { setErrors }) => {
                                if (!values.email) {
                                    values.email = `${msisdn}@spaceso2o.com`;
                                }
                                if (values.email === email) {
                                    delete values.email;
                                    dispatch(updateUserData(values));
                                } else {
                                    const hasExistingEmail = await dispatch(checkExistingEmail(values.email));
                                    if (!hasExistingEmail) {
                                        dispatch(updateUserData(values));
                                    } else {
                                        setErrors({ email: 'Email already exists' });
                                    }
                                }
                            }}
                        >
                            {({ errors, touched, values, setFieldValue, initialValues }) => (
                                <Form>
                                    <InputBlock>
                                        <InputWithLabel
                                            label={'First name'}
                                            type={'text'}
                                            value={values.firstName}
                                            placeholder={'First name'}
                                            name="firstName"
                                            valid={`${!touched.firstName && !errors.firstName}`}
                                            errors={touched && touched.firstName && errors && errors.firstName}
                                            setFieldValue={setFieldValue}
                                            initialValues={initialValues}
                                            readOnly={walletStatus === 'ACTIVE'}
                                            noClearButton={walletStatus === 'ACTIVE'}
                                        />

                                        <InputWithLabel
                                            label={'Last name'}
                                            type={'text'}
                                            value={values.lastName}
                                            placeholder={'Last name'}
                                            name="lastName"
                                            valid={`${touched.lastName && !errors.lastName}`}
                                            errors={touched && touched.lastName && errors && errors.lastName}
                                            setFieldValue={setFieldValue}
                                            initialValues={initialValues}
                                            readOnly={walletStatus === 'ACTIVE'}
                                            noClearButton={walletStatus === 'ACTIVE'}
                                        />
                                        <InputWithLabel
                                            label={'Email address'}
                                            type={'text'}
                                            value={values.email}
                                            placeholder={'Email address'}
                                            name={'email'}
                                            inputMode={'email'}
                                            autoComplete={'email'}
                                            valid={`${touched.email && !errors.email}`}
                                            errors={touched && touched.email && errors && errors.email}
                                            setFieldValue={setFieldValue}
                                            borderRadius={'4px'}
                                            onBlur={(e) => {
                                                if (!e.target.value) {
                                                    e.target.value = email;
                                                }
                                            }}
                                            onFocus={(e) => {
                                                if (values.email.startsWith(msisdn)) {
                                                    e.target.value = '';
                                                }
                                            }}
                                            initialValues={initialValues}
                                        />
                                        <InputWithLabel
                                            label={'House address'}
                                            type={'text'}
                                            value={values.houseAddress}
                                            placeholder={'House address'}
                                            name="houseAddress"
                                            valid={`${touched.houseAddress && !errors.houseAddress}`}
                                            errors={touched && touched.houseAddress && errors && errors.houseAddress}
                                            setFieldValue={setFieldValue}
                                            initialValues={initialValues}
                                            borderRadius={'4px'}
                                        />
                                        {!showCropper && (
                                            <RippleButton type="submit" size={'16px'} top={'50px'}>
                                                Save
                                            </RippleButton>
                                        )}
                                    </InputBlock>
                                </Form>
                            )}
                        </Formik>
                    </UpdateUserDataContainer>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default UpdatePersonalDetails;
