import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { emailRegExp } from '../../../../../utils/regexp/emailRegExp';
import { phoneRegExp } from '../../../../../utils/regexp/phoneRegExp';

export const EditCustomerValidationSchema = Yup.object().shape({
    name: Yup.string().min(1, 'Too Short!').max(25, 'Too long!').nullable().required('Required'),
    phoneNumber: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .test('maxLength', 'Can be maximum 11 digits (with 0)', function (value) {
            if (value && value.trim().startsWith('0') && value.trim().length > 11) {
                toast.error('Can be maximum 11 digits (with 0)');
                return false;
            } else return true;
        })
        .test('minLength', 'Can be maximum 11 digits (with 0)', function (value) {
            if (value && !value.trim().startsWith('0') && value.trim().length > 10) {
                toast.error('Can be maximum 11 digits (with 0)');
                return false;
            } else {
                return true;
            }
        })
        .nullable()
        .required('Required'),
    phoneNumber2: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .test('maxLength', 'Can be maximum 11 digits (with 0)', function (value) {
            if (value && value.trim().startsWith('0') && value.trim().length > 11) {
                return false;
            } else return true;
        })
        .test('minLength', 'Can be maximum 11 digits (with 0)', function (value) {
            if (value && !value.trim().startsWith('0') && value.trim().length > 10) {
                return false;
            } else return true;
        }),
    email: Yup.string().matches(emailRegExp, 'Email is not valid'),
    homeAddress: Yup.object().shape({
        address: Yup.string().when(
            ['homeAddress.address', 'homeAddress.state', 'homeAddress.lga', 'homeAddress.city'],
            {
                is: (address, state, lga, city) => address && (!state || !lga || !city),
                then: Yup.string('Please, provide your state, lga and city'),
                otherwise: Yup.string(),
            },
        ),
        state: Yup.string().when(['homeAddress.state', 'homeAddress.address', 'homeAddress.lga', 'homeAddress.city'], {
            is: (state, address, lga, city) => state && (!address || !lga || !city),
            then: Yup.string().required('Please, enter your home address, lga and city'),
            otherwise: Yup.string(),
        }),
        lga: Yup.string().when(['homeAddress.lga', 'homeAddress.state', 'homeAddress.address', 'homeAddress.city'], {
            is: (lga, state, address, city) => lga && (!state || !address || !city),
            then: Yup.string().required('Please, enter your home state, address and city'),
            otherwise: Yup.string(),
        }),
        city: Yup.string().when(['homeAddress.lga', 'homeAddress.state', 'homeAddress.address', 'homeAddress.city'], {
            is: (city, state, address, lga) => city && (!state || !address || !lga),
            then: Yup.string('Please, provide your state, lga and address'),
            otherwise: Yup.string(),
        }),
    }),
});
