import React, { Fragment, useEffect, useState } from 'react';

import algoliasearch from 'algoliasearch';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';
import aa from 'search-insights';
import styled from 'styled-components';
import useRouter from 'use-react-router';

import { ReactComponent as Descending } from '../../../../assets/high_to_low.svg';
import { ReactComponent as PriceSVG } from '../../../../assets/tag_price.svg';
import { ReactComponent as Ascending } from '../../../../assets/Vector.svg';
import {
    SearchHeader,
    OptionsPopupDialog,
    DeliveryLocationPopup,
    InfiniteScrollList,
    MerchbuyAddtoCartPopup,
    Loader,
} from '../../../../components/';
import { FilterAndSortSection, SortFilterCell } from '../../../../containers/ScreenContainer';
import { merchbuyActions } from '../../../../redux/ducks/applications/merchbuy/actions';
import { colors } from '../../../../styles';
import { CATEGORY_DETAILS, INITIATE_ADD_TO_CART } from '../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../utils/mix-panel/mixPanel';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { ReactComponent as ArrowIcon } from '../assets/arrow.svg';
import { Cart, SearchListProducts } from '../components/';
import { ReactComponent as BulbIcon } from '../search/assets/bulb.svg';
import { ReactComponent as SearchIcon } from '../search/assets/search.svg';
import { Main } from '../styles';
import { filterProducts } from '../utils/filterProducts';
import { sortProducts } from '../utils/sortProducts';

const Card = styled.div`
    display: grid;
    width: 100%;
    gap: 10px;
    borderradius: 8px;
`;

const List = styled.ul`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3px;
    margin-top: -10px;
`;

const CardImagePlaceHolder = styled.div`
    width: 100%;
    height: 100px;
`;

const IconCover = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: ${({ bottom }) => bottom || null};
`;

const EmptyContainer = styled.div`
    margin-top: 30px;
`;

const CoverText = styled.div`
    text-align: center;
    color: ${colors.themeTxtColor10};
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 12px;
`;

const PrimaryText = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: ${({ bottom }) => bottom || null};
    color: ${colors.themeTextColor11};
    font-size: 12px;
`;

const Dot = styled.div`
    margin-right: 5px;
    height: 5px;
    width: 5px;
    background-color: #5c6f7f;
    border-radius: 50%;
    position: relative;
    bottom: -5px;
`;

const algoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY,
);
const searchIndex = process.env.REACT_APP_ALGOLIA_SEARCH_INDEX_V2;
const searchClient = algoliaClient.initIndex(searchIndex);

const MerchbuyProducts = () => {
    const { location } = useRouter();
    const { pathname } = location;
    const pattern = `(.*)?${'/actions/merchbuy/products/:name/:id'}`;
    const match = matchPath(pathname, { path: pattern }) || {};
    const user = useSelector((state) => state.user);

    const [searchValue, setSearchValue] = useState('');
    const [sortType, setSortType] = useState('Sort');
    const [filterType, setFilterType] = useState('Filter');

    const [openFilterOptions, setOpenFilterOptions] = useState(false);
    const [page, setPage] = useState(0);
    const [sortOptionsOpen, setSortOptionsOpen] = useState(false);

    const [products, setProducts] = useState([]);

    const [filteredProducts, setFilteredProductProducts] = useState([]);
    const [showEmptyScreen, setShowEmptyScreen] = useState(false);

    const [deliveryLocation, setDeliveryLocation] = useState(JSON.parse(localStorage.getItem('deliveryLocation')));
    const [openDeliverLocationPopup, setOpenDeliverLocationPopup] = useState(deliveryLocation === null);
    const [allPages, setAllPages] = useState(0);
    const [showCartPopup, setShowCartPopup] = useState(false);
    const [selectedProd, setSelectedProd] = useState({});
    const [doAnimate, setDoAnimate] = useState(false);
    const [noSearch, setNoSearch] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [altLoader, setAltLoader] = useState(false);
    const pageScroll = sessionStorage.getItem('PAGE_SCROLL') && JSON.parse(sessionStorage.getItem('PAGE_SCROLL'));

    const handleClose = () => {
        setDoAnimate(true);
        setTimeout(() => {
            setShowCartPopup(!showCartPopup);
        }, 300);
    };

    const getLocation = (data) => {
        if (data.state) {
            setDeliveryLocation(data);
            setOpenDeliverLocationPopup(!openDeliverLocationPopup);
        }
    };

    const fireAnalyticEvents = () => {
        mixPanel.track(CATEGORY_DETAILS, {
            'Category name': match.params.name,
            'Category id': match.params.id,
        });

        aa('viewedFilters', {
            userToken: user.userId,
            index: searchIndex,
            eventName: 'Category products viewed',
            filters: [`categoryId:${match.params.id}`],
        });
    };

    const setScroll = () => {
        sessionStorage.setItem('PAGE_SCROLL', JSON.stringify({ x: window.scrollX, y: window.scrollY }));
    };

    const initiateAddToCart = (data) => {
        mixPanel.track(INITIATE_ADD_TO_CART, {
            'name of the product': data?.productName || data?.name,
            'product id': data?.productId || data?.id,
        });
    };

    const fetchProductsData = () => {
        if (match?.params?.id) {
            const chosenDeliveryLocation = deliveryLocation || { state: 'Lagos' };

            const query = () =>
                searchClient.search('', {
                    filters: `state:${chosenDeliveryLocation.state} AND onMerchBuy:true AND categoryId:${match.params.id} AND quantityInStock > 0`,
                    clickAnalytics: true,
                    userToken: user.userId,
                    enablePersonalization: true,
                    distinct: true,
                    hitsPerPage: 30,
                    page,
                });

            query().then((res) => {
                setPage(page + 1);
                setAllPages(res.nbPages);
                setProducts([...products, ...res.hits]);
                fireAnalyticEvents();
            });
        }
    };

    useEffect(() => {
        fetchProductsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (pageScroll) {
            window.scrollTo(parseInt(pageScroll.x), parseInt(pageScroll.y));
            setTimeout(() => {
                sessionStorage.removeItem('PAGE_SCROLL');
            }, 1000);
        }
    }, [pageScroll]);

    useEffect(() => {
        products &&
            setFilteredProductProducts(
                products.filter((data) => data.productName.toLowerCase().includes(searchValue.toLowerCase())),
            );
    }, [searchValue, products]);

    useEffect(() => {
        products && sortProducts(sortType, products, setFilteredProductProducts);
    }, [sortType, products, setFilteredProductProducts]);

    useEffect(() => {
        products && filterProducts(filterType, products, setFilteredProductProducts);
    }, [filterType, products, setFilteredProductProducts]);

    useEffect(() => {
        if (searchValue) {
            setTimeout(() => {
                setShowEmptyScreen(true);
            }, 2000);
        }
    }, [searchValue]);

    useEffect(() => {
        if (!searchValue && !filteredProducts?.length) {
            setShowEmptyScreen(true);
            setNoSearch(true);
        } else {
            setNoSearch(false);
        }
    }, [filteredProducts, searchValue]);

    useEffect(() => {
        if (page === 1) {
            setAltLoader(true);
        } else {
            setAltLoader(false);
        }
    }, [page]);

    if (showEmptyScreen && !altLoader && page === 0) return <Loader />;

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <SearchHeader
                    backgroundColor={colors.lightestGray}
                    title={match.params.name}
                    right={'56px'}
                    maxWidth={'150px'}
                    transform={'capitalize'}
                    sticky
                    placeholder={'Search for products...'}
                    handleSearch={setSearchValue}
                    withSpacesHeader
                >
                    <Cart />
                </SearchHeader>
                <Main color={colors.lightestGray}>
                    <FilterAndSortSection top={'7px'} bottom={'13px'} background={colors.white}>
                        <SortFilterCell
                            weight={'500'}
                            height={'40px'}
                            color={colors.themeTxtColor10}
                            justifyContent={'center'}
                            width={'100%'}
                            border={`1px solid ${colors.border.top}`}
                            onClick={() => setSortOptionsOpen(!sortOptionsOpen)}
                        >
                            <div>{sortType}</div>
                            <ArrowIcon style={{ marginLeft: '35px' }} />
                        </SortFilterCell>
                        <SortFilterCell
                            weight={'500'}
                            height={'40px'}
                            color={colors.themeTxtColor10}
                            justifyContent={'center'}
                            width={'100%'}
                            border={`1px solid ${colors.border.top}`}
                            onClick={() => setOpenFilterOptions(!openFilterOptions)}
                        >
                            <div>{filterType}</div>
                            <ArrowIcon style={{ marginLeft: '35px' }} />
                        </SortFilterCell>
                    </FilterAndSortSection>
                    {showEmptyScreen && !filteredProducts?.length && (
                        <EmptyContainer>
                            <IconCover>
                                <SearchIcon />
                            </IconCover>
                            <CoverText>We couldn't find any matches</CoverText>
                            {!noSearch && (
                                <div>
                                    <IconCover bottom={'19px'}>
                                        <BulbIcon />
                                    </IconCover>
                                    <PrimaryText bottom={'7px'}>
                                        <Dot></Dot>
                                        <div>Make sure the spelling is correct</div>
                                    </PrimaryText>
                                    <PrimaryText>
                                        <Dot></Dot>
                                        <div>Use less keywords</div>
                                    </PrimaryText>
                                </div>
                            )}
                        </EmptyContainer>
                    )}
                    {!filteredProducts?.length && !showEmptyScreen ? (
                        <List>
                            {Array(9)
                                .fill()
                                .map((_item, index) => (
                                    <Card key={index}>
                                        <CardImagePlaceHolder>
                                            <Skeleton
                                                width={`162px`}
                                                height={`107px`}
                                                style={{
                                                    gap: '10px',
                                                    marginTop: '10px',
                                                    borderRadius: '8px',
                                                    overflow: 'hidden',
                                                    perspective: '1px',
                                                    margin: '10px 10px 0px 10px',
                                                }}
                                            />
                                        </CardImagePlaceHolder>
                                        <Skeleton
                                            width={`109px`}
                                            height={`12px`}
                                            style={{
                                                margin: '12px 0 12px 11px',
                                            }}
                                        />
                                        <div style={{ marginTop: '-13px', marginLeft: '11px' }}>
                                            <Skeleton width={`47px`} height={`12px`} />
                                        </div>
                                    </Card>
                                ))}
                        </List>
                    ) : (
                        <InfiniteScrollList
                            fetchMore={fetchProductsData}
                            hasMore={page < allPages}
                            data={filteredProducts}
                            color={colors.lightestGray}
                        >
                            <SearchListProducts
                                size={{ width: '150px', height: '96px' }}
                                setShowCartPopup={setShowCartPopup}
                                setSelectedProd={setSelectedProd}
                                setDoAnimate={setDoAnimate}
                                data={filteredProducts}
                                showNumber
                                selectedIndex={selectedIndex}
                                setSelectedIndex={setSelectedIndex}
                                initiateAddToCart={initiateAddToCart}
                                setScroll={setScroll}
                            />
                        </InfiniteScrollList>
                    )}
                </Main>
                <OptionsPopupDialog
                    open={sortOptionsOpen}
                    title={'Sort By'}
                    itemPadding="10px 0"
                    itemMargin="0 16px"
                    cancel={() => {
                        setSortType('Sort');
                        setSortOptionsOpen(!sortOptionsOpen);
                    }}
                    items={[
                        {
                            Icon: Ascending,
                            title: 'Price: High to Low',
                            click: () => {
                                setSortOptionsOpen(!sortOptionsOpen);
                                sortType !== 'Highest Price' && setSortType('Highest Price');
                            },
                        },
                        {
                            Icon: Descending,
                            title: 'Price: Low to High',
                            click: () => {
                                setSortOptionsOpen(!sortOptionsOpen);
                                sortType !== 'Lowest Price' && setSortType('Lowest Price');
                            },
                        },
                        {
                            Icon: Ascending,
                            title: 'None',
                            click: () => {
                                setSortOptionsOpen(!sortOptionsOpen);
                                sortType !== 'Sort' && setSortType('Sort');
                            },
                        },
                    ]}
                />
                <OptionsPopupDialog
                    open={openFilterOptions}
                    title={'Filter'}
                    itemPadding="10px 0"
                    itemMargin="0 16px"
                    cancel={() => {
                        setFilterType('None');
                        setOpenFilterOptions(!openFilterOptions);
                    }}
                    items={[
                        {
                            Icon: PriceSVG,
                            title: 'N0 to N5,000',
                            click: () => {
                                setOpenFilterOptions(!openFilterOptions);
                                filterType !== 'N0 to N5,000' && setFilterType('N0 to N5,000');
                            },
                        },
                        {
                            Icon: PriceSVG,
                            title: 'N5,000 to N50,000',
                            click: () => {
                                setOpenFilterOptions(!openFilterOptions);
                                filterType !== 'N5,000 to N50,000' && setFilterType('N5,000 to N50,000');
                            },
                        },
                        {
                            Icon: PriceSVG,
                            title: 'N50,000 to N100,000',
                            click: () => {
                                setOpenFilterOptions(!openFilterOptions);
                                filterType !== 'N50,000 to N100,000' && setFilterType('N50,000 to N100,000');
                            },
                        },
                        {
                            Icon: PriceSVG,
                            title: 'N100,000 to N500,000',
                            click: () => {
                                setOpenFilterOptions(!openFilterOptions);
                                filterType !== 'N100,000 to N500,000' && setFilterType('N100,000 to N500,000');
                            },
                        },
                        {
                            Icon: PriceSVG,
                            title: 'N500,000 and Above',
                            click: () => {
                                setOpenFilterOptions(!openFilterOptions);
                                filterType !== 'N500,000 and Above' && setFilterType('N500,000 and Above');
                            },
                        },
                        {
                            Icon: PriceSVG,
                            title: 'None',
                            click: () => {
                                setOpenFilterOptions(!openFilterOptions);
                                filterType !== 'Filter' && setFilterType('Filter');
                            },
                        },
                    ]}
                />
                {openDeliverLocationPopup && (
                    <DeliveryLocationPopup
                        open={openDeliverLocationPopup}
                        getLocation={getLocation}
                        cancel={() => {
                            setOpenDeliverLocationPopup(!openDeliverLocationPopup);
                        }}
                    />
                )}
                {Object.keys(selectedProd).length > 0 && (
                    <MerchbuyAddtoCartPopup
                        open={showCartPopup}
                        data={selectedProd}
                        cancel={handleClose}
                        confirm={merchbuyActions.addProductToCart}
                        animate={doAnimate}
                    />
                )}
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default MerchbuyProducts;
