import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    serialNumber: Yup.string().nullable().required('Required'),
    numberOfHours: Yup.string().nullable().required('Required'),
    amount: Yup.string()
        .test('case 1', 'Invalid Amount', (value) => {
            if (value && value === '0') {
                return false;
            } else return true;
        })
        .nullable()
        .required('Required'),
    shsCustomerPhoneNumber: Yup.string().nullable().required('Required'),
});
