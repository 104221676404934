export const clearNativeCache = () => {
    const version = window?.NativeAndroid?.getString('pwa.version');
    fetch('/meta.json')
        .then((res) => res.json())
        .then((meta) => {
            if (version && meta.version !== version) {
                window?.NativeAndroid?.send('pwa.version', meta.version);
            }
        });
};
