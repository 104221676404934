import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import { axios, currentAPI } from '../../../../../../config';
import * as constants from '../../constants';

const baseUrl = `${currentAPI}/api/eazymoni`;
const airtimeBaseUrl = `${baseUrl}/airtime`;
const dataBaseURL = `${baseUrl}/dataPlan`;

export const getAirtimeProviders = () => async (dispatch) => {
    dispatch({ type: constants.LOADING_AIRTIME_PROVIDERS });

    try {
        const url = `${airtimeBaseUrl}/getAirtimeProviders`;
        const airtimeProvidersResponse = await axios.get(url);

        if (airtimeProvidersResponse.status === 200) {
            dispatch({
                type: constants.SET_AIRTIME_PROVIDERS,
                payload: airtimeProvidersResponse.data.responseData,
            });
            return airtimeProvidersResponse.data.responseData;
        }
    } catch (error) {
        dispatch({ type: constants.PAYMENT_ERROR, payload: error.message });
        console.error(error);
    }
};

export const getDataProviders = () => async (dispatch) => {
    dispatch({ type: constants.LOADING_DATA_PROVIDERS });
    try {
        const url = `${dataBaseURL}/getDataPlanProviders`;
        const dataProvidersResponse = await axios.get(url);

        if (dataProvidersResponse.status === 200) {
            dispatch({ type: constants.SET_DATA_PROVIDERS, payload: dataProvidersResponse.data.responseData });
            return dataProvidersResponse.data.responseData;
        }
    } catch (error) {
        dispatch({ type: constants.PAYMENT_ERROR, payload: error.message });
        console.error(error);
    }
};

export const purchaseAirtime =
    (amount, billerId, paymentItemCode = null, phoneNumber) =>
    async (dispatch) => {
        dispatch({ type: constants.PAYMENT_LOADING });

        try {
            const url = `${airtimeBaseUrl}/AirtimePlanPurchase`;
            const airtimePurchaseResponse = await axios.post(url, {
                amount,
                billerId,
                paymentItemCode,
                requesterId: phoneNumber,
            });

            if (airtimePurchaseResponse.status === 200) {
                dispatch({ type: constants.PAYMENT_SUCCESS });
                return airtimePurchaseResponse.status;
            }
        } catch (error) {
            dispatch({ type: constants.PAYMENT_ERROR, payload: error.message });
            console.error(error);
            if (error.response && error.response.status === 400) {
                const { data } = (error && error.response) || {};
                data.message && toast.error(error.response.data.message);
            }
        }
    };

export const getDataPlans = (billerId) => async (dispatch) => {
    try {
        const url = `${dataBaseURL}/getDataPlanProviderById?billerId=${billerId}`;
        const getDataPlansResponse = await axios.get(url);

        if (getDataPlansResponse.status === 200) {
            dispatch({ type: constants.SET_DATA_PLANS, payload: getDataPlansResponse.data.responseData });
            return getDataPlansResponse.data.responseData;
        }
    } catch (error) {
        console.error(error);
    }
};

export const purchaseDataPlan = (amount, billerId, paymentItemCode, requesterId) => async (dispatch) => {
    dispatch({ type: constants.PAYMENT_LOADING });
    try {
        const url = `${dataBaseURL}/Purchase`;
        const dataPlanPurchase = await axios.post(url, {
            amount,
            billerId,
            paymentItemCode,
            requesterId,
            transactionRef: uuid(),
        });

        if (dataPlanPurchase.status === 200) {
            dispatch({ type: constants.PAYMENT_SUCCESS });
            return dataPlanPurchase.status;
        }
    } catch (error) {
        dispatch({ type: constants.PAYMENT_ERROR, payload: error.message });
        console.error(error);
        if (error.response && error.response.status === 400) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.message);
        }
    }
};
