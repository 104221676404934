import React, { Fragment } from 'react';

import moment from 'moment';
import styled from 'styled-components';

import { PopUp, PopUpContent } from '../../../../../components/popup/common';
import { Overlay } from '../../../../../containers/OverlayContainer';
import { colors } from '../../../../../styles';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import { ReactComponent as Close } from '../assets/close.svg';

const Circle = styled.div`
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: #edf2f7;
    position: relative;
`;

const CloseIcon = styled(Close)`
    position: absolute;
    top: 9px;
    left: 8px;
`;

const Header = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.boldDark};
`;

const Wrapper = styled.div``;
const SecondWrapper = styled.div`
    padding: 12px 24px 12px 24px;
`;

const Title = styled.p`
    font-weight: 400;
    font-size: 14px;
    margin: ${({ margin }) => margin || '5px 0 0 0'};
    color: #718596;
    text-align: left;
    font-style: normal;
`;

const SubTitle = styled.p`
    font-weight: 400;
    font-size: 16px;
    color: ${colors.boldDark};
    text-align: left;
    line-height: 24px;
    margin: ${({ margin }) => margin || '5px 0 24px 0'};
`;

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 27px 16px 0px 16px;
    margin-bottom: 17px;
`;

const TopWrapper = styled.div`
    background: #edf2f7;
    display: flex;
    justify-content: space-around;
    border-radius: 4px;
    margin: 0 16px 11px 16px;
`;

const BottomWrapper = styled.div`
    background: #edf2f7;
    border-radius: 4px;
    margin: 0 16px 63px 16px;
`;

const BottomTextWrapper = styled.div`
    margin: 0;
`;

const ReminderHistoryPopup = ({ open, setOpen, reminder, debtorInfo }) => {
    const { name } = debtorInfo;
    const { dateSent, amount } = reminder;
    return (
        open && (
            <Fragment>
                <Overlay bgc="rgba(0, 0, 0, 0.4)">
                    <PopUp padding="0" open={open} zIndex={'10000'}>
                        <PopUpContent color="#F9FAFC" radius="20px 20px 0px 0px">
                            <TitleWrapper>
                                <Header>Reminder Details</Header>
                                <Circle onClick={() => setOpen(!open)}>
                                    <CloseIcon />
                                </Circle>
                            </TitleWrapper>
                            <TopWrapper>
                                <Wrapper>
                                    <Title margin={'24px 0 0 0'}>Date</Title>
                                    <SubTitle>{moment(dateSent, 'DD-MM-YYYY hh:mm:ss').format('MMM D, YYYY')}</SubTitle>
                                </Wrapper>
                                <Wrapper>
                                    <Title margin={'24px 0 0 0'}>Reminded Amount</Title>
                                    <SubTitle>{formatPrice(amount)}</SubTitle>
                                </Wrapper>
                            </TopWrapper>
                            <BottomWrapper>
                                <BottomTextWrapper>
                                    <SecondWrapper>
                                        <Title margin={'19px 0 0 0'}>Reminder Message</Title>
                                        <SubTitle>
                                            Dear {name}, Please kindly make payment for your outstanding debt of{' '}
                                            {formatPrice(amount)}
                                        </SubTitle>
                                    </SecondWrapper>
                                </BottomTextWrapper>
                            </BottomWrapper>
                        </PopUpContent>
                    </PopUp>
                </Overlay>
            </Fragment>
        )
    );
};

export default ReminderHistoryPopup;
