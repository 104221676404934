import React, { Fragment, useState, useEffect } from 'react';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as DeleteIcon } from '../../../../../assets/delete.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/edit.svg';
import {
    TopHeader,
    PageLogo,
    RippleButton,
    OptionsPopupDialog,
    ConfirmPopupDialog,
    UpdateStockPopup,
    Loader,
} from '../../../../../components';
import { ListHeading } from '../../../../../containers/ListContainer';
import { SubTitle, Message } from '../../../../../containers/MessageContainer';
import { ActionBlock } from '../../../../../containers/OverlayContainer';
import { ScreenContainer } from '../../../../../containers/ScreenContainer';
import { deleteProduct, updateProductQuantity } from '../../../../../redux/ducks/applications/my-shop/actions/shop';
import { colors } from '../../../../../styles';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import DefaultProductIcon from '../../assets/default_product.svg';
import headerBackground from '../../assets/header-background.svg';
import { ReactComponent as InfoIcon } from '../../assets/new-info-arrow.svg';
import EditProduct from '../edit-product';

const ProductDetailsWrapper = styled.div`
    display: flex;
    background-color: ${colors.white};
    padding: 16px;
    height: 72px;
    border-radius: 4px;
    width: calc(100% - 32px);
    margin: 0 auto;
    align-items: center;
    z-index: 9999;
    position: absolute;
    top: 110px;
`;

const ProductName = styled(ListHeading)`
    font-weight: 700;
    font-size: 16px;
    margin: 0;
    line-height: 24px;
`;

const DateText = styled(SubTitle)`
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    color: ${colors.lightDark};
`;

const DetailContainer = styled.div`
    margin: 4px -16px 0;
    padding: ${({ padding }) => padding || '16px'};
    display: flex;
    background-color: ${colors.white};
    justify-content: ${({ justify }) => justify && 'space-between'};
    align-items: ${({ align }) => align || 'center'};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${({ left }) => left};
`;

const PriceText = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: ${colors.lightDark};
    margin: 0;
`;

const Amount = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${colors.boldDark};
    margin: 0;
`;

const AddCostPrice = styled.p`
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    color: ${colors.deepBlue};
    text-transform: uppercase;
    margin: 0;
`;

const StockAmount = styled.p`
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: ${colors.themeTextColor3};
`;

const StockText = styled.span`
    color: ${({ color }) => color || colors.lightDark};
    font-weight: ${({ weight }) => weight || '400'};
    font-size: 12px;
    line-height: 18px;
    margin-top: ${({ top }) => top};
`;

const StockButton = styled(RippleButton)`
    width: 132px;
    height: 40px;
    border: 1px solid ${colors.deepBlue};
    color: ${colors.deepBlue};
    background-color: ${colors.white};
    margin: 0;
`;

const ProductDetails = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const shops = useSelector((state) => state.applications.myShop.shops);
    const isLoading = useSelector((state) => state.applications.myShop.isLoading);
    const selectedProduct = location.state?.product;
    const shopId = location.state?.shopId;
    const [openOptions, setOpenOptions] = useState(false);
    const [openEditProduct, setOpenEditProduct] = useState(false);
    const [confirmDeletionOpen, setConfirmDeletionOpen] = useState(false);
    const [openUpdateStock, setOpenUpdateStock] = useState(false);
    const product = shops
        .find((shop) => shop?.id === shopId)
        ?.inventory.find((product) => product?.id === selectedProduct?.id);

    useEffect(() => {
        setOpenUpdateStock(false);
    }, [shops]);

    const calculateProfit = () => {
        if (!selectedProduct.costPrice || !selectedProduct.retailUnitPrice || !product?.quantity) return;
        const profit =
            (Number(selectedProduct.retailUnitPrice) - Number(selectedProduct.costPrice)) * Number(product?.quantity);
        return profit;
    };

    const formatDate = (date) => {
        return moment(new Date(date)).format('Do MMMM, YYYY');
    };

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                {!openEditProduct && (
                    <TopHeader
                        size="14px"
                        color={colors.white}
                        weight="400"
                        title={'Product Details'}
                        backLink={'/actions/shop_inventory'}
                        background={`no-repeat center/cover url(${headerBackground}), rgba(34, 126, 255, .9)`}
                        height="145px"
                        backButtonTop="initial"
                    >
                        <ProductDetailsWrapper>
                            <PageLogo
                                width={'40px'}
                                height={'40px'}
                                iconWidth={'20px'}
                                iconHeight={'25px'}
                                objectFit="cover"
                                background={colors.gray4}
                                borderRadius="4px"
                                Icon={
                                    selectedProduct?.base64ProductImageString ||
                                    selectedProduct?.images?.baseImageUrl ||
                                    DefaultProductIcon
                                }
                            />
                            <Column left="8px">
                                <ProductName>{selectedProduct?.productName}</ProductName>
                                {selectedProduct?.localSettings?.localModifiedDate && (
                                    <DateText>{`Last update: ${formatDate(
                                        selectedProduct?.localSettings?.localModifiedDate,
                                    )}`}</DateText>
                                )}
                            </Column>
                        </ProductDetailsWrapper>
                    </TopHeader>
                )}
                <ScreenContainer
                    paddingBottom={'65px'}
                    top="170px"
                    flex="flex"
                    direction="column"
                    color={colors.lightestGray}
                >
                    <DetailContainer justify>
                        <PriceText>Cost Price</PriceText>
                        {selectedProduct?.costPrice ? (
                            <Amount>{formatPrice(selectedProduct?.costPrice)}</Amount>
                        ) : (
                            <AddCostPrice>Add Cost Price</AddCostPrice>
                        )}
                    </DetailContainer>
                    <DetailContainer justify>
                        <PriceText>Selling Price</PriceText>
                        <Amount>{formatPrice(selectedProduct?.retailUnitPrice)}</Amount>
                    </DetailContainer>
                    <DetailContainer align="flex-start" padding="13.64px 16px">
                        <InfoIcon />
                        <Column left="13.64px">
                            <Message weight="400" color={colors.themeTextColor5} top={'0'} lineHeight="unset">
                                You will earn{' '}
                                <span style={{ color: colors.textGreen, fontWeight: 700 }}>
                                    {formatPrice(calculateProfit())}
                                </span>{' '}
                                in profit
                            </Message>
                            {!selectedProduct?.costPrice && (
                                <SubTitle font="12px" weight="400" lineHeight="18px" color={colors.lightDark} top="4px">
                                    Add cost price to generate profit
                                </SubTitle>
                            )}
                        </Column>
                    </DetailContainer>

                    <StockText weigth="700" top="24px">
                        STOCK
                    </StockText>
                    <DetailContainer justify padding="9px 16px 15px">
                        <Column>
                            <StockAmount>{product && product?.quantity}</StockAmount>
                            {product?.quantity > 0 && product?.localSettings.hasInventory && (
                                <StockText>In Stock</StockText>
                            )}
                            {product?.quantity === 0 && product?.localSettings.hasInventory && (
                                <StockText color={colors.red}>Out of stock</StockText>
                            )}
                            {!product?.localSettings.hasInventory && (
                                <StockText color={colors.red}>No stock added</StockText>
                            )}
                        </Column>
                        <StockButton onClick={() => setOpenUpdateStock(!openUpdateStock)}>{`${
                            product?.localSettings.hasInventory ? 'Update' : 'Add'
                        } stock`}</StockButton>
                    </DetailContainer>

                    <ActionBlock direction={'row'} top={'40px'} bottom="10px">
                        <RippleButton
                            color={colors.textRed}
                            backgroundColor={colors.white}
                            style={{ border: `1px solid ${colors.textRed}` }}
                            onClick={() => setConfirmDeletionOpen(!confirmDeletionOpen)}
                        >
                            Delete
                        </RippleButton>

                        <RippleButton
                            onClick={() => {
                                product?.localSettings && product?.localSettings.hasInventory
                                    ? history.push({
                                          pathname: '/actions/shop_edit_single_product',
                                          state: selectedProduct,
                                      })
                                    : history.push({
                                          pathname: '/actions/shop_edit_single_incomplete_product',
                                          state: selectedProduct,
                                      });
                            }}
                        >
                            Edit Product
                        </RippleButton>
                    </ActionBlock>
                </ScreenContainer>

                {openUpdateStock && (
                    <UpdateStockPopup
                        open={openUpdateStock}
                        title={'Update stock'}
                        quantity={String(product && product.quantity)}
                        cancel={() => setOpenUpdateStock(!openUpdateStock)}
                        updateProductQuantity={(shopId, productId, quantity, qtyChng, updateType, productName) =>
                            dispatch(
                                updateProductQuantity(shopId, productId, quantity, qtyChng, updateType, productName),
                            )
                        }
                        shopId={shopId}
                        id={selectedProduct?.id}
                        productName={selectedProduct?.productName}
                    />
                )}
                <OptionsPopupDialog
                    open={openOptions}
                    title={'Options'}
                    cancel={() => setOpenOptions(!openOptions)}
                    items={[
                        {
                            Icon: EditIcon,
                            title: 'Edit Product Details',
                            link: '/actions/shop_edit_single_product',
                            linkProp: selectedProduct,
                            click: () => {
                                setOpenOptions(!openOptions);
                            },
                        },
                        {
                            Icon: DeleteIcon,
                            title: 'Delete Product',
                            click: () => {
                                setOpenOptions(!openOptions);
                                setConfirmDeletionOpen(!confirmDeletionOpen);
                            },
                        },
                    ]}
                />
                <ConfirmPopupDialog
                    open={confirmDeletionOpen}
                    title={'Are you sure you want to delete this product?'}
                    confirmationText={'Deleting a product will remove it from your inventory.'}
                    answers={[
                        {
                            variant: 'No',
                            action: () => setConfirmDeletionOpen(!confirmDeletionOpen),
                        },
                        {
                            variant: 'Yes',
                            action: async () => {
                                setConfirmDeletionOpen(!confirmDeletionOpen);
                                const res = await dispatch(deleteProduct(shopId, selectedProduct?.id, false));
                                res &&
                                    history.replace({
                                        pathname: '/actions/shop_inventory',
                                    });
                            },
                        },
                    ]}
                />
                {openEditProduct && (
                    <EditProduct
                        open={openEditProduct}
                        setOpen={setOpenEditProduct}
                        shopId={shopId}
                        product={selectedProduct}
                    />
                )}
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default ProductDetails;
