import { push } from 'connected-react-router';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { axios, currentAPI } from '../../../../../config';
import { countriesMap } from '../../../../../data/countries';
import { insertCountryCode, insertZero } from '../../../../../utils/inputs/formatPhoneNumber';
import { AUTH_ENTER_VALID_OTP } from '../../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../../utils/mix-panel/mixPanel';
import { resetCounter, setExpired } from '../../../timer/actions';
import { SAVE_USER_ID } from '../../../user/constants';
import { getUserPublicData } from '../../login/actions';
import {
    SENDING_CODE,
    SENDING_CODE_SUCCESS,
    SENDING_CODE_ERROR,
    LOADING,
    STOP_LOADING,
    INITIAL_PATH,
} from '../constants';

export const sendDeviceVerificationOtp = (msisdn) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
        const response = await axios.get(`${currentAPI}/api/otp/send/mobile/device/verification?msisdn=${msisdn}`);
        if (response.status === 200) {
            dispatch({ type: STOP_LOADING });
            toast.success('OTP was sent to your phone');
            return true;
        }
    } catch (error) {
        if (error.response && error.response.status === 403) {
            dispatch(push('locked'));
        }
    } finally {
        dispatch({ type: STOP_LOADING });
    }
};

export const sendCode =
    (code, startTime = new Date(), actionType) =>
    async (dispatch, getState) => {
        dispatch({ type: SENDING_CODE });

        try {
            const { userId, msisdn } = getState().user;

            const response = await axios.post(
                `${currentAPI}/api/otp/check?actionType=${actionType}&code=${code}&msisdn=${insertZero(msisdn)}`,
            );

            const userData = await dispatch(getUserPublicData(userId));

            if (response.status === 200) {
                mixPanel.track(AUTH_ENTER_VALID_OTP, {
                    Time: Math.round((new Date() - startTime) / 1000),
                });
                dispatch({ type: SENDING_CODE_SUCCESS });
                dispatch(resetCounter());

                if (userData === undefined) {
                    dispatch(push('/user/create_role'));
                } else if (userData && userData.registrationFinished) {
                    dispatch(push('/user/password_set'));
                } else if (userData.roleName === 'ROLE_USER') {
                    if (userData.firstName !== '') {
                        let saved = localStorage.getItem('userData');
                        saved = { ...userData };
                        localStorage.setItem('userData', JSON.stringify(saved));

                        dispatch(push('/user/create_user'));
                    } else {
                        dispatch(push('/user/create_role'));
                    }
                } else if (userData.roleName === 'ROLE_AGENT') {
                    if (userData.bankDataPresent) {
                        dispatch(push('/user/create_agent_group'));
                    } else if (userData.documentsUploaded) {
                        dispatch(push('/user/create_agent_bank_account'));
                    } else if (userData.country !== '') {
                        dispatch(push('/user/create_agent_identity'));
                    } else {
                        dispatch(push('/user/create_agent'));
                    }
                }
            }
        } catch (error) {
            dispatch({
                type: SENDING_CODE_ERROR,
                payload: error.message,
            });
            if (error.response && error.response.status === 400) {
                const { data } = (error && error.response) || {};
                data.message && toast.error(error.response.data.message);
            }
        }
    };

export const sendCodeFor403Validation = (code) => async (dispatch, getState) => {
    dispatch({ type: SENDING_CODE });

    try {
        const userId = getState().user.userId;

        const response = await axios.post(`${currentAPI}/api/otp/check?code=${code}&userId=${userId}`);

        if (response.status === 200) {
            dispatch({ type: SENDING_CODE_SUCCESS });
            dispatch(resetCounter());
            const history = useHistory();
            history.goBack();
        }
    } catch (error) {
        dispatch({
            type: SENDING_CODE_ERROR,
            payload: error.message,
        });
        //toast.error(error.response.data.error);
        if (error.response && error.response.status === 400) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.message);
        }
    }
};

export const sendOtpSmsForOnboarding = () => async (dispatch, getState) => {
    try {
        const { msisdn } = getState().user;

        const responseResendCode = await axios.get(
            `${currentAPI}/api/otp/send/mobile?actionType=ONBOARDING&msisdn=${encodeURIComponent(msisdn)}`,
        );

        if (responseResendCode.status === 200) {
            dispatch(resetCounter());
            dispatch({ type: SAVE_USER_ID, payload: responseResendCode.data.userId });
            return responseResendCode.data.userId;
        }
    } catch (error) {
        console.error(error);
    }
};

export const resendCode = () => async (dispatch, getState) => {
    try {
        const country = getState().user.country;
        const countryCode = countriesMap.get(country).code;
        const msisdn = insertCountryCode(getState().user.msisdn, countryCode);

        const responseResendCode = await axios.get(
            `${currentAPI}/api/otp/send/mobile?msisdn=${encodeURIComponent(msisdn)}`,
        );

        if (responseResendCode.status === 200) {
            dispatch(resetCounter());
        }
    } catch (error) {
        console.error(error);
    }
};

export const sendVoiceOtp = (actionType, phoneNumber) => async (dispatch) => {
    dispatch({ type: SENDING_CODE });

    try {
        const sendVoiceCodeResponse = await axios.get(
            `${currentAPI}/api/otp/send/voice/otp?actionType=${actionType}&msisdn=${encodeURIComponent(phoneNumber)}`,
        );

        if (sendVoiceCodeResponse.status === 200) {
            dispatch({ type: SENDING_CODE_SUCCESS });
            dispatch(setExpired(false));
            dispatch(resetCounter());
            dispatch({ type: SAVE_USER_ID, payload: sendVoiceCodeResponse?.data?.userId });
            return sendVoiceCodeResponse?.data?.userId;
        }
    } catch (error) {
        dispatch({ type: SENDING_CODE_ERROR, payload: error.message });
    }
};

export const sendUssd = () => async (dispatch, getState) => {
    dispatch({ type: SENDING_CODE });

    try {
        const country = getState().user.country;
        const countryCode = countriesMap.get(country).code;
        const msisdn = insertCountryCode(getState().user.msisdn, countryCode);

        const sendUssdCodeResponse = await axios.get(
            `${currentAPI}/api/otp/send/ussd?msisdn=${encodeURIComponent(msisdn)}`,
        );

        if (sendUssdCodeResponse.status === 200) {
            dispatch({ type: SENDING_CODE_SUCCESS });
            dispatch(setExpired(false));
            dispatch({
                type: SAVE_USER_ID,
                payload: sendUssdCodeResponse.data.userId,
            });
            toast.success(`Your pin code is: ${sendUssdCodeResponse.data.pinCode}`);
            dispatch(resetCounter());
        }
    } catch (error) {
        dispatch({ type: SENDING_CODE_ERROR, payload: error.message });
        //toast.error(error.response.data.error);
        if (error.response && error.response.status === 400) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.message);
        }
    }
};

export const sendOTPToWhatsapForOnboarding = () => async (dispatch, getState) => {
    dispatch({ type: SENDING_CODE });

    try {
        const { msisdn } = getState().user;

        const sendWhatsappCodeResponse = await axios.get(
            `${currentAPI}/api/otp/send/whatsapp?actionType=ONBOARDING&msisdn=${encodeURIComponent(msisdn)}`,
        );

        if (sendWhatsappCodeResponse.status === 200) {
            dispatch({ type: SENDING_CODE_SUCCESS });
            dispatch(setExpired(false));
            dispatch(resetCounter());
            dispatch({ type: SAVE_USER_ID, payload: sendWhatsappCodeResponse.data.userId });
            toast.success('Your OTP was sent to your Whatsapp account, associated with this phone number');
            return sendWhatsappCodeResponse.data.userId;
        }
    } catch (error) {
        dispatch({ type: SENDING_CODE_ERROR, payload: error.message });

        toast.error(
            'Sorry there is an error with sending an OTP to your Whatsapp. Please, choose another option to receive an OTP.',
        );
    }
};

export const newPinResendCode = (channelType) => async (dispatch, getState) => {
    try {
        const userId = getState().user.userId;

        const responseResendCode = await axios.get(
            `${currentAPI}/api/otp/resend/${channelType}?actionType=RESET_LOGIN_PIN&userId=${userId}`,
        );

        if (responseResendCode.status === 200) {
            dispatch(resetCounter());
            return responseResendCode.data.userId;
        }
    } catch (error) {
        console.error(error);
    }
};

export const newResendCode = (channelType) => async (dispatch, getState) => {
    try {
        const { userId } = getState().user;

        const responseResendCode = await axios.get(
            `${currentAPI}/api/otp/resend/${channelType}?actionType=ONBOARDING&userId=${userId}`,
        );
        if (responseResendCode.status === 200) {
            dispatch(resetCounter());
            return responseResendCode.data.userId;
        }
    } catch (error) {
        console.error(error);
    }
};

export const verifyDeviceOtp = (msisdn, code) => async (dispatch) => {
    dispatch({ type: SENDING_CODE });
    try {
        dispatch({ type: LOADING });
        const response = await axios.post(
            `${currentAPI}/api/otp/check/device/verification/code?msisdn=${msisdn}&code=${code}`,
        );
        if (response.status === 200) {
            dispatch({ type: SENDING_CODE_SUCCESS });
            dispatch(setExpired(false));
            localStorage.setItem('fingerPrint', response.data.fingerPrint);
            toast.success(response.data.description);
            dispatch(push('/login'));
        }
    } catch (error) {
        if (error.response && error.response.status === 400) {
            dispatch({ type: SENDING_CODE_ERROR, payload: error.response.data.error });
            toast.error(error.response.data.error);
        }
    } finally {
        dispatch({ type: STOP_LOADING });
    }
};

export const sendOTPToWhatsapp =
    (actionType, phoneNumber, forgotPassword = true) =>
    async (dispatch, getState) => {
        dispatch({ type: SENDING_CODE });
        const { msisdn } = getState().user;
        const number = phoneNumber || msisdn;
        try {
            const sendWhatsappCodeResponse = await axios.get(
                `${currentAPI}/api/otp/send/whatsapp?actionType=${actionType}&msisdn=${insertZero(number)}`,
            );

            if (sendWhatsappCodeResponse.status === 200) {
                forgotPassword && dispatch(push({ pathname: '/user/password_forgot' }));
                dispatch(setExpired(false));
                dispatch(resetCounter());
                const { userId } = sendWhatsappCodeResponse.data || {};
                dispatch({ type: SENDING_CODE_SUCCESS });
                return userId;
            }
        } catch (error) {
            dispatch({ type: SENDING_CODE_ERROR, payload: error.message });
        } finally {
            dispatch({ type: STOP_LOADING });
        }
    };

export const sendDeviceVerificationOtpViaChannelType = (channel, msisdn) => async (dispatch) => {
    dispatch({ type: SENDING_CODE });
    try {
        const response = await axios.get(
            `${currentAPI}/api/otp/send/mobile/device/verification/${channel}?msisdn=${msisdn}`,
        );
        if (response.status === 200) {
            dispatch({ type: SENDING_CODE_SUCCESS });
            dispatch(setExpired(false));
            dispatch(resetCounter());
            toast.success(response.data?.description);
            return true;
        }
    } catch (error) {
        dispatch({ type: SENDING_CODE_ERROR, payload: error.message });
        toast.error(
            `Sorry there is an error with sending an OTP to your ${
                channel === 'voice' ? 'phone' : 'whatsapp'
            } }. Please, choose another option to receive an OTP.`,
        );
    }
};

export const resendDeviceVerificationOtp = (channelType, msisdn) => async (dispatch) => {
    dispatch({ type: SENDING_CODE });
    try {
        const response = await axios.get(
            `${currentAPI}/api/otp/send/mobile/device/verification/resend?channelType=${channelType}&msisdn=${msisdn}`,
        );
        if (response.status === 200) {
            dispatch({ type: SENDING_CODE_SUCCESS });
            dispatch(setExpired(false));
            dispatch(resetCounter());
            toast.success(response.data?.description);
            return true;
        }
    } catch (error) {
        dispatch({ type: SENDING_CODE_ERROR, payload: error.message });
        toast.error(
            'Sorry there is an error with sending an OTP to your phone. Please, choose another option to receive an OTP.',
        );
    }
};

export const checkExistingEmail = (email) => async () => {
    if (!navigator.onLine) {
        toast.error('You can not perform this action offline...');
        return;
    }

    try {
        const checkExistingEmailResponse = await axios.post(`${currentAPI}/api/users/validate/email`, { email });

        if (checkExistingEmailResponse.status === 200) {
            return checkExistingEmailResponse.data;
        }
    } catch (error) {
        //
    }
};

export const checkReferralCode = (referralCode) => async () => {
    if (!navigator.onLine) {
        toast.error('You can not perform this action offline...');
        return;
    }

    try {
        const getAgentByReferralResponse = await axios.get(
            `${currentAPI}/api/users/agent/${referralCode}?referralCode=${referralCode}`,
        );

        if (getAgentByReferralResponse.status === 200) {
            const { agentCodeToShare } = getAgentByReferralResponse.data;
            return agentCodeToShare;
        }
    } catch (error) {
        if (error.response && error.response.status === 404) {
            toast.error('Invalid referral code');
        }
    }
};

export const setInitialPath = (path) => (dispatch) => {
    dispatch({ type: INITIAL_PATH, payload: path });
};
