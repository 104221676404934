import React, { Fragment, useState } from 'react';

import { FieldArray, Form, Formik } from 'formik';
//import { AddProductValidationSchema } from "./AddProductValidationSchema";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
    TopHeader,
    RippleButton,
    Loader,
    PageLogo,
    TextareaWithLabel,
    YesNoBlock2,
    SwitchTrigger2,
    InputWithOnchange,
    ImageStack,
} from '../../../../../../../components';
import { LightButton } from '../../../../../../../components/button';
import { InputBlock } from '../../../../../../../containers/InputContainer';
import { SubTitle, Message, Info } from '../../../../../../../containers/MessageContainer';
import { ScreenContainer, FlexContainer } from '../../../../../../../containers/ScreenContainer';
import * as actions from '../../../../../../../redux/ducks/applications/my-shop/actions/shop';
import { formatCurrency } from '../../../../../../../utils/currency/parseBalance';
//import { toast } from "react-toastify";
//import { toTitleCase } from '../../../../../../../utils/toTitleCase';
import DesktopBackgroundLayout from '../../../../../../DesktopBackgroundLayout';
import DefaultIcon from '../../assets/default_product.svg';
import DeleteIcon from '../../assets/delete.svg';
//import JumiaIcon from "../../assets/jumia.svg";
//import KongaIcon from "../../assets/konga.svg";
import DrinksIcon from '../../assets/drinks.svg';
import { ReactComponent as FormGroupSvg } from '../../assets/form_group.svg';
import FurnitureIcon from '../../assets/furniture.svg';
import { ReactComponent as InfoIcon } from '../../assets/info_big.svg';
import MerchBuyIcon from '../../assets/merchbuy.svg';
import MerchBuyLogo from '../../assets/merchbuy_logo.svg';
//import MerchListIcon from "../../assets/merchlist.svg";
import RulerIcon from '../../assets/ruler.svg';
import ShirtIcon from '../../assets/shirt.svg';
import SpoonPanIcon from '../../assets/spoon_pan.svg';
import { ReactComponent as TrachSMSvg } from '../../assets/trash_sm.svg';
import { AddProductCategory } from '../../components/product-category-select';
import { AddProductUnit } from '../../components/product-unit-select';

const AddProductContainer = styled.div`
    margin-top: 64px;
`;

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 12px 0 24px 0;
    padding: 16px;
    border-radius: 10px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
`;

const InfoMessage = styled(Info)`
    display: flex;
    align-items: top;
    padding: 32px;
    @media screen and (max-width: 420px) {
        padding: 16px;
    }
    margin-bottom: 8px;
`;

const FloatingBottomSection = styled.div`
    position: fixed;
    width: 100%;
    bottom: -18px;
    left: 0px;
    right: 0px;
    z-index: 10;
    background-color: #ffffff;
    margin-right: auto;
    margin-left: auto;
    max-width: 375px;
`;

const PVText = styled.h3`
    font-weight: 500;
    font-size: ${({ size }) => size || '10px'};
    color: ${({ colored }) => (colored ? '#22a8ff' : '#212c3d')};
    margin-top: 8px;
    margin-bottom: 4px;
    margin-left: ${({ left }) => left || '0px'};
`;

const Divider = styled.div`
    height: 1px;
    background: #f0f0f0;
    width: 100%;
    margin-top: ${({ top }) => top || '20px'};
    margin-bottom: ${({ bottom }) => bottom || '20px'};
`;

const SpacedBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: ${({ align }) => align || 'center'};
    margin: ${({ top }) => top || '0px'};
    padding: 16px;
`;

const MenuContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const OptionName = styled.h4`
    font-weight: ${({ fontWeight }) => fontWeight || '400'};
    color: #212c3d;
    font-size: 12px;
    margin-left: 8px;
    align-self: center;
`;

const DeleteButtonWrapper = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background-color: #f2f4f8;
`;

const OnlineSection = styled.div`
    display: flex;
    flex-direction: column;
`;

const FormGroupIcon = styled(FormGroupSvg)`
    position: absolute;
    z-index: 1;
    top: 26px;
    right: 20px;
`;

const MoqWrapper = styled.div`
    position: relative;
`;

const AddSingleProductStep2 = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const isLoading = useSelector((state) => state.applications.myShop.isLoading);
    const shops = useSelector((state) => state.applications.myShop.shops);
    const currentShop = shops[0];
    const [selectedCategory, setSelectedCategory] = useState({});
    const [openProductCategory, setOpenProductCategory] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState({});
    const [openProductUnit, setOpenProductUnit] = useState(false);
    const [online, setOnline] = useState(false);
    const [arrayImage, setArrayImage] = useState([]);
    const product = location.state || {};

    const addRemoveImage = (image, add) => {
        if (add) {
            arrayImage.push(image);
            setArrayImage(arrayImage);
        } else {
            setArrayImage(arrayImage.filter((e) => e !== image));
        }
    };

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                {!openProductCategory && <TopHeader title={'Add a product'} newHeader />}
                <ScreenContainer style={{ backgroundColor: '#f9fafc' }} paddingBottom={'100px'}>
                    <Formik
                        initialValues={{
                            productName: product?.productName || '',
                            productDescription: product?.productDescription || '',
                            productCategory: product?.productCategory || '',
                            productUnit: product?.productUnit || '',
                            costPrice: product?.costPrice || '',
                            quantity: product?.quantity || '',
                            moq: '',
                            localCreatedDate: product?.localCreatedDate || Date.now(),
                            retailUnitPrice: product?.retailUnitPrice || '',
                            availableAtRetailPrice: product?.availableAtRetailPrice || false,
                            availableAtWholesalePrice: product?.availableAtWholesalePrice || false,
                            bulkPrices: product?.bulkPrices || [{ price: '', moq: '' }],
                            base64ProductImageString: product?.base64ProductImageString || '',
                            otherImagesBase64String: product?.otherImagesBase64String || [],
                            images: product?.images || { baseImageUrl: '', others: [] },
                            masterListImageUrl: product?.masterListImageUrl || '',
                            localSettings: product?.localSettings || { hasInventory: true },
                        }}
                        // validationSchema={AddProductValidationSchema}
                        onSubmit={async (values, { setErrors }) => {
                            if (!online) {
                                const res = await dispatch(actions.addProductV2(currentShop.id, product, setErrors));
                                res &&
                                    res.status &&
                                    history.replace({
                                        pathname: '/actions/shop_single_product_success',
                                        state: { product: res.productData, source: 'saved' },
                                    });
                            } else {
                                values = {
                                    ...values,
                                    productCategory: selectedCategory.id,
                                    productUnit: selectedUnit.label,
                                };

                                const res = await dispatch(actions.addProductV2(currentShop.id, values, setErrors));
                                res &&
                                    res.status &&
                                    history.replace({
                                        pathname: '/actions/shop_single_product_success',
                                        state: { product: res.productData, source: 'saved' },
                                    });
                            }
                        }}
                    >
                        {({ errors, touched, values, setFieldValue, handleChange, initialValues }) => {
                            return (
                                <AddProductContainer>
                                    <Form>
                                        <InputBlock>
                                            <SectionContainer>
                                                <MenuContent>
                                                    <ImageWrapper>
                                                        <PageLogo
                                                            width={'32px'}
                                                            height={'32px'}
                                                            iconWidth={'32px'}
                                                            iconHeight={'32px'}
                                                            Icon={
                                                                product?.base64ProductImageString ||
                                                                product?.masterListImageUrl ||
                                                                DefaultIcon ||
                                                                ''
                                                            }
                                                        />
                                                        <OptionName>{product?.productName || ''}</OptionName>
                                                    </ImageWrapper>
                                                </MenuContent>
                                            </SectionContainer>

                                            <YesNoBlock2
                                                title={'Do you want to sell this product online?'}
                                                setAnswer={setOnline}
                                                answer={online}
                                            />

                                            <InfoMessage>
                                                <InfoIcon style={{ minWidth: '16px', marginRight: '8px' }} />
                                                <Message weight="500" size="12px" color="#212c3d" top={'0'}>
                                                    Listing a product online helps you reach a wider audience.
                                                    <span style={{ color: '#22a8ff', fontWeight: 500 }}>
                                                        {' '}
                                                        Learn More
                                                    </span>
                                                </Message>
                                            </InfoMessage>

                                            {online && (
                                                <OnlineSection>
                                                    <FlexContainer top={'16px'}>
                                                        <SubTitle color={'#212c3d99'} font="12px">
                                                            Product category
                                                        </SubTitle>
                                                    </FlexContainer>
                                                    <SectionContainer>
                                                        {selectedCategory &&
                                                        Object.keys(selectedCategory).length > 0 ? (
                                                            <MenuContent>
                                                                <ImageWrapper>
                                                                    <PageLogo
                                                                        width={'32px'}
                                                                        height={'32px'}
                                                                        iconWidth={'32px'}
                                                                        iconHeight={'32px'}
                                                                        Icon={
                                                                            selectedCategory.image || DefaultIcon || ''
                                                                        }
                                                                    />
                                                                    <OptionName fontWeight="500">
                                                                        {selectedCategory.name || ''}
                                                                    </OptionName>
                                                                </ImageWrapper>
                                                                <DeleteButtonWrapper>
                                                                    <img
                                                                        onClick={() => {
                                                                            setSelectedCategory(null);
                                                                        }}
                                                                        src={DeleteIcon}
                                                                        alt="delete"
                                                                        height="24px"
                                                                        width="24px"
                                                                    />
                                                                </DeleteButtonWrapper>
                                                            </MenuContent>
                                                        ) : (
                                                            <div>
                                                                <FlexContainer
                                                                    style={{
                                                                        justifyContent: 'center',
                                                                        marginBottom: '8px',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={SpoonPanIcon}
                                                                        alt="image1"
                                                                        height="56px"
                                                                        width="56px"
                                                                        style={{ margin: '0 7px 0 7px' }}
                                                                    />
                                                                    <img
                                                                        src={ShirtIcon}
                                                                        alt="image2"
                                                                        height="56px"
                                                                        width="56px"
                                                                        style={{ margin: '0 7px 0 7px' }}
                                                                    />
                                                                    <img
                                                                        src={DrinksIcon}
                                                                        alt="image3"
                                                                        height="56px"
                                                                        width="56px"
                                                                        style={{ margin: '0 7px 0 7px' }}
                                                                    />
                                                                    <img
                                                                        src={FurnitureIcon}
                                                                        alt="image4"
                                                                        height="56px"
                                                                        width="56px"
                                                                        style={{ margin: '0 7px 0 7px' }}
                                                                    />
                                                                </FlexContainer>
                                                                <SubTitle
                                                                    textAlign={'center'}
                                                                    color={'#212c3d99'}
                                                                    font="12px"
                                                                >
                                                                    To increase this product's online visibility, add a
                                                                    category that fits best.
                                                                </SubTitle>
                                                                <LightButton
                                                                    style={{
                                                                        padding: '12px 28px',
                                                                        marginTop: '16px',
                                                                    }}
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setOpenProductCategory(true);
                                                                    }}
                                                                    top={'24px'}
                                                                >
                                                                    Add a product category
                                                                </LightButton>
                                                            </div>
                                                        )}
                                                    </SectionContainer>
                                                    <FlexContainer>
                                                        <SubTitle color={'#212c3d99'} font="12px">
                                                            Product Unit
                                                        </SubTitle>
                                                    </FlexContainer>
                                                    <SectionContainer>
                                                        {selectedUnit && Object.keys(selectedUnit).length > 0 ? (
                                                            <MenuContent>
                                                                <OptionName fontWeight="500">
                                                                    {selectedUnit.value || ''}
                                                                </OptionName>
                                                                <DeleteButtonWrapper>
                                                                    <img
                                                                        onClick={() => {
                                                                            setSelectedUnit(null);
                                                                        }}
                                                                        src={DeleteIcon}
                                                                        alt="delete"
                                                                        height="24px"
                                                                        width="24px"
                                                                    />
                                                                </DeleteButtonWrapper>
                                                            </MenuContent>
                                                        ) : (
                                                            <div>
                                                                <FlexContainer
                                                                    style={{
                                                                        justifyContent: 'center',
                                                                        marginBottom: '8px',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={RulerIcon}
                                                                        alt="delete"
                                                                        height="56px"
                                                                        width="56px"
                                                                    />
                                                                </FlexContainer>
                                                                <SubTitle
                                                                    textAlign={'center'}
                                                                    color={'#212c3d99'}
                                                                    font="12px"
                                                                >
                                                                    Add the unit that this product would be sold in.
                                                                </SubTitle>
                                                                <LightButton
                                                                    style={{
                                                                        padding: '12px 28px',
                                                                        marginTop: '16px',
                                                                    }}
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setOpenProductUnit(true);
                                                                    }}
                                                                    top={'24px'}
                                                                >
                                                                    Add a product unit
                                                                </LightButton>
                                                            </div>
                                                        )}
                                                    </SectionContainer>

                                                    <FlexContainer>
                                                        <SubTitle color={'#212c3d99'} font="12px">
                                                            Product Description
                                                        </SubTitle>
                                                    </FlexContainer>
                                                    <TextareaWithLabel
                                                        name={'productDescription'}
                                                        errorTop={'41px'}
                                                        background={'#FFFFFF'}
                                                        value={values.productDescription}
                                                        placeholder={'Enter the description of this product'}
                                                        height={'96px'}
                                                        valid={`${
                                                            !touched.productDescription && !errors.productDescription
                                                        }`}
                                                        errors={
                                                            touched &&
                                                            touched.productDescription &&
                                                            errors &&
                                                            errors.productDescription
                                                        }
                                                        onChange={(e) =>
                                                            setFieldValue('productDescription', e.target.value)
                                                        }
                                                        onKeyUp={(e) => {}}
                                                        initialValues={initialValues}
                                                    />

                                                    <SubTitle color={'#212c3d'} font="14px">
                                                        Turn on the Marketplace(s) where you want this product to be
                                                        listed.
                                                    </SubTitle>

                                                    <InfoMessage style={{ margin: '8px 0 24px 0' }}>
                                                        <InfoIcon style={{ minWidth: '16px', marginRight: '8px' }} />
                                                        <Message weight="500" size="12px" color="#212c3d" top={'0'}>
                                                            The marketplaces available for you to sell this product is
                                                            based on your shop setup.
                                                            <br />
                                                            <span style={{ color: '#22a8ff', fontWeight: 500 }}>
                                                                {' '}
                                                                Change your setup
                                                            </span>
                                                        </Message>
                                                    </InfoMessage>

                                                    <SectionContainer>
                                                        <MenuContent>
                                                            <img src={MerchBuyIcon} alt="merchbuy" height="24px" />
                                                            <SwitchTrigger2
                                                                checkStatus={values.availableAtWholesalePrice}
                                                                switchStatus={(value) => {
                                                                    setFieldValue(
                                                                        'bulkPrices',
                                                                        product?.bulkPrices || [{ price: '', moq: '' }],
                                                                    );
                                                                    setFieldValue('availableAtWholesalePrice', value);
                                                                    setFieldValue('moq', '');
                                                                    addRemoveImage(MerchBuyLogo, value);
                                                                }}
                                                            />
                                                        </MenuContent>
                                                    </SectionContainer>

                                                    {/* <SectionContainer>
                                                <MenuContent>
                                                    <img
                                                        src={MerchListIcon}
                                                        alt="merchList"
                                                        height="24px" 
                                                    />
                                                    <SwitchTrigger2
                                                        checkStatus={values.availableAtRetailPrice}
                                                        switchStatus={(value)=> {
                                                            setFieldValue("availableAtRetailPrice", value)
                                                            addRemoveImage(ShirtIcon, value)
                                                        }}
                                                    />
                                                </MenuContent>
                                            </SectionContainer> */}

                                                    {values.availableAtWholesalePrice && (
                                                        <OnlineSection>
                                                            <InputWithOnchange
                                                                label={'Minimum order quantity'}
                                                                type={'text'}
                                                                inputMode={'numeric'}
                                                                placeholder={'Minimum order quantity'}
                                                                autoComplete={'off'}
                                                                name={'moq'}
                                                                bottom="16px"
                                                                height={'56px'}
                                                                background={'#FFFFFF'}
                                                                value={values.moq}
                                                                noClearButton={true}
                                                                onChange={(e) => {
                                                                    const {
                                                                        target: { value: inputValue = 0 },
                                                                    } = e;
                                                                    const value = inputValue.replace(/\D/g, '');
                                                                    setFieldValue('moq', value);
                                                                    setFieldValue('bulkPrices[0].moq', value);
                                                                }}
                                                                initialValues={initialValues}
                                                            />

                                                            <InfoMessage style={{ margin: '8px 0 24px 0' }}>
                                                                <InfoIcon
                                                                    style={{ minWidth: '16px', marginRight: '8px' }}
                                                                />
                                                                <Message
                                                                    weight="500"
                                                                    size="12px"
                                                                    color="#212c3d"
                                                                    top={'0'}
                                                                >
                                                                    Enter the smallest number of products a buyer can
                                                                    order.
                                                                </Message>
                                                            </InfoMessage>

                                                            <SectionContainer>
                                                                <InfoMessage style={{ margin: '8px 0 24px 0' }}>
                                                                    <InfoIcon
                                                                        style={{ minWidth: '16px', marginRight: '8px' }}
                                                                    />
                                                                    <Message
                                                                        weight="500"
                                                                        size="12px"
                                                                        color="#212c3d"
                                                                        top={'0'}
                                                                    >
                                                                        This is the price you set for this product based
                                                                        on the quantity the customer orders. E.g. 4
                                                                        cartons of indomie = ₦1,200.00.
                                                                    </Message>
                                                                </InfoMessage>

                                                                <FieldArray name="bulkPrices">
                                                                    {({ push, pop }) => (
                                                                        <Fragment>
                                                                            {values.bulkPrices.map(
                                                                                (bulkPrice, index) => (
                                                                                    <Fragment key={index}>
                                                                                        {index !== 0 && (
                                                                                            <SpacedBlock>
                                                                                                <Message
                                                                                                    top="0"
                                                                                                    size="12px"
                                                                                                >
                                                                                                    NEW RULE
                                                                                                </Message>
                                                                                                <TrachSMSvg
                                                                                                    onClick={() =>
                                                                                                        pop()
                                                                                                    }
                                                                                                />
                                                                                            </SpacedBlock>
                                                                                        )}
                                                                                        <MoqWrapper>
                                                                                            <FormGroupIcon />
                                                                                            <InputWithOnchange
                                                                                                label={
                                                                                                    'Wholesale unit price'
                                                                                                }
                                                                                                type={'text'}
                                                                                                inputMode={'numeric'}
                                                                                                placeholder={
                                                                                                    'Wholesale unit price'
                                                                                                }
                                                                                                autoComplete={'off'}
                                                                                                name={`bulkPrices[${index}].price`}
                                                                                                bottom="16px"
                                                                                                height={'56px'}
                                                                                                background={'#FFFFFF'}
                                                                                                value={formatCurrency(
                                                                                                    bulkPrice.price ||
                                                                                                        0,
                                                                                                    'NG',
                                                                                                )}
                                                                                                noClearButton={true}
                                                                                                onChange={(e) => {
                                                                                                    const {
                                                                                                        target: {
                                                                                                            value: inputValue = 0,
                                                                                                        },
                                                                                                    } = e;
                                                                                                    const value =
                                                                                                        parseInt(
                                                                                                            inputValue.replace(
                                                                                                                /\D/g,
                                                                                                                '',
                                                                                                            ) || 0,
                                                                                                            10,
                                                                                                        );
                                                                                                    setFieldValue(
                                                                                                        `bulkPrices[${index}].price`,
                                                                                                        value,
                                                                                                    );
                                                                                                }}
                                                                                                initialValues={
                                                                                                    initialValues
                                                                                                }
                                                                                            />
                                                                                            <InputWithOnchange
                                                                                                label={'Quantity'}
                                                                                                type={'text'}
                                                                                                inputMode={'numeric'}
                                                                                                placeholder={'Quantity'}
                                                                                                autoComplete={'off'}
                                                                                                name={`bulkPrices[${index}].moq`}
                                                                                                bottom="16px"
                                                                                                height={'56px'}
                                                                                                background={'#FFFFFF'}
                                                                                                value={bulkPrice.moq}
                                                                                                noClearButton={true}
                                                                                                onChange={(e) => {
                                                                                                    const {
                                                                                                        target: {
                                                                                                            value: inputValue = 0,
                                                                                                        },
                                                                                                    } = e;
                                                                                                    const value =
                                                                                                        inputValue.replace(
                                                                                                            /\D/g,
                                                                                                            '',
                                                                                                        );
                                                                                                    setFieldValue(
                                                                                                        `bulkPrices[${index}].moq`,
                                                                                                        value,
                                                                                                    );
                                                                                                    if (index === 0) {
                                                                                                        setFieldValue(
                                                                                                            'moq',
                                                                                                            value,
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                                initialValues={
                                                                                                    initialValues
                                                                                                }
                                                                                            />
                                                                                        </MoqWrapper>
                                                                                    </Fragment>
                                                                                ),
                                                                            )}
                                                                            <LightButton
                                                                                type="button"
                                                                                onClick={() =>
                                                                                    push({ price: '', moq: '' })
                                                                                }
                                                                                top={'8px'}
                                                                            >
                                                                                Add new pricing rule
                                                                            </LightButton>
                                                                        </Fragment>
                                                                    )}
                                                                </FieldArray>
                                                            </SectionContainer>
                                                        </OnlineSection>
                                                    )}
                                                </OnlineSection>
                                            )}

                                            <FloatingBottomSection>
                                                <Divider top={'0px'} bottom={'0px'} />
                                                <SpacedBlock>
                                                    <div>
                                                        <PVText>Ecommerce Listing(s)</PVText>
                                                        <ImageStack
                                                            arrayImage={arrayImage}
                                                            defaultIcon={DefaultIcon}
                                                            text="site(s)"
                                                        />
                                                    </div>
                                                    <RippleButton
                                                        style={{
                                                            width: '140px',
                                                            padding: '12px 28px',
                                                            marginTop: '0px',
                                                        }}
                                                        type="submit"
                                                        disabled={
                                                            (online &&
                                                                (!selectedCategory ||
                                                                    Object.keys(selectedCategory).length === 0 ||
                                                                    !selectedUnit ||
                                                                    Object.keys(selectedUnit).length === 0 ||
                                                                    !values.productDescription ||
                                                                    (values.availableAtWholesalePrice &&
                                                                        values.bulkPrices &&
                                                                        values.bulkPrices.some(
                                                                            (bulk) =>
                                                                                bulk && (!bulk.price || !bulk.moq),
                                                                        )))) ||
                                                            isLoading
                                                        }
                                                        top={'24px'}
                                                    >
                                                        SAVE
                                                    </RippleButton>
                                                </SpacedBlock>
                                            </FloatingBottomSection>
                                        </InputBlock>
                                    </Form>
                                </AddProductContainer>
                            );
                        }}
                    </Formik>
                </ScreenContainer>
                <AddProductCategory
                    open={openProductCategory}
                    setOpen={setOpenProductCategory}
                    category={(item) => {
                        setSelectedCategory(item);
                    }}
                />
                <AddProductUnit
                    open={openProductUnit}
                    setOpen={setOpenProductUnit}
                    unit={(item) => {
                        setSelectedUnit(item);
                    }}
                />
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default AddSingleProductStep2;
