import React from 'react';

import { string, number } from 'prop-types';
import styled from 'styled-components';

import { Badge } from '../../../containers/BadgeContainer';
import { SecondaryText, SubTitle } from '../../../containers/MessageContainer';

const BadgeWrapper = styled(Badge)`
    height: ${({ height }) => height || '88px'};
    margin-top: ${({ top }) => top || '78px'};
    background: linear-gradient(109.36deg, #fdede7 9.97%, #efddf6 94.46%);
    box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    @media (min-width: 576px) {
        margin-top: ${({ mTop }) => mTop || null};
    }
`;

const BadgeData = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 4px;
`;

const BadgeHeading = styled(SecondaryText)``;

const BadgeSubHeading = styled(SubTitle)`
    line-height: 30px;
    font-weight: 700;
    width: min-content;
`;

const MerchantsBadge = ({ title, merchantsCount, bottom, top, mTop }) => {
    return (
        <BadgeWrapper height={'88px'} bottom={bottom} top={top} mTop={mTop}>
            <BadgeData>
                <BadgeSubHeading font="20px" color="#212C3D">
                    {merchantsCount}
                </BadgeSubHeading>
                <BadgeHeading top="0" weight="400" color="#071827" lineHeight="18px">
                    {title}
                </BadgeHeading>
            </BadgeData>
        </BadgeWrapper>
    );
};

MerchantsBadge.propTypes = {
    title: string,
    merchantsCount: number,
    bottom: string,
    top: string,
};

export default MerchantsBadge;
