import React, { Fragment, useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { TopHeader, RippleLink, UserAvatar } from '../../../../../../components';
import { ScreenContainer } from '../../../../../../containers/ScreenContainer';
import DesktopBackgroundLayout from '../../../../../DesktopBackgroundLayout';
import { ReactComponent as ArrowIcon } from '../assets/arrow.svg';
import { ReactComponent as AddIcon } from '../assets/circle_add.svg';
import NoBeneficiaryIcon from '../assets/no_beneficiary.svg';

const Wrapper = styled.div`
    display: flex;
    position: relative;
    background-color: #e9f2ff;
    border-radius: 4px;
    align-items: center;
    padding: 20px;
    margin: 16px;
    top: 24px;
`;

const ArrowForward = styled(ArrowIcon)`
    position: absolute;
    color: #516c82;
    right: 16px;
    top: 34px;
`;

const Text = styled.h2`
    font-size: ${({ size }) => size || '14px'};
    font-weight: ${({ weight }) => weight || '400'};
    color: ${({ color }) => color || '#227EFF'};
    text-align: ${({ align }) => align || 'left'};
    padding: 2px;
    line-height: 18px;
    margin-left: ${({ left }) => left || null};
    margin-top: ${({ top }) => top || null};
`;

const ImageWrapper = styled.div`
    display: flex;
    width: 32px;
    height: 100%;
`;

const BeneficiaryWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-top: 38px;
    width: 100%;
`;

const ListItem = styled.div`
    display: flex;
    margin: 16px;
`;

const VerticalDiv = styled.div`
    diplay: flex;
    flex-direction: column;
    margin-left: 16px;
`;

const NoBeneficiaryWrapper = styled.div`
    diplay: flex;
    flex-direction: column;
    margin: 16px;
    padding: 24px 40px;
    align-items: center;
`;

const NoBeneficiaryLogo = styled.div`
    width: 100%;
    height: 100px;
    background-image: url(${({ icon }) => icon});
    background-repeat: no-repeat;
    background-position: center;
`;

const WalletToBankList = () => {
    const history = useHistory();
    const customers = useSelector((state) => state.applications.myShop.customers);
    const [beneficiaries, setBeneficiaries] = useState([]);

    useEffect(() => {
        setBeneficiaries(customers && customers.filter((customer) => customer.bank && customer.bank?.accountNumber));
    }, [customers]);

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Bank Transfer'} />
                <ScreenContainer padding="0px" top="64px">
                    <Wrapper onClick={() => history.push('/user/wallet_to_bank_transfer')}>
                        <ImageWrapper>
                            <AddIcon />
                        </ImageWrapper>
                        <Text left="16px">New Bank Account</Text>
                        <ArrowForward />
                    </Wrapper>

                    <BeneficiaryWrapper>
                        <Text color={'#718596'}>Recent Beneficiary</Text>
                        {beneficiaries && beneficiaries.length > 0 && (
                            <RippleLink to={{ pathname: '/user/wallet_to_bank_list_more' }}>
                                <Text weight="700">VIEW ALL</Text>
                            </RippleLink>
                        )}
                    </BeneficiaryWrapper>

                    {!beneficiaries || beneficiaries.length === 0 ? (
                        <NoBeneficiaryWrapper>
                            <NoBeneficiaryLogo icon={NoBeneficiaryIcon} />
                            <Text top="36px" color="#071827" weight="700" align="center">
                                No beneficiary saved
                            </Text>
                            <Text top="8px" color="#718596" size="12px" align="center">
                                You don't have any saved beneficiaries, add new bank account to continue.
                            </Text>
                        </NoBeneficiaryWrapper>
                    ) : (
                        <div>
                            {beneficiaries &&
                                beneficiaries.slice(0, 20).map((beneficiary, index) => (
                                    <ListItem
                                        key={index}
                                        onClick={() => {
                                            history.push({
                                                pathname: '/user/wallet_to_bank_transfer',
                                                state: beneficiary,
                                            });
                                        }}
                                    >
                                        <UserAvatar avatar={beneficiary.avatar} width={'32px'} height={'32px'} />
                                        <VerticalDiv>
                                            <Text size="12px" color="#071827">
                                                {beneficiary.name}
                                            </Text>
                                            <Text color="#718596">{`${beneficiary?.bank?.name || ''}, ${
                                                beneficiary?.bank?.accountNumber || ''
                                            }`}</Text>
                                        </VerticalDiv>
                                    </ListItem>
                                ))}
                        </div>
                    )}
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default WalletToBankList;
