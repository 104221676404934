import React, { Fragment, useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
    TopHeader,
    PageLogo,
    RippleButton,
    PaymentConfirmation,
    InputWithLabel,
    Loader,
    SelectBox,
} from '../../../../components';
import ValidatePasswordModal from '../../../../components/validate-password';
import { InputBlock } from '../../../../containers/InputContainer';
import { SubTitle } from '../../../../containers/MessageContainer';
import { ScreenContainer, FlexCenteredBlock } from '../../../../containers/ScreenContainer';
import * as actions from '../../../../redux/ducks/applications/bill-payments/actions/utility-services';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import History from '../../../../utils/History';
import { SuccessPage } from '../../../home';

import { ElectricityBillValidationSchema } from './ElectricityBillValidationSchema';

const SelectPaymentType = styled.section`
    margin-top: 24px;
    width: 100%;
`;

const ElectricityPay = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.applications.billPayments.isLoading);
    const allProvidersItems = useSelector((state) => state.applications.billPayments.allProvidersItems);

    const [selectedProvider] = useState(location.state);
    const [billerId] = useState(selectedProvider && selectedProvider.billerId);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [openPasswordModal, setOpenPasswordModal] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [isPrepaid, setIsPrepaid] = useState(false);
    const [tokenPin, setTokenPin] = useState();
    const [transactionData, setTransactionData] = useState({});

    if (!billerId) History.push('/actions/electricity');

    useEffect(() => {
        dispatch(actions.getUtilitiesProvidersItems(billerId));
    }, [dispatch, billerId]);

    const selectOptions = allProvidersItems
        ? allProvidersItems.map((providerItem) => ({ value: providerItem.name, label: providerItem.name }))
        : [];

    const { subChargeFee, logoUrl, name } = (selectedProvider && selectedProvider) || {};
    const { amount, paymentItemCode, meterNumber, fullName, billCategoryId } =
        (transactionData && transactionData) || {};

    return isLoading ? (
        <Loader />
    ) : (
        <>
            {paymentSuccess ? (
                <SuccessPage
                    header={'Successful Transaction'}
                    body={
                        isPrepaid
                            ? 'Your Token for this transaction is'
                            : `${selectedProvider.name} topup of ${formatPrice(amount)} was successful`
                    }
                    token={
                        isPrepaid
                            ? tokenPin
                                ? tokenPin.split(' ').join(' - ')
                                : 'xxxx - xxxx - xxxx - xxxx - xxxx'
                            : null
                    }
                    description={
                        isPrepaid
                            ? "If you didn't see a token on this page, you can still view it in the notification tray"
                            : ''
                    }
                />
            ) : (
                <Fragment>
                    {openPasswordModal ? (
                        <ValidatePasswordModal
                            title={'Electricity bill'}
                            isLoading={isLoading}
                            setOpen={setOpenPasswordModal}
                            open={openPasswordModal}
                            next={async () => {
                                const response = await dispatch(
                                    actions.utilityPayment(amount, billerId, paymentItemCode, meterNumber),
                                );
                                if (response.status === 200) {
                                    setPaymentSuccess(true);
                                    setTokenPin(response.data.responseData.tokenPIN);
                                }
                            }}
                        />
                    ) : (
                        <Fragment>
                            <TopHeader title={'Electricity Bill'} />
                            <ScreenContainer>
                                <FlexCenteredBlock top={'56px'}>
                                    <PageLogo Icon={logoUrl} width={'48px'} height={'48px'} top={'8px'} />
                                    <SubTitle font={12} top={'8px'}>
                                        {name}
                                    </SubTitle>

                                    <SelectPaymentType>
                                        <Formik
                                            initialValues={{
                                                amount: 0,
                                                meterNumber: '',
                                                paymentName: '',
                                            }}
                                            validationSchema={ElectricityBillValidationSchema}
                                            onSubmit={async (values, { setErrors }) => {
                                                const chosenItem = allProvidersItems.find(
                                                    (item) => item.name === values.paymentName,
                                                );
                                                setIsPrepaid(values.paymentName.toLowerCase() === 'prepaid');

                                                const validCustomer = await dispatch(
                                                    actions.validateUtilityCustomer(
                                                        billerId,
                                                        chosenItem.paymentItemCode,
                                                        values.meterNumber,
                                                        setErrors,
                                                    ),
                                                );

                                                if (!validCustomer) return;
                                                let updatedTransactionData = {
                                                    ...chosenItem,
                                                    meterNumber: values.meterNumber,
                                                    amount: values.amount,
                                                    fullName: validCustomer.fullName,
                                                };
                                                setTransactionData(updatedTransactionData);
                                                setOpenConfirmation(!openConfirmation);
                                            }}
                                        >
                                            {({
                                                errors,
                                                touched,
                                                setFieldValue,
                                                values,
                                                handleChange,
                                                initialValues,
                                            }) => (
                                                <Form>
                                                    <InputBlock top={'24px'}>
                                                        <SelectBox
                                                            name={'paymentName'}
                                                            setSelectedValue={setFieldValue}
                                                            placeholder={'Choose payment type'}
                                                            options={selectOptions}
                                                            value={values.paymentName}
                                                            handleChange={handleChange}
                                                            valid={`${!touched.paymentName && !errors.paymentName}`}
                                                            initialValues={initialValues}
                                                            error={
                                                                touched &&
                                                                touched.paymentName &&
                                                                errors &&
                                                                errors.paymentName
                                                            }
                                                        />
                                                        <InputWithLabel
                                                            label={'Amount'}
                                                            type={'number'}
                                                            value={values.amount}
                                                            placeholder={'Amount'}
                                                            name="amount"
                                                            inputMode={'decimal'}
                                                            valid={`${!touched.amount && !errors.amount}`}
                                                            errors={
                                                                touched && touched.amount && errors && errors.amount
                                                            }
                                                            setFieldValue={setFieldValue}
                                                            initialValues={initialValues}
                                                        />
                                                        <InputWithLabel
                                                            label={'Meter number'}
                                                            type={'text'}
                                                            value={values.meterNumber}
                                                            placeholder={'Meter number'}
                                                            name="meterNumber"
                                                            inputMode={'decimal'}
                                                            valid={`${touched.meterNumber && !errors.meterNumber}`}
                                                            errors={
                                                                touched &&
                                                                touched.meterNumber &&
                                                                errors &&
                                                                errors.meterNumber
                                                            }
                                                            setFieldValue={setFieldValue}
                                                            initialValues={initialValues}
                                                        />
                                                        <RippleButton
                                                            type="submit"
                                                            disabled={
                                                                Object.values(values).some(
                                                                    (value) => value.length === 0,
                                                                ) || isLoading
                                                            }
                                                        >
                                                            Continue
                                                        </RippleButton>
                                                    </InputBlock>
                                                    {openConfirmation && (
                                                        <PaymentConfirmation
                                                            open={openConfirmation}
                                                            close={setOpenConfirmation}
                                                            confirm={setOpenPasswordModal}
                                                            transactionDetails={{
                                                                fullName,
                                                                billCategoryId,
                                                                customer: meterNumber,
                                                                amount: Number(amount),
                                                                subChargeFee,
                                                                paymentItemCode,
                                                                providerLogo: logoUrl,
                                                                biller_name: name,
                                                                billerId,
                                                                subHeading: `Sub charge: ${formatPrice(
                                                                    parseInt(subChargeFee, 10),
                                                                )}`,
                                                            }}
                                                        />
                                                    )}
                                                </Form>
                                            )}
                                        </Formik>
                                    </SelectPaymentType>
                                </FlexCenteredBlock>
                            </ScreenContainer>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </>
    );
};

export default ElectricityPay;
