import React from 'react';

import { ModifiedBadge, PageSubSectionsTopLeftTitle, RecommendedProductWrapper } from '../../product-details/styles';
import { ProductCarousel } from '../listProducts/slideCarousel';

export const SuggestedProduct = ({ products, searchTerm, title, Icon }) => {
    return (
        <ModifiedBadge bottom="0">
            <RecommendedProductWrapper>
                <Icon />
                <PageSubSectionsTopLeftTitle>{title}</PageSubSectionsTopLeftTitle>
            </RecommendedProductWrapper>
            <ProductCarousel
                data={products}
                size={{ width: '105px', height: '98px' }}
                searchTerm={searchTerm}
                count={products?.length}
            />
        </ModifiedBadge>
    );
};
