import React, { Fragment, useState } from 'react';

import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { RippleButton } from '../../../../../components';
import { PopUp, PopUpContent } from '../../../../../components/popup/common';
import { Overlay } from '../../../../../containers/OverlayContainer';
import { editStorefrontProduct } from '../../../../../redux/ducks/applications/store-front/actions/store';
import { colors } from '../../../../../styles';
import { Text } from '../../storeFront';

import { ReactComponent as Add } from './assets/add.svg';
import { ReactComponent as Sub } from './assets/sub.svg';
import { ReactComponent as CloseIcon } from './assets/xclose.svg';

const TitleWrapper = styled.div`
    padding: 16px 0;
    position: relative;
    border-bottom: 1px solid #edf2f7;
`;

const IconWrapper = styled.div`
    position: absolute;
    left: 17px;
    top: 16px;
`;

const Header = styled.div`
    font-weight: 500;
    font-size: 14px;
    color: #212c3d;
`;

const Box = styled.div`
    display: flex;
    justify-content: flex-start;
`;

const SetStockDiv = styled.div`
    padding: 0 16px;
    text-align: left;
    margin-bottom: 24px;
`;

const SpanText = styled.span`
    font-weight: 700;
    font-size: 12px;
    color: #071827;
`;

const StockDiv = styled.div`
    padding: 8px 16px;
    background: #edf2f7;
    text-align: right;
    margin-bottom: ${({ bottom }) => bottom || '16px'};
`;

const TabOutterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px 0;
`;

const TabWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    background: ${colors.gray4};
    width: 248px;
    height: 40px;
    border-radius: 12px;
`;

const Circle = styled.div`
    background: ${({ background }) => background || '#227EFF'};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: ${({ display }) => display || 'flex'};
    justify-content: center;
    align-items: center;
    margin-right: ${({ right }) => right || null};
    margin-left: ${({ left }) => left || null};
    border: ${({ border }) => border || null};
`;

const Square = styled.div`
    background: ${({ background }) => background || '#F2F5FA'};
    width: 39px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${({ right }) => right || null};
    margin-left: ${({ left }) => left || null};
`;

const FlexButtonWrapper = styled.div`
    display: flex;
    padding: 0 16px 24px;
    margin-top: 100px;
`;

const StorefrontUpdateStock = ({ open, setOpen, product }) => {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(0);
    const [count, setCount] = useState(0);
    // const [altLoading, setAltLoading] = useState(false);

    const tabs = [
        {
            title: 'Add stock',
        },
        {
            title: 'Remove Stock',
        },
    ];

    const handleSubmit = (values, total, storeId, productId) => {
        const params = {
            name: values.name,
            imageUrl: [],
            price: values.price,
            description: values.description,
            stockQuantityThreshold: values.stockQuantityThreshold,
            quantityInStock: total,
        };
        dispatch(editStorefrontProduct(params, storeId, productId)).then((res) => {
            if (res) {
                setOpen();
                toast.success('Stock Quantity Successfully Updated');
            } else {
                toast.error('Stock Quantity Update Failed');
            }
        });
    };

    const total = selectedTab === 0 ? product.quantityInStock + count : product.quantityInStock - count;

    return (
        open && (
            <Fragment>
                <Overlay bgc="rgba(0, 0, 0, 0.4)" zIndex={'100000'}>
                    <PopUp padding="0" open={open} zIndex={'10000'}>
                        <PopUpContent color="#F9FAFC" radius="10px 10px 0px 0px">
                            <TitleWrapper>
                                <Header>Update stock</Header>
                                <IconWrapper onClick={() => setOpen()}>
                                    <CloseIcon />
                                </IconWrapper>
                            </TitleWrapper>
                            <TabOutterWrapper>
                                <TabWrapper>
                                    {tabs.map((tab, index) => (
                                        <RippleButton
                                            onClick={() => {
                                                setSelectedTab(index);
                                                setCount(0);
                                            }}
                                            key={index}
                                            width="130px"
                                            top="0"
                                            size="12px"
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            ptop={'5px'}
                                            color={selectedTab === index ? colors.border.active : colors.border.active}
                                            type="button"
                                            height="30px"
                                            radius="8px"
                                            backgroundColor={selectedTab === index ? colors.white : colors.gray4}
                                        >
                                            <Text weight="500" color="#212C3D">
                                                {tab.title}
                                            </Text>
                                        </RippleButton>
                                    ))}
                                </TabWrapper>
                            </TabOutterWrapper>
                            <StockDiv>
                                <Text color={'#5C6F7F'}>
                                    Available Stock : <SpanText>{product.quantityInStock}</SpanText>
                                </Text>
                            </StockDiv>
                            <SetStockDiv>
                                <Text size={'14px'} color={'#071827'} bottom={'16px'} weight={'500'}>
                                    {selectedTab === 0
                                        ? 'How many units do you want to add?'
                                        : 'How many units do you want to Remove?'}
                                </Text>

                                <Box>
                                    <Circle
                                        onClick={() => setCount(count - 1)}
                                        right={'8px'}
                                        background={colors.white}
                                        border={'1px solid #BBC0C9'}
                                        display={count === 0 ? 'none' : 'flex'}
                                    >
                                        <Sub />
                                    </Circle>
                                    <Square right={'8px'}>{count}</Square>
                                    <Circle onClick={() => setCount(count + 1)}>
                                        <Add />
                                    </Circle>
                                </Box>
                            </SetStockDiv>
                            <StockDiv>
                                <Text color={'#5C6F7F'}>
                                    Final Stock : <SpanText>{total}</SpanText>
                                </Text>
                            </StockDiv>
                            <FlexButtonWrapper>
                                <RippleButton
                                    size={'14px'}
                                    height={'48px'}
                                    border={`1px solid #718596`}
                                    color={'#718596'}
                                    backgroundColor={colors.white}
                                    right={'9px'}
                                    onClick={() => setOpen()}
                                >
                                    Cancel
                                </RippleButton>
                                <RippleButton
                                    size={'14px'}
                                    height={'48px'}
                                    onClick={() => handleSubmit(product, total, product.storeId, product.id)}
                                >
                                    Update
                                </RippleButton>
                            </FlexButtonWrapper>
                        </PopUpContent>
                    </PopUp>
                </Overlay>
            </Fragment>
        )
    );
};

export default StorefrontUpdateStock;
