import React from 'react';

import { bool, object, func } from 'prop-types';
import { useDispatch } from 'react-redux';
import { Route, Redirect, withRouter, useLocation } from 'react-router-dom';

import { setInitialPath } from '../redux/ducks/auth/check/actions';

const excludePaths = ['/more', '/user/account_settings'];

export const ProtectedRoute = withRouter(({ component, isAuthenticated }) => {
    let location = useLocation();
    const dispatch = useDispatch();

    // Get shared product detail URL in local storage
    const pathKey = 'Shared Product URL';
    const sharedURL = localStorage.getItem(pathKey);

    if (location.pathname && !excludePaths.includes(location.pathname)) {
        dispatch(setInitialPath(location.pathname));
    }

    if (isAuthenticated) {
        if (sharedURL) {
            localStorage.removeItem(pathKey);
            return <Redirect to={{ pathname: sharedURL }} />;
        }

        return <Route render={component} />;
    }

    // store shared sharedURL in a local storage
    if (location.pathname && !excludePaths.includes(location.pathname)) {
        localStorage.setItem(pathKey, location.pathname);
    }

    return <Redirect to={{ pathname: '/launch' }} />;
});

ProtectedRoute.propTypes = {
    isAuthenticated: bool,
    component: func,
    props: object,
    location: object,
};
