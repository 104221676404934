import React, { Fragment } from 'react';

import { Formik } from 'formik';
import { bool, func, string } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { InputBlock } from '../../../containers/InputContainer';
import { Overlay, ActionBlock } from '../../../containers/OverlayContainer';
import { ReactComponent as CloseIcon } from '../../../views/actions/my-shop/sales/assets/close.svg';
import { NewAmountInputWithLabel } from '../../forms/input/new-amount-input';
import { NewTextInputWithLabel } from '../../forms/input/new-text-input';
import { TopHeader, RippleButton } from '../../index';
import { PopUp, PopUpContent } from '../common';

import { AddProductOnTheFlyValidationSchema } from './AddProductOnTheFlyValidationSchema';

const Back = styled.div`
    width: 32px;
    height: 32px;
    position: absolute;
    left: 4px;
    top: 22px;
`;

const BottonWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    height: 48px;
    margin-bottom: 10px;
`;

export const AddProductOnTheFly = ({
    setOpen,
    open,
    shopId,
    addProduct,
    manageSelectedProducts,
    selectedProducts,
    passedValues,
    searchProductsOnMasterList,
}) => {
    const isLoading = useSelector((state) => state.applications.myShop.isLoading);
    const shops = useSelector((state) => state.applications.myShop.shops);
    const productsMasterList = useSelector((state) => state.applications.myShop.productsMasterList);
    const isOffline = useSelector((state) => state.offline.isOffline);
    const branchId = shops.find((shop) => shop.id === shopId).branchId;
    const dispatch = useDispatch();

    return (
        <Fragment>
            <Overlay onClick={() => setOpen(!open)} bgc={'rgba(0, 0, 0, 0.4)'} zIndex={'99999'} />
            <PopUp padding="0" open={open} zIndex={'100000'}>
                <PopUpContent bottom="0" padding={'16px'}>
                    <TopHeader
                        title={'Add Product'}
                        size={'16px'}
                        weight={'400'}
                        position={'relative'}
                        margin={'0'}
                        top={'0'}
                        noArrow
                    >
                        <Back onClick={() => setOpen(!open)}>
                            <CloseIcon />
                        </Back>
                    </TopHeader>
                    <Formik
                        initialValues={{
                            productName: passedValues ? passedValues.productName : '',
                            productUnit: '',
                            quantity: '1',
                            images: {
                                baseImageUrl: passedValues ? passedValues.base64ProductImageString : '',
                                others: [],
                            },
                        }}
                        validationSchema={AddProductOnTheFlyValidationSchema}
                    >
                        {({ errors, values, setFieldValue }) => (
                            <Fragment>
                                <InputBlock top={'16px'} bottom={'250px'}>
                                    <NewTextInputWithLabel
                                        label={'Product Name'}
                                        placeholder={'Product Name'}
                                        name="productName"
                                        list={'ProductList'}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'productName',
                                                e.target.value.trim().length ? e.target.value : e.target.value.trim(),
                                            );
                                        }}
                                        onKeyUp={(e) => {
                                            if (!isOffline) {
                                                dispatch(searchProductsOnMasterList(branchId, e.target.value));
                                                const productInMasterList =
                                                    productsMasterList &&
                                                    productsMasterList.find(
                                                        (product) => product.itemName === values.productName,
                                                    );

                                                if (productInMasterList && productInMasterList.masterListImageUrl) {
                                                    const imageUrl = productInMasterList.masterListImageUrl;
                                                    setFieldValue('images.baseImageUrl', imageUrl);
                                                    setFieldValue('masterListImageUrl', imageUrl);
                                                    setFieldValue('base64ProductImageString', imageUrl);
                                                }
                                            }
                                        }}
                                    />
                                    <NewAmountInputWithLabel
                                        label={'Product Price'}
                                        placeholder={'How much do you sell 1 unit?'}
                                        name="productUnit"
                                    />
                                </InputBlock>
                                <ActionBlock direction={'row'} top={'16px'}>
                                    <BottonWrapper>
                                        <RippleButton
                                            backgroundColor={'white'}
                                            border={'1px solid #718596'}
                                            color={'#718596'}
                                            width={'159px'}
                                            right={'10px'}
                                            top="0"
                                            onClick={() => setOpen(!open)}
                                        >
                                            Cancel
                                        </RippleButton>
                                        <RippleButton
                                            top="0"
                                            width={'159px'}
                                            type="submit"
                                            disabled={
                                                errors.productName || errors.productUnit || errors.quantity || isLoading
                                            }
                                            onClick={async () => {
                                                const response = await dispatch(addProduct(shopId, values));

                                                if (response && response.status) {
                                                    manageSelectedProducts(selectedProducts, {
                                                        ...response.product,
                                                        status: 'ON_THE_FLY',
                                                    });

                                                    setOpen(!open);
                                                } else
                                                    toast.error(
                                                        response.product || 'There is an error with adding a product',
                                                    );
                                            }}
                                        >
                                            Add Product
                                        </RippleButton>
                                    </BottonWrapper>
                                </ActionBlock>
                            </Fragment>
                        )}
                    </Formik>
                    <datalist id="ProductList">
                        {productsMasterList &&
                            productsMasterList.map((product, index) => <option key={index}>{product.itemName}</option>)}
                    </datalist>
                </PopUpContent>
            </PopUp>
        </Fragment>
    );
};

AddProductOnTheFly.propTypes = {
    open: bool,
    setOpen: func,
    addProduct: func,
    shopId: string,
};
