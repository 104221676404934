import React, { Fragment, useState, useEffect } from 'react';

import { string, func, bool } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { toDataURL } from '../../../../components/popup/image-cropper';
import { axios, currentAPI } from '../../../../config';
import { colors } from '../../../../styles';
import { compressImage } from '../../../../utils/files/compressImage';
import { fileToFormData } from '../../../../utils/files/fileToFormData';
import { ProgressBar } from '../../../progress';

import { ReactComponent as CancelIcon } from './assets/cancel.svg';
import { ReactComponent as FileIcon } from './assets/file_icon.svg';
import { ReactComponent as RetryIcon } from './assets/retry.svg';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ height }) => height || '64px'};
    margin: 4px 0 0 0;
    border-radius: 4px;
    background-color: #f2f5fa;
    width: 100%;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
`;

const UploadText = styled.h3`
    font-size: 14px;
    color: ${({ useFileName }) => (useFileName ? colors.textLightDark : colors.deepBlue)};
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 2px;
    verflow: hidden;
    text-overflow: ellipsis;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: ${({ direction }) => direction || 'row'};
    align-items: ${({ alignItem }) => alignItem || 'center'};
    justify-content: ${({ justify }) => justify || 'center'};
    width: 100%;
    height: 100%;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: ${({ left }) => left || '16px'};
    margin-right: ${({ right }) => right || '16px'};
`;

const FeedbackMessage = styled.div`
    color: ${({ color }) => color || '#E01A00'};
    font-size: 10px;
    font-weight: 400;
    line-height: 21px;
`;

const UploadButton = styled.input`
    position: absolute;
    right: 1em;
    left: 1em;
    width: 100%;
    appearance: button;
    cursor: pointer;
    opacity: 0;
    -webkit-tap-highlight-color: transparent;
`;

export const DEFAULT = 'default';
export const PROGRESS = 'progress';
export const FAILED = 'failed';
export const SUCCESS = 'success';

export const FileInput3 = ({
    name,
    value,
    mimeType,
    placeholder,
    label,
    handleChange,
    compressFile,
    base64String,
    useFileName,
    useFormData,
    height,
}) => {
    const dispatch = useDispatch();
    const msisdn = useSelector((state) => state.user.msisdn);
    const [status, setStatus] = useState(DEFAULT);
    const [progress, setProgress] = useState(0);
    const [file, setFile] = useState({});
    const [documentError, setDocumentError] = useState('');
    const [maxFileSize] = useState(1000 * 1024);

    const uploadFile = (file, msisdn, label) => async () => {
        setStatus(PROGRESS);
        setProgress(0.1);

        try {
            const formData = await fileToFormData(file.uri, file);

            setProgress(0.5);
            const res = await axios.post(
                `${currentAPI}/api/storage/uploadAgentDocuments/${encodeURIComponent(msisdn)}/${label}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            );

            setProgress(0.8);
            if (res.status === 200) {
                setStatus(SUCCESS);
                setProgress(0);
                handleChange(res.data, file.fileName);
            } else {
                setProgress(0);
                setStatus(FAILED);
            }
        } catch (error) {
            setProgress(0);
            setStatus(FAILED);
        }
    };

    const convertToBase64String = async (file) => {
        setStatus(PROGRESS);
        setProgress(0.1);
        try {
            setProgress(0.5);
            const image = await toDataURL(file.uri);
            setProgress(0.8);
            if (image) {
                setStatus(SUCCESS);
                setProgress(0);
                handleChange(image, file.fileName);
            } else {
                setProgress(0);
                setStatus(FAILED);
            }
        } catch (error) {
            setProgress(0);
            setStatus(FAILED);
        }
    };

    const handleUseFormData = async (file) => {
        setStatus(PROGRESS);
        setProgress(0.1);
        try {
            const formData = await fileToFormData(file.uri, file);
            setProgress(0.5);

            setProgress(0.8);
            if (formData) {
                setStatus(SUCCESS);
                setProgress(0);
                handleChange(formData, file.fileName);
            } else {
                setProgress(0);
                setStatus(FAILED);
            }
        } catch (error) {
            setProgress(0);
            setStatus(FAILED);
        }
    };

    const pickFile = async (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0];
            if (!compressFile && file.size > maxFileSize) {
                setDocumentError('File size exceeds 5mb');
                setStatus(FAILED);
            } else if (file.size > maxFileSize) {
                setDocumentError('');
                const data = await compressImage(event.target.files[0]);
                const fileData = {
                    uri: URL.createObjectURL(data),
                    type: mimeType,
                    name: label,
                    fileName: file.name,
                };
                setFile(fileData);
                useFormData && handleUseFormData(data);
                !base64String && !useFormData && dispatch(uploadFile(data, msisdn, label));
                base64String && convertToBase64String(data);
            } else {
                setDocumentError('');
                const fileData = {
                    uri: URL.createObjectURL(event.target.files[0]),
                    type: mimeType,
                    name: label,
                    fileName: file.name,
                };
                setFile(fileData);
                useFormData && handleUseFormData(fileData);
                !base64String && !useFormData && dispatch(uploadFile(fileData, msisdn, label));
                base64String && convertToBase64String(fileData);
            }
        }
    };

    useEffect(() => {
        if (value) {
            setStatus(SUCCESS);
        }
    }, [value]);

    return (
        <Fragment>
            <Container height={height}>
                {status === DEFAULT ? (
                    <Wrapper>
                        <UploadText>{placeholder || 'Tap to Upload Document here'}</UploadText>
                        <UploadButton type="file" id="identity" accept={mimeType} onChange={pickFile} />
                    </Wrapper>
                ) : (
                    <Wrapper>
                        <IconWrapper>
                            <FileIcon />
                        </IconWrapper>
                        <Wrapper direction={'column'} alignItem={'left'}>
                            <UploadText useFileName={useFileName}>
                                {useFileName ? file.fileName || label : label}
                            </UploadText>
                            {status === PROGRESS ? <ProgressBar step={progress} amount={1} /> : null}
                            {status === SUCCESS ? (
                                <FeedbackMessage color={'#05944F'}>Upload Successful</FeedbackMessage>
                            ) : null}
                            {status === FAILED ? (
                                <FeedbackMessage>{documentError || 'Upload Failed'}</FeedbackMessage>
                            ) : null}
                        </Wrapper>
                        {status === FAILED && !documentError && (
                            <IconWrapper
                                right={'0px'}
                                onClick={() => {
                                    useFormData && handleUseFormData(file);
                                    !base64String && !useFormData && dispatch(uploadFile(file, msisdn, label));
                                    base64String && convertToBase64String(file);
                                }}
                            >
                                <RetryIcon />
                            </IconWrapper>
                        )}
                        <IconWrapper
                            left={'8px'}
                            onClick={() => {
                                setFile({});
                                handleChange('');
                                setStatus(DEFAULT);
                            }}
                        >
                            <CancelIcon />
                        </IconWrapper>
                    </Wrapper>
                )}
            </Container>
        </Fragment>
    );
};
FileInput3.propTypes = {
    label: string.isRequired,
    mimeType: string,
    placeholder: string,
    compressFile: bool,
    handleChange: func,
};
