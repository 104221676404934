import React, { Fragment, useState } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { RippleButton } from '../../../components';
import { ScreenContainer } from '../../../containers/ScreenContainer';
import { colors } from '../../../styles';
import { formatPrice } from '../../../utils/currency/formatPriceWithComma';
import History from '../../../utils/History';

import back from './assets/Card_1.jpg';
import { ReactComponent as CartIcon } from './assets/cart.svg';
import { ReactComponent as CopyIcon } from './assets/copy.svg';
import EmptyStore from './assets/emptyStore.png';
import { ReactComponent as LinkIcon } from './assets/link.svg';
import { ReactComponent as ListIcon } from './assets/listedproducts.svg';
import StorefrontOrders from './storefront/orders';
import ListedProducts from './storefront/products';

const CartStyleIcon = styled(CartIcon)`
    width: 16px;
    height: 16px;
    margin-right: 4px;
`;

const ListStyleIcon = styled(ListIcon)`
    width: 16px;
    height: 16px;
    margin-right: 4px;
`;

const OuterLinkDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 0 16px;
`;

const LinkDiv = styled.div`
    display: flex;
    align-items: center;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: ${({ width }) => `repeat(auto-fill, ${width})`};
    grid-auto-flow: column;
    grid-auto-columns: ${({ width }) => width || null};
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
    }
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 22px;
`;

const OnlineSalesDetails = styled.div`
    background-image: url(${back});
    border-radius: 8px;
    width: 315px;
    height: 111px;
    margin-right: 4px;
    margin-left: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 320px) {
        width: 268px;
    }
`;

const CopyLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ebecff;
    border: 1px solid #cdd0fe;
    border-radius: 60px;
    width: 94px;
    height: 26px;
`;

export const Text = styled.div`
    font-size: ${({ size }) => size || '12px'};
    font-weight: ${({ weight }) => weight || '400'};
    color: ${({ color }) => color || '#02075C'};
    margin-bottom: ${({ bottom }) => bottom || null};
    margin-top: ${({ top }) => top || null};
    margin-left: ${({ left }) => left || null};
    margin-right: ${({ right }) => right || null};
    display: ${({ display }) => display || null};
    max-width: ${({ maxWidth }) => maxWidth || null};
    overflow-wrap: ${({ wrap }) => wrap || null};
    text-transform: ${({ transform }) => transform || null};
    text-align: ${({ align }) => align || null};
    width: ${({ width }) => width || null};
    justify-content: ${({ justifyContent }) => justifyContent || null};
    position: ${({ position }) => position || null};
    right: ${({ relRight }) => relRight || null};
`;

const TabOutterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 26px;
`;

const TabWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    background: ${colors.gray4};
    width: 248px;
    height: 40px;
    border-radius: 12px;
`;

export const TabContent = styled.div`
    margin-top: ${({ top }) => top || null};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
    width: 100%;
`;

const Wrapper = styled.div`
    padding: 32px 1em 50px 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fromRightTransform 0.5s ease;
    @keyframes fromRightTransform {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(0%);
        }
    }
`;

const EmptyTitle = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: ${colors.boldDark};
    margin: 32px 0 8px 0;
    text-align: center;
`;

const EmptyDesc = styled.div`
    line-height: 21px;
    font-size: 14px;
    color: ${colors.subGrey};
    max-width: 301px;
    text-align: center;
`;

const StoreFront = ({ selectedIndex }) => {
    const store = useSelector((state) => state.applications.storeFront.store);
    const storeFrontOrdersSummary = useSelector((state) => state.applications.storeFront.storeFrontOrdersSummary);
    const [selectedTab, setSelectedTab] = useState(selectedIndex ? selectedIndex : 0);
    const [text] = useState(`${window.location.host}/actions/store_front/${store?.link}`);
    const [copy, setCopy] = useState('Copy link');

    const copyText = async () => {
        await navigator.clipboard.writeText(text);
        setCopy('copied');
        setTimeout(() => {
            setCopy('Copy link');
        }, 2000);
    };

    const tabs = [
        {
            Icon: <ListStyleIcon />,
            title: 'Listed Products',
        },
        {
            Icon: <CartStyleIcon />,
            title: 'Orders',
        },
    ];

    return (
        <Fragment>
            {Object.keys(store).length > 0 ? (
                <ScreenContainer top={'0'} padding={'0'} color={colors.lightestGray}>
                    <OuterLinkDiv>
                        <LinkDiv>
                            <LinkIcon style={{ marginRight: '10px' }} />
                            <Text maxWidth={'120px'} wrap={'break-word'}>
                                {store?.link}
                            </Text>
                        </LinkDiv>
                        <CopyLink onClick={() => copyText()}>
                            <CopyIcon style={{ marginRight: '4px' }} />
                            <Text color="#3C48FC">{copy}</Text>
                        </CopyLink>
                    </OuterLinkDiv>
                    <GridContainer>
                        <OnlineSalesDetails>
                            <Text color={colors.white} bottom="5px">
                                Total Online Sale
                            </Text>
                            <Text color={colors.white} size="20px" weight="700">
                                {formatPrice(storeFrontOrdersSummary?.totalSales)}
                            </Text>
                        </OnlineSalesDetails>
                        <OnlineSalesDetails>
                            <Text color={colors.white} bottom="5px">
                                Total Number of Orders
                            </Text>
                            <Text color={colors.white} size="20px" weight="700">
                                {storeFrontOrdersSummary?.totalOrders?.toLocaleString()}
                            </Text>
                        </OnlineSalesDetails>
                    </GridContainer>
                    <TabOutterWrapper>
                        <TabWrapper>
                            {tabs?.map((tab, index) => (
                                <RippleButton
                                    onClick={() => setSelectedTab(index)}
                                    key={index}
                                    width="130px"
                                    top="0"
                                    size="12px"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    ptop={'5px'}
                                    color={selectedTab === index ? colors.border.active : colors.border.active}
                                    type="button"
                                    height="30px"
                                    radius="8px"
                                    backgroundColor={selectedTab === index ? colors.white : colors.gray4}
                                >
                                    {tab.Icon}
                                    <Text weight="500" color={colors.themeTextColor1}>
                                        {tab.title}
                                    </Text>
                                </RippleButton>
                            ))}
                        </TabWrapper>
                    </TabOutterWrapper>
                    {selectedTab === 0 ? <ListedProducts /> : <StorefrontOrders />}
                </ScreenContainer>
            ) : (
                <Wrapper>
                    <img src={EmptyStore} alt="empty store" />
                    <EmptyTitle>Sell online in 2 easy steps</EmptyTitle>
                    <EmptyDesc>
                        Setup your online store to be able to sell multiple products online on a single page.
                    </EmptyDesc>
                    <RippleButton onClick={() => History.push('/actions/store_setup')} height="48px" width="137px">
                        Get Started
                    </RippleButton>
                </Wrapper>
            )}
        </Fragment>
    );
};

export default StoreFront;
