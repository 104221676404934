import { countriesMap } from '../../data/countries';

export const formatPrice = (value, country = 'NG') => {
    // if (value === undefined || value === null || value === "") throw new Error("Price value is empty");

    return new Intl.NumberFormat(`en-${country}`, {
        style: 'currency',
        currency: countriesMap.get(country).currency.code,
    })
        .format(value || 0)
        .replace(/^(\D+)/, '$1 ');
};

export const breakUpFormatPrice = (value, country = 'NG') => {
    const price = formatPrice(value, country);
    const sign = price.substring(0, 1);
    const main = price.substring(2, price.length - 2);
    const sub = price.substring(price.length - 2);
    return [sign, main, sub];
};
