import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import { colors } from '../../../../../styles';

const Dot = styled.div`
    cursor: pointer;
    height: 8px;
    width: 8px;
    margin: 0 2px;
    background-color: ${(props) => (props.active ? colors.border.primary : colors.themeTextColor0)};
    border-radius: 50%;
`;

const DotCover = styled.div`
    display: flex;
    justify-content: center;
`;

const ScrollContainer = ({ count, target }) => {
    const [scrollProgress, setScrollProgress] = useState(0);
    const [score, setScore] = useState(60);

    const scrollListener = () => {
        if (!target.current) {
            return;
        }

        const element = target.current;
        const windowScroll = element.scrollLeft;
        const totalWidth = element.scrollWidth - element.clientWidth;
        if (windowScroll === 0) {
            return setScrollProgress(0);
        }

        if (windowScroll > totalWidth) {
            return setScrollProgress(100);
        }

        setScrollProgress((windowScroll / totalWidth) * 100);
    };

    const scroll = (scrollOffset, index) => {
        setScore(scrollOffset);
        if (index === 0) {
            if (target.current.scrollLeft === 0) {
                target.current.scrollLeft = target.current.scrollLeft + score;
            }
            target.current.scrollLeft = 0;
        } else if (index === 1) {
            if (target.current.scrollLeft === 0) {
                target.current.scrollLeft = target.current.scrollLeft + score;
            }
            target.current.scrollLeft = 60;
        } else if (index === 2) {
            if (target.current.scrollLeft === 0) {
                target.current.scrollLeft = target.current.scrollLeft + score * 2;
            }
            if (target.current.scrollLeft === 60) {
                target.current.scrollLeft = target.current.scrollLeft + score;
            }
            target.current.scrollLeft = 120;
        } else if (index === 3) {
            if (target.current.scrollLeft === 0) {
                target.current.scrollLeft = target.current.scrollLeft + score * 3;
            }
            if (target.current.scrollLeft === 60) {
                target.current.scrollLeft = target.current.scrollLeft + score * 2;
            }
            if (target.current.scrollLeft === 120) {
                target.current.scrollLeft = target.current.scrollLeft + score;
            }
        }
    };

    const renderDots = () => {
        const selectedDotValue = (scrollProgress * count) / 100;
        return [...Array(count).keys()].map((index) => (
            <Dot
                key={index}
                active={selectedDotValue >= index && selectedDotValue <= index + 1}
                onClick={() => scroll(60, index)}
            />
        ));
    };

    useEffect(() => {
        target.current.addEventListener('touchmove', scrollListener);
        target.current.addEventListener('scroll', scrollListener);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            target.current && target.current.removeEventListener('touchmove', scrollListener);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            target.current && target.current.removeEventListener('scroll', scrollListener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [target]);

    return <DotCover>{renderDots()}</DotCover>;
};

export default ScrollContainer;
