import { USER_LOGOUT } from '../../user/constants';

import { LOADING } from './constants';

const initialState = {
    isLoading: false,
    //availableStates: []
};

export default function shsReducer(state = initialState, action) {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }

        // case FETCH_POPULAR_PRODUCTS_FAILURE: {
        //     return {
        //         ...state,
        //         availableStates: []
        //     };
        // }

        case USER_LOGOUT: {
            return {
                isLoading: false,
                //availableStates: [],
            };
        }

        default: {
            return state;
        }
    }
}
