import React from 'react';

import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TopHeader } from '../../../../components';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { colors } from '../../../../styles';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import { Paragraph } from '../../../actions/merchbuy/group-buy/styles';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { transactionTypeEnum } from '../transaction-card';

import { ReactComponent as CommissionIcon } from './assets/commission.svg';

const PageHeader = styled.header`
    margin: 4px 0;
    padding: 8px 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const HeaderText = styled.p`
    font-size: 10px;
    color: ${colors.lightDark};
    margin: 0;
`;

const CommissionList = styled.div`
    display: grid;
    align-items: center;
`;

const CommissionLineItem = styled.div`
    display: grid;
    grid-template-columns: 32px 1fr 106px;
    align-items: center;
    column-gap: 16px;

    padding: 13.5px 16px 14.5px;
    background: white;

    border-top: 0.5px solid #e5e5e5;
    border-right: none;
    border-left: none;
`;

const Amount = styled.p`
    color: ${colors.textGreen};
    text-align: right;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    padding: 0;
`;

const TransactionDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const TransactionType = styled.p`
    font-size: 14px;
    line-height: 21px;
    margin: 0;
    padding: 0;
`;
const TransactionDate = styled.p`
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: ${colors.lightDark};
    margin: 0;
    padding: 0;
`;

const SummarySection = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: inherit;
    // adding this to ensure this is as large as the desktop container size

    background: ${colors.white};
    box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.04);

    z-index: 10;

    display: flex;
    justify-content: space-between;
    padding: 16px;
`;

const SummaryText = styled.p`
    margin: 0;
    padding: 0;

    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
`;

export default function Commission() {
    const commission = useSelector((state) => state?.account?.wallet?.commission);

    const transactionType = new Map([
        ...transactionTypeEnum.map((transactionType) => [transactionType.type, transactionType.value]),
    ]);

    return (
        <DesktopBackgroundLayout>
            <TopHeader title={'Commission Breakdown'} backLink={'/user/wallet_index'} withCancelIcon={true} />
            <ScreenContainer padding={'16px 0'}>
                <PageHeader>
                    <HeaderText>TRANSACTION</HeaderText>
                    <HeaderText>COMMISSION EARNED</HeaderText>
                </PageHeader>

                <CommissionList>
                    {commission?.loading ? (
                        Array(6)
                            .fill(1)
                            .map((_, index) => (
                                <CommissionLineItem key={index}>
                                    <CommissionIcon />
                                    <TransactionDetails>
                                        <TransactionType>
                                            <Skeleton width={'150px'} height={'10px'} />
                                        </TransactionType>
                                        <TransactionDate>
                                            <Skeleton width={'50px'} height={'10px'} />
                                        </TransactionDate>
                                    </TransactionDetails>
                                    <Amount>
                                        <Skeleton width={'50px'} height={'10px'} />
                                    </Amount>
                                </CommissionLineItem>
                            ))
                    ) : commission?.userCommissionBreakDown?.content?.length > 0 ? (
                        commission?.userCommissionBreakDown?.content?.map((lineItem) => (
                            <CommissionLineItem key={lineItem?.id}>
                                <CommissionIcon />
                                <TransactionDetails>
                                    <TransactionType>
                                        {transactionType.get(lineItem?.commissionServiceType)}
                                    </TransactionType>
                                    <TransactionDate>{new Date(lineItem?.createdDate).toDateString()}</TransactionDate>
                                </TransactionDetails>
                                <Amount>{formatPrice(lineItem?.userCommissionEarning)}</Amount>
                            </CommissionLineItem>
                        ))
                    ) : (
                        <Paragraph margin={'40px auto 0'}>No Pending Commission</Paragraph>
                    )}
                </CommissionList>
            </ScreenContainer>
            <SummarySection>
                <SummaryText>Total Commission</SummaryText>
                <SummaryText>
                    {commission?.loading ? (
                        <Skeleton width={'50px'} height={'10px'} />
                    ) : (
                        commission?.totalCommissionEarned
                    )}
                </SummaryText>
            </SummarySection>
        </DesktopBackgroundLayout>
    );
}
