import React from 'react';

import { any } from 'prop-types';
import { useSelector } from 'react-redux';
import aa from 'search-insights';

import { RippleLink } from '../../../../../components';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import { toTitleCasePerWord } from '../../../../../utils/toTitleCase';
import FallbackProductImage from '../../assets/productImage.svg';
import { GridItem, ProductInfo, ProductInfoDesc, ProductInfoPrice, ImageWrapper, ProductImage } from '../../styles';

export const Product = ({ data, size, gridWidth, setScroll, fromCategory }) => {
    const userId = useSelector((state) => state.user.userId);
    const productIndex = process.env.REACT_APP_ALGOLIA_SEARCH_INDEX_V2;

    const handleProductClick = () => {
        setScroll && setScroll();
        const { __queryID } = data;
        const eventName = __queryID && 'Product Clicked after Search';
        const eventType = __queryID && 'clickedObjectIDsAfterSearch';
        const eventProperties = {
            objectIDs: [data?.objectID || data?.id],
            userToken: userId,
            index: productIndex,
            eventName,
        };

        if (__queryID) {
            eventProperties.queryID = __queryID;
            eventProperties.positions = [data.__position + 1];
        }

        aa(eventType, {
            ...eventProperties,
        });
    };

    return (
        <RippleLink
            to={{
                pathname: `/actions/merchbuy/product/${data?.id || data?.productId}`,
                state: fromCategory ? { ...data, fromCategories: true } : data,
            }}
            onClick={handleProductClick}
        >
            <GridItem width={gridWidth} radius={'8px'} padding="0" paddingBottom={'10px'} margin={'5px 0 0 0'}>
                <ImageWrapper justifyContent="center" width={size.width} height={size.height}>
                    <ProductImage
                        src={data?.images?.baseImageUrl || data?.image || FallbackProductImage}
                        alt="product image"
                        width={'100%'}
                        left={'0'}
                        height={size.height}
                        onError={(e) => (e.target.src = FallbackProductImage)}
                    />
                </ImageWrapper>
                <ProductInfo padding="0">
                    <ProductInfoDesc>{`${toTitleCasePerWord(data?.brandName)} ${toTitleCasePerWord(
                        data?.productName,
                    )} - ${toTitleCasePerWord(data?.productVariantName)}`}</ProductInfoDesc>
                    {/* <ProductInfoPrice>
                        {data?.bulkPrices?.length > 0
                            ? formatPrice(data?.bulkPrices[0]?.price)
                            : formatPrice(data?.price?.retail)}
                    </ProductInfoPrice> */}
                    <ProductInfoPrice>
                        {data?.bulkPrices?.length > 0
                            ? formatPrice(
                                  data?.bulkPrices?.reduce((prev, current) => (prev.moq < current.moq ? prev : current))
                                      ?.price,
                              )
                            : formatPrice(data?.price?.retail)}
                    </ProductInfoPrice>
                </ProductInfo>
            </GridItem>
        </RippleLink>
    );
};

Product.propTypes = {
    data: any,
    size: any,
};
