const fingerPrint = localStorage.getItem('fingerPrint');

export const authHeader = () => {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
        return {
            Authorization: `Bearer ${token}`,
            fingerprint: fingerPrint,
        };
    } else {
        return {};
    }
};
