import React, { Fragment, useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { RippleButton, TopHeader } from '../../../../components';
import { SlidingOverlay } from '../../../../containers/OverlayContainer';
import { postSurvey } from '../../../../redux/ducks/user/actions/nps';
import { colors } from '../../../../styles';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { RatingTextArea } from '../../transactions/wallet-transactions/transaction-rating';
import { ReactComponent as BackIcon } from '../assets/back.svg';

import EmojiRating from './components/EmojiRating';
import StarRating from './components/StarRating';
import StarRating1 from './components/StarRating1';
import { npsRates, npsStarRates } from './utils';

const RatingContainer = styled.div`
    padding: 0 16px 32px 16px;
    margin-top: 85px;
`;

const RateBox = styled.div`
    border: 1px solid ${colors.lightGray};
    padding: 16px;
    border-radius: 8px;
    margin-bottom: ${({ bottom }) => bottom || null};
`;

const FlexRating = styled.div`
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent || 'center'};
`;

export const Back = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: absolute;
    left: 16px;
    top: 16px;
    background: ${colors.gray4};
`;

const RateText = styled.div`
    font-weight: 700;
    font-size: 14px;
    color: ${colors.boldDark};
    margin-bottom: ${({ bottom }) => bottom || '16px'};
`;

const HelpRating = ({ open, setOpen }) => {
    const dispatch = useDispatch();
    const surveyTypes = useSelector((state) => state.user.surveyTypes);
    const surveyType = surveyTypes.find((type) => type.title === 'NPS SURVEY');
    const [rating, setRating] = useState(null);
    const [helpRating, setHelpRating] = useState(null);
    const [recommendRating, setRecommendRating] = useState(null);
    const [showValue, setShowValue] = useState('');
    const [helpValue, setHelpValue] = useState('');
    const [recommendValue, setRecommendValue] = useState('');
    const [content, setContent] = useState('');
    const [helpContent, setHelpContent] = useState('');
    const [recommendContent, setRecommendContent] = useState('');
    const [altloading, setAltloading] = useState(false);
    const [score, setScore] = useState(0);
    const [secondScore, setSecondScore] = useState(0);
    const [thirdScore, setThirdScore] = useState(0);
    const showCriteria = score <= 30 && !content;
    const helpCriteria = secondScore <= 30 && !helpContent;
    const recommendCriteria = thirdScore <= 30 && !recommendContent;

    useEffect(() => {
        showValue && setScore(npsStarRates.find((x) => x.value === showValue)?.score);
        helpValue && setSecondScore(npsRates.find((x) => x.value === helpValue)?.score);
        recommendValue && setThirdScore(npsRates.find((x) => x.value === recommendValue)?.score);
    }, [showValue, helpValue, recommendValue]);

    const handleSubmit = () => {
        const params = {
            externalRef: '',
            responded: true,
            surveyId: surveyType && surveyType.id,
            value1: score,
            value2: content,
            value3: secondScore,
            value4: helpContent,
            value5: thirdScore,
            value6: recommendContent,
        };
        setAltloading(true);
        dispatch(postSurvey(params)).then((res) => {
            if (res) {
                setAltloading(false);
                setOpen();
            } else {
                setAltloading(false);
            }
        });
    };

    return (
        <Fragment>
            <SlidingOverlay>
                <DesktopBackgroundLayout>
                    <TopHeader title={'Rate Us'} noArrow>
                        <Back onClick={() => setOpen()}>
                            <BackIcon />
                        </Back>
                    </TopHeader>
                    <RatingContainer>
                        <RateBox bottom={'20px'}>
                            <RateText bottom={'16px'}>How would you rate us?</RateText>
                            <FlexRating>
                                <StarRating
                                    rating={rating}
                                    setRating={setRating}
                                    showValue={showValue}
                                    setShowValue={setShowValue}
                                />
                            </FlexRating>
                        </RateBox>
                        {(showValue === 'Poor' || showValue === 'Bad') && (
                            <RateBox bottom={'20px'}>
                                <RatingTextArea
                                    value={content}
                                    onChange={(event) => setContent(event.target.value)}
                                    type={'text'}
                                    placeholder={'Tell us how we can improve'}
                                    name={'description'}
                                />
                            </RateBox>
                        )}
                        <RateBox bottom={'20px'}>
                            <RateText bottom={'24px'}>How easy was it to get help from us?</RateText>
                            <FlexRating>
                                <EmojiRating
                                    rating={helpRating}
                                    setRating={setHelpRating}
                                    showValue={helpValue}
                                    setShowValue={setHelpValue}
                                />
                            </FlexRating>
                        </RateBox>
                        {(helpValue === 'Terrible' || helpValue === 'Bad') && (
                            <RateBox bottom={'20px'}>
                                <RatingTextArea
                                    value={content}
                                    onChange={(event) => setHelpContent(event.target.value)}
                                    type={'text'}
                                    placeholder={'Tell us how we can improve'}
                                    name={'description'}
                                />
                            </RateBox>
                        )}
                        <RateBox>
                            <RateText bottom={'24px'}>Will you recommend Sabi to your friends & family?</RateText>
                            <FlexRating justifyContent={'flex-start'}>
                                <StarRating1
                                    rating={recommendRating}
                                    setRating={setRecommendRating}
                                    showValue={recommendValue}
                                    setShowValue={setRecommendValue}
                                />
                            </FlexRating>
                        </RateBox>
                        {recommendValue === 'No' && (
                            <RateBox bottom={'20px'}>
                                <RatingTextArea
                                    value={content}
                                    onChange={(event) => setRecommendContent(event.target.value)}
                                    type={'text'}
                                    placeholder={'Tell us how we can improve'}
                                    name={'description'}
                                />
                            </RateBox>
                        )}
                        <RippleButton
                            height={'56px'}
                            top={'40%'}
                            onClick={() => handleSubmit()}
                            disabled={
                                !showValue ||
                                !helpValue ||
                                !recommendValue ||
                                altloading ||
                                showCriteria ||
                                helpCriteria ||
                                recommendCriteria
                            }
                        >
                            {altloading ? 'Submitting...' : 'Submit'}
                        </RippleButton>
                    </RatingContainer>
                </DesktopBackgroundLayout>
            </SlidingOverlay>
        </Fragment>
    );
};

export default HelpRating;
