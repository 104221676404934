import SouthAfricaFlag from '../../../assets/flags/southAfrica.svg';

export const SouthAfricaInfo = {
    name: 'South Africa',
    code: '+27',
    flag: SouthAfricaFlag,
    currency: {
        sign: 'R',
        code: 'ZAR',
    },
};
