import React from 'react';

import styled from 'styled-components';

import { Main } from '../styles';

import { ModifiedBadge } from './styles';

const Title = styled.p`
    margin: 0;
    color: #071827;
    font-size: 10px;
    font-weight: 500;
`;

const Description = styled.p`
    margin: 0;
    color: #071827;
    font-size: 12px;
    font-weight: 400;
`;

const ProductDescription = ({ data }) => {
    return (
        <Main>
            <ModifiedBadge height="70px" bottom="0">
                <Title>Description</Title>
                <Description>{data?.productDescription}</Description>
            </ModifiedBadge>
            <ModifiedBadge height="70px" bottom="0">
                <Title>Product category</Title>
                <Description>{data.categoryName}</Description>
            </ModifiedBadge>
            <ModifiedBadge height="70px" bottom="0">
                <Title>Product Unit</Title>
                <Description>{data?.unitValue}</Description>
            </ModifiedBadge>
            <ModifiedBadge height="70px" bottom="0">
                <Title>Minimum quantity</Title>
                <Description>{data?.bulkPrices[0]?.moq}</Description>
            </ModifiedBadge>
        </Main>
    );
};

export default ProductDescription;
