import React, { Fragment, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { TopHeader, RippleLink } from '../../../../../components';
import { VerificationStatus } from '../../../../../components/verification-status';
import {
    MenuOptions,
    MenuOption,
    MenuOptionLogo,
    OptionName,
    ArrowForward,
} from '../../../../../containers/MenuContainer';
import { FlexStartBlock } from '../../../../../containers/ScreenContainer';
import { getAgentActivationStatus } from '../../../../../redux/ducks/applications/agents/actions';
import { getRejectedReasons } from '../../../../../redux/ducks/user/actions';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';

import MeansOfIDIcon from './assets/means_of_id.svg';
import GlobeIcon from './assets/world.svg';

const KycContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ModifiedMenuOption = styled(MenuOption)`
    align-items: center;
`;

const KYCPage = () => {
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user.userId);
    const agentState = useSelector((state) => (state.user.agentState ? state.user.agentState : 'PENDING'));

    useEffect(() => {
        dispatch(getAgentActivationStatus());
    }, [dispatch]);

    useEffect(() => {
        if (agentState !== 'APPROVED') {
            dispatch(getRejectedReasons(userId));
        }
    }, [agentState, dispatch, userId]);

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'KYC'} />
                <KycContainer>
                    <MenuOptions style={{ marginTop: '64px' }}>
                        <RippleLink to="/user/account_kyc_region">
                            <MenuOption>
                                <MenuOptionLogo icon={GlobeIcon} />
                                <FlexStartBlock>
                                    <OptionName>Region Selection</OptionName>
                                </FlexStartBlock>
                                <ArrowForward />
                            </MenuOption>
                        </RippleLink>
                        <RippleLink to="/user/account_kyc_means">
                            <ModifiedMenuOption>
                                <MenuOptionLogo icon={MeansOfIDIcon} />
                                <FlexStartBlock top={'8px'}>
                                    <OptionName>Means of Identification</OptionName>
                                    <VerificationStatus status={agentState} />
                                </FlexStartBlock>
                                <ArrowForward top={'30px'} />
                            </ModifiedMenuOption>
                        </RippleLink>
                    </MenuOptions>
                </KycContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default KYCPage;
