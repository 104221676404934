export const productUnits = [
    { value: 'Piece(Pcs)', label: 'pcs' },
    { value: 'Bags', label: 'bags' },
    { value: 'Boxes', label: 'boxes' },
    { value: 'feet(Ft)', label: 'ft' },
    { value: 'Meters(M)', label: 'm' },
    { value: 'Pairs', label: 'pairs' },
    { value: 'Rolls', label: 'rolls' },
    { value: 'Sets', label: 'sets' },
    { value: 'Square Meter', label: 'sqm' },
    { value: 'Square Feet', label: 'sqf' },
    { value: 'Yard', label: 'Yard' },
    { value: 'Grams', label: 'g' },
    { value: 'Kilograms', label: 'kg' },
    { value: 'Others', label: 'others' },
    // { value: "K.G. (Kilograms)", label: "kg" },
    // { value: "G. (Grams)",       label: "g" },
    // { value: "INC (Inches)",     label: "inc" },
    // { value: "LT (Litres)",      label: "lt" }
];
