import React, { Fragment, useState, useEffect } from 'react';

import { string, array } from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../../../styles/variables';

import { ReactComponent as DownwardsIcon } from './assets/down.svg';
import { ReactComponent as UpwardsIcon } from './assets/upward.svg';

const DropdownBodyInactive = styled.div`
    position: relative;
    margin: 0px 16px;
    background-color: white;
    padding: 26px 21px 26px 16px;
`;

const DropdownBodyActive = styled.div`
    position: relative;
    margin: 0 16px;
    background-color: #fff;
`;

const DropdownTitle = styled.h3`
    color: #718596;
    font-weight: 500;
    font-size: 16px;
    color: ${colors.themeTxtColor10};
`;
const DropdownTitleActive = styled(DropdownTitle)``;

const Cover = styled.div`
    padding-top: 26px;
    padding: 26px 16px 24px 16px;
`;

const DropdownContainer = styled.div`
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || null};
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: ${({ bottom }) => bottom || '25px'};
    padding: ${({ padding }) => padding || null};
    // margin-top: ${({ top }) => top || '25px'};
`;

export const Dropdown = ({ title, top, list, children, index }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (index === 0) setOpen(true);
    }, [setOpen, index]);

    return (
        <DropdownContainer top={top}>
            {!open ? (
                <DropdownBodyInactive>
                    <HeaderWrapper onClick={() => setOpen(!open)} bottom={'0'}>
                        <DropdownTitle>{title}</DropdownTitle>
                        <DownwardsIcon />
                    </HeaderWrapper>
                </DropdownBodyInactive>
            ) : (
                <DropdownBodyActive>
                    <Cover>
                        <HeaderWrapper bottom="10px" onClick={() => setOpen(!open)}>
                            <DropdownTitleActive>{title}</DropdownTitleActive>
                            <UpwardsIcon />
                        </HeaderWrapper>
                        {list && <Fragment>{children}</Fragment>}
                    </Cover>
                </DropdownBodyActive>
            )}
        </DropdownContainer>
    );
};

Dropdown.propTypes = {
    list: array,
    title: string,
};
