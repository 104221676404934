import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Loader, PageLogo, RippleButton, TopHeader } from '../../../components';
import { FeatureUndergoingFixes } from '../../../components/popup/feature-undergoing-fixes';
import { ListHeading } from '../../../containers/ListContainer';
import { ScreenContainer, SectionWrapper, FlexContainer } from '../../../containers/ScreenContainer';
import { getIdentityDetails } from '../../../redux/ducks/applications/merchants/actions';
import { getShops } from '../../../redux/ducks/applications/my-shop/actions/shop';
import { colors } from '../../../styles';
import History from '../../../utils/History';
import { toTitleCase } from '../../../utils/toTitleCase';
import { tryParseJSONObject } from '../../../utils/tryParseJSONObject';
import VerifyIdentity from '../../actions/my-shop/shop/verify-identity';
import DesktopBackgroundLayout from '../../DesktopBackgroundLayout';
import { getStatusColor, getTextColor } from '../utils';

import { ReactComponent as AlertIcon } from './assets/alert-circle.svg';
import headerBackground from './assets/header-bg.svg';
import StoreIcon from './assets/store.svg';
import { ReactComponent as UserIcon } from './assets/user.svg';

const ShopDetailsWrapper = styled.div`
    display: flex;
    background-color: ${colors.white};
    padding: 16px;
    height: 72px;
    border-radius: 4px;
    width: calc(100% - 32px);
    margin: 0 auto;
    align-items: center;
    z-index: 9999;
    position: absolute;
    top: 110px;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${({ left }) => left};
`;

const ShopName = styled(ListHeading)`
    font-weight: 700;
    font-size: 16px;
    margin: 0;
    line-height: 24px;
`;

const Text = styled.p`
    font-size: ${({ size }) => size || '10px'};
    line-height: ${({ lineHeight }) => lineHeight || '15px'};
    font-weight: ${({ weight }) => weight || '400'};
    color: ${({ color }) => color || colors.white};
    margin: ${({ margin }) => margin || '0px'};
    padding: 0;
    text-align: ${({ align }) => align || null};
`;

const VerificationStatus = styled.div`
    background-color: ${({ color }) => color};
    padding: 4px 8px;
    display: flex;
    height: 24px;
    border-radius: 24px;
    margin-left: auto;
    text-align: center;
`;

const Image = styled.img``;

const FailedStatusDetails = styled.div`
    background-color: #ffc3bd;
    margin: 16.4px 0 12px;
    padding: 12px 16px;
    border-radius: 4px;
`;

const ShopImagesContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: ${({ top }) => top || 0};

    & > *:not(:last-child) {
        margin-right: 12px;
    }
`;

const AddressText = styled(Text)`
    color: ${colors.subGrey};
    font-size: 12px;
    line-height: 2px;
    margin: 18.4px 0 0;
    max-width: 231px;
`;

const Dot = styled.span`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${colors.textRed};
    margin-right: 10px;
`;

export const ShopSettings = () => {
    const dispatch = useDispatch();
    const shops = useSelector((state) => state.applications.myShop.shops);
    const shop = shops[0];
    const user = useSelector((state) => state.user);
    const merchVerify = useSelector((state) => state.applications.merchants.merchVerify);
    const isLoading = useSelector((state) => state.applications.merchants.isLoading);
    const features = useSelector((state) => state.user.features);
    const userNumber = user?.msisdn;
    const firstName = user?.firstName;
    const lastName = user?.lastName;
    const [openVerifyIdentity, setOpenVerifyIdentity] = useState(false);
    const latestShopImages = shop?.shopImages?.slice(-3) || [];
    const comments = tryParseJSONObject(shop?.verificationComment) || {};
    const [openFeatureFixes, setOpenFeatureFixes] = useState(false);

    const formatDate = (date) => {
        return moment(new Date(date)).format('Do MMMM, YYYY');
    };

    useEffect(() => {
        dispatch(getShops());
        dispatch(getIdentityDetails());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) return <Loader />;

    return (
        <DesktopBackgroundLayout>
            {!openVerifyIdentity && (
                <TopHeader
                    size={'14px'}
                    color={colors.white}
                    weight={'400'}
                    title={'Shop Settings'}
                    backLink={'/my-shop'}
                    background={`no-repeat center/cover url(${headerBackground})`}
                    height={'145px'}
                    backButtonTop={'initial'}
                >
                    <ShopDetailsWrapper>
                        <PageLogo
                            width={'40px'}
                            height={'40px'}
                            iconWidth={'20px'}
                            iconHeight={'25px'}
                            objectFit={'cover'}
                            background={colors.gray4}
                            borderRadius={'4px'}
                            Icon={shop?.avatar || StoreIcon}
                        />
                        <Column left={'8px'}>
                            <ShopName>{shop?.shopName}</ShopName>
                            {shop && (
                                <Text color={colors.lightDark}>{`Last update: ${formatDate(shop?.updatedAt)}`}</Text>
                            )}
                        </Column>
                        <Text
                            color={colors.deepBlue}
                            weight={'700'}
                            size={'12px'}
                            lineHeight={'18px'}
                            style={{ marginLeft: 'auto' }}
                            onClick={() => History.push('/actions/shop_edit')}
                        >
                            Edit
                        </Text>
                    </ShopDetailsWrapper>
                </TopHeader>
            )}
            <ScreenContainer color={colors.gray4} style={{ minHeight: '100vh' }} top={'145px'}>
                <SectionWrapper
                    bgcolor={colors.white}
                    radius={'4px'}
                    margin={'40px 0 16px'}
                    padding={'18.4px 16px 24px'}
                >
                    <FlexContainer justifyContent={'initial'}>
                        <Image src={StoreIcon} />
                        <Text color={colors.boldDark} size={'14px'} lineHeight={'21px'} margin={'0 0 0 9.2px'}>
                            Store details
                        </Text>
                        <VerificationStatus color={getStatusColor(shop?.verificationStatus)}>
                            {shop?.verificationStatus === 'UNVERIFIED' && shop?.verificationComment && (
                                <AlertIcon style={{ marginRight: '6.33px' }} />
                            )}
                            {shop?.verificationStatus === 'UNVERIFIED' && shop?.verificationComment ? (
                                <Text color={getTextColor(shop?.verificationStatus)}>Verification Failed</Text>
                            ) : (
                                <Text color={getTextColor(shop?.verificationStatus)}>
                                    {toTitleCase(shop?.verificationStatus?.toLowerCase() || '')}
                                </Text>
                            )}
                        </VerificationStatus>
                    </FlexContainer>

                    {shop?.verificationStatus === 'UNVERIFIED' && Object.keys(comments).length > 0 && (
                        <FailedStatusDetails>
                            {Object.values(comments)
                                ?.filter((comment) => !comment === false)
                                .map((comment, index) => (
                                    <FlexContainer key={index} justifyContent={'initial'}>
                                        <Dot />
                                        <Text color={colors.textRed} size={'12px'} weight={'500'} lineHeight={'18px'}>
                                            {comment && comment}
                                        </Text>
                                    </FlexContainer>
                                ))}
                        </FailedStatusDetails>
                    )}

                    <AddressText>{`${shop?.shopNumber || ''}, ${shop?.streetAddress || ''}.`}</AddressText>
                    <AddressText>{`${shop?.city || ''}, ${shop?.lga || ''} L.G.A`}</AddressText>
                    <AddressText>{`${shop?.countryState} State`}</AddressText>

                    {shop?.shopImages?.length > 0 && (
                        <ShopImagesContainer top={'16px'}>
                            {latestShopImages?.map((image, index) => (
                                <PageLogo
                                    key={index}
                                    Icon={image}
                                    width={'64px'}
                                    height={'64px'}
                                    iconHeight={'64px'}
                                    iconWidth={'64px'}
                                    borderRadius={'4px'}
                                    objectFit={'cover'}
                                />
                            ))}
                        </ShopImagesContainer>
                    )}

                    {shop?.verificationStatus === 'UNVERIFIED' && (
                        <RippleButton
                            height={'40px'}
                            color={colors.deepBlue}
                            border={`1px solid ${colors.deepBlue}`}
                            backgroundColor={colors.white}
                            top={'16px'}
                            onClick={() => {
                                if (!features?.verifyStore?.status) return setOpenFeatureFixes(true);
                                History.push({
                                    pathname: '/actions/verify_store',
                                    state:
                                        shop?.verificationStatus === 'UNVERIFIED' && shop?.verificationComment ? 3 : 1,
                                });
                            }}
                        >
                            {shop?.verificationStatus === 'UNVERIFIED' &&
                                !shop?.verificationComment &&
                                'Verify your store'}
                            {shop?.verificationStatus === 'UNVERIFIED' &&
                                shop?.verificationComment &&
                                'Try Verification again'}
                        </RippleButton>
                    )}
                </SectionWrapper>

                <SectionWrapper bgcolor={colors.white} radius="4px" padding="18.4px 16px 24px">
                    <FlexContainer justifyContent="initial">
                        <UserIcon />
                        <Text color={colors.boldDark} size="14px" lineHeight="21px" margin="0 0 0 9.2px">
                            Identity
                        </Text>
                        <VerificationStatus
                            color={
                                merchVerify?.verificationStatus
                                    ? getStatusColor(merchVerify?.verificationStatus)
                                    : '#FFC3BD'
                            }
                        >
                            {merchVerify?.verificationStatus === 'UNVERIFIED' && merchVerify?.comments && (
                                <AlertIcon style={{ marginRight: '6.33px' }} />
                            )}
                            {merchVerify?.verificationStatus === 'UNVERIFIED' && merchVerify?.comments ? (
                                <Text color={getTextColor(merchVerify?.verificationStatus)}>Verification Failed</Text>
                            ) : (
                                <Text color={getTextColor(merchVerify?.verificationStatus || 'UNVERIFIED')}>
                                    {toTitleCase(merchVerify?.verificationStatus?.toLowerCase() || 'unverified')}
                                </Text>
                            )}
                        </VerificationStatus>
                    </FlexContainer>

                    {merchVerify?.verificationStatus === 'UNVERIFIED' && merchVerify?.comments && (
                        <FailedStatusDetails>
                            <FlexContainer justifyContent={'initial'}>
                                <Text color={colors.textRed} size={'12px'} weight={'500'} lineHeight={'18px'}>
                                    {merchVerify?.comments}
                                </Text>
                            </FlexContainer>
                        </FailedStatusDetails>
                    )}

                    <Text
                        color={colors.boldDark}
                        weight="500"
                        size="14px"
                        lineHeight="21px"
                        margin="17.2px 0 2px"
                    >{`${firstName} ${lastName}`}</Text>
                    <Text color={colors.subGrey} size="12px" lineHeight="18px">
                        {userNumber}
                    </Text>
                    {(merchVerify?.verificationStatus === 'UNVERIFIED' ||
                        (merchVerify?.verificationStatus === 'UNVERIFIED' && merchVerify?.comments) ||
                        !merchVerify?.verificationStatus) && (
                        <RippleButton
                            height="40px"
                            color={colors.deepBlue}
                            border={`1px solid ${colors.deepBlue}`}
                            backgroundColor={colors.white}
                            top="16px"
                            onClick={() => {
                                if (!features?.verifyIdentity?.status) return setOpenFeatureFixes(true);
                                setOpenVerifyIdentity(!openVerifyIdentity);
                            }}
                        >
                            {((merchVerify?.verificationStatus === 'UNVERIFIED' && !merchVerify?.comments) ||
                                !merchVerify?.verificationStatus) &&
                                'Verify your identity'}
                            {merchVerify?.verificationStatus === 'UNVERIFIED' && merchVerify?.comments && 'Edit'}
                        </RippleButton>
                    )}
                </SectionWrapper>
                {openVerifyIdentity && (
                    <VerifyIdentity
                        open={openVerifyIdentity}
                        setOpen={() => {
                            setOpenVerifyIdentity(!openVerifyIdentity);
                        }}
                    />
                )}
                {openFeatureFixes && <FeatureUndergoingFixes cancel={() => setOpenFeatureFixes(false)} />}
            </ScreenContainer>
        </DesktopBackgroundLayout>
    );
};
