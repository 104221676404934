import React, { Fragment } from 'react';

import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Title, SubTitle } from '../../../../../containers/MessageContainer';
import { colors } from '../../../../../styles';
import { ReactComponent as MoreCategoriesForwardIcon } from '../../assets/more_categories.svg';
import { ReactComponent as RecommendedProducts } from '../../assets/recommendedProducts.svg';

const RecommendedWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

const CategoriesWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-top: 16px;
    margin-bottom: 30px;
    gap: 12px;
`;

const CategoriesContainer = styled.div`
    border-bottom: 1px solid #cbd6e0;
    margin: 0 -16px 40px -16px;
`;

const CategoryImage = styled.img`
    width: 64px;
    height: 64px;
    border-radius: 50px;
`;

const CategoryImageWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    cursor: pointer;
`;

const MoreCategoriesSection = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    align-items: center;
    cursor: pointer;
`;

export const TopNavIconsMenu = ({ categories = [] }) => {
    const history = useHistory();

    const goToCategoriesPage = ({ name = '', id = '' } = {}) => {
        if (name) {
            history.push(`/actions/merchbuy/products/${name}/${id}`);
            return;
        }

        history.push('/actions/merchbuy/product-categories');
    };

    return (
        <Fragment>
            <RecommendedWrapper>
                <RecommendedProducts />
                <Title weight="700" bottom="0" color={colors.boldDark} size="16px">
                    Recommended Categories For You
                </Title>
            </RecommendedWrapper>

            <CategoriesWrapper>
                {categories?.map((category) => (
                    <CategoryImageWrapper key={category.id} onClick={() => goToCategoriesPage(category)}>
                        <CategoryImage src={category?.imageUrl} />
                        <SubTitle top="8px" color={colors.boldDark} font="10px" textAlign="center">
                            {category?.name}
                        </SubTitle>
                    </CategoryImageWrapper>
                ))}
            </CategoriesWrapper>
            <MoreCategoriesSection onClick={() => goToCategoriesPage()}>
                <div>
                    <Title textAlign="left" weight="700" color="#227EFF" size="12px">
                        See Other Categories
                    </Title>
                    <SubTitle weight="400" font="10px" color="#5C6F7F">
                        Phone and Accessories, Clothing, Electronics, Gaming...
                    </SubTitle>
                </div>
                <MoreCategoriesForwardIcon />
            </MoreCategoriesSection>
            <CategoriesContainer />
        </Fragment>
    );
};
