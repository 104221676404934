import React, { Fragment } from 'react';

import { bool, string } from 'prop-types';
import styled from 'styled-components';

import { PopUp, PopUpContent } from '../../../../components/popup/common';
import { Overlay } from '../../../../containers/OverlayContainer';
import { ScreenContainer, ViewContainer } from '../../../../containers/ScreenContainer';
import { colors } from '../../../../styles';

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.boldDark};
    text-align: left;
`;

const Comment = styled.div`
    font-size: 14px;
    line-height: 21px;
    color: ${colors.lightDark};
    margin-top: 4px;
    text-align: left;
`;

const Padding = styled.div`
    padding: 16px;
`;

const StoreSettingsModal = ({ open, zIndex, children, height, title, description, close = () => null }) => {
    return (
        <Fragment>
            <Overlay close={close} bgc="rgba(0, 0, 0, 0.4)" zIndex={zIndex}>
                <PopUp padding="0" open={open} zIndex={'10000'}>
                    <PopUpContent color="#F9FAFC" radius="20px 20px 0px 0px" height={height}>
                        <ScreenContainer paddingBottom="24px" color="#F9FAFC" padding="0" top={'16px'}>
                            {title && (
                                <Padding>
                                    <Title>{title}</Title>
                                    <Comment>{description}</Comment>
                                </Padding>
                            )}
                            <ViewContainer top={'0px'}>{children}</ViewContainer>
                        </ScreenContainer>
                    </PopUpContent>
                </PopUp>
            </Overlay>
        </Fragment>
    );
};
StoreSettingsModal.propTypes = {
    open: bool,
    title: string,
    description: string,
};

export default StoreSettingsModal;
