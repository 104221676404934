import React, { useState, useEffect } from 'react';

import { bool, func, object } from 'prop-types';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Overlay, ActionBlock } from '../../../containers/OverlayContainer';
import { colors } from '../../../styles';
import { formatPrice } from '../../../utils/currency/formatPriceWithComma';
import { formatCurrency } from '../../../utils/currency/parseBalance';
import { is_url } from '../../../utils/urls/isUrl';
import { PopUp, PopUpContent, CancelButton, ConfirmButton } from '../common';

import { ReactComponent as AddIcon } from './assets/add.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import { ReactComponent as ErrorIcon } from './assets/error.svg';
import FallbackProductImage from './assets/productImage.svg';
import { ReactComponent as SubstractIcon } from './assets/substract.svg';

const ProductBlock = styled.div`
    margin: ${({ margin }) => margin || '0px 0 34px'};
    display: flex;
    width: 100%;
`;

const ProductImage = styled.img`
    width: 44px;
    height: 44px;
    margin-top: 0;
    border-radius: 4px;
    object-fit: cover;
    background-color: ${colors.themeColor3};
`;

const ProductInfo = styled.div`
    flex: 1;
    font-size: 14px;
    padding: 0px 15% 0px 10px;
`;
const ModifiedSubstractIcon = styled(SubstractIcon)`
    margin: 0 8px 0 0;
`;
const ModifiedAddIcon = styled(AddIcon)`
    margin: 0px 8px;
`;

const Title = styled.div`
    font-size: 12px;
    font-weight: 500;
    margin: 0 0 8px;
    text-align: left;
    color: ${colors.boldDark};
`;

const ProductDesc = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    text-align: left;
`;

const UpdateCalculatorBlock = styled.div`
    display: flex;

    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    & > svg {
        cursor: pointer;
    }
`;

const QuantityDisplay = styled.div`
    border-radius: 8px;
    width: 64px;
    height: 32px;
    background-color: ${colors.themeColor3};
    text-align: center;
`;

const CurrentQuantity = styled.input`
    position: relative;
    top: calc(50% - 8px);
    font-weight: 100;
    font-size: 14px;
    font-weight: 500;
    color: ${({ color }) => color || colors.themeTextColor1};
    margin-top: ${({ top }) => top || null};
    margin-left: ${({ left }) => left || null};
    width: 70%;
    background-color: inherit;
    border: none;
    text-align: center;
    &:focus {
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
`;

const MoqParagraph = styled.div`
    font-size: 10px;
    color: ${colors.themeSubColor1};
    font-weight: 700;
    text-align: left;

    &:first-child {
        margin-bottom: 2px;
    }
`;

const OuterQuantityHold = styled.div`
    display: flex;
    flex-direction: column;
`;

const QuantityHold = styled.div`
    line-height: 18px;
    display: flex;
`;
const PriceHolder = styled.p`
    margin-top: 8px;
    font-size: 12px;
    color: ${colors.boldDark};
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 0;
`;

const ProductDescText = styled.h3`
    font-size: 12px;
    color: ${colors.boldDark};
    font-weight: 400;
    line-height: 18px;
`;

const StyledCloseIcon = styled(CloseIcon)`
    margin-left: auto;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 8px;
`;

const TotalBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;
`;

const TotalFlexBox = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ bottom }) => bottom || null};
`;

const TotalText = styled.div`
    font-weight: ${({ fontWeight }) => fontWeight || '700'};
    font-size: ${({ fontSize }) => fontSize || '14px'};
    color: ${({ color }) => color || colors.themeTxtColor10};
    display: flex;
    align-items: center;
`;

const ErrorBox = styled.div`
    padding: 12px;
    background: ${colors.lightYellowBg};
    border-radius: 8px;
    transition: all 0.3s ease 0s;
`;

const ErrorTextBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const BuynowPopup = ({
    open,
    cancel,
    data,
    confirm,
    selectedMoq,
    setSelectedMoq,
    minimumOrderValue,
    showError,
    productDetails,
    totalPrice,
    setTotalPrice,
    animate,
}) => {
    const [IDX, setIDEX] = useState(0);

    useEffect(() => {
        if (data?.bulkPrices?.length > 0) {
            setSelectedMoq(data?.bulkPrices[0]?.moq || data?.bulkPrices[0]?.quantity);
        } else {
            setSelectedMoq(data?.moq);
        }
    }, [data, setSelectedMoq]);

    useEffect(() => {
        setTotalPrice(selectedMoq * ((data?.bulkPrices && data?.bulkPrices[IDX]?.price) || data?.price?.retail));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [IDX, data.bulkPrices, selectedMoq]);

    const setPosition = (val) => {
        for (let i = 0; i < data?.bulkPrices?.length; i++) {
            if (!data.bulkPrices[i + 1]) {
                return setIDEX(i);
            } else if (val >= data?.bulkPrices[i]?.moq && val < data?.bulkPrices[i + 1]?.moq) {
                return setIDEX(i);
            }
        }
    };

    const handleAddToCart = () => {
        const newData = {
            ...data,
            productId: data?.id || data?.productId,
            shopId: data.branchId,
            quantity: selectedMoq,
        };

        confirm(newData);
    };

    const disabled = (data) => {
        if (
            selectedMoq > data?.quantityInStock ||
            !selectedMoq ||
            minimumOrderValue?.value > totalPrice ||
            (data?.bulkPrices.length > 0 && selectedMoq < data?.bulkPrices[0]?.moq) ||
            (data?.moq && selectedMoq < data?.moq)
        ) {
            return true;
        }
    };

    return (
        <PopUp open={open} padding={'0px'}>
            <Overlay bgc={'rgba(0,0,0,.4)'} />
            <PopUpContent width={'375px'} radius={'8px'} padding={'24px 16px 28px 16px'} animate={animate}>
                <ProductBlock margin={'0px 0 16px'}>
                    <ProductImage
                        src={
                            is_url(data.images ? data.images.baseImageUrl : '')
                                ? data.images.baseImageUrl
                                : FallbackProductImage
                        }
                        alt="product image"
                    />
                    <ProductInfo>
                        <ProductDesc>
                            <ProductDescText>
                                {data?.name || data?.productName} <br />{' '}
                            </ProductDescText>
                            <PriceHolder>
                                {(productDetails?.bulkPrices?.length > 0 ||
                                    data?.bulkPrices?.length > 0 ||
                                    data?.maxPrice ||
                                    data?.price?.retail) &&
                                    formatPrice(
                                        productDetails?.bulkPrices?.[IDX]?.price ||
                                            data?.bulkPrices?.[IDX]?.price ||
                                            data?.maxPrice ||
                                            data?.price?.retail,
                                    ) +
                                        ('/' + (data?.unitValue || 'Unit'))}
                            </PriceHolder>
                        </ProductDesc>
                    </ProductInfo>
                    <StyledCloseIcon onClick={cancel} />
                </ProductBlock>
                <hr
                    style={{
                        margin: '0 auto 16px',
                        width: '100%',
                        backgroundColor: colors.gray4,
                    }}
                />

                <OuterQuantityHold>
                    <Title> Select Quantity</Title>

                    <QuantityHold>
                        <UpdateCalculatorBlock>
                            <ModifiedSubstractIcon
                                onClick={() => {
                                    setSelectedMoq(
                                        (data?.bulkPrices.length > 0 &&
                                            parseInt(selectedMoq) - 1 <= data?.bulkPrices[0]?.moq) ||
                                            (data?.moq && parseInt(selectedMoq) - 1 <= data?.moq)
                                            ? (data?.bulkPrices.length > 0 && data?.bulkPrices[0]?.moq) ||
                                                  (data?.moq && data.moq)
                                            : parseInt(selectedMoq) - 1,
                                    );
                                    setPosition(parseInt(selectedMoq) - 1);
                                }}
                            />
                            <QuantityDisplay>
                                <CurrentQuantity
                                    value={selectedMoq}
                                    type={'number'}
                                    onChange={(e) => {
                                        setSelectedMoq(
                                            e.target.value < 0
                                                ? parseInt(
                                                      data?.moq ||
                                                          data?.bulkPrices[0]?.moq ||
                                                          data?.bulkPrices[0]?.quantity,
                                                  )
                                                : parseInt(e.target.value),
                                        );
                                        setPosition(
                                            e.target.value < 0
                                                ? parseInt(
                                                      data?.moq ||
                                                          data?.bulkPrices[0]?.moq ||
                                                          data?.bulkPrices[0]?.quantity,
                                                  )
                                                : parseInt(e.target.value),
                                        );
                                        if (
                                            e.target.value > data?.quantity?.retail ||
                                            e.target.value > (productDetails?.quantity || data?.quantityInStock)
                                        ) {
                                            toast.error(
                                                `You cannot add more than ${
                                                    productDetails?.quantity || data?.quantityInStock
                                                } to your cart at this time`,
                                            );
                                        }
                                        if (
                                            e.target.value < data?.moq ||
                                            e.target.value < data?.bulkPrices[0]?.moq ||
                                            e.target.value < data?.bulkPrices[0]?.quantity
                                        ) {
                                            toast.error(
                                                `You cannot add less than ${
                                                    data?.bulkPrices[0]?.moq ||
                                                    data?.moq ||
                                                    data?.bulkPrices[0]?.quantity
                                                } to your cart at this time`,
                                            );
                                        }
                                    }}
                                />
                            </QuantityDisplay>
                            <ModifiedAddIcon
                                onClick={() => {
                                    setSelectedMoq(parseInt(selectedMoq) + 1);
                                    setPosition(parseInt(selectedMoq) + 1);
                                }}
                            />
                        </UpdateCalculatorBlock>
                        <Column>
                            <MoqParagraph>
                                {data?.quantity?.retail || data?.quantityInStock || data?.quantity} in stock
                            </MoqParagraph>
                        </Column>
                    </QuantityHold>
                </OuterQuantityHold>

                <hr
                    style={{
                        margin: '24px auto 24px',
                        width: '100%',
                        backgroundColor: colors.gray4,
                    }}
                />

                <TotalBox>
                    <TotalFlexBox bottom={'5px'}>
                        <TotalText>Total</TotalText>
                        <TotalText>{formatCurrency(totalPrice)}</TotalText>
                    </TotalFlexBox>
                    <TotalFlexBox>
                        <TotalText color={colors.themeTextColor11} fontWeight={'400'} fontSize={'12px'}>
                            Delivery fee not included
                        </TotalText>
                    </TotalFlexBox>
                </TotalBox>

                {(minimumOrderValue?.value > totalPrice || showError) && (
                    <ErrorBox>
                        <ErrorTextBox>
                            <ErrorIcon />
                            <TotalText
                                fontWeight={'400'}
                                fontSize={'12px'}
                                style={{ textAlign: 'left', maxWidth: '271px' }}
                            >{`You must order items worth at least ${formatCurrency(
                                minimumOrderValue?.value,
                            )} to buy. Add more to buy now`}</TotalText>
                        </ErrorTextBox>
                    </ErrorBox>
                )}

                <ActionBlock direction={'row'} top={'24px'}>
                    <CancelButton
                        type={'button'}
                        onClick={cancel}
                        border
                        color={colors.themeTextColor12}
                        weight="500"
                        top={'0px'}
                    >
                        Cancel
                    </CancelButton>
                    <ConfirmButton
                        disabled={disabled(data)}
                        onClick={handleAddToCart}
                        bgColor={colors.themeTextColor12}
                        color="#fff"
                        weight="500"
                        top={'0px'}
                    >
                        Buy Now
                    </ConfirmButton>
                </ActionBlock>
            </PopUpContent>
        </PopUp>
    );
};

BuynowPopup.propTypes = {
    open: bool,
    cancel: func,
    data: object,
    shopDetails: object,
    confirm: func,
};
