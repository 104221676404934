import React, { Fragment, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Loader, TopHeader, RippleButton } from '../../../components';
import { Message, Title } from '../../../containers/MessageContainer';
import { ScreenContainer } from '../../../containers/ScreenContainer';
import { colors } from '../../../styles';
import { insertZero } from '../../../utils/inputs/formatPhoneNumber';
import DesktopBackgroundLayout from '../../DesktopBackgroundLayout';

import { ReactComponent as NewDeviceAvater } from './assets/new_device_logo.svg';
import { ChooseOtpChannel } from './otp-channel';
import OtpPage from './otp-page';

const Container = styled.div`
    margin-top: 50px;
    text-align: center;
`;

const SwitchAccount = styled.span`
    text-align: center;
    font-size: 14px;
    font-weight: 500;
`;

const AlternativeOptionBlock = styled.section`
    display: flex;
    flex-direction: column;
`;

const NewDevice = () => {
    const isLoading = useSelector((state) => state.auth.check.isLoading);
    const [showChannelPage, setShowChannelPage] = useState(false);
    const [showOtpPage, setShowOtpPage] = useState(false);
    const [resend, setResend] = useState(false);
    const phoneNumber = useSelector((state) => state.user.msisdn);

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                {!showChannelPage && !showOtpPage && (
                    <>
                        <TopHeader noTitle noBorderBottom backLink={'/login'} />
                        <ScreenContainer>
                            <Container>
                                <NewDeviceAvater />
                                <Title textAlign="left" size="24px" top="24px" weight="bold">
                                    New Device Login
                                </Title>
                                <Message top="8px" bottom="24px" size="14px" align="left" weight="400">
                                    Kindly select an option so we can send you an OTP to confirm your phone number
                                    <strong style={{ color: colors.deepBlue }}>{insertZero(phoneNumber)}</strong>
                                </Message>
                                <RippleButton
                                    onClick={() => setShowChannelPage(true)}
                                    top="24px"
                                    bottom="24px"
                                    height={'56px'}
                                >
                                    Continue
                                </RippleButton>
                                <AlternativeOptionBlock>
                                    <SwitchAccount>
                                        Not you?{' '}
                                        <Link style={{ color: colors.deepBlue }} to="/phone-signup">
                                            Switch Account
                                        </Link>
                                    </SwitchAccount>
                                </AlternativeOptionBlock>
                            </Container>
                        </ScreenContainer>
                    </>
                )}

                {showChannelPage && !showOtpPage && (
                    <ChooseOtpChannel
                        cancel={() => setShowChannelPage(false)}
                        setShowOtpPage={setShowOtpPage}
                        resend={resend}
                    />
                )}

                {showOtpPage && !showChannelPage && (
                    <OtpPage
                        setShowChannelPage={setShowChannelPage}
                        setShowOtpPage={setShowOtpPage}
                        setResend={setResend}
                    />
                )}
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default NewDevice;
