import styled from 'styled-components';

import { ProductCategoryName } from '../styles';

export const ProductCategory = styled(ProductCategoryName)`
    margin: 3px 5px;
    display: inline-block;
    border-radius: 32px;
    background-color: #edf2f7;
    padding: 5px 10px 2px 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #071827;
`;

export const ShopInfoSection = styled.div`
    color: #071827;
    border-top: solid 4px #eeeeee;
    border-bottom: solid 4px #eeeeee;
    margin-top: -19px;
    height: 50px;
    padding: 2px;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
    }
`;
export const Street = styled.div`
    margin: 5px;
`;

export const LGA = styled.div`
    margin: 5px;
`;

export const State = styled.div`
    margin: 5px;
`;
