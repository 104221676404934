import React, { useEffect, useRef } from 'react';

import styled from 'styled-components';

import { Button } from '../../../../components';
import ProductFallbackImage from '../../../../components/popup/merchbuy-add-to-cart-popup/assets/productImage.svg';
import { BlurBackground } from '../../../../containers/BlurBackground';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import { is_url } from '../../../../utils/urls/isUrl';
import GreenCheck from '../assets/green-check.svg';
import MerchBuyShoppingCartIcon from '../assets/merchbuy-cart.svg';

import { ProductImageBlock } from './product-quantity-and-buyers';
import { SharedQuantityRow } from './shared-slots';
import { Card, GreenCheckImage, Paragraph, ShoppingBagImage, SuccessIconContainer } from './styles';

const SectionHeader = styled.div`
    background: #f9fafc;
    padding: 16px;
    height: 42px;
    text-transform: uppercase;
    color: #718596;
    font-size: 12px;
    font-weight: bold;
`;

const SummaryFlexContainer = styled.div`
    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
    width: 100%;
    margin: ${({ margin }) => margin || 'initial'};
`;

const QuantityAndSharing = styled(SummaryFlexContainer)`
    border-bottom: 1px dashed #ebf1f6;
    justify-content: space-between;
    padding: 10px 0;
`;

const GroupBuyParticipant = styled(SummaryFlexContainer)`
    padding: 11px 0;
    justify-content: space-between;
`;

const GreyParagraph = styled(Paragraph)`
    color: #7a7978;
    line-height: 21px;
`;

const BoldParagraph = styled(Paragraph)`
    font-weight: 500;
    line-height: 21px;
`;

const SummaryCard = styled(Card)`
    align-items: flex-start;
    flex-direction: column;
    padding: 16px 15px;
`;

const SummaryImage = styled.img`
    height: 44px;
    width: 44px;
    border-radius: 4px;
    margin-right: 12px;
    border: 1px solid black;
`;

const BlurryOverlay = styled(BlurBackground)`
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(4px);
    position: absolute;
    z-index: 1001;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    transition: all 0.3s ease-in-out;
`;

function GroupBuySummary({
    product,
    currentPrice,
    orderQuantity,
    noOfInvitees,
    invitedFriends = [],
    invitedSlots,
    unitPrice,
}) {
    const { unitValue, images } = product;

    return (
        <>
            <SectionHeader>product</SectionHeader>
            <ProductImageBlock
                product={product}
                padding={'12px 16px'}
                shadow={'none'}
                margin={'0'}
                background={'#fff'}
                currentPrice={currentPrice}
                height={'78px'}
            />
            <SectionHeader>quantity and sharing</SectionHeader>

            <SummaryCard>
                <QuantityAndSharing>
                    <GreyParagraph>Quantity</GreyParagraph>
                    <BoldParagraph>
                        {orderQuantity} {unitValue}
                    </BoldParagraph>
                </QuantityAndSharing>

                <QuantityAndSharing>
                    <GreyParagraph>Price</GreyParagraph>
                    <BoldParagraph>{formatPrice(orderQuantity * currentPrice)}</BoldParagraph>
                </QuantityAndSharing>

                <QuantityAndSharing>
                    <GreyParagraph>Participants/Slots</GreyParagraph>
                    <BoldParagraph>{noOfInvitees}</BoldParagraph>
                </QuantityAndSharing>

                <QuantityAndSharing>
                    <GreyParagraph>Invited People</GreyParagraph>
                    <BoldParagraph>{invitedFriends.length}</BoldParagraph>
                </QuantityAndSharing>
            </SummaryCard>

            <SectionHeader>Slots</SectionHeader>

            <SummaryCard>
                <SharedQuantityRow images={images} invitedSlots={invitedSlots} unitPrice={unitPrice} />
            </SummaryCard>

            <SectionHeader>participants</SectionHeader>
            <SummaryCard>
                {invitedFriends.map(({ name, number }, index) => (
                    <GroupBuyParticipant key={index}>
                        <Paragraph>{number}</Paragraph>
                        <GreyParagraph>{name ? name : `User ${index + 1}`}</GreyParagraph>
                    </GroupBuyParticipant>
                ))}
            </SummaryCard>
        </>
    );
}

function GroupBuyOrderConfirmation({ product, unitPrice, orderQuantity, addToCart, setShowSummary }) {
    const { images, name, unitValue } = product;

    const componentRef = useRef(null);

    useEffect(() => {
        if (!componentRef) return;

        // focus on the element
        if (componentRef.current) {
            componentRef.current.focus();
        }
    }, []);

    const hidePopup = () => {
        setShowSummary(false);
    };

    const addToCartClickHandler = (event) => {
        event.preventDefault();
        addToCart();
    };

    return (
        <BlurryOverlay>
            <Card
                height={'252px'}
                padding={'24px 16px'}
                flexDirection={'column'}
                borderRadius={'8px 8px 0 0'}
                ref={componentRef}
                onBlur={hidePopup}
                tabIndex={-1}
            >
                {/* The negative tab index ensures that keyboards cannot focus on this card */}
                {/* and that the user can't tab out of it. */}
                <Paragraph fontWeight={'500'}>Groupbuy Order</Paragraph>

                <SummaryFlexContainer margin={'29px 0 46px'}>
                    <SummaryImage
                        src={is_url(images ? images.baseImageUrl : '') ? images.baseImageUrl : ProductFallbackImage}
                        onError={(e) => {
                            e.target.src = ProductFallbackImage;
                        }}
                        alt="product image"
                    />

                    <SummaryFlexContainer flexDirection={'column'}>
                        <Paragraph>{name}</Paragraph>
                        <SummaryFlexContainer margin={'8px 0 0'} justifyContent={'space-between'}>
                            <BoldParagraph>
                                {formatPrice(unitPrice)} / {unitValue}
                            </BoldParagraph>
                            <GreyParagraph>
                                {orderQuantity} {unitValue}
                            </GreyParagraph>
                        </SummaryFlexContainer>
                    </SummaryFlexContainer>
                </SummaryFlexContainer>

                <SummaryFlexContainer>
                    <SummaryFlexContainer flexDirection={'column'}>
                        <GreyParagraph>Price</GreyParagraph>
                        <BoldParagraph>{formatPrice(orderQuantity * unitPrice)}</BoldParagraph>
                    </SummaryFlexContainer>

                    <Button top={'0'} type={'button'} onMouseDown={addToCartClickHandler} tabIndex={0}>
                        Add to Cart
                    </Button>
                </SummaryFlexContainer>
            </Card>
        </BlurryOverlay>
    );
}

function GroupBuyShoppingBag() {
    return (
        <SuccessIconContainer>
            <ShoppingBagImage src={MerchBuyShoppingCartIcon} />
            <GreenCheckImage src={GreenCheck} />
        </SuccessIconContainer>
    );
}

export { GroupBuySummary, GroupBuyOrderConfirmation, GroupBuyShoppingBag };
