import { Field } from 'formik';
import styled from 'styled-components';

import { colors } from '../../../../../styles';

export const Space = styled.div`
    height: ${({ val }) => val || '10px'};
`;

export const Text = styled.p`
    font-size: ${({ size }) => size || '14px'};
    color: ${({ color }) => color || colors.boldDark};
    margin: ${({ margin }) => margin || '0px'};
    font-weight: ${({ weight }) => weight || '400'};
    line-height: ${({ lineHeight }) => lineHeight || '21px'};
`;

export const MapBox = styled.div`
    height: 477px;
    width: 100%;
    margin: 20px 0;
`;

export const InputField = styled(Field)`
    border: none;
    padding: 14px;
    height: 48px;
    font-size: 14px;
    width: 100%;
    position: relative;
    background-color: ${colors.border.default};
    outline: none;

    &:not(:placeholder-shown) {
        margin-top: 6px;
    }

    &:not(:placeholder-shown) + label {
        display: block;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 30px ${colors.border.default} inset !important;
        box-shadow: 0 0 0 30px ${colors.border.default} inset !important;
    }
`;

export const MarkerContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #000;
    border: 2px solid ${colors.white};
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    &:hover {
        z-index: 1;
    }
`;

export const PageCount = styled(Text)`
    position: absolute;
    right: 0;
    margin-right: 16px;
`;

export const SpanText = styled(Text)``;

export const AddressDetails = styled.div`
    padding: 14.6px 12px 12px;
    border: 1px solid ${colors.gray4};
    border-radius: 4px;
`;

export const Divider = styled.div`
    background-color: #e5e5e5;
    height: 8px;
    width: 100%;
`;
export const DocumentImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: auto;
`;

export const ImageContainer = styled.div`
    max-height: 70vh;
    overflow-y: scroll;
    margin-top: 10px;
`;

export const FailedStatusDetails = styled.div`
    background-color: ${colors.background.failedStatus};
    margin: ${({ margin }) => margin};
    padding: 12px 16px;
    border-radius: 4px;
`;

export const AddressText = styled(Text)`
    color: ${colors.subGrey};
    font-size: 12px;
    line-height: 2px;
    margin: 18.4px 0 0;
    max-width: 231px;
`;
