import firebase from 'firebase/app';

import 'firebase/messaging';
import { axios, currentAPI } from '../../../../../../config';
import { addPendingRequest } from '../../../../offline/actions';

firebase.initializeApp({
    apiKey: 'AIzaSyD_ABxB2Q8elWNN-U_5_kh9FT2BuEDPQ7A',
    authDomain: 'spaces-super-app.firebaseapp.com',
    databaseURL: 'https://spaces-super-app.firebaseio.com',
    projectId: 'spaces-super-app',
    storageBucket: 'spaces-super-app.appspot.com',
    messagingSenderId: '349474949042',
    appId: '1:349474949042:web:a0d12e73d2e32df70ac6ab',
    measurementId: 'G-X2S5PSXHLV',
});

if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    messaging.onMessage((payload) => {
        displayNotification(payload);
    });
    messaging.onTokenRefresh(() => async (dispatch) => {
        messaging
            .getToken()
            .then(function (refreshToken) {
                var payload = { browser: { token: refreshToken, topics: [] } };
                dispatch(sendSubscription(payload));
            })
            .catch(function (err) {
                console.log('Unable to retrieve refreshed token ', err);
            });
    });
}

export const subscribeUser = () => async (dispatch) => {
    if (firebase.messaging.isSupported()) {
        try {
            const messaging = firebase.messaging();
            await messaging.requestPermission();
            const token = await messaging.getToken();
            var payload = { browser: { token: token, topics: [] } };
            dispatch(sendSubscription(payload));
        } catch (error) {
            console.error(error);
            if (error.code === 'messaging/token-unsubscribe-failed') {
                subscribeUser();
            }
        }
    }
};

export const sendSubscription = (payload) => async (dispatch) => {
    if (navigator.onLine) {
        try {
            const response = await axios.put(`${currentAPI}/api/users/deviceData`, JSON.stringify(payload));

            if (response.status === 200) {
                //console.log("sent token subscription successful")
            }
        } catch (error) {
            dispatch(addPendingRequest('PUT', `${currentAPI}/api/users/deviceData`, payload));
        }
    } else {
        dispatch(addPendingRequest('PUT', `${currentAPI}/api/users/deviceData`, payload));
    }
};

const displayNotification = (payload) => {
    if ('Notification' in window) {
        if (Notification.permission === 'granted') {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistration().then((registration) => {
                    if (registration) {
                        const { title, body, icon, ...restPayload } = payload.data;

                        const notificationOptions = {
                            body,
                            icon: icon,
                            data: restPayload,
                        };

                        registration.showNotification(title, notificationOptions);
                    }
                });
            }
        }
    }
};
