import React, { Fragment, useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import { Loader, TopHeader, UserAvatar } from '../../../../components';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { /*sendOTPToWhatsapp,*/ newPinResendCode } from '../../../../redux/ducks/auth/check/actions';
import { getUserPublicData } from '../../../../redux/ducks/auth/login/actions';
import { /* PinResetVoiceOtp,*/ otpLoginBySms } from '../../../../redux/ducks/user/actions/password';
import { colors } from '../../../../styles';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
// import { ReactComponent as CallImage } from '../../check/assets/call-img.svg';
import { ReactComponent as TextMessageImage } from '../../check/assets/text-message.svg';
// import { ReactComponent as WhatsappImage } from '../../check/assets/whatsapp-img.svg';

const StyleAvatar = {
    display: 'flex',
    justifyContent: 'center',
    margin: '41px auto auto auto',
};

// const StyledWhatsapPSvg = styled(WhatsappImage)`
//     display: flex;
//     justify-content: center;
//     margin: 40px auto auto auto;
// `;
// const StyledCallSvg = styled(CallImage)`
//     display: flex;
//     justify-content: center;
//     margin: 16px auto auto auto;
// `;
const StyledTextMessageSvg = styled(TextMessageImage)`
    display: flex;
    justify-content: center;
    margin: 16px auto auto auto;
`;

const MainText = styled.span`
    color: ${colors.themeTextColor11};
    font-weight: 700;
    font-size: 24px;
    justify-content: center;
    display: flex;
    margin: 16px auto auto auto;
`;

const SubText = styled.div`
    color: ${colors.themeTextColor10};
    font-weight: 400;
    font-size: 14px;
    justify-content: center;
    margin: 4px auto auto 39px;
`;

const OTP_ACTION_TYPES = {
    WHATSAPP: 'WHATSAPP',
    SMS: 'SMS',
    VOICE: 'VOICE',
    RESET_LOGIN_PIN: 'RESET_LOGIN_PIN',
};

const PasswordOtpOptions = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const userId = user && user.userId;
    const [userData, setUserData] = useState(null);
    const firstName = user && user.firstName;
    const avatar = user && user.avatar;
    const isLoading = useSelector((state) => state.auth.check.isLoading);
    const resend = location?.state?.resend;

    useEffect(() => {
        (async () => {
            const data = await dispatch(getUserPublicData(userId));
            if (data) setUserData(data);
        })();
    }, [dispatch, userId, firstName]);

    // const otpByWhatsapp = async () => {
    //     let id;
    //     if (resend) {
    //         id = await dispatch(newPinResendCode(OTP_ACTION_TYPES.WHATSAPP));
    //     } else {
    //         id = await dispatch(sendOTPToWhatsapp(OTP_ACTION_TYPES.RESET_LOGIN_PIN));
    //     }
    //     id && history.push('/user/password_forgot');
    // };

    // const otpByVoice = async () => {
    //     let id;
    //     if (resend) {
    //         id = dispatch(newPinResendCode(OTP_ACTION_TYPES.VOICE));
    //     } else {
    //         id = await dispatch(PinResetVoiceOtp(OTP_ACTION_TYPES.RESET_LOGIN_PIN));
    //     }
    //     id && history.push('/user/password_forgot');
    // };

    const otpBySms = async () => {
        let id;
        if (resend) {
            id = dispatch(newPinResendCode(OTP_ACTION_TYPES.SMS));
        } else {
            id = await dispatch(otpLoginBySms(OTP_ACTION_TYPES.RESET_LOGIN_PIN));
        }
        id && history.push('/user/password_forgot');
    };

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title="OTP Verification" />
                <ScreenContainer top={'64px'}>
                    <div style={StyleAvatar}>
                        <UserAvatar avatar={avatar} width="72px" height="72px" />
                    </div>
                    <MainText>
                        Hello,
                        <strong style={{ color: 'black', marginLeft: 2 }}>{firstName || userData?.firstName} </strong>
                    </MainText>
                    <SubText>
                        Let's get you back into your account. Kindly <br />
                        select an option so we can send you an OTP
                    </SubText>
                    {/* <StyledWhatsapPSvg onClick={otpByWhatsapp} />
                    <StyledCallSvg onClick={otpByVoice} /> */}
                    <StyledTextMessageSvg onClick={otpBySms} />
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default PasswordOtpOptions;
