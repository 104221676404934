import React, { Fragment, useEffect } from 'react';

import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Loader, TopHeader, RippleButton, PageLogo, PhoneNumberInput } from '../../../components';
import { Message, Title } from '../../../containers/MessageContainer';
import { ScreenContainer } from '../../../containers/ScreenContainer';
import { getCategories } from '../../../redux/ducks/applications/my-shop/actions/shop';
import { sendTelephone } from '../../../redux/ducks/auth/phone/actions';
import { colors } from '../../../styles';
import DesktopBackgroundLayout from '../../DesktopBackgroundLayout';

import Avatar from './assets/avatar.svg';
import { PhoneNumberSignUpValidationSchema } from './PhoneNumberSignUpValidationSchema';

const PhoneNumberSignUp = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.auth.phone.isLoading);

    useEffect(() => {
        dispatch(getCategories());
    }, [dispatch]);

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader noTitle noBorderBottom />
                <ScreenContainer>
                    <PageLogo top={'14px'} Icon={Avatar} background={colors.lightPink} />
                    <Title textAlign={'left'} color={colors.themeTextColor10} top={'10px'}>
                        Welcome to Sabi
                    </Title>
                    <Message bottom={'24px'} top={'20px'}>
                        {' '}
                        Please provide your phone number below to login or create account
                    </Message>
                    <Formik
                        initialValues={{
                            phoneNumber: '',
                            country: 'NG',
                        }}
                        validationSchema={PhoneNumberSignUpValidationSchema}
                        onSubmit={(values, { resetForm }) => {
                            setTimeout(() => {
                                resetForm();
                                dispatch(sendTelephone(values.phoneNumber, values.country));
                            }, 1000);
                        }}
                    >
                        {({ touched, values, errors, initialValues, setFieldValue }) => (
                            <Form>
                                <PhoneNumberInput
                                    label="Phone number"
                                    placeholder="Phone number"
                                    name="phoneNumber"
                                    autoComplete={'tel'}
                                />
                                <RippleButton
                                    type="submit"
                                    disabled={values.phoneNumber.length < 10 || errors.phoneNumber}
                                    top={'8px'}
                                >
                                    Continue
                                </RippleButton>
                                <Message size={'12px'} align={'center'}>
                                    By continuing, you agree to our{' '}
                                    <strong style={{ color: colors.blue }}>
                                        <Link to="/termsAndConditions">Terms and Conditions</Link>
                                    </strong>{' '}
                                    and{' '}
                                    <strong style={{ color: colors.blue }}>
                                        <Link to="/privacyPolicy">Privacy Policy</Link>
                                    </strong>
                                </Message>
                            </Form>
                        )}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default PhoneNumberSignUp;
