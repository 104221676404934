import React, { Fragment, useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { TopHeader, UserAvatar } from '../../../../../../components';
import { ScreenContainer } from '../../../../../../containers/ScreenContainer';
import DesktopBackgroundLayout from '../../../../../DesktopBackgroundLayout';
import { ReactComponent as Bulb } from '../assets/bulb.svg';
import SearchIconSvg from '../assets/search.svg';
import { ReactComponent as SearchSvg } from '../assets/search_icon.svg';

const Text = styled.h2`
    font-size: ${({ size }) => size || '14px'};
    font-weight: ${({ weight }) => weight || '400'};
    color: ${({ color }) => color || '#227EFF'};
    text-align: ${({ align }) => align || 'left'};
    padding: 2px;
    margin-left: ${({ left }) => left || null};
    margin-top: ${({ top }) => top || null};
`;

const ListItem = styled.div`
    display: flex;
    margin: 16px 0px;
`;

const VerticalDiv = styled.div`
    diplay: flex;
    flex-direction: column;
    margin-left: 16px;
`;

const NoBeneficiaryWrapper = styled.div`
    diplay: flex;
    flex-direction: column;
    margin: 16px;
    padding: 24px 40px;
    align-items: center;
`;

const NoBeneficiaryLogo = styled.div`
    width: 100%;
    height: 100px;
    background-image: url(${({ icon }) => icon});
    background-repeat: no-repeat;
    background-position: center;
`;

const SearchWrapper = styled.div`
    display: flex;
    height: 40px;
`;

const SearchInput = styled.input`
    border: none;
    outline-color: transparent;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    background-color: #edf2f7;
    padding: 15px;
    padding-left: 40px;
    border-radius: 4px;
    width: 100%;
`;

const SearchIcon = styled(SearchSvg)`
    position: absolute;
    left: 0;
    margin-left: 30px;
    margin-top: 13px;
`;

const WalletToBankBeneficiaryList = () => {
    const history = useHistory();
    const customers = useSelector((state) => state.applications.myShop.customers);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        setBeneficiaries(customers && customers.filter((customer) => customer.bank && customer.bank?.accountNumber));
    }, [customers]);

    useEffect(() => {
        setSearchResult(beneficiaries);
    }, [beneficiaries]);

    useEffect(() => {
        if (searchValue) {
            setSearchResult(
                beneficiaries &&
                    beneficiaries.filter((beneficiary) =>
                        beneficiary.name.toLowerCase().includes(searchValue.toLowerCase()),
                    ),
            );
        } else {
            setSearchResult(beneficiaries);
        }
    }, [searchValue, beneficiaries]);

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Beneficiaries'} />
                <ScreenContainer top="64px">
                    <SearchWrapper>
                        <SearchInput
                            placeholder={'Search for beneficary'}
                            onChange={(e) => setSearchValue(e.target.value)}
                            value={searchValue}
                        />
                        <SearchIcon />
                    </SearchWrapper>

                    {!searchResult || searchResult.length === 0 ? (
                        <NoBeneficiaryWrapper>
                            <NoBeneficiaryLogo icon={SearchIconSvg} />
                            <Text bottom="24px" top="36px" color="#071827" weight="700" align="center">
                                No Search results found
                            </Text>
                            <Bulb width={'100%'} />
                            <Text top="8px" color="#718596" size="12px" align="center">
                                Make sure the spelling is correct
                            </Text>
                            <Text top="8px" color="#718596" size="12px" align="center">
                                Use less keywords
                            </Text>
                        </NoBeneficiaryWrapper>
                    ) : (
                        <div>
                            {searchResult &&
                                searchResult.slice(0, 20).map((beneficiary, index) => (
                                    <ListItem
                                        key={index}
                                        onClick={() => {
                                            history.push({
                                                pathname: '/user/wallet_to_bank_transfer',
                                                state: beneficiary,
                                            });
                                        }}
                                    >
                                        <UserAvatar avatar={beneficiary.avatar} width={'32px'} height={'32px'} />
                                        <VerticalDiv>
                                            <Text size="12px" color="#071827">
                                                {beneficiary.name}
                                            </Text>
                                            <Text color="#718596">{`${beneficiary?.bank?.name || ''}, ${
                                                beneficiary?.bank?.accountNumber || ''
                                            }`}</Text>
                                        </VerticalDiv>
                                    </ListItem>
                                ))}
                        </div>
                    )}
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default WalletToBankBeneficiaryList;
