import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import useRouter from 'use-react-router';

import { Loader, RippleButton, TopHeader } from '../../../../components';
import { getProductByPublicProductId } from '../../../../redux/ducks/applications/store-front/actions/store';
import { colors } from '../../../../styles';
import { formatCurrency } from '../../../../utils/currency/parseBalance';
import { toTitleCase } from '../../../../utils/toTitleCase';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import StoreFrontFooter from '../footer';
import StoreFrontHeader from '../header';

const Space = styled.div`
    height: 24px;
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 16px;
    width: 100%;
`;

const ImageRightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 25%;
    height: 192px;
`;

const Image = styled.img`
    border-radius: ${({ radius }) => radius || '8px'};
    height: ${({ height }) => height || '192px'};
    width: ${({ width }) => width || '73%'};
    cursor: pointer;
`;

const ImageInner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 65.41px;
    border: ${({ active }) => active && `1px solid ${colors.deepBlue}`};
    border-radius: 8px;
    padding: ${({ padding }) => padding};
    margin-bottom: ${({ index, active }) => index < 2 && (active ? '3.89' : '6.89')};
`;

const Title = styled.div`
    margin-top: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.boldDark};
`;

const Desc = styled.div`
    margin-top: 8px;
    font-size: 12px;
    line-height: 18px;
    color: #5c6f7f;
`;

const Amount = styled.div`
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: ${colors.boldDark};
    margin-top: 16px;
`;

const Wrapper = styled.div`
    margin: 0 16px;
`;

const Body = styled.div`
    max-height: 80vh;
    overflow-y: scroll;
    margin-top: 70px;
`;

const StoreFrontProduct = () => {
    const dispatch = useDispatch();
    const product = useSelector((state) => state.applications.storeFront.publicProduct);
    const loading = useSelector((state) => state.applications.storeFront.loadingProducts);
    const [activeImage, setActiveImage] = useState(0);
    const { location } = useRouter();
    const { pathname } = location;
    const pattern = `(.*)?${'/actions/store_front/:store_link/:product_id'}`;
    const match = matchPath(pathname, { path: pattern }) || {};
    const [cartLength, setCartLength] = useState(0);
    const [buttonText, setButtonText] = useState('Add to Cart');

    const carouselItem = [
        {
            id: 1,
            image: Object.keys(product).length > 0 && product?.images[product?.images?.length - 3]?.imageUrl,
        },
        {
            id: 2,
            image: Object.keys(product).length > 0 && product?.images[product?.images?.length - 2]?.imageUrl,
        },
        {
            id: 3,
            image: Object.keys(product).length > 0 && product?.images[product?.images?.length - 1]?.imageUrl,
        },
    ];

    const handleAddCart = () => {
        if (product.quantityInStock === 0) {
            toast.error('Sorry, there is not enough of this product to buy at this time');
        } else {
            setButtonText('Adding to cart...');
            const carts = JSON.parse(localStorage.getItem('storeFrontCart'));
            if (carts) {
                const newCarts = [...carts];
                const isCartExist = newCarts?.find((cart) => cart?.id === match.params.product_id);
                if (isCartExist) {
                    isCartExist.quantity = isCartExist?.quantity + 1;
                    const existingCartIndex = newCarts?.findIndex((cart) => cart?.id === isCartExist?.id);
                    newCarts[existingCartIndex] = isCartExist;
                    localStorage.setItem('storeFrontCart', JSON.stringify(newCarts));
                } else {
                    newCarts.push({ ...product, quantity: 1 });
                    localStorage.setItem('storeFrontCart', JSON.stringify(newCarts));
                    setCartLength(cartLength + 1);
                }
            } else {
                localStorage.setItem('storeFrontCart', JSON.stringify([{ ...product, quantity: 1 }]));
                setCartLength(cartLength + 1);
            }
            setTimeout(() => {
                setButtonText('Add to Cart');
            }, 2000);
        }
    };

    useEffect(() => {
        dispatch(getProductByPublicProductId(match.params.product_id));
    }, [dispatch, match.params.product_id]);

    useEffect(() => {
        const carts = JSON.parse(localStorage.getItem('storeFrontCart'));
        if (carts) {
            setCartLength(carts?.length);
        }
    }, []);

    if (loading) return <Loader />;

    return (
        <DesktopBackgroundLayout fullHeight bgColor="#F9FAFC">
            <StoreFrontHeader cartItemNumber={cartLength} showMenu />
            <TopHeader noBorderBottom backgroundColor="#F9FAFC" top="65px" mediaTop="65px" />
            <Body>
                <ImageWrapper>
                    <Image src={carouselItem[activeImage].image} />
                    <ImageRightWrapper>
                        {carouselItem.map((image, index) => (
                            <ImageInner
                                active={activeImage === index}
                                onClick={() => setActiveImage(index)}
                                index={index}
                                padding="3px 2.57px"
                                key={index}
                            >
                                <Image
                                    src={image.image}
                                    alt={`product picture ${index}`}
                                    radius="6px"
                                    height="100%"
                                    width="100%"
                                />
                            </ImageInner>
                        ))}
                    </ImageRightWrapper>
                </ImageWrapper>
                <Wrapper>
                    <Title>{toTitleCase(product?.name)}</Title>
                    <Desc>{product?.description}</Desc>
                    <Amount>{formatCurrency(product?.price)}</Amount>
                    <RippleButton onClick={handleAddCart} height="48px">
                        {buttonText}
                    </RippleButton>
                </Wrapper>
                <Space />
                <StoreFrontFooter noPosition />
            </Body>
        </DesktopBackgroundLayout>
    );
};

export default StoreFrontProduct;
