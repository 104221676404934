import React from 'react';

import { number } from 'prop-types';
import styled from 'styled-components';

import { InputLabelBlock } from '../../../../containers/InputContainer';
import { TransactionStatus } from '../../../../containers/MessageContainer';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';

const OutstandingAmount = styled(TransactionStatus)`
    margin-left: 16px;
`;

export const AmountDue = ({ amount }) => {
    return (
        <InputLabelBlock textCenter={true} top={'16px'}>
            <OutstandingAmount type={amount === 0 ? 'PAID' : 'PENDING'}>
                {amount && formatPrice(amount)}
            </OutstandingAmount>
        </InputLabelBlock>
    );
};

AmountDue.propTypes = {
    amount: number,
};
