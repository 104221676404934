import GhanaFlag from '../../assets/flags/ghana.svg';
import KenyaFlag from '../../assets/flags/kenya.svg';
import NigerFlag from '../../assets/flags/niger.svg';
import NigeriaFlag from '../../assets/flags/nigeria.svg';
import SouthAfricaFlag from '../../assets/flags/southAfrica.svg';

import { GhanaInfo } from './ghana';
import { KenyaInfo } from './kenya';
import { NigerInfo } from './niger';
import { NigeriaInfo } from './nigeria';
import { SouthAfricaInfo } from './southAfrica';

const countriesMap = new Map();

countriesMap.set('NG', NigeriaInfo);
countriesMap.set('KE', KenyaInfo);
countriesMap.set('NI', NigerInfo);
countriesMap.set('GH', GhanaInfo);
countriesMap.set('SA', SouthAfricaInfo);

const countriesData = Array.from(countriesMap.values());
const countriesKeys = Array.from(countriesMap.keys());
const phoneCodes = countriesData.map((country) => country.code);
const countriesNames = countriesData.map((country, index) => ({
    value: countriesKeys[index],
    label: country.name,
}));

const flags = [
    { label: 'Nigeria', value: NigeriaFlag, customAbbreviation: 'NG' },
    { label: 'Kenya', value: KenyaFlag, customAbbreviation: 'KE' },
    { label: 'Niger', value: NigerFlag, customAbbreviation: 'NI' },
    { label: 'Ghana', value: GhanaFlag, customAbbreviation: 'GH' },
    { label: 'South Africa', value: SouthAfricaFlag, customAbbreviation: 'SA' },
];

export { countriesMap, countriesNames, countriesKeys, flags, countriesData, phoneCodes };
