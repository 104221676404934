import React, { useState, useEffect } from 'react';

import GoogleMapReact from 'google-map-react';

import { getCoordinateFromAddress } from './getAddress';
import Marker from './marker';

const geocodeKey = process.env.REACT_APP_GEOCODE_KEY;

const SimpleMap = ({ coordinates, setCoordinates, address }) => {
    const [center, setCenter] = useState({
        lat: 0,
        lng: 0,
    });

    const getMapOptions = {
        disableDefaultUI: true,
        mapTypeControl: true,
        streetViewControl: true,
        zoomControl: true,
        styles: [
            {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [
                    {
                        visibility: 'on',
                    },
                ],
            },
        ],
    };

    useEffect(() => {
        setCenter({
            lat: coordinates.latitude || 0,
            lng: coordinates.longitude || 0,
        });
    }, [coordinates]);

    useEffect(() => {
        if (address) {
            getCoordinateFromAddress(address).then((mapProps) => {
                setCoordinates({ latitude: mapProps.lat, longitude: mapProps.lng });
            });
        }
    }, [address, setCoordinates]);

    return (
        <div style={{ height: '250px', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: geocodeKey,
                }}
                center={center}
                defaultZoom={15}
                options={getMapOptions}
                onClick={async (mapProps) => {
                    setCoordinates({ latitude: mapProps.lat, longitude: mapProps.lng });
                }}
            >
                <Marker lat={coordinates.latitude} lng={coordinates.longitude} name="My Marker" color="blue" />
            </GoogleMapReact>
        </div>
    );
};

export default SimpleMap;
