import React, { Fragment } from 'react';

import { useClearCache } from 'react-clear-cache';
import styled from 'styled-components';

import { TopHeader } from '../../components';
import { ScreenContainer } from '../../containers/ScreenContainer';
import { colors } from '../../styles';
import { clearUserCache } from '../../utils/clear-cache';
import DesktopBackgroundLayout from '../DesktopBackgroundLayout';

const VersionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0 0 0;
`;

const RightMainText = styled.p`
    color: ${colors.themeTextColor11};
    fontsize: 10px;
    line-height: 16px;
    font-weight: 700;
`;

const LeftText = styled.p`
    color: ${colors.themeTxtColor10};
    fontsize: 10px;
    line-height: 16px;
    font-weight: 500;
`;

const LeftText2 = styled.p`
    color: ${colors.themeTextColor12};
    fontsize: 10px;
    line-height: 16px;
    font-weight: 500;
    &:hover {
        border: 1px solid ${colors.blue};
        cursor: pointer;
    }
`;

const VersionNumber = styled.p`
    color: ${colors.themeTextColor10};
    fontsize: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 0px;
`;

export const AppVersion = () => {
    const cacheInfo = useClearCache({ duration: 60000 });

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'About App'} />
                <ScreenContainer>
                    <VersionWrapper>
                        <RightMainText>APP VERSION </RightMainText>
                        {cacheInfo.isLatestVersion ? (
                            <LeftText>Updated</LeftText>
                        ) : (
                            <LeftText2 onClick={(e) => clearUserCache(e, cacheInfo)}>Update available</LeftText2>
                        )}
                    </VersionWrapper>
                    <VersionNumber>{cacheInfo.latestVersion}</VersionNumber>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};
