export const classPick = (complaint) => {
    if (complaint === 'Open') {
        return 'open';
    } else if (complaint === 'On-hold') {
        return 'hold';
    } else {
        return 'closed';
    }
};

export const divColor = (selectedIndex) => {
    if (selectedIndex === 0 || selectedIndex % 3 === 0) {
        return '#ebecff';
    } else if (selectedIndex === 1 || (selectedIndex + 2) % 3 === 0) {
        return '#ffece0';
    } else if (selectedIndex === 2 || (selectedIndex + 1) % 3 === 0) {
        return '#e9f2ff';
    }
};
export const textColor = (selectedIndex) => {
    if (selectedIndex === 0 || selectedIndex % 3 === 0) {
        return '#3c48fc';
    } else if (selectedIndex === 1 || (selectedIndex + 2) % 3 === 0) {
        return '#ffa470';
    } else if (selectedIndex === 2 || (selectedIndex + 1) % 3 === 0) {
        return '#64a4ff';
    }
};
