import React, { Fragment, useState, useEffect } from 'react';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as CalendarIcon } from '../../../../assets/calendar-new.svg';
import {
    PageLogo,
    TopHeader,
    Loader,
    OptionsPopupDialog,
    DeliveryLocationPopup,
    RippleLink,
    RippleButton,
    InfiniteScrollList,
    DateRangePopup,
} from '../../../../components';
import { List, ListItem, ListHeading, ListSubHeading } from '../../../../containers/ListContainer';
import { Message, Title } from '../../../../containers/MessageContainer';
import {
    FlexCenteredBlock,
    ScreenContainer,
    FilterAndSortSection,
    SortFilterCell,
} from '../../../../containers/ScreenContainer';
import { merchbuyActions } from '../../../../redux/ducks/applications/merchbuy/actions';
import { colors } from '../../../../styles';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import { formatDateWithSlash } from '../../../../utils/date/formatDateWithSlash';
import { parseTextCountRender } from '../../../../utils/inputs/parseTextCountRender';
import { ORDER_DETAILS, ORDER_HISTORY_TRACKING } from '../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../utils/mix-panel/mixPanel';
import { toTitleCase } from '../../../../utils/toTitleCase';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { OrderQuantity } from '../../my-shop/containers/OrderBadgesContainer';
import { ReactComponent as CloseIcon } from '../assets/close-new.svg';
import DefaultProduct from '../assets/default-product-image.svg';
import { ReactComponent as DownIcon } from '../assets/drop-down-new.svg';
import EmptyResultsIcon from '../assets/empty-search.svg';
import { ReactComponent as ItemIcon } from '../assets/item-view.svg';
import BulbIcon from '../assets/lightbulb.svg';
import { ReactComponent as OrderViewIcon } from '../assets/order-view.svg';
import OrdersIcon from '../assets/order.svg';
import { ReactComponent as SearchIcon } from '../assets/search-new.svg';
import { ReactComponent as Oldest } from '../assets/sort-oldest.svg';
import { ReactComponent as MostRecent } from '../assets/sort-recent.svg';
import { ReactComponent as UsersSvg } from '../assets/two-users.svg';
import { Space } from '../styles';

import GroupBuyOrderDetails from './GroupBuyOrderDetails';

const OrderTypeWrapper = styled.div`
    display: flex;
    background-color: #edf2f7;
    padding: 4px 5px;
    border-radius: 8px;
    width: 127px;
    height: 32px;
    margin: 16px 0 16px auto;
`;

const OrderTypeContainer = styled.div`
    width: 100%;
    justify-content: center;
    background-color: ${({ background }) => background};
    border-radius: 4px;
    display: flex;
    align-items: center;

    & > svg {
        margin-right: 6px;
    }
`;

const OrderTypeText = styled.p`
    color: #212c3d;
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
`;

const StyledRippleLink = styled(RippleLink)`
    position: absolute;
    bottom: 71px;
    width: calc(100% - 32px);
`;

const Form = styled.form`
    position: fixed;
    margin: 0 -16px;
    padding: 5px 0 12px;
    background-color: #fff;
    top: 125px;
    left: 0;
    right: 0;
    margin: auto;
    width: 343px;

    @media (max-width: 576px) {
        width: calc(100% - 32px);
        top: 62px;
    }
    z-index: 99;
`;

const SearchInput = styled.input`
    border: none;
    outline-color: transparent;
    font-weight: 400;
    color: #071827;
    font-size: 14px;
    background-color: #edf2f7;
    padding: 10px 14px 10px 40px;
    border-radius: 4px;
    width: calc(100% - 32px);
    margin: 0 auto;
    display: block;
    line-height: 21px;
    &::placeholder {
        font-size: inherit;
        font-weight: inherit;
        color: #6f798b;
        line-height: 20px;
    }
`;

const StyledSearchIcon = styled(SearchIcon)`
    position: absolute;
    left: 33.33px;
    top: 18.33px;
`;

const StyledCloseIcon = styled(CloseIcon)`
    position: absolute;
    right: 29.33px;
    top: 18.33px;
`;

const OrderPrice = styled.p`
    font-weight: 700;
    font-size: 12px;
    color: #071827;
    margin: 0;
`;

const OrderDate = styled(OrderQuantity)``;

const CustomerName = styled.span`
    color: ${({ color }) => color || '#718596'};
    font-size: 12px;
    font-weight: 500;
    margin: 5px;
`;

const Block = styled.div`
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
    align-items: ${({ align }) => align};
    border-bottom: ${({ borderBottom }) => borderBottom && '1px solid #EDF2F7'};
    margin-top: ${({ top }) => top};
    margin-bottom: ${({ bottom }) => bottom};
    padding-bottom: ${({ bottomPadding }) => bottomPadding};
`;

const ModifiedListItem = styled(ListItem)`
    background-color: #fff;
    border-radius: 4px;
    &:not(:first-child) {
        margin-top: 8px;
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${({ margin }) => margin};
    width: 100%;
`;

const GroupbuyStatus = styled.div`
    display: flex;
    align-items: center;
    width: 78px;
    height: 20px;
    border-radius: 16px;
    background: linear-gradient(94.68deg, #7e42f5 16.08%, #227eff 262.55%);
    padding: 2px;
`;

const UsersIconWrapper = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ede6fe;
    margin-right: 4px;
`;

const GroupbuyText = styled.p`
    margin: 0;
    font-size: 10px;
    line-height: 16px;
    color: #fff;
`;

const ItemName = styled(ListHeading)`
    font-weight: 400;
    text-transform: initial;
    margin-bottom: 8px;
`;
const SmallText = styled.p`
    font-size: 10px;
    line-height: 16px;
    margin: 0;
    color: ${({ color }) => color || '#718596'};
`;

const SpanText = styled.span`
    color: ${({ color }) => color};
    margin-left: 5px;
`;

const StatusColor = styled.span`
    width: 10px;
    height: 10px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
    margin-right: 4px;
`;

const dateSearchValues = {
    Today: 'TODAY',
    Yesterday: 'YESTERDAY',
    'This Week': 'THIS_WEEK',
    'This Month': 'THIS_MONTH',
    'This Year': 'THIS_YEAR',
    'Specific Day': 'SPECIFIC_DATE',
    'Date Range': 'DATE_RANGE',
    'All time': 'LIFE_TIME',
};

const sortTypeValues = {
    'Most Recent': 'DESC',
    Oldest: 'ASC',
};

const Image = styled.img`
    width: 44px;
    height: 44px;
    border-radius: 4px;
`;

const OrderHistoryList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const orders = useSelector((state) => state.applications.merchbuy.orderHistory);
    const totalOrdersNumber = useSelector((state) => state.applications.merchbuy.totalOrders);
    const orderItems = useSelector((state) => state.applications.merchbuy.orderHistoryByItems);
    const totalOrderItems = useSelector((state) => state.applications.merchbuy.totalOrdersByItems);
    const isLoading = useSelector((state) => state.applications.merchbuy.isLoading);
    const role = useSelector((state) => state.user.role);

    const [allOrders, setAllOrders] = useState(orders);
    const [orderType, setOrderType] = useState('orders');
    const [sortType, setSortType] = useState('Most Recent');
    const [filterType, setFilterType] = useState('All time');
    const [searchValue, setSearchValue] = useState('');
    const [confirmedSearch, setConfirmedSearch] = useState('');
    const [openFilterOptions, setOpenFilterOptions] = useState(false);
    const [sortOptionsOpen, setSortOptionsOpen] = useState(false);
    const [openGroupBuyOrderDetails, setGroupBuyOpenOrderDetails] = useState(false);
    const [groupBuyOrderId /*setGroupBuyOrderId*/] = useState();
    const [deliveryLocation, setDeliveryLocation] = useState(JSON.parse(localStorage.getItem('deliveryLocation')));

    const [openDeliverLocationPopup, setOpenDeliverLocationPopup] = useState(deliveryLocation === null);
    const [page, setPage] = useState(1);
    const perPage = 50;
    const [hasMore, setHasMore] = useState(false);
    const [ordersToDisplay, setOrdersToDisplay] = useState(allOrders);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [openDateRange, setOpenDateRange] = useState(false);
    const [formatedStartDate, setFormatedStartDate] = useState(undefined);
    const [formatedEndDate, setFormatedEndDate] = useState(undefined);
    const [isFIlterEmpty, setIsFIlterEmpty] = useState(false);
    const [loading, setLoading] = useState(false);
    const [altloading, setAltloading] = useState(false);

    const getLocation = (data) => {
        if (data.state) {
            setDeliveryLocation(data);
            setOpenDeliverLocationPopup(!openDeliverLocationPopup);
        }
    };

    useEffect(() => {
        if (startDate) {
            setFormatedStartDate(formatDateWithSlash(startDate));
        }
        if (endDate) {
            setFormatedEndDate(formatDateWithSlash(endDate));
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (orderType === 'orders' && orders?.length > 0) {
            setAllOrders(orders);
        }
        if (orderType === 'items' && orderItems?.length > 0) {
            setAllOrders(orderItems);
        }
    }, [orderType, orders, orderItems]);

    useEffect(() => {
        dispatch(
            merchbuyActions.getOrderHistory({
                actionType: 'allOrders',
                page: 1,
                size: perPage,
            }),
        );
        dispatch(
            merchbuyActions.getOrderHistoryByItems({
                actionType: 'allOrders',
                page: 1,
                size: perPage,
            }),
        );
    }, [dispatch]);

    useEffect(() => {
        mixPanel.track(ORDER_HISTORY_TRACKING, {
            'View Type': orderType,
        });
    }, [orderType]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setConfirmedSearch(searchValue);
        }, 1500);
        return () => clearTimeout(timeout);
    }, [searchValue]);

    useEffect(() => {
        const getOrders = async () => {
            setLoading(true);
            const params = {
                actionType: 'filterOrders',
                page: 1,
                size: perPage,
                searchTerm: confirmedSearch,
                sortDirection: sortTypeValues[sortType],
                dateSearch: dateSearchValues[filterType],
                fromDate: formatedStartDate,
                toDate: formatedEndDate,
            };

            const data =
                orderType === 'orders'
                    ? await dispatch(merchbuyActions.getOrderHistory(params))
                    : await dispatch(merchbuyActions.getOrderHistoryByItems(params));

            if (data && data?.data == null) return setLoading(false);
            if (data && data?.data?.length === 0) {
                setIsFIlterEmpty(true);
                setLoading(false);
            }
            if (data && data?.data?.length > 0) {
                setIsFIlterEmpty(false);
                setOrdersToDisplay(data?.data);
                setLoading(false);
            }
        };

        if (
            (filterType === 'Specific Day' || filterType === 'Date Range') &&
            (!formatedStartDate || !formatedEndDate)
        ) {
            return;
        }

        getOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, confirmedSearch, formatedStartDate, formatedEndDate, orderType, sortType, filterType]);

    const addMoreDataToDisplay = async () => {
        if (ordersToDisplay?.length < totalOrdersNumber) {
            const data = await dispatch(
                merchbuyActions.getOrderHistory({
                    actionType: 'paginateOrders',
                    page: page,
                    size: perPage,
                    searchTerm: searchValue,
                    sortDirection: sortTypeValues[sortType],
                    dateSearch: dateSearchValues[filterType],
                    fromDate: formatedStartDate,
                    toDate: formatedEndDate,
                }),
            );
            setOrdersToDisplay([...ordersToDisplay, ...data?.data]);
            setPage(page + 1);
        } else {
            setHasMore(false);
        }
    };

    const showEmptyState = () => {
        if (orderType === 'orders' && !orders?.length) return true;
        if (orderType === 'items' && !orderItems?.length) return true;
    };

    useEffect(() => {
        if (orderType === 'orders') {
            setOrdersToDisplay(orders);
            // change to total or orders by orders when merged
            if (orders && orders?.length < totalOrdersNumber) {
                setPage(2);
                setHasMore(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders, orderType]);

    useEffect(() => {
        if (orderType === 'items') {
            setOrdersToDisplay(orderItems);

            if (orderItems && orderItems?.length < totalOrderItems) {
                setPage(2);
                setHasMore(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderItems, orderType]);

    const addMoreDataItemsToDisplay = async () => {
        if (ordersToDisplay?.length < totalOrderItems) {
            const data = await dispatch(
                merchbuyActions.getOrderHistoryByItems({
                    actionType: 'paginateOrders',
                    page: page,
                    size: perPage,
                    searchTerm: searchValue,
                    sortDirection: sortTypeValues[sortType],
                    dateSearch: dateSearchValues[filterType],
                    fromDate: formatedStartDate,
                    toDate: formatedEndDate,
                }),
            );
            setOrdersToDisplay([...ordersToDisplay, ...data.data]);
            setPage(page + 1);
        } else {
            setHasMore(false);
        }
    };

    const imageHasRightExtension = (url) => {
        return !!url.match(/\w+\.(jpg|jpeg|gif|png|tiff|bmp)$/gi);
    };

    if (isLoading || !ordersToDisplay || loading || altloading) {
        return <Loader />;
    } else
        return (
            <Fragment>
                <DesktopBackgroundLayout>
                    {openGroupBuyOrderDetails && (
                        <GroupBuyOrderDetails
                            close={() => {
                                setGroupBuyOpenOrderDetails(false);
                            }}
                            groupBuyOrderId={groupBuyOrderId}
                        />
                    )}
                    {!openGroupBuyOrderDetails && (
                        <Fragment>
                            <Fragment>
                                <TopHeader
                                    noBorderBottom={true}
                                    title={'Orders'}
                                    right={'24px'}
                                    sticky
                                    backLink={'/actions/merchbuy'}
                                    size="14px"
                                    weight="400"
                                    color="#071827"
                                />
                                <Space height={'54px'} />
                                <ScreenContainer color="#F9FAFC" top="0" padding="10px 16px 16px">
                                    {showEmptyState() ? (
                                        <Fragment>
                                            <FlexCenteredBlock
                                                top={'24px'}
                                                mtop={'24px'}
                                                color="#fff"
                                                padding="0 0 13px"
                                                radius="8px"
                                                bottom="70px"
                                            >
                                                <PageLogo
                                                    Icon={OrdersIcon}
                                                    width={'150px'}
                                                    height={'130px'}
                                                    iconHeight={'122.46px'}
                                                    iconWidth={'118.5px'}
                                                    margin={'48px auto 19px'}
                                                />
                                                <Title size="14px" color="#071827" weight="700">
                                                    You don't have any orders
                                                </Title>
                                                <PageLogo
                                                    Icon={BulbIcon}
                                                    width={'24px'}
                                                    height={'24px'}
                                                    borderRadius="50%"
                                                    background="#FFF7E6"
                                                    iconHeight={'auto'}
                                                    iconWidth={'auto'}
                                                    margin={'8px auto'}
                                                />
                                                <Message
                                                    bottom={'24px'}
                                                    top={'0px'}
                                                    align={'center'}
                                                    padding={'0 1em'}
                                                    size="12px"
                                                    weight="400"
                                                    color="#718596"
                                                >
                                                    When you make a purchase, you can track your items in orders?.
                                                </Message>
                                            </FlexCenteredBlock>
                                            <StyledRippleLink to={'/actions/merchbuy'}>
                                                <RippleButton size="16px">Start Shopping</RippleButton>
                                            </StyledRippleLink>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <Form>
                                                <StyledSearchIcon />
                                                <SearchInput
                                                    type="text"
                                                    value={searchValue}
                                                    onChange={(e) => setSearchValue(e.target.value)}
                                                    placeholder="Search product, order or customer"
                                                />
                                                {searchValue?.length > 0 && (
                                                    <StyledCloseIcon onClick={() => setSearchValue('')} />
                                                )}
                                            </Form>

                                            <FilterAndSortSection
                                                top="60px"
                                                bottom="8px"
                                                borderTop="1px solid #EDF2F7"
                                                borderBottom="1px solid #EDF2F7"
                                                padding="0 17px 0 30px"
                                                left="-16px"
                                                right="-16px"
                                                background="#fff"
                                            >
                                                <SortFilterCell
                                                    padding="11px 0 11px 20px"
                                                    color="#071827"
                                                    weight="500"
                                                    height="40px"
                                                    borderRight="1px solid #EDF2F7"
                                                    width="50%"
                                                    onClick={() => setOpenFilterOptions(!openFilterOptions)}
                                                >
                                                    {filterType}
                                                    <DownIcon />
                                                </SortFilterCell>
                                                <SortFilterCell
                                                    padding="11px 20px 11px 0"
                                                    color="#071827"
                                                    weight="500"
                                                    height="40px"
                                                    onClick={() => setSortOptionsOpen(!sortOptionsOpen)}
                                                >
                                                    {sortType}
                                                    <DownIcon />
                                                </SortFilterCell>
                                            </FilterAndSortSection>

                                            <OrderTypeWrapper>
                                                <OrderTypeContainer
                                                    onClick={() => setOrderType('items')}
                                                    background={orderType === 'items' ? '#fff' : null}
                                                >
                                                    <ItemIcon />
                                                    <OrderTypeText>Item</OrderTypeText>
                                                </OrderTypeContainer>
                                                <OrderTypeContainer
                                                    onClick={() => setOrderType('orders')}
                                                    background={orderType === 'orders' ? '#fff' : null}
                                                >
                                                    <OrderViewIcon />
                                                    <OrderTypeText>Order</OrderTypeText>
                                                </OrderTypeContainer>
                                            </OrderTypeWrapper>

                                            {isFIlterEmpty ? (
                                                <Fragment>
                                                    <FlexCenteredBlock
                                                        top={'16px'}
                                                        color="#fff"
                                                        padding="0 0 60.02px"
                                                        radius="8px"
                                                        bottom="70px"
                                                    >
                                                        <PageLogo
                                                            Icon={EmptyResultsIcon}
                                                            width={'126.5px'}
                                                            height={'105.7px'}
                                                            iconWidth={'126.5px'}
                                                            iconHeight={'105.7px'}
                                                            margin={'40px auto 24.3px'}
                                                            borderRadius="initial"
                                                        />
                                                        <Title size="14px" color="#071827" weight="700">
                                                            We couldn't find any matches
                                                        </Title>
                                                        <PageLogo
                                                            Icon={BulbIcon}
                                                            width={'24px'}
                                                            height={'24px'}
                                                            background="#FFF7E6"
                                                            iconHeight={'auto'}
                                                            iconWidth={'auto'}
                                                            margin={'12px auto 14px'}
                                                        />
                                                        <Message
                                                            top={'0px'}
                                                            align={'center'}
                                                            padding={'0 1em'}
                                                            size="12px"
                                                            weight="400"
                                                            color="#718596"
                                                        >
                                                            {searchValue?.length > 0
                                                                ? 'Make sure the spelling is correct'
                                                                : 'Filter by another period'}
                                                        </Message>
                                                    </FlexCenteredBlock>
                                                </Fragment>
                                            ) : (
                                                <List childLink>
                                                    <InfiniteScrollList
                                                        data={ordersToDisplay || []}
                                                        fetchMore={
                                                            orderType === 'orders'
                                                                ? addMoreDataToDisplay
                                                                : addMoreDataItemsToDisplay
                                                        }
                                                        hasMore={hasMore}
                                                        endMessage={`That's all your ${orderType}!`}
                                                    >
                                                        {ordersToDisplay &&
                                                            ordersToDisplay.map((item, index) => (
                                                                <ModifiedListItem
                                                                    key={index}
                                                                    direction="column"
                                                                    top="18px"
                                                                    bottom="14px"
                                                                    onClick={async () => {
                                                                        mixPanel.track(ORDER_DETAILS, {
                                                                            'order no': item?.orderNumber,
                                                                            Time: new Date().toLocaleString(),
                                                                        });
                                                                        const res = await dispatch(
                                                                            merchbuyActions.getOrderHistoryById(
                                                                                orderType === 'items'
                                                                                    ? item?.orderId
                                                                                    : item?.id,
                                                                            ),
                                                                        );
                                                                        setAltloading(false);
                                                                        res &&
                                                                            history.push(
                                                                                '/actions/merchbuy/order-history/details',
                                                                            );
                                                                    }}
                                                                >
                                                                    {orderType === 'items' && (
                                                                        <>
                                                                            <Block
                                                                                justifyContent="initial"
                                                                                borderBottom
                                                                                bottom="8px"
                                                                                bottomPadding="8px"
                                                                            >
                                                                                <RippleLink
                                                                                    to={{
                                                                                        pathname: `/actions/merchbuy/product/${
                                                                                            item?.productId || item?.id
                                                                                        }`,
                                                                                        state: {
                                                                                            fromOrderHistory: true,
                                                                                        },
                                                                                    }}
                                                                                >
                                                                                    <Image
                                                                                        alt="product"
                                                                                        src={
                                                                                            imageHasRightExtension(
                                                                                                item?.productImageUrl ||
                                                                                                    '',
                                                                                            )
                                                                                                ? item?.productImageUrl
                                                                                                : DefaultProduct
                                                                                        }
                                                                                    />
                                                                                </RippleLink>
                                                                                <Column margin="0 0 0 12px">
                                                                                    {item?.isGroupBuy && (
                                                                                        <Block bottom="4px">
                                                                                            <GroupbuyStatus>
                                                                                                <UsersIconWrapper>
                                                                                                    <UsersSvg />
                                                                                                </UsersIconWrapper>
                                                                                                <GroupbuyText>
                                                                                                    Groupbuy
                                                                                                </GroupbuyText>
                                                                                            </GroupbuyStatus>
                                                                                        </Block>
                                                                                    )}
                                                                                    <ItemName>
                                                                                        {item.productName &&
                                                                                            toTitleCase(
                                                                                                item?.productName.toLowerCase(),
                                                                                            )}
                                                                                    </ItemName>
                                                                                    <Block>
                                                                                        <OrderPrice>
                                                                                            {formatPrice(
                                                                                                item?.unitPrice,
                                                                                            )}
                                                                                        </OrderPrice>
                                                                                        <OrderQuantity>
                                                                                            x{item?.totalQuantity}
                                                                                        </OrderQuantity>
                                                                                    </Block>
                                                                                </Column>
                                                                            </Block>
                                                                            {role === 'ROLE_AGENT' && (
                                                                                <Block
                                                                                    borderBottom
                                                                                    bottom="8px"
                                                                                    bottomPadding="8px"
                                                                                    align="center"
                                                                                >
                                                                                    <ListSubHeading
                                                                                        margin="0"
                                                                                        weight="400"
                                                                                        fontSize="12px"
                                                                                        color={colors.lightDark}
                                                                                    >
                                                                                        Customer
                                                                                    </ListSubHeading>
                                                                                    <CustomerName
                                                                                        weight="400"
                                                                                        fontSize="12px"
                                                                                        color="#071827"
                                                                                    >
                                                                                        {item?.customerName}
                                                                                    </CustomerName>
                                                                                </Block>
                                                                            )}
                                                                            {item?.statusQuantities?.new > 0 && (
                                                                                <Block
                                                                                    justifyContent="initial"
                                                                                    align="center"
                                                                                    top="8px"
                                                                                >
                                                                                    <StatusColor color="grey"></StatusColor>
                                                                                    <SmallText>
                                                                                        New:{' '}
                                                                                        <SpanText>
                                                                                            {parseTextCountRender(
                                                                                                item?.statusQuantities
                                                                                                    ?.new,
                                                                                                'unit',
                                                                                            )}
                                                                                        </SpanText>
                                                                                    </SmallText>
                                                                                </Block>
                                                                            )}
                                                                            {item?.statusQuantities?.unverifiedPayment >
                                                                                0 && (
                                                                                <Block
                                                                                    justifyContent="initial"
                                                                                    align="center"
                                                                                    top="8px"
                                                                                >
                                                                                    <StatusColor color="black"></StatusColor>
                                                                                    <SmallText>
                                                                                        Unverified:{' '}
                                                                                        <SpanText>
                                                                                            {parseTextCountRender(
                                                                                                item?.statusQuantities
                                                                                                    ?.unverifiedPayment,
                                                                                                'unit',
                                                                                            )}
                                                                                        </SpanText>
                                                                                    </SmallText>
                                                                                </Block>
                                                                            )}
                                                                            {(item?.statusQuantities?.hunting > 0 ||
                                                                                item?.statusQuantities?.attended > 0 ||
                                                                                item?.statusQuantities?.onHold > 0 ||
                                                                                item?.statusQuantities?.confirmed > 0 ||
                                                                                item?.statusQuantities?.picked > 0 ||
                                                                                item?.statusQuantities?.packed > 0) && (
                                                                                <Block
                                                                                    justifyContent="initial"
                                                                                    align="center"
                                                                                    top="8px"
                                                                                >
                                                                                    <StatusColor color="purple"></StatusColor>
                                                                                    <SmallText>
                                                                                        In progress:
                                                                                        <SpanText>
                                                                                            {parseTextCountRender(
                                                                                                (item?.statusQuantities
                                                                                                    ?.hunting || 0) +
                                                                                                    item
                                                                                                        ?.statusQuantities
                                                                                                        ?.confirmed +
                                                                                                    item
                                                                                                        ?.statusQuantities
                                                                                                        ?.picked +
                                                                                                    item
                                                                                                        ?.statusQuantities
                                                                                                        ?.attended +
                                                                                                    item
                                                                                                        ?.statusQuantities
                                                                                                        ?.onHold +
                                                                                                    item
                                                                                                        ?.statusQuantities
                                                                                                        ?.packed,
                                                                                                'unit',
                                                                                            )}
                                                                                        </SpanText>
                                                                                    </SmallText>
                                                                                </Block>
                                                                            )}
                                                                            {item?.statusQuantities?.shipped > 0 && (
                                                                                <Block
                                                                                    justifyContent="initial"
                                                                                    align="center"
                                                                                    top="8px"
                                                                                >
                                                                                    <StatusColor color="blue"></StatusColor>
                                                                                    <SmallText>
                                                                                        Shipped:{' '}
                                                                                        <SpanText>
                                                                                            {parseTextCountRender(
                                                                                                item?.statusQuantities
                                                                                                    ?.shipped,
                                                                                                'unit',
                                                                                            )}
                                                                                        </SpanText>
                                                                                    </SmallText>
                                                                                </Block>
                                                                            )}
                                                                            {item?.statusQuantities?.delivered > 0 && (
                                                                                <Block
                                                                                    justifyContent="initial"
                                                                                    align="center"
                                                                                    top="8px"
                                                                                >
                                                                                    <StatusColor color="green"></StatusColor>
                                                                                    <SmallText>
                                                                                        Delivered:{' '}
                                                                                        <SpanText>
                                                                                            {parseTextCountRender(
                                                                                                item?.statusQuantities
                                                                                                    ?.delivered,
                                                                                                'unit',
                                                                                            )}
                                                                                        </SpanText>
                                                                                    </SmallText>
                                                                                </Block>
                                                                            )}
                                                                            {(item?.statusQuantities?.failedDelivery >
                                                                                0 ||
                                                                                item?.statusQuantities?.invalidOrder >
                                                                                    0 ||
                                                                                item?.statusQuantities
                                                                                    ?.refundInitiated > 0 ||
                                                                                item?.statusQuantities?.refundDone >
                                                                                    0 ||
                                                                                item?.statusQuantities?.cancelled >
                                                                                    0) && (
                                                                                <Block
                                                                                    justifyContent="initial"
                                                                                    align="center"
                                                                                    top="8px"
                                                                                >
                                                                                    <StatusColor color="red"></StatusColor>
                                                                                    <SmallText>
                                                                                        Cancelled:{' '}
                                                                                        <SpanText>
                                                                                            {parseTextCountRender(
                                                                                                item?.statusQuantities
                                                                                                    ?.failedDelivery +
                                                                                                    item
                                                                                                        ?.statusQuantities
                                                                                                        ?.invalidOrder +
                                                                                                    item
                                                                                                        ?.statusQuantities
                                                                                                        ?.refundInitiated +
                                                                                                    item
                                                                                                        ?.statusQuantities
                                                                                                        ?.refundDone +
                                                                                                    item
                                                                                                        ?.statusQuantities
                                                                                                        ?.cancelled,
                                                                                                'unit',
                                                                                            )}
                                                                                        </SpanText>
                                                                                    </SmallText>
                                                                                </Block>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {orderType === 'orders' && (
                                                                        <>
                                                                            <Block bottom="4px">
                                                                                <ListHeading
                                                                                    weight="400"
                                                                                    maxWidth={'150px'}
                                                                                    fontSize="12px"
                                                                                >
                                                                                    Order No. {item?.orderNumber}
                                                                                </ListHeading>
                                                                                <OrderPrice>
                                                                                    {formatPrice(item?.totalPrice)}
                                                                                </OrderPrice>
                                                                            </Block>
                                                                            {role === 'ROLE_AGENT' && (
                                                                                <Block
                                                                                    borderBottom
                                                                                    bottom="8px"
                                                                                    bottomPadding="8px"
                                                                                >
                                                                                    <ListSubHeading
                                                                                        margin="0"
                                                                                        weight="400"
                                                                                        fontSize="12px"
                                                                                        color={colors.lightDark}
                                                                                    >
                                                                                        Customer:
                                                                                        <CustomerName
                                                                                            weight="500"
                                                                                            fontSize="12px"
                                                                                        >
                                                                                            {item?.userName ===
                                                                                            item?.placedFor
                                                                                                ? 'YOU'
                                                                                                : item?.placedFor.toUpperCase()}
                                                                                        </CustomerName>
                                                                                    </ListSubHeading>
                                                                                    <OrderQuantity>
                                                                                        {parseTextCountRender(
                                                                                            item?.noOfItems,
                                                                                            'Item',
                                                                                        )}
                                                                                    </OrderQuantity>
                                                                                </Block>
                                                                            )}
                                                                            <Block>
                                                                                <OrderDate>
                                                                                    {item?.createdDate &&
                                                                                        moment(
                                                                                            item?.createdDate,
                                                                                        ).format('LL')}
                                                                                </OrderDate>
                                                                                {role !== 'ROLE_AGENT' && (
                                                                                    <OrderQuantity>
                                                                                        {parseTextCountRender(
                                                                                            item?.noOfItems,
                                                                                            'Item',
                                                                                        )}
                                                                                    </OrderQuantity>
                                                                                )}
                                                                            </Block>
                                                                        </>
                                                                    )}
                                                                </ModifiedListItem>
                                                            ))}
                                                    </InfiniteScrollList>
                                                </List>
                                            )}
                                        </Fragment>
                                    )}
                                </ScreenContainer>
                            </Fragment>
                            <OptionsPopupDialog
                                open={sortOptionsOpen}
                                title={'Sort by'}
                                headerPadding="16px 16px 24px"
                                contentPadding="0 16px"
                                itemPadding="10px 0"
                                cancel={() => {
                                    setSortType('Most Recent');
                                    setSortOptionsOpen(!sortOptionsOpen);
                                }}
                                items={[
                                    {
                                        Icon: MostRecent,
                                        title: 'Most Recent',
                                        click: () => {
                                            setSortOptionsOpen(!sortOptionsOpen);
                                            sortType !== 'Most Recent' && setSortType('Most Recent');
                                        },
                                    },
                                    {
                                        Icon: Oldest,
                                        title: 'Oldest',
                                        click: () => {
                                            setSortOptionsOpen(!sortOptionsOpen);
                                            sortType !== 'Oldest' && setSortType('Oldest');
                                        },
                                    },
                                ]}
                            />
                            <OptionsPopupDialog
                                open={openFilterOptions}
                                title={'Filter by'}
                                headerPadding="16px 16px 24px"
                                contentPadding="0 16px"
                                itemPadding="10px 0"
                                cancel={() => {
                                    setFilterType('All time');
                                    setOpenFilterOptions(!openFilterOptions);
                                }}
                                items={[
                                    {
                                        Icon: CalendarIcon,
                                        title: 'All time',
                                        click: () => {
                                            setOpenFilterOptions(!openFilterOptions);
                                            filterType !== 'All time' && setFilterType('All time');
                                        },
                                    },
                                    {
                                        Icon: CalendarIcon,
                                        title: 'Today',
                                        click: () => {
                                            setOpenFilterOptions(!openFilterOptions);
                                            filterType !== 'Today' && setFilterType('Today');
                                        },
                                    },
                                    {
                                        Icon: CalendarIcon,
                                        title: 'This Week',
                                        click: () => {
                                            setOpenFilterOptions(!openFilterOptions);
                                            filterType !== 'This Week' && setFilterType('This Week');
                                        },
                                    },
                                    {
                                        Icon: CalendarIcon,
                                        title: 'This Month',
                                        click: () => {
                                            setOpenFilterOptions(!openFilterOptions);
                                            filterType !== 'This Month' && setFilterType('This Month');
                                        },
                                    },
                                    {
                                        Icon: CalendarIcon,
                                        title: 'This Year',
                                        click: () => {
                                            setOpenFilterOptions(!openFilterOptions);
                                            filterType !== 'This Year' && setFilterType('This Year');
                                        },
                                    },
                                    {
                                        Icon: CalendarIcon,
                                        title: 'Specific Day',
                                        click: () => {
                                            setOpenFilterOptions(!openFilterOptions);
                                            filterType !== 'Specific Day' && setFilterType('Specific Day');
                                            setOpenDateRange(!openDateRange);
                                        },
                                    },
                                    {
                                        Icon: CalendarIcon,
                                        title: 'Date Range',
                                        click: () => {
                                            setOpenFilterOptions(!openFilterOptions);
                                            filterType !== 'Date Range' && setFilterType('Date Range');
                                            setOpenDateRange(!openDateRange);
                                        },
                                    },
                                ]}
                            />

                            <DateRangePopup
                                open={openDateRange}
                                setOpen={setOpenDateRange}
                                startDate={startDate}
                                filterParam={filterType}
                                setSelectedFilter={setFilterType}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                                specificDate={filterType === 'Specific Day' ? true : false}
                            />
                        </Fragment>
                    )}
                    {openDeliverLocationPopup && (
                        <DeliveryLocationPopup
                            open={openDeliverLocationPopup}
                            getLocation={getLocation}
                            cancel={() => {
                                setOpenDeliverLocationPopup(!openDeliverLocationPopup);
                            }}
                        />
                    )}
                </DesktopBackgroundLayout>
            </Fragment>
        );
};

export default OrderHistoryList;
