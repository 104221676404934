import React, { Fragment, useState, useEffect } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as CalendarIcon } from '../../../../assets/calendar-new.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/link.svg';
import { ReactComponent as QrIcon } from '../../../../assets/qr-code.svg';
import {
    TopHeader,
    MerchantsBadge,
    MerchantShareLink,
    UserAvatar,
    OptionsPopupDialog,
    CheckPopupDialog,
    RippleLink,
    Loader,
    QrShare,
    DateRangePopup,
    CopyReferralBadge,
    ButtonWithShareIcon,
    SharePopup,
} from '../../../../components';
import { currentHost } from '../../../../config/API';
import {
    List,
    ListItem,
    ListHeading,
    ListSubHeading,
    ListLeftBlock,
    EmptyList,
    ListHighlight,
} from '../../../../containers/ListContainer';
import { Message, SubTitle, SmallLightText } from '../../../../containers/MessageContainer';
import {
    FlexCenteredBlock,
    ScreenContainer,
    ViewContainer,
    EmptyListContainer,
    FilterAndSortSection,
    SortFilterCell,
} from '../../../../containers/ScreenContainer';
import { colors } from '../../../../styles';
import { MODULE_INTERACTION } from '../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../utils/mix-panel/mixPanel';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';

import { ReactComponent as AddIcon } from './assets/add.svg';
import { ReactComponent as Oldest } from './assets/anti-clockwise.svg';
import { ReactComponent as DownIcon } from './assets/chevron-down.svg';
import { ReactComponent as MostRecent } from './assets/clockwise.svg';
import merchantsIcon from './assets/merchants.png';

const MerchantsTitle = styled(SubTitle)`
    margin: ${({ margin }) => margin || '0 0 16px 0'};
    text-align: left;
    font-weight: 700;
`;

const MerchantListBlock = styled.div`
    margin-top: 8px;
`;

const MerchantsListNav = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
`;

const EmptyListWrapper = styled(FlexCenteredBlock)`
    border-radius: 8px;
    border: 1px solid ${colors.border.default};
    padding: 32px 16px 20px;
    background-color: #edf2f7;
`;

const MerchantsPhone = styled.span`
    display: block;
    ${SmallLightText};
    text-align: right;
    font-weight: 500;
    line-height: 12px;
    color: ${({ color }) => color || `${colors.themeTextColor3}`};
    margin-bottom: 8px;
`;

const StyledAddIcon = styled(AddIcon)`
    position: absolute;
    right: 16px;
`;
const MerchantsIcon = styled.img``;

const ModifiedListHeading = styled(ListHeading)`
    margin: 0px;
`;

const MerchantsDashboard = () => {
    const isLoading = useSelector((state) => state.applications.merchants.isLoading);
    const merchants = useSelector((state) => state.applications.merchants.merchantsList);
    const merchantsCount = useSelector((state) => state.applications.merchants.merchantsListCount);
    const agentCodeToShare = useSelector((state) => state.user.agentCodeToShare);
    const avatar = useSelector((state) => state.user.avatar);
    const firstName = useSelector((state) => state.user.firstName);
    const lastName = useSelector((state) => state.user.lastName);
    const [selectedFilter, setSelectedFilter] = useState('All time');
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [merchantsList, setMerchantsList] = useState([...merchants]);
    const [filteredMerchantsCount, setFilteredMerchantsCount] = useState(merchantsCount);
    const [openShareLinks, setOpenShareLinks] = useState(false);
    const [openDateRange, setOpenDateRange] = useState(false);
    const [openQrShare, setOpenQrShare] = useState(false);
    const [openOptions, setOpenOptions] = useState(false);
    const [openDateFilters, setOpenDateFilters] = useState(false);
    const [openShareAppLinks, setOpenShareAppLinks] = useState(false);
    const url = `${currentHost}/launch?referralCode=${agentCodeToShare}`;
    const marketingMessage = `Hello, \n\nJoin in on spaces where you can easily manage and grow your business to new levels using the link below\n${url}\n\nUse my referral code\n${agentCodeToShare}`;
    const [sortType, setSortType] = useState('Most Recent');
    const [sortOptionsOpen, setSortOptionsOpen] = useState(false);

    const setListAndCount = (list) => {
        setMerchantsList(list);
        setFilteredMerchantsCount(list.length);
    };

    useEffect(() => {
        mixPanel.track(MODULE_INTERACTION, {
            'Icon Name': 'My Merchants',
            Time: new Date().toLocaleString(),
        });
    }, []);

    useEffect(() => {
        if (merchants.length !== 0) {
            if (selectedFilter === 'All time') {
                setListAndCount(merchants);
            } else if (selectedFilter === 'Today') {
                const list = [...merchants].filter((merchant) => moment(merchant.createdAt).isSame(new Date(), 'day'));
                setListAndCount(list);
            } else if (selectedFilter === 'This Week') {
                const list = [...merchants].filter((merchant) =>
                    moment(merchant.createdAt).isBetween(moment().weekday(0), new Date(), 'days', '[]'),
                );
                setListAndCount(list);
            } else if (selectedFilter === 'This Month') {
                const list = [...merchants].filter((merchant) =>
                    moment(merchant.createdAt).isSame(new Date(), 'month'),
                );
                setListAndCount(list);
            } else if (selectedFilter === 'This Year') {
                const list = [...merchants].filter((merchant) => moment(merchant.createdAt).isSame(new Date(), 'year'));
                setListAndCount(list);
            } else if (selectedFilter === 'Date Range') {
                const list = [...merchants].filter((merchant) =>
                    moment(startDate).isSame(endDate)
                        ? new Date(merchant.createdAt).getDay() === new Date(startDate).getDay()
                        : moment(merchant.createdAt).isBetween(startDate, endDate),
                );
                setListAndCount(list);
            }
        }
    }, [selectedFilter, merchants, startDate, endDate]);

    const filterOptions = [
        {
            Icon: CalendarIcon,
            title: 'All time',
            click: () => {
                selectedFilter !== 'All time' && setSelectedFilter('All time');
                setOpenDateFilters(!openDateFilters);
            },
        },
        {
            Icon: CalendarIcon,
            title: 'Today',
            click: () => {
                selectedFilter !== 'Today' && setSelectedFilter('Today');
                setOpenDateFilters(!openDateFilters);
            },
        },
        {
            Icon: CalendarIcon,
            title: 'This Week',
            click: () => {
                selectedFilter !== 'This Week' && setSelectedFilter('This Week');
                setOpenDateFilters(!openDateFilters);
            },
        },
        {
            Icon: CalendarIcon,
            title: 'This Month',
            click: () => {
                selectedFilter !== 'This Month' && setSelectedFilter('This Month');
                setOpenDateFilters(!openDateFilters);
            },
        },
        {
            Icon: CalendarIcon,
            title: 'This Year',
            click: () => {
                selectedFilter !== 'This Year' && setSelectedFilter('This Year');
                setOpenDateFilters(!openDateFilters);
            },
        },
        {
            Icon: CalendarIcon,
            title: 'Date Range',
            click: () => {
                setOpenDateFilters(!openDateFilters);
                setOpenDateRange(!openDateRange);
            },
        },
    ];

    useEffect(() => {
        if (merchants.length !== 0) {
            if (sortType === 'Most Recent') {
                setMerchantsList([...merchants].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
                setFilteredMerchantsCount(merchants.length);
            } else if (sortType === 'Oldest') {
                setMerchantsList([...merchants].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)));
                setFilteredMerchantsCount(merchants.length);
            }
        }
    }, [sortType, merchants]);

    if (isLoading) {
        return <Loader />;
    } else {
        return (
            <Fragment>
                <DesktopBackgroundLayout>
                    {merchants && merchants.length === 0 ? (
                        <Fragment>
                            {!openQrShare && (
                                <TopHeader
                                    title={'Merchants'}
                                    size="14px"
                                    weight="400"
                                    noBorderBottom
                                    color="#071827"
                                    backLink="/more"
                                />
                            )}
                            <ScreenContainer>
                                <FlexCenteredBlock top={'0'}>
                                    <MerchantsBadge
                                        title={'Total Merchants'}
                                        merchantsCount={merchantsCount}
                                        top="25px"
                                    />
                                    <EmptyList>
                                        <MerchantsTitle color="#253246">Recently Added</MerchantsTitle>
                                        <EmptyListWrapper>
                                            <MerchantsIcon src={merchantsIcon} />
                                            <MerchantsTitle color="#253246" margin="16px 0 0">
                                                No Merchants
                                            </MerchantsTitle>
                                            <Message
                                                bottom={'16px'}
                                                top={'4px'}
                                                align={'center'}
                                                weight="400"
                                                color="#718596"
                                                lineHeight="21px"
                                            >
                                                You’ve not added any merchant. To add a merchant, send your referral
                                                code to the person.
                                            </Message>
                                            <CopyReferralBadge referralCode={agentCodeToShare} />
                                            <ButtonWithShareIcon
                                                backgroundColor="#EDF2F7"
                                                size="16px"
                                                title="Share Referral Code"
                                                color="#227EFF"
                                                top="24px"
                                                onClick={() => setOpenShareLinks(!openShareLinks)}
                                            >
                                                Share
                                            </ButtonWithShareIcon>
                                        </EmptyListWrapper>
                                    </EmptyList>
                                </FlexCenteredBlock>
                            </ScreenContainer>
                        </Fragment>
                    ) : (
                        <Fragment>
                            {!openQrShare && (
                                <TopHeader
                                    title={'Merchants'}
                                    right={'48px'}
                                    size="14px"
                                    weight="400"
                                    noBorderBottom
                                    color="#071827"
                                    backLink="/more"
                                >
                                    <StyledAddIcon onClick={() => setOpenShareLinks(!openShareLinks)} />
                                </TopHeader>
                            )}
                            <ViewContainer style={{ padding: '0 16px' }} top={'72px'}>
                                {merchantsList && merchantsList.length === 0 ? (
                                    <Fragment>
                                        <FilterAndSortSection
                                            top="16px"
                                            bottom="16px"
                                            borderTop="1px solid #EDF2F7"
                                            borderBottom="1px solid #EDF2F7"
                                            padding="0 17px 0 30px"
                                            left="-16px"
                                            right="-16px"
                                        >
                                            <SortFilterCell
                                                onClick={() => setOpenDateFilters(!openDateFilters)}
                                                padding="11px 0"
                                                color="#071827"
                                                weight="500"
                                                height="40px"
                                            >
                                                {selectedFilter}
                                                <DownIcon />
                                            </SortFilterCell>
                                            <SortFilterCell
                                                onClick={() => setSortOptionsOpen(!sortOptionsOpen)}
                                                padding="11px 0"
                                                color="#071827"
                                                weight="500"
                                                height="40px"
                                            >
                                                {sortType}
                                                <DownIcon />
                                            </SortFilterCell>
                                        </FilterAndSortSection>
                                        <MerchantsBadge
                                            title={'Total Merchants'}
                                            merchantsCount={filteredMerchantsCount}
                                            bottom={'24px'}
                                            top="0"
                                        />
                                        <MerchantsListNav>
                                            <MerchantsTitle color="#071827">My Merchants</MerchantsTitle>
                                        </MerchantsListNav>
                                        <EmptyListContainer>No merchants found</EmptyListContainer>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <FilterAndSortSection
                                            top="0"
                                            bottom="16px"
                                            borderTop="1px solid #EDF2F7"
                                            borderBottom="1px solid #EDF2F7"
                                            padding="0 17px 0 30px"
                                            left="-16px"
                                            right="-16px"
                                        >
                                            <SortFilterCell
                                                onClick={() => setOpenDateFilters(!openDateFilters)}
                                                padding="11px 0"
                                                color="#071827"
                                                weight="500"
                                                height="40px"
                                            >
                                                {selectedFilter}
                                                <DownIcon />
                                            </SortFilterCell>
                                            <SortFilterCell
                                                onClick={() => setSortOptionsOpen(!sortOptionsOpen)}
                                                padding="11px 0"
                                                color="#071827"
                                                weight="500"
                                                height="40px"
                                            >
                                                {sortType}
                                                <DownIcon />
                                            </SortFilterCell>
                                        </FilterAndSortSection>
                                        <MerchantsBadge
                                            title={'Total Merchants'}
                                            merchantsCount={filteredMerchantsCount}
                                            bottom={'24px'}
                                            top="0"
                                        />
                                        <MerchantListBlock>
                                            <MerchantsListNav>
                                                <MerchantsTitle color="#071827">My Merchants</MerchantsTitle>
                                            </MerchantsListNav>
                                            <List fullScreen>
                                                {merchantsList &&
                                                    merchantsList.map((merchant, index) => (
                                                        <RippleLink
                                                            key={index}
                                                            to={{
                                                                pathname: '/actions/merchant_details',
                                                                state: merchant,
                                                            }}
                                                        >
                                                            <ListItem
                                                                bottom={'8px'}
                                                                borderBottom={false}
                                                                borderTop={false}
                                                            >
                                                                <UserAvatar
                                                                    avatar={merchant.avatar}
                                                                    width={'32px'}
                                                                    height={'32px'}
                                                                />
                                                                <ListLeftBlock>
                                                                    <ModifiedListHeading
                                                                        color="#071827"
                                                                        maxWidth={'180px'}
                                                                    >{`${merchant.firstName || ''} ${
                                                                        merchant.lastName || ''
                                                                    }`}</ModifiedListHeading>
                                                                    {merchant.businessProfile && (
                                                                        <ListSubHeading
                                                                            fontWeight="400"
                                                                            color="#718596"
                                                                            fontSize="12px"
                                                                            maxWidth={'180px'}
                                                                        >
                                                                            {merchant.businessProfile.businessName}
                                                                        </ListSubHeading>
                                                                    )}
                                                                </ListLeftBlock>
                                                                <ListHighlight>
                                                                    <MerchantsPhone color="#718596">
                                                                        {merchant?.msisdn?.replace(/^.{1,4}/, 0)}
                                                                    </MerchantsPhone>
                                                                    <ListSubHeading
                                                                        fontWeight="400"
                                                                        color="#718596"
                                                                        fontSize="12px"
                                                                    >
                                                                        Created:
                                                                        {new Intl.DateTimeFormat('en-GB').format(
                                                                            new Date(merchant.createdAt),
                                                                        )}
                                                                    </ListSubHeading>
                                                                </ListHighlight>
                                                            </ListItem>
                                                        </RippleLink>
                                                    ))}
                                            </List>
                                        </MerchantListBlock>
                                    </Fragment>
                                )}
                            </ViewContainer>
                        </Fragment>
                    )}
                    {openOptions && (
                        <OptionsPopupDialog
                            open={openOptions}
                            title={'Sharing options'}
                            headerPadding="16px 0 24px"
                            popupPadding="16px"
                            cancel={() => setOpenOptions(!openOptions)}
                            items={[
                                {
                                    Icon: QrIcon,
                                    title: 'QR Code',
                                    more: false,
                                    click: () => {
                                        setOpenOptions(!openOptions);
                                        setOpenQrShare(!openQrShare);
                                    },
                                },
                                {
                                    Icon: LinkIcon,
                                    title: 'Share referral link',
                                    more: false,
                                    click: () => {
                                        setOpenOptions(!openOptions);
                                        setOpenShareAppLinks(!openShareAppLinks);
                                    },
                                },
                            ]}
                        />
                    )}
                    {openQrShare && (
                        <QrShare
                            open={openQrShare}
                            cancel={() => setOpenQrShare(!openQrShare)}
                            referralCode={agentCodeToShare}
                            avatar={avatar}
                            fullName={`${firstName} ${lastName}`}
                            backLink={'/actions/merchants'}
                        />
                    )}
                    {openDateFilters && (
                        <CheckPopupDialog
                            open={openDateFilters}
                            title={'Filter by'}
                            headerPadding="16px 0 24px"
                            popupPadding="16px"
                            contentPadding="0 16px"
                            labelItemPadding="16px 0"
                            noBorderBottom={true}
                            width="100%"
                            color="#212C3D"
                            size="14px"
                            cancel={() => {
                                setSelectedFilter('All time');
                                setOpenDateFilters(!openDateFilters);
                            }}
                            items={filterOptions}
                        />
                    )}
                    {openDateRange && (
                        <DateRangePopup
                            open={openDateRange}
                            setOpen={setOpenDateRange}
                            startDate={startDate}
                            filterParam={'Date Range'}
                            setSelectedFilter={setSelectedFilter}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                        />
                    )}
                    {openShareLinks && (
                        <MerchantShareLink
                            open={openShareLinks}
                            setOpen={setOpenShareLinks}
                            setOpenOptions={() => {
                                setOpenShareLinks(!openShareLinks);
                                setOpenOptions(!openOptions);
                            }}
                            referralCode={agentCodeToShare}
                            url={url}
                            marketingMessage={marketingMessage}
                        />
                    )}
                    {openShareAppLinks && (
                        <SharePopup
                            url={url}
                            marketingMessage={marketingMessage}
                            open={openShareAppLinks}
                            setOpen={setOpenShareAppLinks}
                        />
                    )}
                    {sortOptionsOpen && (
                        <OptionsPopupDialog
                            open={sortOptionsOpen}
                            title={'Sort by'}
                            headerPadding="16px 0 24px"
                            popupPadding="16px"
                            color="#212C3D"
                            size="14px"
                            cancel={() => {
                                setSortType('Most Recent');
                                setSortOptionsOpen(!sortOptionsOpen);
                            }}
                            items={[
                                {
                                    Icon: MostRecent,
                                    title: 'Most Recent',
                                    click: () => {
                                        setSortOptionsOpen(!sortOptionsOpen);
                                        sortType !== 'Most Recent' && setSortType('Most Recent');
                                    },
                                },
                                {
                                    Icon: Oldest,
                                    title: 'Oldest',
                                    click: () => {
                                        setSortOptionsOpen(!sortOptionsOpen);
                                        sortType !== 'Oldest' && setSortType('Oldest');
                                    },
                                },
                            ]}
                        />
                    )}
                </DesktopBackgroundLayout>
            </Fragment>
        );
    }
};

export default MerchantsDashboard;
