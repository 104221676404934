import React, { Fragment, useEffect, useState } from 'react';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { useHistory } from 'react-router';
import { matchPath } from 'react-router-dom';
import styled from 'styled-components';
import useRouter from 'use-react-router';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { RippleButton, Loader, ConfirmPopupDialog, TopHeader } from '../../../../../components';
import { ScreenContainer } from '../../../../../containers/ScreenContainer';
import {
    deleteStorefrontProduct,
    getProductByProductId,
} from '../../../../../redux/ducks/applications/store-front/actions/store';
import { colors } from '../../../../../styles';
import { formatCurrency } from '../../../../../utils/currency/parseBalance';
import { toTitleCase } from '../../../../../utils/toTitleCase';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import back from '../../assets/Card_1.jpg';

import { ReactComponent as BackArrow } from './assets/arrow.svg';
import DelIcon from './assets/Del.svg';
import StorefrontUpdateStock from './updateStock';

const StyleOverrideCarousel = styled(Carousel)`
    .carousel .slide img {
        width: 184px;
        height: 207px;
    }

    .carousel .control-dots {
        position: absolute;
        bottom: auto;
        margin-top: 50px;
        top: 230px;
    }

    .carousel .control-dots .dot {
        transition: opacity 0.25s ease-in;
        opacity: 1;
        box-shadow: none;
        cursor: pointer;
        margin: 0 4px;
        width: 6px;
        height: 6px;
        background: #d6e6f0;
    }

    .carousel .control-dots .dot.selected {
        box-shadow: none;
        width: 18px;
        height: 6px;
        background: #fe8f49;
        border-radius: 4px;
    }
`;

const SliderHolder = styled.div`
    position: relative;
    padding: ${({ padding }) => padding || '52px 16px 60px'};
    background: ${({ background }) => background || '#F9F9FA'};
    overflow: hidden;
`;

const CarouselLayout = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 90px;
`;

const SliderImageWrapper = styled.div`
    position: relative;
    justify-content: ${({ content }) => content || null};
    right: ${({ right }) => right || null};
    height: ${({ height }) => height || null};
    text-align: ${({ textAlign }) => textAlign || null};
`;

const SliderImage = styled.img`
    display: flex;
    align-items: center;
    position: relative;
`;

const TextDiv = styled.div`
    overflow-wrap: break-word;
    margin-bottom: 16px;
    padding: 0 16px;
`;

const ContentBox = styled.div`
    background: ${colors.white};
    padding: 16px;
    display: ${({ display }) => display || null};
    justify-content: ${({ justifyContent }) => justifyContent || null};
    margin-bottom: ${({ bottom }) => bottom || null};
    position: ${({ position }) => position || null};
`;

const InnerContent = styled.div`
    display: ${({ display }) => display || null};
    justify-content: ${({ justifyContent }) => justifyContent || null};
    align-items: ${({ alignItems }) => alignItems || null};
`;

const Title = styled.div`
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || null};
    font-weight: ${({ weight }) => weight || null};
    font-size: ${({ size }) => size || null};
    line-height: ${({ lineHeight }) => lineHeight || null};
    color: ${({ color }) => color || null};
    text-align: ${({ align }) => align || null};
    position: ${({ position }) => position || null};
    bottom: ${({ bottom }) => bottom || null};
    right: ${({ right }) => right || null};
    letter-spacing: ${({ spacing }) => spacing || null};
`;

const ButtonWrapper = styled.div`
    position: absolute;
    right: 16px;
    top: -20px;
`;

const FlexButtonWrapper = styled.div`
    display: flex;
    padding: 0 16px;
    margin-top: 79px;
`;

const Back = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: absolute;
    left: 16px;
    top: 30px;
    background: ${colors.white};
`;

const StoreFrontProductDetails = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const product = useSelector((state) => state?.applications?.storeFront?.storeFrontSingleproduct);
    const loading = useSelector((state) => state.applications.storeFront.loadingProducts);
    const [updateStockPopUp, setUpdateStockPopUp] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const { location } = useRouter();
    const { pathname } = location;
    const pattern = `(.*)?${'/actions/storefront/product/:id'}`;
    const match = matchPath(pathname, { path: pattern }) || {};

    useEffect(() => {
        dispatch(getProductByProductId(match.params.id));
    }, [dispatch, match.params.id]);

    const carouselItem = [
        {
            id: 1,
            image: (
                <SliderImage
                    src={Object.keys(product).length > 0 && product?.images[product?.images?.length - 3]?.imageUrl}
                    alt="product picture 1"
                />
            ),
            content: 'flex-end',
        },
        {
            id: 2,
            image: (
                <SliderImage
                    src={Object.keys(product).length > 0 && product?.images[product?.images?.length - 2]?.imageUrl}
                    alt="product picture 2"
                />
            ),
            content: 'flex-end',
        },
        {
            id: 3,
            image: (
                <SliderImage
                    src={Object.keys(product).length > 0 && product?.images[product?.images?.length - 1]?.imageUrl}
                    alt="product picture 3"
                />
            ),
            content: 'center',
        },
    ];

    if (loading) return <Loader />;

    return (
        <Fragment>
            {updateStockPopUp && (
                <StorefrontUpdateStock
                    open={updateStockPopUp}
                    setOpen={() => setUpdateStockPopUp(!updateStockPopUp)}
                    product={product}
                />
            )}
            {openConfirmDelete && (
                <ConfirmPopupDialog
                    open={openConfirmDelete}
                    confirmationText={'Are you sure you want to delete this product?'}
                    title={'Delete Product'}
                    answers={[
                        {
                            variant: 'Cancel',
                            action: () => setOpenConfirmDelete(!openConfirmDelete),
                        },
                        {
                            Icon: DelIcon,
                            variant: 'Delete',
                            action: () => {
                                dispatch(deleteStorefrontProduct(product.id));
                                setOpenConfirmDelete(!openConfirmDelete);
                            },
                        },
                    ]}
                />
            )}
            <DesktopBackgroundLayout bgColor={colors.lightestGray}>
                <TopHeader
                    title={'Product Details'}
                    noBorderBottom
                    backgroundImage={`url(${back})`}
                    noArrow
                    height={'100px'}
                    size={'14px'}
                    weight={'400'}
                    color={colors.white}
                >
                    <Back onClick={() => history.push('/my-shop/storefront')}>
                        <BackArrow />
                    </Back>
                    <Title
                        size={'10px'}
                        weight={'400'}
                        color={colors.white}
                        position={'absolute'}
                        bottom={'5px'}
                        right={'16px'}
                        spacing={'0.01em'}
                    >
                        Last update: {`${moment(new Date(product.updatedAt)).format('Do MMMM')}`}
                    </Title>
                </TopHeader>
                <ScreenContainer color={colors.lightestGray} padding={'0'} paddingBottom={'19px'}>
                    <CarouselLayout>
                        <StyleOverrideCarousel
                            showThumbs={false}
                            showArrows={false}
                            showStatus={false}
                            swipeable
                            autoPlay
                            infiniteLoop
                        >
                            {carouselItem?.map((carousel) => (
                                <SliderHolder key={carousel?.id}>
                                    <SliderImageWrapper content={carousel?.content}>
                                        {carousel?.image}
                                    </SliderImageWrapper>
                                </SliderHolder>
                            ))}
                        </StyleOverrideCarousel>
                    </CarouselLayout>
                    <TextDiv>
                        <Title
                            size={'16px'}
                            weight={'700'}
                            lineHeight={'24px'}
                            color={colors.themeTextColor1}
                            top={'44px'}
                            bottom={'10px'}
                        >
                            {Object.keys(product).length > 0 && toTitleCase(product?.name)}
                        </Title>
                        <Title size={'12px'} weight={'400'} lineHeight={'18px'} color={'#5C6F7F'}>
                            {product?.description}
                        </Title>
                    </TextDiv>
                    <ContentBox bottom={'8px'}>
                        <InnerContent display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Title size={'10px'} weight={'700'} color={'#5C6F7F'} lineHeight={'16px'}>
                                COST PRICE
                            </Title>
                            <Title size={'10px'} weight={'700'} color={'#5C6F7F'} lineHeight={'16px'}>
                                STOCK QUANTITY THRESHOLD
                            </Title>
                        </InnerContent>
                        <InnerContent display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Title size={'12px'} weight={'500'} color={colors.themeTxtColor10} lineHeight={'18px'}>
                                {formatCurrency(product?.price)}
                            </Title>
                            <Title size={'12px'} weight={'500'} color={colors.themeTxtColor10} lineHeight={'18px'}>
                                {product?.stockQuantityThreshold}
                            </Title>
                        </InnerContent>
                    </ContentBox>
                    <ContentBox position={'relative'}>
                        <InnerContent>
                            <Title size={'10px'} weight={'700'} color={'#5C6F7F'} lineHeight={'16px'}>
                                QUANTITY IN STOCK
                            </Title>
                            <Title size={'12px'} weight={'500'} color={colors.themeTxtColor10} lineHeight={'18px'}>
                                {product?.quantityInStock}
                            </Title>
                        </InnerContent>
                        <ButtonWrapper>
                            <RippleButton
                                size={'14px'}
                                height={'40px'}
                                width={'132px'}
                                border={`1px solid ${colors.border.primary}`}
                                color={colors.border.primary}
                                backgroundColor={colors.white}
                                onClick={() => setUpdateStockPopUp(!updateStockPopUp)}
                            >
                                Update stock
                            </RippleButton>
                        </ButtonWrapper>
                    </ContentBox>

                    <FlexButtonWrapper>
                        <RippleButton
                            size={'14px'}
                            height={'45px'}
                            border={`1px solid #E01A00`}
                            color={'#E01A00'}
                            backgroundColor={colors.white}
                            right={'9px'}
                            onClick={() => setOpenConfirmDelete(!openConfirmDelete)}
                        >
                            Remove
                        </RippleButton>
                        <RippleButton
                            size={'14px'}
                            height={'45px'}
                            onClick={() => history.push(`/actions/storefront/edit_product/${product.id}`)}
                        >
                            Edit Product
                        </RippleButton>
                    </FlexButtonWrapper>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default StoreFrontProductDetails;
