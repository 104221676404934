import styled from 'styled-components';

import { Badge } from '../../../../containers/BadgeContainer';
import { colors } from '../../../../styles';

export const PageSubSectionsTopLeftTitle = styled.h4`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-left: 10px;
    color: ${colors.sectionTitle};
`;

export const MoqWrapper = styled.div`
    display: grid;
    gap: 8px;
`;

export const ImageWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    padding: 8px 16px;
    background: ${colors.white};
    position: relative;
`;

export const RecommendedProductWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

export const MoqContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const MoqHeaderText = styled.p`
    margin: 0;
    color: ${colors.subGrey};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    width: 75%;
`;

export const MoqBodyText = styled.p`
    color: ${colors.boldDark};
    font-size: 12px;
    font-weight: ${({ weight }) => weight || '400'};
    margin: 0;
    width: 75%;
`;

export const ModifiedBadge = styled(Badge)`
    border-top: ${({ borderTop }) => borderTop || '1px'} solid ${colors.badge};
    margin-bottom: ${({ bottom }) => bottom || '14px'};
    border-radius: 0;
`;

export const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    margin-left: 15px;
`;

export const ViewMore = styled.p`
    margin: 0;
    color: ${colors.deepBlue};
    font-size: 12px;
    font-weight: 400;
    padding-top: 5px;
`;

export const SectionContainer = styled.div`
    height: 26px;
    display: flex;
    align-items: center;
    background: ${colors.lightestGray};
`;

export const BuyButtons = styled.div`
    display: flex;
    width: 90%;
`;

export const PriceText = styled.p`
    margin: 8px 0 0;
    font-size: 20px;
    font-weight: 700;
    color: ${colors.boldDark};
    line-height: 30px;
`;

export const TopIconWrapper = styled.div`
    display: flex;
    gap: 13px;
    position: absolute;
    right: 15px;
    align-items: center;
    justify-content: center;
`;

export const CartCount = styled.div`
    width: 16px;
    height: 16px;
    background-color: ${colors.cartCount};
    border-radius: 26px;
    position: absolute;
    margin-top: -32px;
    margin-left: 14px;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    color: ${colors.white};
    padding-top: 2px;
`;

export const BlurBackgroundEducate = styled.div`
    height: 100%;
    position: ${({ position }) => position || 'fixed'};
    z-index: ${({ zIndex }) => zIndex || '200'};
    width: 100%;
    max-width: inherit;
    background-color: rgba(0, 0, 0, 0.84);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    -webkit-animation: fadeIn 0.2s ease-in-out both;
    animation: fadeIn 0.2s ease-in-out both;
    animation-delay: 0s;
    transition: all 0.2s ease-in;
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    &.fadeOut {
        -webkit-animation: fadeOut 0.3s ease-in-out both;
        animation: fadeOut 0.3s ease-in-out both;
        transition: all 0.3s ease-in-out;
        @keyframes fadeOut {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }
    }
`;

export const EducateContainer = styled.div`
    height: 100%;
    position: fixed;
    z-index: ${({ zIndex }) => zIndex || '200'};
    width: 100%;
    max-width: inherit;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const ShareIconWrapper = styled.div`
    display: flex;
    position: absolute;
    bottom: 0;
    right: 30px;
    z-index: ${({ zIndex }) => zIndex || null};
`;

export const IconCircle = styled.div`
    display: ${({ display }) => display || 'flex'};
    justify-content: center;
    align-items: center;
    background: ${colors.white};
    width: 40px;
    height: 40px;
    box-shadow: 0px 2px 0px #edf2f7;
    border-radius: 50%;
    margin-right: ${({ right }) => right || null};
    cursor: pointer;

    &.active {
        pointer-events: none;
        > svg {
            transition: opacity 0.5s;
            animation: activeMove 1s;
            @keyframes activeMove {
                0% {
                    transform: scale(0.8);
                }
                50% {
                    transform: scale(1.1);
                }
                100% {
                    transform: scale(1);
                }
            }
        }
    }
`;

export const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.white};
    width: 40px;
    height: 40px;
    box-shadow: 0px 2px 0px #edf2f7;
    cursor: pointer;
    border-radius: 50%;
    z-index: ${({ zIndex }) => zIndex || null};
    margin-right: ${({ right }) => right || null};

    &.active {
        pointer-events: none;
        > svg {
            transition: opacity 0.5s;
            animation: activeMove 1s;
            @keyframes activeMove {
                0% {
                    transform: scale(0.8);
                }
                50% {
                    transform: scale(1.1);
                }
                100% {
                    transform: scale(1);
                }
            }
        }
    }
`;

export const FlexDivs = styled.div`
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
    align-items: ${({ alignItems }) => alignItems || 'center'};
    margin-bottom: ${({ bottom }) => bottom || null};
`;

export const FlexText = styled.div`
    font-size: ${({ size }) => size || '10px'};
    font-weight: ${({ size }) => size || '500'};
    text-align: ${({ align }) => align || null};
    color: ${({ color }) => color || colors.white};
    margin-bottom: ${({ bottom }) => bottom || null};
    margin-right: ${({ right }) => right || null};
    cursor: ${({ cursor }) => cursor || null};
`;

export const FlexButton = styled.button`
    font-size: ${({ size }) => size || '10px'};
    font-weight: ${({ size }) => size || '500'};
    width: 47px;
    height: 24px;
    color: ${({ color }) => color || colors.white};
    background: ${({ color }) => color || 'linear-gradient(106.74deg, #5271F2 0%, #3D60F3 97.91%)'};
    margin-bottom: ${({ bottom }) => bottom || null};
    margin-right: ${({ right }) => right || null};
    border: ${({ border }) => border || 'none'};
    border-radius: 17px;
`;
