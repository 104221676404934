import React, { Fragment, useState } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { RippleButton } from '../../../../../components';
import { PopUp, PopUpContent } from '../../../../../components/popup/common';
import { Overlay } from '../../../../../containers/OverlayContainer';
import { countriesMap } from '../../../../../data/countries';
import { colors } from '../../../../../styles';
import { formatCurrency } from '../../../../../utils/currency/parseBalance';
import { insertCountryCode } from '../../../../../utils/inputs/formatPhoneNumber';

const Title = styled.div`
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: ${colors.boldDark};
    text-align: left;
`;

const MessageContainer = styled.div`
    min-height: 120px;
    background: rgba(226, 254, 240, 0.5);
    padding: 16px;
    margin-top: 24px;
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .link {
        width: 100%;
        height: 45px;
        background: ${colors.deepBlue};
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        margin-top: 32px;
    }
`;

const SendText = styled.div`
    font-weight: 500;
    line-height: 21px;
    color: ${colors.white};
`;

const MessageTitle = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #5c6f7f;
`;

const MessageEdit = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: ${colors.deepBlue};
`;

const Message = styled.div`
    margin-top: 16px;
    font-size: 12px;
    line-height: 18px;
    color: #5c6f7f;
    text-align: left;
`;

const Seperator = styled.div`
    width: 10px;
`;

const TextAreaContainer = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
`;

const TextArea = styled.textarea`
    height: 116px;
    border: 2px solid ${colors.deepBlue};
    border-radius: 4px;
    background: ${colors.themeColor3};
    margin-top: 8px;
    line-height: 21px;
    color: #5c6f7f;
    padding: 8px 16px;
`;

const TextAreaTitle = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${colors.boldDark};
    text-align: left;
`;

const ReminderPopup = ({ open, close, outstandingAmount, shopName, shopPhone, customerName, customerPhoneNumber }) => {
    const [message, setMessage] = useState(
        `Dear ${customerName}, Your debt of ${formatCurrency(
            outstandingAmount,
        )} is outstanding at ${shopName} (${shopPhone}). Kindly remember to pay the debt.`,
    );
    const [edit, setEdit] = useState(false);

    const country = useSelector((state) => state.user.country);

    const whatsAppRequestPaymentLink = `https://wa.me/${encodeURIComponent(
        insertCountryCode(customerPhoneNumber, countriesMap.get(country).code),
    )}?text=${encodeURIComponent(message)} `;

    return (
        <Fragment>
            <Overlay bgc="rgba(0, 0, 0, 0.4)">
                <PopUp padding="0" open={open} zIndex={'10000'}>
                    <PopUpContent padding="24px 16px" height="299px" radius="8px 8px 0px 0px">
                        <Title>Send Reminder</Title>
                        {edit ? (
                            <TextAreaContainer>
                                <TextAreaTitle>Message</TextAreaTitle>
                                <TextArea value={message} onChange={(e) => setMessage(e.target.value)} />
                            </TextAreaContainer>
                        ) : (
                            <MessageContainer>
                                <Row>
                                    <MessageTitle>MESSAGE</MessageTitle>
                                    <MessageEdit onClick={() => setEdit(!edit)}>EDIT</MessageEdit>
                                </Row>
                                <Message>{message}</Message>
                            </MessageContainer>
                        )}
                        <Row>
                            <RippleButton
                                top={'32px'}
                                backgroundColor={colors.white}
                                border={`1px solid ${colors.deepBlue}`}
                                onClick={close}
                                color={colors.deepBlue}
                            >
                                Cancel
                            </RippleButton>
                            <Seperator />
                            <a className="link" href={whatsAppRequestPaymentLink}>
                                <SendText>Yes Send</SendText>
                            </a>
                        </Row>
                    </PopUpContent>
                </PopUp>
            </Overlay>
        </Fragment>
    );
};

export default ReminderPopup;
