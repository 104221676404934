import { combineReducers } from 'redux';

import agents from './agents';
import banners from './banners';
import billPayments from './bill-payments';
import complaints from './complaints';
import merchantAndAgent from './merchant-and-agent';
import merchants from './merchants';
import merchbuy from './merchbuy';
import merchlist from './merchlist';
import myShop from './my-shop';
import remittance from './remittance';
import shs from './shs';
import storeFront from './store-front';
import transactions from './transactions';

const applications = combineReducers({
    agents,
    merchants,
    merchlist,
    billPayments,
    myShop,
    merchbuy,
    transactions,
    merchantAndAgent,
    shs,
    banners,
    storeFront,
    complaints,
    remittance,
});

export default applications;
