import React from 'react';

import {
    addHeaderToRoutes,
    // addFooterToRoutes,
} from '../../hoc/addHeaderToRoutes';
import {
    MerchbuyIndex,
    MerchbuyProducts,
    // MerchbuyProductCategories,
    // MerchbuyShops,
    MerchbuyPopularProducts,
    MerchbuyRecommendedProducts,
    MerchbuyProductDescription,
    MerchbuyShopDetails,
    CartItems,
    AddedToCartView,
    MerchbuyOrderHistoryList,
    MerchbuyOrderDetails,
    PaymentMethod,
    MerchBuyProducts,
    GroupBuy,
    GroupBuyInvitee,
    CommissionTable,
    MerchbuySearch,
    ShoppingLocation,
    DeliveryAddress,
} from '../../views/actions/merchbuy';
import MerchbuyList from '../../views/actions/merchbuy/product-categories/merchbuy';
import SavedItems from '../../views/actions/merchbuy/saved-items';
import Checkout from '../../views/actions/merchbuy/shopping-cart/checkout';
import { OrdersList } from '../../views/actions/my-shop';

export const merchbuyRoutes = [
    {
        path: '/actions/merchbuy/product/:id/groupBuy',
        exact: true,
        main: () => <GroupBuy />,
        public: false,
    },
    {
        path: '/actions/merchbuy/acceptance/:id',
        exact: true,
        main: () => <GroupBuyInvitee />,
        public: false,
    },
    {
        path: '/actions/merchbuy/products/:name/:id',
        exact: true,
        main: () => <MerchbuyProducts />,
        public: false,
    },
    {
        path: '/actions/merchbuy/checkout',
        exact: true,
        main: () => <Checkout />,
        public: false,
    },
    {
        path: '/actions/merchbuy/to-cart',
        exact: true,
        main: () => <AddedToCartView />,
        public: false,
    },
    {
        path: '/actions/merchbuy/account/commission',
        exact: true,
        main: () => <CommissionTable />,
        public: false,
    },
];

export const merchBuyRoutesWithHeader = [
    ...addHeaderToRoutes(merchbuyRoutes),
    {
        path: '/actions/merchbuy',
        exact: true,
        main: () => <MerchbuyIndex />,
        public: false,
    },
    {
        path: '/actions/merchbuy/search',
        exact: true,
        main: () => <MerchbuySearch />,
        public: false,
    },
    {
        path: '/actions/merchbuy/description',
        exact: true,
        main: () => <MerchbuyProductDescription />,
        public: false,
    },
    {
        path: '/actions/merchbuy/product-categories',
        exact: true,
        main: () => <MerchbuyList />,
        public: false,
    },
    {
        path: '/actions/merchbuy/products/:name/:id',
        exact: true,
        main: () => <MerchbuyProducts />,
        public: false,
    },
    {
        path: '/actions/merchbuy/cart',
        exact: true,
        main: () => <CartItems />,
        public: false,
    },
    {
        path: '/actions/merchbuy/checkout',
        exact: true,
        main: () => <Checkout />,
        public: false,
    },
    {
        path: '/actions/merchbuy/order-history',
        exact: true,
        main: () => <MerchbuyOrderHistoryList />,
        public: false,
    },
    {
        path: '/actions/merchbuy/order-history/details',
        exact: true,
        main: () => <MerchbuyOrderDetails />,
        public: false,
    },
    {
        path: '/actions/merchbuy/saved-items',
        exact: true,
        main: () => <SavedItems />,
        public: false,
    },
    {
        path: '/actions/merchbuy/popular-products',
        exact: true,
        main: () => <MerchbuyPopularProducts />,
        public: false,
    },
    {
        path: '/actions/merchbuy/recommended-products',
        exact: true,
        main: () => <MerchbuyRecommendedProducts />,
        public: false,
    },
    // {
    //     path: '/actions/merchbuy/shops',
    //     exact: true,
    //     main: () => <MerchbuyShops />,
    //     public: false,
    // },
    {
        path: '/actions/merchbuy/shop/:id',
        exact: true,
        main: () => <MerchbuyShopDetails />,
        public: false,
    },
    {
        path: '/actions/merchbuy/shopping-location',
        exact: true,
        main: () => <ShoppingLocation />,
        public: false,
    },
    {
        path: '/actions/merchbuy/account/products',
        exact: true,
        main: () => <MerchBuyProducts />,
        public: false,
    },
    {
        path: '/actions/merchbuy/account/payment-method',
        exact: true,
        main: () => <PaymentMethod />,
        public: false,
    },
    {
        path: '/actions/merchbuy/account/orders',
        exact: true,
        main: () => <OrdersList />,
        public: false,
    },
    {
        path: '/actions/merchbuy/delivery-address',
        exact: true,
        main: () => <DeliveryAddress />,
        public: false,
    },
];
