import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { colors } from '../../../../../styles';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';

import ProfitReport from './profitReport';
import SalesReport from './salesReport';

const Wrapper = styled.div`
    min-height: 100vh;
    background: ${colors.lightestGray};
    padding-bottom: 50px;
`;

const amountFormatter = (num) => {
    const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'B' },
        { value: 1e12, symbol: 'T' },
    ];
    const regex = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let item = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value;
        });
    return item ? (num / item.value).toFixed(1).replace(regex, '$1') + item.symbol : '0';
};

const isDayLastSeven = (date) => {
    const today = moment();
    return today.diff(moment(new Date(date)), 'days') <= 7;
};

const SalesReports = () => {
    const dispatch = useDispatch();
    const sales = useSelector((state) => state.applications.myShop.sales);
    const [openFilterOptions, setOpenFilterOptions] = useState(false);
    const [filterType] = useState('Daily');
    const [options, setOptions] = useState({});
    const [series, setSeries] = useState([]);
    const [profitSeries, setProfitSeries] = useState([]);
    const [todayTotal, setTodayTotal] = useState(formatPrice(0));
    const [difference, setDifference] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        const sevenDaySales = sales.filter((item) => isDayLastSeven(item?.salesInfo?.localSalesDate));
        const oneDay = sevenDaySales.filter(
            (item) => moment().diff(moment(new Date(item?.salesInfo?.localSalesDate)), 'days') === 0,
        );
        const oneDayTotal = oneDay.reduce((acc, curr) => acc + curr?.salesInfo?.totalAmount, 0) || 0;
        setTodayTotal(oneDayTotal);
        const twoDay =
            sevenDaySales.filter(
                (item) => moment().diff(moment(new Date(item?.salesInfo?.localSalesDate)), 'days') === 1,
            ) || 0;
        const twoDayTotal = twoDay.reduce((acc, curr) => acc + curr?.salesInfo?.totalAmount, 0) || 0;
        const threeDay = sevenDaySales.filter(
            (item) => moment().diff(moment(new Date(item?.salesInfo?.localSalesDate)), 'days') === 2,
        );
        const threeDayTotal = threeDay.reduce((acc, curr) => acc + curr?.salesInfo?.totalAmount, 0) || 0;
        const fourDay = sevenDaySales.filter(
            (item) => moment().diff(moment(new Date(item?.salesInfo?.localSalesDate)), 'days') === 3,
        );
        const fourDayTotal = fourDay.reduce((acc, curr) => acc + curr?.salesInfo?.totalAmount, 0) || 0;
        const fiveDay = sevenDaySales.filter(
            (item) => moment().diff(moment(new Date(item?.salesInfo?.localSalesDate)), 'days') === 4,
        );
        const fiveDayTotal = fiveDay.reduce((acc, curr) => acc + curr?.salesInfo?.totalAmount, 0) || 0;
        const sixDay = sevenDaySales.filter(
            (item) => moment().diff(moment(new Date(item?.salesInfo?.localSalesDate)), 'days') === 5,
        );
        const sixDayTotal = sixDay.reduce((acc, curr) => acc + curr?.salesInfo?.totalAmount, 0) || 0;
        const sevenDay = sevenDaySales.filter(
            (item) => moment().diff(moment(new Date(item?.salesInfo?.localSalesDate)), 'days') === 6,
        );
        const sevenDayTotal = sevenDay.reduce((acc, curr) => acc + curr?.salesInfo?.totalAmount, 0) || 0;
        setTotalAmount(
            oneDayTotal + twoDayTotal + threeDayTotal + fourDayTotal + fiveDayTotal + sixDayTotal + sevenDayTotal,
        );
        const amounts = [
            oneDayTotal,
            twoDayTotal,
            threeDayTotal,
            fourDayTotal,
            fiveDayTotal,
            sixDayTotal,
            sevenDayTotal,
        ];

        const oneDayProfitTotal = oneDay.reduce((acc, curr) => acc + curr?.salesInfo?.profit, 0) || 0;
        const twoDayProfitTotal = twoDay.reduce((acc, curr) => acc + curr?.salesInfo?.profit, 0) || 0;
        const threeDayProfitTotal = threeDay.reduce((acc, curr) => acc + curr?.salesInfo?.profit, 0) || 0;
        const fourDayProfitTotal = fourDay.reduce((acc, curr) => acc + curr?.salesInfo?.profit, 0) || 0;
        const fiveDayProfitTotal = fiveDay.reduce((acc, curr) => acc + curr?.salesInfo?.profit, 0) || 0;
        const sixDayProfitTotal = sixDay.reduce((acc, curr) => acc + curr?.salesInfo?.profit, 0) || 0;
        const sevenDayProfitTotal = sevenDay.reduce((acc, curr) => acc + curr?.salesInfo?.profit, 0) || 0;
        const profitAmounts = [
            oneDayProfitTotal,
            twoDayProfitTotal,
            threeDayProfitTotal,
            fourDayProfitTotal,
            fiveDayProfitTotal,
            sixDayProfitTotal,
            sevenDayProfitTotal,
        ];
        const days = {
            one: moment().format('MMM D'),
            two: moment().subtract(1, 'days').format('MMM D'),
            three: moment().subtract(2, 'days').format('MMM D'),
            four: moment().subtract(3, 'days').format('MMM D'),
            five: moment().subtract(4, 'days').format('MMM D'),
            six: moment().subtract(5, 'days').format('MMM D'),
            seven: moment().subtract(6, 'days').format('MMM D'),
        };
        setOptions({
            chart: { id: 'basic-bar' },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: [
                    days['seven'],
                    days['six'],
                    days['five'],
                    days['four'],
                    days['three'],
                    days['two'],
                    days['one'],
                ],
            },
            yaxis: {
                labels: {
                    show: true,
                    formatter: (value) => {
                        return amountFormatter(value);
                    },
                },
            },
        });
        setSeries([
            {
                name: 'Sales',
                data: amounts.reverse(),
            },
        ]);
        setProfitSeries([
            {
                name: 'Profits',
                data: profitAmounts.reverse(),
            },
        ]);
        const differenceBtwLast2Months = amounts && amounts[amounts.length - 1] - (amounts[amounts.length - 2] || 0);
        setDifference(differenceBtwLast2Months);
    }, [dispatch, sales]);

    return (
        <Wrapper>
            <SalesReport
                filterType={filterType}
                setOpenFilterOptions={setOpenFilterOptions}
                openFilterOptions={openFilterOptions}
                todayTotal={todayTotal}
                options={options}
                series={series}
                difference={difference}
                totalAmount={totalAmount}
                sales={sales}
            />
            <ProfitReport
                filterType={filterType}
                setOpenFilterOptions={setOpenFilterOptions}
                openFilterOptions={openFilterOptions}
                todayTotal={todayTotal}
                options={options}
                series={profitSeries}
                difference={difference}
                totalAmount={totalAmount}
                sales={sales}
            />
        </Wrapper>
    );
};

export default SalesReports;
