import React, { Fragment, useState } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TopHeader } from '../../../../components';
import { SearchInput } from '../../../../components/forms/input/search-input/SearchInput';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { colors } from '../../../../styles';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';

import FaqItem from './faqItem';

const InputWrapper = styled.div`
    width: 100%;
    margin-bottom: 24px;
    margin-top: 24px;
    padding: 0 16px;
`;

const GridContainer = styled.div`
    padding: 0 16px;
    display: grid;
    min-height: 100%;
    grid-template-columns: ${({ width }) => `repeat(auto-fill, ${width})`};
    grid-auto-flow: column;
    grid-auto-columns: ${({ width }) => width || null};
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const Text = styled.div`
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    height: 150px;
    overflow: auto;
`;

const IconCover = styled.img`
    margin-right: 9px;
    display: flex;
    align-items: center;
    width: 19px;
    height: 19px;
`;

const OuterCover = styled.div`
    margin-right: 16px;
`;

const Box = styled.div`
    background: ${({ background }) => background || colors.gray4};
    border: 1px solid ${colors.lightGray};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 37px;
    color: ${({ color }) => color || `${colors.themeTextColor11}`};
    font-weight: 500;
    font-size: 14px;

    &.select {
        > div {
            &:first-child {
                > svg {
                    > path {
                        stroke: ${colors.white};
                        fill: ${colors.white};
                    }
                }
            }
        }
    }
`;

export const StyleDiv = styled.div`
    margin-top: 50px;
    > div {
        &:not(:first-of-type) {
            > div {
                &:first-child {
                    border-top: 1px solid ${colors.gray4};
                }
            }
        }
    }
`;

const FAQPage = () => {
    const faqCategories = useSelector((state) => state.help.faqCategories);
    const faqFilter = faqCategories.filter((data) => data.status === 'SHOW_IN_HELPCENTER' && data.name !== 'xxxx');
    const [searchValue, setSearchValue] = useState('');
    const [currentList, setCurrentList] = useState(0);

    const filteredSample = (faq) =>
        searchValue.length === 0
            ? faq
            : faq.filter((data) => data.title.toLowerCase().includes(searchValue.toLowerCase()));

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Frequently Asked Questions'} backLink={'/help'} noBorderBottom />

                <ScreenContainer top={'70px'} padding={'5px 0 0 0'} color={colors.lightestGray} minHeight={'100%'}>
                    <InputWrapper>
                        <SearchInput
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            placeholder="Search using keywords"
                        />
                    </InputWrapper>
                    <GridContainer width="180px">
                        {faqFilter.map((data, index) => (
                            <OuterCover key={index}>
                                <Box
                                    onClick={() => {
                                        setCurrentList(index);
                                    }}
                                    className={currentList === index ? 'select' : ''}
                                    background={
                                        currentList === index
                                            ? 'linear-gradient(106.74deg, #5271F2 0%, #3D60F3 97.91%)'
                                            : colors.gray4
                                    }
                                    color={currentList === index ? colors.white : '#5c6f7f'}
                                >
                                    <IconCover src={data.logoUrl} />
                                    {data.translations[0].name}
                                </Box>
                            </OuterCover>
                        ))}
                    </GridContainer>
                    <FaqItem id={faqFilter[currentList]?.id} filteredSample={filteredSample} />
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default FAQPage;
