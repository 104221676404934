import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as CancelSvg } from '../../../../assets/cancel.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/filter-icon.svg';
import { ReactComponent as SearchSvg } from '../../../../assets/search_icon.svg';

const SearchWrapper = styled.div`
    display: flex;
    height: 40px;
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || null};
`;

const SearchWrapperForInputWithCancel = styled(SearchWrapper)`
    width: 100%;
`;

const Input = styled.input`
    border: none;
    outline-color: transparent;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    background-color: #edf2f7;
    padding: 15px;
    border-radius: 4px;
    width: 100%;
    padding-left: 40px;
`;

const SearchIcon = styled(SearchSvg)`
    position: absolute;
    left: ${({ left }) => left || '27px'};
    margin: ${({ margin }) => margin || '12px 28px 0 0'};
`;

const InputWithCancelSearchIcon = styled(SearchIcon)`
    left: ${({ left }) => left || '0px'};
    margin-left: 30px;
    margin-top: 13px;
`;

const CancelIcon = styled(CancelSvg)`
    position: absolute;
    right: ${({ right }) => right || '-40px'};
    margin-right: 73px;
    margin-top: 13px;
`;

const MainSearchWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${({ margin }) => margin || null};
`;

const SearchCancelText = styled.p`
    font-size: 12px;
    color: ${({ color }) => color || '#000000'};
    cursor: default;
    margin-left: 16px;
`;

const StyledFilterIcon = styled(FilterIcon)`
    right: 10%;
    position: absolute;
    margin-top: 13px;
    cursor: pointer;
`;

const Flex = styled.div`
    display: flex;
`;

export const SearchInput = ({ placeholder, onClick, top, bottom, value, onChange, iconLeft }) => {
    return (
        <SearchWrapper onClick={onClick} top={top} bottom={bottom}>
            <SearchIcon left={iconLeft} />
            <Input value={value} onChange={onChange} placeholder={placeholder} />
        </SearchWrapper>
    );
};

export const SearchInputWithCancel = ({
    value,
    placeholder,
    onChange,
    onKeyDown,
    onCancel,
    margin,
    showCancelOnInteract,
    onCancelInputValue,
    iconLeft,
    onKeyPress,
    onClick,
    color,
    closeCancel,
    clearInput,
}) => {
    const inputRef = useRef(null);
    const [showCancel, setShowCancel] = useState(false);

    useEffect(() => {
        if (inputRef) inputRef.current.focus();
    }, [inputRef]);

    useEffect(() => {
        if (showCancelOnInteract) {
            if (value && value.length > 0) setShowCancel(true);
        } else if (!onCancel) {
            if (value && value.length > 0) setShowCancel(true);
            else setShowCancel(false);
        } else if (closeCancel) {
            if (value && value.length > 0) setShowCancel(true);
        } else {
            setShowCancel(true);
        }
    }, [value, onCancel, showCancelOnInteract, closeCancel]);

    return (
        <MainSearchWrapper margin={margin}>
            <SearchWrapperForInputWithCancel>
                <Input
                    placeholder={placeholder}
                    onChange={onChange}
                    ref={inputRef}
                    defaultValue={value}
                    onKeyDown={onKeyDown}
                    onKeyPress={onKeyPress}
                    onClick={() => setShowCancel(true)}
                />
                <Flex>
                    <InputWithCancelSearchIcon left={iconLeft} />
                    {!showCancel && (
                        <StyledFilterIcon onClick={onClick}>
                            <img src={FilterIcon} alt="" width="20" height="50" />
                        </StyledFilterIcon>
                    )}
                </Flex>
                {showCancel && (
                    <CancelIcon
                        right={`${showCancel ? '0' : '-40px'}`}
                        onClick={() => {
                            inputRef.current.value = '';
                            onCancelInputValue && onCancelInputValue();
                        }}
                    />
                )}
            </SearchWrapperForInputWithCancel>
            {showCancel && (
                <SearchCancelText
                    onClick={() => {
                        if (!onCancel) setShowCancel(false);
                        if (closeCancel) {
                            setShowCancel(false);
                            inputRef.current.value = '';
                            onCancel();
                        } else onCancel();
                    }}
                    color={color}
                >
                    Cancel
                </SearchCancelText>
            )}
        </MainSearchWrapper>
    );
};
