import { combineReducers } from 'redux';

import check from './check';
import login from './login';
import password from './password';
import phone from './phone';
import signup from './signup';
import updateUserData from './update-user-data';

const auth = combineReducers({
    phone,
    check,
    login,
    password,
    signup,
    updateUserData,
});

export default auth;
