import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { SelectBox } from '../../../../components';
import { NewTextInputWithLabel } from '../../../../components/forms/input/new-text-input';
import { NewPhoneNumberInput } from '../../../../components/forms/input/phone-number-input';
import { getLgaByStateId } from '../../../../redux/ducks/applications/store-front/actions/store';
import { colors } from '../../../../styles';
import { ReactComponent as BagIcon } from '../assets/bag.svg';
import { ReactComponent as CardIcon } from '../assets/card.svg';
import { ReactComponent as CheckIcon } from '../assets/radioCheck.svg';
import { ReactComponent as UncheckIcon } from '../assets/radioUncheck.svg';

const Body = styled.div`
    margin-top: 67.5px;
    background: ${colors.white};
    padding: 24px 16px;
`;

const Payment = styled.div`
    margin-top: 8px;
    background: ${colors.white};
    padding: 24px 16px;
`;

const Label = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #5c6f7f;
    margin-bottom: 8px;
`;

const OptionContainer = styled.div`
    height: 56px;
    border: 1px solid ${({ active }) => (active ? colors.deepBlue : colors.gray4)};
    border-radius: 4px;
    background: ${colors.white};
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-top: ${({ index }) => index === 1 && '16px'};
`;

const OptionInnerContainer = styled.div`
    display: flex;
    align-items: center;
`;

const OptionInnerContainerText = styled.div`
    font-size: 14px;
    line-height: 21px;
    color: ${colors.boldDark};
    margin-left: 14px;
`;

const StepOne = ({ state, setState, city, setCity, paymentOptions, handleActivePayment }) => {
    const dispatch = useDispatch();
    const states = useSelector((state) => state.applications.storeFront.states);
    const store = useSelector((state) => state.applications.storeFront.storeByLink);
    const lga = useSelector((state) => state.applications.storeFront.lga);

    useEffect(() => {
        if (state.name) {
            const lgaContent = states.filter((data) => data.name === state.name);
            dispatch(getLgaByStateId(lgaContent[0]?.id));
        }
    }, [dispatch, state.name, states]);

    const findPaymentMethod = (data) => {
        if (data === 'PayOnline') {
            return paymentOptions[0].title;
        } else if (data === 'PayOnDelivery') {
            return paymentOptions[1].title;
        }
    };

    return (
        <>
            <Body>
                <Label>ENTER PERSONAL DETAILS</Label>
                <NewTextInputWithLabel
                    minHeight="0"
                    bottom="8px"
                    label={'Full Name'}
                    placeholder={'Full Name'}
                    name="fullName"
                />
                <NewTextInputWithLabel
                    minHeight="0"
                    bottom="8px"
                    label={'Email'}
                    placeholder={'Email Address'}
                    name="email"
                />
                <NewPhoneNumberInput
                    label={'Phone Number'}
                    name="phoneNumber"
                    placeholder={'Phone Number'}
                    bottomMargin="32px"
                />
                <Label>ENTER ADDRESS DETAILS</Label>
                <SelectBox
                    value={state?.name}
                    handleChange={setState}
                    placeholder="State"
                    options={states}
                    bottom="8px"
                    height="70%"
                    top="0"
                />
                <SelectBox
                    value={city?.name}
                    handleChange={setCity}
                    placeholder="City"
                    options={lga}
                    bottom="8px"
                    height="70%"
                    top="0"
                />
                <NewTextInputWithLabel
                    minHeight="0"
                    bottom="8px"
                    label={'Address'}
                    placeholder={'Address'}
                    name="address"
                />
            </Body>
            <Payment>
                <Label>PAYMENT METHOD</Label>
                {store?.payMode?.length > 0
                    ? store?.payMode?.map((data, index) => (
                          <OptionContainer
                              active={data.active}
                              index={index}
                              onClick={() => handleActivePayment(data.name)}
                              key={index}
                          >
                              <OptionInnerContainer>
                                  {index === 0 ? <CardIcon /> : <BagIcon />}
                                  <OptionInnerContainerText>{findPaymentMethod(data)}</OptionInnerContainerText>
                              </OptionInnerContainer>
                              {data.active ? <CheckIcon /> : <UncheckIcon />}
                          </OptionContainer>
                      ))
                    : paymentOptions.map((data, index) => (
                          <OptionContainer
                              active={data.active}
                              index={index}
                              onClick={() => handleActivePayment(data.name)}
                              key={index}
                          >
                              <OptionInnerContainer>
                                  {index === 0 ? <CardIcon /> : <BagIcon />}
                                  <OptionInnerContainerText>{data.title}</OptionInnerContainerText>
                              </OptionInnerContainer>
                              {data.active ? <CheckIcon /> : <UncheckIcon />}
                          </OptionContainer>
                      ))}
            </Payment>
        </>
    );
};

export default StepOne;
