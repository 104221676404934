export const sortProducts = (type, products, setProducts) => {
    if (type === 'Sort') {
        setProducts([...products]);
    } else if (type === 'Highest Price') {
        setProducts(
            [...products]?.sort(
                (a, b) => a.bulkPrices && b.bulkPrices && b.bulkPrices[0].price - a.bulkPrices[0].price,
            ),
        );
    } else if (type === 'Lowest Price') {
        setProducts(
            [...products]?.sort(
                (a, b) => a.bulkPrices && b.bulkPrices && a.bulkPrices[0].price - b.bulkPrices[0].price,
            ),
        );
    } else if (type === 'Quantity') {
        setProducts(
            products?.sort(function (a, b) {
                if (a.quantityInStock < b.quantityInStock) {
                    return -1;
                }
                if (a.quantityInStock > b.quantityInStock) {
                    return 1;
                }
                return 0;
            }),
        );
    }
};
