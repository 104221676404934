import React, { useMemo } from 'react';

import { useField, Field } from 'formik';
import { string } from 'prop-types';
import styled, { css } from 'styled-components';

import {
    FormMetaData,
    InputLabel,
    InputLabelBlock,
    InputLabelContainer,
} from '../../../../containers/NewInputContainer';
import { colors } from '../../../../styles';

export const TextInputField = styled(Field)`
    border: none;
    padding: ${({ padding }) => (padding ? padding : '0')};
    height: ${({ height }) => height || '24px'};
    font-size: ${({ size }) => size || '16px'};
    font-weight: ${({ weight }) => weight || '500'};
    color: ${({ color }) => color || `${colors.boldDark}`};
    width: 100%;
    height: ${({ inputheight }) => inputheight || '24px'};
    position: relative;
    background-color: ${({ background }) => background || `${colors.border.default}`};
    outline: none;
    appearance: textfield;
    letter-spacing: ${({ letterSpacing }) => letterSpacing || '0'};
    &input::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ${({ nolabelmargin }) =>
        !nolabelmargin &&
        css`
            &:not(:placeholder-shown) {
                margin-top: ${({ mtop }) => mtop || '6px'};
            }
        `}

    &:not(:placeholder-shown) + label {
        display: block;
    }

    & + label {
        ${({ showerror }) =>
            showerror === 'true' &&
            css`
                color: ${colors.red};
            `};
    }

    &:focus + label {
        color: ${colors.deepBlue};

        ${({ showerror }) =>
            showerror === 'true' &&
            css`
                color: ${colors.red};
            `};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 30px ${colors.border.default} inset !important;
        box-shadow: 0 0 0 30px ${colors.border.default} inset !important;
    }
`;

const TextAreaInputField = styled(TextInputField)`
    height: ${({ height }) => height || '90%'};
    font-weight: ${({ weight }) => weight || null};
    color: ${({ color }) => color || null};
`;

export const NewTextAreaWithLabel = ({
    label,
    description,
    countryselection,
    height,
    weight,
    color,
    inputHeight,
    padding,
    width,
    top,
    bottom,
    minHeight,
    labelBlockBottom,
    labelContWidth,
    errorBottom,
    ...props
}) => {
    const [field, meta] = useField(props.name);
    const { error, touched } = meta;
    const formHasError = useMemo(() => touched && error && error.length > 0, [error, touched]);

    return (
        <InputLabelContainer width={labelContWidth} minHeight={minHeight} top={top} bottom={bottom}>
            <InputLabelBlock
                width={width}
                height={height}
                id={props.id}
                padding={padding}
                valid={touched ? String(touched && !formHasError) : ''}
                error={error}
                bottom={labelBlockBottom}
            >
                <TextAreaInputField
                    mtop={'13px'}
                    touched={touched ? 'true' : 'false'}
                    showerror={String(formHasError)}
                    valid={touched ? String(touched && !formHasError) : 'true'}
                    {...field}
                    {...props}
                    component={'textarea'}
                    height={inputHeight}
                    weight={weight}
                    color={color}
                />
                <InputLabel
                    valid={touched ? String(touched && !formHasError) : 'true'}
                    countryselection={countryselection && countryselection.toString()}
                >
                    {label}
                </InputLabel>
            </InputLabelBlock>

            {formHasError && (
                <FormMetaData bottom={errorBottom} showerror={String(formHasError)}>
                    {error}
                </FormMetaData>
            )}

            {!formHasError && description && <FormMetaData>{description}</FormMetaData>}
        </InputLabelContainer>
    );
};

NewTextAreaWithLabel.propTypes = {
    label: string.isRequired,
    countrySelection: string,
    description: string,
};
