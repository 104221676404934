import React, { useState } from 'react';

import { string, bool } from 'prop-types';
import styled from 'styled-components';

import background from '../../../assets/badge_card_bg.svg';
import { ReactComponent as CopyIcon } from '../../../assets/copy-new.svg';
import { ReactComponent as MaskBanner } from '../../../assets/mask-banner.svg';
import { Badge } from '../../../containers/BadgeContainer';
import { SecondaryText } from '../../../containers/MessageContainer';
import { colors } from '../../../styles';
import { NewToast } from '../../new-toast';

const ReferralCode = styled.input`
    color: #ffffff;
    font-weight: 700;
    width: min-content;
    appearance: none;
    background: none;
    text-align: left;
    outline: none;
    border: none;
    margin-top: 14px;
    padding: 0;
    margin-top: 20px;
    font-size: 16px;
    z-index: 1;
`;

const CenteredBadge = styled(Badge)`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: ${({ top }) => top};
`;

const ReferralBadgeTitle = styled(SecondaryText)`
    position: absolute;
    margin-top: 0px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
`;

const CopyReferralCode = styled.div`
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;
    border-radius: 60px;
    background-color: #64a4ff;
    width: 50px;
    height: 50px;
    justify-content: center;
`;

const StyledMaskBanner = styled(MaskBanner)`
    position: absolute;
    top: 0;
    left: 0;
`;

const CopyReferralBadge = ({ title = 'Referral Code', referralCode, embedded = false, top }) => {
    const [showToast, setShowToast] = useState(false);
    return (
        <>
            {showToast && <NewToast text={'copied to clipboard!'} close={() => setShowToast(!showToast)} />}
            <CenteredBadge background={colors.deepBlue} height={'77px'} embedded={embedded || null} top={top || null}>
                <StyledMaskBanner />
                <ReferralBadgeTitle>Referral Code</ReferralBadgeTitle>
                <ReferralCode id={'referralCode'} readOnly value={referralCode} />
                <CopyReferralCode
                    onClick={() => {
                        document.querySelector('#referralCode').select();
                        document.execCommand('copy');
                        setShowToast(!showToast);
                    }}
                >
                    <CopyIcon />
                </CopyReferralCode>
            </CenteredBadge>
        </>
    );
};

CopyReferralBadge.propTypes = {
    referralCode: string,
    embedded: bool,
    top: string,
};

export default CopyReferralBadge;
