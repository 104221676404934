import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import { axios, currentAPI } from '../../../../../../config';
import History from '../../../../../../utils/History';
import { insertZero } from '../../../../../../utils/inputs/formatPhoneNumber';
import { SHOP_ADD_CUSTOMER, SHOP_DELETE_CUSTOMER } from '../../../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../../../utils/mix-panel/mixPanel';
import { addPendingRequest } from '../../../../offline/actions';
import {
    UPDATE_PENDING_REQUEST,
    REMOVE_PENDING_REQUEST,
    UPDATE_PENDING_SALE_CUSTOMER_ID,
} from '../../../../offline/constants';
import {
    LOADING_SHOP,
    MY_SHOP_SUCCESS,
    MY_SHOP_ERROR,
    SAVE_CUSTOMER,
    GET_CUSTOMERS,
    GET_TOTAL,
    DELETE_CUSTOMER,
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_ID,
    UPDATE_CUSTOMER_OWING_STATUS,
    LOADING_CUSTOMERS_AND_REFERRALS,
    FETCH_CUSTOMERS_AND_REFERRALS_SUCCESS,
    UPDATE_CUSTOMERS_AND_REFERRALS,
    STOP_LOADING,
    LOADING_DELIVERY_DETAILS,
    FETCH_DELIVERY_DETAILS_SUCCESS,
    FETCH_CUSTOMER,
} from '../../constants';

export const getCustomers = () => async (dispatch) => {
    if (!navigator.onLine) return;
    try {
        const getCustomersResponse = await axios.get(`${currentAPI}/api/customers/?pageSize=10000`);
        if (getCustomersResponse.status === 200) {
            dispatch({
                type: GET_TOTAL,
                payload: getCustomersResponse.data.totalElements,
            });
            dispatch({
                type: GET_CUSTOMERS,
                payload: getCustomersResponse.data.content,
            });
        }
    } catch (error) {
        //
    }
};

export const getCustomersAndReferrals = () => async (dispatch) => {
    dispatch({ type: LOADING_CUSTOMERS_AND_REFERRALS });
    try {
        const deliveryLocation =
            localStorage.getItem('deliveryLocation') && JSON.parse(localStorage.getItem('deliveryLocation'));
        const { state } = deliveryLocation || {};
        const response = await axios.get(`${currentAPI}/api/customers/customer/list?state=${state}`);

        if (response.status === 200) {
            dispatch({
                type: FETCH_CUSTOMERS_AND_REFERRALS_SUCCESS,
                payload: response.data.customerDetails,
            });
            dispatch({ type: STOP_LOADING });
        }
    } catch (error) {
        //
    } finally {
        dispatch({ type: STOP_LOADING });
    }
};

export const getSabiCustomers = () => async (dispatch) => {
    dispatch({ type: LOADING_CUSTOMERS_AND_REFERRALS });
    try {
        const deliveryLocation =
            localStorage.getItem('deliveryLocation') && JSON.parse(localStorage.getItem('deliveryLocation'));
        const { state } = deliveryLocation || {};
        const response = await axios.get(`${currentAPI}/api/customers/v2/?state=${state}`);
        dispatch({ type: STOP_LOADING });
        if (response.status === 200) {
            dispatch({
                type: FETCH_CUSTOMERS_AND_REFERRALS_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        dispatch({ type: STOP_LOADING });
    }
};

export const addSabiCustomers = (payload) => async (dispatch) => {
    dispatch({ type: LOADING_CUSTOMERS_AND_REFERRALS });
    try {
        const response = await axios.post(`${currentAPI}/api/customers/v2/`, JSON.stringify(payload));

        dispatch({ type: STOP_LOADING });
        if (response.status === 200) {
            dispatch(getSabiCustomers());
        }
    } catch (error) {
        dispatch({ type: STOP_LOADING });
    }
};

export const editSabiCustomers = (payload, id) => async (dispatch) => {
    dispatch({ type: LOADING_CUSTOMERS_AND_REFERRALS });
    try {
        const response = await axios.put(`${currentAPI}/api/customers/v2/${id}`, JSON.stringify(payload));

        dispatch({ type: STOP_LOADING });
        if (response.status === 200) {
            dispatch(getSabiCustomers());
        }
    } catch (error) {
        //
    } finally {
        dispatch({ type: STOP_LOADING });
    }
};

export const fetchDeliveryDetails = (id) => async (dispatch, getState) => {
    dispatch({ type: LOADING_DELIVERY_DETAILS });
    try {
        const userId = getState().user.userId || id;
        const response = await axios.get(`${currentAPI}/api/customers/merchant/delivery/${userId}`);
        if (response.status === 200) {
            dispatch({
                type: FETCH_DELIVERY_DETAILS_SUCCESS,
                payload: response.data,
            });
            dispatch({ type: STOP_LOADING });
        }
        // eslint-disable-next-line no-empty
    } catch (error) {
        //
    } finally {
        dispatch({ type: STOP_LOADING });
    }
};

export const updateMerchantDelivery = (id, payload, contactType) => async (dispatch) => {
    dispatch({ type: LOADING_CUSTOMERS_AND_REFERRALS });
    try {
        const response = await axios.post(`${currentAPI}/api/customers/customer/delivery/${id}`, payload);
        if (response.status === 200) {
            if (contactType === 'MERCHANT') {
                dispatch({
                    type: UPDATE_CUSTOMERS_AND_REFERRALS,
                    payload: { id, customerInfo: payload },
                });
            } else {
                dispatch({
                    type: FETCH_DELIVERY_DETAILS_SUCCESS,
                    payload: response.data,
                });
            }
            toast.success("Successfully updated customers' info");
            return true;
        }
    } catch (error) {
        if (error.response && error.response.status === 400) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.message || 'Sorry, customer cannot be updated');
        }
    } finally {
        dispatch({ type: STOP_LOADING });
    }
};

export const addCustomer =
    (customerInfo, redirect = '/actions/shop_customers', showToast = false) =>
    async (dispatch, getState) => {
        dispatch({ type: LOADING_SHOP });

        const userId = getState().user.userId;

        try {
            const addCustomerResponse = await axios.post(`${currentAPI}/api/customers/`, JSON.stringify(customerInfo));

            if (addCustomerResponse.status === 200) {
                customerInfo.id = addCustomerResponse.data.id;
                customerInfo.createdDate = addCustomerResponse.data.createdDate;

                mixPanel.track(SHOP_ADD_CUSTOMER, {
                    'User ID': userId,
                    'Customer ID': customerInfo.id,
                });
                dispatch(getCustomers());
                dispatch({ type: MY_SHOP_SUCCESS });
                showToast && toast.success('You have successfully added a new customer');
                redirect && dispatch(push(redirect));
                !redirect && dispatch(getCustomersAndReferrals());
                return addCustomerResponse.data;
            }
        } catch (error) {
            if (error.message === 'Network Error') {
                dispatch(
                    addPendingRequest('POST', `${currentAPI}/api/customers/`, customerInfo, [
                        UPDATE_CUSTOMER_ID,
                        UPDATE_PENDING_SALE_CUSTOMER_ID,
                    ]),
                );
                customerInfo.id = uuid();
                customerInfo.createdDate = new Date();

                mixPanel.track(SHOP_ADD_CUSTOMER, {
                    'User ID': userId,
                    'Customer ID': customerInfo.id,
                });

                dispatch({ type: SAVE_CUSTOMER, payload: customerInfo });
                redirect && dispatch(push(redirect));
                return customerInfo.id;
            } else {
                dispatch({ type: MY_SHOP_ERROR, payload: error.message });
                console.error(error);
            }
        }
    };

export const updatedDeliveryDetails = (phoneNumber) => async (dispatch) => {
    try {
        const response = await axios.get(`${currentAPI}/api/users/byMobile/${phoneNumber}`);
        if (response.status === 200) {
            return response?.data || {};
        }
        return null;
    } catch (error) {
        //
    } finally {
        dispatch({ type: STOP_LOADING });
    }
};

export const updateCustomer =
    (id, customerInfo, redirect = true) =>
    async (dispatch, getState) => {
        dispatch({ type: LOADING_SHOP });
        try {
            const updateCustomerResponse = await axios.put(
                `${currentAPI}/api/customers/${id}`,
                JSON.stringify(customerInfo),
            );
            if (updateCustomerResponse.status === 200) {
                dispatch({ type: MY_SHOP_SUCCESS });
                dispatch({
                    type: UPDATE_CUSTOMER,
                    payload: { id, customerInfo },
                });
                !redirect &&
                    dispatch({
                        type: UPDATE_CUSTOMERS_AND_REFERRALS,
                        payload: { id, customerInfo },
                    });
                redirect && History.goBack();
                toast.success("Successfully updated customers' info");
            }
        } catch (error) {
            if (error.message === 'Network Error') {
                const pendingPostRequests = getState().offline.pendingRequests.filter(
                    (request) => request.method === 'POST',
                );

                const findNonCreatedCustomerRequest = pendingPostRequests.find(
                    (request) => request.payload.id === customerInfo.id,
                );

                if (findNonCreatedCustomerRequest) {
                    dispatch({
                        type: UPDATE_PENDING_REQUEST,
                        payload: {
                            requestId: findNonCreatedCustomerRequest.id,
                            payload: customerInfo,
                        },
                    });
                } else {
                    dispatch(addPendingRequest('PUT', `${currentAPI}/api/customers/${id}`, customerInfo));
                }

                dispatch({ type: MY_SHOP_SUCCESS });
                dispatch({
                    type: UPDATE_CUSTOMER,
                    payload: { id, customerInfo },
                });
                dispatch(push('/actions/shop_customers'));
                toast.success("Successfully updated customers' info");
            } else {
                dispatch({
                    type: MY_SHOP_ERROR,
                    payload: error.response.data.message,
                });

                if (error.response && error.response.status === 400) {
                    const { data } = (error && error.response) || {};
                    data.message && toast.error(error.response.data.message || 'Sorry, customer cannot be updated');
                }
            }
        }
    };

export const deleteCustomer = (id) => async (dispatch, getState) => {
    dispatch({ type: LOADING_SHOP });
    const userId = getState().user.userId;

    try {
        const deleteCustomerResponse = await axios.delete(`${currentAPI}/api/customers/${id}`);

        if (deleteCustomerResponse.status === 204) {
            mixPanel.track(SHOP_DELETE_CUSTOMER, {
                'User ID': userId,
                'Customer ID': id,
            });
            dispatch({ type: DELETE_CUSTOMER, payload: id });
            dispatch(getCustomers());
            dispatch({ type: MY_SHOP_SUCCESS });
            dispatch(push('/actions/shop_customers'));
        }
    } catch (error) {
        if (error.message === 'Network Error') {
            const pendingRequests = getState().offline.pendingRequests;
            const pendingCustomerPostRequest = pendingRequests
                .filter((request) => request.method === 'POST')
                .find((request) => request.payload.id === id);

            if (pendingCustomerPostRequest) {
                dispatch({
                    type: REMOVE_PENDING_REQUEST,
                    payload: { id: pendingCustomerPostRequest.id },
                });
            } else {
                dispatch(addPendingRequest('DELETE', `${currentAPI}/api/customers/${id}`));
            }

            dispatch({ type: DELETE_CUSTOMER, payload: id });
            dispatch({ type: MY_SHOP_SUCCESS });
            dispatch(push('/actions/shop_customers'));
        } else {
            dispatch({
                type: MY_SHOP_ERROR,
                payload: error.response.data.message,
            });
        }
    }
};

export const findOrCreateCustomer = (customerInfo) => async (dispatch, getState) => {
    let customerId;

    try {
        const customers = getState().applications.myShop.customers;
        const existingCustomer = customers.find(
            (customer) => customer.phoneNumber === customerInfo.customerPhoneNumber,
        );

        if (existingCustomer) {
            customerId = existingCustomer.id;
            return customerId;
        } else {
            customerId = await dispatch(
                addCustomer(
                    {
                        name: customerInfo.customerName,
                        phoneNumber: insertZero(customerInfo.customerPhoneNumber),
                    },
                    null,
                    false,
                ),
            );
            return customerId;
        }
    } catch (error) {
        if (error.message === 'Network Error') {
            dispatch(
                addPendingRequest('POST', `${currentAPI}/api/customers/`, customerInfo, [
                    UPDATE_CUSTOMER_ID,
                    UPDATE_PENDING_SALE_CUSTOMER_ID,
                ]),
            );

            customerInfo.id = uuid();
            customerId = customerInfo.id;

            dispatch({ type: SAVE_CUSTOMER, payload: customerInfo });
        } else {
            throw new Error("Sorry, customer wasn't found");
        }
    }

    return customerId;
};

export const updateCustomerOwingStatus = (customerId) => (dispatch, getState) => {
    const sales = getState().applications.myShop.sales;
    const customers = getState().applications.myShop.customers;

    const existingCustomer = customers.find((customer) => customer.id === customerId);
    if (!existingCustomer) {
        toast.error("Sorry, customer wasn't found");
    } else {
        const customerOwingStatus = customers.find((customer) => customer.id === customerId).owing;
        const customerSalesInfo = sales.map((sale) => sale.salesInfo);

        const customerActivities = customerSalesInfo.filter((sale) => sale.customer && sale.customer.id === customerId);

        if (customerOwingStatus && customerActivities.every((sale) => sale.amountOutstanding === 0)) {
            dispatch({
                type: UPDATE_CUSTOMER_OWING_STATUS,
                payload: { customerId, status: !customerOwingStatus },
            });
        } else if (!customerOwingStatus && customerActivities.some((sale) => sale.amountOutstanding > 0)) {
            dispatch({
                type: UPDATE_CUSTOMER_OWING_STATUS,
                payload: { customerId, status: !customerOwingStatus },
            });
        }
    }
};

export const getCustomer = (customerId) => async (dispatch) => {
    if (!navigator.onLine) return;
    try {
        const getCustomerResponse = await axios.get(`${currentAPI}/api/customers/${customerId}`);
        if (getCustomerResponse.status === 200) {
            dispatch({
                type: FETCH_CUSTOMER,
                payload: getCustomerResponse.data,
            });
        }
    } catch (error) {
        //
    }
};
