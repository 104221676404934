import React, { Fragment, useState, useEffect } from 'react';

import moment from 'moment';
import { Calendar, utils } from 'react-modern-calendar-datepicker';
import { useSelector } from 'react-redux';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { PageLogo, Loader, RippleLink, OptionsPopupDialog, RippleButton } from '../../../../../components';
import { List, ListLeftBlock, ListItem, ListHeading, ListSubHeading } from '../../../../../containers/ListContainer';
import { Title, Message } from '../../../../../containers/MessageContainer';
import { Overlay } from '../../../../../containers/OverlayContainer';
import {
    FlexCenteredBlock,
    ScreenContainer,
    FilterAndSortSection,
    SortFilterCell,
} from '../../../../../containers/ScreenContainer';
import { colors } from '../../../../../styles';
import { countAmount } from '../../../../../utils/currency/countAmount';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import { parseTransactionsByDate, parseTransactionsByDay } from '../../../../../utils/date/parseTransactionsByDate';
import { MY_SHOP_HOME_INTERACTION, SHOP_START_CREATE_SALE } from '../../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../../utils/mix-panel/mixPanel';
import { SaleBadge, SaleBadges, SaleDate } from '../../../my-shop/containers/SaleBadgesContainer';
import { CalendarCover, CalendarHeaderCover, CalendarParagraph, CalendarStyleOverride } from '../add-sale';
import { CustomWeekdays } from '../add-sale/components/locale';

import { ReactComponent as SalesSVG } from './assets/allsales.svg';
import { ReactComponent as Arrow } from './assets/arrow.svg';
import SalesIcon from './assets/cart.svg';
import { ReactComponent as Group9 } from './assets/complete.svg';
import { ReactComponent as Group8 } from './assets/incomplete.svg';
import { ReactComponent as Plus } from './assets/plus.svg';
import { ReactComponent as Oldest } from './assets/time.svg';
import { filterSales } from './filter';
import { sortSales } from './sort';

const DailySalesBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6px 16px;
    height: 56px;
    background-color: #edf2f7;
`;

const SalesList = styled.div`
    position: relative;
    padding: 0 16px 16px 16px;
`;

export const ListText = styled(ListSubHeading)`
    font-weight: 500;
    font-size: 12px;
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: right;
`;

export const ButtonTextWrapper = styled.div`
    display: flex;
    padding-left: 17px;
`;

export const AddButton = styled(RippleButton)`
    z-index: 10000;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 130px;
    border-radius: 44px;
`;

const TextSpan = styled.span`
    color: ${colors.myShop.pending.text};
    font-size: 12px;
    font-weight: 500;
`;

const Wrapper = styled.div`
    text-align: right;
`;

const SelectSaleBadge = ({ type }) => {
    switch (type) {
        case true: {
            return (
                <SaleBadge bg={'transparent'} color={'#718596'} mleft={'0px'}>
                    Fully paid
                </SaleBadge>
            );
        }

        case false: {
            return (
                <SaleBadge bg={'transparent'} color={colors.myShop.pending.text} mleft={'0px'}>
                    Incomplete
                </SaleBadge>
            );
        }

        default: {
            return null;
        }
    }
};

const Sales = () => {
    const location = useLocation();
    const isLoading = useSelector((state) => state.applications.myShop.isLoading);
    const userId = useSelector((state) => state.user.userId);
    const sales = useSelector((state) => state.applications.myShop.sales);
    const passedFilterType = useSelector((state) =>
        state.router.location.state ? state.router.location.state.filterType : null,
    );
    const [openFilterOptions, setOpenFilterOptions] = useState(false);
    const [sortOptionsOpen, setSortOptionsOpen] = useState(false);
    const [sortType, setSortType] = useState('Recent sales');
    const [openDateRangePopup, setOpenDateRangePopup] = useState(false);
    const [openDatePopup, setOpenDatePopup] = useState(false);
    const [allSales, setAllSales] = useState(sales);
    const [singleDate, setSingleDate] = useState(null);
    const [dateRange, setDateRange] = useState({
        from: null,
        to: null,
    });
    const [filterType, setFilterType] = useState('');
    const [filteredSales] = useState(
        passedFilterType ? sales.filter((data) => data.salesInfo.paymentCompleteStatus === false) : sales,
    );
    const defaultShop = useSelector((state) => state.applications.myShop.shops[0]) || {};
    const merchantId = location && location.state ? location.state.merchantId : defaultShop.merchantId;
    const branchId = location && location.state ? location.state.branchId : defaultShop.branchId;
    const paymentStatus = location && location.state ? location.state.paymentStatus : null;

    useEffect(() => {
        if (paymentStatus === 'Incomplete Payment') {
            mixPanel.track(MY_SHOP_HOME_INTERACTION, {
                'Icon Name': 'Incomplete Sale',
                Time: new Date().toLocaleString(),
            });
            setFilterType(paymentStatus);
        } else {
            setFilterType(passedFilterType || 'All Sales');
        }
    }, [paymentStatus, passedFilterType]);

    useEffect(() => {
        setAllSales(
            filteredSales.filter(
                (sale) =>
                    String(sale.salesInfo.amountCollected) ||
                    (sale.salesInfo.customer && sale.salesInfo.customer.name) ||
                    (sale.salesInfo.customer && sale.salesInfo.customer.phoneNumber) ||
                    String(sale.salesInfo.id),
            ),
        );
    }, [filteredSales]);

    useEffect(() => {
        filterSales(filterType, sales, setAllSales);
    }, [filterType, sales, setAllSales]);

    useEffect(() => {
        sortSales(sortType, filteredSales, dateRange, singleDate, setAllSales);
    }, [sortType, filteredSales, dateRange, singleDate, setAllSales]);

    useEffect(() => {
        mixPanel.track(MY_SHOP_HOME_INTERACTION, {
            'Icon Name': 'Your Sale',
            Time: new Date().toLocaleString(),
        });
    }, []);

    if (isLoading) {
        return <Loader />;
    } else
        return (
            <Fragment>
                {sales.length === 0 ? (
                    <Fragment>
                        <ScreenContainer>
                            <FlexCenteredBlock top={'64px'}>
                                <PageLogo
                                    Icon={SalesIcon}
                                    width={'184px'}
                                    height={'184px'}
                                    iconHeight={'auto'}
                                    iconWidth={'auto'}
                                    margin={'24px auto'}
                                />
                                <Title>No sales made</Title>
                                <Message bottom={'24px'} top={'8px'} align={'center'} padding={'0 1em'}>
                                    You’ve not recorded any sale. Sales you make will appear here.
                                </Message>
                                <RippleLink
                                    style={{ width: '100%' }}
                                    to={{
                                        pathname: '/actions/shop_sales_add',
                                        state: { merchantId, branchId },
                                    }}
                                >
                                    <RippleButton type={'button'}>Add a sale</RippleButton>
                                </RippleLink>
                            </FlexCenteredBlock>
                        </ScreenContainer>
                    </Fragment>
                ) : (
                    <Fragment>
                        <ScreenContainer top={'0px'} padding={'0px'} paddingBottom={'65px'} color="#F9FAFC">
                            <FilterAndSortSection
                                top={'0px'}
                                justifyContent={'space-around'}
                                background={'white'}
                                padding={'6px 0'}
                            >
                                <SortFilterCell
                                    onClick={() => setSortOptionsOpen(!sortOptionsOpen)}
                                    style={{ color: '#071827' }}
                                    weight={'500'}
                                >
                                    {sortType}
                                    <Arrow />
                                </SortFilterCell>
                                <SortFilterCell
                                    onClick={() => setOpenFilterOptions(!openFilterOptions)}
                                    style={{ color: '#071827' }}
                                    weight={'500'}
                                >
                                    {filterType}
                                    <Arrow />
                                </SortFilterCell>
                            </FilterAndSortSection>
                            <Link
                                to={{
                                    pathname: '/actions/shop_sales_add',
                                    state: { merchantId, branchId },
                                }}
                                onClick={() => {
                                    mixPanel.track(SHOP_START_CREATE_SALE, {
                                        'Entry Point': 'Sales',
                                        Time: new Date().toLocaleDateString(),
                                        'User ID': userId,
                                        'shop ID': defaultShop.id,
                                    });
                                }}
                            >
                                <AddButton>
                                    <ButtonTextWrapper>
                                        <Plus />
                                        <ListText
                                            style={{
                                                color: '#ffffff',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                marginLeft: '13px',
                                                position: 'relative',
                                                top: '-2px',
                                            }}
                                        >
                                            Add Sales
                                        </ListText>
                                    </ButtonTextWrapper>
                                </AddButton>
                            </Link>
                            {parseTransactionsByDate(
                                allSales.map((sale) => sale.salesInfo),
                                'localSalesDate',
                            ).map((monthlySales, index) => (
                                <SalesList key={index}>
                                    {parseTransactionsByDay(monthlySales.transactions, 'localSalesDate').map(
                                        (sales, index) => (
                                            <List fullScreen childLink key={index}>
                                                <DailySalesBar key={index}>
                                                    <ListText style={{ color: '#718596' }}>{sales.day}</ListText>
                                                    <TextWrapper>
                                                        <Wrapper>
                                                            <ListText
                                                                style={{
                                                                    color: '#023B20',
                                                                    textalign: 'right',
                                                                }}
                                                            >
                                                                {countAmount(sales.transactions, 'totalAmount')}
                                                            </ListText>
                                                        </Wrapper>

                                                        <ListText style={{ color: '#718596' }}>
                                                            Outstanding:{' '}
                                                            {countAmount(sales.transactions, 'amountOutstanding')}
                                                        </ListText>
                                                    </TextWrapper>
                                                </DailySalesBar>
                                                {sales.transactions.map((sale, index) => (
                                                    <RippleLink
                                                        key={index}
                                                        to={{
                                                            pathname: '/actions/shop_sale_details',
                                                            state: { sale, shopId: defaultShop.id },
                                                        }}
                                                    >
                                                        <ListItem
                                                            key={index}
                                                            pressedUpList
                                                            top={'16px'}
                                                            bottom={'16px'}
                                                            background={'white'}
                                                        >
                                                            <ListLeftBlock left={'0px'}>
                                                                {sale.salesItemDetails &&
                                                                sale.salesItemDetails.length > 0 ? (
                                                                    <ListHeading maxWidth={'110px'} bottom={'4px'}>
                                                                        {sale.salesItemDetails.length > 1
                                                                            ? sale.salesItemDetails.map(
                                                                                  (item) => `${item.name}, `,
                                                                              )
                                                                            : sale.salesItemDetails[0].name}
                                                                    </ListHeading>
                                                                ) : (
                                                                    <ListHeading maxWidth={'150px'} bottom={'4px'}>
                                                                        Sale
                                                                    </ListHeading>
                                                                )}

                                                                <SaleBadges>
                                                                    <ListText style={{ color: '#718596' }}>
                                                                        {moment(new Date(sale.localSalesDate)).format(
                                                                            'hh',
                                                                        )}
                                                                        :
                                                                        {moment(new Date(sale.localSalesDate)).format(
                                                                            'mm',
                                                                        )}{' '}
                                                                        {moment(new Date(sale.localSalesDate)).format(
                                                                            'A',
                                                                        )}
                                                                        .
                                                                    </ListText>
                                                                    <SelectSaleBadge
                                                                        type={sale.paymentCompleteStatus}
                                                                    />
                                                                </SaleBadges>
                                                            </ListLeftBlock>
                                                            <SaleDate>{formatPrice(sale.totalAmount)}</SaleDate>
                                                            {sale.amountOutstanding !== 0 && (
                                                                <SaleDate
                                                                    top={'20px'}
                                                                    style={{
                                                                        color: '#718596',
                                                                        fontSize: '12px',
                                                                        fontWeight: '400',
                                                                    }}
                                                                >
                                                                    Balance:{' '}
                                                                    <TextSpan>
                                                                        {formatPrice(sale.amountOutstanding)}
                                                                    </TextSpan>
                                                                </SaleDate>
                                                            )}
                                                        </ListItem>
                                                    </RippleLink>
                                                ))}
                                            </List>
                                        ),
                                    )}
                                </SalesList>
                            ))}
                        </ScreenContainer>
                    </Fragment>
                )}

                {sortOptionsOpen && (
                    <OptionsPopupDialog
                        open={sortOptionsOpen}
                        title={'Filter'}
                        cancel={() => {
                            setSortType('All time');
                            setSortOptionsOpen(!sortOptionsOpen);
                        }}
                        items={[
                            {
                                Icon: Oldest,
                                title: 'All time',
                                click: () => {
                                    setSortOptionsOpen(!sortOptionsOpen);
                                    sortType !== 'All time' && setSortType('All time');
                                },
                            },
                            {
                                Icon: Oldest,
                                title: 'Specific date',
                                click: () => {
                                    setSortOptionsOpen(!sortOptionsOpen);
                                    setOpenDatePopup(!openDatePopup);
                                },
                            },
                            {
                                Icon: Oldest,
                                title: 'Date range',
                                click: () => {
                                    setSortOptionsOpen(!sortOptionsOpen);
                                    setOpenDateRangePopup(!openDateRangePopup);
                                },
                            },
                        ]}
                    />
                )}
                {openDateRangePopup && (
                    <Overlay bgc={'rgba(0, 0, 0, 0.4)'} zIndex={'99999'}>
                        <CalendarCover>
                            <CalendarHeaderCover>
                                <CalendarParagraph>Select Date</CalendarParagraph>
                            </CalendarHeaderCover>
                            <CalendarStyleOverride>
                                <Calendar
                                    value={dateRange}
                                    onChange={setDateRange}
                                    colorPrimary="#227EFF"
                                    locale={CustomWeekdays}
                                    maximumDate={utils().getToday()}
                                    calendarClassName="custom-calendar"
                                    calendarSelectedDayClassName="custom-today-day"
                                    renderFooter={() => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '16px',
                                            }}
                                        >
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setDateRange(null);
                                                    setOpenDateRangePopup((prev) => !prev);
                                                }}
                                                style={{
                                                    border: '1px solid #2E4457',
                                                    background: '#fff',
                                                    borderRadius: '4px',
                                                    color: '#2E4457',
                                                    width: '100px',
                                                    padding: '13px 15px',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setDateRange(dateRange);
                                                    setOpenDateRangePopup((prev) => !prev);
                                                    setSortType('Date range');
                                                }}
                                                style={{
                                                    border: 'none',
                                                    background: '#227EFF',
                                                    borderRadius: '4px',
                                                    color: '#FFFFFF',
                                                    padding: '13px 40px',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    )}
                                />
                            </CalendarStyleOverride>
                        </CalendarCover>
                    </Overlay>
                )}
                {openDatePopup && (
                    <Overlay bgc={'rgba(0, 0, 0, 0.4)'} zIndex={'99999'}>
                        <CalendarCover>
                            <CalendarHeaderCover>
                                <CalendarParagraph>Select Date</CalendarParagraph>
                            </CalendarHeaderCover>
                            <CalendarStyleOverride>
                                <Calendar
                                    value={singleDate}
                                    onChange={setSingleDate}
                                    colorPrimary="#227EFF"
                                    locale={CustomWeekdays}
                                    maximumDate={utils().getToday()}
                                    calendarClassName="custom-calendar"
                                    calendarSelectedDayClassName="custom-today-day"
                                    renderFooter={() => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '16px',
                                            }}
                                        >
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setSingleDate(null);
                                                    setOpenDatePopup((prev) => !prev);
                                                }}
                                                style={{
                                                    border: '1px solid #2E4457',
                                                    background: '#fff',
                                                    borderRadius: '4px',
                                                    color: '#2E4457',
                                                    width: '100px',
                                                    padding: '13px 15px',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setSingleDate(singleDate);
                                                    setOpenDatePopup((prev) => !prev);
                                                    setSortType('Specific date');
                                                }}
                                                style={{
                                                    border: 'none',
                                                    background: '#227EFF',
                                                    borderRadius: '4px',
                                                    color: '#FFFFFF',
                                                    padding: '13px 40px',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    )}
                                />
                            </CalendarStyleOverride>
                        </CalendarCover>
                    </Overlay>
                )}
                {openFilterOptions && (
                    <OptionsPopupDialog
                        open={openFilterOptions}
                        title={'Filter'}
                        cancel={() => {
                            setFilterType('All Sales');
                            setOpenFilterOptions(!openFilterOptions);
                        }}
                        items={[
                            {
                                Icon: SalesSVG,
                                title: 'All Sales',
                                click: () => {
                                    setOpenFilterOptions(!openFilterOptions);
                                    filterType !== 'All Sales' && setFilterType('All Sales');
                                },
                            },
                            {
                                Icon: Group9,
                                title: 'Complete Payment',
                                click: () => {
                                    setOpenFilterOptions(!openFilterOptions);
                                    filterType !== 'Complete Payment' && setFilterType('Complete Payment');
                                },
                            },
                            {
                                Icon: Group8,
                                title: 'Incomplete Payment',
                                click: () => {
                                    setOpenFilterOptions(!openFilterOptions);
                                    filterType !== 'Incomplete Payment' && setFilterType('Incomplete Payment');
                                },
                            },
                        ]}
                    />
                )}
            </Fragment>
        );
};

export default Sales;
