import React, { Fragment, useState, useEffect } from 'react';

import { Formik, Form } from 'formik';
import { bool, func, shape, string } from 'prop-types';
import { useSelector } from 'react-redux';

import { Overlay, ActionBlock } from '../../../containers/OverlayContainer';
import { SelectBox } from '../../forms/input/select';
import { InputWithLabel } from '../../forms/input/text';
import { PopUp, ConfirmButton, PopUpContent, CancelButton, PopUpHeader } from '../common';

import { BankAccountValidationSchema } from './BankAccountValidationSchema';

export const StorefrontAddBankAccount = ({ open, cancel, accountDetails, addBankAccount }) => {
    const banks = useSelector((state) => state.account.wallet.availableBanks);
    const [modifiedBanksList, setModifiedBankList] = useState([]);

    useEffect(() => {
        banks && setModifiedBankList(banks.map((bank) => ({ value: bank['code'], label: bank['name'] })) || []);
    }, [banks]);

    return (
        <Fragment>
            {open && <Overlay bgc={'rgba(0, 0, 0, 0.4)'} zIndex={'99999'} onClick={cancel} />}
            <PopUp open={open} zIndex={'100000'}>
                <PopUpContent>
                    <PopUpHeader>Bank details</PopUpHeader>
                    <Formik
                        initialValues={{
                            bankName: (accountDetails && accountDetails.bankName) || '',
                            accountNumber: (accountDetails && accountDetails.accountNumber) || '',
                            accountName: (accountDetails && accountDetails.accountName) || '',
                        }}
                        validationSchema={BankAccountValidationSchema}
                        onSubmit={(values) => {
                            setTimeout(() => {
                                addBankAccount(values);
                            }, 200);
                        }}
                    >
                        {({ errors, touched, setFieldValue, initialValues, values }) => (
                            <Form style={{ padding: '0 16px 0 16px' }}>
                                <SelectBox
                                    placeholder={'Bank'}
                                    value={values.bankName}
                                    options={modifiedBanksList}
                                    handleChange={(selected) => {
                                        setFieldValue('bankName', selected.label);
                                    }}
                                    height={'100%'}
                                    valid={`${!touched.bankName && !errors.bankName}`}
                                    error={touched && touched.bankName && errors && errors.bankName}
                                />
                                <InputWithLabel
                                    label={'Account number'}
                                    type={'text'}
                                    noClearButton
                                    value={values.accountNumber}
                                    placeholder={'Account number'}
                                    name={'accountNumber'}
                                    maxLength={10}
                                    top={'16px'}
                                    valid={`${
                                        !(touched && touched.accountNumber) && !(errors && errors.accountNumber)
                                    }`}
                                    errors={touched && touched.accountNumber && errors && errors.accountNumber}
                                    setFieldValue={setFieldValue}
                                    initialValues={initialValues}
                                />
                                <InputWithLabel
                                    label={'Account name'}
                                    type={'text'}
                                    noClearButton
                                    value={values.accountName}
                                    placeholder={'Account name'}
                                    name={'accountName'}
                                    valid={`${!(touched && touched.accountName) && !(errors && errors.accountName)}`}
                                    errors={touched && touched.accountName && errors && errors.accountName}
                                    setFieldValue={setFieldValue}
                                    initialValues={initialValues}
                                />
                                <ActionBlock direction={'row'}>
                                    <CancelButton style={{ marginLeft: '-16px' }} type="button" onClick={cancel}>
                                        Cancel
                                    </CancelButton>
                                    <ConfirmButton style={{ marginRight: '-16px' }} type="submit">
                                        Save
                                    </ConfirmButton>
                                </ActionBlock>
                            </Form>
                        )}
                    </Formik>
                </PopUpContent>
            </PopUp>
        </Fragment>
    );
};

StorefrontAddBankAccount.propTypes = {
    open: bool,
    cancel: func,
    accountDetails: shape({
        accountName: string,
        accountNumber: string,
        bankName: string,
    }),
    addBankAccount: func,
};
