export const LOADING_AGENTS_REFERRALS_DATA = 'applications/agents/loading_agent_referral_data';
export const LOADING_AGENTS_REFERRALS_COMPLETED = 'applications/agents/loading_agent_referral_completed';
export const LOADING_AGENTS_DATA = 'applications/agents/sending_agents_data';
export const LOADING_AGENTS_DATA_SUCCESS = 'applications/agents/sending_agents_data_success';
export const LOADING_AGENTS_DATA_ERROR = 'applications/agents/sending_agents_data_error';
export const SAVE_AGENTS_REFERRAL = 'applications/agents/save_agents_referral';
export const SAVE_AGENTS_COMMISSIONS = 'applications/agents/save_agents_commissions';
export const SAVE_TEAM_DATA = 'applications/agents/save_team_data';
export const SAVE_TEAM_MEMBERS = 'applications/agents/save_team_members';
export const AGENT_LAST_SEEN = 'applications/agents/agent_last_seen';
export const UPDATE_TEAM = 'applications/agents/update_team';
export const DELETE_TEAM = 'applications/agents/delete_team';
export const AGENT_ACTIVATION_STATUS_SUCCESS = 'applications/agents/activation_status_success';
export const AGENT_ACTIVATION_STATUS_FAILURE = 'applications/agents/activation_status_failure';
export const SAVE_REFERRED_AGENTS_COUNT = 'applications/agents/save_referred_agents_count';
