export const PAYMENT_LOADING = '/applications/bill-payments/payment_loading';
export const PAYMENT_SUCCESS = '/applications/bill-payments/payment_success';
export const PAYMENT_ERROR = '/applications/bill-payments/payment_error';
export const SET_PROVIDERS = '/applications/bill-payments/set_providers';
export const SET_AIRTIME_PROVIDERS = '/applications/bill-payments/set_airtime_providers';
export const SET_DATA_PROVIDERS = '/applications/bill-payments/set_data_providers';
export const SET_DATA_PLANS = '/applications/bill-payments/set_data_plans';
export const SET_PROVIDERS_ITEMS = '/applications/bill-payments/set_providers_items';
export const LOADING_ELECTRICITY_PROVIDERS = '/applications/bill-payments/loading_electricity_providers';
export const LOADING_AIRTIME_PROVIDERS = '/applications/bill-payments/loading_airtime_providers';
export const LOADING_DATA_PROVIDERS = '/applications/bill-payments/loading_data_providers';
export const LOADING_CABLE_PROVIDERS = '/applications/bill-payments/loading_cable_providers';
