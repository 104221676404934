import React, { Fragment, useState, useEffect } from 'react';

import { Formik, Form } from 'formik';
import { string, func, bool } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { TopHeader, RippleButton, SelectBox, TextInputWithLabel } from '../../../../../../components';
import { LoaderWithoutHeader } from '../../../../../../components/loader';
import { NewToast } from '../../../../../../components/new-toast';
import { Close } from '../../../../../../containers/HeaderContainer';
import { InputBlock } from '../../../../../../containers/InputContainer';
import { ScreenContainer } from '../../../../../../containers/ScreenContainer';
import { flags } from '../../../../../../data/countries';
import { merchbuyActions } from '../../../../../../redux/ducks/applications/merchbuy/actions';
import { sendOTPToWhatsapp, sendVoiceOtp } from '../../../../../../redux/ducks/auth/check/actions';
import { verifyShopName } from '../../../../../../redux/ducks/auth/signup/merchant/actions';
import { colors } from '../../../../../../styles';
import { upperCaseToTitleCase } from '../../../../../../utils/toTitleCase';
import ConstantInfo from '../../../assets/constant-info.svg';
import { ReactComponent as ShopIcon } from '../../../assets/shop_icon_blue.svg';
import { Space } from '../../../styles';
import SimpleMap from '../../google-map/simpleMap';
import { ChooseOtpChannel } from '../choose-otp-channel';
import { AddNewDeliveryAddressDetailsOtpCheck } from '../delivery-details-add-new-otp';

import { AddDeliveryDetailsValidationSchema } from './AddDeliveryDetailsValidationSchema';

const Padding = styled.div`
    padding: 0 1em;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #071827;
    margin-bottom: 16px;
`;

const InfoText = styled.div`
    font-size: 10px;
    color: ${colors.themeTextColor11};
    font-weight: 400;
    margin: -4px 0 5px 5px;
`;

const InnerTitle = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${({ color }) => color || '#071827'};
    margin: 8px 0 5px 16px;
`;

const FullAddressHolder = styled.div`
    background: #ffffff;
    border: 1px solid #edf2f7;
    box-sizing: border-box;
    box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 12px;
    margin: 16px;
`;

const ShopAddressInfo = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #718596;
    margin-left: ${({ left }) => left || '0px'};
`;

const Divider = styled.div`
    border: 1px solid #edf2f7;
    margin: 8px 0;
`;

const ShopAddressWrapper = styled.div`
    display: flex;
    flex-direction: nrow;
`;

const InLineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const AddressContainer = styled.div`
    background-color: #f9fafc;
    width: 343px;
    height: 78px;
    border-radius: 4px;
    border: 1px solid ${colors.lightGray};
    margin-top: 23%;
`;
const MiniTitle = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${({ color }) => color || `${colors.defaultGray}`};
    margin-top: ${({ top }) => top || '12px'};
    padding-left: ${({ left }) => left || '16px'};
`;
const Text = styled.p`
    margin: ${({ margin }) => margin || '0'};
    font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
    font-size: ${({ font }) => font || '12px'};
    color: ${({ color }) => color || null};
    padding-top: ${({ top }) => top || null};
`;

const MiniContent = styled.div`
    font-weight: 500;
    font-size: 14px;
    color: ${colors.defaultGray};
    margin-top: -5px;
    padding-left: 16px;
`;

const CountryFlag = styled.img`
    width: 24px;
    height: 24px;
    margin: -5px 0 0 19px;
`;

export const AddNewDeliveryAddressDetails = ({
    open,
    cancel,
    searchPhoneNumber,
    setSabiUserUse,
    setSabiUserEdit,
    setShowNewAddressPopup,
}) => {
    const dispatch = useDispatch();
    const shopInfo = useSelector((state) => state.applications.myShop.shops[0]);
    const user = useSelector((state) => state.user);
    const isLoading = useSelector((state) => state.applications.merchbuy.isLoading);
    const loading = useSelector((state) => state.auth.check.isLoading);
    const lgas = useSelector((state) => state.applications.merchbuy.lga);
    const listOfAvailableStatesAndLga = useSelector((state) => state.applications.merchbuy.availableStates);
    const AgentDeliveryLocation =
        localStorage.getItem('deliveryLocation') && JSON.parse(localStorage.getItem('deliveryLocation'));
    const { state: AgentdeliveryState } = AgentDeliveryLocation || {};

    const { businessPhoneNumber } = shopInfo || {};
    const [showOtpPage, setShowOtpPage] = useState(false);
    const [userId, setUserId] = useState('');
    const [isSelected, setIsSelected] = useState(false);
    const [coordinates, setCoordinates] = useState({
        longitude: 0,
        latitude: 0,
    });
    const [savedValue, setSavedValue] = useState(null);
    const [showToast, setShowToast] = useState(true);
    const [otpChannel, setOtpChannel] = useState('');
    const [showChooseOtpChannelPage, setShowChooseOtpChannelPage] = useState(false);
    const [resend, setResend] = useState(false);
    const [cities, setCities] = useState([]);
    const [selectedLga, setSelectedLga] = useState('');
    const [lgaChanged, setLgaChanged] = useState(false);
    const editedPhoneNumber = [
        searchPhoneNumber?.slice(0, 4),
        ' ',
        searchPhoneNumber?.slice(4, 7),
        ' ',
        searchPhoneNumber?.slice(7),
    ].join('');
    const [shopName, setShopName] = useState('');
    const [isShopNameAvailable, setIsShopNameAvailable] = useState(null);

    const getCities = async () => {
        if (lgaChanged) {
            const response = await dispatch(
                merchbuyActions.getAllCities(savedValue?.state || AgentdeliveryState, selectedLga),
            );
            response && setCities(response?.data?.content);
        }
    };

    useEffect(() => {
        getCities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedValue?.state || AgentdeliveryState, selectedLga]);

    useEffect(() => {
        const onSubmit = async () => {
            if (resend) {
                const channel = otpChannel.toUpperCase();
                const res = await dispatch(merchbuyActions.newPinResendCode(channel, userId, 'ONBOARDING'));
                res && setShowChooseOtpChannelPage(false);
                res && setShowOtpPage(true);
            } else {
                let newUserId;
                setShowChooseOtpChannelPage(false);

                if (otpChannel === 'sms') {
                    newUserId = await dispatch(merchbuyActions.sendTelephone(savedValue.msisdn, 'ONBOARDING'));
                } else if (otpChannel === 'voice') {
                    newUserId = await dispatch(sendVoiceOtp('ONBOARDING', savedValue.msisdn));
                } else {
                    newUserId = await dispatch(sendOTPToWhatsapp('ONBOARDING', savedValue.msisdn, false));
                }
                newUserId && setUserId(newUserId);
                newUserId && setShowOtpPage(true);
            }
        };
        otpChannel && onSubmit();
        setOtpChannel('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, otpChannel]);

    const handleSubmit = (values, setErrors) => {
        setTimeout(() => {
            if (businessPhoneNumber) {
                const isUserPhoneNumber = businessPhoneNumber === values.msisdn;
                if (isUserPhoneNumber) {
                    return setErrors({
                        msisdn: 'Sorry, you cannot use your number for a customer!',
                    });
                }
            }
            setSavedValue(values);
            setShowChooseOtpChannelPage(true);
        }, 200);
    };

    const completeNewSignUp = async (values) => {
        const payload = {
            ...values,
            deliveryInstructions: values.deliveryInstruction,
            agentCustomer: {
                city: values.city,
                state: values.state,
                cordinates: coordinates,
                customerId: userId,
                deliveryInstructions: values.deliveryInstruction,
                firstName: values.firstName.trim(),
                lastName: values.lastName.trim(),
                lga: values.lga,
                middleName: '',
                phoneNumber: values.phoneNumber,
                shopAddress: values.shopAddress,
                shopName: values.shopName,
                shopNumber: values.shopNumber,
            },
        };

        const createUser = await dispatch(merchbuyActions.createAccountForMerchant(payload));
        createUser && setSabiUserUse(payload.agentCustomer);
    };

    const initialFormValues = {
        firstName: savedValue?.firstName || '',
        lastName: savedValue?.lastName || '',
        email: `${searchPhoneNumber}@spaceso2o.com`,
        country: 'NG',
        phoneNumber: searchPhoneNumber || '',
        businessName: savedValue?.businessName || '',
        shopAddress: savedValue?.shopAddress || '',
        businessPhoneNumber: searchPhoneNumber || '',
        state: savedValue?.state || AgentdeliveryState || '',
        lga: savedValue?.lga || '',
        city: savedValue?.city || '',
        msisdn: searchPhoneNumber || '',
        businessCategories: [],
        shopNumber: savedValue?.shopNumber || '',
        deliveryInstruction: savedValue?.deliveryInstruction || '',
        referralCode: user?.agentCodeToShare || '',
    };

    const resendCode = () => {
        setShowOtpPage(false);
        setShowChooseOtpChannelPage(true);
        setResend(true);
    };

    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (shopName) {
                dispatch(verifyShopName(shopName)).then((status) => {
                    if (status === true) {
                        setIsShopNameAvailable(true);
                    } else {
                        setIsShopNameAvailable(false);
                    }
                });
            }
        }, 1000);
        return () => clearTimeout(timeOut);
    }, [shopName, dispatch]);

    return isLoading || loading ? (
        <LoaderWithoutHeader />
    ) : (
        open && (
            <Fragment>
                {!isSelected && !showOtpPage && !showChooseOtpChannelPage && (
                    <TopHeader title={'New Address'} noArrow withSpacesHeader>
                        <Close left={'16px'} onClick={cancel} />
                    </TopHeader>
                )}

                {showOtpPage && (
                    <AddNewDeliveryAddressDetailsOtpCheck
                        open={showOtpPage}
                        cancel={() => {
                            setShowOtpPage(false);
                            setShowChooseOtpChannelPage(true);
                        }}
                        completeSignUp={() => {
                            setShowOtpPage(false);
                            completeNewSignUp(savedValue);
                        }}
                        phoneNumber={searchPhoneNumber}
                        resendCode={resendCode}
                    />
                )}

                {showChooseOtpChannelPage && (
                    <ChooseOtpChannel
                        open={showChooseOtpChannelPage}
                        cancel={() => setShowChooseOtpChannelPage(false)}
                        setOtpChannel={setOtpChannel}
                    />
                )}

                {!showOtpPage && !showChooseOtpChannelPage && (
                    <ScreenContainer top="0" padding="0">
                        {showToast && (
                            <NewToast text={'Merchant does not exist on Sabi'} close={() => setShowToast(false)} />
                        )}
                        {!isSelected && (
                            <Fragment>
                                <Padding>
                                    <AddressContainer>
                                        <InLineContainer>
                                            <MiniTitle>Phone Number of New Merchant</MiniTitle>
                                            <Text
                                                color={colors.deepBlue}
                                                margin="30px 0 0 76px"
                                                fontWeight="500"
                                                onClick={() => {
                                                    cancel();
                                                    setShowNewAddressPopup(true);
                                                }}
                                            >
                                                Change
                                            </Text>
                                        </InLineContainer>
                                        <InLineContainer>
                                            <CountryFlag
                                                src={
                                                    flags.filter((flag) => flag?.customAbbreviation === 'NG')[0]?.value
                                                }
                                                alt={flags.filter((flag) => flag.customAbbreviation === 'NG')[0].value}
                                            />

                                            <MiniContent>{editedPhoneNumber}</MiniContent>
                                        </InLineContainer>
                                    </AddressContainer>
                                </Padding>
                                <Padding>
                                    <MiniTitle color={colors.themeTextColor11} top={'16px'} left={'0px'}>
                                        There is currently no user for this number, please set up a new merchant
                                        account.
                                    </MiniTitle>
                                </Padding>
                                <Space height="16px" />
                                <Padding>
                                    <Title>Add Merchant information</Title>
                                </Padding>
                            </Fragment>
                        )}

                        <Formik
                            initialValues={initialFormValues}
                            validationSchema={AddDeliveryDetailsValidationSchema}
                            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
                        >
                            {({ errors, touched, values, handleChange, setFieldValue, initialValues, setErrors }) => {
                                return (
                                    <Form>
                                        <InputBlock>
                                            <Padding>
                                                <TextInputWithLabel
                                                    label="First Name"
                                                    type={'text'}
                                                    minHeight="0px"
                                                    value={values.firstName}
                                                    placeholder={'First Name'}
                                                    bottom="8px"
                                                    name="firstName"
                                                    valid={`${!touched.firstName && !errors.firstName}`}
                                                    errors={touched && touched.firstName && errors && errors.firstName}
                                                    setFieldValue={setFieldValue}
                                                    initialValues={initialValues}
                                                />
                                            </Padding>
                                            <Padding>
                                                <TextInputWithLabel
                                                    label="Last Name"
                                                    type={'text'}
                                                    value={values.lastName}
                                                    placeholder={'Last Name'}
                                                    minHeight="0px"
                                                    bottom="8px"
                                                    name="lastName"
                                                    valid={`${!touched.lastName && !errors.lastName}`}
                                                    errors={touched && touched.lastName && errors && errors.lastName}
                                                    setFieldValue={setFieldValue}
                                                    initialValues={initialValues}
                                                />
                                            </Padding>
                                            <Padding>
                                                <TextInputWithLabel
                                                    label="Shop Name"
                                                    type={'text'}
                                                    value={values.shopName}
                                                    placeholder={'Shop Name'}
                                                    minHeight="0px"
                                                    bottom="8px"
                                                    name="shopName"
                                                    valid={`${!touched.shopName && !errors.shopName}`}
                                                    errors={touched && touched.shopName && errors && errors.shopName}
                                                    setFieldValue={setFieldValue}
                                                    initialValues={initialValues}
                                                    onKeyUp={(e) => setShopName(e.target.value)}
                                                />
                                                {shopName && isShopNameAvailable && (
                                                    <Text margin={'10px 0'}>Shop name is available</Text>
                                                )}
                                                {shopName && !isShopNameAvailable && (
                                                    <Text margin={'10px 0'} color={colors.textRed}>
                                                        Shop name is not available
                                                    </Text>
                                                )}
                                            </Padding>
                                            <Padding>
                                                <Title>Add Merchant Address</Title>
                                                <TextInputWithLabel
                                                    placeholder={'State'}
                                                    label={'State'}
                                                    color={colors.themeTextColor11}
                                                    value={values.state}
                                                    top="0px"
                                                    title="Select State"
                                                    height="90%"
                                                    options={listOfAvailableStatesAndLga}
                                                    handleChange={(selected) => {
                                                        setFieldValue('state', selected.label);
                                                        setFieldValue('lga', '');
                                                        setFieldValue('city', '');
                                                    }}
                                                    disabled
                                                />
                                            </Padding>
                                            <InLineContainer>
                                                <img
                                                    src={ConstantInfo}
                                                    alt=""
                                                    width="13"
                                                    height="13"
                                                    style={{ margin: '-13px 0 0 33px' }}
                                                />
                                                <InfoText>
                                                    Delivery state is fixed to your current shopping location
                                                </InfoText>
                                            </InLineContainer>
                                            <Padding>
                                                <SelectBox
                                                    placeholder={'Local government'}
                                                    value={values.lga}
                                                    top="8px"
                                                    title="Select L.G.A"
                                                    height="90%"
                                                    isSelected={isSelected}
                                                    setIsSelected={setIsSelected}
                                                    options={lgas}
                                                    handleChange={(selected) => {
                                                        setFieldValue('lga', selected.name);
                                                        setSelectedLga(selected.name);
                                                        setLgaChanged(true);
                                                    }}
                                                    valid={`${!touched.lga && !errors.lga}`}
                                                    errors={touched && touched.lga && errors && errors.lga}
                                                />
                                                <SelectBox
                                                    placeholder={'Select City'}
                                                    value={upperCaseToTitleCase(values.city)}
                                                    top="8px"
                                                    height="90%"
                                                    title="Select City"
                                                    isSelected={isSelected}
                                                    setIsSelected={setIsSelected}
                                                    options={cities}
                                                    handleChange={(selected) => {
                                                        setFieldValue('city', selected.name);
                                                    }}
                                                    valid={`${!touched.city && !errors.city}`}
                                                    errors={touched && touched.city && errors && errors.city}
                                                />
                                            </Padding>
                                            <Padding>
                                                <TextInputWithLabel
                                                    label={'Street Name'}
                                                    type={'text'}
                                                    value={values.shopAddress}
                                                    placeholder={'Street Name'}
                                                    bottom="8px"
                                                    top="8px"
                                                    name="shopAddress"
                                                    valid={`${touched.shopAddress && !errors.shopAddress}`}
                                                    errors={
                                                        touched && touched.shopAddress && errors && errors.shopAddress
                                                    }
                                                    setFieldValue={setFieldValue}
                                                    initialValues={initialValues}
                                                />
                                            </Padding>

                                            <FullAddressHolder>
                                                <ShopAddressWrapper>
                                                    <ShopIcon />
                                                    <ShopAddressInfo left="6px">Your Shop address</ShopAddressInfo>
                                                </ShopAddressWrapper>
                                                <Divider />
                                                <ShopAddressInfo>
                                                    {`
                                                    ${values.shopNumber}
                                                    ${`${upperCaseToTitleCase(values.shopAddress)} `}
                                                    ${`${values.city.toLowerCase()}, `}
                                                    ${`${values.lga.toLowerCase()} LGA,`}
                                                    ${`${values.state} sta. `}
                                                `}
                                                </ShopAddressInfo>
                                            </FullAddressHolder>
                                            <InnerTitle>Drag the marker to the exact location</InnerTitle>
                                            <Padding>
                                                <SimpleMap
                                                    coordinates={coordinates}
                                                    setCoordinates={setCoordinates}
                                                    address={`${values.shopNumber} ${values.shopAddress} ${values.city} ${values.lga} ${values.state}`}
                                                />
                                            </Padding>
                                            <Padding>
                                                <RippleButton
                                                    type="submit"
                                                    disabled={
                                                        !values.firstName ||
                                                        !values.lastName ||
                                                        !values.lga ||
                                                        !values.city ||
                                                        !values.shopAddress ||
                                                        !isShopNameAvailable
                                                    }
                                                >
                                                    Continue
                                                </RippleButton>
                                            </Padding>
                                        </InputBlock>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </ScreenContainer>
                )}
            </Fragment>
        )
    );
};

AddNewDeliveryAddressDetails.propTypes = {
    open: bool,
    cancel: func,
    receiverName: string,
    shopName: string,
    streetAddress: string,
    state: string,
    lga: string,
    instruction: string,
    msisdn: string,
    getDeliveryDetails: func,
};
