import React, { Fragment } from 'react';

import styled from 'styled-components';

import { PageLogo } from '../../../../../components';
import { List, ListItemNoDivider, ListLeftBlock } from '../../../../../containers/ListContainer';
import { colors } from '../../../../../styles';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import { parseTextCountRender } from '../../../../../utils/inputs/parseTextCountRender';
import { upperCaseToTitleCase } from '../../../../../utils/toTitleCase';
import FallbackProductImage from '../../assets/productImage.svg';
import SupermarketIcon from '../../assets/supermarket.svg';
import { Container } from '../../styles';
import { getPriceFromBulkPrices } from '../../utils/getPriceFromBulkPrices';
import { ReactComponent as GroupBuyBg } from '../assets/groupBuyIcon.svg';
import Countdown from '../countdown';

const ListItemChild = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    padding-top: 8px;
    opacity: ${({ grayOut }) => grayOut || null};
`;

const ModifiedListItem = styled(ListItemNoDivider)``;

const ModifiedContainer = styled(Container)`
    width: 100%;
`;

const ShopBlock = styled.section`
    background: ${colors.white};
    border-radius: 4px;
    padding: 0px 15px 0px 15px;
    box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
    min-height: ${({ isGroupBy }) => (isGroupBy ? '110px' : '51px')};
    max-height: ${({ isGroupBy }) => (isGroupBy ? '140px' : '120px')};
    margin-bottom: 8px;
`;

const GroupBuyWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4px;
`;

const GroupBuyBoxWrapper = styled.div`
    width: 78px;
    height: 20px;
    background: linear-gradient(94.68deg, #7e42f5 16.08%, #227eff 262.55%);
    border-radius: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const GroupBuyBoxBgWrapper = styled.div`
    background: ${colors.background.lightCircle};
    width: 15px;
    height: 15px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px 1px 2px;
`;

const GroupBuyBoxText = styled.div`
    color: ${colors.white};
    font-size: 10px;
    margin-bottom: 1px;
`;

const GroupBuyEditText = styled.div`
    color: ${colors.red};
    font-size: 10px;
`;

const PriceWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2px;
`;

const QuantityWrapper = styled.div`
    align-self: flex-end;
    font-size: 12px;
    color: ${colors.subGrey};
`;

const ListHeading = styled.h4`
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: ${colors.boldDark};
`;

let currentDate = new Date().toISOString();

const Products = ({ carts }) => {
    return (
        <Fragment>
            <ModifiedContainer>
                <List noBorderBottom={true} fullScreen="0px 1em">
                    <Fragment>
                        {carts?.map((product, index) => {
                            const price = getPriceFromBulkPrices(product);
                            return (
                                <Fragment key={index}>
                                    <ShopBlock
                                        isGroupBy={product.groupBuyId}
                                        index={index}
                                        cartLength={carts.length}
                                        expired={
                                            product.groupBuyTimer && product.groupBuyTimer < currentDate ? true : false
                                        }
                                    >
                                        <ModifiedListItem top={'0px'} bottom={'8px'} direction={'column'}>
                                            <ListItemChild key={index}>
                                                <PageLogo
                                                    width={'32px'}
                                                    height={'32px'}
                                                    iconWidth={'32px'}
                                                    iconHeight={'32px'}
                                                    Icon={product?.images?.baseImageUrl || FallbackProductImage}
                                                    fallback={SupermarketIcon}
                                                    borderRadius="4px"
                                                />
                                                <ListLeftBlock left="12px" width="100%">
                                                    {product.groupBuyId && (
                                                        <GroupBuyWrapper>
                                                            <GroupBuyBoxWrapper>
                                                                <GroupBuyBoxBgWrapper>
                                                                    <GroupBuyBg />
                                                                </GroupBuyBoxBgWrapper>
                                                                <GroupBuyBoxText>Groupbuy</GroupBuyBoxText>
                                                            </GroupBuyBoxWrapper>
                                                            <GroupBuyEditText>
                                                                <Countdown date={product.groupBuyTimer} />
                                                            </GroupBuyEditText>
                                                        </GroupBuyWrapper>
                                                    )}
                                                    <ListHeading>
                                                        {product &&
                                                            upperCaseToTitleCase(
                                                                `${product?.brandName} - ${product?.productName} - ${product.productVariantName}` ||
                                                                    '',
                                                            )}
                                                    </ListHeading>
                                                    <PriceWrapper>
                                                        <QuantityWrapper>
                                                            {parseTextCountRender(product?.quantity, 'unit')}
                                                        </QuantityWrapper>
                                                        <QuantityWrapper>{formatPrice(price)}</QuantityWrapper>
                                                    </PriceWrapper>
                                                </ListLeftBlock>
                                            </ListItemChild>
                                        </ModifiedListItem>
                                    </ShopBlock>
                                </Fragment>
                            );
                        })}
                    </Fragment>
                </List>
            </ModifiedContainer>
        </Fragment>
    );
};

export default Products;
