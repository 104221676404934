import React, { Fragment, useCallback, useState, useEffect } from 'react';

import { Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { RippleButton, TopHeader } from '../../../../components';
import { NewTextInputWithLabel } from '../../../../components/forms/input/new-text-input';
import { NewPhoneNumberInput } from '../../../../components/forms/input/phone-number-input';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { updateStore } from '../../../../redux/ducks/applications/store-front/actions/store';
import { colors } from '../../../../styles';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import SetupStoreFrontFailure from '../setup-store/storeFrontFailure';
import SetupStoreFrontSuccess from '../setup-store/storeFrontSuccess';

import { ValidationSchema } from './formValidation';

const FormSeperator = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: ${colors.subGrey};
    margin-bottom: 16px;
`;

const Counter = styled.div`
    font-size: 10px;
    line-height: 16px;
    text-align: right;
    color: ${colors.subGrey};
    margin: 4px 16px 16px 0;
`;

const DisabledBlock = styled.div`
    opacity: 0.8;
`;

const StoreFrontDetails = () => {
    const dispatch = useDispatch();
    const store = useSelector((state) => state.applications.storeFront.store);
    const loading = useSelector((state) => state.applications.storeFront.loadingStore);
    const [buttonText, setButtonText] = useState('Save Changes');
    const [showSuccessPage, setShowSuccessPage] = useState(false);
    const [showFailurePage, setShowFailurePage] = useState(false);
    const [{ content, wordCount }, setContent] = useState({
        content: store.description,
        wordCount: 0,
    });
    const limit = 20;

    const setFormattedContent = useCallback(
        (text) => {
            let words = text?.split(' ')?.filter(Boolean);
            if (words?.length > limit) {
                setContent({
                    content: words?.slice(0, limit)?.join(' '),
                    wordCount: limit,
                });
            } else {
                setContent({ content: text, wordCount: words?.length });
            }
        },
        [limit, setContent],
    );

    const handleSubmit = (data, storeId) => {
        const params = {
            ...data,
            description: content,
            link: data.name.split(' ').join(''),
            paymentMode: [],
            deliveryFees: [],
        };
        setButtonText('Saving...');
        dispatch(updateStore(params, storeId)).then((res) => {
            if (res) {
                setShowSuccessPage(!showSuccessPage);
            } else {
                setButtonText('Save Changes');
                setShowFailurePage(!showFailurePage);
            }
        });
    };

    useEffect(() => {
        setFormattedContent(content);
    }, [content, setFormattedContent]);

    return (
        <Fragment>
            {showSuccessPage && (
                <SetupStoreFrontSuccess
                    update
                    open={showSuccessPage}
                    setOpen={() => setShowSuccessPage(!showSuccessPage)}
                />
            )}
            {showFailurePage && (
                <SetupStoreFrontFailure
                    update
                    open={showFailurePage}
                    setOpen={() => setShowFailurePage(!showFailurePage)}
                />
            )}
            <DesktopBackgroundLayout>
                {!showSuccessPage && !showFailurePage && (
                    <TopHeader title={'Store Details'} backLink={'/actions/storefront_settings'} />
                )}
                <ScreenContainer top={'70px'} color={colors.lightestGray} minHeight={'100%'} paddingBottom={'31px'}>
                    <Formik
                        initialValues={{
                            name: store.name || '',
                            description: content,
                            link: store.link || '',
                            businessEmail: store.businessEmail || '',
                            whatsappNumber: store.whatsappNumber || '',
                            twitterUrl: store.twitterUrl || '',
                            facebookUrl: store.facebookUrl || '',
                        }}
                        validationSchema={ValidationSchema}
                        onSubmit={(values, { setErrors }) => {
                            handleSubmit(values, store.id);
                        }}
                    >
                        {({ values }) => (
                            <Form>
                                <NewTextInputWithLabel
                                    value={values.name}
                                    label={'Store Name'}
                                    placeholder={'Store Name'}
                                    name="name"
                                />
                                <NewTextInputWithLabel
                                    value={content}
                                    label={'Store Description'}
                                    placeholder={'Store Description'}
                                    onChange={(event) => setFormattedContent(event.target.value)}
                                    name="description"
                                    minHeight="56px"
                                />
                                <Counter>
                                    {wordCount}/{limit}
                                </Counter>
                                <DisabledBlock>
                                    <NewTextInputWithLabel
                                        value={values.name.split(' ').join('-')}
                                        label={'Store Link'}
                                        placeholder={'Store Link'}
                                        disabled
                                        name="link"
                                    />
                                </DisabledBlock>
                                <FormSeperator>OTHER DETAILS</FormSeperator>
                                <NewTextInputWithLabel
                                    value={values.businessEmail}
                                    label={'Business Email'}
                                    placeholder={'Business Email'}
                                    name="businessEmail"
                                />
                                <NewPhoneNumberInput
                                    value={values.whatsappNumber}
                                    label={'Whatsapp Phone Number'}
                                    name="whatsappNumber"
                                    placeholder={'Whatsapp Phone Number'}
                                    minHeight={'56px'}
                                />
                                <NewTextInputWithLabel
                                    value={values.twitterUrl}
                                    label={'Twitter URL'}
                                    placeholder={'Twitter URL'}
                                    name="twitterUrl"
                                />
                                <NewTextInputWithLabel
                                    value={values.facebookUrl}
                                    label={'Facebook URL'}
                                    placeholder={'Facebook URL'}
                                    name="facebookUrl"
                                />
                                <RippleButton
                                    type="submit"
                                    disabled={!values.name || !values.whatsappNumber || !content || loading}
                                    height={'56px'}
                                >
                                    {buttonText}
                                </RippleButton>
                            </Form>
                        )}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default StoreFrontDetails;
