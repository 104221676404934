import React, { Fragment, useState, useEffect } from 'react';

import { Formik, Form } from 'formik';
import { string, func, bool } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Loader, TopHeader, PasswordGroup, ResendCodeTimer } from '../../../../../../components';
import { NewToast } from '../../../../../../components/new-toast';
import { Message, PageCaption, Strong } from '../../../../../../containers/MessageContainer';
import { ScreenContainer } from '../../../../../../containers/ScreenContainer';
import { merchbuyActions } from '../../../../../../redux/ducks/applications/merchbuy/actions';
import { tick, setExpired } from '../../../../../../redux/ducks/timer/actions';
import { getInputValues } from '../../../../../../utils/inputs/getInputValues';

import { CodeCheckFormValidationSchema } from './CodeCheckFormValidationSchema';

export const AddNewDeliveryAddressDetailsOtpCheck = ({ open, cancel, phoneNumber, completeSignUp, resendCode }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.auth.check.isLoading);
    const expired = useSelector((state) => state.timer.expired);
    const counter = useSelector((state) => state.timer.counter);
    const [value, setValue] = useState('');
    const [code, setCode] = useState('');
    const [showToast, setShowToast] = useState(true);

    useEffect(() => {
        if (code.length === 6) {
            dispatch(merchbuyActions.sendCode(code, phoneNumber, 'ONBOARDING')).then((result) => {
                result && completeSignUp();
            });
        }
    }, [dispatch, completeSignUp, code, phoneNumber]);

    return isLoading ? (
        <Loader />
    ) : (
        open && (
            <Fragment>
                <TopHeader
                    backAction={() => {
                        cancel();
                    }}
                    title={'Enter OTP'}
                    withSpacesHeader
                    backLink={'#'}
                />
                <ScreenContainer top={'64px'}>
                    {showToast && <NewToast text={'OTP has been sent to Merchant'} close={() => setShowToast(false)} />}
                    <PageCaption>Enter OTP</PageCaption>
                    <Message align={'left'} top={'8px'} bottom={'24px'} maxWidth={'328px'} color={'#718596'}>
                        Kindly provide the 6-digit OTP that was sent to the merchants phone number{' '}
                        <Strong>{phoneNumber}</Strong>
                    </Message>
                    <Message size={'10px'} align={'left'} top={'8px'} lineHeight={'inherit'} color={'#718596'}>
                        Enter PIN
                    </Message>
                    <Formik
                        initialValues={{
                            code: '',
                        }}
                        validationSchema={CodeCheckFormValidationSchema}
                        onSubmit={(values) => {}}
                    >
                        {({ errors, values, valid, touched, setFieldValue }) => {
                            const handlePasswordChange = (event) => {
                                setValue(event.target.value);
                                setFieldValue('code', getInputValues('code'));
                                setCode(getInputValues('code'));
                            };

                            return (
                                <Form>
                                    <PasswordGroup
                                        align={'center'}
                                        count={6}
                                        startIndex={1}
                                        type="number"
                                        valid={valid}
                                        name="code"
                                        errors={errors}
                                        touched={touched}
                                        enteredValue={value}
                                        handleChange={handlePasswordChange}
                                    />
                                    <ResendCodeTimer
                                        counter={counter}
                                        expired={expired}
                                        showWhatsAppButton={false}
                                        resendButtonText="Resend OTP"
                                        sendUssd={() => dispatch(merchbuyActions.sendUssd(phoneNumber))}
                                        setExpired={(condition) => dispatch(setExpired(condition))}
                                        tick={(counter) => dispatch(tick(counter))}
                                        resendCode={resendCode}
                                    />
                                </Form>
                            );
                        }}
                    </Formik>
                </ScreenContainer>
            </Fragment>
        )
    );
};

AddNewDeliveryAddressDetailsOtpCheck.propTypes = {
    open: bool,
    cancel: func,
    phoneNumber: string,
    userId: string,
};
