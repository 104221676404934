import React, { Fragment, useState } from 'react';

import { string, bool, object } from 'prop-types';

import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import { ReactComponent as MinusIcon } from '../assets/iconMinus.svg';
import { ReactComponent as LossIcon } from '../assets/loss.svg';
import { ReactComponent as ProfitIcon } from '../assets/profit.svg';
import {
    Ruler,
    SemiRulerBox,
    SemiRuler,
    LeftBox,
    CollapseIcon,
    ShowIcon,
    DropdownBodyActive,
    DropdownBodyInactive,
    DropdownBodyInactiveMain,
    PercentageCount,
    DropdownContainer,
    DropdownTitle,
    Amount,
    Relative,
    Container,
} from '../reports.styles';
import { calPercentageCountColor, isValidNum } from '../utils/processData';

import { LegendBox } from './BubbleChartLegendBox';

export const DropdownList = ({ title, simple, children, salesReportExtra, productsReport, sortType }) => {
    const [open, setOpen] = useState(false);
    const colors = ['#015436', '#02b57d', '#579fd7', '#6c7984', '#92cfdf', '#ffaa00'];

    return (
        <DropdownContainer>
            {!open ? (
                <Container>
                    <DropdownBodyInactiveMain onClick={() => setOpen(!open)}>
                        <DropdownBodyInactive>
                            <DropdownTitle>
                                {title}
                                <ShowIcon />
                            </DropdownTitle>
                            {simple ? (
                                <>
                                    {title === 'Sales' ? (
                                        salesReportExtra && Number(salesReportExtra.salesPercentageIncrease) !== 0 ? (
                                            salesReportExtra.salesIncreased ? (
                                                <ProfitIcon />
                                            ) : (
                                                <LossIcon />
                                            )
                                        ) : (
                                            <MinusIcon />
                                        )
                                    ) : salesReportExtra &&
                                      isValidNum(salesReportExtra.profitPercentageIncrease) !== 0 ? (
                                        salesReportExtra.profitIncreased ? (
                                            <ProfitIcon />
                                        ) : (
                                            <LossIcon />
                                        )
                                    ) : (
                                        <MinusIcon />
                                    )}

                                    <PercentageCount
                                        top={'23'}
                                        bottom={'0'}
                                        color={
                                            salesReportExtra &&
                                            calPercentageCountColor(
                                                title,
                                                salesReportExtra.salesPercentageIncrease,
                                                salesReportExtra.salesIncreased,
                                                isValidNum(salesReportExtra.profitPercentageIncrease),
                                                salesReportExtra.profitIncreased,
                                            )
                                        }
                                    >
                                        {salesReportExtra
                                            ? title === 'Sales'
                                                ? (salesReportExtra.salesPercentageIncrease || 0).toFixed(2)
                                                : isValidNum(salesReportExtra.profitPercentageIncrease).toFixed(2)
                                            : '0'}
                                        % from previous {sortType.type}
                                    </PercentageCount>

                                    <Amount open={open}>
                                        {salesReportExtra
                                            ? title === 'Sales'
                                                ? formatPrice(salesReportExtra.totalSum || 0)
                                                : formatPrice(salesReportExtra.totalProfitSum || 0)
                                            : '₦0.00'}
                                    </Amount>
                                </>
                            ) : (
                                <>
                                    <SemiRulerBox>
                                        {productsReport.length ? (
                                            colors.map((color, key) => {
                                                return (
                                                    <SemiRuler
                                                        key={key}
                                                        width={productsReport[key] ? productsReport[key].percentage : 0}
                                                        color={color}
                                                    >
                                                        {' '}
                                                    </SemiRuler>
                                                );
                                            })
                                        ) : (
                                            <Ruler />
                                        )}
                                    </SemiRulerBox>
                                    <LeftBox>
                                        <span>{salesReportExtra && salesReportExtra.totalProduct}</span>
                                        <PercentageCount top={'7'} bottom={'0'}>
                                            Products Sold
                                        </PercentageCount>
                                    </LeftBox>
                                </>
                            )}
                        </DropdownBodyInactive>
                        {!simple && <LegendBox productsReport={productsReport} />}
                    </DropdownBodyInactiveMain>
                </Container>
            ) : (
                <DropdownBodyActive>
                    <Container>
                        <Relative>
                            <DropdownTitle onClick={() => setOpen(!open)}>
                                {title}
                                <CollapseIcon />
                            </DropdownTitle>
                            {simple ? (
                                <>
                                    {title === 'Sales' ? (
                                        salesReportExtra && Number(salesReportExtra.salesPercentageIncrease) !== 0 ? (
                                            salesReportExtra.salesIncreased ? (
                                                <ProfitIcon />
                                            ) : (
                                                <LossIcon />
                                            )
                                        ) : (
                                            <MinusIcon />
                                        )
                                    ) : salesReportExtra &&
                                      isValidNum(salesReportExtra.profitPercentageIncrease) !== 0 ? (
                                        salesReportExtra.profitIncreased ? (
                                            <ProfitIcon />
                                        ) : (
                                            <LossIcon />
                                        )
                                    ) : (
                                        <MinusIcon />
                                    )}
                                    <PercentageCount
                                        color={
                                            salesReportExtra &&
                                            calPercentageCountColor(
                                                title,
                                                salesReportExtra.salesPercentageIncrease,
                                                salesReportExtra.salesIncreased,
                                                isValidNum(salesReportExtra.profitPercentageIncrease),
                                                salesReportExtra.profitIncreased,
                                            )
                                        }
                                        top={'7'}
                                        bottom={'17'}
                                    >
                                        {salesReportExtra
                                            ? title === 'Sales'
                                                ? (salesReportExtra.salesPercentageIncrease || 0).toFixed(2)
                                                : isValidNum(salesReportExtra.profitPercentageIncrease).toFixed(2)
                                            : '0'}
                                        % from previous {sortType.type}
                                    </PercentageCount>

                                    <Amount open={open}>
                                        {salesReportExtra
                                            ? title === 'Sales'
                                                ? formatPrice(salesReportExtra.totalSum || 0)
                                                : formatPrice(salesReportExtra.totalProfitSum || 0)
                                            : '₦0.00'}
                                    </Amount>
                                </>
                            ) : (
                                <>
                                    <SemiRulerBox>
                                        {productsReport && productsReport.length ? (
                                            colors.map((color, key) => {
                                                return (
                                                    <SemiRuler
                                                        key={key}
                                                        width={productsReport[key] ? productsReport[key].percentage : 0}
                                                        color={color}
                                                    >
                                                        {' '}
                                                    </SemiRuler>
                                                );
                                            })
                                        ) : (
                                            <Ruler />
                                        )}
                                    </SemiRulerBox>

                                    <LeftBox open={true}>
                                        <span>{salesReportExtra && salesReportExtra.totalProduct}</span>
                                        <PercentageCount top={'7'} bottom={'0'}>
                                            Products Sold
                                        </PercentageCount>
                                    </LeftBox>
                                </>
                            )}
                        </Relative>
                    </Container>
                    <Fragment>{children}</Fragment>
                </DropdownBodyActive>
            )}
        </DropdownContainer>
    );
};

DropdownList.propTypes = {
    title: string,
    simple: bool,
    salesReportExtra: object,
    sortType: object,
};
