import React, { Fragment, useEffect, useState } from 'react';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { matchPath } from 'react-router-dom';
import styled from 'styled-components';
import useRouter from 'use-react-router';

import { Loader, RippleButton, TopHeader } from '../../../../../components';
import { ScreenContainer } from '../../../../../containers/ScreenContainer';
import {
    changeOrderStatus,
    getOrderByOrderId,
} from '../../../../../redux/ducks/applications/store-front/actions/store';
import { colors } from '../../../../../styles';
import { formatCurrency } from '../../../../../utils/currency/parseBalance';
import { toTitleCase } from '../../../../../utils/toTitleCase';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import { Text } from '../../storeFront';

import { ReactComponent as MailIcon } from './assets/mail.svg';
import { ReactComponent as PhoneIcon } from './assets/phone.svg';
import StatusPopup from './statusPopup';
import { getText, getCircleColor } from './utils';

const ContactBox = styled.div`
    background: ${colors.white};
    padding: 16px;
    margin-bottom: 16px;
`;

const RefBox = styled.div`
    background: ${colors.white};
    margin-bottom: 0;
    padding: 16px 16px 23.5px 16px;
`;

const ReceivedBox = styled.div`
    background: #f8f9fb;
    padding: 8px 16px;
    display: flex;
    margin-bottom: 16px;
`;

const InnerFlex = styled.div``;

const ImageWrapper = styled.img`
    width: 44px;
    height: 44px;
    border-radius: 4px;
`;

const ItemBox = styled.div`
    display: flex;
    background: ${colors.white};
    padding: 16px 16px 21px 16px;
    margin-bottom: 5px;
`;

const Circle = styled.div`
    width: 8px;
    height: 8px;
    background: ${({ background }) => background || null};
    border-radius: 50%;
    margin-right: 11px;
    position: relative;
    top: 4px;
`;

const FlexText = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${({ bottom }) => bottom || null};
    justify-content: ${({ justifyContent }) => justifyContent || null};
`;

const ProductDiv = styled.div`
    margin-left: 16px;
    width: 100%;
`;

const AcceptedAction = styled.div`
    margin-bottom: 32px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    margin-top: ${({ top }) => top || '89px'};
`;

const StoreFrontOrderDetails = () => {
    const dispatch = useDispatch();
    const store = useSelector((state) => state.applications.storeFront.store);
    const order = useSelector((state) => state.applications.storeFront?.storeFrontMerchantOrder);
    const loading = useSelector((state) => state.applications.storeFront.loadingProducts);
    const { location } = useRouter();
    const { pathname } = location;
    const pattern = `(.*)?${'/actions/storefront/order/:id'}`;
    const match = matchPath(pathname, { path: pattern }) || {};
    const statusList = {
        accepted: 'ACCEPTED',
        declined: 'DECLINED',
        cancel: 'CANCELLED',
        delivered: 'MARK_AS_DELIVERED',
    };
    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [openDeliverPopup, setOpenDeliverPopup] = useState(false);

    useEffect(() => {
        dispatch(getOrderByOrderId(match.params.id));
    }, [dispatch, match.params.id]);

    if (loading) return <Loader />;

    return (
        <Fragment>
            {openCancelPopup && (
                <StatusPopup
                    open={openCancelPopup}
                    setOpen={() => setOpenCancelPopup(!openCancelPopup)}
                    order={order}
                    store={store}
                    status={statusList}
                    cancel
                />
            )}
            {openDeliverPopup && (
                <StatusPopup
                    open={openDeliverPopup}
                    setOpen={() => setOpenDeliverPopup(!openDeliverPopup)}
                    order={order}
                    store={store}
                    status={statusList}
                />
            )}
            <DesktopBackgroundLayout>
                <TopHeader title={'Order details'} backLink={'/my-shop/storefront/orders'} />
                <ScreenContainer color={colors.lightestGray} top={'70px'} minHeight={'100%'}>
                    {order?.status === 'ACCEPTED' && (
                        <AcceptedAction>
                            <Text size={'12px'} weight={'700'} bottom={'10px'} color={'#5C6F7F'}>
                                ORDER ACTIONS
                            </Text>
                            <ButtonWrapper top={'0px'}>
                                <RippleButton
                                    radius={'60px'}
                                    backgroundColor={colors.white}
                                    color={'#5C6F7F'}
                                    right={'16px'}
                                    height={'48px'}
                                    border={'1px solid #CBD6E0'}
                                    top={'0px'}
                                    onClick={() => setOpenCancelPopup(!openCancelPopup)}
                                >
                                    Cancel Order
                                </RippleButton>
                                <RippleButton
                                    radius={'60px'}
                                    backgroundColor={colors.lightGreen}
                                    color={'#024525'}
                                    height={'48px'}
                                    top={'0px'}
                                    onClick={() => setOpenDeliverPopup(!openDeliverPopup)}
                                >
                                    Mark as delivered
                                </RippleButton>
                            </ButtonWrapper>
                        </AcceptedAction>
                    )}
                    <Text size={'12px'} weight={'700'} bottom={'8px'} color={'#5C6F7F'}>
                        ORDER PLACED BY
                    </Text>
                    <ContactBox>
                        <Text bottom={'12px'} size={'14px'} weight={'500'} color={colors.themeTxtColor10}>
                            {toTitleCase(order?.fullName)}
                        </Text>
                        <FlexText bottom={'6px'}>
                            <MailIcon />
                            <Text size={'12px'} weight={'400'} right={'10px'} left={'8px'}>
                                Mail
                            </Text>
                            <Text size={'14px'} weight={'400'} color={colors.deepBlue}>
                                {order?.email}
                            </Text>
                        </FlexText>
                        <FlexText>
                            <PhoneIcon />
                            <Text size={'12px'} weight={'400'} right={'10px'} left={'8px'}>
                                Phone
                            </Text>
                            <Text size={'14px'} weight={'400'} color={colors.deepBlue}>
                                {order?.phoneNumber?.replace(/(\d{3})\D?(\d{4})\D?(\d{4})/, '$1 $2 $3')}
                            </Text>
                        </FlexText>
                    </ContactBox>
                    <RefBox>
                        <Text
                            bottom={'5px'}
                            size={'12px'}
                            weight={'400'}
                            color={colors.themeTxtColor10}
                        >{`Order No. ${order?.orderReference}`}</Text>
                        <FlexText justifyContent={'space-between'}>
                            <Text size={'12px'} weight={'700'} color={colors.themeTxtColor10}>
                                {formatCurrency(order?.totalCost)}
                            </Text>
                            <Text
                                size={'12px'}
                                weight={'400'}
                                color={'#5C6F7F'}
                            >{`x${order?.productsOrdered?.length} Items`}</Text>
                        </FlexText>
                    </RefBox>
                    <ReceivedBox>
                        <Circle background={getCircleColor(order?.status)} />
                        <InnerFlex>
                            <Text size={'12px'} weight={'500'} color={'#5C6F7F'}>
                                {getText(order?.status)}
                            </Text>
                            <Text transform={'uppercase'} size={'10px'} weight={'500'} color={'#5C6F7F'}>
                                {moment(new Date(order?.updatedAt ? order?.updatedAt : order?.createdAt)).format(
                                    'hh:mm A. MMM D',
                                )}
                            </Text>
                        </InnerFlex>
                    </ReceivedBox>
                    <Text size={'12px'} weight={'700'} color={'#5C6F7F'} bottom={'8px'}>
                        ORDERS
                    </Text>
                    {order?.productsOrdered?.map((item, index) => (
                        <ItemBox key={index}>
                            <ImageWrapper
                                src={item?.storeProductImageList[item.storeProductImageList.length - 3]?.imageUrl}
                            />
                            <ProductDiv>
                                <Text bottom={'5px'} size={'12px'} weight={'400'} color={colors.themeTxtColor10}>
                                    {item.name}
                                </Text>
                                <Text size={'12px'} weight={'700'} color={colors.themeTxtColor10}>
                                    {formatCurrency(item.price)}
                                </Text>
                                <Text
                                    align={'right'}
                                    color={'#5C6F7F'}
                                    size={'12px'}
                                    weight={'400'}
                                >{`x${item.quantityOrdered}`}</Text>
                            </ProductDiv>
                        </ItemBox>
                    ))}
                    {order?.status === 'PENDING' && (
                        <ButtonWrapper>
                            <RippleButton
                                height={'48px'}
                                right={'16px'}
                                backgroundColor={'#07D571'}
                                onClick={() => dispatch(changeOrderStatus(store.id, order.id, statusList.accepted))}
                            >
                                Accept Order
                            </RippleButton>
                            <RippleButton
                                color={colors.textRed}
                                height={'48px'}
                                backgroundColor={colors.white}
                                border={`1px solid ${colors.textRed}`}
                                onClick={() => dispatch(changeOrderStatus(store.id, order.id, statusList.declined))}
                            >
                                Decline Order
                            </RippleButton>
                        </ButtonWrapper>
                    )}
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default StoreFrontOrderDetails;
