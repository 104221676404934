import React, { Fragment } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { TopHeader, RippleLink } from '../../../../../components';
import {
    ArrowForward,
    MenuOption,
    MenuOptions,
    MenuOptionLogo,
    OptionName,
} from '../../../../../containers/MenuContainer';
// import USSDIcon from './assets/ussd.svg';
// import BankAppIcon from './assets/bank_app.svg';
import { Message } from '../../../../../containers/MessageContainer';
import { switchWalletUsageMode } from '../../../../../redux/ducks/account/wallet/actions';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';

import BankAccountIcon from './assets/bank_account.svg';
import DebitCardIcon from './assets/debit_card.svg';

const ChoosePaymentContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ChoosePayment = () => {
    const dispatch = useDispatch();

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Payment method'} />
                <ChoosePaymentContainer>
                    <Message align={'left'} top={'80px'} bottom={'24px'} padding={'0 16px'}>
                        Select a payment method to complete the transaction
                    </Message>
                    <MenuOptions withTitle={true}>
                        <MenuOption>
                            <RippleLink
                                to="/user/wallet_cards_all"
                                onClick={() => dispatch(switchWalletUsageMode('payment'))}
                            >
                                <MenuOptionLogo icon={DebitCardIcon} />
                                <OptionName>Debit Card</OptionName>
                                <ArrowForward />
                            </RippleLink>
                        </MenuOption>
                        <MenuOption>
                            <RippleLink
                                to="/user/wallet_withdrawal_settings"
                                onClick={() => dispatch(switchWalletUsageMode('payment'))}
                            >
                                <MenuOptionLogo icon={BankAccountIcon} />
                                <OptionName>Bank Account</OptionName>
                                <ArrowForward />
                            </RippleLink>
                        </MenuOption>
                        {/* <MenuOption>
                        <RippleLink to="/user/wallet_ussd">
                            <MenuOptionLogo icon={USSDIcon} />
                            <OptionName>USSD</OptionName>
                            <ArrowForward />
                        </RippleLink>
                    </MenuOption> */}
                        {/* <MenuOption>
                        <RippleLink to="/user/wallet_bank_app">
                            <MenuOptionLogo icon={BankAppIcon} />
                            <OptionName>Bank App</OptionName>
                            <ArrowForward />
                        </RippleLink>
                    </MenuOption> */}
                    </MenuOptions>
                </ChoosePaymentContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default ChoosePayment;
