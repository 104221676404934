import React, { Fragment, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { SuccessPage } from '../../../../components';
import { SlidingOverlay } from '../../../../containers/OverlayContainer';
import { getComplaints } from '../../../../redux/ducks/help/actions';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import ComplaintDetails from '../complaint-details';
const ComplaintSuccess = ({ open, cancel }) => {
    const dispatch = useDispatch();
    const [openComplaintDetails, setOpenComplaintDetails] = useState(false);
    const complaints = useSelector((state) => state.help.complaints);

    useEffect(() => {
        dispatch(getComplaints());
    }, [dispatch]);

    function handleClick() {
        setOpenComplaintDetails(!openComplaintDetails);
    }
    return (
        open && (
            <Fragment>
                {!openComplaintDetails && (
                    <SlidingOverlay>
                        <DesktopBackgroundLayout>
                            <SuccessPage
                                title={'Complaint Created'}
                                subtitle={'Your complaint has been succesfully logged.'}
                                doneText={'View Complaint'}
                                onDoneClick={handleClick}
                                mposition={'fixed'}
                                position={'absolute'}
                            />
                        </DesktopBackgroundLayout>
                    </SlidingOverlay>
                )}
                {openComplaintDetails && (
                    <ComplaintDetails
                        complaint={complaints[0]}
                        open={openComplaintDetails}
                        setOpen={setOpenComplaintDetails}
                        cancel={cancel}
                        colorIndex={0}
                    />
                )}
            </Fragment>
        )
    );
};

export default ComplaintSuccess;
