import React, { Fragment } from 'react';

import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { TopHeader, DropdownList } from '../../../../components';
import { Message } from '../../../../containers/MessageContainer';
import { parseTransactionsByDate } from '../../../../utils/date/parseTransactionsByDate';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';

const CommissionsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const MerchantCommissions = () => {
    let location = useLocation();
    const filteredList = location.state && parseTransactionsByDate(location.state);

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Commisions'} />
                {filteredList && filteredList.length > 0 && (
                    <CommissionsContainer>
                        <Message top={'80px'} padding={'0 16px'} align={'left'}>
                            Showing all commissions:
                        </Message>
                        {filteredList &&
                            filteredList.map((transactionsList, index) => (
                                <DropdownList
                                    key={index}
                                    title={transactionsList.date}
                                    subHeading={'count'}
                                    transactionList={transactionsList.transactions}
                                />
                            ))}
                    </CommissionsContainer>
                )}
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default MerchantCommissions;
