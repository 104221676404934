import React from 'react';

import { string, array } from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { UserAvatar, NotificationBell } from '../../../components';
import { NOTIFICATION_TRAY } from '../../../utils/mix-panel/constants';
import { mixPanel } from '../../../utils/mix-panel/mixPanel';

import { ReactComponent as OptionsSvg } from './assets/shop-options.svg';
import { ReactComponent as WifiSvg } from './assets/wifi-connection.svg';

const HeaderBar = styled.header`
    display: flex;
    flex-direction: row;
    margin: 0;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 65px;
    max-width: inherit;
    padding: 20px;
    padding-bottom: 9px;
    background: #f9fafc;
    z-index: 1000;
    @media (max-width: 576px) {
        top: 0;
    }
`;

const RightBar = styled.div`
    display: flex;
    align-items: center;
`;

const OnlineStatus = styled.div`
    width: 77px;
    height: 24px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d3fde9;
`;

const OnlineText = styled.p`
    margin: 0 0 0 8px;
    font-weight: 700px;
    font-size: 10px;
    line-height: 16px;
    color: #024525;
    text-transform: uppercase;
`;

export const Header = ({ notifications, avatar, options, optionsClick, setOpenFeatureFixes }) => {
    const history = useHistory();
    const features = useSelector((state) => state.user.features);

    const onClick = () => {
        if (!features?.notifications.status) return setOpenFeatureFixes(true);
        mixPanel.track(NOTIFICATION_TRAY, {
            'with unread notifications': notifications.some((notification) => notification.state === 'UNREAD'),
            Time: new Date().toLocaleString(),
        });
        history.push('/user/notifications');
    };

    return (
        <HeaderBar>
            {navigator.onLine && (
                <OnlineStatus>
                    <WifiSvg />
                    <OnlineText>Online</OnlineText>
                </OnlineStatus>
            )}
            <RightBar>
                <NotificationBell onClick={onClick} notifications={notifications} />
                <UserAvatar width="24px" height="24px" avatar={avatar} alt="User avatar"></UserAvatar>
                {options && <OptionsSvg style={{ marginLeft: '8px' }} onClick={optionsClick} />}
            </RightBar>
        </HeaderBar>
    );
};

Header.propTypes = {
    avatar: string,
    notifications: array,
};
