import localForage from 'localforage';

export const clearStorage = async () => {
    localStorage.removeItem('persist:root');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    await localForage.clear();
};

export const checkUserExistInLocalStorage = (id) => {
    let saved = (localStorage.getItem('forgotPassword') && JSON.parse(localStorage.getItem('forgotPassword'))) || [];
    return saved.find((value) => value === id);
};

export const saveUserIdInLocalStorage = (id) => {
    let saved = (localStorage.getItem('forgotPassword') && JSON.parse(localStorage.getItem('forgotPassword'))) || [];
    saved = [...saved, id];
    localStorage.setItem('forgotPassword', JSON.stringify(saved));
};

export const removeUserIdFromLocalStorage = (id) => {
    let saved = (localStorage.getItem('forgotPassword') && JSON.parse(localStorage.getItem('forgotPassword'))) || [];
    const FilteredIds = saved.filter((value) => value === !id);
    localStorage.setItem('forgotPassword', JSON.stringify(FilteredIds));
};
