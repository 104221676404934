import React from 'react';

import styled from 'styled-components';

const CustomTable = styled.div`
    width: 90%;
    border-radius: 8px;
    border: 1px solid #f0f0f0;
    margin: auto;
`;

const TableHeader = styled.div`
    display: flex;
`;

const TableBody = styled.div`
    display: flex;
`;
const TableCell = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    color: #56636d;
    flex: 1;
    padding: 10px;
`;

const HeaderCell = styled(TableCell)`
    font-size: 10px;
    border-bottom: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    color: #56636d;
    flex: 1;
    text-align: center;
    font-weight: 400;
`;

export const Table = ({ header, body }) => {
    return (
        <CustomTable>
            <TableHeader>
                {header.map((cell) => (
                    <HeaderCell key={cell}>{cell}</HeaderCell>
                ))}
            </TableHeader>
            {body.map((el, index) => (
                <TableBody key={index}>
                    {Object.keys(el).map((val) => (
                        <TableCell key={val}>{el[val]}</TableCell>
                    ))}
                </TableBody>
            ))}
        </CustomTable>
    );
};
