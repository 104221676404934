import { USER_LOGOUT } from '../../user/constants';

import { RETRIEVING_ORDERS, RETRIEVED_ORDERS, RETRIEVAL_ERROR, SAVE_RETRIEVED_ORDERS } from './constants';

const initialState = {
    isLoading: false,
    errorMsg: '',
    orders: [],
};

export default function merchlistReducer(state = initialState, action) {
    switch (action.type) {
        case RETRIEVING_ORDERS: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case RETRIEVED_ORDERS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case RETRIEVAL_ERROR: {
            return {
                ...state,
                isLoading: false,
                errorMsg: action.payload,
            };
        }

        case SAVE_RETRIEVED_ORDERS: {
            const savedOrders = state.orders.slice();

            return {
                ...state,
                orders: savedOrders.concat(action.payload),
            };
        }

        case USER_LOGOUT: {
            return {
                isLoading: false,
                errorMsg: '',
                orders: [],
            };
        }

        default: {
            return state;
        }
    }
}
