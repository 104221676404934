import React, { Fragment, useEffect, useState } from 'react';

import { Formik, Form } from 'formik';
import { string, func, bool } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import {
    Loader,
    TopHeader,
    RippleButton,
    InputWithLabel,
    SelectBox,
    SuccessBadge,
    TextInputWithLabel,
} from '../../../../../../components';
import { MerchantNewDetailsLoader } from '../../../../../../components/loader';
import { Close } from '../../../../../../containers/HeaderContainer';
import { InputBlock } from '../../../../../../containers/InputContainer';
import { ScreenContainer } from '../../../../../../containers/ScreenContainer';
import { merchbuyActions } from '../../../../../../redux/ducks/applications/merchbuy/actions';
import * as actions from '../../../../../../redux/ducks/applications/my-shop/actions/customers';
import { colors } from '../../../../../../styles';
import { delay, toTitleCase, upperCaseToTitleCase } from '../../../../../../utils/toTitleCase';
import ConstantInfo from '../../../../merchbuy/assets/constant-info.svg';
import { ReactComponent as YourAddressIcon } from '../../../../merchbuy/assets/your-address.svg';
import { ReactComponent as ShopIcon } from '../../../assets/shop_icon_blue.svg';
import SyncImage from '../../../assets/sync.svg';
import { Space } from '../../../styles';
import SimpleMap from '../../google-map/simpleMap';

import InfoBadge from './../../../../../../components/badges/info-badge/index';
import { UpdateDeliveryDetailsValidationSchema } from './UpdateDeliveryDetailsValidationSchema';

const Padding = styled.div`
    padding: 0 1em;
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: black;
`;

const Previous = styled.div`
    font-weight: bold;
    font-size: 14px;
    color: ${({ color }) => color || '#5C6F7F'};
    margin-top: 16px;
    padding-left: 16px;
`;

const AddressContainer = styled.div`
    background-color: ${({ bg }) => bg || '#F9FAFC'};
    width: 343px;
    height: 167px;
    border-radius: 4px;
    border: ${({ border }) => border || '1px solid #E2E9F0'};
    margin: ${({ top }) => top || '8px'};
`;
const MiniTitle = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #7a7978;
    margin-top: 16px;
    padding-left: 16px;
    width: ${({ width }) => width || null};
`;

const UpdateText = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #5c6f7f;
    align-items: center;
    text-align: center;
    margin: 30px 0 4px 0;
`;
const SyncButton = styled.div`
    font-weight: 500;
    font-size: 16px;
    color: #227eff;
    margin-left: 3%;
    padding-top: 10px;
`;
const SyncButtonWrapper = styled.div`
    background-color: white;
    background-repeat: no-repeat;
    border: 0.5px solid #64a4ff;
    align-items: center;
    text-align: center;
    height: 46px;
    width: 343px;
    margin: auto;
    border-radius: 4px;
`;
const InLineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

const MiniRight = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #7a7978;
    margin: 4% 0 0 20%;
    padding-left: 16px;
    width: ${({ width }) => width || null};
`;
const MiniContent = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #071827;
    padding-left: 16px;
    margin-top: ${({ top }) => top || '4px'};
    width: ${({ width }) => width || null};
`;
const MiniContentRight = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #071827;
    margin-left: 20%;
    padding-left: 16px;
    width: ${({ width }) => width || null};
`;

const InnerTitle = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${({ color }) => color || '#071827'};
    margin: 8px 0 5px 16px;
`;

const Subtitle = styled.div`
    font-size: 14px;
    line-height: 21px;
    color: ${colors.themeSubColor1};
    padding-top: 6px;
`;

const FullAddressHolder = styled.div`
    background: #ffffff;
    border: 1px solid #edf2f7;
    box-sizing: border-box;
    box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 12px;
    margin: 16px;
`;

const ShopAddressInfo = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #718596;
    margin-left: ${({ left }) => left || '0px'};
`;

const Divider = styled.div`
    border: 1px solid #edf2f7;
    margin: 8px 0;
`;

const ShopAddressWrapper = styled.div`
    display: flex;
`;

const InfoText = styled.div`
    font-size: 10px;
    color: ${colors.themeTextColor11};
    font-weight: 400;
    margin: 4px 0 0 5px;
`;

const checkRequiredFields = (address, lga, phoneNumber, state, city) => {
    return !address || !lga || !phoneNumber || !state;
};

export const EditDeliveryAddressDetails = ({
    open,
    cancel,
    deliveryLocation,
    getDeliveryDetails,
    isEditingContactAddress,
    handleSelectedContactAddress,
    setSelectedDeliveryInformation,
    setShowEditPopup,
    close,
    getAnyNewShopName,
}) => {
    const dispatch = useDispatch();
    const shopInfo = useSelector((state) => state.applications.myShop.shops[0]);
    const user = useSelector((state) => state.user);
    const isLoading = useSelector((state) => state.applications.myShop.isLoading);
    const [cities, setCities] = useState([]);
    const AgentDeliveryLocation =
        localStorage.getItem('deliveryLocation') && JSON.parse(localStorage.getItem('deliveryLocation'));
    const { state: AgentdeliveryState } = AgentDeliveryLocation || {};

    const { businessPhoneNumber, lga } = shopInfo || {};
    const listOfAvailableStatesAndLga = useSelector((state) => state.applications.merchbuy.availableStates);
    const lgas = useSelector((state) => state.applications.merchbuy.lga);
    const [isSelected, setIsSelected] = useState(false);
    const [coordinates, setCoordinates] = useState(deliveryLocation?.cordinates || { longitude: 0, latitude: 0 });
    const deliveryAddressEditLimit = useSelector((state) => state.applications.merchbuy.deliveryAddressEditLimit);
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [stopLoadingInfo, setStopLoadingInfo] = useState(false);
    const [updatedInfo, setUpdatedInfo] = useState({});
    const [onUpdateInfo, setOnUpdateInfo] = useState(false);
    const [showTitle, setShowTitle] = useState(true);
    const [selectedLga, setSelectedLga] = useState('');
    const [lgaChanged, setLgaChanged] = useState(false);

    const getCities = async () => {
        if (lgaChanged) {
            const response = await dispatch(merchbuyActions.getAllCities(AgentdeliveryState, selectedLga));
            response && setCities(response?.data?.content);
        } else {
            const res = await dispatch(merchbuyActions.getAllCities(AgentdeliveryState, deliveryLocation?.lga));
            res && setCities(res?.data?.content);
        }
    };

    useEffect(() => {
        getCities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryLocation.lga, selectedLga]);

    const checkDeliveryLocation = (state) => String(AgentdeliveryState).toLowerCase() === String(state).toLowerCase();

    useEffect(() => {
        dispatch(actions.fetchDeliveryDetails(deliveryLocation?.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
        getUpdatedDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUpdatedDetail = async () => {
        setLoadingInfo(true);
        const res = await dispatch(actions.updatedDeliveryDetails(deliveryLocation?.phoneNumber));
        res && setUpdatedInfo(res);
        await delay(4000);
        setLoadingInfo(false);
        setStopLoadingInfo(true);
    };

    const updateNewInfo = async () => {
        getAnyNewShopName(updatedInfo?.shops[0]?.details?.shopName);
        setStopLoadingInfo(false);
        let payload;
        payload = {
            city: deliveryLocation.city,
            cordinates: {
                longitude: coordinates?.longitude.toString(),
                latitude: coordinates?.latitude.toString(),
            },
            customerId: deliveryLocation?.id,
            deliveryInstructions: deliveryLocation?.deliveryInstruction,
            firstName: deliveryLocation?.firstName,
            lastName: deliveryLocation?.lastName,
            lga: deliveryLocation?.lga,
            middleName: deliveryLocation?.middleName,
            phoneNumber: deliveryLocation?.phoneNumber,
            shopAddress: deliveryLocation?.shopAddress,
            shopName: updatedInfo?.shops[0]?.details?.shopName,
            shopNumber: deliveryLocation?.shopNumber,
            state: deliveryLocation?.state,
        };
        await dispatch(actions.editSabiCustomers(payload, deliveryLocation?.id));
        setOnUpdateInfo(true);
        setShowTitle(false);
    };

    const handleSelectSelfAddress = (userInfo) => {
        const { address, lga, phoneNumber, state, city } = userInfo || deliveryLocation || {};

        const isRequiredDetailsField = checkRequiredFields(address, lga, phoneNumber, state, city);

        const info = {
            ...deliveryLocation,
            contactType: 'SELF',
        };

        if (!checkDeliveryLocation(state)) {
            return toast.error('State does not match shopping location, update state to your shopping location');
        }

        if (isRequiredDetailsField || !checkDeliveryLocation(state)) {
            setSelectedDeliveryInformation && setSelectedDeliveryInformation(info);
            setShowEditPopup && setShowEditPopup(true);
        } else {
            setSelectedDeliveryInformation && setSelectedDeliveryInformation(info);
        }
        getDeliveryDetails(info);
        close();
    };

    const onSubmit = (values) => {
        let payload = {
            ...values,
            deliveryInstructions: values.deliveryInstruction,
            shopName: updatedInfo?.shops[0]?.details?.shopName || deliveryLocation?.shopName,
            cordinates: {
                longitude: coordinates.longitude.toString(),
                latitude: coordinates.latitude.toString(),
            },
        };

        if (deliveryLocation && deliveryLocation?.contactType === 'SELF') {
            payload = {
                address: values.shopAddress,
                deliveryInstruction: values.deliveryInstruction || null,
                lga: values.lga,
                phoneNumber: values.msisdn,
                receiverName: `${values.firstName} ${values.lastName}`,
                firstName: values.firstName,
                lastName: values.lastName,
                state: values.state,
                city: values.city,
                shopNumber: values.shopNumber,
                shopName: updatedInfo?.shops[0]?.details?.shopName || deliveryLocation?.shopName,
            };

            handleSelectSelfAddress(payload);
            return dispatch(actions.updateMerchantDelivery(user.userId, payload, deliveryLocation.contactType));
        } else if (deliveryLocation?.contactType === 'CUSTOMER' || deliveryLocation?.id !== '') {
            handleSelectedContactAddress(payload);
            return dispatch(actions.editSabiCustomers(payload, deliveryLocation?.id));
        } else {
            handleSelectedContactAddress(payload);
            return dispatch(actions.addSabiCustomers(payload));
        }
    };

    const handleSubmit = (values, setErrors) => {
        setTimeout(() => {
            if (businessPhoneNumber) {
                const isUserPhoneNumber = businessPhoneNumber === values.msisdn;
                if (
                    (deliveryLocation && deliveryLocation.contactType !== 'SELF' && isUserPhoneNumber) ||
                    (!deliveryLocation && isUserPhoneNumber)
                ) {
                    return setErrors({
                        msisdn: 'Sorry, you cannot use your number for a customer!',
                    });
                }
            }
            getDeliveryDetails(values);
            onSubmit(values);
            cancel();
        }, 200);
    };

    const initialFormValues = {
        firstName: deliveryLocation?.firstName || deliveryLocation?.fullName || user?.firstName || '',
        lastName: deliveryLocation?.lastName || deliveryLocation?.fullName || user?.lastName || '',
        middleName: '',
        country: 'NG',
        state: AgentdeliveryState,
        lga:
            AgentdeliveryState === deliveryLocation.state
                ? deliveryLocation?.lga || deliveryLocation?.localGovt || lga || ''
                : '',
        city:
            AgentdeliveryState === deliveryLocation.state
                ? upperCaseToTitleCase(deliveryLocation?.city && deliveryLocation?.city) || ''
                : '',
        customerId: deliveryLocation?.customerId || deliveryLocation?.userId || deliveryLocation?.id || '',
        cordinates: deliveryLocation?.cordinates || '',
        msisdn: deliveryLocation?.phoneNumber || businessPhoneNumber || '',
        phoneNumber: deliveryLocation?.phoneNumber || businessPhoneNumber || '',
        shopAddress: toTitleCase(deliveryLocation?.address) || toTitleCase(deliveryLocation?.shopAddress) || '',
        deliveryInstruction: deliveryLocation?.deliveryInstruction || deliveryLocation?.deliveryInstructions || '',
        shopNumber: deliveryLocation?.shopNumber || '',
    };

    return isLoading ? (
        <Loader />
    ) : (
        open && (
            <Fragment>
                {!isSelected && (
                    <TopHeader title={'Edit Address'} noArrow noBorderBottom withSpacesHeader>
                        <Close left={'16px'} onClick={cancel} />
                    </TopHeader>
                )}
                {!isEditingContactAddress ? (
                    <ScreenContainer top="0" padding="0">
                        {!isSelected && (
                            <Fragment>
                                <Space height="71px" />
                                {user.role === 'ROLE_AGENT' && (
                                    <InfoBadge
                                        margin="0 16px 24px"
                                        weight="400"
                                        title={`Kindly note, you only have access to change your address ${deliveryAddressEditLimit} times`}
                                        marginTop="4px"
                                        message={`It has been changed ${
                                            deliveryLocation?.changeDeliveryAddressCount ||
                                            deliveryLocation?.changeAddressCount
                                        } time(s)`}
                                        color={colors.warning}
                                        backgroundColor={colors.lightYellowBg}
                                        top="4px"
                                        bottom="4px"
                                    />
                                )}

                                <Padding>
                                    <Title>{'Update Your Address'}</Title>
                                    <Subtitle>Kindly update your delivery address.</Subtitle>
                                </Padding>
                            </Fragment>
                        )}
                        <Formik
                            initialValues={initialFormValues}
                            validationSchema={UpdateDeliveryDetailsValidationSchema}
                            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
                        >
                            {({ errors, touched, values, handleChange, setFieldValue, initialValues, setErrors }) => {
                                return (
                                    <Form>
                                        <InputBlock>
                                            <Padding>
                                                <InputWithLabel
                                                    label={'State'}
                                                    type={'text'}
                                                    value={values.state}
                                                    placeholder={'State'}
                                                    textColor={colors.themeTextColor11}
                                                    bottom="8px"
                                                    top="8px"
                                                    size="16px"
                                                    weight="500"
                                                    height="56px"
                                                    name="state"
                                                    options={listOfAvailableStatesAndLga}
                                                    handleChange={(selected) => {
                                                        setFieldValue('state', selected.label);
                                                        setFieldValue('lga', '');
                                                        setFieldValue('city', '');
                                                    }}
                                                    disabled
                                                    noClearButton
                                                    valid={`${touched.shopAddress && !errors.shopAddress}`}
                                                    errors={
                                                        touched && touched.shopAddress && errors && errors.shopAddress
                                                    }
                                                    setFieldValue={setFieldValue}
                                                    initialValues={initialValues}
                                                />
                                                <InLineContainer>
                                                    <img
                                                        src={ConstantInfo}
                                                        alt=""
                                                        style={{ margin: '15px 0 16px 16px' }}
                                                    />
                                                    <InfoText>
                                                        Delivery state is fixed to your current shopping location
                                                    </InfoText>
                                                </InLineContainer>
                                                <SelectBox
                                                    placeholder={'local government'}
                                                    value={values.lga}
                                                    top="8px"
                                                    title="Select L.G.A"
                                                    height="90%"
                                                    isSelected={isSelected}
                                                    options={lgas}
                                                    setIsSelected={setIsSelected}
                                                    handleChange={(selected) => {
                                                        setFieldValue('lga', selected.name);
                                                        setSelectedLga(selected.name);
                                                        setLgaChanged(true);
                                                    }}
                                                    valid={`${!touched.lga && !errors.lga}`}
                                                    errors={touched && touched.lga && errors && errors.lga}
                                                />
                                                <SelectBox
                                                    name={'city'}
                                                    placeholder={'Select City'}
                                                    value={upperCaseToTitleCase(values.city)}
                                                    top="8px"
                                                    height="90%"
                                                    title="Select City"
                                                    isSelected={isSelected}
                                                    setIsSelected={setIsSelected}
                                                    options={cities}
                                                    handleChange={handleChange}
                                                    valid={`${!touched.city && !errors.city}`}
                                                    errors={touched && touched.city && errors && errors.city}
                                                />
                                                <TextInputWithLabel
                                                    label={'Street Name'}
                                                    type={'text'}
                                                    value={values.shopAddress}
                                                    placeholder={'Street Name'}
                                                    bottom="8px"
                                                    top="8px"
                                                    height="56px"
                                                    name="shopAddress"
                                                    noClearButton
                                                    valid={`${touched.shopAddress && !errors.shopAddress}`}
                                                    errors={
                                                        touched && touched.shopAddress && errors && errors.shopAddress
                                                    }
                                                    setFieldValue={setFieldValue}
                                                    initialValues={initialValues}
                                                />
                                            </Padding>

                                            <FullAddressHolder>
                                                <ShopAddressWrapper>
                                                    <YourAddressIcon />
                                                    <ShopAddressInfo left="6px">Your Shop address</ShopAddressInfo>
                                                </ShopAddressWrapper>
                                                <Divider />
                                                <ShopAddressInfo>
                                                    {`
                                                    ${values.shopNumber}
                                                    ${`${upperCaseToTitleCase(values.shopAddress)} `}
                                                    ${`${values.city.toLowerCase()}, `}
                                                    ${`${values.lga.toLowerCase()} LGA,`}
                                                    ${`${values.state} state. `}
                                                `}
                                                </ShopAddressInfo>
                                            </FullAddressHolder>
                                            <InnerTitle>Drag the marker to the exact location</InnerTitle>
                                            <Padding>
                                                <SimpleMap
                                                    coordinates={coordinates}
                                                    setCoordinates={setCoordinates}
                                                    address={`${values.shopNumber} ${values.shopAddress} ${values.city} ${values.lga} ${values.state}`}
                                                />
                                            </Padding>
                                            <Padding>
                                                <RippleButton type="submit" bottom="32px">
                                                    Update
                                                </RippleButton>
                                            </Padding>
                                        </InputBlock>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </ScreenContainer>
                ) : (
                    <ScreenContainer top="0" padding="16">
                        <Padding>
                            <Title style={{ marginTop: '20%' }}>Merchant’s Information</Title>
                            {onUpdateInfo && (
                                <SuccessBadge
                                    margin="0"
                                    position="absolute"
                                    badgePadding="8px 16px 16px 0px"
                                    width="92%"
                                    color={colors.darkGreen2}
                                    height="52px"
                                    radius="0px"
                                    message={
                                        'Merchant details succesfully synced, check other details and save when done.'
                                    }
                                    showIcon={false}
                                    onCancelClick={() => setOnUpdateInfo(false)}
                                />
                            )}
                            {stopLoadingInfo &&
                                updatedInfo?.shops[0]?.details?.shopName &&
                                deliveryLocation.shopName !== updatedInfo?.shops[0]?.details?.shopName && (
                                    <AddressContainer
                                        top={'8px 0'}
                                        border={'1px solid #64A4FF'}
                                        bg={colors.setting.userTypeBg}
                                    >
                                        <Previous color={colors.themeTextColor10}>New Information</Previous>
                                        <MiniTitle>Shop Name</MiniTitle>
                                        <MiniContent>
                                            {toTitleCase(updatedInfo?.shops[0]?.details?.shopName)}
                                        </MiniContent>
                                        <InLineContainer>
                                            <MiniTitle width="50%">First Name</MiniTitle>
                                            <MiniRight width="50%">Last Name</MiniRight>
                                        </InLineContainer>
                                        <InLineContainer>
                                            <MiniContent width="50%">{deliveryLocation?.firstName}</MiniContent>
                                            <MiniContentRight width="50%">
                                                {deliveryLocation?.lastName}
                                            </MiniContentRight>
                                        </InLineContainer>
                                    </AddressContainer>
                                )}

                            <AddressContainer top="8px 0">
                                {showTitle && loadingInfo && <Previous>Previous Information</Previous>}
                                <MiniTitle>Shop Name</MiniTitle>
                                <MiniContent top="10px">{toTitleCase(deliveryLocation?.shopName)}</MiniContent>
                                <InLineContainer>
                                    <MiniTitle width="50%">First Name</MiniTitle>
                                    <MiniRight width="50%">Last Name</MiniRight>
                                </InLineContainer>
                                <InLineContainer>
                                    <MiniContent width="50%">{toTitleCase(deliveryLocation?.firstName)}</MiniContent>
                                    <MiniContentRight width="50%">
                                        {toTitleCase(deliveryLocation?.lastName)}
                                    </MiniContentRight>
                                </InLineContainer>
                            </AddressContainer>
                        </Padding>
                        {loadingInfo && (
                            <>
                                <div>
                                    <MerchantNewDetailsLoader bg="none" margin="-4% 0 0 -2%" padding="0" height="0" />
                                    <UpdateText>
                                        We will let you know if the merchant has an <br /> updated personal information
                                    </UpdateText>
                                </div>
                            </>
                        )}
                        {stopLoadingInfo &&
                            updatedInfo?.shops[0]?.details?.shopName &&
                            deliveryLocation.shopName !== updatedInfo?.shops[0]?.details?.shopName && (
                                <>
                                    <UpdateText>
                                        Merchant has updated details. Sync to update
                                        <br /> your address book
                                    </UpdateText>
                                    <SyncButtonWrapper onClick={() => updateNewInfo()}>
                                        <InLineContainer>
                                            <img
                                                src={SyncImage}
                                                alt=""
                                                style={{ marginLeft: '40%', paddingTop: '10px' }}
                                            />
                                            <SyncButton>Sync</SyncButton>
                                        </InLineContainer>
                                    </SyncButtonWrapper>
                                </>
                            )}
                        {!isSelected && (
                            <Fragment>
                                <Space height="30px" />
                                <Padding>
                                    <Title>{"Update Merchant's Address"}</Title>
                                </Padding>
                                <Padding>
                                    {user.role === 'ROLE_AGENT' && (
                                        <InfoBadge
                                            margin="2% 0 0 -0.5%"
                                            width={'343px'}
                                            weight="400"
                                            title={`Kindly note, you only have access to change your address ${deliveryAddressEditLimit} times`}
                                            marginTop="4px"
                                            message={`It has been changed ${
                                                deliveryLocation?.changeDeliveryAddressCount ||
                                                deliveryLocation?.changeAddressCount
                                            } time(s)`}
                                            color={colors.warning}
                                            backgroundColor={colors.lightYellowBg}
                                            top="4px"
                                            bottom="4px"
                                        />
                                    )}
                                </Padding>
                            </Fragment>
                        )}
                        <Formik
                            initialValues={initialFormValues}
                            validationSchema={UpdateDeliveryDetailsValidationSchema}
                            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
                        >
                            {({ errors, touched, values, handleChange, setFieldValue, initialValues, setErrors }) => {
                                return (
                                    <Form>
                                        <InputBlock>
                                            <Space height="16px" />
                                            <InnerTitle color={colors.themeTextColor11}>
                                                ENTER ADDRESS DETAILS
                                            </InnerTitle>
                                            <Padding>
                                                <SelectBox
                                                    placeholder={'State'}
                                                    value={values.state}
                                                    top="0px"
                                                    title="Select State"
                                                    height="90%"
                                                    disabled
                                                    isSelected={isSelected}
                                                    setIsSelected={setIsSelected}
                                                    options={listOfAvailableStatesAndLga}
                                                    handleChange={(selected) => {
                                                        setFieldValue('state', selected.label);
                                                        setFieldValue('lga', '');
                                                        setFieldValue('city', '');
                                                    }}
                                                    valid={`${!touched.state && !errors.state}`}
                                                    errors={touched && touched.state && errors && errors.state}
                                                />
                                            </Padding>
                                            <InLineContainer>
                                                <img
                                                    src={ConstantInfo}
                                                    alt=""
                                                    style={{ margin: '4px 0 0 33px' }}
                                                    width="14"
                                                    height="14"
                                                />
                                                <InfoText>
                                                    Delivery state is fixed to your current shopping location
                                                </InfoText>
                                            </InLineContainer>
                                            <Padding>
                                                <SelectBox
                                                    placeholder={'Local government'}
                                                    value={values.lga}
                                                    top="8px"
                                                    title="Select L.G.A"
                                                    height="90%"
                                                    isSelected={isSelected}
                                                    setIsSelected={setIsSelected}
                                                    options={lgas}
                                                    handleChange={(selected) => {
                                                        setFieldValue('lga', selected.name);
                                                        setSelectedLga(selected.name);
                                                        setLgaChanged(true);
                                                    }}
                                                    valid={`${!touched.lga && !errors.lga}`}
                                                    errors={touched && touched.lga && errors && errors.lga}
                                                />
                                                <SelectBox
                                                    name={'city'}
                                                    placeholder={'Select City'}
                                                    value={upperCaseToTitleCase(values.city)}
                                                    top="8px"
                                                    height="90%"
                                                    title="Select City"
                                                    isSelected={isSelected}
                                                    setIsSelected={setIsSelected}
                                                    options={cities}
                                                    handleChange={handleChange}
                                                    valid={`${!touched.city && !errors.city}`}
                                                    errors={touched && touched.city && errors && errors.city}
                                                />
                                            </Padding>
                                            <Padding>
                                                <TextInputWithLabel
                                                    label={'Street Address'}
                                                    type={'text'}
                                                    height={'56px'}
                                                    value={values.shopAddress}
                                                    placeholder={'Street Address'}
                                                    bottom="8px"
                                                    top="8px"
                                                    name="shopAddress"
                                                    valid={`${touched.shopAddress && !errors.shopAddress}`}
                                                    errors={
                                                        touched && touched.shopAddress && errors && errors.shopAddress
                                                    }
                                                    setFieldValue={setFieldValue}
                                                    initialValues={initialValues}
                                                />
                                            </Padding>

                                            <FullAddressHolder>
                                                <ShopAddressWrapper>
                                                    <ShopIcon />
                                                    <ShopAddressInfo left="6px">Your Shop address</ShopAddressInfo>
                                                </ShopAddressWrapper>
                                                <Divider />
                                                <ShopAddressInfo>
                                                    {`
                                                ${values.shopNumber}
                                                ${`${upperCaseToTitleCase(values.shopAddress)} `}
                                                ${`${values.city.toLowerCase()}, `}
                                                ${`${values.lga.toLowerCase()} LGA,`}
                                                ${`${values.state} sta. `}
                                            `}
                                                </ShopAddressInfo>
                                            </FullAddressHolder>
                                            <InnerTitle>Drag the marker to the exact location</InnerTitle>
                                            <Padding>
                                                <SimpleMap
                                                    coordinates={coordinates}
                                                    setCoordinates={setCoordinates}
                                                    address={`${values.shopNumber} ${values.shopAddress} ${values.city} ${values.lga} ${values.state}`}
                                                />
                                            </Padding>
                                            <Padding>
                                                <RippleButton type="submit">Update address</RippleButton>
                                            </Padding>
                                        </InputBlock>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </ScreenContainer>
                )}
            </Fragment>
        )
    );
};

EditDeliveryAddressDetails.propTypes = {
    open: bool,
    cancel: func,
    receiverName: string,
    shopName: string,
    streetAddress: string,
    state: string,
    lga: string,
    instruction: string,
    msisdn: string,
    getDeliveryDetails: func,
};
