import React, { Fragment, useState } from 'react';

import { Formik, Form } from 'formik';
import { bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Loader, TopHeader, PasswordGroup, RippleButton } from '../../../../components';
import { InputBlock } from '../../../../containers/InputContainer';
import { Message } from '../../../../containers/MessageContainer';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { updateUserPassword, resetPassword } from '../../../../redux/ducks/user/actions/password';
import { getInputValues } from '../../../../utils/inputs/getInputValues';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';

import { UpdatePasswordValidationSchema } from './UpdatePasswordValidationSchema';

const InputBlockWrap = styled(InputBlock)`
    & > * {
        &:first-of-type {
            margin-top: inherit;
        }
        margin-bottom: 5px;
    }
`;
const HeaderTitleWrap = styled.div`
    position: relative;
    margin-bottom: 45px;

    @media (max-width: 576px) {
        margin-top: 80px;
    }
`;
const HeaderTitle = styled.h4`
    font-size: 16px;
    color: #071827;
    font-weight: 600;
`;
const SubHeaderTitle = styled.p`
    font-size: 12px;
    font-weight: normal;
    color: #718596;
`;

const UpdatePassword = ({ isLoading, resetPassword, updateUserPassword }) => {
    const [oldPin, setOldPin] = useState(undefined);
    const [newPin, setNewPin] = useState(undefined);

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Change PIN'} />
                <ScreenContainer>
                    <Formik
                        initialValues={{
                            oldPin: '',
                            newPin: '',
                        }}
                        validationSchema={UpdatePasswordValidationSchema}
                        onSubmit={(values, { resetForm }) => {
                            setTimeout(() => {
                                if (values.oldPin === values.newPin) {
                                    return toast.error('Old PIN and New PIN are same');
                                }
                                resetForm({ oldPin: '', newPin: '' });
                                updateUserPassword(values.oldPin, values.newPin);
                            }, 1000);
                        }}
                    >
                        {({ errors, valid, touched, setFieldValue }) => (
                            <Form
                                style={{
                                    marginTop: '30px',
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '72vh',
                                }}
                            >
                                <HeaderTitleWrap>
                                    <HeaderTitle>Change you PIN</HeaderTitle>
                                    <SubHeaderTitle>Set a 6-digit PIN to secure your account</SubHeaderTitle>
                                </HeaderTitleWrap>
                                <InputBlockWrap>
                                    <Message
                                        top={'0px'}
                                        left={'5px'}
                                        bottom={'0px'}
                                        color={'#718596'}
                                        size={'13px'}
                                        weight={400}
                                    >
                                        Enter Old PIN
                                    </Message>
                                    <PasswordGroup
                                        count={6}
                                        startIndex={1}
                                        type="password"
                                        valid={valid}
                                        name="oldPin"
                                        errors={errors}
                                        touched={touched}
                                        align={'center'}
                                        enteredValue={oldPin}
                                        handleChange={(event) => {
                                            setOldPin(event.target.value);
                                            setFieldValue('oldPin', getInputValues('oldPin'));
                                        }}
                                    />
                                    <Message
                                        top={'20px'}
                                        left={'5px'}
                                        bottom={'0px'}
                                        color={'#718596'}
                                        size={'13px'}
                                        weight={400}
                                    >
                                        New PIN
                                    </Message>
                                    <PasswordGroup
                                        count={6}
                                        startIndex={7}
                                        type="password"
                                        align={'center'}
                                        valid={valid}
                                        name="newPin"
                                        errors={errors}
                                        touched={touched}
                                        enteredValue={newPin}
                                        handleChange={(event) => {
                                            setNewPin(event.target.value);
                                            setFieldValue('newPin', getInputValues('newPin'));
                                        }}
                                    />
                                </InputBlockWrap>
                                <RippleButton type="submit" top={'auto'} size={'1em'}>
                                    Save
                                </RippleButton>
                            </Form>
                        )}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

const mapStateToProps = ({ auth }) => ({
    isLoading: auth.password.isLoading,
});

UpdatePassword.propTypes = {
    isLoading: bool,
    updateUserPassword: func,
    resetPassword: func,
};

export default connect(mapStateToProps, {
    updateUserPassword,
    resetPassword,
})(UpdatePassword);
