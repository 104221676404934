import React, { Fragment, useState } from 'react';

import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { Loader, RippleButton, TopHeader, PageLogo, PasswordGroup } from '../../../../../components';
import { Message, PageTitleWrapper, PageTitle, PageSubTitle } from '../../../../../containers/MessageContainer';
import {
    ScreenContainer,
    PageProgressWrap,
    PageProgressLine,
    PageProgressStep,
    FloatingBottomSection,
} from '../../../../../containers/ScreenContainer';
import { createAgentAccount } from '../../../../../redux/ducks/auth/signup/agent/actions';
import { getInputValues } from '../../../../../utils/inputs/getInputValues';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';

import { AgentPinCreationValidationSchema } from './AgentPinCreationValidationSchema';
import PinCode from './assets/password.svg';

const CreatePin = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.auth.signup.agent.isLoading);

    const [value, setValue] = useState(undefined);
    const [confirmValue, setConfirmValue] = useState(undefined);
    const [submitAction, setSubmitAction] = useState(false);

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Agent Account'}>
                    <PageProgressWrap>
                        <PageProgressStep>6</PageProgressStep>/6
                    </PageProgressWrap>
                    <PageProgressLine lineWidth={'95%'} />
                </TopHeader>

                <ScreenContainer>
                    <PageLogo
                        top={'26px'}
                        margin={'auto'}
                        borderRadius={'0'}
                        Icon={PinCode}
                        iconWidth="72px"
                        iconHeight="72px"
                    />
                    <PageTitleWrapper mtop={'10px'} mbottom={'35px'}>
                        <PageTitle size={'16px'} top={'16px'} bottom={'4px'}>
                            Almost Done
                        </PageTitle>
                        <PageSubTitle margin={'0'}>Create a 6-digit PIN to secure your account</PageSubTitle>
                    </PageTitleWrapper>

                    <Formik
                        initialValues={{
                            password: '',
                            confirmPassword: '',
                        }}
                        validationSchema={AgentPinCreationValidationSchema}
                        onSubmit={async (values) => {
                            setSubmitAction(true);
                            await dispatch(createAgentAccount(values.password));
                            setSubmitAction(false);
                        }}
                    >
                        {({ values, valid, touched, errors, setFieldValue }) => (
                            <Form>
                                <Message color={'#718596'} weight={'400'} size={'10px'} top={'24px'} bottom={'4px'}>
                                    Password
                                </Message>
                                <PasswordGroup
                                    count={6}
                                    startIndex={1}
                                    type={'password'}
                                    valid={valid}
                                    errors={errors}
                                    name={'password'}
                                    touched={touched}
                                    align={'center'}
                                    enteredValue={value}
                                    handleChange={(event) => {
                                        setValue(event.target.value);
                                        setFieldValue('password', getInputValues('password'));
                                    }}
                                />
                                <Message color={'#718596'} weight={'400'} size={'10px'} top={'24px'} bottom={'4px'}>
                                    Confirm Password
                                </Message>
                                <PasswordGroup
                                    count={6}
                                    startIndex={7}
                                    type={'password'}
                                    valid={valid}
                                    errors={errors}
                                    name={'confirmPassword'}
                                    touched={touched}
                                    align={'center'}
                                    enteredValue={confirmValue}
                                    handleChange={(event) => {
                                        setConfirmValue(event.target.value);
                                        setFieldValue('confirmPassword', getInputValues('confirmPassword'));
                                    }}
                                />

                                <FloatingBottomSection>
                                    <RippleButton
                                        type="submit"
                                        disabled={
                                            values.password.length < 6 ||
                                            values.confirmPassword.length < 6 ||
                                            values.password !== values.confirmPassword ||
                                            submitAction
                                        }
                                    >
                                        Create Account
                                    </RippleButton>
                                </FloatingBottomSection>
                            </Form>
                        )}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default CreatePin;
