import React, { Fragment, useState, useEffect, useRef } from 'react';

import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Loader, TopHeader, PasswordGroup, ResendCodeTimer } from '../../../../components';
import { Message, Strong, Title } from '../../../../containers/MessageContainer';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { verifyOtp, resendOtp } from '../../../../redux/ducks/account/transactions/actions';
import { tick, setExpired } from '../../../../redux/ducks/timer/actions';
import { colors } from '../../../../styles';
import { insertZero } from '../../../../utils/inputs/formatPhoneNumber';
import { getInputValues } from '../../../../utils/inputs/getInputValues';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';

import { CodeCheckFormValidationSchema } from './CodeCheckFormValidationSchema';

const ForgotTransactionOtp = () => {
    const dispatch = useDispatch();
    const formRef = useRef();
    const history = useHistory();
    const phoneNumber = useSelector((state) => state.user.msisdn);
    const isLoading = useSelector((state) => state.auth.check.isLoading);
    const expired = useSelector((state) => state.timer.expired);
    const counter = useSelector((state) => state.timer.counter);
    const [value, setValue] = useState('');
    const [code, setCode] = useState('');

    useEffect(() => {
        if (code.length === 6) {
            const payload = {
                pinCode: code,
            };

            dispatch(verifyOtp(payload)).then((res) => {
                if (res?.status) {
                    history.push('/user/wallet_index/transaction-change-pin');
                } else {
                    formRef.current && formRef.current.setFieldError('code', res.message);
                }
            });
        }
    }, [dispatch, code, history]);

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Enter OTP'} noBorderBottom />
                <ScreenContainer>
                    <Title
                        top={'40px'}
                        lineHeight={'24px'}
                        textAlign={'left'}
                        size={'16px'}
                        weight={'700'}
                        color={colors.themeTxtColor10}
                    >
                        Enter OTP
                    </Title>
                    <Message
                        align={'left'}
                        lineHeight={'21px'}
                        weight={'400'}
                        top={'8px'}
                        bottom={'24px'}
                        color={colors.lightDark}
                    >
                        Kindly provide the 6-digit OTP that was sent to{' '}
                        <Strong color={'#718596'}>{insertZero(phoneNumber)}</Strong>
                    </Message>
                    <Message size={'10px'} align={'left'} top={'24px'} lineHeight={'inherit'} color={'#718596'}>
                        Enter Pin
                    </Message>
                    <Formik
                        innerRef={formRef}
                        initialValues={{
                            code: '',
                        }}
                        validationSchema={CodeCheckFormValidationSchema}
                    >
                        {({ errors, valid, touched, setFieldValue }) => {
                            return (
                                <Form>
                                    <PasswordGroup
                                        align={'center'}
                                        count={6}
                                        startIndex={1}
                                        type="number"
                                        valid={valid}
                                        name="code"
                                        errors={errors}
                                        touched={touched}
                                        enteredValue={value}
                                        handleChange={(event) => {
                                            setValue(event.target.value);
                                            setFieldValue('code', getInputValues('code'));
                                            setCode(getInputValues('code'));
                                        }}
                                    />

                                    <ResendCodeTimer
                                        counter={counter}
                                        expired={expired}
                                        showWhatsAppButton={false}
                                        resendButtonText="Resend OTP"
                                        sendUssd={() => dispatch(resendOtp())}
                                        setExpired={(condition) => dispatch(setExpired(condition))}
                                        tick={(counter) => dispatch(tick(counter))}
                                        resendCode={() => history.push('/user/wallet_index/forgot-transaction-pin')}
                                    />
                                </Form>
                            );
                        }}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default ForgotTransactionOtp;
