import React, { Fragment } from 'react';

import { bool, string, func } from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as CancelIcon } from '../../../assets/cancel2.svg';
import { Overlay } from '../../../containers/OverlayContainer';
import { ScreenContainer, ViewContainer } from '../../../containers/ScreenContainer';
import { colors } from '../../../styles';
import { PopUp, PopUpContent } from '../common';

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.boldDark};
`;

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
`;

const Padding = styled.div`
    padding: 24px;
`;

export const PhoneNumberPopup = ({ open, cancel = () => null, children, height, maxHeight }) => {
    return (
        <Fragment>
            <Overlay zIndex="9999" bgc="rgba(0, 0, 0, 0.4);">
                <PopUp padding="0" open={open} zIndex={'10000'}>
                    <PopUpContent color="#F9FAFC" radius="20px 20px 0px 0px" height={height}>
                        <ScreenContainer color="#F9FAFC" padding="0" top={'16px'}>
                            <Padding>
                                <TitleWrapper>
                                    <Title>Select Country</Title>
                                    <CancelIcon onClick={cancel} />
                                </TitleWrapper>
                            </Padding>
                            <ViewContainer overflowY="scroll" maxHeight={maxHeight} top={'0px'}>
                                {children}
                            </ViewContainer>
                        </ScreenContainer>
                    </PopUpContent>
                </PopUp>
            </Overlay>
        </Fragment>
    );
};
PhoneNumberPopup.propTypes = {
    open: bool,
    title: string,
    cancel: func,
};
