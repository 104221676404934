export const banks = [
    { value: 'zenith', label: 'Zenith - 1214403902' },
    { value: 'gtb', label: 'GTB - 0627779713' },
];

export const paymentMethods = [
    { value: 1, label: 'Bank' },
    { value: 2, label: 'Wallet' },
];

export const remitStatus = {
    REMIT: 'REMIT',
    PIN: 'PIN',
};
