import styled from 'styled-components';

import { colors } from '../../../../../styles';

export const BoldText = styled.p`
    margin: ${({ margin }) => margin || '20px 0 0'};
    font-weight: 700;
    font-size: ${({ fontSize }) => fontSize || '14px'};
    line-height: 24px;
`;

export const FadedText = styled.p`
    color: ${colors.themeTextColor11};
    font-size: ${({ fontSize }) => fontSize || '14px'};
    line-height: 21px;
    margin: ${({ margin }) => margin || '4px 0 0'};
`;

export const Bills = styled.ul`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const Bill = styled.li`
    background: ${colors.gray4};
    border-radius: 4px;
    font-size: 14px;
    color: ${colors.boldDark};
    height: ${({ height }) => height || '64px'};
    padding: 12px;
    box-sizing: border-box;
    font-weight: 500;

    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    gap: 16px;
    cursor: pointer;
`;

export const Abbr = styled.div`
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '100%'};
    border-radius: 50%;
    margin-bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ background }) => background};

    font-size: 12px;
    font-weight: 700;
`;

export const BillContainer = styled.div`
    margin: 32px 0 24px;
`;

export const BillTitle = styled.p`
    margin-top: 10px;
`;

export const TransactionWrapperDetails = styled.div`
    display: grid;
    gap: 18px;
`;

export const NetworkProviderAndAmount = styled.div`
    display: grid;
    grid-template-columns: 53px 1fr;
    margin-top: 3px;
    column-gap: 12px;
    align-items: center;
`;

export const CustomerValidationContainer = styled.div`
    height: 81px;
    width: 100%;
    background: ${colors.gray4};
    padding: 0 22px;
    margin-bottom: 10px;

    display: flex;
    align-items: center;
    gap: 11px;

    border: 1px dashed ${colors.faintGrey};
    box-shadow: 0px -20px 30px rgba(0, 0, 0, 0.03);
    border-radius: 7px;
`;

export const CustomerDetails = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CustomerReferenceBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 14px;
`;

export const LoaderContainer = styled.div`
    margin: 10px 0;
`;
