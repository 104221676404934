import { SAVE_BANNERS } from './constants';

const initialState = {
    allBanners: {},
};

export default function bannerReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_BANNERS: {
            return {
                ...state,
                allBanners: action.payload,
            };
        }

        default: {
            return state;
        }
    }
}
