import React, { Fragment } from 'react';

import styled from 'styled-components';

import { Message, Title } from '../../../../containers/MessageContainer';

import { ReactComponent as Vacation } from './assets/vacation.svg';

const Wrapper = styled.div`
    width: 100%;
    background-color: #ffffff;
    align-items: center;
    align-content: center;
    justify-items: center;
    display: grid;
    margin-top: 32px;
    border-radius: 16px;
    border: 1px solid #edf2f7;
    padding: 60px 23px 130px 23px;
`;

const BalanceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    border-radius: 16px;
    height: 96px;
    background-image: linear-gradient(94.68deg, #227eff 27.32%, #7e42f5 72.68%);
`;

const ComingSoonPage = ({ title, subtitle }) => (
    <Fragment>
        <BalanceWrapper>
            <Title size="12px" weight="400" color="#FFFFFF">
                Wallet Balance
            </Title>
            <span role="img" aria-labelledby="money">
                💸💸💸💸💸
            </span>
        </BalanceWrapper>
        <Wrapper>
            <Vacation />
            <Title top={'36px'} size="20px" weight="700" color="#0350BB">
                {title || 'Wallet is coming soon'}
            </Title>
            <Message top={'0px'} color="#718596" bottom={'24px'} weight={'400'} align={'center'}>
                {subtitle || 'Relax while we work on revamping the wallet experience'}
            </Message>
        </Wrapper>
    </Fragment>
);

export default ComingSoonPage;
