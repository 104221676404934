import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { RippleButton, SelectBox } from '../../../../components';
import { NewAmountInputWithLabel } from '../../../../components/forms/input/new-amount-input';
import loaderSvg from '../../../../components/loader/assets/loader_animation.svg';
import { getLgaByStateId } from '../../../../redux/ducks/applications/store-front/actions/store';
import { colors } from '../../../../styles';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import { ReactComponent as AddIcon } from '../assets/add.svg';
import { ReactComponent as BagIcon } from '../assets/bag.svg';
import { ReactComponent as CardIcon } from '../assets/card.svg';
import { ReactComponent as LineIcon } from '../assets/line.svg';
import { ReactComponent as LocationIcon } from '../assets/location-pin.svg';
import { ReactComponent as RingIcon } from '../assets/ring.svg';

const AddContainer = styled.div`
    height: 67px;
    background: ${colors.white};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LineIconStyled = styled(LineIcon)`
    margin: 12px 0 12px 5px;
`;

const Container = styled.div`
    background: ${({ hasData }) => hasData && colors.white};
    padding: ${({ hasData }) => hasData && '20px 16px 10px 16px'};
`;

const AddText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.deepBlue};
    margin-left: 11.33px;
`;

const FormTitle = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.boldDark};
    margin-top: 36px;
`;

const FormDesc = styled.div`
    line-height: 21px;
    font-size: 14px;
    color: ${colors.lightDark};
    margin: 8px 0 16px 0;
`;

const DeliveryItem = styled.div`
    height: 156px;
    background: ${colors.white};
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const LocationText = styled.div`
    font-size: 14px;
    line-height: 21px;
    color: ${colors.themeTextColor11};
    margin-left: 8px;
`;

const Space = styled.div`
    height: 10px;
`;

const OptionContainer = styled.div`
    height: 56px;
    border: 1px solid ${colors.gray4};
    border-radius: 4px;
    background: ${colors.white};
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-top: ${({ index }) => index === 1 && '16px'};
`;

const OptionInnerContainer = styled.div`
    display: flex;
    align-items: center;
`;

const OptionInnerContainerText = styled.div`
    font-size: 14px;
    line-height: 21px;
    color: ${colors.boldDark};
    margin-left: 14px;
`;

const FooterWrapper = styled.div`
    height: 72px;
    position: fixed;
    background: #f7f8f9;
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 33px;
    width: 100%;
    max-width: 375px;
    margin: auto;
    @media (max-width: 576px) {
        max-width: 100%;
    }
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

const BottomContainer = styled.div`
    height: 43px;
    background: #edf2f7;
    border: 1px solid #edf2f7;
    border-radius: 0px 0px 5px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 16px;
`;

const Amount = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.boldDark};
`;

const ActionContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Edit = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #3c48fc;
    margin-right: 24px;
`;

const Delete = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #e01a00;
`;

const Padding = styled.div`
    padding: 12px;
`;

const SpaceFooter = styled.div`
    height: 150px;
`;

const HalfLoader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    margin-top: 50%;
`;

const SecondStep = ({
    handleDeleteDeliveryFees,
    deliveries,
    payments,
    setSelectedDelivery,
    setOpenPayment,
    openAddress,
    chooseAddress,
    price,
    data,
    setData,
    handlePrice,
    selectedDelivery,
    handleEditDeliveryFees,
}) => {
    const dispatch = useDispatch();
    const country = useSelector((state) => state.applications.storeFront.country);
    const states = useSelector((state) => state.applications.storeFront.states);
    const lga = useSelector((state) => state.applications.storeFront.lga);
    const loading = useSelector((state) => state.applications.storeFront.loadingProducts);
    const [state, setState] = useState({ name: '' });
    const [startPoint, setStartPoint] = useState({ name: '' });
    const [endPoint, setEndPoint] = useState({ name: '' });
    const findStateId = states.filter((data) => data.name === state.name);
    const findStartId = lga.filter((data) => data.name === startPoint.name);
    const findEndId = lga.filter((data) => data.name === endPoint.name);

    useEffect(() => {
        setState({ label: selectedDelivery?.state || '' });
        setStartPoint({ label: selectedDelivery?.startPoint || '' });
        setEndPoint({ label: selectedDelivery?.endPoint || '' });
    }, [selectedDelivery]);

    const handleAddLocation = () => {
        if (selectedDelivery?.id) {
            handleEditDeliveryFees({
                countryId: country[0].id,
                startPointId: [startPoint.name, findStartId[0].id],
                endPointId: [endPoint.name, findEndId[0].id],
                stateId: findStateId[0].id,
                fee: price,
                id: Math.random(),
            });
        } else {
            const newInfo = {
                ...data,
                deliveryFees: [
                    ...data.deliveryFees,
                    {
                        countryId: country[0].id,
                        startPointId: [startPoint.name, findStartId[0].id],
                        endPointId: [endPoint.name, findEndId[0].id],
                        stateId: findStateId[0].id,
                        fee: price,
                        id: Math.random(),
                    },
                ],
            };
            setData(newInfo);
        }
        handlePrice();
        openAddress();
        setState({ label: '' });
        setStartPoint({ label: '' });
        setEndPoint({ label: '' });
        setSelectedDelivery({});
    };

    useEffect(() => {
        if (!chooseAddress) {
            handlePrice();
            setState({ label: '' });
            setStartPoint({ label: '' });
            setEndPoint({ label: '' });
            setSelectedDelivery({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chooseAddress]);

    useEffect(() => {
        if (state.name) {
            const lgaContent = states.filter((data) => data.name === state.name);
            dispatch(getLgaByStateId(lgaContent[0]?.id));
        }
    }, [dispatch, state.name, states]);

    const isDisable = state.name && startPoint.name && endPoint.name && price > 0 ? false : true;

    if (loading)
        return (
            <HalfLoader>
                <img src={loaderSvg} alt="Loading..."></img>
            </HalfLoader>
        );

    return (
        <div>
            {chooseAddress ? (
                <div>
                    <SelectBox
                        value={state.name}
                        handleChange={setState}
                        placeholder="State"
                        options={states}
                        bottom="16px"
                        height="90%"
                    />
                    <SelectBox
                        value={startPoint.name}
                        handleChange={setStartPoint}
                        placeholder="Start Point"
                        options={lga}
                        bottom="16px"
                        height="90%"
                    />
                    <SelectBox
                        value={endPoint.name}
                        handleChange={setEndPoint}
                        placeholder="End Point"
                        options={lga}
                        bottom="16px"
                        height="90%"
                    />
                    <NewAmountInputWithLabel
                        value={price}
                        label={'Price'}
                        placeholder={'Price'}
                        name="price"
                        minHeight="56px"
                    />
                    <SpaceFooter />
                    <FooterWrapper>
                        <RippleButton
                            type={'button'}
                            top={'0'}
                            height="55px"
                            disabled={isDisable}
                            onClick={handleAddLocation}
                        >
                            Add Fee
                        </RippleButton>
                    </FooterWrapper>
                </div>
            ) : (
                <div>
                    {deliveries.map((data, index) => (
                        <DeliveryItem key={index}>
                            <Padding>
                                <Flex>
                                    <RingIcon />
                                    <LocationText>{data?.startPointId[0]}</LocationText>
                                </Flex>
                                <LineIconStyled />
                                <Flex>
                                    <LocationIcon />
                                    <LocationText>{data?.endPointId[0]}</LocationText>
                                </Flex>
                            </Padding>
                            <BottomContainer>
                                <Amount>{formatPrice(data?.fee)}</Amount>
                                <ActionContainer>
                                    <Edit
                                        onClick={() => {
                                            setSelectedDelivery(data);
                                            openAddress();
                                        }}
                                    >
                                        Edit
                                    </Edit>
                                    <Delete onClick={() => handleDeleteDeliveryFees(data?.id)}>Delete</Delete>
                                </ActionContainer>
                            </BottomContainer>
                        </DeliveryItem>
                    ))}
                    <Space />
                    <AddContainer onClick={openAddress}>
                        <AddIcon />
                        <AddText>Add delivery fee</AddText>
                    </AddContainer>
                    <FormTitle>Payment Mode</FormTitle>
                    <FormDesc>Select the payment mode you want your customers to pay with</FormDesc>
                    <Container hasData={payments.length > 0}>
                        {payments?.map((data, index) => (
                            <OptionContainer index={index} key={index}>
                                <OptionInnerContainer>
                                    {index === 0 ? <CardIcon /> : <BagIcon />}
                                    <OptionInnerContainerText>{data[0]}</OptionInnerContainerText>
                                </OptionInnerContainer>
                            </OptionContainer>
                        ))}
                        <Space />
                        <AddContainer onClick={() => setOpenPayment(true)}>
                            <AddIcon />
                            <AddText>Add payment mode</AddText>
                        </AddContainer>
                    </Container>
                </div>
            )}
        </div>
    );
};

export default SecondStep;
