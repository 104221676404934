import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import loaderSvg from '../../../../components/loader/assets/new-loader.png';
import { getFaqByCategories } from '../../../../redux/ducks/help/actions';
import { colors } from '../../../../styles';

import { Dropdown } from './dropdown';

import { Text } from '.';

const FaqContainer = styled.div`
    margin-bottom: 8px;
    > div {
        &:not(:first-of-type) {
            > div {
                &:first-child {
                    border-top: 1px solid ${colors.gray4};
                }
            }
        }
    }
`;

const HalfLoader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background: #ffffff;
    margin-top: 50%;
`;

const FaqItem = ({ id, filteredSample }) => {
    const faq = useSelector((state) => state.help.faq);
    const isLoading = useSelector((state) => state.help.isLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFaqByCategories(id));
    }, [dispatch, id]);

    if (isLoading)
        return (
            <HalfLoader>
                <img src={loaderSvg} alt="Loading..." width="100" height="100"></img>
            </HalfLoader>
        );

    return (
        <FaqContainer>
            {faq &&
                faq.length > 0 &&
                filteredSample(faq).map((data, index) => (
                    <Dropdown key={index} title={data.title} list={filteredSample(faq)} top={'24px'} bottom={'4px'}>
                        <Text>{data.summary}</Text>
                    </Dropdown>
                ))}
        </FaqContainer>
    );
};

export default FaqItem;
