import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { ConfirmCartPopupDialog, RippleButton, TopHeader } from '../../../../components';
import { colors } from '../../../../styles';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import { formatCurrency } from '../../../../utils/currency/parseBalance';
import History from '../../../../utils/History';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { ReactComponent as DeleteIcon } from '../../merchbuy/shopping-cart/assets/deleteIcon.svg';
import StoreFrontFooter from '../footer';

import { SetAvailableQuantity } from './set-available-quantity';

const Space = styled.div`
    height: 24px;
`;

const Body = styled.div`
    margin: 65px 16px 0 16px;
`;

const CartWrapper = styled.div`
    width: 100%;
    height: 130px;
    background: ${colors.white};
    box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 8px;
`;

const TopWrapper = styled.div`
    display: flex;
`;

const CartImage = styled.div`
    min-width: 44px;
    height: 44px;
    background-image: ${({ image }) => `url(${image})`};
    background-color: #e2e9f0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px;
`;

const TitleWrapper = styled.div`
    margin-left: 15px;
`;

const Title = styled.div`
    font-size: 12px;
    line-height: 18px;
    color: ${colors.boldDark};
    opacity: 0.8;
`;

const Amount = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: ${colors.boldDark};
    margin-top: 4px;
`;

const BottomWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DeleteWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Remove = styled.div`
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: ${colors.boldDark};
    opacity: 0.8;
    margin-left: 10px;
`;

const TotalWrapper = styled.div`
    height: 71px;
    background: ${colors.white};
    box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.04);
    margin-top: 24px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
`;

const Subtotal = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.boldDark};
`;

const Comment = styled.div`
    font-size: 12px;
    line-height: 18px;
    color: #5c6f7f;
`;

const ButtonWrapper = styled.div`
    margin: 13px 16px;
`;

const StoreFrontCart = () => {
    const [carts, setCarts] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [itemToBeDeleted, setItemToBeDeleted] = useState('');
    const [itemToBeDeletedId, setItemToBeDeletedId] = useState('');
    const [subTotal, setSubTotal] = useState(
        carts.reduce((accum, current) => Number(accum) + Number(current.price) * Number(current.quantity), 0),
    );
    const updateCart = (data) => {
        const newSubTotal = data.reduce(
            (accum, current) => Number(accum) + Number(current.price) * Number(current.quantity),
            0,
        );
        localStorage.setItem('storeFrontCart', JSON.stringify(data));
        setSubTotal(newSubTotal);
    };

    const deleteItems = async () => {
        const newCarts = carts.filter((cart) => cart.id !== itemToBeDeletedId);
        const newSubTotal = [...newCarts].reduce(
            (accum, current) => Number(accum) + Number(current.price) * Number(current.quantity),
            0,
        );
        localStorage.setItem('storeFrontCart', JSON.stringify(newCarts));
        setCarts(newCarts);
        setSubTotal(newSubTotal);
        setItemToBeDeleted('');
        setItemToBeDeletedId('');
    };

    const setToBeDeleted = (cart) => {
        setItemToBeDeletedId(cart.id);
        setItemToBeDeleted(cart.name);
        setConfirmDelete(true);
    };

    useEffect(() => {
        const carts = JSON.parse(localStorage.getItem('storeFrontCart'));
        if (carts) {
            setCarts(carts);
            const newSubTotal = carts.reduce(
                (accum, current) => Number(accum) + Number(current.price) * Number(current.quantity),
                0,
            );
            setSubTotal(newSubTotal);
        }
    }, []);
    return (
        <DesktopBackgroundLayout fullHeight bgColor="#F9FAFC">
            <TopHeader withCancelIcon={true} noBorderBottom backgroundColor="#F9FAFC" />
            <Body>
                {carts.map((cart, index) => (
                    <CartWrapper key={index}>
                        <TopWrapper>
                            <CartImage image={cart?.images[0]?.imageUrl} />
                            <TitleWrapper>
                                <Title>{cart?.name}</Title>
                                <Amount>{formatCurrency(cart.price)}</Amount>
                            </TitleWrapper>
                        </TopWrapper>
                        <BottomWrapper>
                            <DeleteWrapper onClick={() => setToBeDeleted(cart)}>
                                <DeleteIcon />
                                <Remove>REMOVE</Remove>
                            </DeleteWrapper>
                            <SetAvailableQuantity
                                productQuantity={cart?.quantity}
                                productId={cart?.id}
                                carts={carts}
                                availableQuantity={cart?.quantityInStock}
                                updateCart={updateCart}
                            />
                        </BottomWrapper>
                    </CartWrapper>
                ))}
            </Body>
            <TotalWrapper>
                <div>
                    <Subtotal>Total</Subtotal>
                    <Comment>Delivery fee not included</Comment>
                </div>
                <Subtotal>{formatPrice(subTotal)}</Subtotal>
            </TotalWrapper>
            <ButtonWrapper>
                <RippleButton
                    onClick={() => (carts.length > 0 ? History.push('/actions/store_front/store_link/checkout') : null)}
                    disabled={carts.length === 0}
                    height="48px"
                    top="0"
                >
                    Checkout
                </RippleButton>
            </ButtonWrapper>
            <Space />
            <StoreFrontFooter />
            <ConfirmCartPopupDialog
                open={confirmDelete}
                title="Remove from Cart"
                confirmationTextBegining="Are you sure you want to remove"
                confirmationTextEnding="from your cart?"
                productName={itemToBeDeleted}
                answers={[
                    {
                        variant: 'Cancel',
                        action: () => {
                            setConfirmDelete(false);
                        },
                    },
                    {
                        variant: 'OK',
                        action: () => {
                            deleteItems();
                            setConfirmDelete(false);
                        },
                    },
                ]}
            />
        </DesktopBackgroundLayout>
    );
};

export default StoreFrontCart;
