import { toast } from 'react-toastify';

import { currentAPI, axios } from '../../../../../config';
import { getUserAgentByReferralCode } from '../../../user/actions';
import { SAVE_USER_REFERRAL_CODE } from '../../../user/constants';
import { REQUEST_IN_PROGRESS, REQUEST_DONE } from '../constants';

export const sendAgentReferral = (agentCodeToShare) => async (dispatch) => {
    if (!navigator.onLine) {
        toast.error('You can not perform this action offline...');
        return;
    }

    dispatch({ type: REQUEST_IN_PROGRESS });

    try {
        const sendAgentReferralResponse = await axios.patch(
            `${currentAPI}/api/users/merchantAgent/${agentCodeToShare.referralCode}`,
            null,
        );

        if (sendAgentReferralResponse.status === 200) {
            const { referralCode } = sendAgentReferralResponse.data;

            referralCode &&
                dispatch({
                    type: SAVE_USER_REFERRAL_CODE,
                    payload: referralCode,
                });

            referralCode && (await dispatch(getUserAgentByReferralCode(referralCode)));

            toast.success('Your agent information was successfully updated');
            dispatch({ type: REQUEST_DONE });
        }
    } catch (error) {
        dispatch({ type: REQUEST_DONE });
        if (error.response && error.response.status === 404) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.message);
        }
    }
};
