import React, { Fragment, useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as SpacesLogo } from '../../../../../assets/spaces_logo.svg';
import { TopHeader, RippleButton } from '../../../../../components';
import { SubListContainer, SubList, SubListHeading, SubListValue } from '../../../../../containers/CheckoutContainer';
import { List } from '../../../../../containers/ListContainer';
import { SubTitle, SecondaryText, SecondaryStrongText } from '../../../../../containers/MessageContainer';
import { ScreenContainer, FlexCenteredBlock } from '../../../../../containers/ScreenContainer';
import { uploadContentToBucketBase64 } from '../../../../../redux/ducks/applications/my-shop/actions/sales';
import { colors } from '../../../../../styles';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import { formatDateWithDash } from '../../../../../utils/date/formatDateWithDash';
import { htmlToFormData } from '../../../../../utils/files/htmlToFormData';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ReminderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 323px;
    height: fit-content;
    border-radius: 10px;
    border: 1px solid ${colors.gray2};
    background-color: #ffffff;
    box-shadow: 0 1px 10px 0 ${colors.gray2}, 0 4px 5px 0 #26000000, 0 2px 4px -1px #26000000;
`;

const ReminderHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
    padding-top: 4px;
    margin-bottom: 16px;
`;

const ReminderTitle = styled(SubTitle)`
    font-weight: bolder;
    text-transform: uppercase;
    margin: 8px 0;
`;

const ReminderBody = styled.div`
    min-height: 50px;
`;

const ReminderTextBlock = styled.div`
    border-top: 1px solid ${colors.border.top};
    border-bottom: 1px solid ${colors.border.bottom};
    padding: 8px 16px;
`;

const TotalCheckout = styled(List)`
    padding: 24px 16px;
`;

const IncompletePayment = styled(SubListHeading)`
    font-size: 14px;
    font-weight: 500;
    color: ${colors.transactions.pending};
`;

const PaymentReminder = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const sale = location.state.sale;
    const shopId = location.state.shopId;
    const whatsAppRequestPaymentLink = location.state.whatsAppRequestPaymentLink;
    const customerName = sale.salesInfo ? sale.salesInfo.customer.name : sale.customer.name;
    const saleId = sale.salesInfo ? sale.salesInfo.id : sale.id;
    const amountOutstanding = sale.salesInfo ? sale.salesInfo.amountOutstanding : sale.amountOutstanding;
    const totalAmount = sale.salesInfo ? sale.salesInfo.totalAmount : sale.totalAmount;
    const discount = sale.salesInfo ? sale.salesInfo.discount : sale.discount;
    const salesDate = sale.salesInfo ? sale.salesInfo.localSalesDate : sale.localSalesDate;
    const shopName = useSelector((state) => state.applications.myShop.shops).find(
        (shop) => shop.id === shopId,
    ).shopName;
    const [url, setUrl] = useState('');

    useEffect(() => {
        htmlToFormData('reminder', uploadContentToBucketBase64, dispatch).then((res) => setUrl(res));
    }, [dispatch]);

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Request Payment'} withSpacesHeader />
                <ScreenContainer top={'78px'} paddingBottom={'65px'}>
                    <Container>
                        <ReminderWrapper id={'reminder'}>
                            <FlexCenteredBlock>
                                <ReminderHeader>
                                    <SpacesLogo width={'97px'} height={'21px'} />
                                    <ReminderTitle>Payment Reminder</ReminderTitle>
                                    <SubTitle>Sale No. {saleId}</SubTitle>
                                </ReminderHeader>
                                <ReminderBody>
                                    <ReminderTextBlock>
                                        <SecondaryText>
                                            Hello {customerName}, your payment of{' '}
                                            <SecondaryStrongText>{formatPrice(amountOutstanding)}</SecondaryStrongText>{' '}
                                            is still pending on the purchase made from{' '}
                                            <SecondaryStrongText>{shopName} Shop</SecondaryStrongText> on{' '}
                                            <SecondaryStrongText>{formatDateWithDash(salesDate)}</SecondaryStrongText>.
                                        </SecondaryText>
                                    </ReminderTextBlock>
                                    <TotalCheckout noBorderBottom>
                                        <SubListContainer>
                                            <SubList>
                                                <SubListHeading>Status</SubListHeading>
                                                <IncompletePayment>Incomplete Sale</IncompletePayment>
                                            </SubList>
                                            <SubList>
                                                <SubListHeading>SubTotal</SubListHeading>
                                                <SubListValue>{formatPrice(totalAmount + discount)}</SubListValue>
                                            </SubList>
                                            <SubList>
                                                <SubListHeading>Discount</SubListHeading>
                                                <SubListValue>{formatPrice(-discount)}</SubListValue>
                                            </SubList>
                                            <SubList>
                                                <SubListHeading>TOTAL</SubListHeading>
                                                <SubListValue>{formatPrice(totalAmount)}</SubListValue>
                                            </SubList>
                                        </SubListContainer>
                                    </TotalCheckout>
                                </ReminderBody>
                            </FlexCenteredBlock>
                        </ReminderWrapper>
                    </Container>
                    <RippleButton
                        type={'button'}
                        disabled={!url}
                        onClick={async () => {
                            url && window.open(`${whatsAppRequestPaymentLink} ${encodeURIComponent(url)}`);
                        }}
                    >
                        {url ? `Send to ${customerName}'s WhatsApp` : 'Generating link...'}
                    </RippleButton>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default PaymentReminder;
