import React from 'react';

import styled from 'styled-components';

import { colors } from '../../../../../styles';
import { ReactComponent as EmptyCartonSvg } from '../../assets/empty_carton.svg';
import { ReactComponent as LightBulb } from '../../assets/lightbulb.svg';

const UnAvailableProductContainer = styled.div`
    width: 334px;
    height: 343px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${colors.white};
    border-radius: 8px;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    padding-top: 20px;
    margin-left: 16px;
`;

const Title = styled.p`
    margin-top: 19px;
    font-size: 14px;
    font-weight: 700;
    color: ${colors.boldDark};
    line-height: 21px;
`;

const Description = styled.p`
    font-size: 12px;
    font-weight: 400;
    color: ${colors.themeTextColor11};
    line-height: 18px;
`;

export const UnAvailableProduct = () => {
    return (
        <UnAvailableProductContainer>
            <EmptyCartonSvg />
            <Title>This product is not available</Title>
            <LightBulb />
            <Description>
                You can choose to go back or view other
                <br /> recommended products
            </Description>
        </UnAvailableProductContainer>
    );
};
