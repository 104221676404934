import { axios, currentAPI } from '../../../../../config';
import { SAVE_BANNERS } from '../constants';

const baseUrl = `${currentAPI}/api`;
export const getBanners = () => async (dispatch) => {
    try {
        const bannerResponse = await axios.get(`${baseUrl}/banner/bannerImage/all`);

        if (bannerResponse.status === 200) {
            const { data } = bannerResponse.data;
            dispatch({ type: SAVE_BANNERS, payload: data });
        }
    } catch (error) {
        console.error(error);
    }
};

export const bannerInteraction = (bannerName, imageId) => async (dispatch) => {
    try {
        await axios.put(`${baseUrl}/portal/users/bannerImage/${bannerName}/interaction/update/${imageId}`);
    } catch (error) {
        console.error(error);
    }
};
