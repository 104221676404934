import React, { useState, useEffect } from 'react';

import algoliasearch from 'algoliasearch';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import aa from 'search-insights';
import styled from 'styled-components';

import { MerchbuyAddtoCartPopup } from '../../../../components';
import { SearchInputWithCancel } from '../../../../components/forms/input/search-input/SearchInput';
import { ScreenContainer, SectionWrapper } from '../../../../containers/ScreenContainer';
import { merchbuyActions } from '../../../../redux/ducks/applications/merchbuy/actions';
import { colors } from '../../../../styles';
import { COMPLETE_SEARCH, INITIATE_ADD_TO_CART } from '../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../utils/mix-panel/mixPanel';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { SearchListProducts } from '../components';
import { OOSEducation, OOSEducationBackground } from '../saved-items/educate';
import { Container } from '../styles';

import { ReactComponent as BulbIcon } from './assets/bulb.svg';
import { ReactComponent as SearchIcon } from './assets/search.svg';

const RecentSearchText = styled.p`
    font-size: 14px;
    font-weight: 700;
    color: ${colors.themeTxtColor10};
    margin: ${({ margin }) => margin || null};
`;

const SearchItem = styled.div`
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;

    > span {
        color: ${colors.themeTextColor11};
    }
`;

const SearchContainer = styled.div`
    position: fixed;
    padding: 5px 0 0px;
    background-color: ${({ bgColor }) => bgColor || '#fff'};
    top: 79px;
    left: 0;
    right: 0;
    margin: auto;
    width: 343px;
    @media (max-width: 576px) {
        width: calc(100% - 32px);
        top: 16px;
    }
    z-index: 99;
`;
const Hold = styled.div`
    background-color: ${({ bgColor }) => bgColor || '#fff'};
    z-index: ${({ zIndex }) => zIndex || null};
    position: relative;
    padding: 5px 0 0px;
    @media (max-width: 576px) {
        width: calc(100% - 32px);
        margin-top: 0px;
`;

const PrimaryText = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: ${({ bottom }) => bottom || null};
    color: ${colors.themeTextColor11};
    font-size: 12px;
`;

const Dot = styled.div`
    margin-right: 5px;
    height: 5px;
    width: 5px;
    background-color: #5c6f7f;
    border-radius: 50%;
    position: relative;
    bottom: -5px;
`;

const IconCover = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: ${({ bottom }) => bottom || null};
`;

const EmptyContainer = styled.div`
    margin-top: 30px;
`;

const CoverText = styled.div`
    text-align: center;
    color: ${colors.themeTxtColor10};
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 12px;
`;

const algoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY,
);
const searchIndex = process.env.REACT_APP_ALGOLIA_SEARCH_INDEX_V2;
const querySuggestionIndex = process.env.REACT_APP_ALGOLIA_SEARCH_QUERY_SUGGESTION_INDEX_V2;

const MerchbuySearch = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user.userId);
    const [query, setQuery] = useState('');
    const [products, setProducts] = useState([]);
    const [searchTerms, setSearchTerms] = useState([]);
    const [isLoadingProducts, setIsLoadingProducts] = useState(false);
    const [showCartPopup, setShowCartPopup] = useState(false);
    const [selectedProd, setSelectedProd] = useState({});
    const [doAnimate, setDoAnimate] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [showProducts, setShowProducts] = useState(false);
    const oosEducation = localStorage.getItem('OOSEducation') && JSON.parse(localStorage.getItem('OOSEducation'));
    const [userEducate, setUserEducate] = useState(oosEducation?.search ? false : true);
    const recentSearch = localStorage.getItem('RECENT_SEARCH') && JSON.parse(localStorage.getItem('RECENT_SEARCH'));
    const [allSearch, setAllSearch] = useState(recentSearch ? [...recentSearch] : []);
    const isInSearch =
        recentSearch &&
        recentSearch.length &&
        recentSearch.find((item) => item?.query?.toLowerCase().includes(query?.toLowerCase()));

    const setSearchEducation = () =>
        localStorage.setItem('OOSEducation', JSON.stringify({ ...oosEducation, search: true }));

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (query && products.length > 0 && query.length >= 3 && !isInSearch) {
                localStorage.setItem(
                    'RECENT_SEARCH',
                    JSON.stringify(
                        recentSearch
                            ? [{ query: query, label: query }, ...recentSearch]
                            : [{ query: query, label: query }],
                    ),
                );
                setAllSearch([{ query: query, label: query }, ...allSearch]);
            }
        }, 1000);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    useEffect(() => {
        if (!query) setShowProducts(false);
    }, [query]);

    useEffect(() => {
        dispatch(merchbuyActions.getProductNotificationList());
    }, [dispatch]);

    const handleClearButton = () => {
        dispatch(merchbuyActions.updateProductSearch(''));
        history.goBack();
    };

    const initiateAddToCart = (data) => {
        mixPanel.track(INITIATE_ADD_TO_CART, {
            'name of the product': data?.productName || data?.name,
            'product id': data?.productId || data?.id,
        });
    };

    const addToCartAfterSearch = (data) => {
        aa('convertedObjectIDsAfterSearch', {
            userToken: userId,
            eventName: 'Product Added to Cart after Search',
            index: searchIndex,
            queryID: data?.__queryID,
            objectIDs: [data?.productId || data?.id],
            Position: [data?.__position],
        });
    };

    const currentLocation =
        localStorage.getItem('deliveryLocation') && JSON.parse(localStorage.getItem('deliveryLocation'));

    const onChange = (value) => {
        setQuery(value);
        setShowProducts(false);

        const productSearch = {
            indexName: searchIndex,
            query: value,
            params: {
                filters: `state:${currentLocation.state} AND onMerchBuy:true AND quantityInStock > 0`,
                clickAnalytics: true,
                userToken: userId,
                enablePersonalization: true,
                distinct: true,
            },
        };

        const previousSearchParams = {
            indexName: querySuggestionIndex,
            query: value,
            params: {
                userToken: userId,
            },
        };

        const queries = [productSearch, previousSearchParams];

        setIsLoadingProducts(true);

        const searchIndexObject = algoliaClient.initIndex(searchIndex);

        algoliaClient.multipleQueries(queries).then((res) => {
            const [productSearchResults, previousSearchResults] = res.results;

            const products = productSearchResults.hits.map((product) => {
                const position = searchIndexObject.getObjectPosition(productSearchResults, product.objectID);
                return {
                    ...product,
                    __queryID: productSearchResults.queryID,
                    __position: position,
                };
            });

            setProducts(products);
            setSearchTerms(previousSearchResults.hits.slice(0, 10));

            setIsLoadingProducts(false);
        });
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (query && products.length > 0) {
                const start = new Date().getTime();
                mixPanel.track(COMPLETE_SEARCH, {
                    'Search value': query,
                    'user id': userId,
                    'Time Processed': new Date().getTime() - start,
                });
            }
        }, 1000);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, products]);

    const handleClose = () => {
        setDoAnimate(true);
        setTimeout(() => {
            setShowCartPopup(!showCartPopup);
        }, 300);
    };

    const CustomListProducts = ({ hits, setSelectedProd, ...others }) => {
        if (hits.length === 0 && query !== '') {
            return (
                <EmptyContainer>
                    <IconCover>
                        <SearchIcon />
                    </IconCover>
                    <CoverText>We couldn't find any matches</CoverText>
                    <IconCover bottom={'19px'}>
                        <BulbIcon />
                    </IconCover>
                    <PrimaryText bottom={'7px'}>
                        <Dot></Dot>
                        <div>Make sure the spelling is correct</div>
                    </PrimaryText>
                    <PrimaryText>
                        <Dot></Dot>
                        <div>Use less keywords</div>
                    </PrimaryText>
                </EmptyContainer>
            );
        }

        return (
            <SearchListProducts
                data={hits}
                size={{ width: '150px', height: '96px' }}
                searchTerm={query}
                setShowCartPopup={setShowCartPopup}
                setSelectedProd={setSelectedProd}
                setDoAnimate={setDoAnimate}
                justifyContent={'space-between'}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                initiateAddToCart={initiateAddToCart}
                addToCartAfterSearch={addToCartAfterSearch}
                showStock
            />
        );
    };

    return (
        <DesktopBackgroundLayout>
            {userEducate && <OOSEducationBackground />}
            <ScreenContainer color="#f9fafc" top={'16px'} padding={'0'} paddingBottom={'0'} height={'100%'}>
                {userEducate && (
                    <OOSEducation
                        top={'15%'}
                        title={'Search:'}
                        text={'Find items quickly by typing here'}
                        setUserEducate={setUserEducate}
                        setSavedEducation={setSearchEducation}
                        content={'flex-end'}
                        justifyContent={'flex-end'}
                        arrowOne
                    />
                )}
                <Hold bgColor={userEducate ? 'transparent' : '#fff'} zIndex={userEducate ? '2000' : null}>
                    <SectionWrapper
                        bgcolor={userEducate ? 'transparent' : '#ffffff'}
                        padding={'1em 1em 0'}
                        margin={'0 0 4px'}
                    >
                        <SearchContainer bgColor={userEducate ? 'transparent' : '#fff'}>
                            <SearchInputWithCancel
                                iconLeft={'-15px'}
                                value={query}
                                placeholder={'Start typing...'}
                                onChange={(event) => onChange(event.target.value)}
                                onKeyPress={() => {
                                    setShowProducts(true);
                                }}
                                onCancel={handleClearButton}
                                onCancelInputValue={() => onChange('')}
                                key={query}
                                color={userEducate ? '#fff' : '#000000'}
                            />
                        </SearchContainer>
                    </SectionWrapper>
                </Hold>

                <Container width={'100%'} padding={'1em'} height={'100%'}>
                    <SectionWrapper height={'100%'} bgcolor={'#ffffff'} padding={'0 12px'}>
                        {!query && (
                            <div>
                                <RecentSearchText margin={'16px 0 14px'}>Recent Search</RecentSearchText>
                                {allSearch &&
                                    allSearch.slice(0, 20).map((data, index) => (
                                        <div key={index}>
                                            <SearchItem
                                                onClick={() => {
                                                    onChange(data.query);
                                                    setShowProducts(true);
                                                }}
                                            >
                                                {data.query}
                                            </SearchItem>
                                        </div>
                                    ))}
                            </div>
                        )}

                        {query && !showProducts && (
                            <div>
                                <RecentSearchText margin={'16px 0 14px'}>Suggestions</RecentSearchText>
                                {searchTerms &&
                                    searchTerms.map((data, index) => (
                                        <div key={index}>
                                            <SearchItem
                                                onClick={() => {
                                                    onChange(data.query);
                                                    setShowProducts(true);
                                                }}
                                            >
                                                {data.query}
                                            </SearchItem>
                                        </div>
                                    ))}
                            </div>
                        )}

                        {query && !isLoadingProducts && showProducts && (
                            <CustomListProducts hits={products} setSelectedProd={setSelectedProd} />
                        )}
                    </SectionWrapper>
                </Container>
                {Object.keys(selectedProd).length > 0 && (
                    <MerchbuyAddtoCartPopup
                        open={showCartPopup}
                        data={selectedProd}
                        cancel={handleClose}
                        confirm={merchbuyActions.addProductToCart}
                        animate={doAnimate}
                    />
                )}
            </ScreenContainer>
        </DesktopBackgroundLayout>
    );
};

export default MerchbuySearch;
