import React from 'react';

import styled from 'styled-components';

import { colors } from '../../../../styles';
import { BlurBackgroundEducate } from '../product-details/buyNowEducation';

import { ReactComponent as CloseIcon } from './assets/close.svg';
import { ReactComponent as CurvedIconTwo } from './assets/curve.svg';
import { ReactComponent as CurvedIcon } from './assets/curved.svg';

export const EducateCover = styled.div`
    z-index: 10000;
    width: ${({ width }) => width || '100%'};
    padding: 0 16px;
    position: ${({ position }) => position || 'absolute'};
    top: ${({ top }) => top || null};
    left: ${({ left }) => left || null};
    bottom: ${({ bottom }) => bottom || null};
`;
export const EducateBox = styled.div`
    width: 100%;
    padding: 16px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 4px;
    z-index: 1000;
`;

export const FlexDivs = styled.div`
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
    align-items: ${({ alignItems }) => alignItems || 'center'};
    margin-bottom: ${({ bottom }) => bottom || null};
`;

export const FlexText = styled.div`
    font-size: ${({ size }) => size || '10px'};
    font-weight: ${({ size }) => size || '500'};
    text-align: ${({ align }) => align || null};
    color: ${({ color }) => color || colors.white};
    margin-bottom: ${({ bottom }) => bottom || null};
    margin-right: ${({ right }) => right || null};
    cursor: ${({ cursor }) => cursor || null};
`;

export const FlexButton = styled.button`
    font-size: ${({ size }) => size || '10px'};
    font-weight: ${({ size }) => size || '500'};
    width: 47px;
    height: 24px;
    color: ${({ color }) => color || colors.white};
    background: ${({ color }) => color || 'linear-gradient(106.74deg, #5271F2 0%, #3D60F3 97.91%)'};
    margin-bottom: ${({ bottom }) => bottom || null};
    margin-right: ${({ right }) => right || null};
    border: ${({ border }) => border || 'none'};
    border-radius: 17px;
`;

export const EducateContainer = styled.div`
    height: 100%;
    position: fixed;
    z-index: ${({ zIndex }) => zIndex || '200'};
    width: 100%;
    max-width: inherit;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const OOSEducation = ({
    top,
    setUserEducate,
    setSavedEducation,
    arrowOne,
    arrowTwo,
    title,
    text,
    left,
    position,
    width,
    justifyContent,
    content,
    bottom,
}) => {
    return (
        <EducateCover top={top} bottom={bottom} left={left} position={position} width={width}>
            {arrowOne && <CurvedIcon style={{ position: 'absolute', left: '55%', top: '-20px' }} />}
            {arrowTwo && <CurvedIconTwo style={{ position: 'absolute', right: '20%', top: '-40px' }} />}
            <EducateBox>
                <FlexDivs
                    justifyContent={justifyContent}
                    onClick={() => {
                        setUserEducate(false);
                        setSavedEducation();
                    }}
                >
                    <CloseIcon />
                </FlexDivs>
                <FlexText size={'12px'} bottom={'5px'} weight={'700'}>
                    {title}
                </FlexText>
                <FlexText bottom={'9px'}>{text}</FlexText>
                <FlexDivs justifyContent={content}>
                    <FlexDivs>
                        <FlexButton
                            onClick={() => {
                                setUserEducate(false);
                                setSavedEducation();
                            }}
                        >
                            Done
                        </FlexButton>
                    </FlexDivs>
                </FlexDivs>
            </EducateBox>
        </EducateCover>
    );
};

export const OOSEducationBackground = () => {
    return (
        <EducateContainer zIndex={'2000'}>
            <BlurBackgroundEducate></BlurBackgroundEducate>
        </EducateContainer>
    );
};
