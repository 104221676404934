import React, { Fragment } from 'react';

import styled from 'styled-components';

import { colors } from '../../styles';

import { ReactComponent as Logo } from './assets/Logo.svg';

const DesktopContentWrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    width: 100%;
    background-color: #edf2f7;
    padding: 0;
`;

const DesktopWrapper = styled.div`
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    background-color: #227eff;
    padding: 20px 15px;
    z-index: 12000;
    height: 65px;
    @media (max-width: 576px) {
        display: none;
    }
`;

const DesktopLogoWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-right: 15px;
    padding-left: 15px;
    @media (min-width: 425px) {
        max-width: 100%;
    }
    @media (min-width: 576px) {
        max-width: 540px;
    }
    @media (min-width: 768px) {
        max-width: 720px;
    }
    @media (min-width: 992px) {
        max-width: 960px;
    }
    @media (min-width: 1200px) {
        max-width: 1140px;
    }
    @media (min-width: 1366px) {
        max-width: 1248px;
    }
`;

const Row = styled.div`
    position: relative;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
`;

const DesktopStyledBackground = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    background: #edf2f7;
    margin: 0 auto;
    top: 65px;
    min-height: ${({ fullHeight }) => (fullHeight ? '100vh' : 'calc(100vh - 65px)')};

    @media (max-width: 576px) {
        top: 0;
        min-height: 100vh;
    }
`;

const DesktopStyledWidth = styled.div`
    position: relative;
    width: inherit;
    background: ${({ bgColor }) => bgColor || colors.white};
    background-image: ${({ bgImage }) => bgImage || null};
    background-size: ${({ bgSize }) => bgSize || null};
    background-position: ${({ bgPosition }) => bgPosition || null};
    margin: 0 auto 0;
    overflow: hidden;
    @media (min-width: 576px) {
        max-width: 375px;
        -webkit-box-shadow: 0px 52px 120px rgba(0, 0, 0, 0.08);
        box-shadow: 0px 52px 120px rgba(0, 0, 0, 0.08);
    }
`;

const BlueBackground = styled.div`
    position: absolute;
    height: ${({ height }) => height};
    width: 100%;
    background: ${colors.deepBlue};
`;

const DesktopBackgroundLayout = ({
    children,
    withBlueBackground = false,
    blueBackgroundHeight = '279px',
    bgColor,
    bgImage,
    bgSize,
    bgPosition,
    fullHeight,
}) => {
    return (
        <Fragment>
            <DesktopContentWrapper>
                <DesktopWrapper>
                    <DesktopLogoWrapper>
                        <Row>
                            <Logo />
                        </Row>
                    </DesktopLogoWrapper>
                </DesktopWrapper>
                <DesktopStyledBackground fullHeight={fullHeight}>
                    <DesktopStyledWidth bgColor={bgColor} bgImage={bgImage} bgSize={bgSize} bgPosition={bgPosition}>
                        {withBlueBackground ? <BlueBackground height={blueBackgroundHeight} /> : null}
                        {children}
                    </DesktopStyledWidth>
                </DesktopStyledBackground>
            </DesktopContentWrapper>
        </Fragment>
    );
};

export default DesktopBackgroundLayout;
