import { axios, currentAPI, BILL_AUTH_KEY } from '../../../../../../config';
import { getWalletTransactions } from '../../../transactions/actions';
import {
    BILL_PAYMENT_RESPONSE,
    FETCH_ALL_BILL_CATEGORIES,
    USER_WALLET_FAILED,
    FETCH_BILLERS,
    FETCH_AIRTIME_PURCHASE_TRANSACTIONS,
    LOADING,
    FETCH_BILLER_SERVICES,
    CUSTOMER_DETAILS,
    VERIFYING_CUSTOMER_DETAILS,
    FETCH_AIRTIME_PROVIDER_SERVICES,
} from '../../constants';

export const fetchAirtimeProviderServices = () => async (dispatch) => {
    dispatch({ type: LOADING, payload: true });

    try {
        const response = await axios.get(`${currentAPI}/api/airtime/provider/services`);

        if (response.status === 200) {
            dispatch({
                type: FETCH_AIRTIME_PROVIDER_SERVICES,
                payload: response.data?.data,
            });
        }
    } catch (error) {
        return '';
    } finally {
        dispatch({ type: LOADING, payload: false });
    }
};

export const fetchAllBillCategories = () => async (dispatch) => {
    dispatch({ type: LOADING, payload: true });

    try {
        const response = await axios.get(`${currentAPI}/api/billPayments/bill/categories/available`);

        if (response.status === 200) {
            dispatch({
                type: FETCH_ALL_BILL_CATEGORIES,
                payload: response.data?.data?.billCategories,
            });
        }
    } catch (error) {
        return '';
    } finally {
        dispatch({ type: LOADING, payload: false });
    }
};

export const submitAirtimePurchaseRequest = (airtimePurchaseRequest) => async (dispatch) => {
    try {
        const response = await axios.post(`${currentAPI}/api/airtime/purchase`, JSON.stringify(airtimePurchaseRequest));

        if (response.status === 200) {
            dispatch({
                type: BILL_PAYMENT_RESPONSE,
                payload: response.data?.data,
            });
            dispatch(getWalletTransactions());
            return true;
        }
    } catch (error) {
        dispatch({
            type: USER_WALLET_FAILED,
            payload: error?.response?.data?.message,
        });
        return false;
    }
};

export const fetchAirtimeTransactionHistory = (userNumber) => async (dispatch) => {
    try {
        const response = await axios.get(`${currentAPI}/api/airtime/history/${userNumber}`);

        if (response.status === 200) {
            dispatch({
                type: FETCH_AIRTIME_PURCHASE_TRANSACTIONS,
                payload: response?.data?.data?.content || [],
            });
            return response?.data?.data?.content;
        }
    } catch (error) {
        return '';
    }
};

export const fetchBillers = (billCategoryId) => async (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    try {
        const response = await axios.get(`${currentAPI}/api/billPayments/bill/category/${billCategoryId}`, {
            headers: {
                authKey: `${BILL_AUTH_KEY}`,
            },
        });

        if (response.status === 200) {
            dispatch({
                type: FETCH_BILLERS,
                payload: response.data?.data?.billers,
            });
        }
    } catch (error) {
        return '';
    } finally {
        dispatch({ type: LOADING, payload: false });
    }
};

export const fetchBillerServices = (billerId, countryCode) => async (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    dispatch({
        type: FETCH_BILLER_SERVICES,
        payload: null,
    });

    try {
        const response = await axios.post(
            `${currentAPI}/api/billPayments/biller-services`,
            JSON.stringify({ billerId, countryCode }),
        );

        if (response.status === 200) {
            dispatch({
                type: FETCH_BILLER_SERVICES,
                payload: response.data?.data?.billerServices,
            });
        }
    } catch (error) {
        return '';
    } finally {
        dispatch({ type: LOADING, payload: false });
    }
};

export const verifyCustomerDetails = (customerIdentifier, billerCode, billerId) => async (dispatch) => {
    dispatch({ type: VERIFYING_CUSTOMER_DETAILS, payload: true });
    try {
        const response = await axios.post(
            `${currentAPI}/api/billPayments/customer-validation`,
            JSON.stringify({ customerIdentifier, billerCode, billerId }),
        );

        if (response.status === 200) {
            dispatch({
                type: CUSTOMER_DETAILS,
                payload: response.data?.data,
            });

            return response.data?.data;
        }
    } catch (error) {
        return null;
    } finally {
        dispatch({ type: VERIFYING_CUSTOMER_DETAILS, payload: false });
    }
};

export const submitBillPaymentRequest = (billPaymentRequest) => async (dispatch) => {
    dispatch({ type: LOADING, payload: true });

    try {
        const response = await axios.post(
            `${currentAPI}/api/billPayments/bill/bill-purchase`,
            JSON.stringify(billPaymentRequest),
        );

        if (response.status === 200) {
            dispatch({
                type: BILL_PAYMENT_RESPONSE,
                payload: response.data,
            });
            dispatch(getWalletTransactions());
            return true;
        }
    } catch (error) {
        dispatch({
            type: USER_WALLET_FAILED,
            payload: error?.response?.data?.message,
        });
        return false;
    } finally {
        dispatch({ type: LOADING, payload: false });
    }
};
