import React, { Fragment, useState, useEffect } from 'react';

import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';

import { Loader, TopHeader, PasswordGroup, RippleButton, ConfirmPopupDialog } from '../../../components';
import { Message, Title } from '../../../containers/MessageContainer';
import { ScreenContainer } from '../../../containers/ScreenContainer';
import { getUserPublicData } from '../../../redux/ducks/auth/login/actions';
import { loginUser } from '../../../redux/ducks/user/actions';
import { colors } from '../../../styles';
import { saveUserIdInLocalStorage } from '../../../utils/auth/clearStorage';
import { getInputValues } from '../../../utils/inputs/getInputValues';
import DesktopBackgroundLayout from '../../DesktopBackgroundLayout';

import FallbackUserAvatar from './assets/user.svg';
import { LoginValidationSchema } from './LoginValidationSchema';

const LoginContainer = styled.div`
    margin-top: 8px;
`;

const ForgotPassword = styled(Link)`
    margin-top: 16px;
    text-align: center;
    font-size: 14px;
`;

const SwitchAccount = styled.span`
    margin-top: 24px;
    text-align: center;
    font-size: 14px;
`;

const AlternativeOptionBlock = styled.section`
    display: flex;
    flex-direction: column;
`;

const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.user);

    const isLoading = useSelector((state) => state.auth.login.isLoading);

    const [value, setValue] = useState(null);
    const [userData, setUserData] = useState(null);

    const userId = user && user.userId;
    const firstName = user && user.firstName;
    const avatar = user && user.avatar;
    const [showResetPasswordConfirmationDialog, setShowResetPasswordConfirmationDialog] = useState(false);

    const resetUserPassword = () => {
        setShowResetPasswordConfirmationDialog(true);
    };

    const resetPasswordAction = (value) => {
        if (value === 'No') {
            setShowResetPasswordConfirmationDialog(false);
        }

        if (value === 'Yes') {
            saveUserIdInLocalStorage(userId);
            history.push('/user/password-otp-options');
        }
    };

    useEffect(() => {
        (async () => {
            const data = await dispatch(getUserPublicData(userId));
            if (data) setUserData(data);
        })();
    }, [dispatch, userId, firstName]);

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader
                    noTitle
                    noBorderBottom
                    isIcon
                    Icon={avatar && avatar !== 'Not Set' ? avatar : FallbackUserAvatar}
                    iconWidth={'40px'}
                    iconHeight={'40px'}
                    objectFit="cover"
                />
                <ScreenContainer top="50px">
                    <ConfirmPopupDialog
                        open={showResetPasswordConfirmationDialog}
                        title={'Did you forget your PIN?'}
                        confirmationText={'Click YES to reset your PIN and NO to cancel.'}
                        answers={[
                            {
                                variant: 'No',
                                action: () => resetPasswordAction('No'),
                            },
                            {
                                variant: 'Yes',
                                action: () => resetPasswordAction('Yes'),
                            },
                        ]}
                    />
                    {userData && (
                        <Fragment>
                            <Title top={'62px'} textAlign="left" size="24px" weight="bold" color={colors.subGrey}>
                                Welcome Back,{' '}
                                <strong style={{ color: 'black' }}> {firstName || userData.firstName} </strong>
                            </Title>
                        </Fragment>
                    )}
                    <LoginContainer>
                        <Message bottom={'53px'} top={'8'}>
                            Enter your PIN to login to your account
                        </Message>
                        <Formik
                            initialValues={{
                                password: '',
                            }}
                            validationSchema={LoginValidationSchema}
                            onSubmit={(values) => dispatch(loginUser(values.password))}
                        >
                            {({ errors, valid, values, touched, setFieldValue }) => (
                                <Form>
                                    <Message size={'10px'} align="left">
                                        {' '}
                                        Enter PIN{' '}
                                    </Message>
                                    <PasswordGroup
                                        align={'left'}
                                        count={6}
                                        startIndex={1}
                                        type="password"
                                        valid={valid}
                                        name="password"
                                        errors={errors}
                                        touched={touched}
                                        disabled={isLoading}
                                        enteredValue={value || undefined}
                                        handleChange={(event) => {
                                            setValue(event.target.value);
                                            setFieldValue('password', getInputValues('password'));
                                        }}
                                    />
                                    <div style={{ marginTop: '27px', textAlign: 'right' }}>
                                        <ForgotPassword
                                            to="#"
                                            style={{ color: colors.deepBlue }}
                                            onClick={() => resetUserPassword()}
                                        >
                                            Forgot Your Pin?
                                        </ForgotPassword>
                                    </div>
                                    <RippleButton
                                        type="submit"
                                        top="24px"
                                        disabled={values.password.length < 6 || isLoading}
                                    >
                                        Log In
                                    </RippleButton>
                                </Form>
                            )}
                        </Formik>
                        <AlternativeOptionBlock>
                            <SwitchAccount>
                                Not you?{' '}
                                <Link style={{ color: colors.deepBlue }} to="/phone-signup">
                                    Switch Account
                                </Link>
                            </SwitchAccount>
                        </AlternativeOptionBlock>
                    </LoginContainer>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default Login;
