import React, { useEffect } from 'react';

import styled from 'styled-components';

import { colors } from '../../styles';

import { ReactComponent as CloseIcon } from './assets/close.svg';

const Container = styled.div`
    width: ${({ width }) => width || '343px'};
    background-color: ${({ bgColor }) => bgColor || colors.lightGreen};
    min-height: ${({ height }) => height || '34px'};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: ${({ position }) => position || 'fixed'};
    top: ${({ top }) => top || '120px'};
    bottom: ${({ bottom }) => bottom || null};
    border-radius: 4px;
    padding: 8px 16px;
    z-index: 99999999999;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 343px;

    @media (max-width: 576px) {
        width: calc(100% - 32px);
    }
`;

const Text = styled.p`
    color: ${({ color }) => color || colors.darkGree2};
    font-weight: 500;
    font-size: ${({ fontSize }) => fontSize || '12px'};
    line-height: ${({ lineHeight }) => lineHeight || '18px'};
    margin: 0px;
`;

export const NewToast = ({ text, close, failure }) => {
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            close();
        }, 3000);

        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container bgColor={failure ? colors.background.failedStatus : colors.lightGreen}>
            <Text color={failure ? colors.textRed : colors.darkGree2}>{text}</Text>
            <CloseIcon onClick={close} />
        </Container>
    );
};
