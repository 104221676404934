import React from 'react';

import { string } from 'prop-types';

import { BadgeWrapper, HorizontalLayout, InfoMessage, InfoTitle } from '../styles';

import { ReactComponent as InfoIcon } from './assets/info.svg';

const InfoBadge = ({
    margin,
    title,
    message,
    backgroundColor,
    color,
    weight,
    width,
    top,
    bottom,
    marginTop,
    size,
    height,
    icon,
    radius,
    titleColor,
    titleSize,
}) => {
    return (
        <BadgeWrapper height={height} width={width} margin={margin} backgroundColor={backgroundColor} radius={radius}>
            {icon ? icon : <InfoIcon />}
            <HorizontalLayout>
                {title && (
                    <InfoTitle marginTop={marginTop} weight={weight} size={titleSize} color={titleColor}>
                        {title}
                    </InfoTitle>
                )}
                <InfoMessage size={size} height={height} top={top} bottom={bottom} color={color}>
                    {message}
                </InfoMessage>
            </HorizontalLayout>
        </BadgeWrapper>
    );
};

InfoBadge.propTypes = {
    margin: string,
    message: string,
};

export default InfoBadge;
