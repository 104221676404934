import React from 'react';

import { bool, func, string } from 'prop-types';
import Switch from 'react-switch';

import { colors } from '../../styles';

export const SwitchTrigger2 = ({ name, checkStatus, switchStatus, switchOffColor, switchOnColor, width, height }) => {
    return (
        <Switch
            onColor={switchOnColor || colors.switch.on}
            offColor={switchOffColor || colors.switch.off}
            uncheckedIcon={false}
            checkedIcon={false}
            onHandleColor={colors.switch.noob}
            offHandleColor={colors.switch.noob}
            width={width || 46}
            height={height || 24}
            className={'react-switch'}
            checked={checkStatus}
            onChange={() => {
                if (name) {
                    switchStatus(name, !checkStatus);
                } else {
                    switchStatus(!checkStatus);
                }
            }}
        />
    );
};

SwitchTrigger2.propTypes = {
    name: string,
    checkStatus: bool,
    switchStatus: func,
};
