import React, { Fragment, useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as ArrowDownIcon } from '../../../../assets/arrow_down.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/arrow_up.svg';
import { DropdownList, CheckPopupDialog, TopHeader } from '../../../../components';
import ItemsFilters from '../../../../components/filters/items-filters';
import { getWalletTransactions } from '../../../../redux/ducks/account/transactions/actions';
import { parseTransactionsByDate } from '../../../../utils/date/parseTransactionsByDate';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import Navigation from '../../navigation';
import EmptyTransactionPage from '../wallet-transactions/empty-transactions';

import { ReactComponent as AllTransactionSVG } from './assets/all_transactions.svg';
import { ReactComponent as BankTransactionSVG } from './assets/bank_transactions.svg';
import { ReactComponent as CommissionTransactionSVG } from './assets/commision_transactions.svg';
import { ReactComponent as CreditTransactionSVG } from './assets/credit_transactions.svg';
import { ReactComponent as DebitTransactionSVG } from './assets/debit_transactions.svg';
import { ReactComponent as PayTransactionSVG } from './assets/pay_transactions.svg';
import { ReactComponent as TopupTransactionSVG } from './assets/topup_transactions.svg';
import { ReactComponent as WalletTransactionSVG } from './assets/wallet_transactions.svg';
import { filterByDate } from './filter';

const TransactionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 64px 0;
`;

const TransactionPage = () => {
    const dispatch = useDispatch();
    const transactions = useSelector((state) => state.account.transactions.transactionsList);

    const [filteredList, setFilteredList] = useState(parseTransactionsByDate(transactions));
    const [type, setType] = useState('All type');
    const [sortBy, setSortBy] = useState('Most Recent');
    const [openFilterOptions, setOpenFilterOptions] = useState(false);
    const [openSortByOptions, setOpenSortByOptions] = useState(false);

    useEffect(() => {
        dispatch(getWalletTransactions());
    }, [dispatch]);

    const filterTransactions = (type = '', sortBy = '', typeEnum) => {
        if (type) {
            setType(type);
            setOpenFilterOptions(false);
            return setFilteredList(filterByDate(transactions, typeEnum));
        } else if (sortBy) {
            setSortBy(sortBy);
            setOpenSortByOptions(false);
            return setFilteredList(filterByDate(filteredList[0].transactions, typeEnum));
        } else return transactions;
    };

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title="Transactions" />
                <TransactionsContainer>
                    <ItemsFilters
                        filterText={type}
                        filterAction={() => setOpenFilterOptions(true)}
                        sortText={sortBy}
                        sortAction={() => setOpenSortByOptions(true)}
                    />
                    {filteredList && filteredList.length > 0 ? (
                        parseTransactionsByDate(filteredList[0].transactions, 'createdDate').map(
                            (transactionsList, index) => (
                                <DropdownList
                                    key={index}
                                    title={transactionsList.date}
                                    subHeading={'count'}
                                    index={index}
                                    transactionList={transactionsList.transactions}
                                    detailsLink={'/user/wallet_transaction_details'}
                                    text="transactions"
                                />
                            ),
                        )
                    ) : (
                        <EmptyTransactionPage />
                    )}
                </TransactionsContainer>
                <CheckPopupDialog
                    open={openFilterOptions}
                    title={'Filters'}
                    cancel={() => setOpenFilterOptions(!openFilterOptions)}
                    items={[
                        {
                            Icon: AllTransactionSVG,
                            title: 'All transactions',
                            click: () => filterTransactions('All types', null, 'ALL'),
                        },
                        {
                            Icon: CreditTransactionSVG,
                            title: 'Credit transactions',
                            click: () => filterTransactions('Credit Transactions', null, 'CREDIT'),
                        },
                        {
                            Icon: DebitTransactionSVG,
                            title: 'Debit transactions',
                            click: () => filterTransactions('Debit Transactions', null, 'DEBIT'),
                        },
                        {
                            Icon: TopupTransactionSVG,
                            title: 'Wallet Top up',
                            click: () => filterTransactions('Wallet Top up', null, 'TOP_UP_WALLET'),
                        },
                        {
                            Icon: WalletTransactionSVG,
                            title: 'Wallet to Wallet',
                            click: () => filterTransactions('Wallet Top up', null, 'WALLET_TO_WALLET'),
                        },
                        {
                            Icon: BankTransactionSVG,
                            title: 'Bank Transfer',
                            click: () => filterTransactions('Bank Transfer', null, 'CREDIT_BANK'),
                        },
                        {
                            Icon: CommissionTransactionSVG,
                            title: 'Commissions',
                            click: () => filterTransactions('Commissions', null, 'COMMISSION_TO_WALLET'),
                        },
                        {
                            Icon: PayTransactionSVG,
                            title: 'Pay with wallet',
                            click: () => filterTransactions('Pay with wallet', null, 'PAY_WITH_WALLET'),
                        },
                    ]}
                />
                <CheckPopupDialog
                    open={openSortByOptions}
                    title={'Sort By'}
                    cancel={() => setOpenSortByOptions(!openSortByOptions)}
                    items={[
                        {
                            Icon: ArrowUpIcon,
                            title: 'Most Recent',
                            click: () => filterTransactions(null, 'Most Recent', 'Most Recent'),
                        },
                        {
                            Icon: ArrowDownIcon,
                            title: 'Oldest',
                            click: () => filterTransactions(null, 'Oldest', 'Oldest'),
                        },
                        {
                            Icon: ArrowUpIcon,
                            title: 'Amount: High to Low',
                            click: () => filterTransactions(null, 'Amount: High to Low', 'High to Low'),
                        },
                        {
                            Icon: ArrowUpIcon,
                            title: 'Amount: Low to High',
                            click: () => filterTransactions(null, 'Amount: Low to High', 'Low to High'),
                        },
                    ]}
                />
                <Navigation />
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default TransactionPage;
