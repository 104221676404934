import { USER_LOGOUT } from '../../user/constants';

import {
    LOADING,
    LOADING_TRANSACTION,
    LOADING_TRANSACTIONS,
    FETCH_ALL_TRANSACTIONS_SUCCESS,
    FETCH__ALL_TRANSACTIONS_FAILURE,
    FETCH_TRANSACTION_DETAILS_SUCCESS,
    FETCH_TRANSACTION_DETAILS_FAILURE,
} from './constants';

const initialState = {
    isLoading: false,
    allTransactions: [],
    transactionById: {},
};

export default function transactionReducer(state = initialState, action) {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }
        case LOADING_TRANSACTIONS: {
            // Check if the data is available and then serve the user with it
            if (state.allTransactions && state.allTransactions.length > 0) {
                return {
                    ...state,
                    isLoading: false,
                };
            } else {
                // Wait for the record to return from the server if not is available from the cache
                return {
                    ...state,
                    isLoading: true,
                };
            }
        }

        case FETCH_ALL_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                allTransactions: action.payload,
            };
        }
        case FETCH__ALL_TRANSACTIONS_FAILURE: {
            return {
                ...state,
                allTransactions: [],
            };
        }

        case LOADING_TRANSACTION: {
            // Check if the data is available, find the record and sends it to the client
            if (state.allTransactions && state.allTransactions.length > 0) {
                const transactionById = state.allTransactions.find((x) => x.id === action.payload);
                return {
                    ...state,
                    transactionById,
                };
            } else {
                // Wait for the record to return from the server if not is available from the cache
                return {
                    ...state,
                    isLoading: true,
                };
            }
        }

        case FETCH_TRANSACTION_DETAILS_SUCCESS: {
            return {
                ...state,
                transactionById: action.payload,
            };
        }

        case FETCH_TRANSACTION_DETAILS_FAILURE: {
            return {
                ...state,
                transactionById: {},
            };
        }
        case USER_LOGOUT: {
            return {
                isLoading: false,
                MerchbuyProductCategeries: [],
                shop: {},
            };
        }

        default: {
            return state;
        }
    }
}
