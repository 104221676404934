export const FilterOrders = (type, storeFrontOrders, dateRange, singleDate, setOrdersList) => {
    const getWeekNumber = (date) => {
        const todayObj = new Date();
        const todayDate = todayObj.getDate();
        const todayDay = todayObj.getDay();

        // get first date of week
        const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

        // get last date of week
        const lastDayOfWeek = new Date(firstDayOfWeek);
        lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

        // if date is equal or within the first and last dates of the week
        return date >= firstDayOfWeek && date <= lastDayOfWeek;
    };

    if (type === 'Lifetime') {
        setOrdersList(storeFrontOrders);
    } else if (type === 'Specific Day') {
        setOrdersList(
            storeFrontOrders.filter(
                (data) =>
                    new Date(data.createdAt).getDate() === singleDate.day &&
                    new Date(data.createdAt).getMonth() + 1 === singleDate.month &&
                    new Date(data.createdAt).getFullYear() === singleDate.year,
            ),
        );
    } else if (type === 'Today') {
        setOrdersList(
            storeFrontOrders.filter(
                (data) =>
                    new Date(data.createdAt).getDate() === new Date().getDate() &&
                    new Date(data.createdAt).getMonth() === new Date().getMonth() &&
                    new Date(data.createdAt).getFullYear() === new Date().getFullYear(),
            ),
        );
    } else if (type === 'This Week') {
        setOrdersList(storeFrontOrders.filter((data) => getWeekNumber(new Date(data.createdAt))));
    } else if (type === 'This Month') {
        setOrdersList(storeFrontOrders.filter((data) => new Date(data.createdAt).getMonth() === new Date().getMonth()));
    } else if (type === 'This Year') {
        setOrdersList(
            storeFrontOrders.filter((data) => new Date(data.createdAt).getFullYear() === new Date().getFullYear()),
        );
    } else if (type === 'Date Range') {
        setOrdersList(
            storeFrontOrders.filter(
                (data) =>
                    new Date(data.createdAt).getDate() >= dateRange.from.day &&
                    new Date(data.createdAt).getMonth() + 1 >= dateRange.from.month &&
                    new Date(data.createdAt).getFullYear() === dateRange.from.year &&
                    new Date(data.createdAt).getDate() <= dateRange.to.day &&
                    new Date(data.createdAt).getMonth() + 1 <= dateRange.to.month &&
                    new Date(data.createdAt).getFullYear() === dateRange.to.year,
            ),
        );
    }
};
