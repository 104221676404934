import React from 'react';

import { addHeaderToRoutes } from '../../hoc/addHeaderToRoutes';
import {
    SetupStoreFront,
    StoreFrontSettings,
    StoreFrontDetails,
    StoreFrontDeliveryOptions,
} from '../../views/actions/store-front';
import StoreFrontOrderDetails from '../../views/home/dashboard/storefront/orders/orderDetails';
import StorefrontAddProduct from '../../views/home/dashboard/storefront/products/addProduct';
import StorefrontEditProduct from '../../views/home/dashboard/storefront/products/editProduct';
import StoreFrontProductDetails from '../../views/home/dashboard/storefront/products/productDetails';

const storeFrontRoutes = [
    {
        path: '/actions/store_setup',
        exact: true,
        main: () => <SetupStoreFront />,
        public: false,
    },
    {
        path: '/actions/storefront_settings',
        exact: true,
        main: () => <StoreFrontSettings />,
        public: false,
    },
    {
        path: '/actions/storefront_details',
        exact: true,
        main: () => <StoreFrontDetails />,
        public: false,
    },
    {
        path: '/actions/storefront_delivery_options',
        exact: true,
        main: () => <StoreFrontDeliveryOptions />,
        public: false,
    },

    {
        path: '/actions/storefront/add_product',
        exact: true,
        main: () => <StorefrontAddProduct />,
        public: false,
    },

    {
        path: '/actions/storefront/edit_product/:id',
        exact: true,
        main: () => <StorefrontEditProduct />,
        public: false,
    },

    {
        path: '/actions/storefront/order/:id',
        exact: true,
        main: () => <StoreFrontOrderDetails />,
        public: false,
    },

    {
        path: '/actions/storefront/product/:id',
        exact: true,
        main: () => <StoreFrontProductDetails />,
        public: false,
    },
];

export const storeFrontRoutesWithHeader = [...addHeaderToRoutes(storeFrontRoutes)];
