import React, { Fragment } from 'react';

import { ErrorMessage } from 'formik';
import { string, bool, any, func, object } from 'prop-types';
import styled from 'styled-components';

import { Clear } from '../../../../containers/HeaderContainer';
import { InputLabelBlock, TextareaInputBlock } from '../../../../containers/InputContainer';

const InputBlock = styled(InputLabelBlock)`
    border-radius: ${({ radius }) => radius || '8px'};
    margin-top: ${({ mtop }) => mtop || '8px'};
    border: ${({ border }) => border || 'none'};
`;

export const TextareaWithLabel = (props) => {
    const {
        id,
        placeholder,
        background,
        valid,
        errors,
        value,
        name,
        width,
        right,
        left,
        top,
        height,
        readOnly,
        inputMode,
        type,
        disabled,
        onKeyUp,
        onBlur,
        onFocus,
        onChange,
        countryselection,
        autoComplete,
        touched,
        setFieldValue,
        initialValues,
        noClearButton,
        errorTop,
        as,
        maxLength,
        bottom,
        onKeyPress,
        pattern,
        step,
        list,
        nolabelmargin,
        size,
        inputheight,
        autoFocus,
        mtop,
        radius,
        overflow,
        holderColor,
        border,
    } = props;

    return (
        <Fragment>
            <InputBlock
                background={background}
                left={left}
                right={right}
                id={id}
                height={height}
                width={width}
                valid={valid}
                error={errors}
                bottom={bottom}
                top={top}
                border={border}
                mtop={mtop}
                radius={radius}
            >
                <TextareaInputBlock
                    component={'textarea'}
                    placeholder={placeholder}
                    holderColor={holderColor}
                    background={background}
                    countryselection={countryselection && countryselection.toString()}
                    nolabelmargin={nolabelmargin && nolabelmargin.toString()}
                    touched={touched && touched.toString()}
                    inputMode={inputMode && inputMode.toString()}
                    autoComplete={autoComplete && autoComplete.toString()}
                    valid={valid && valid.toString()}
                    errors={errors}
                    readOnly={readOnly}
                    size={size}
                    overflow={overflow}
                    onBlur={onBlur}
                    onKeyUp={onKeyUp}
                    onFocus={onFocus}
                    onChange={onChange}
                    value={value}
                    name={name}
                    as={as}
                    type={type}
                    disabled={disabled}
                    maxLength={maxLength}
                    onKeyPress={onKeyPress}
                    autoFocus={autoFocus}
                    pattern={pattern}
                    step={step}
                    list={list}
                    inputheight={inputheight}
                    mtop={mtop}
                />

                {type === 'text' && value && noClearButton === false && (
                    <Clear
                        right={'true'}
                        onClick={() => {
                            document.querySelector(`input[name=${name}]`).value = '';
                            setFieldValue(name, initialValues[name]);
                        }}
                    />
                )}
                <ErrorMessage
                    name={name}
                    style={{ top: `${errorTop ? errorTop : null}` }}
                    component="div"
                    className="form-error"
                />
            </InputBlock>
        </Fragment>
    );
};

TextareaWithLabel.propTypes = {
    label: string,
    placeholder: string.isRequired,
    name: string.isRequired,
    id: string,
    countrySelection: string,
    autoComplete: string,
    inputMode: string,
    valid: string,
    errors: string,
    width: string,
    height: string,
    type: string,
    right: string,
    left: string,
    top: string,
    as: string,
    noClearButton: bool,
    readOnly: bool,
    disabled: bool,
    touched: bool,
    onKeyUp: func,
    onChange: func,
    setFieldValue: func,
    initialValues: object,
    value: any,
    list: any,
    nolabelmargin: bool,
    autoFocus: bool,
};
