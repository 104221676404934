import React from 'react';

import styled from 'styled-components';

import { RippleButton } from '../../../../components';
import { LightButton } from '../../../../components/button';
import { colors } from '../../../../styles';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import { formatCreationDate } from '../../../../utils/date/formatCreationDate';
import { Text } from '../../merchbuy/styles';

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ bottom }) => bottom || '8px'};
`;

const FooterContainer = styled.div`
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 4%) 0px -8px 16px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    align-items: center;
    left: 0;
    padding: 16px 24px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 16px;
    margin-top: 16px;
`;

const PaymentFooter = ({ paymentDetails, onDownloadClick, setShowReceipt, btnBackground }) => {
    const onPayClick = (fundingLink) => {
        window.location.href = fundingLink;
    };

    return (
        <FooterContainer>
            <FlexContainer>
                <Text color={colors.boldDark} font="14px" fontWeight="700">
                    Total
                </Text>
                <Text color={colors.boldDark} font="14px" fontWeight="700">
                    {formatPrice(paymentDetails?.amount)}
                </Text>
            </FlexContainer>
            {paymentDetails?.status === 'CLOSED' && (
                <FlexContainer bottom="24px">
                    <Text color={colors.boldDark} font="14px" fontWeight="700">
                        Date paid
                    </Text>
                    <Text color={colors.boldDark} font="14px" fontWeight="700">
                        {formatCreationDate(paymentDetails?.createdAt)}
                    </Text>
                </FlexContainer>
            )}
            {paymentDetails?.status === 'OPENED' ? (
                <RippleButton top="16px" type={'button'} onClick={() => onPayClick(paymentDetails.fundingLink)}>
                    Pay
                </RippleButton>
            ) : btnBackground ? (
                <LightButton
                    onClick={() => onDownloadClick()}
                    color={colors.themeColor4}
                    background={colors.white}
                    top="0"
                    type={'button'}
                >
                    Download Receipt
                </LightButton>
            ) : (
                <ButtonsWrapper>
                    <RippleButton onClick={() => setShowReceipt()} top="0">
                        View Receipt
                    </RippleButton>
                    <LightButton
                        onClick={() => onDownloadClick()}
                        color={colors.themeColor4}
                        background={colors.white}
                        top="0"
                        type={'button'}
                    >
                        Download Receipt
                    </LightButton>
                </ButtonsWrapper>
            )}
        </FooterContainer>
    );
};

export default PaymentFooter;
