import React, { useState } from 'react';

import { bool, func, string } from 'prop-types';
import styled from 'styled-components';

import { SecondaryText } from '../../../containers/MessageContainer';
import { Overlay, ActionBlock } from '../../../containers/OverlayContainer';
import { colors } from '../../../styles';
import { RippleButton } from '../../button';
import { PopUp, PopUpContent, PopUpHeader, InfoMessage } from '../common';

import { ReactComponent as AddIcon } from './assets/add.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import { ReactComponent as SubtractIcon } from './assets/subtract.svg';

const QuantityCount = styled.span`
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    margin-left: 3px;
    margin-right: 6px;
    color: #071827;
`;

const UpdateStockHeading = styled(PopUpHeader)`
    font-size: 14px;
    font-weight: 500;
    color: #212c3d;
    line-height: 21px;
    padding: 16px 0 15px;
`;

const UpdateCalculatorBlock = styled.div`
    display: flex;
    align-items: center;
    margin: 16px 17px 0 24px;

    & > svg {
        cursor: pointer;
    }
`;

const QuantityDisplay = styled.div`
    border-radius: 2px;
    max-width: 50px;
    height: 40px;
    background-color: #f2f5fa;
    text-align: center;
    margin: 0 8px;
`;

const CurrentQuantity = styled.input`
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    font-weight: 400;
    font-size: 14px;
    color: ${({ color }) => color || colors.themeTextColor1};
    width: 100%;
    background-color: inherit;
    border: none;
    text-align: center;
    &:focus {
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
`;

const ButtonBlock = styled(ActionBlock)`
    padding: 0 24px 0;
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translate(-50%, 0);
`;

const StyledCloseIcon = styled(CloseIcon)`
    position: absolute;
    top: 25px;
    left: 28px;
`;

const UpdateTypeWrapper = styled.div`
    background-color: #edf2f7;
    border-radius: 12px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px auto;
    width: 212px;
    height: 40px;
`;

const UpdateTypeCell = styled.div`
    border-radius: 8px;
    height: 30px;
    padding: 6px 14px;
`;

const UpdateTypeText = styled.p`
    color: #212c3d;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
`;

const QuantityWrapper = styled(SecondaryText)`
    background-color: #edf2f7;
    width: 100%;
    height: 30px;
    padding: 6px 0;
    text-align: right;
    font-weight: 400;
    color: #718596;
`;

export const UpdateStockPopup = ({ open, title, quantity, cancel, updateProductQuantity, shopId, id, productName }) => {
    const [newQuantity, setNewQuantity] = useState(0);
    const [quantityChange, setQuantityChange] = useState('increase');

    const handleUpdate = () => {
        if (newQuantity < 0) return;
        const operation =
            quantityChange === 'increase'
                ? parseInt(quantity) + parseInt(newQuantity)
                : parseInt(quantity) - parseInt(newQuantity);
        updateProductQuantity(shopId, id, operation, newQuantity, quantityChange, productName);
    };

    return (
        <PopUp open={open}>
            <Overlay onClick={cancel}></Overlay>
            <PopUpContent style={{ height: '80vh', maxHeight: '80vh', overflowY: 'scroll' }}>
                <StyledCloseIcon onClick={cancel} />
                <UpdateStockHeading>{title}</UpdateStockHeading>

                <UpdateTypeWrapper>
                    <UpdateTypeCell
                        onClick={() => setQuantityChange('increase')}
                        style={{ backgroundColor: quantityChange === 'increase' ? '#fff' : null }}
                    >
                        <UpdateTypeText>Add stock</UpdateTypeText>
                    </UpdateTypeCell>
                    <UpdateTypeCell
                        onClick={() => {
                            setQuantityChange('decrease');
                        }}
                        style={{ backgroundColor: quantityChange === 'decrease' ? '#fff' : null }}
                    >
                        <UpdateTypeText>Remove stock</UpdateTypeText>
                    </UpdateTypeCell>
                </UpdateTypeWrapper>

                <QuantityWrapper>
                    Available Stock: <QuantityCount>{quantity}</QuantityCount>
                </QuantityWrapper>

                <InfoMessage left="17px" align="left" top="22px" color="#071827" weight="500">
                    {`How many units do you want to ${quantityChange === 'increase' ? 'add' : 'remove'}?`}
                </InfoMessage>
                <UpdateCalculatorBlock>
                    <SubtractIcon
                        onClick={() => {
                            setNewQuantity(parseInt(newQuantity) - 1 <= 0 ? 0 : parseInt(newQuantity) - 1);
                        }}
                    />
                    <QuantityDisplay>
                        <CurrentQuantity
                            color="#0B0C0E"
                            value={Number.isNaN(newQuantity) ? '' : newQuantity}
                            type={'number'}
                            onChange={(e) => {
                                setNewQuantity(e.target.value < 0 ? 0 : parseInt(e.target.value));
                            }}
                        />
                    </QuantityDisplay>
                    <AddIcon onClick={() => setNewQuantity(parseInt(newQuantity) + 1)} />
                </UpdateCalculatorBlock>

                <QuantityWrapper style={{ marginBottom: '150px' }}>
                    Final Stock:
                    <QuantityCount>
                        {quantityChange === 'increase'
                            ? parseInt(newQuantity || 0) + parseInt(quantity)
                            : parseInt(quantity) - parseInt(newQuantity || 0)}
                    </QuantityCount>
                </QuantityWrapper>

                <ButtonBlock direction={'row'}>
                    <RippleButton
                        type="button"
                        onClick={cancel}
                        backgroundColor="#fff"
                        color="#718596"
                        border="1px solid #718596"
                    >
                        Cancel
                    </RippleButton>
                    <RippleButton
                        type="button"
                        disabled={(quantityChange === 'decrease' && newQuantity > quantity) || !newQuantity}
                        onClick={() => handleUpdate()}
                    >
                        Update
                    </RippleButton>
                </ButtonBlock>
            </PopUpContent>
        </PopUp>
    );
};

UpdateStockPopup.propTypes = {
    open: bool,
    title: string,
    quantity: string,
    cancel: func,
    updateProductQuantity: func,
    shopId: string,
    id: string,
    productName: string,
};
