export const toTitleCase = (str) => {
    return str
        ?.split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const upperCaseToTitleCase = (string) => string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();

export const toAbbr = (str) => {
    const initials = str.split(' ');
    return initials[0] !== str
        ? `${initials[0][0]}${initials[1][0] || ''}`.toUpperCase()
        : `${str[0]}${str[str.length - 1]}`.toUpperCase();
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const toTitleCasePerWord = (words = '') =>
    words.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (word) => word.toUpperCase());
