import React, { Fragment } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';
import useRouter from 'use-react-router';

import { RippleButton, PageLogo } from '../../../../../../components';
import { Title, SubTitle } from '../../../../../../containers/MessageContainer';
import { FlexCenteredBlock, ScreenContainer } from '../../../../../../containers/ScreenContainer';
import { formatPrice } from '../../../../../../utils/currency/formatPriceWithComma';
import History from '../../../../../../utils/History';
import DesktopBackgroundLayout from '../../../../../DesktopBackgroundLayout';
import { ReactComponent as Confetti } from '../assets/confetti.svg';
import DefaultIcon from '../assets/default_product.svg';
import AddedToCartIcon from '../assets/product_success.svg';
import background from '../assets/success-background.svg';

const Gif = styled.img`
    width: 65px;
    height: 65px;
    margin-bottom: 12px;
`;

const MenuContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 0 45px 0;
    padding: 35px 16px 22px;
    border-radius: 8px;
    background: linear-gradient(121.74deg, rgba(255, 255, 255, 0.6) 0%, rgba(237, 242, 247, 0.3) 119.79%);
    overflow: hidden;
    cursor: pointer;
    border-left: 1px solid #edf2f7;
    border-top: 1px solid #edf2f7;
    border-bottom: 1px solid #edf2f7;
    -webkit-tap-highlight-color: transparent;
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const OptionName = styled.h4`
    font-weight: 700;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    margin: 8px 0 4px;
`;

const CongratsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 128px;
    height: 24px;
    border-radius: 34px;
    background-color: rgba(255, 255, 255, 0.2);
    margin-bottom: 8px;
`;

const PricesContainer = styled.div`
    margin-top: 10px;
    display: flex;
    padding: 16px 12px;
    background-color: rgba(3, 80, 187, 0.4);
    justify-content: space-between;
    border-radius: 8px;
    width: 100%;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

const PricesText = styled.p`
    font-size: 10px;
    margin: 0 0 4px;
    font-weight: 700px;
    line-height: 16px;
    color: #fff;
    text-transform: uppercase;
`;

const PricesAmount = styled.p`
    font-size: 14px;
    font-weight: 500px;
    line-height: 21px;
    color: #fff;
    margin: 0;
`;

const SingleProductSuccess = () => {
    const { location } = useRouter();
    const shops = useSelector((state) => state.applications.myShop.shops);
    const { product, source } = location.state || {};
    const { productName, base64ProductImageString } = product || {};

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <ScreenContainer
                    style={{
                        minHeight: '100vh',
                        background: ` no-repeat center/cover url(${background}), rgba(34, 126, 255, .9)`,
                    }}
                    top="0"
                >
                    <FlexCenteredBlock top={'50px'} mtop="50px">
                        <Gif src={AddedToCartIcon} alt="loading..." />
                        {source === 'Created' && (
                            <CongratsWrapper>
                                <Confetti />
                                <SubTitle
                                    style={{ marginLeft: '4px' }}
                                    font="10px"
                                    weight="400"
                                    lineHeight="16px"
                                    color="#fff"
                                >
                                    Congratulations
                                </SubTitle>
                            </CongratsWrapper>
                        )}
                        <Title size="16px" color="#fff" weight="700" lineHeight="24px" bottom="16px">{`Product ${
                            source || ''
                        } Successfully`}</Title>
                        {product && (
                            <MenuContent
                                onClick={() => {
                                    History.replace({
                                        pathname: '/actions/shop_product_details',
                                        state: {
                                            product,
                                            shopId: shops[0] && shops[0].id,
                                        },
                                    });
                                }}
                            >
                                <ImageWrapper>
                                    <PageLogo
                                        width={'56px'}
                                        height={'56px'}
                                        iconWidth={'28px'}
                                        iconHeight={'35px'}
                                        borderRadius="4px"
                                        background="#EDF2F7"
                                        Icon={base64ProductImageString || DefaultIcon || ''}
                                    />
                                    <OptionName>{productName || ''}</OptionName>
                                    <SubTitle color="#fff" weight="400" lineHeight="16px" font="10px">
                                        <span style={{ fontWeight: '700', color: '#fff' }}>{product.quantity}</span> in
                                        stock
                                    </SubTitle>
                                </ImageWrapper>

                                <PricesContainer>
                                    <Column>
                                        <PricesText>Selling Price</PricesText>
                                        <PricesAmount>{formatPrice(product.retailUnitPrice)}</PricesAmount>
                                    </Column>

                                    {product.costPrice && (
                                        <Column>
                                            <PricesText>Cost Price</PricesText>
                                            <PricesAmount>{formatPrice(product.costPrice)}</PricesAmount>
                                        </Column>
                                    )}
                                </PricesContainer>
                            </MenuContent>
                        )}

                        <RippleButton
                            top={'50px'}
                            style={{
                                backgroundColor: '#ffffff',
                                color: '#22a8ff',
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                            onClick={() =>
                                History.replace({
                                    pathname: '/actions/shop_inventory',
                                    state: shops[0] && shops[0].id,
                                })
                            }
                        >
                            Done
                        </RippleButton>
                        {(source === 'saved' || source === 'Created') && (
                            <RippleButton
                                top={'16px'}
                                border="1px solid #CBD6E0"
                                color="#E9F2FF"
                                backgroundColor="transparent"
                                onClick={() => History.replace('/actions/shop_add_single_product')}
                            >
                                New product
                            </RippleButton>
                        )}
                    </FlexCenteredBlock>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default SingleProductSuccess;
