import styled from 'styled-components';

import { colors } from '../../../../styles';

export const PageTitle = styled.h4`
    font-size: 12px;
    font-weight: 400;
    color: #071827;
    line-height: 18px;
`;

export const PageCount = styled.span`
    font-size: ${({ size }) => size || '10px'};
    font-weight: ${({ weight }) => weight || '500'};
    line-height: ${({ lineHeight }) => lineHeight || '13px'};
    color: ${({ color }) => color || colors.themeColor6};
`;

export const PageHeading = styled.div`
    height: ${({ height }) => height || '88px'};
    padding: 16px;
    margin-top: ${({ top }) => top || '78px'};
    background: linear-gradient(109.36deg, #fdede7 9.97%, #efddf6 94.46%);
    box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
`;

export const PageList = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: ${({ margin }) => margin || '16px'};
    padding: ${({ padding }) => padding || '33px 16px'};
    border-radius: 8px;
    border: 1px solid ${colors.gray3};
    background-color: ${({ bgColor }) => bgColor};
`;
