import React, { Fragment, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { TopHeader, Loader } from '../../../../../../components';
import { ScreenContainer } from '../../../../../../containers/ScreenContainer';
import { addCardInitial } from '../../../../../../redux/ducks/account/wallet/actions';
import {
    sendDebitCardWithPin,
    sendOTPAndVerifyWallet,
} from '../../../../../../redux/ducks/account/wallet/actions/cards/pin-verification';
import DesktopBackgroundLayout from '../../../../../DesktopBackgroundLayout';

import { DebitCardForm } from './forms/card';
import { OTPForm } from './forms/otp';
import OTP3DSecureModal from './forms/otp-3d';
import { EnterPINForm } from './forms/pin';

const AddDebitCard = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.account.wallet.isLoading);
    const msisdn = useSelector((state) => state.user.msisdn);
    const cardToAdd = useRef(null);
    const [enteredPin, setEnteredPin] = useState('');
    const [pinFormOpen, setPinFormOpen] = useState(false);
    const [pinApproved, setPinApproved] = useState(false);
    const [otpModalOpen, setOtpModalOpen] = useState(false);

    if (!pinFormOpen && !pinApproved) {
        return isLoading ? (
            <Loader />
        ) : (
            <Fragment>
                <DesktopBackgroundLayout>
                    <TopHeader title={'Add A Debit Card'} />
                    <ScreenContainer>
                        <DebitCardForm
                            otpModalOpen={otpModalOpen}
                            setOtpModalOpen={setOtpModalOpen}
                            addCardInitial={(debitCard) => dispatch(addCardInitial(debitCard))}
                            setPinFormOpen={setPinFormOpen}
                            pinFormOpen={pinFormOpen}
                            cardToAdd={cardToAdd}
                        />
                        <OTP3DSecureModal
                            otpModalOpen={otpModalOpen}
                            setOtpModalOpen={setOtpModalOpen}
                            debitCard={cardToAdd.current}
                        />
                    </ScreenContainer>
                </DesktopBackgroundLayout>
            </Fragment>
        );
    } else if (pinFormOpen && !pinApproved) {
        return isLoading ? (
            <Loader />
        ) : (
            <Fragment>
                <TopHeader title={'Add A Debit Card'} />
                <ScreenContainer>
                    <EnterPINForm
                        sendDebitCardWithPin={(cardToAdd, pin) => dispatch(sendDebitCardWithPin(cardToAdd, pin))}
                        pinFormOpen={pinFormOpen}
                        setPinFormOpen={setPinFormOpen}
                        pinApproved={pinApproved}
                        setPinApproved={setPinApproved}
                        enteredPin={enteredPin}
                        setEnteredPin={setEnteredPin}
                        cardToAdd={cardToAdd.current}
                    />
                </ScreenContainer>
            </Fragment>
        );
    } else {
        return isLoading ? (
            <Loader />
        ) : (
            <Fragment>
                <DesktopBackgroundLayout>
                    <TopHeader title={'Add A Debit Card'} />
                    <ScreenContainer>
                        <OTPForm
                            sendOTPAndVerifyWallet={(cardToAdd, otp) =>
                                dispatch(sendOTPAndVerifyWallet(cardToAdd, otp))
                            }
                            cardToAdd={cardToAdd.current}
                            msisdn={msisdn}
                        />
                    </ScreenContainer>
                </DesktopBackgroundLayout>
            </Fragment>
        );
    }
};

export default AddDebitCard;
