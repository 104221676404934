import React, { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { pageview } from 'react-ga';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Redirect, withRouter, useLocation } from 'react-router-dom';
import aa from 'search-insights';

import { ClearCache } from './components/cache-cleanup';
import { ProtectedRoute, ErrorBoundary } from './hoc';
import { setOnlineState } from './redux/ducks/offline/actions';
import { updateToken, logoutUser, getFeatures } from './redux/ducks/user/actions';
import { routes, protectedRoutes, openRoutes } from './router';
import ScrollToTop from './scrollToTop';
import { clearNativeCache } from './utils/auth/clearNativeCache';
import { mixPanel } from './utils/mix-panel/mixPanel';
import { NotFoundPage } from './views';

aa('init', {
    appId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    apiKey: process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY,
});

const App = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const showPopUp = useSelector((state) => state.account.notifications.showPopUp);
    const walletStatus = useSelector((state) => state.account.wallet.status);
    const shops = useSelector((state) => state.applications.myShop.shops);
    const isB2BAgent = useSelector((state) => state.user.isB2BAgent);

    const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));
    const isAuthenticated = token ? true : false;
    const refreshToken = localStorage.getItem('refreshToken') && JSON.parse(localStorage.getItem('refreshToken'));
    const fingerPrint = localStorage.getItem('fingerPrint');
    const expirationTime = token && token.expiresIn - new Date();
    const refreshExpirationTime = refreshToken && refreshToken.expiresIn - new Date();

    useEffect(() => {
        if (process.env.REACT_APP_ENV_NAME === 'production') {
            pageview(location.pathname + location.search + location.hash);
        }
    }, [location]);

    useEffect(() => {
        if (
            process.env.REACT_APP_ENV_NAME === 'development' ||
            process.env.REACT_APP_ENV_NAME === 'staging' ||
            process.env.REACT_APP_ENV_NAME === 'production'
        ) {
            if (user.email && user.msisdn && user.userId) {
                Sentry.setUser({
                    id: user.userId,
                    username: `${user.firstName} ${user.lastName}`,
                    email: user.email || 'user@spaceso2o.com',
                    phoneNumber: user.msisdn || '',
                });
                aa('setUserToken', user?.userId);
            }
        }
    }, [user]);

    useEffect(() => {
        if (user && user.userId) {
            const shop = shops && shops.length > 0 && shops[0];
            mixPanel.identify(user.userId);
            mixPanel.people.set({
                $avatar: user.avatar || '',
                $email: user.email || '',
                $first_name: user.firstName || '',
                $last_name: user.lastName || '',
                $phone: user.msisdn || '',
                $created: user.createdAt || '',
                Realm: user.realmNames || '',
                'Wallet status': walletStatus || '',
                'User Id': user.userId || '',
                'Account type': user.role === 'ROLE_USER' ? 'Merchant' : 'Agent',
                'Push notification': showPopUp,
                'Referral status': user.referralCode ? true : false,
                'Referee number': user.referralCode ? user.referralCode : '',
                'Business category': (shop && shop.businessCategories) || [],
                'Shop Id': (shop && shop.branchId) || '',
                State: (user.regionData && user.regionData.state) || '',
                'Local government': (user.regionData && user.regionData.lga) || '',
            });
        }
    }, [user, walletStatus, showPopUp, shops]);

    useEffect(() => {
        if (expirationTime < 200000 && refreshExpirationTime > 0) dispatch(updateToken());
    }, [expirationTime, dispatch, refreshExpirationTime]);

    useEffect(() => {
        if (token && !fingerPrint) {
            dispatch(logoutUser());
        }
    }, [fingerPrint, dispatch, token]);

    useEffect(() => {
        if (token && expirationTime <= 0 && refreshToken && refreshExpirationTime <= 0) dispatch(logoutUser());
    }, [expirationTime, token, refreshToken, dispatch, refreshExpirationTime]);

    useEffect(() => {
        const referralCode = location.search && location.search.split('referralCode=')[1];
        if (referralCode && referralCode.length > 0) {
            localStorage.setItem('referralCode', referralCode);
        }
    }, [location]);

    useEffect(() => {
        window.addEventListener('offline', () => {
            dispatch(setOnlineState(!navigator.onLine));
        });

        return () => window.removeEventListener('offline');
    }, [dispatch]);

    useEffect(() => {
        window.addEventListener('online', () => {
            dispatch(setOnlineState(!navigator.onLine));
        });

        return () => window.removeEventListener('online');
    }, [dispatch]);

    useEffect(() => {
        clearNativeCache();
    }, []);

    useEffect(() => {
        dispatch(getFeatures());
    }, [dispatch, location]);

    if (
        process.env.REACT_APP_ENV_NAME === 'development' ||
        process.env.REACT_APP_ENV_NAME === 'staging' ||
        process.env.REACT_APP_ENV_NAME === 'production'
    ) {
        return (
            <Sentry.ErrorBoundary fallback={'An error has occured'}>
                <ClearCache />
                <ScrollToTop />
                <Switch>
                    {openRoutes.map((route) => (
                        <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />
                    ))}
                    {protectedRoutes.map((route) => (
                        <ProtectedRoute
                            key={route.path}
                            isAuthenticated={isAuthenticated}
                            path={route.path}
                            component={route.main}
                            exact={route.exact}
                            public={route.public}
                        />
                    ))}
                    {routes.map((route) =>
                        isAuthenticated ? (
                            isB2BAgent ? (
                                <Redirect to="/actions/remittance/dashboard" key="redirect" />
                            ) : (
                                <Redirect to="/my-shop" key="redirect" />
                            )
                        ) : (
                            <Route key={route.path} path={route.path} exact={route.exact} component={route.main} />
                        ),
                    )}
                    <Route component={NotFoundPage} />
                </Switch>
            </Sentry.ErrorBoundary>
        );
    } else {
        return (
            <ErrorBoundary>
                <ClearCache />
                <ScrollToTop />
                <Switch>
                    {openRoutes.map((route) => (
                        <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />
                    ))}
                    {protectedRoutes.map((route) => (
                        <ProtectedRoute
                            key={route.path}
                            isAuthenticated={isAuthenticated}
                            path={route.path}
                            component={route.main}
                            exact={route.exact}
                            public={route.public}
                        />
                    ))}
                    {routes.map((route) =>
                        isAuthenticated ? (
                            <Redirect to="/" key="redirect" />
                        ) : (
                            <Route key={route.path} path={route.path} exact={route.exact} component={route.main} />
                        ),
                    )}
                    <Route component={NotFoundPage} />
                </Switch>
            </ErrorBoundary>
        );
    }
};

export default Sentry.withProfiler(withRouter(App));
