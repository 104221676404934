import Geocode from 'react-geocode';
const geocodeKey = process.env.REACT_APP_GEOCODE_KEY;

export const getAddressName = async (lat, lng) => {
    Geocode.setApiKey(geocodeKey);
    Geocode.setLanguage('en');
    Geocode.setRegion('ng');

    if (lat && lng) {
        const addressArray = await Geocode.fromLatLng(lat, lng);
        const address = addressArray.results[0] ? addressArray.results[0].formatted_address : '';

        return address;
    }
    return;
};

export const getCoordinateFromAddress = async (address) => {
    Geocode.setApiKey(geocodeKey);
    Geocode.setLanguage('en');
    Geocode.setRegion('ng');

    if (address) {
        const coordinate = await Geocode.fromAddress(address);
        const { lat, lng } = coordinate.results[0] ? coordinate.results[0].geometry.location : {};
        return { lat, lng };
    }
    return {};
};
