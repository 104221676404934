import React, { Fragment, useState, useEffect } from 'react';

import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { TopHeader, PageLogo, RippleButton, InputWithLabel } from '../../../../../components';
import { InputBlock } from '../../../../../containers/InputContainer';
import { PageTitleWrapper, PageTitle, PageSubTitle } from '../../../../../containers/MessageContainer';
import { ScreenContainer, PageProgressLine } from '../../../../../containers/ScreenContainer';
import { checkExistingEmail, checkReferralCode } from '../../../../../redux/ducks/auth/check/actions';
import { getReferral } from '../../../../../redux/ducks/user/actions';
import { colors } from '../../../../../styles';
import { AUTH_PROVIDE_ACCOUNT_INFO } from '../../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../../utils/mix-panel/mixPanel';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import StoreIcon from '../business-profile/assets/store.svg';

import { ReactComponent as Spinner } from './assets/loading-spinner.svg';
import ShopIcon from './assets/shopper.svg';
import UserAvatar from './assets/User_avatar.svg';
import { UserSignUpValidationSchema } from './UserSignUpValidationSchema';

const PageProgressWrap = styled.div`
    position: absolute;
    display: flex;
    right: 15px;
    color: ${colors.themeTextColor0};
    z-index: 100;
`;

const PageProgressStep = styled.span`
    font-size: 14px;
    color: ${colors.themeTextColor1};
`;

const InputGroupWrapper = styled.div`
    position: relative;
`;

const LoaderSpin = styled(Spinner)`
    position: absolute;
    display: flex;
    top: 14px;
    right: 14px;
    width: 20px;
    height: 20px;
    transform: rotate(360deg);
    animation: ld-spinner 1.8s linear infinite;
    @keyframes ld-spinner {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0.3;
        }
    }
`;

const InputLabelName = styled.div`
    position: absolute;
    top: 52px;
    color: #071827;
    font-size: 11px;
    letter-spacing: 0.01em;
    margin: 0 15px;
`;

const CardDetailsWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 180px;
    background-color: ${colors.deepBlue};
    border-radius: 8px;
    padding: 25px 16px;
    overflow: hidden;
    margin-top: ${({ top }) => top || '40px'};

    &::before {
        content: '';
        position: absolute;
        top: -70px;
        left: -45px;
        background: #0350bb;
        width: 176px;
        height: 176px;
        border-radius: 100%;
        opacity: 0.2;
    }

    &::after {
        content: url(${ShopIcon});
        position: absolute;
        bottom: -3px;
        right: 0px;
    }
`;

const CardBusinessName = styled.h4`
    position: relative;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
`;
const CardBusinessAddress = styled.p`
    position: relative;
    display: flex;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    line-height: 18px;
    margin: 5px 0;
`;
const CardBusinessLocation = styled.p`
    position: absolute;
    display: flex;
    bottom: 15px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 18px;
`;

const MerchantSignUp = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const { msisdn: phoneNumber, country } = user && user;
    const { isLoading: loadingAccountReferral, referral } = useSelector((state) => state.user.accountReferral);
    const [referralLinkCode] = useState(localStorage.getItem('referralCode') || '');
    let userData = localStorage.getItem('userData');
    const { firstName, lastName, email, referralCode, referralNumber, referringAgentName, businessProfileData } =
        (userData && JSON.parse(userData)) || {};
    const { referralCode: agentReferralCode, email: userEmail } = businessProfileData || {};
    const [userReferralNumber, setUserReferralNumber] = useState();

    useEffect(() => {
        userReferralNumber && dispatch(getReferral(userReferralNumber));
    }, [dispatch, userReferralNumber]);

    const { firstName: refFirstName, lastName: refLastName } = referral;
    const referralName = refFirstName || refLastName ? `${refFirstName}  ${refLastName}` : 'Invalid Referral Number';

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Seller Account'}>
                    <PageProgressWrap>
                        <PageProgressStep>2</PageProgressStep>/4
                    </PageProgressWrap>
                    <PageProgressLine lineWidth={'50%'} />
                </TopHeader>
                {firstName === ' ' ? (
                    <ScreenContainer paddingBottom={'40px'}>
                        <PageLogo
                            top={'75px'}
                            margin={'auto'}
                            borderRadius={'0'}
                            Icon={StoreIcon}
                            iconWidth="72px"
                            iconHeight="72px"
                        />
                        <PageTitleWrapper mtop={'10px'} mbottom={'35px'}>
                            <PageTitle size={'16px'} top={'16px'} bottom={'6px'}>
                                {firstName}, we found a store you created
                            </PageTitle>
                            <PageSubTitle margin={'0'}>You can change this store information later</PageSubTitle>
                        </PageTitleWrapper>
                        <CardDetailsWrapper>
                            <CardBusinessName></CardBusinessName>
                            <CardBusinessAddress></CardBusinessAddress>
                            <CardBusinessLocation>State</CardBusinessLocation>
                        </CardDetailsWrapper>
                        <RippleButton type="submit" top={'auto'}>
                            Continue to your store
                        </RippleButton>
                    </ScreenContainer>
                ) : (
                    <ScreenContainer>
                        <PageLogo top={'75px'} margin={'auto'} Icon={UserAvatar} iconWidth="72px" iconHeight="72px" />
                        <PageTitleWrapper mtop={'10px'} mbottom={'35px'}>
                            <PageTitle size={'16px'} top={'16px'} bottom={'6px'}>
                                Personal information
                            </PageTitle>
                            <PageSubTitle margin={'0'}>Let's get to know more about you</PageSubTitle>
                        </PageTitleWrapper>
                        <Formik
                            initialValues={{
                                firstName: firstName !== 'Not Set' ? firstName : '',
                                lastName: lastName !== 'Not Set' ? lastName : '',
                                email: userEmail || email || '',
                                referralNumber:
                                    agentReferralCode || (referralNumber !== 'Not Set' ? referralNumber : ''),
                                referringAgentName,
                                referralCode: referralCode
                                    ? referralCode.trim()
                                    : referralLinkCode
                                    ? referralLinkCode.trim()
                                    : '',
                                country,
                                phoneNumber,
                            }}
                            enableReinitialize={true}
                            validationSchema={UserSignUpValidationSchema}
                            onSubmit={(values, { setErrors }) => {
                                setTimeout(async () => {
                                    if (!values.email) values.email = `${phoneNumber}@spaceso2o.com`;

                                    const hasExistingEmail = await dispatch(checkExistingEmail(values.email));
                                    if (hasExistingEmail === undefined) return;
                                    if (hasExistingEmail) return setErrors({ email: 'Email already exists' });

                                    if (values.referralNumber) {
                                        const refCode = values.referralNumber.replace(/\s/g, '');
                                        if (refCode && refCode.length < 11) {
                                            toast.error('Invalid referral number');
                                            return;
                                        }

                                        const refNumber = `0${refCode.substr(refCode.length - 10)}`;
                                        var isSameUser = phoneNumber.localeCompare(refNumber);
                                        if (isSameUser === 0) {
                                            toast.error('You can not use your referral code here');
                                            return;
                                        }

                                        const isValidReferral = await dispatch(checkReferralCode(refNumber));
                                        if (!isValidReferral) {
                                            return;
                                        }
                                    }

                                    userData = JSON.parse(userData);
                                    userData = { ...userData, ...values, referralCode: values.referralNumber };
                                    localStorage.setItem('userData', JSON.stringify(userData));
                                    mixPanel.track(AUTH_PROVIDE_ACCOUNT_INFO, {
                                        'Was Referred': values.referralNumber !== '',
                                        Role: 'Merchant',
                                        'Email Provided': !values.email.includes('@spaceso2o.com'),
                                    });
                                    history.push('/user/create_business_profile');
                                }, 200);
                            }}
                        >
                            {({ errors, touched, setFieldValue, values, initialValues }) => {
                                if (values.referralNumber && values.referralNumber.length === 11) {
                                    setUserReferralNumber(values.referralNumber);
                                }

                                return (
                                    <Form>
                                        <InputBlock height={'60vh'}>
                                            <InputWithLabel
                                                label={'First name'}
                                                type={'text'}
                                                value={values.firstName}
                                                placeholder={'First name'}
                                                name="firstName"
                                                valid={`${!touched.firstName && !errors.firstName}`}
                                                errors={touched && touched.firstName && errors && errors.firstName}
                                                setFieldValue={setFieldValue}
                                                initialValues={initialValues}
                                            />
                                            <InputWithLabel
                                                label={'Last name'}
                                                type={'text'}
                                                value={values.lastName}
                                                placeholder={'Last name'}
                                                name="lastName"
                                                valid={`${touched.lastName && !errors.lastName}`}
                                                errors={touched && touched.lastName && errors && errors.lastName}
                                                setFieldValue={setFieldValue}
                                                initialValues={initialValues}
                                            />
                                            <InputWithLabel
                                                label={'Email'}
                                                type={'email'}
                                                inputMode={'email'}
                                                value={values.email}
                                                placeholder={'Email'}
                                                name="email"
                                                valid={`${!touched.email && !errors.email}`}
                                                errors={touched && touched.email && errors && errors.email}
                                                setFieldValue={setFieldValue}
                                                initialValues={initialValues}
                                            />
                                            <InputGroupWrapper>
                                                <InputWithLabel
                                                    label={'Referral Number'}
                                                    type={'text'}
                                                    value={values.referralNumber}
                                                    placeholder={'Referral Number'}
                                                    name="referralNumber"
                                                    valid={`${!touched.referralNumber && !errors.referralNumber}`}
                                                    errors={
                                                        touched &&
                                                        touched.referralNumber &&
                                                        errors &&
                                                        errors.referralNumber
                                                    }
                                                    setFieldValue={setFieldValue}
                                                    initialValues={initialValues}
                                                    noClearButton={loadingAccountReferral === 'LOADING'}
                                                />

                                                {loadingAccountReferral === 'LOADING' && <LoaderSpin />}
                                                {loadingAccountReferral === 'SUCCESSFUL' && (
                                                    <InputLabelName>{referralName}</InputLabelName>
                                                )}
                                            </InputGroupWrapper>
                                            <RippleButton type="submit" top={'auto'}>
                                                Continue
                                            </RippleButton>
                                        </InputBlock>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </ScreenContainer>
                )}
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default MerchantSignUp;
