import styled from 'styled-components';

export const BlurBackground = styled.div`
    height: 100%;
    position: ${({ position }) => position || 'relative'};
    min-height: ${({ minHeight }) => minHeight || null};
    z-index: ${({ zIndex }) => zIndex || '200'};
    width: 100%;
    max-width: inherit;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(1.5px);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    -webkit-animation: fadeIn 0.2s ease-in-out both;
    animation: fadeIn 0.2s ease-in-out both;
    animation-delay: 0s;
    transition: all 0.2s ease-in;
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    &.fadeOut {
        -webkit-animation: fadeOut 0.3s ease-in-out both;
        animation: fadeOut 0.3s ease-in-out both;
        transition: all 0.3s ease-in-out;

        @keyframes fadeOut {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }
    }
`;
