import React, { Fragment, useEffect, useState } from 'react';

import moment from 'moment';
import Chart from 'react-apexcharts';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
    ApprovalStatusPopup,
    NotificationPopup,
    ComingSoon,
    RippleLink,
    PageLogo,
    RippleButton,
} from '../../../components';
import { PopUp, PopUpContent } from '../../../components/popup/common';
import { FeatureUndergoingFixes } from '../../../components/popup/feature-undergoing-fixes';
import { NpsPopup } from '../../../components/popup/nps-popup';
import { List, ListItem, ListHeading, ListSubHeading } from '../../../containers/ListContainer';
import { Message } from '../../../containers/MessageContainer';
import { Overlay, ActionBlock } from '../../../containers/OverlayContainer';
import { FlexCenteredBlock, FlexContainer } from '../../../containers/ScreenContainer';
import { subscribeUser, sendSubscription } from '../../../redux/ducks/account/notifications/actions/push/index';
import { SHOW_POPUP } from '../../../redux/ducks/account/notifications/constants';
import { postSurvey } from '../../../redux/ducks/user/actions/nps';
import { colors } from '../../../styles';
import { countAmount } from '../../../utils/currency/countAmount';
import { formatPrice } from '../../../utils/currency/formatPriceWithComma';
import { parseTransactionsByDate } from '../../../utils/date/parseTransactionsByDate';
import History from '../../../utils/History';
import { SHOP_START_CREATE_SALE } from '../../../utils/mix-panel/constants';
import { mixPanel } from '../../../utils/mix-panel/mixPanel';
import { Title } from '../../actions/merchbuy/group-buy/styles';
import { ReactComponent as AddShopIcon } from '../../actions/my-shop/shop/shop-details/assets/add_shop.svg';
import SalesIcon from '../../actions/my-shop/shop/shop-details/assets/cart.svg';
import { ReactComponent as CloseOverlayIcon } from '../../actions/my-shop/shop/shop-details/assets/close_overlay.svg';
import { ReactComponent as ShoppingIcon } from '../../actions/my-shop/shop/shop-details/assets/shopper.svg';
import {
    NoSalesContainer,
    OverlayOptions,
    OverlayRow,
    OverlayRowText,
    ShopStatsContainer,
} from '../../actions/my-shop/shop/shop-details/styles';

import { ReactComponent as ConfettiSvg } from './assets/confetti.svg';
import { ReactComponent as CustomersSvg } from './assets/customers.svg';
import { ReactComponent as DoucmentSvg } from './assets/document.svg';
import { ReactComponent as MinusUserSvg } from './assets/minus-user.svg';
import PaymentLinkBg from './assets/payment-link-bg.svg';
import { ReactComponent as PaymentSvg } from './assets/payment.svg';
import { ReactComponent as PlusSvg } from './assets/plus.svg';
import { ReactComponent as ProductsSvg } from './assets/products.svg';
import { ReactComponent as RedRightArrowSvg } from './assets/red-right-arrow.svg';
import { ReactComponent as ReportsSvg } from './assets/reports-new.svg';
import ShopperSvg from './assets/shopper.svg';
import { ReactComponent as VacationSvg } from './assets/vacation.svg';
import WalletBackground from './assets/wallet-bg.svg';
import WalletIcon from './assets/wallet_unavailable.svg';
import TermsInfoOverlay from './termsInfoOverlay';

const CloseOverlayWrapper = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    animation: floatButtons 0.2s ease forwards;
    @keyframes floatButtons {
        0% {
            opacity: 0;
            transform: scale(0) rotateZ(0deg);
        }
        20% {
            opacity: 0;
            transform: scale(1.1) rotateZ(-3deg) translateY(46px);
        }
        50% {
            transform: scale(0.8) rotateZ(5deg) translateY(-5px);
        }
        70% {
            transform: scale(1.1) rotateZ(-3deg) translateY(2px);
        }
        100% {
            opasity: 1;
            transform: scale(1) rotateZ(0deg) translateY(0px);
        }
    }
`;

const CloseOverlay = styled(CloseOverlayIcon)`
    cursor: pointer;
`;

const AddIconWrapperEmptyScreen = styled.div`
    position: fixed;
    display: flex;
    bottom: 65px;
    right: 0;
    left: 0;
    width: 100%;
    height: auto;
    justify-content: flex-end;
    margin: auto;
    z-index: 10;
    @media (min-width: 570px) {
        display: flex;
        bottom: 65px;
        right: 0;
        left: 0;
        width: 375px;
        height: auto;
        justify-content: flex-end;
        margin: auto;
    }
`;

const Text = styled.p`
    color: ${({ color }) => color || colors.white};
    font-size: ${({ fontSize }) => fontSize || '10px'};
    line-height: ${({ lineHeight }) => lineHeight || '15px'};
    font-weight: ${({ fontWeight }) => fontWeight || '400'};
    color: ${({ color }) => color || colors.white};
    margin: 0;
    margin-top: ${({ top }) => top || 0};
    padding: 0;
    text-align: ${({ align }) => align || null};
`;

const FlexWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: ${({ margin }) => margin || null};
`;

const ShopDetailWrapper = styled(FlexWrapper)`
    column-gap: 8px;
    align-items: center;
`;

const WalletWrapper = styled(FlexWrapper)`
    border-radius: 16px;
    height: 178px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgb(78, 110, 242);
    align-items: center;
    background-image: url(${WalletBackground});
    padding: 19px 16px 16px;
    display: flex;
    flex-direction: column;
    justify-content: initial;
    align-items: center;
    position: relative;
    z-index: 99;
    box-shadow: 0px 15px 20px #dae1fd;
`;

const AnalysisWrapper = styled.div`
    width: 167px;
    height: 85px;
    max-height: 85px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 18px 16px 16px 14px;
    box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
    &:hover {
        cursor: pointer;
    }
    @media (max-width: 355px) {
        width: 120px;
    }
    @media (max-width: 307px) {
        width: 110px;
    }
`;

const DebtorsWrapper = styled(FlexWrapper)`
    background-color: white;
    border-radius: 16px;
    padding: 24px 22.7px 18.5px 18px;
    position: relative;
    align-items: flex-end;
    height: 120px;
    top: -55px;
    z-index: 0;
`;

const ShopTitleText = styled.p`
    margin: 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #718596;
`;

const AnalysisTotalWrapper = styled.div`
    min-width: 28px;
    height: 24px;
    border-radius: 24px;
    padding: 4px 8px;
    background-color: #edf2f7;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const GreyText = styled.p`
    color: #718596;
    font-weight: ${({ weight }) => weight || '400'};
    margin: 0;
    font-size: 12px;
    line-height: 18px;
`;

const RedText = styled.span`
    color: #cd4213;
`;

const PaymentWrapper = styled.div`
    width: calc(100% - 32px);
    margin: 16px auto 59px;
    height: 125px;
    background: url(${PaymentLinkBg}) no-repeat center, linear-gradient(94.68deg, #7e42f5 16.08%, #227eff 262.55%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 21px 0 21px 20px;
    border-radius: 8px;
`;

const AtmLargeText = styled.p`
    margin: 25px 0 0 -10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #fff;
`;

const DifferenceWrapper = styled.div`
    background-color: ${({ color }) => color || '#D3FDE9'};
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 32px);
    margin-top: 17px;
    border-radius: 4px;
`;

const Dot = styled.span`
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #718596;
    display: inline-block;
`;

const Wrapper = styled.div`
    padding: 0 1em;
    animation: fromRightTransform 0.5s ease;
    @keyframes fromRightTransform {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(0%);
        }
    }
`;

const Overview = () => {
    const dispatch = useDispatch();
    const debtors = useSelector((state) => state.applications.myShop.debtors);
    const surveyTypes = useSelector((state) => state.user.surveyTypes);
    const NpsSurveyType = surveyTypes?.find((type) => type.title === 'NPS SURVEY');
    const surveyWindow = useSelector((state) => state.user.surveyWindow);
    const features = useSelector((state) => state.user.features);
    const lastSurveyResponse = useSelector((state) => state?.user?.lastSurveyResponse);
    const userId = useSelector((state) => state.user.userId);
    const showNotifcationPopup = useSelector((state) => state.account.notifications.showPopUp || false);
    const agentState = useSelector((state) =>
        state.user.agentState ? state.user.agentState.toUpperCase() : 'PENDING',
    );
    const shops = useSelector((state) => state.applications.myShop.shops);
    const sales = useSelector((state) => state.applications.myShop.sales);
    const shop = shops[0];
    const customers = useSelector((state) => state.applications.myShop.customers);
    const [monthlySales, setMonthlySales] = useState(formatPrice(0));
    const [, setClassNames] = useState('');
    const [openOptionsOverlay, setOpenOptionsOverlay] = useState(false);
    const [openNps, setOpenNps] = useState(false);
    const [statusPopup, setStatusPopup] = useState(false);
    const [notificationPopup, setNotificationPopup] = useState(false);
    const [openTermsOverlay, setOpenTermsOverlay] = useState(false);
    const [openWallet, setOpenWallet] = useState(false);
    const [openPaymentPopup, setOpenPaymentPopup] = useState(false);
    const [options, setOptions] = useState({});
    const [series, setSeries] = useState([]);
    const currentDay = moment().format('dddd');
    const [difference, setDifference] = useState(0);
    const [todayTotal, setTodayTotal] = useState(formatPrice(0));
    const [openFeatureFixes, setOpenFeatureFixes] = useState(false);

    const setDateObject = () => {
        const surveyDate = moment().add(2, 'days');
        localStorage.setItem('surveyDate', surveyDate);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return (_) => window.removeEventListener('scroll', handleScroll);
    }, []);

    const amountFormatter = (num) => {
        const lookup = [
            { value: 1, symbol: '' },
            { value: 1e3, symbol: 'k' },
            { value: 1e6, symbol: 'M' },
            { value: 1e9, symbol: 'B' },
            { value: 1e12, symbol: 'T' },
        ];
        const regex = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let item = lookup
            .slice()
            .reverse()
            .find(function (item) {
                return num >= item.value;
            });
        return item ? (num / item.value).toFixed(1).replace(regex, '$1') + item.symbol : '0';
    };

    const isDayLastSeven = (date) => {
        const today = moment();
        return today.diff(moment(new Date(date)), 'days') <= 7;
    };

    useEffect(() => {
        const currentMonth = moment().format('MMMM');
        const currentYear = moment().format('YYYY');
        const groupedSales = parseTransactionsByDate(
            sales.map((sale) => sale.salesInfo),
            'localSalesDate',
        );
        const monthSales =
            groupedSales && groupedSales.find((sales) => sales.date.includes(`${currentMonth} ${currentYear}`));
        monthSales && setMonthlySales(countAmount(monthSales.transactions, 'totalAmount'));

        const sevenDaySales = sales.filter((item) => isDayLastSeven(item?.salesInfo?.localSalesDate));
        const oneDay = sevenDaySales.filter(
            (item) => moment().diff(moment(new Date(item?.salesInfo?.localSalesDate)), 'days') === 0,
        );
        const oneDayTotal = oneDay.reduce((acc, curr) => acc + curr?.salesInfo?.totalAmount, 0) || 0;
        setTodayTotal(oneDayTotal);
        const twoDay =
            sevenDaySales.filter(
                (item) => moment().diff(moment(new Date(item?.salesInfo?.localSalesDate)), 'days') === 1,
            ) || 0;
        const twoDayTotal = twoDay.reduce((acc, curr) => acc + curr?.salesInfo?.totalAmount, 0) || 0;
        const threeDay = sevenDaySales.filter(
            (item) => moment().diff(moment(new Date(item?.salesInfo?.localSalesDate)), 'days') === 2,
        );
        const threeDayTotal = threeDay.reduce((acc, curr) => acc + curr?.salesInfo?.totalAmount, 0) || 0;
        const fourDay = sevenDaySales.filter(
            (item) => moment().diff(moment(new Date(item?.salesInfo?.localSalesDate)), 'days') === 3,
        );
        const fourDayTotal = fourDay.reduce((acc, curr) => acc + curr?.salesInfo?.totalAmount, 0) || 0;
        const fiveDay = sevenDaySales.filter(
            (item) => moment().diff(moment(new Date(item?.salesInfo?.localSalesDate)), 'days') === 4,
        );
        const fiveDayTotal = fiveDay.reduce((acc, curr) => acc + curr?.salesInfo?.totalAmount, 0) || 0;
        const sixDay = sevenDaySales.filter(
            (item) => moment().diff(moment(new Date(item?.salesInfo?.localSalesDate)), 'days') === 5,
        );
        const sixDayTotal = sixDay.reduce((acc, curr) => acc + curr?.salesInfo?.totalAmount, 0) || 0;
        const sevenDay = sevenDaySales.filter(
            (item) => moment().diff(moment(new Date(item?.salesInfo?.localSalesDate)), 'days') === 6,
        );
        const sevenDayTotal = sevenDay.reduce((acc, curr) => acc + curr?.salesInfo?.totalAmount, 0) || 0;
        const amounts = [
            oneDayTotal,
            twoDayTotal,
            threeDayTotal,
            fourDayTotal,
            fiveDayTotal,
            sixDayTotal,
            sevenDayTotal,
        ];

        const days = {
            one: moment().format('ddd'),
            two: moment().subtract(1, 'days').format('ddd'),
            three: moment().subtract(2, 'days').format('ddd'),
            four: moment().subtract(3, 'days').format('ddd'),
            five: moment().subtract(4, 'days').format('ddd'),
            six: moment().subtract(5, 'days').format('ddd'),
            seven: moment().subtract(6, 'days').format('ddd'),
        };
        setOptions({
            chart: { id: 'basic-bar' },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: [
                    days['seven'],
                    days['six'],
                    days['five'],
                    days['four'],
                    days['three'],
                    days['two'],
                    days['one'],
                ],
            },
            yaxis: {
                labels: {
                    show: true,
                    formatter: (value) => {
                        return amountFormatter(value);
                    },
                },
            },
            colors: ['#227EFF'],
        });
        setSeries([
            {
                name: 'Sales',
                data: amounts.reverse(),
            },
        ]);
        const differenceBtwLast2Months = amounts && amounts[amounts.length - 1] - (amounts[amounts.length - 2] || 0);
        setDifference(differenceBtwLast2Months);
    }, [dispatch, sales]);

    useEffect(() => {
        if (showNotifcationPopup) {
            //default  //denied  //granted
            if ('Notification' in window && Notification.permission === 'default') {
                setNotificationPopup(!notificationPopup);
            }
            // Safari/Android webview [call native code]
            else if (window.NativeAndroid && !window.NativeAndroid.getBoolean('notification.permission')) {
                setNotificationPopup(!notificationPopup);
            } else {
                dispatch({ type: SHOW_POPUP, payload: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showNotifcationPopup]);

    useEffect(() => {
        const response = shouldDisplayNps(surveyWindow, lastSurveyResponse, features);
        setTimeout(() => {
            setOpenNps(response);
        }, 2500);
    }, [features, lastSurveyResponse, surveyWindow]);

    const shouldDisplayNps = (surveyWindow, lastSurveyResponse, features) => {
        if (surveyWindow) {
            if (!Object.entries(surveyWindow).length) return false;
            if (!features?.npsSurvey?.status) return false;

            const endDate = moment(surveyWindow.startDate).add(Number(surveyWindow.length), 'days');

            if (!moment(new Date()).isBetween(moment(surveyWindow.startDate), moment(endDate), 'day', '[]')) {
                return false;
            }

            const dateShown = localStorage.getItem('surveyDate');

            if (
                lastSurveyResponse?.responded &&
                moment(lastSurveyResponse?.createdAt).isBetween(
                    moment(surveyWindow.startDate),
                    moment(endDate),
                    'day',
                    '[]',
                ) &&
                !dateShown
            )
                return false;

            if (
                moment(new Date()).isSame(moment(dateShown), 'day', '[]') &&
                !moment(lastSurveyResponse?.createdAt).isBetween(
                    moment(surveyWindow.startDate),
                    moment(endDate),
                    'day',
                    '[]',
                )
            )
                return true;

            const difference = moment().diff(moment(dateShown), 'days');
            if (difference < 0) return false;

            return true;
        }
    };

    const handleUpdateNps = (info) => {
        dispatch(postSurvey(info));
        setOpenNps(false);
    };

    const handleScroll = () => {
        const scrollY = window.scrollY;
        if (scrollY < 50) setClassNames('');
        if (scrollY > 50) setClassNames('firstStep');
        if (scrollY > 100) setClassNames('secondStep');
        if (scrollY > 120) setClassNames('thirdStep');
        if (scrollY > 140) setClassNames('fourthStep');
    };

    const subscribeToNotification = () => {
        if ('Notification' in window) {
            dispatch(subscribeUser());
            dispatch({ type: SHOW_POPUP, payload: false });
        } else if (window.NativeAndroid) {
            var token = window.NativeAndroid.getString('firebase.token');
            if (token) {
                var payload = { android: { token: token, topics: [] } };
                dispatch(sendSubscription(payload));
                window.NativeAndroid.saveBoolean('notification.permission', true);
            }
            dispatch({ type: SHOW_POPUP, payload: false });
        }
    };

    const formatDate = (date) => {
        const hours = moment(new Date(date)).format('hh');
        const minutes = moment(new Date(date)).format('mm');
        const interv = moment(new Date(date)).format('A');

        let day;

        if (moment(new Date(date)).isSame(new Date(), 'day')) {
            day = `${hours}:${minutes} ${interv} Today`;
        } else if (moment(new Date(date)).isSame(moment().subtract(1, 'day'), 'day')) {
            day = `${hours}:${minutes} ${interv} Yesterday`;
        } else {
            day = moment(new Date(date)).format('MMM D');
        }

        return day;
    };

    return (
        <Wrapper>
            {shop && Object.keys(shop).includes('branchId') ? (
                <Fragment>
                    <WalletWrapper>
                        <Text lineHeight="16px" fontWeight="700" style={{ textTransform: 'uppercase' }}>
                            Today Sales
                        </Text>
                        <Text fontSize="24px" lineHeight="36px" fontWeight="700">
                            {formatPrice(todayTotal)}
                        </Text>
                        <Text align="center" lineHeight="18px" fontSize="12px" fontWeight="700" top="2px">
                            This month: <span style={{ color: '#D3FDE9' }}>{monthlySales}</span>
                        </Text>
                        <ActionBlock direction="row">
                            <RippleButton
                                mediaFontSize
                                color="#E9F2FF"
                                backgroundColor="transparent"
                                border="1.5px solid #E9F2FF"
                                height="40px"
                                width="151px"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '10px 0 10px 22px',
                                }}
                                onClick={() => History.push('/actions/shop_sales')}
                            >
                                <DoucmentSvg style={{ marginRight: '11px' }} />
                                Sales History
                            </RippleButton>

                            <RippleButton
                                mediaFontSize
                                color={colors.deepBlue}
                                backgroundColor="#fff"
                                height="40px"
                                width="151px"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '10px 0 10px 22px',
                                }}
                                onClick={() => {
                                    mixPanel.track(SHOP_START_CREATE_SALE, {
                                        'Entry Point': 'MyShop Home',
                                        Time: new Date().toLocaleDateString(),
                                        'User ID': userId,
                                        'shop ID': shop && shop.id,
                                    });
                                    History.push({
                                        pathname: '/actions/shop_sales_add',
                                        state: {
                                            merchantId: shop && shop.merchantId,
                                            branchId: shop && shop.branchId,
                                        },
                                    });
                                }}
                            >
                                <PlusSvg style={{ marginRight: '10px' }} />
                                Record Sale
                            </RippleButton>
                        </ActionBlock>
                    </WalletWrapper>

                    <RippleLink
                        top={'16px'}
                        bottom={'0px'}
                        to={{
                            pathname: '/actions/debtors',
                            state: {
                                paymentStatus: 'Incomplete Payment',
                            },
                        }}
                    >
                        <DebtorsWrapper>
                            <div style={{ display: 'flex' }}>
                                <MinusUserSvg style={{ marginRight: '10px' }} />
                                <Text fontSize="14px" lineHeight="21px" fontWeight="500" color="#071827">
                                    Debtors
                                </Text>
                            </div>
                            <ShopDetailWrapper>
                                <Text fontSize="14px" lineHeight="21px" fontWeight="700" color="#A31300">
                                    {formatPrice(debtors?.totalDebtAmount)}
                                </Text>
                                <RedRightArrowSvg />
                            </ShopDetailWrapper>
                        </DebtorsWrapper>
                    </RippleLink>

                    <ShopTitleText style={{ margin: '-25px 0 8px' }}>Shop Activities</ShopTitleText>
                    <FlexWrapper>
                        <AnalysisWrapper onClick={() => History.push('/actions/shop_sales?step=1')}>
                            <div>
                                <ReportsSvg />
                                <Text top="10px" fontSize="14px" lineHeight="21px" fontWeight="500" color="#071827">
                                    Reports
                                </Text>
                            </div>
                        </AnalysisWrapper>

                        <AnalysisWrapper onClick={() => History.push('/actions/shop_inventory')}>
                            <div>
                                <ProductsSvg />
                                <Text top="10px" fontSize="14px" lineHeight="21px" fontWeight="500" color="#071827">
                                    Products
                                </Text>
                            </div>
                            {shop && (
                                <AnalysisTotalWrapper>
                                    <Text fontSize="10px" lineHeight="16px" fontWeight="500" color="#718596">
                                        {shop.inventory.length}
                                    </Text>
                                </AnalysisTotalWrapper>
                            )}
                        </AnalysisWrapper>
                    </FlexWrapper>
                    <FlexWrapper margin="8px 0 33px">
                        <AnalysisWrapper onClick={() => History.push('/actions/shop_customers')}>
                            <div>
                                <CustomersSvg />
                                <Text top="10px" fontSize="14px" lineHeight="21px" fontWeight="500" color="#071827">
                                    Customers
                                </Text>
                            </div>
                            {customers && (
                                <AnalysisTotalWrapper>
                                    <Text fontSize="10px" lineHeight="16px" fontWeight="500" color="#718596">
                                        {customers.length}
                                    </Text>
                                </AnalysisTotalWrapper>
                            )}
                        </AnalysisWrapper>

                        <AnalysisWrapper
                            onClick={() => {
                                if (!features?.paymentLinks?.status) return setOpenFeatureFixes(true);
                                History.push('/actions/payment-links');
                            }}
                        >
                            <div>
                                <PaymentSvg />
                                <Text top="10px" fontSize="14px" lineHeight="21px" fontWeight="500" color="#071827">
                                    Payment links
                                </Text>
                            </div>
                        </AnalysisWrapper>
                    </FlexWrapper>

                    <ShopStatsContainer>
                        {sales.length > 0 ? (
                            <Fragment>
                                <FlexContainer top={'33px'} style={{ marginBottom: '16px' }}>
                                    <ShopTitleText fontWeight="400">Sales History</ShopTitleText>
                                    <RippleLink to="/actions/shop_sales">
                                        <Text
                                            fontSize="12px"
                                            lineHeight="18px"
                                            fontWeight="700"
                                            color={colors.deepBlue}
                                        >
                                            VIEW All
                                        </Text>
                                    </RippleLink>
                                </FlexContainer>

                                <List childLink fullScreen borderTop={'none'} style={{ backgroundColor: 'white' }}>
                                    {sales &&
                                        sales
                                            .sort(
                                                (a, b) =>
                                                    new Date(b.salesInfo.localSalesDate).getTime() -
                                                    new Date(a.salesInfo.localSalesDate).getTime(),
                                            )
                                            .slice(0, 3)
                                            .map((sale, index) => (
                                                <RippleLink
                                                    key={index}
                                                    top={'16px'}
                                                    bottom={'16px'}
                                                    to={{
                                                        pathname: '/actions/shop_sale_details',
                                                        state: {
                                                            sale: sales[index]?.salesInfo,
                                                            shopId: shop && shop.id,
                                                        },
                                                    }}
                                                >
                                                    <ListItem
                                                        pressedUpList
                                                        key={index}
                                                        top={'16px'}
                                                        bottom={'16px'}
                                                        direction="column"
                                                    >
                                                        <FlexContainer>
                                                            <ListHeading color="#071827" weight="400">
                                                                {sale?.salesInfo?.salesItemDetails &&
                                                                sale?.salesInfo?.salesItemDetails.length > 0
                                                                    ? sale?.salesInfo?.salesItemDetails[0].name
                                                                    : 'Sale'}
                                                                {sale?.salesInfo?.salesItemDetails &&
                                                                    sale?.salesInfo?.salesItemDetails.length > 1 &&
                                                                    `, ${sale?.salesInfo?.salesItemDetails[1].name}...`}
                                                            </ListHeading>
                                                            <ListSubHeading
                                                                top="0px"
                                                                color="#071827"
                                                                fontWeight="500"
                                                                fontSize="14px"
                                                            >
                                                                {formatPrice(sale.salesInfo.totalAmount)}
                                                            </ListSubHeading>
                                                        </FlexContainer>

                                                        <FlexContainer>
                                                            <GreyText>
                                                                {formatDate(sale.salesInfo.localSalesDate)} <Dot />
                                                                {sale.salesInfo.paymentCompleteStatus
                                                                    ? ' Fully paid'
                                                                    : ' Incomplete'}
                                                            </GreyText>

                                                            {!sale.salesInfo.paymentCompleteStatus && (
                                                                <GreyText>
                                                                    Balance:{' '}
                                                                    <RedText>
                                                                        {formatPrice(sale.salesInfo.amountOutstanding)}
                                                                    </RedText>
                                                                </GreyText>
                                                            )}
                                                        </FlexContainer>
                                                    </ListItem>
                                                </RippleLink>
                                            ))}
                                </List>

                                <FlexContainer top={'33px'} style={{ marginBottom: '16px' }}>
                                    <ShopTitleText fontWeight="400">Sales Report(7 Days)</ShopTitleText>
                                    <RippleLink to="/actions/shop_sales?step=1">
                                        <Text
                                            fontSize="12px"
                                            lineHeight="18px"
                                            fontWeight="700"
                                            color={colors.deepBlue}
                                        >
                                            VIEW All
                                        </Text>
                                    </RippleLink>
                                </FlexContainer>
                                <FlexCenteredBlock
                                    style={{
                                        backgroundColor: '#fff',
                                        padding: '15px 0 17px',
                                        margin: '0 -16px 16px',
                                    }}
                                >
                                    <GreyText weight="500">{currentDay}</GreyText>
                                    <Text fontSize="16px" lineHeight="24px" fontWeight="700" color={colors.deepBlue}>
                                        {formatPrice(todayTotal)}
                                    </Text>
                                    <Chart
                                        options={options}
                                        series={series}
                                        type="bar"
                                        width="343"
                                        style={{ margin: '0 auto' }}
                                    />
                                    {difference <= 0 ? (
                                        <DifferenceWrapper color="#FFC3BD">
                                            <Text lineHeight="16px" color="#024525">
                                                You sold{' '}
                                                <span style={{ fontWeight: '700', color: '#A31300' }}>
                                                    {formatPrice(-difference)}
                                                </span>{' '}
                                                less than yesterday
                                            </Text>
                                        </DifferenceWrapper>
                                    ) : (
                                        <DifferenceWrapper>
                                            <ConfettiSvg style={{ marginRight: '8.21px' }} />
                                            <Text lineHeight="16px" color="#024525">
                                                You sold{' '}
                                                <span style={{ fontWeight: '700' }}>{formatPrice(difference)}</span>{' '}
                                                more than yesterday
                                            </Text>
                                        </DifferenceWrapper>
                                    )}
                                </FlexCenteredBlock>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <ShopTitleText fontWeight="400" top={'33px'} bottom={'16px'}>
                                    Recent Sales
                                </ShopTitleText>
                                <NoSalesContainer>
                                    <FlexCenteredBlock>
                                        <ShoppingIcon />
                                        <Message
                                            bottom={'24px'}
                                            top={'9.13px'}
                                            align={'center'}
                                            padding={'0 1em'}
                                            size={'12px'}
                                            weight={400}
                                        >
                                            You’ve not recorded any sales. Your most recent sales will appear here.
                                        </Message>
                                        <AddIconWrapperEmptyScreen onClick={() => setOpenOptionsOverlay(true)}>
                                            <AddShopIcon />
                                        </AddIconWrapperEmptyScreen>
                                    </FlexCenteredBlock>
                                </NoSalesContainer>
                            </Fragment>
                        )}
                    </ShopStatsContainer>
                </Fragment>
            ) : (
                <FlexCenteredBlock>
                    <PageLogo
                        Icon={ShopperSvg}
                        width={'108px'}
                        height={'108px'}
                        iconHeight={'108px'}
                        iconWidth={'108px'}
                        margin={'94px auto 8px'}
                        borderRadius="0px"
                    />
                    <Title color="#071827" weight="700" size="14px" style={{ textTransform: 'initial' }}>
                        No Shop Setup
                    </Title>
                    <Message
                        color="#718596"
                        weight="400"
                        size="12px"
                        bottom={'40px'}
                        top={'8px'}
                        align={'center'}
                        padding={'0 1em'}
                    >
                        You’ve not setup any shop. Setup a shop to manage your inventory, track your sales and manage
                        your orders.
                    </Message>
                    <RippleLink
                        to="/actions/shop_setup"
                        style={{
                            width: 'calc(100% - 32px)',
                            marginBottom: '50px',
                        }}
                    >
                        <RippleButton top={'0px'}>Setup Shop</RippleButton>
                    </RippleLink>
                </FlexCenteredBlock>
            )}
            {openOptionsOverlay && (
                <Overlay bgc={'rgba(0, 0, 0, 0.45)'} onClick={() => setOpenOptionsOverlay(!openOptionsOverlay)}>
                    <OverlayOptions bottom="75px" right="12px">
                        <Link
                            to={{
                                pathname: '/actions/shop_sales_add',
                                state: {
                                    merchantId: shop && shop.merchantId,
                                    branchId: shop && shop.branchId,
                                },
                            }}
                            onClick={() => {
                                mixPanel.track(SHOP_START_CREATE_SALE, {
                                    'Entry Point': 'MyShop Home',
                                    Time: new Date().toLocaleDateString(),
                                    'User ID': userId,
                                    'shop ID': shop && shop.id,
                                });
                            }}
                        >
                            <OverlayRow className="animated_btn2">
                                <OverlayRowText color={colors.white}>New Sale</OverlayRowText>
                                <PageLogo
                                    width={'32px'}
                                    height={'32px'}
                                    iconWidth={'18px'}
                                    iconHeight={'15px'}
                                    Icon={SalesIcon}
                                    background={colors.white}
                                    className={'icon'}
                                />
                            </OverlayRow>
                        </Link>
                        <OverlayRow className="close_btn">
                            <OverlayRowText color={colors.white}>Close</OverlayRowText>
                            <CloseOverlayWrapper>
                                <CloseOverlay onClick={() => setOpenOptionsOverlay(!openOptionsOverlay)} />
                            </CloseOverlayWrapper>
                        </OverlayRow>
                    </OverlayOptions>
                </Overlay>
            )}
            <ApprovalStatusPopup open={statusPopup} cancel={() => setStatusPopup(!statusPopup)} status={agentState} />

            <NpsPopup
                open={openNps}
                updateNps={handleUpdateNps}
                surveyType={NpsSurveyType}
                close={() => {
                    setOpenNps(!openNps);
                    setDateObject();
                }}
            />

            <TermsInfoOverlay
                open={openTermsOverlay}
                cancel={() => {
                    setOpenTermsOverlay(!openTermsOverlay);
                }}
                confirm={() => {
                    setOpenTermsOverlay(!openTermsOverlay);
                    History.push('/user/account_agency_banking_signup');
                }}
            />

            <ComingSoon
                open={openWallet}
                cancel={() => setOpenWallet(!openWallet)}
                icon={WalletIcon}
                title={'Wallet'}
                subtitle={'You can use the wallet to send money to other users and bank accounts.'}
                notifyText={'We will notify you as soon as the wallet is ready for use'}
            />

            <NotificationPopup
                open={notificationPopup}
                cancel={() => {
                    setNotificationPopup(!notificationPopup);
                    dispatch({ type: SHOW_POPUP, payload: false });
                }}
                confirm={() => {
                    setNotificationPopup(!notificationPopup);
                    subscribeToNotification();
                }}
            />

            {openFeatureFixes && <FeatureUndergoingFixes cancel={() => setOpenFeatureFixes(false)} />}

            <PopUp open={openPaymentPopup} cancel={() => setOpenPaymentPopup(!openPaymentPopup)} noBorderBottom>
                {openPaymentPopup && <Overlay onClick={() => setOpenPaymentPopup(!openPaymentPopup)} />}
                <PopUpContent>
                    <PaymentWrapper>
                        <AtmLargeText style={{ maxWidth: '172px' }}>Receive payment with our secure link</AtmLargeText>
                    </PaymentWrapper>

                    <VacationSvg />
                    <Text fontSize="20px" lineHeight="30px" fontWeight="700" color="#0350BB" top="36px">
                        Coming Soon
                    </Text>
                    <Text
                        fontSize="14px"
                        lineHeight="21px"
                        fontWeight="400"
                        color="#718596"
                        style={{ margin: '5px 40px 70px' }}
                    >
                        Relax, while we work on making sure you get paid without hassle.
                    </Text>
                </PopUpContent>
            </PopUp>
        </Wrapper>
    );
};

export default Overview;
