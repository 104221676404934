/* eslint-disable no-unused-vars */
import React, { useState, Fragment } from 'react';

import { string, func, shape, arrayOf, any, bool } from 'prop-types';
import styled, { css } from 'styled-components';

import { BlurBackground as Background } from '../../../../containers/BlurBackground';
import { OpenOverlaySelectBox, InputBlock } from '../../../../containers/InputContainer';
import { ListItem } from '../../../../containers/ListContainer';
import { colors } from '../../../../styles';
import { InlineButton } from '../../../button/index';
import { InputWithOnchange } from '../TextInputWithOnchange/index';

import { ReactComponent as CancelIcon } from './assets/cancel-icon-bg.svg';
import { ReactComponent as Checked } from './assets/checked.svg';
import { ReactComponent as Search } from './assets/search.svg';

const ErrorMessage = styled.div`
    color: ${colors.red};
    font-size: 12px;
    font-weight: 400;
`;

const BlurBackground = styled(Background)`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: ${({ position }) => position || 'fixed'};
    z-index: 1000;
    height: 100vh;
    min-height: ${({ minHeight }) => minHeight || null};

    @media (min-width: 576px) {
        margin: ${({ fullMargin }) => fullMargin || null};
        max-width: ${({ fullMaxWidth }) => fullMaxWidth || '375px'};
        width: ${({ fullWidth }) => fullWidth || null};
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%); /* for IE 9 */
        -webkit-transform: translate(-50%, -50%); /* for Safari */
    }
`;

const Container = styled.div`
    bottom: 0;
    width: 100%;
    max-height: ${({ height }) => height || '70%'};
    display: flex;
    position: absolute;
    flex-direction: column;
    background-color: ${colors.white};
    border-radius: 15px 21px 0 0;
    padding: 42px 21px 32px 24px;
`;

const StyledListItemContainer = styled.div`
    overflow-y: ${({ overflow }) => overflow || 'scroll'};
    height: ${({ ListHeight }) => ListHeight || '70%'};
`;

const ModifiedListItem = styled(ListItem)`
    align-items: ${({ alignItems }) => alignItems || 'center'};
    padding-top: 0px;
    border-bottom: 1px solid ${colors.gray4};
    border-top: 1px solid ${colors.gray4};
    justify-content: space-between;
`;

export const ListHeading = styled.p`
    margin-top: ${({ top }) => top || '0px'};
    margin-bottom: ${({ bottom }) => bottom || '0px'};
    margin-left: ${({ left }) => left || null};
    max-width: ${({ maxWidth }) => maxWidth || '220px'};
    font-size: ${({ fontSize }) => fontSize || '14px'};
    font-weight: ${({ weight }) => weight || '500'};
    line-height: ${({ lineHeight }) => lineHeight || '18px'};
    color: ${({ color }) => color || colors.themeTextColor1};
    opacity: ${({ opacity }) => opacity || null};
    line-break: loose;
    height: fit-content;
    text-transform: capitalize;
    text-align: ${({ textAlign }) => textAlign || 'left'};

    ${({ noHeadingWrap }) =>
        noHeadingWrap
            ? null
            : css`
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
              `}
`;

const ModifiedInlineButton = styled(InlineButton)`
    font-size: 16px;
    font-weight: 500;
    padding: ${({ padding }) => padding || '30px 0 70px'};
    margin-bottom: ${({ mbottom }) => mbottom || '38px'};
`;

const Title = styled.h3`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    align-items: ${({ alignItems }) => alignItems || 'center'};
    text-align: ${({ textAlign }) => textAlign || 'center'};
    color: ${({ color }) => color || `${colors.themeTextColor3}`};
    margin: auto;
    padding-bottom: 16px;
`;

const SearcBarWrapper = styled.div`
    position: relative;
`;

const SearchSvg = styled(Search)`
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    margin-right: 13px;
    margin-top: 13px;
`;

const SpaceFlex = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: transparent;
`;

const Label = styled.h3`
    position: absolute;
    top: 0;
    margin-top: 8px;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: ${colors.lightDark};
`;

const StyledCancelIcon = styled.div`
    padding-left: ${({ paddingLeft }) => paddingLeft || null};
`;

const HeaderColumn = styled.div`
    display: flex;
`;

const SelectListOverlay = ({
    list = [],
    onCancelModal,
    label,
    onSelect,
    onSearch,
    height,
    title,
    selectedValue,
    checkable,
    showSearchBar,
    showCancelIcon,
    buttonText,
    ListHeight,
    position,
    minHeight,
    padding,
    alignItems,
    textAlign,
    paddingLeft,
    overflow,
    left,
    color,
    mbottom,
    fullMaxWidth,
    fullWidth,
    fullMargin,
    itemKey = '',
}) => {
    const [value, setSearchValue] = useState();

    const handleChange = (value) => {
        setSearchValue(value);
        onSearch(value);
    };

    const handleSelect = (item) => {
        onSelect(item);
        onCancelModal();
    };

    return (
        <BlurBackground
            position={position}
            minHeight={minHeight}
            fullMaxWidth={fullMaxWidth}
            fullWidth={fullWidth}
            fullMargin={fullMargin}
        >
            <SpaceFlex onClick={onCancelModal} />
            <Container height={height}>
                <HeaderColumn>
                    <Title alignItems={alignItems} textAlign={textAlign} color={color}>
                        {title}
                    </Title>
                    {showCancelIcon && (
                        <StyledCancelIcon paddingLeft={paddingLeft}>
                            <CancelIcon width="24px" height="24px" onClick={onCancelModal} />
                        </StyledCancelIcon>
                    )}
                </HeaderColumn>
                {showSearchBar && (
                    <SearcBarWrapper>
                        <InputWithOnchange
                            name="SearchBar"
                            label={''}
                            type={'text'}
                            inputMode={'text'}
                            placeholder={`Search ${label}`}
                            autoComplete={'off'}
                            bottom="16px"
                            height={'48px'}
                            nolabelmargin
                            background={colors.themeColor3}
                            value={value}
                            onChange={(e) => {
                                handleChange(e.target.value);
                            }}
                        />
                        <SearchSvg />
                    </SearcBarWrapper>
                )}
                <StyledListItemContainer ListHeight={ListHeight} overflow={overflow}>
                    {list?.map((entry, index) => (
                        <ModifiedListItem pressedUpList key={index} onClick={() => handleSelect(entry)}>
                            <ListHeading top={'10px'} maxWidth={'100%'} color={colors.black} left={left}>
                                {itemKey
                                    ? entry[itemKey]
                                    : entry?.translations
                                    ? entry?.translations[0]?.name
                                    : entry?.label || entry?.name || entry?.question}
                            </ListHeading>
                            {checkable && selectedValue === entry.label && <Checked />}
                        </ModifiedListItem>
                    ))}
                </StyledListItemContainer>
                <ModifiedInlineButton onClick={onCancelModal} padding={padding} mbottom={mbottom}>
                    {buttonText || 'Cancel'}
                </ModifiedInlineButton>
            </Container>
        </BlurBackground>
    );
};

export const SelectBox = ({
    name,
    value,
    opacity,
    handleChange,
    placeholder,
    options = [],
    disabled = false,
    valid,
    errors,
    height,
    children,
    weight,
    ListHeight,
    top,
    padding,
    paddingLeft,
    alignItems,
    textAlign,
    mbottom,
    position,
    minHeight,
    fullMargin,
    fullMaxWidth,
    fullWidth,
    bottom,
    fontSize,
    isSelected,
    setIsSelected,
    overflow,
    color,
    left,
    title,
    checkable,
    showSearchBar = true,
    showCancelIcon = false,
    buttonText,
    contentTop,
    itemKey,
}) => {
    const [toggleSelectList, setToggleSelectList] = useState(false);
    const [listValues, setListValues] = useState(options);

    const searchArr = (searchTerm) => {
        if (searchTerm === '') {
            return setListValues(options);
        }

        setListValues(
            options.filter(
                (entry) =>
                    (entry[itemKey] || entry?.label || entry?.name || entry?.question) &&
                    (entry[itemKey] || entry.label || entry.name || entry.question)
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()),
            ),
        );
    };

    const onToggleSelectList = () => {
        setListValues(options);
        setToggleSelectList(!toggleSelectList);
        if (setIsSelected) {
            setIsSelected(!isSelected);
        }
    };

    const onSelect = (selected) => {
        if (name) {
            handleChange(name)(
                selected?.translations
                    ? selected?.translations[0]?.name
                    : selected?.label || selected?.name || selected?.question,
            );
        } else {
            handleChange(selected);
        }
    };
    return (
        <Fragment>
            <OpenOverlaySelectBox
                height={'56px'}
                onClick={onToggleSelectList}
                disabled={disabled}
                valid={valid}
                error={errors}
                top={top || '16px'}
                bottom={bottom || '0px'}
                contentTop={contentTop}
            >
                <InputBlock>
                    {value && <Label>{placeholder}</Label>}
                    {value ? (
                        children ? (
                            children
                        ) : (
                            <ListHeading top={'8px'} color={colors.themeTxtColor10}>
                                {value}
                            </ListHeading>
                        )
                    ) : (
                        <ListHeading weight={400} fontSize={fontSize} color={colors.textLightDark} opacity={opacity}>
                            {placeholder}
                        </ListHeading>
                    )}
                </InputBlock>
            </OpenOverlaySelectBox>
            {errors && <ErrorMessage>{errors}</ErrorMessage>}
            {toggleSelectList && (
                <SelectListOverlay
                    height={height}
                    onCancelModal={onToggleSelectList}
                    label={placeholder}
                    list={listValues}
                    onSearch={searchArr}
                    padding={padding}
                    mbottom={mbottom}
                    ListHeight={ListHeight}
                    position={position}
                    minHeight={minHeight}
                    fullMaxWidth={fullMaxWidth}
                    fullWidth={fullWidth}
                    fullMargin={fullMargin}
                    onSelect={onSelect}
                    title={title}
                    selectedValue={value}
                    checkable={checkable}
                    showSearchBar={showSearchBar}
                    buttonText={buttonText}
                    overflow={overflow}
                    left={left}
                    color={color}
                    showCancelIcon={showCancelIcon}
                    textAlign={textAlign}
                    alignItems={alignItems}
                    paddingLeft={paddingLeft}
                    itemKey={itemKey}
                />
            )}
        </Fragment>
    );
};

SelectBox.propTypes = {
    name: string,
    placeholder: string,
    handleChange: func,
    setSelectedValue: func,
    valid: string,
    value: any,
    setItem: any,
    opacity: string,
    errors: string,
    disabled: bool,
    options: arrayOf(shape({ value: string, label: string, question: string })),
};
