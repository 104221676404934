import {
    ForgotPassword,
    SetNewPassword,
    PasswordOtpOptions,
    CustomerSaleReceipt,
    CustomersListProducts,
    StoreFrontCart,
    StoreFrontCheckout,
    StoreFrontOrders,
    StoreFrontProduct,
    CustomerPhoneNumberSignUp,
} from '../views';
import { MerchbuyProductDetails } from '../views/actions/merchbuy';
import { AppVersion } from '../views/app-version';
import Inputs from '../views/inputs';
import { PrivacyPolicy } from '../views/privacy-policy';
import SkeletalLoading from '../views/skeletal-loading';
import { TermsAndConditions } from '../views/terms-conditions';

import { paymentLinkOpenRoutes } from './actions/paymentLinkRoutes';

//import { ZendeskWindow } from '../components';
const devRoutes = [
    {
        path: '/skeletal-loader',
        component: SkeletalLoading,
        exact: true,
    },
    { path: '/inputs', exact: true, component: Inputs },
];

export const openRoutes = [
    {
        path: '/termsAndConditions',
        component: TermsAndConditions,
        exact: true,
    },
    {
        path: '/privacyPolicy',
        component: PrivacyPolicy,
        exact: true,
    },
    {
        path: '/appVersion',
        component: AppVersion,
        exact: true,
    },
    {
        path: '/user/password_forgot',
        component: ForgotPassword,
        exact: true,
    },
    {
        path: '/user/password-otp-options',
        component: PasswordOtpOptions,
        exact: true,
    },
    {
        path: '/user/password_set',
        component: SetNewPassword,
        exact: true,
    },
    {
        path: '/receipt',
        component: CustomerSaleReceipt,
        exact: true,
    },
    {
        path: '/actions/store_front/:store_link',
        exact: true,
        component: CustomersListProducts,
    },
    {
        path: '/customer/phone_check',
        exact: true,
        component: CustomerPhoneNumberSignUp,
    },
    {
        path: '/actions/store_front/:store_link/cart',
        exact: true,
        component: StoreFrontCart,
    },

    {
        path: '/actions/store_front/:store_link/checkout',
        exact: true,
        component: StoreFrontCheckout,
    },
    {
        path: '/actions/store_front/store_link/orders/:number',
        exact: true,
        component: StoreFrontOrders,
    },
    {
        path: '/actions/store_front/:store_link/:product_id',
        exact: true,
        component: StoreFrontProduct,
    },
    {
        path: '/actions/merchbuy/product/:id',
        exact: true,
        component: MerchbuyProductDetails,
    },
    {
        path: '/actions/merchbuy/product/:id/:warehouseId',
        exact: true,
        component: MerchbuyProductDetails,
    },

    ...paymentLinkOpenRoutes,
];

if (process.env.NODE_ENV === 'development') openRoutes.push(...devRoutes);
