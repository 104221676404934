import React from 'react';

import { Bubble } from 'react-chartjs-2';
import styled from 'styled-components';

import { BubbleBox, Space } from '../reports.styles';

import { LegendBox } from './BubbleChartLegendBox';

export const StyledBuble = styled(Bubble)`
    width: 90%;
    margin: 0 auto;
`;

export const BubbleChartComponent = ({ productsReport, open }) => {
    const xCords = [4, 6, 8].sort(() => 0.5 - Math.random());
    const yCords = [4, 1, 8].sort(() => 0.5 - Math.random());
    const colors = ['#015436', '#02b57d', '#579fd7', '#6c7984', '#92cfdf', '#ffaa00'];
    const productReportNames = productsReport.map((x) => x.name);
    const productReportPercentage = productsReport.map((x) => x.percentage);
    const productReportCounts = productsReport.map((x) => x.count);

    const myData = [
        { x: 1, y: 5, r: Math.floor(productReportPercentage[0] * 0.01 * (50 - 10 + 1) + 10) },
        { x: xCords[1], y: yCords[1], r: Math.floor(productReportPercentage[1] * 0.01 * (50 - 10 + 1) + 10) },
        { x: xCords[2], y: yCords[2], r: Math.floor(productReportPercentage[2] * 0.01 * (50 - 10 + 1) + 10) },
        { x: xCords[0], y: yCords[0], r: Math.floor(productReportPercentage[3] * 0.01 * (50 - 10 + 1) + 10) },
        { x: 13, y: 2, r: Math.floor(productReportPercentage[4] * 0.01 * (50 - 10 + 1) + 10) },
        { x: 12, y: 12, r: Math.floor(productReportPercentage[5] * 0.01 * (50 - 10 + 1) + 10) },
    ];

    const data = {
        datasets: [
            {
                labels: productReportNames.slice(0, productsReport.length),
                fill: false,
                lineTension: 0.1,
                backgroundColor: colors,
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: myData.slice(0, productsReport.length),
            },
        ],
        labels: productReportCounts,
        labels2: productReportNames,
    };

    return (
        <BubbleBox open={open}>
            <Space val={'30px'} />
            <StyledBuble
                data={data}
                height={400}
                options={{
                    title: {
                        display: false,
                    },
                    responsive: true,
                    legend: {
                        display: false,
                    },
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                return `${data.labels2[tooltipItem.index]} (${data.labels[tooltipItem.index]})`;
                            },
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                gridLines: {
                                    display: false,
                                },
                                ticks: {
                                    autoSkip: true,
                                    beginAtZero: true,
                                    stepSize: 8,
                                    display: false,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                gridLines: {
                                    display: false,
                                },
                                ticks: {
                                    autoSkip: true,
                                    beginAtZero: true,
                                    stepSize: 8,
                                    display: false,
                                },
                            },
                        ],
                    },
                    bezierCurve: false,
                }}
            />
            <LegendBox productsReport={productsReport} open={open} />
        </BubbleBox>
    );
};
