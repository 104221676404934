import React from 'react';

import { any } from 'prop-types';
import styled from 'styled-components';

import { RippleLink } from '../../../../../components';
import { is_url } from '../../../../../utils/urls/isUrl';
import FallbackShopImage from '../../assets/shop_image.svg';
import { ShopBlock, ShopImage, ShopInfo, ShopName, ShopCategory, ShopLocation } from '../../styles';

const Link = styled(RippleLink)`
    width: 100%;
    &:hover {
        background: #f2f5fa;
    }
`;

export const ShopItem = ({ data, size, showProductCategory = false }) => {
    return (
        <Link to={`/actions/merchbuy/shop/${data.id}`}>
            <ShopBlock>
                <ShopImage
                    src={is_url(data.imageUrl) ? data.imageUrl : FallbackShopImage}
                    bg={is_url(data.imageUrl) ? null : '#E9F2FF'}
                    wd={is_url(data.imageUrl) ? null : 'scale-down'}
                    alt="product image"
                    width={size.width}
                    height={size.height}
                    onError={(e) => {
                        e.target.src = FallbackShopImage;
                    }}
                />
                <ShopInfo>
                    <ShopName>{data.name}</ShopName>
                    {showProductCategory && (
                        <ShopCategory>
                            {data.businessCategoryNames !== null ? data.businessCategoryNames.join(', ') : ''}
                        </ShopCategory>
                    )}
                    <ShopLocation>{data.location.state ? ` ${data.location.state} ,` : ''} Nigeria</ShopLocation>
                </ShopInfo>
            </ShopBlock>
        </Link>
    );
};

ShopItem.propTypes = {
    data: any,
    size: any,
};
