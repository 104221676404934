import React, { Fragment } from 'react';

import styled from 'styled-components';

import { ReactComponent as ActivationSvg } from '../../../assets/activation.svg';
import { RippleLink } from '../../../components';
import { ScreenContainer } from '../../../containers/ScreenContainer';
import { colors } from '../../../styles';

const PageTitle = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.themeTextColor1};
    margin: 30px 0 13px 16px;
`;

const Container = styled.div`
    height: 376px;
    margin: 20px;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 39px 0 52px 0;
    border-radius: 8px;
`;

const ActivationText = styled.p`
    font-weight: 700;
    size: 16px;
    line-height: 24px;
    color: #071827;
`;

const ActivationMsg = styled.p`
    color: #718596;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    text-align: center;
    padding: 0 24px;
`;

const ActivationLink = styled.p`
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: ${colors.deepBlue};
`;

const MainContainer = styled.div`
    height: calc(100vh - 101px);
    background-color: #f9fafc;
    display: flex;
    align-items: center;
`;

const PendingActivation = ({ pageTitle }) => {
    return (
        <Fragment>
            <PageTitle>{pageTitle}</PageTitle>
            <MainContainer>
                <ScreenContainer padding="0">
                    <Container>
                        <div>
                            <ActivationSvg />
                        </div>
                        <ActivationText>Your Space force activation is pending</ActivationText>
                        <ActivationMsg>
                            You cannot carry out this action until your profile has been activated as a Space Force
                            Agent
                        </ActivationMsg>
                        <RippleLink to={'/user/account_kyc'}>
                            <ActivationLink>See activation Details</ActivationLink>
                        </RippleLink>
                    </Container>
                </ScreenContainer>
            </MainContainer>
        </Fragment>
    );
};

export default PendingActivation;
