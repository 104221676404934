import React from 'react';

import * as Sentry from '@sentry/react';
import { ConnectedRouter } from 'connected-react-router';
import { render } from 'react-dom';
import { initialize } from 'react-ga';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';

import App from './App';
import Toast from './components/new-toast/toast';
import configureStore from './redux/store';
import * as serviceWorker from './serviceWorker';
import { GlobalStyles } from './styles';
import History from './utils/History';
import 'react-toastify/dist/ReactToastify.min.css';
import { APP_LAUNCH } from './utils/mix-panel/constants';
import { mixPanel } from './utils/mix-panel/mixPanel';

if (
    process.env.REACT_APP_ENV_NAME === 'development' ||
    process.env.REACT_APP_ENV_NAME === 'staging' ||
    process.env.REACT_APP_ENV_NAME === 'production'
) {
    //Stop sentry from logging server error
    const serverErrorsRegex = new RegExp(
        `Failed to fetch|NetworkError when attempting to fetch resource|Invalid left-hand side in assignment|Request failed with status code 403|Network Error`,
        'mi',
    );
    Sentry.init({
        environment: process.env.REACT_APP_ENV_NAME,
        dsn: process.env.REACT_APP_SENTRY_KEY,
        release: 'spaces-frontend@' + process.env.npm_package_version,
        ignoreErrors: [serverErrorsRegex],
    });

    initialize(process.env.REACT_APP_GA_KEY);
}

export const { store, persistor } = configureStore();

mixPanel.track(APP_LAUNCH, {
    Time: new Date().toLocaleDateString(),
});

render(
    <BrowserRouter>
        <Provider store={store}>
            <ConnectedRouter history={History}>
                <PersistGate loading={null} persistor={persistor}>
                    <GlobalStyles />
                    <App />
                    <Toast />
                </PersistGate>
            </ConnectedRouter>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root'),
);

serviceWorker.register();
