import React, { Fragment } from 'react';

import { bool, func, string } from 'prop-types';
import styled from 'styled-components';

import { SubTitle, SecondaryText } from '../../../containers/MessageContainer';
import { Overlay, ActionBlock } from '../../../containers/OverlayContainer';
import { CopyReferralBadge } from '../../badges';
import { ButtonWithShareIcon } from '../../button';
import { PopUp, PopUpContent, PopUpHeader, CancelButton } from '../common';

import { ReactComponent as EarnIcon } from './assets/earn-more.svg';
import { ReactComponent as AddIcon } from './assets/invite.svg';
import { ReactComponent as CartIcon } from './assets/purple-cart.svg';
import { ReactComponent as ShareIcon } from './assets/share.svg';

const InfoBlock = styled.div`
    padding: 16px;
`;

const InfoIntroduction = styled(SubTitle)`
    line-height: 17px;
    margin: 0;
    font-weight: 100;
    text-align: left;
`;

const SubInfoIntroduction = styled(SubTitle)`
    line-height: 17px;
    margin-top: 24px;
    font-weight: 700;
    text-align: left;
    color: #227eff;
`;

const IconBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: ${({ bgColor }) => bgColor || 'white'};
`;

const StrongText = styled.strong`
    font-weight: 500;
`;

const StepsBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 21px;
`;

const Step = styled.div`
    display: flex;
    align-items: center;
`;

const StepText = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 16px;
`;

const StepHeading = styled(SubTitle)`
    font-weight: 700;
    color: #071827;
    text-align: left;
    margin: 0;
`;

const StepDetails = styled(SecondaryText)`
    margin: 6px 0 0 0;
    text-align: left;
    font-size: 14px;
    color: #718596;
`;

const BrokenLine = styled.div`
    width: 0.5px;
    height: ${({ height }) => height || '10px'};
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 20px;
    background-color: #a0b1c0;
`;

export const MerchantShareLink = ({ open, setOpen, referralCode, url, marketingMessage, setOpenOptions }) => {
    return (
        open && (
            <Fragment>
                <Overlay onClick={() => setOpen(!open)} bgc={'rgba(0,0,0,0.4)'} />
                <PopUp open={open}>
                    <PopUpContent overflowY="auto" maxHeight="80vh">
                        <PopUpHeader
                            size="20px"
                            weight="700"
                            color="#071827"
                            padding="32px 16px 0"
                            align={'left'}
                            noBorderBottom={true}
                        >
                            Refer and Earn
                        </PopUpHeader>
                        <InfoBlock>
                            <InfoIntroduction>
                                You can <StrongText>add a merchant</StrongText> using your referral code or link.
                                <br />
                            </InfoIntroduction>
                            <SubInfoIntroduction font="16px">How it works</SubInfoIntroduction>
                            <StepsBlock>
                                <Step>
                                    <IconBlock bgColor="#FDEDE7">
                                        <AddIcon />
                                    </IconBlock>
                                    <StepText>
                                        <StepHeading>Invite a Merchant</StepHeading>
                                        <StepDetails>Share your referral link or code.</StepDetails>
                                    </StepText>
                                </Step>
                                <BrokenLine height="5px" />
                                <BrokenLine />
                                <BrokenLine />
                                <BrokenLine />
                                <BrokenLine height="5px" />

                                <Step>
                                    <IconBlock bgColor="#EDE6FE">
                                        <CartIcon />
                                    </IconBlock>
                                    <StepText>
                                        <StepHeading>Make sure they're active</StepHeading>
                                        <StepDetails>Send them activation reminders.</StepDetails>
                                    </StepText>
                                </Step>
                                <BrokenLine height="5px" />
                                <BrokenLine />
                                <BrokenLine />
                                <BrokenLine />
                                <BrokenLine height="5px" />

                                <Step>
                                    <IconBlock bgColor="#D3FDE9">
                                        <EarnIcon />
                                    </IconBlock>
                                    <StepText>
                                        <StepHeading>Earn More!</StepHeading>
                                        <StepDetails>Earn commissions from merchant activities!</StepDetails>
                                    </StepText>
                                </Step>
                            </StepsBlock>
                            <CopyReferralBadge top="36px" referralCode={referralCode} embedded={true} />
                            <ActionBlock direction={'row'} top={'32px'}>
                                <CancelButton
                                    top="0px"
                                    type={'button'}
                                    onClick={() => setOpen(!open)}
                                    color="#227EFF"
                                    radius="4px"
                                    size="16px"
                                    height="48px"
                                    bgColor="#fff"
                                    border={true}
                                >
                                    Cancel
                                </CancelButton>
                                <ButtonWithShareIcon
                                    top={'0px'}
                                    radius="4px"
                                    icon={<ShareIcon />}
                                    color="#fff"
                                    backgroundColor="#227EFF"
                                    onClick={setOpenOptions}
                                    size="16px"
                                />
                            </ActionBlock>
                        </InfoBlock>
                    </PopUpContent>
                </PopUp>
            </Fragment>
        )
    );
};

MerchantShareLink.propTypes = {
    open: bool,
    setOpen: func,
    referralCode: string,
};
