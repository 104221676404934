import React, { Fragment, useEffect } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { TopHeader } from '../../../../../components';
import { getDebtors, getTotalDebtAmount } from '../../../../../redux/ducks/applications/my-shop/actions/debtors';
import { colors } from '../../../../../styles';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import { formatCurrency } from '../../../../../utils/currency/parseBalance';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import Bg from '../assets/bg.png';

const Header = styled.div`
    height: 178px;
    background-image: url('${Bg}');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4b6bf1;
`;

const Box = styled.div`
    position: relative;
    height: 96px;
    top: -70px;
    background: ${colors.white};
    box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 16px;
`;

const Text = styled.div`
    font-size: ${({ size }) => size || '14px'};
    color: ${({ color }) => color || colors.black};
    padding: ${({ padding }) => padding || null};
    line-height: ${({ lineHeight }) => lineHeight || null};
    font-weight: ${({ weight }) => weight || '400'};
`;

const Background = styled.div`
    margin-top: -50px;
    background-color: #edf2f7;
    max-height: 80vh;
    overflow-y: scroll;
`;

const ItemWrapper = styled.div`
    margin: 8px 0 15px 0;
`;

const Item = styled.div`
    height: 65px;
    background: ${colors.white};
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    width: 100%;
`;

const NameInitial = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 32px;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ bg }) => bg};
    color: ${({ color }) => color};
`;

const NameWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const NameInnerWrapper = styled.div`
    margin-left: 16px;
`;

export const mockName = [
    { name: 'Segun Onigbinde', id: 1 },
    { name: 'Bessie Cooper', id: 2 },
    { name: 'Marvin McKinney', id: 3 },
    { name: 'Jenny Wilson', id: 4 },
    { name: 'Ralph Edwards', id: 5 },
    { name: 'Darrell Steward', id: 6 },
    { name: 'Jane Cooper', id: 7 },
    { name: 'Segun Onigbinde', id: 8 },
    { name: 'Segun Onigbinde', id: 9 },
    { name: 'Bessie Cooper', id: 10 },
    { name: 'Marvin McKinney', id: 11 },
    { name: 'Jenny Wilson', id: 12 },
    { name: 'Ralph Edwards', id: 13 },
    { name: 'Darrell Steward', id: 14 },
    { name: 'Jane Cooper', id: 15 },
    { name: 'Segun Onigbinde', id: 16 },
];

const randomColors = [
    {
        bg: '#E9F2FF',
        color: '#227EFF',
    },
    {
        bg: '#EDF2F7',
        color: '#2E4457',
    },
    {
        bg: '#FDEDE7',
        color: '#EE6F44',
    },
    {
        bg: '#EDE6FE',
        color: '#7E42F5',
    },
    {
        bg: '#E9F2FF',
        color: '#227EFF',
    },
    {
        bg: '#D3FDE9',
        color: '#05944F',
    },
    {
        bg: '#FFF7E6',
        color: '#D78F00',
    },
];

const ListDebtors = () => {
    const dispatch = useDispatch();
    const debtors = useSelector((state) => state.applications.myShop.debtors);
    useEffect(() => {
        dispatch(getDebtors());
        dispatch(getTotalDebtAmount());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Fragment>
            <DesktopBackgroundLayout fullHeight bgColor="#edf2f7">
                <TopHeader
                    weight="400"
                    margin="27px 0 0 0"
                    size="14px"
                    color={colors.white}
                    noBorderBottom={true}
                    backgroundColor="transparent"
                    title="Debtors"
                    noArrow={false}
                ></TopHeader>
                <Header />
                <Box>
                    <Text weight="bold" lineHeight="30px" color="#A31300" size="20px">
                        {formatCurrency(debtors?.totalDebtAmount, 'NG')}
                    </Text>
                    <Text lineHeight="18px" color="#718596" size="12px">
                        Total Amount Owed
                    </Text>
                </Box>
                <Background>
                    <Text padding="0 16px" weight="bold" lineHeight="18px" color="#718596" size="12px">
                        OWING CUSTOMERS
                    </Text>
                    <ItemWrapper>
                        {debtors?.allDebtors?.map((data) => {
                            const selectedColor = randomColors[Math.floor(Math.random() * randomColors.length)];
                            const initials = data.name
                                .split(' ')
                                .map((n) => n[0])
                                .join('')
                                .toUpperCase();
                            return (
                                <Link to={`/actions/debtors/${data.customerId}`} key={data.customerId}>
                                    <Item>
                                        <NameWrapper>
                                            <NameInitial bg={selectedColor.bg} color={selectedColor.color}>
                                                {initials}
                                            </NameInitial>
                                            <NameInnerWrapper>
                                                <Text weight="500" lineHeight="21px" color="#071827">
                                                    {data.name}
                                                </Text>
                                                {data?.reminderHistory?.length > 0 && (
                                                    <Text
                                                        padding="4px 0 0 0"
                                                        lineHeight="18px"
                                                        color="#718596"
                                                        size="12px"
                                                    >
                                                        Reminded:{' '}
                                                        {moment(
                                                            data?.reminderHistory[data?.reminderHistory?.length - 1]
                                                                ?.dateSent,
                                                            'DD-MM-YYYY hh:mm:ss',
                                                        ).fromNow()}
                                                    </Text>
                                                )}
                                            </NameInnerWrapper>
                                        </NameWrapper>
                                        <Text padding="4px 0 0 0" lineHeight="21px" color="#2E4457">
                                            {formatPrice(data.totalAmountOwed)}
                                        </Text>
                                    </Item>
                                </Link>
                            );
                        })}
                    </ItemWrapper>
                </Background>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default ListDebtors;
