import React, { Fragment, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as ReceiptSearch } from '../../../../../assets/receipt_search.svg';
import { ReactComponent as ReceiptLogo } from '../../../../../assets/spaces_icon.svg';
import { TopHeader, Loader, RippleButton } from '../../../../../components';
import { Close } from '../../../../../containers/HeaderContainer';
import { ScreenContainer } from '../../../../../containers/ScreenContainer';
import { getSaleReceipt } from '../../../../../redux/ducks/applications/my-shop/actions/sales';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import { parseTime, parseDateYear } from '../../../../../utils/date/parseDateFromStr';
import { downloadSalesReceipt } from '../../../../../utils/files/htmlToFormData';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';

const ButtonsFlex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    padding-left: 16px;
    padding-right: 16px;
`;

const Receipt = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
`;

const ReceiptHeader = styled.div`
    display: flex;
    flex-direction: row;
    padding: 16px;
    align-items: 'center';
    justify-content: space-between;
    background-color: #a7d9ff40;
`;

const ReceiptShopDetail = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    padding-top: 4px;
`;

const PageTitle = styled.span`
    font-size: 16px;
    font-weight: 600;
    display: block;
    color: #579fd7;
`;

const BoldText = styled.span`
    font-size: 14px;
    font-weight: 700;
    display: block;
    color: #212c3d;
    padding: 4px 16px;
`;

const TableText = styled.span`
    font-size: ${({ size }) => size || '12px'};
    font-weight: 500;
    display: block;
    color: #212c3d;
    padding: 0px 16px;
`;

const Text = styled.span`
    font-size: 12px;
    line-height: 1.5;
    padding: 0 10px;
    display: block;
`;

const ReceiptDetail = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    padding-top: 4px;
`;

const ReceiptText = styled.span`
    font-size: 10px;
    line-height: 1.5;
    display: inline;
    color: #253246;
    margin-right: 16px;
    margin-left: 16px;
    align-self: ${({ align }) => align || 'flex-start'};
`;

const ReceiptBoldText = styled.span`
    font-size: 12px;
    font-weight: 500;
    display: inline;
    color: #253246;
`;

const ReceiptTable = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
`;

const Table = styled.table`
    border-collapse: collapse;
    border: 1px solid #f0f0f0;
    margin-top: 16px;
    width: 100%;
`;

const TableHead = styled.thead``;
const TableBody = styled.tbody``;
const TableRow = styled.tr``;

const TableData = styled.td`
    border: 1px solid #f0f0f0;
    font-size: ${({ size }) => size || '12px'};
    color: #212c3d;
    font-weight: 500;
    padding: 12px 16px;
    text-align: ${({ align }) => align || 'center'};
`;

const TableDataProduct = styled(TableData)`
    font-size: 11px;
    color: #253246;
    font-weight: 400;
    text-align: left;
`;

const TableHeadItem = styled.th`
    border-collapse: collapse;
    border: 1px solid #f0f0f0;
    font-weight: 400;
    color: #56636d;
    font-size: 10px;
    padding: 12px 16px;
`;

const Divider = styled.div`
    height: 1px;
    background: #f0f0f0;
    width: 100%;
    margin-top: ${({ top }) => top || '0px'};
`;

const TableTotalAmount = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 0px;
`;

const Container = styled.div``;

const Dot = styled.div`
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    background-color: #c4c4c4;
    margin-right: 5px;
`;

const Reason = styled.span`
    display: block;
    font-weight: 400;
    font-size: 12px;
    color: #56636d;
    line-height: 1.5;
`;

const CustomerSaleReceipt = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const salesId = (location.search && location.search.split('id=')[1]) || '';
    const isLoading = useSelector((state) => state.applications.myShop.isLoading || false);

    const [sale, setSale] = useState(null);
    const id = salesId;
    const shopName = sale && sale.branchName;
    const shopAddress = sale && sale.branchAddress;
    const shopLga = sale && sale.branchLocalGovt;
    const shopState = sale && sale.branchState;
    const shopNumber = sale && sale.branchPhoneNumber;

    const customerName = sale && sale.customerName;
    const customerPhoneNumber = sale && sale.customerPhoneNumber;

    const salesDate = sale && sale.localSalesDate;
    const totalAmount = sale && sale.totalAmount;
    const saleItemDetails = sale && sale.salesItemDetails;

    useEffect(() => {
        async function fetchData() {
            const response = await dispatch(getSaleReceipt(salesId));
            response && setSale(response);
        }
        fetchData();
    }, [dispatch, salesId]);

    if (isLoading) {
        return <Loader />;
    } else
        return (
            <Fragment>
                <DesktopBackgroundLayout>
                    <TopHeader title={'Receipt'} noArrow>
                        <Close left={'16px'} onClick={() => history.goBack()} />
                    </TopHeader>
                    <ScreenContainer padding={'0px'} paddingBottom={'65px'} top={'60px'}>
                        {sale ? (
                            <Container>
                                <Receipt id={'receipt'}>
                                    <ReceiptHeader>
                                        <PageTitle>
                                            {'SALES'}
                                            <br />
                                            {'RECEIPT'}
                                        </PageTitle>
                                        <ReceiptLogo style={{ width: '40px', height: '40px' }} />
                                    </ReceiptHeader>

                                    <ReceiptShopDetail>
                                        <BoldText>{shopName && shopName}</BoldText>
                                        <Text>{shopAddress && shopAddress + ','}</Text>
                                        <Text>
                                            {shopLga && shopLga + ' L.G.A, '}
                                            {shopState && shopState + ' State'}
                                        </Text>
                                        <Text>{shopNumber && shopNumber}</Text>
                                    </ReceiptShopDetail>

                                    <ReceiptDetail>
                                        <ReceiptText align={'flex-end'}>
                                            {'Date: '}
                                            <ReceiptBoldText>{parseDateYear(salesDate)}</ReceiptBoldText>
                                        </ReceiptText>
                                        <ReceiptText align={'flex-end'}>
                                            {'Time: '}
                                            <ReceiptBoldText>{parseTime(salesDate)}</ReceiptBoldText>
                                        </ReceiptText>
                                        {customerName && (
                                            <ReceiptText>
                                                {'Customer: '}
                                                <ReceiptBoldText>{customerName}</ReceiptBoldText>
                                            </ReceiptText>
                                        )}
                                        {customerPhoneNumber && (
                                            <ReceiptText>
                                                {'Phone: '}
                                                <ReceiptBoldText>{customerPhoneNumber}</ReceiptBoldText>
                                            </ReceiptText>
                                        )}
                                        <ReceiptText>
                                            {'Sale ID: '}
                                            <ReceiptBoldText>No. {id}</ReceiptBoldText>
                                        </ReceiptText>
                                    </ReceiptDetail>

                                    <ReceiptTable>
                                        <TableText>{'Order Summary'}</TableText>
                                        {saleItemDetails ? (
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableHeadItem style={{ textAlign: 'left' }}>
                                                            Product
                                                        </TableHeadItem>
                                                        <TableHeadItem>Unit</TableHeadItem>
                                                        <TableHeadItem>Price</TableHeadItem>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {saleItemDetails.map((item, index) => (
                                                        <TableRow key={index}>
                                                            <TableDataProduct>{item.name}</TableDataProduct>
                                                            <TableData>{item.quantity}</TableData>
                                                            <TableData style={{ whiteSpace: 'nowrap' }} align={'right'}>
                                                                {formatPrice(item.itemPrice)}
                                                            </TableData>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        ) : (
                                            <Divider top={'16px'} />
                                        )}
                                        <TableTotalAmount>
                                            <TableText size={'10px'}>{'TOTAL'}</TableText>
                                            <TableText size={'14px'}>{formatPrice(totalAmount)}</TableText>
                                        </TableTotalAmount>
                                        <Divider />
                                    </ReceiptTable>
                                </Receipt>
                                <ButtonsFlex>
                                    <RippleButton
                                        type={'button'}
                                        style={{
                                            marginTop: '0px',
                                            backgroundColor: '#e9f6ff',
                                            color: '#22a8ff',
                                            border: '1px solid #22a8ff',
                                        }}
                                        onClick={async () => {
                                            //const result =
                                            await downloadSalesReceipt('receipt');
                                            //result &&
                                        }}
                                    >
                                        Download Receipt
                                    </RippleButton>
                                </ButtonsFlex>
                            </Container>
                        ) : (
                            <Container>
                                {/* <ReceiptHeader>
                                <PageTitle>{"SALES"}<br/>{"RECEIPT"}</PageTitle>
                                <ReceiptLogo style={{width: '40px', height: '40px'}} />
                            </ReceiptHeader> */}
                                <ReceiptShopDetail>
                                    <ReceiptSearch style={{ marginTop: '112px', width: '91px', height: '117px' }} />
                                    <TableText style={{ marginTop: '24px', marginBottom: '16px' }} size={'14px'}>
                                        We couldn't find a match
                                    </TableText>
                                    <Reason>
                                        <Dot />
                                        Receipt might no longer exist.
                                    </Reason>
                                    <Reason>
                                        <Dot />
                                        No Internet Connectivity.
                                    </Reason>
                                </ReceiptShopDetail>
                            </Container>
                        )}
                    </ScreenContainer>
                </DesktopBackgroundLayout>
            </Fragment>
        );
};

export default CustomerSaleReceipt;
