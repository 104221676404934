import styled from 'styled-components';

import { ReactComponent as DiscountIcon } from '../../../../../../assets/discount.svg';
import { colors } from '../../../../../../styles';
import { ReactComponent as TransactionLinkIcon } from '../assets/link.svg';

export const Title = styled.h2`
    margin: ${({ margin }) => margin || '30px 0 4px'};
    font-size: 16px;
    font-weight: bold;
`;

export const Subtitle = styled.h2`
    color: ${({ color }) => color || colors.themeTextColor11};
    font-size: ${({ size }) => size || '14px'};
    margin: ${({ margin }) => margin || '0'};
    line-height: ${({ lineHeight }) => lineHeight || null};
    text-align: ${({ textAlign }) => textAlign || null};
`;

export const FormWrapper = styled.div`
    display: grid;
    gap: ${({ gap }) => gap || '16px'};
`;

export const LinkSelectField = styled.div`
    display: flex;
    width: 100%;
    height: 53px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 14px;
    background-color: ${colors.themeColor3};
    border: 1px solid ${colors.white};
    border-radius: 8px;
    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
    cursor: default;
`;

export const SelectTitleText = styled.div`
    color: ${({ color }) => color || colors.boldDark};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
`;

export const SelectTitleValue = styled.div`
    color: ${({ color }) => color || colors.themeTextColor11};
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.01em;
`;

export const ListTransactionItem = styled.li`
    display: flex;
    flex-direction: ${({ direction }) => direction || 'row'};
    justify-content: ${({ justifyContent }) => justifyContent || null};
    position: relative;
    background: ${({ background }) => background || null};
    padding: ${({ padding }) => padding || '8px 12px'};
    margin-bottom: ${({ bottom }) => bottom || '8px'};
    min-height: ${({ height }) => height || '56px'};
    align-items: ${({ align }) => align || null};
    border-radius: ${({ radius }) => radius || '8px'};
`;

export const TransactionIcon = styled.div`
    position: absolute;
    right: 12px;
    height: 24px;
    width: 24px;
    &:hover {
        transform: rotate(90deg);
        transition: 0.25s;
    }
`;

export const CommissionBlock = styled.div`
    background: ${colors.lightGreen};
    padding: 10px;
    color: ${({ color }) => color || colors.darkGreen1};
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${({ top }) => top || '15px'};
`;

export const FormFooterWrap = styled.div`
    position: ${({ position }) => position || 'absolute'};
    bottom: ${({ bottom }) => bottom || '20px'};
    right: ${({ right }) => right || '0px'};
    left: ${({ left }) => left || '0px'};
    display: ${({ display }) => display || 'flex'};
    flex-direction: ${({ direction }) => direction || 'column'};
    align-items: ${({ alignItems }) => alignItems || 'flex-start'};
    padding: ${({ padding }) => padding || '10px 16px 5px'};
    background: ${({ background }) => background || colors.white};
    margin: ${({ margin }) => margin || '50px 0 0 0'};
    box-shadow: ${({ boxShadow }) => boxShadow || '0px -8px 16px rgba(0, 0, 0, 0.04)'};
`;

export const ServiceChargeBlock = styled.div`
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
    align-items: ${({ alignItems }) => alignItems || 'center'};
    background: ${({ background }) => background || colors.white};
    color: ${({ color }) => color || colors.themeTextColor11};
    width: ${({ width }) => width || '100%'};
    margin: ${({ margin }) => margin || '6px 0 0px'};
`;

export const Paragraph = styled.p`
    margin: 0;
    padding: 0;
    font-size: ${({ size }) => size || '14px'};
    font-weight: ${({ weight }) => weight || '400'};
    display: flex;
    align-items: center;
`;

export const ModifiedDiscountIcon = styled(DiscountIcon)`
    margin-right: 10px;
`;

export const TransactionDetails = styled.div`
    height: 277px;
    margin: 30px 0 16px;
    background: ${({ color }) => color || colors.white};
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: grid;
    grid-template-rows: 187px 90px;
`;

export const NetworkProviderDetails = styled.div`
    display: grid;
    grid-template-columns: 10px auto;
    column-gap: 26px;
    padding-left: 31px;
    padding-top: 32px;
    padding-bottom: 24px;
`;

export const Artwork = styled.div`
    min-height: 84px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ModifiedTransactionLinkIcon = styled(TransactionLinkIcon)`
    margin-bottom: 3px;
`;

export const Details = styled.div`
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 24px;
`;

export const TransactionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TransactionWrapperWithCharge = styled(TransactionWrapper)`
    display: grid;
    gap: 3px;
`;

export const FlowTitle = styled(Paragraph)`
    color: ${colors.subGrey};
    letter-spacing: 0.01em;
    font-size: 10px;
    text-transform: uppercase;
`;

export const OutflowAccount = styled(Paragraph)`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.lightBlack};
`;

export const Balance = styled(Paragraph)`
    font-size: 12px;
    line-height: 18px;
    color: ${({ color }) => color || colors.boldDark};
`;

export const PhoneNumber = styled(Paragraph)`
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: ${colors.lightBlack};
`;

export const Amount = styled(Paragraph)`
    font-size: 11px;
    line-height: 16px;
    color: ${({ color }) => color || colors.boldDark};
`;

export const NetworkProviderAndAmount = styled.div`
    display: grid;
    grid-template-columns: 40px 1fr;
    margin-top: 3px;
    column-gap: 12px;
    align-items: center;
`;

export const NetworkProviderLogo = styled.img`
    height: ${({ height }) => height || '32px'};
    width: ${({ width }) => width || '32px'};
    margin: ${({ margin }) => margin || null};
`;

export const SelectedNetworkProvider = styled.div`
    display: flex;
    align-items: center;
    margin-top: 11px;
`;

export const SelectedNetworkProviderLogo = styled.img`
    height: 16px;
    width: 16px;
    margin-right: 8px;
`;

export const AmountTitle = styled(Paragraph)`
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: ${colors.boldDark};
`;

export const TotalTransactionAmount = styled(Paragraph)`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${({ color }) => color || colors.boldDark};
`;

export const TotalAmountBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    border-top: 1px dashed ${colors.cardBorder};
    margin: 0 8px;
`;

export const TransactionPinFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const WalletBalance = styled.div`
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: ${({ size }) => size || '10px'};
    line-height: ${({ lineHeight }) => lineHeight || '16px'};
    color: ${({ color }) => color || colors.white};
    letter-spacing: 0.01em;
    margin-left: ${({ marginLeft }) => marginLeft || '16px'};
    margin-right: ${({ marginRight }) => marginRight || '3px'};
    margin-top: ${({ marginTop }) => marginTop || '-5px'};
`;

export const Dot = styled.span`
    height: 6px;
    width: 6px;
    background-color: ${({ color }) => color || colors.white};
    border-radius: 50%;
    display: inline-block;
    margin-right: 1px;
    margin-left: 1px;
`;

export const WalletBalanceTitle = styled.h6`
    font-size: 12px;
    margin-bottom: ${({ bottom }) => bottom || '4px'};
    color: ${({ color }) => color || colors.white};
    font-weight: 400;
    line-height: 18px;
`;

export const WalletBalanceText = styled(Paragraph)`
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
`;

export const WalletBalanceAmount = styled(WalletBalanceText)`
    font-weight: bold;
    margin-left: 2px;
`;

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
`;

export const TransactionCharge = styled(Paragraph)`
    font-size: 12px;
`;
