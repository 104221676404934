import React, { Fragment, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { RippleButton, TopHeader } from '../../../../../components';
import { Add } from '../../../../../containers/HeaderContainer';
import { colors } from '../../../../../styles';
import { SHOP_START_CREATE_SALE } from '../../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../../utils/mix-panel/mixPanel';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';

import { ReactComponent as SalesIcon } from './assets/salesHistory.svg';
import { ReactComponent as SalesReportIcon } from './assets/salesReport.svg';
import SalesReports from './reports';

import Sales from './index';

const Container = styled.div`
    padding: 0 17px;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-top: 60px;
    background: #f9fafc;
`;

const TabOutterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 26px;
`;

const TabWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    background: ${colors.gray4};
    width: 248px;
    height: 40px;
    border-radius: 12px;
`;

const SalesHistoryIcon = styled(SalesIcon)`
    width: 30px;
    height: 30px;
    position: relative;
    left: 8px;
`;

const SalesReportsIcon = styled(SalesReportIcon)`
    width: 30px;
    height: 30px;
    position: relative;
    left: 6px;
`;

const TextCover = styled.span`
    position: relative;
    top: 10px;
    width: 200px;
`;

const SalesList = () => {
    const location = useLocation();
    const search = location.search;
    const step = new URLSearchParams(search).get('step');
    const defaultShop = useSelector((state) => state.applications.myShop.shops[0]) || {};
    const userId = useSelector((state) => state.user.userId);
    const merchantId = location && location.state ? location.state.merchantId : defaultShop.merchantId;
    const branchId = location && location.state ? location.state.branchId : defaultShop.branchId;
    const [selectedTab, setSelectedTab] = useState(0);
    const tabs = [
        {
            Icon: <SalesHistoryIcon />,
            title: 'Sales History',
            component: <Sales />,
        },
        {
            Icon: <SalesReportsIcon />,
            title: 'Sales Reports',
            component: <SalesReports />,
        },
    ];

    useEffect(() => {
        if (step && Number(step) >= 0 && Number(step) <= 1) {
            setSelectedTab(Number(step));
        }
    }, [step]);
    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader
                    title={'Sales'}
                    withSpacesHeader={false}
                    backLink={'/'}
                    noBorderBottom
                    backgroundColor="#F9FAFC"
                >
                    <Link
                        to={{
                            pathname: '/actions/shop_sales_add',
                            state: { merchantId, branchId },
                        }}
                        onClick={() => {
                            mixPanel.track(SHOP_START_CREATE_SALE, {
                                'Entry Point': 'Sales',
                                Time: new Date().toLocaleDateString(),
                                'User ID': userId,
                                'shop ID': defaultShop.id,
                            });
                        }}
                    >
                        <Add right={'16px'} />
                    </Link>
                </TopHeader>
                <Container>
                    <TabOutterWrapper>
                        <TabWrapper>
                            {tabs.map((tab, index) => (
                                <RippleButton
                                    onClick={() => setSelectedTab(index)}
                                    key={index}
                                    width="114px"
                                    top="0"
                                    size="12px"
                                    display="flex"
                                    justifyContent="center"
                                    ptop={'5px'}
                                    color={selectedTab === index ? colors.border.active : colors.border.active}
                                    type="button"
                                    height="30px"
                                    radius="8px"
                                    backgroundColor={selectedTab === index ? colors.white : colors.gray4}
                                >
                                    {tab.Icon}
                                    <TextCover>{tab.title}</TextCover>
                                </RippleButton>
                            ))}
                        </TabWrapper>
                    </TabOutterWrapper>
                </Container>
                {tabs[selectedTab].component}
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default SalesList;
