import { USER_LOGOUT } from '../../user/constants';

import {
    LOADING_STORE,
    POST_STORE_FRONT,
    FETCH_STORE_PRODUCTS,
    LOADING_STORE_PRODUCTS,
    FETCH_STORE_PRODUCT,
    STOREFRONT_PRODUCT_LIST,
    FETCH_STORE,
    STOREFRONT_PRODUCT,
    DELETE_PRODUCT,
    FETCH_ORDERS,
    FETCH_ORDERS_SUMMARY,
    FETCH_MERCHANT_ORDER,
    FETCH_COUNTRY_BY_NAME,
    FETCH_STATES_BY_ID,
    FETCH_LGA_BY_STATE,
    FETCH_STORE_PUBLIC,
    FETCH_STORE_LINK,
    FETCH_PRODUCT_PUBLIC,
    SAVE_LAST_ORDER_PLACED,
    FETCH_CUSTOMER_ORDERS,
} from './constants';

const initialState = {
    loadingStore: false,
    loadingProducts: false,
    storeFrontDetails: {},
    storeFrontProducts: [],
    storeFrontProduct: {},
    store: {},
    storeFrontProductsList: [],
    storeFrontSingleproduct: {},
    storeFrontOrdersList: [],
    storeFrontOrdersSummary: {},
    storeFrontMerchantOrder: {},
    country: [],
    states: [],
    lga: [],
    publicProducts: [],
    publicProduct: {},
    storeByLink: {},
    order: {},
    customersOrders: [],
};

export default function myStoreReducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_STORE: {
            return {
                ...state,
                loadingStore: action.payload,
            };
        }

        case LOADING_STORE_PRODUCTS: {
            return {
                ...state,
                loadingProducts: action.payload,
            };
        }

        case DELETE_PRODUCT: {
            return {
                ...state,
                storeFrontProductsList: state.storeFrontProductsList.filter((prod) => prod.id !== action.payload),
            };
        }

        case STOREFRONT_PRODUCT_LIST: {
            return {
                ...state,
                storefrontProductsList: action.payload,
            };
        }

        case FETCH_STORE_PUBLIC: {
            return {
                ...state,
                publicProducts: action.payload,
            };
        }

        case FETCH_CUSTOMER_ORDERS: {
            return {
                ...state,
                customersOrders: action.payload,
            };
        }

        case FETCH_PRODUCT_PUBLIC: {
            return {
                ...state,
                publicProduct: action.payload,
            };
        }

        case SAVE_LAST_ORDER_PLACED: {
            return {
                ...state,
                order: action.payload,
            };
        }

        case FETCH_STORE_LINK: {
            return {
                ...state,
                storeByLink: action.payload,
            };
        }

        case STOREFRONT_PRODUCT: {
            return {
                ...state,
                storeFrontSingleproduct: action.payload,
            };
        }

        case FETCH_COUNTRY_BY_NAME: {
            return {
                ...state,
                country: action.payload,
            };
        }

        case FETCH_STATES_BY_ID: {
            return {
                ...state,
                states: action.payload,
            };
        }

        case FETCH_LGA_BY_STATE: {
            return {
                ...state,
                lga: action.payload,
            };
        }

        case POST_STORE_FRONT: {
            const newDetails = { ...action.payload };
            return {
                ...state,
                storeFrontDetails: newDetails,
            };
        }

        case FETCH_STORE_PRODUCTS: {
            const newProducts = [...action.payload];
            return {
                ...state,
                storeFrontProducts: newProducts,
            };
        }

        case FETCH_STORE_PRODUCT: {
            const newProduct = [...action.payload];
            return {
                ...state,
                storeFrontProduct: newProduct,
            };
        }

        case FETCH_STORE: {
            return {
                ...state,
                store: action.payload,
            };
        }

        case FETCH_ORDERS: {
            return {
                ...state,
                storeFrontOrdersList: action.payload,
            };
        }

        case FETCH_MERCHANT_ORDER: {
            return {
                ...state,
                storeFrontMerchantOrder: action.payload,
            };
        }

        case FETCH_ORDERS_SUMMARY: {
            return {
                ...state,
                storeFrontOrdersSummary: action.payload,
            };
        }

        case USER_LOGOUT: {
            return {
                storefrontProductsList: [],
                store: {},
                storeFrontSingleproduct: {},
                storeFrontOrdersList: [],
                storeFrontOrdersSummary: {},
                storeFrontMerchantOrder: {},
                country: [],
                states: [],
                lga: [],
                publicProducts: [],
                publicProduct: {},
                storeByLink: {},
            };
        }

        default: {
            return state;
        }
    }
}
