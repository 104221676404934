import React, { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Loader } from '../../../../../components';
import { PhoneCheck } from '../../../../../components/forms/input/phone-check';
import { colors } from '../../../../../styles';
import { SharedQuantityRow } from '../shared-slots';
import { ClickToEdit, QuantityAllocationSummary } from '../split-configuration';
import { SubTitle, Title, Paragraph, Card, ModifiedParticipantsBuyIcon } from '../styles';

const InputsBlock = styled.div`
    width: 100%;
    margin: 13px 0;
`;

const Add = styled.button`
    color: ${colors.deepBlue};
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    background: none;
    border: none;
    margin: 10px auto;
    display: flex;
`;

export const GroupBuySplitFriends = ({
    product,
    noOfInvitees = 1,
    unitPrice = 0,
    invitedFriends,
    setInvitedFriends,
    invitedSlots,
    backToQuantityAllocation,
}) => {
    const [friendsInputs, setFriendsInputs] = useState({});
    const [inputsRender, setInputsRender] = useState(0);

    const isLoading = useSelector((state) => state.applications.merchbuy.isLoading);

    const handleChange = ({ name, val }) => {
        setFriendsInputs({
            ...friendsInputs,
            [name]: val,
        });

        if (String(val).length === 11) {
            updateContactsList({ number: val, name: '', position: name });
        }

        // we can update the state here with the number only for starters

        // check if the contact exists already in the list
        // if yes, update the contact
        // if no, add the contact to the list
    };

    const updateContactsList = (contact) => {
        const { name, number, position } = contact;

        const copyOfNameAndPhoneNumbers = [...invitedFriends];

        copyOfNameAndPhoneNumbers[position] = {
            name,
            number,
        };

        setInvitedFriends(copyOfNameAndPhoneNumbers);
    };

    const renderInputs = () => {
        let arr = [];

        for (let i = 0; i < inputsRender; i++) {
            arr.push(i);
        }

        return arr.map((el, index) => (
            <PhoneCheck
                key={el}
                name={index}
                inputVal={friendsInputs[index] || ''}
                handleChange={handleChange}
                callback={updateContactsList}
            />
        ));
    };

    const { images } = product || {};

    useEffect(() => {
        if (invitedFriends.length > noOfInvitees) {
            setInputsRender(invitedFriends.length);
            return;
        }

        setInputsRender(noOfInvitees);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // if (invitedFriends.length < 1) return;
        let friendsInput = {};
        invitedFriends.forEach((contact, index) => {
            friendsInput[index] = contact.number;
        });

        // const numbersOnly = invitedFriends.map((friend) => friend.number);
        setFriendsInputs(friendsInput);

        return () => {
            // clean up the state
        };
        // setNameAndPhoneNumbers(invitedFriends);
    }, [invitedFriends]);

    // when do we want to disable adding more inputs
    // if the last input is not filled - filled

    const disableAddMoreFriendsButton = useMemo(() => {
        // when the number of inputs is more than the number of items in existing inputs
        const existingInputs = Object.values(friendsInputs);

        return !existingInputs[inputsRender - 1] || existingInputs[inputsRender - 1].length < 11;

        // if the last input is not filled
    }, [friendsInputs, inputsRender]);

    if (isLoading) {
        return <Loader />;
    } else
        return (
            <>
                <Title margin={'20px 0 15px'} color={'#718596'}>
                    Share
                </Title>

                <Card
                    flexDirection={'column'}
                    alignItems={'flex-start'}
                    justifyContent={'center'}
                    padding={'21px 26px 14px 32px'}
                    margin={'0 0 30px auto'}
                    width={'97%'}
                    onClick={backToQuantityAllocation}
                >
                    <SharedQuantityRow images={images} invitedSlots={invitedSlots} unitPrice={unitPrice} />

                    <QuantityAllocationSummary>
                        <ClickToEdit>Click To edit</ClickToEdit>
                    </QuantityAllocationSummary>

                    <ModifiedParticipantsBuyIcon color={'#7E42F5'} />
                </Card>

                <Title margin={'40px 0 10px'}>invite</Title>

                <SubTitle>Invite your friends</SubTitle>
                <Paragraph color={'#718596'}>
                    Invite a minimum of {noOfInvitees - 1} {noOfInvitees - 1 > 1 ? 'friends' : 'friend'}
                </Paragraph>

                <InputsBlock>{renderInputs()}</InputsBlock>
                <Add disabled={disableAddMoreFriendsButton} onClick={() => setInputsRender(inputsRender + 1)}>
                    + Add More Friends (Recommended)
                </Add>
            </>
        );
};

//export default GroupBuySplitFriends;
