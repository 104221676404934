import React, { Fragment, useState } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as RequestPaymentIcon } from '../../../../../assets/add.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/delete.svg';
import { ReactComponent as ShareIcon } from '../../../../../assets/share.svg';
import {
    TopHeader,
    RippleButton,
    UpdateIncompletePayment,
    ConfirmPopupDialog,
    OptionsPopupDialog,
    RippleLink,
} from '../../../../../components';
import { ScreenContainer } from '../../../../../containers/ScreenContainer';
import { deleteSale, updateSale } from '../../../../../redux/ducks/applications/my-shop/actions/sales';
import { colors } from '../../../../../styles';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import { formatCurrency } from '../../../../../utils/currency/parseBalance';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import CustomerDetails from '../../customers/customer-details';
import { ReactComponent as ActivityGraphIcon } from '../assets/activity-graph.svg';
import Bg from '../assets/Background.svg';
import { ReactComponent as NotificationIcon } from '../assets/notification.svg';
import { ReactComponent as SendIcon } from '../assets/send.svg';

import ReminderPopup from './popup';

const Header = styled.div`
    height: 178px;
    background: rgba(31, 123, 250, 1);
    background-image: url('${Bg}');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

const Box = styled.div`
    min-height: 310px;
    background: ${colors.white};
    border-radius: 8px;
    padding: 39px 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 16px;
`;

const BoxWrapper = styled.div`
    max-height: 70vh;
    overflow-y: scroll;
    position: relative;
    top: -80px;
    padding-bottom: 100px;
    border-radius: 8px;
`;

const GrayBox = styled.div`
    width: 100%;
    min-height: 156px;
    background: rgba(237, 242, 247, 0.5);
    border-radius: 8px;
    padding: 16px;
`;

const Text = styled.div`
    font-size: ${({ size }) => size || '12px'};
    color: ${({ color }) => color || colors.boldDark};
    padding: ${({ padding }) => padding || null};
    line-height: ${({ lineHeight }) => lineHeight || '18px'};
    font-weight: ${({ weight }) => weight || '400'};
    text-align: ${({ align }) => align || null};
    letter-spacing: ${({ spacing }) => spacing || null};
    opacity: ${({ opacity }) => opacity || null};

    ${({ elipsis }) =>
        elipsis &&
        `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
    `}
`;

const Dash = styled.div`
    width: 100%;
    height: 0px;
    border: 1px dashed ${colors.lightGray};
    margin: 15px 0 9px 0;
`;

const ShortDash = styled.div`
    width: 22px;
    height: 0px;
    border: 1px dashed ${colors.faintGrey};
`;

const Row = styled.div`
    width: ${({ noWidth }) => !noWidth && '100%'};
    display: flex;
    justify-content: ${({ justify }) => justify || 'space-between'};
    align-items: center;
    padding: ${({ padding }) => padding || '8px 0 0 0'};
`;

const ItemOutterWrapper = styled.div`
    width: 100%;
`;

const ItemWrapper = styled.div`
    width: 100%;
    background: rgba(237, 242, 247, 0.5);
    border-radius: 4px;
    height: 52px;
    padding: 8px;
    margin-top: 4px;
`;

const ProfitContainer = styled.div`
    height: 48px;
    background: ${colors.white};
    border-radius: 8px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 16px 0 16px;
`;

const FooterWrapper = styled.div`
    height: 80px;
    position: fixed;
    background: ${colors.white};
    box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.04);
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 0px;
    width: 100%;
    max-width: 375px;
    margin: auto;
    @media (max-width: 576px) {
        max-width: 100%;
    }
`;

const NoteWrapper = styled.div`
    min-height: 68px;
    padding: 16px;
    background: ${colors.white};
`;

const SaleDetails = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [openUpdatePayment, setOpenUpdatePayment] = useState(false);
    const [confirmDeletionOpen, setConfirmDeletionOpen] = useState(false);
    const [openOptions, setOpenOptions] = useState(false);
    const [openCustomerDetails, setOpenCustomerDetails] = useState(false);
    const [openRequestPayment, setOpenRequestPayment] = useState(false);

    const sale = location.state.sale;
    const shopId = location.state.shopId;
    const id = sale.salesInfo ? sale.salesInfo.id : sale.id;
    const saleStatus = sale.salesInfo ? sale.salesInfo.paymentCompleteStatus : sale.paymentCompleteStatus;
    const saleCompleteStatus = saleStatus ? 'PAID' : 'PENDING';
    const customerName =
        sale.salesInfo && sale.salesInfo.customer ? sale.salesInfo.customer.name : sale.customer && sale.customer.name;
    const customerPhoneNumber =
        sale.salesInfo && sale.salesInfo.customer
            ? sale.salesInfo.customer.phoneNumber
            : sale.customer && sale.customer.phoneNumber;
    const note = sale.note || sale?.salesInfo?.note;
    const totalProfit = sale.profit ? sale.profit : 0;
    const totalAmount = sale.salesInfo ? sale.salesInfo.totalAmount : sale.totalAmount;
    const discount = sale.salesInfo ? sale.salesInfo.discount : sale.discount;
    const saleItemDetails = sale.salesInfo ? sale.salesInfo.salesItemDetails : sale.salesItemDetails;
    const salesDate = sale.salesInfo ? sale.salesInfo.localSalesDate : sale.localSalesDate;
    const amountOutstanding = sale.salesInfo ? sale.salesInfo.amountOutstanding : sale.amountOutstanding;
    const customerByPhoneNumber = useSelector((state) => state.applications.myShop.customers).find(
        (customer) => customer.phoneNumber === customerPhoneNumber,
    );
    const shopName = useSelector((state) => state.applications.myShop.shops).find(
        (shop) => shop.id === shopId,
    ).shopName;
    const shopPhone = useSelector((state) => state.applications.myShop.shops).find(
        (shop) => shop.id === shopId,
    ).businessPhoneNumber;

    const SaleOptions =
        saleCompleteStatus === 'PAID'
            ? [
                  {
                      Icon: ShareIcon,
                      title: 'Share Receipt',
                      link: '/actions/shop_sale_receipt',
                      linkProp: { sale, shopId },
                      click: () => {
                          setOpenOptions(!openOptions);
                      },
                  },
                  {
                      Icon: DeleteIcon,
                      title: 'Delete Sale',
                      click: () => setConfirmDeletionOpen(!confirmDeletionOpen),
                  },
              ]
            : saleCompleteStatus === 'PENDING' && customerName
            ? [
                  {
                      Icon: RequestPaymentIcon,
                      title: 'Request Payment',
                      click: () => {
                          setOpenOptions(!openOptions);
                          setOpenRequestPayment(!openRequestPayment);
                      },
                  },
                  {
                      Icon: DeleteIcon,
                      title: 'Delete Sale',
                      click: () => setConfirmDeletionOpen(!confirmDeletionOpen),
                  },
              ]
            : [
                  {
                      Icon: DeleteIcon,
                      title: 'Delete Sale',
                      click: () => setConfirmDeletionOpen(!confirmDeletionOpen),
                  },
              ];

    return (
        <Fragment>
            <DesktopBackgroundLayout bgColor={colors.lightestGray}>
                <TopHeader
                    weight="400"
                    margin="27px 0 0 0"
                    size="14px"
                    color={openRequestPayment ? colors.gray6 : colors.white}
                    noBorderBottom={true}
                    backgroundColor="transparent"
                    title="Sale Details"
                    noArrow={false}
                />
                <Header />
                <div>
                    <BoxWrapper>
                        <Box>
                            <GrayBox>
                                <Text size="20px" align="center" lineHeight="30px" weight="bold" color={colors.bgBlue}>
                                    {formatCurrency(totalAmount)}
                                </Text>
                                <Text
                                    spacing="0.01em"
                                    size="10px"
                                    align="center"
                                    lineHeight="16px"
                                    weight="bold"
                                    color={colors.lightDark}
                                >
                                    TOTAL SALE
                                </Text>
                                <Dash />
                                {discount > 0 && (
                                    <div>
                                        <Row>
                                            <Text weight="500" color={colors.subGrey}>
                                                Subtotal
                                            </Text>
                                            <Text weight="bold">{formatPrice(totalAmount + discount)}</Text>
                                        </Row>
                                        <Row>
                                            <Text weight="500" color={colors.subGrey}>
                                                Discount
                                            </Text>
                                            <Text weight="bold">{formatPrice(discount)}</Text>
                                        </Row>
                                        {saleCompleteStatus === 'PENDING' && (
                                            <Row>
                                                <Text weight="500" color={colors.subGrey}>
                                                    Total
                                                </Text>
                                                <Text weight="bold">{formatPrice(totalAmount)}</Text>
                                            </Row>
                                        )}
                                    </div>
                                )}

                                {amountOutstanding > 0 && (
                                    <div>
                                        <Row>
                                            <Text weight="500" color={colors.subGrey}>
                                                Paid
                                            </Text>
                                            <Text weight="bold">{formatPrice(totalAmount - amountOutstanding)}</Text>
                                        </Row>
                                        <Row>
                                            <Text weight="500" color={colors.subGrey}>
                                                Balance
                                            </Text>
                                            <Text weight="bold" color={colors.red}>
                                                {formatPrice(amountOutstanding)}
                                            </Text>
                                        </Row>
                                    </div>
                                )}
                                {(discount > 0 || amountOutstanding > 0) && <Dash />}

                                <Row>
                                    <Text color={colors.subGrey}>Customer</Text>
                                    <Text color={colors.subGrey}>Date</Text>
                                </Row>
                                <Row padding="3px 0 0 0">
                                    <Text weight="500" color={colors.boldDark}>
                                        {customerName || '-------'}
                                    </Text>
                                    <Text color={colors.boldDark}>{moment(salesDate).format('MMMM D, YYYY')}</Text>
                                </Row>
                            </GrayBox>
                            {saleItemDetails?.length > 0 && (
                                <ItemOutterWrapper>
                                    <Text
                                        opacity="0.6"
                                        weight="bold"
                                        spacing="0.01em"
                                        padding="16px 0 6px 0"
                                        size="10px"
                                        lineHeight="16px"
                                        color={colors.themeTextColor1}
                                    >
                                        ITEMS BOUGHT
                                    </Text>
                                    {saleItemDetails.map((item, index) => (
                                        <ItemWrapper key={index}>
                                            <Text elipsis color={colors.boldDark}>
                                                {item.name}
                                            </Text>
                                            <Row padding="0">
                                                <Text weight="500" color={colors.subGrey}>
                                                    {formatCurrency(item.itemPrice)}
                                                </Text>
                                                <Text color={colors.subGrey}>x{item.quantity}</Text>
                                            </Row>
                                        </ItemWrapper>
                                    ))}
                                </ItemOutterWrapper>
                            )}
                            <Row padding="16px 0 19px 0" justify="center">
                                <ShortDash />
                                <Text
                                    align="center"
                                    padding="0 9px"
                                    size="10px"
                                    lineHeight="16px"
                                    color={colors.subGrey}
                                >
                                    {shopName?.toUpperCase()}'S STORE
                                </Text>
                                <ShortDash />
                            </Row>
                            <Row justify={saleCompleteStatus === 'PAID' && 'center'} padding="0 16px">
                                <RippleLink
                                    to={{
                                        pathname: '/actions/shop_sale_receipt',
                                        state: { sale, shopId },
                                    }}
                                >
                                    <Row noWidth padding="0" justify="flex-start">
                                        <SendIcon />
                                        <Text padding="0 0 0 8px" weight="500" color="#051926">
                                            Send Receipt
                                        </Text>
                                    </Row>
                                </RippleLink>
                                {amountOutstanding > 0 && (
                                    <Row
                                        onClick={() => {
                                            setOpenRequestPayment(!openRequestPayment);
                                        }}
                                        noWidth
                                        padding="0"
                                        justify="flex-start"
                                    >
                                        <NotificationIcon />
                                        <Text padding="0 0 0 8px" weight="500" color="#051926">
                                            Send Reminder
                                        </Text>
                                    </Row>
                                )}
                            </Row>
                        </Box>
                        <ProfitContainer>
                            <Row noWidth justiy="flex-start">
                                <ActivityGraphIcon />
                                <Text size="14px" padding="0 0 0 11px" lineHeight="21px" color={colors.boldDark}>
                                    Profit on Sale
                                </Text>
                            </Row>
                            <Text
                                size="14px"
                                padding="0 0 0 11px"
                                lineHeight="21px"
                                color={colors.transactions.successful}
                            >
                                {formatPrice(totalProfit)}
                            </Text>
                        </ProfitContainer>
                        {note && (
                            <div>
                                <Text padding="24px 0 4px 16px" weight="bold" color={colors.subGrey}>
                                    Note
                                </Text>
                                <NoteWrapper>
                                    <Text color={colors.subGrey}>{note}</Text>
                                </NoteWrapper>
                            </div>
                        )}
                    </BoxWrapper>
                </div>
                {openUpdatePayment && (
                    <UpdateIncompletePayment
                        open={openUpdatePayment}
                        setOpen={setOpenUpdatePayment}
                        updatePayment={(salesInfo, amountToPay) => dispatch(updateSale(salesInfo, amountToPay))}
                        salesInfo={sale.salesInfo || sale}
                    />
                )}
                {openCustomerDetails && (
                    <CustomerDetails
                        customer={customerByPhoneNumber}
                        open={openCustomerDetails}
                        setOpen={setOpenCustomerDetails}
                    />
                )}
                {openOptions && (
                    <OptionsPopupDialog
                        open={openOptions}
                        cancel={() => setOpenOptions(!openOptions)}
                        title={'Options'}
                        items={SaleOptions}
                    />
                )}
                {openRequestPayment && (
                    <ReminderPopup
                        outstandingAmount={amountOutstanding}
                        shopName={shopName}
                        shopPhone={shopPhone}
                        open={openRequestPayment}
                        customerName={customerName?.split(' ')[0]}
                        close={() => setOpenRequestPayment(false)}
                        customerPhoneNumber={customerPhoneNumber}
                    />
                )}
                <ConfirmPopupDialog
                    open={confirmDeletionOpen}
                    title={'Are you sure you want to delete this sale?'}
                    confirmationText={'Deleting a sale will remove it from the list of sales.'}
                    answers={[
                        {
                            variant: 'No',
                            action: () => setConfirmDeletionOpen(!confirmDeletionOpen),
                        },
                        {
                            variant: 'Yes',
                            action: () => {
                                setConfirmDeletionOpen(!confirmDeletionOpen);
                                setOpenOptions(!openOptions);
                                dispatch(deleteSale(id));
                            },
                        },
                    ]}
                />
                <FooterWrapper>
                    <RippleButton
                        top="0"
                        height="45px"
                        right="10px"
                        type={'button'}
                        onClick={() => setOpenUpdatePayment(!openUpdatePayment)}
                    >
                        Update sale
                    </RippleButton>
                    <RippleButton
                        top="0"
                        height="45px"
                        backgroundColor={colors.white}
                        border={`1px solid ${colors.red}`}
                        color={colors.red}
                        type={'button'}
                        onClick={() => setConfirmDeletionOpen(!confirmDeletionOpen)}
                    >
                        Delete
                    </RippleButton>
                </FooterWrapper>
                <ScreenContainer paddingBottom={'65px'} top={'60px'}></ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default SaleDetails;
