import React, { useState, useEffect } from 'react';

import { string } from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../styles';

import { ReactComponent as PendingIcon } from './assets/alert_circle.svg';
import { ReactComponent as ApprovedIcon } from './assets/good.svg';
import { ReactComponent as UnapprovedIcon } from './assets/wrong.svg';

const VerificationStatusWrapper = styled.div`
    background: ${({ bgc }) => bgc || 'none'};
    margin: 4px 0px 4px 16px;
    height: 24px;
    display: flex;
    padding: 4px 8px;
    width: fit-content;
    align-items: center;
    border-radius: 24px;
`;

const StatusText = styled.span`
    height: 16px;
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 0.01em;
    color: ${({ color }) => color || colors.themeTextColor1};
    white-space: nowrap;
    margin: 0px 5px;
`;

export const VerificationStatus = ({ status }) => {
    const [statusText, setStatusText] = useState('');
    const [color, setColor] = useState('');
    const [icon, setIcon] = useState('');
    const [bg, setBg] = useState('');

    useEffect(() => {
        if (status === 'INCOMPLETE' || status === 'REJECTED') {
            setStatusText('Unapproved');
            setColor(colors.textRed);
            setIcon(<UnapprovedIcon />);
            setBg(colors.lightRedBg);
        } else if (status === 'APPROVED' || status === 'ACCEPTED') {
            setStatusText('Approved');
            setColor(colors.textGreen);
            setIcon(<ApprovedIcon />);
            setBg(colors.lightGreen);
        } else {
            setStatusText('Pending');
            setColor(colors.textYellow);
            setIcon(<PendingIcon />);
            setBg(colors.lightYellowBg);
        }
    }, [status]);

    return (
        <VerificationStatusWrapper bgc={bg}>
            {icon}
            <StatusText color={color}>{statusText}</StatusText>
        </VerificationStatusWrapper>
    );
};

VerificationStatus.propTypes = {
    status: string,
};
