export const getStoreFrontProductInfoForMixPanel = (carts) => {
    let totalItemCount = 0;
    const productIds = [];
    const productNames = [];
    carts.map((x) => {
        totalItemCount += x?.quantity;
        productIds.push(x?.id);
        productNames.push(x?.name);
        return null;
    });

    return { totalItemCount, productNames, productIds };
};
