import React, { Fragment, useState, useRef, useEffect } from 'react';

import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
    TopHeader,
    RippleButton,
    ErrorBadge,
    FlatProgressBar,
    InputWithOnchange,
    Loader,
} from '../../../../../../components';
import { ScreenContainer } from '../../../../../../containers/ScreenContainer';
import { saveTempBvnDetails } from '../../../../../../redux/ducks/account/wallet/actions';
import { colors } from '../../../../../../styles';
import DesktopBackgroundLayout from '../../../../../DesktopBackgroundLayout';

import { BvnDetailValidationSchema } from './BvnDetailValidationSchema';

const Title = styled.h2`
    font-size: 16px;
    font-weight: 700;
    color: #071827;
    text-align: left;
    margin-bottom: 6px;
    line-height: 24px;
    margin-top: ${({ top }) => top || null};
`;

const Message = styled.h2`
    font-size: 14px;
    font-weight: 400;
    color: #718596;
    text-align: left;
    margin-bottom: 6px;
    line-height: 21px;
    margin-top: ${({ top }) => top || null};
`;

const PageCount = styled(Message)`
    position: absolute;
    right: 0;
    margin-right: 16px;
`;

const InputBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const FormWrapper = styled(Form)`
    display: flex;
    flex-direction: column;
    padding: 20px 16px 0px 16px;
`;

const FloatingBottomSection = styled.div`
    position: absolute;
    width: 100%;
    bottom: -18px;
    left: 0px;
    padding: 0px 16px 40px 16px;
    background-color: #ffffff;
`;

const BvnDetail = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const formRef = useRef();
    const firstName = useSelector((state) => state.user.firstName);
    const lastName = useSelector((state) => state.user.lastName);
    const isLoading = useSelector((state) => state.account.wallet.isLoading);
    const tempBvnDetail = useSelector((state) => state.account.wallet.tempBvnDetail);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setTimeout(() => {
            if (tempBvnDetail?.bvnError) {
                setErrorMessage(tempBvnDetail.bvnError);
                // eslint-disable-next-line no-unused-expressions
                formRef.current?.setTouched({ bvn: true }, true);
                return formRef.current?.setErrors({ bvn: tempBvnDetail.bvnError });
            }

            if (tempBvnDetail?.verifyFirstNameError) {
                setErrorMessage("First name doesn't match BVN data");
                // eslint-disable-next-line no-unused-expressions
                formRef.current?.setTouched({ firstName: true }, true);
                return formRef.current?.setErrors({ firstName: "Doesn't Match Name linked with BVN" });
            }

            if (tempBvnDetail?.verifyLastNameError) {
                setErrorMessage("Last name doesn't match BVN data");
                // eslint-disable-next-line no-unused-expressions
                formRef.current?.setTouched({ lastName: true }, true);
                return formRef.current?.setErrors({ lastName: "Doesn't Match Name linked with BVN" });
            }
        }, 500);
    }, [tempBvnDetail]);

    if (isLoading) return <Loader text="Verifying Information" />;
    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'BVN Details'}>
                    <PageCount>1/3</PageCount>
                </TopHeader>
                <ScreenContainer height="100%" padding="0px" top="64px">
                    <FlatProgressBar step={1} amount={3} />
                    {errorMessage && (
                        <ErrorBadge
                            title="We couldn't Verify your data"
                            message={errorMessage}
                            onCancelClick={() => {
                                setErrorMessage('');
                                const temp = {
                                    ...tempBvnDetail,
                                    verifyFirstNameError: '',
                                    verifyLastNameError: '',
                                    bvnError: '',
                                };
                                dispatch(saveTempBvnDetails(temp));
                            }}
                        />
                    )}
                    <Formik
                        innerRef={formRef}
                        initialValues={{
                            bvn: tempBvnDetail?.bvn || '',
                            firstName: tempBvnDetail?.firstName || firstName || '',
                            lastName: tempBvnDetail?.lastName || lastName || '',
                        }}
                        validationSchema={BvnDetailValidationSchema}
                        onSubmit={async (values, { setErrors }) => {
                            // Clear error
                            const temp = {
                                ...tempBvnDetail,
                                ...values,
                                bvnError: '',
                                verifyFirstNameError: '',
                                verifyLastNameError: '',
                            };
                            dispatch(saveTempBvnDetails(temp));
                            history.push('/user/wallet_add_bank_account');
                        }}
                    >
                        {({ errors, touched, setFieldValue, initialValues, values }) => (
                            <FormWrapper>
                                <InputBlock bottom="64px">
                                    <Title>Provide your BVN</Title>
                                    <Message>Provide your 11-digit Bank Verification Number</Message>

                                    <InputWithOnchange
                                        label={'BVN'}
                                        type={'text'}
                                        value={values.bvn}
                                        placeholder={'BVN'}
                                        name="bvn"
                                        valid={`${!touched.bvn && !errors.bvn}`}
                                        autoComplete={'off'}
                                        errors={touched && touched.bvn && errors && errors.bvn}
                                        bottom="0px"
                                        height="56px"
                                        top="24px"
                                        maxLength={11}
                                        background="#F2F5FA"
                                        onChange={(e) => setFieldValue('bvn', e.target.value)}
                                        onKeyUp={(e) => {}}
                                        onFocus={(e) => {}}
                                        noClearButton={true}
                                        initialValues={initialValues}
                                    />

                                    <Title top="22px">Verify your details</Title>
                                    <Message>Kindly confirm if the name below matches what's on your BVN</Message>

                                    <InputWithOnchange
                                        label={'First name'}
                                        type={'text'}
                                        value={values.firstName}
                                        placeholder={'First name'}
                                        name="firstName"
                                        valid={`${!touched.firstName && !errors.firstName}`}
                                        autoComplete={'off'}
                                        errors={touched && touched.firstName && errors && errors.firstName}
                                        height="56px"
                                        top="24px"
                                        background="#F2F5FA"
                                        onChange={(e) => setFieldValue('firstName', e.target.value.replace(/\s/g, ''))}
                                        onKeyUp={(e) => {}}
                                        onFocus={(e) => {}}
                                        noClearButton={true}
                                        initialValues={initialValues}
                                    />

                                    <InputWithOnchange
                                        label={'Last name'}
                                        type={'text'}
                                        value={values.lastName}
                                        placeholder={'Last name'}
                                        name="lastName"
                                        valid={`${!touched.lastName && !errors.lastName}`}
                                        autoComplete={'off'}
                                        errors={touched && touched.lastName && errors && errors.lastName}
                                        height="56px"
                                        background="#F2F5FA"
                                        onChange={(e) => setFieldValue('lastName', e.target.value.replace(/\s/g, ''))}
                                        onKeyUp={(e) => {}}
                                        onFocus={(e) => {}}
                                        noClearButton={true}
                                        initialValues={initialValues}
                                    />
                                </InputBlock>

                                <FloatingBottomSection>
                                    <RippleButton
                                        type="submit"
                                        top={'2px'}
                                        backgroundColor={colors.deepBlue}
                                        disabled={!values.bvn || !values.firstName || !values.lastName}
                                    >
                                        Continue
                                    </RippleButton>
                                </FloatingBottomSection>
                            </FormWrapper>
                        )}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default BvnDetail;
