import React from 'react';

import { HorizontalBar } from 'react-chartjs-2';

import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';

export const HorizontalBarChart = ({ data, type, height }) => {
    const mydata = {
        labels: data.map((x) => x.recordType),
        datasets: [
            {
                // label: 'My First dataset',
                backgroundColor: '#579fd7',
                borderWidth: 0,
                data: type === 'Sales' ? data.map((x) => x.totalAmount) : data.map((x) => x.profit),
            },
        ],
    };
    return (
        <div>
            <HorizontalBar
                data={mydata}
                height={height}
                options={{
                    title: {
                        display: false,
                    },
                    responsive: true,
                    legend: {
                        display: false,
                    },
                    backgroundColor: 'red',
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem) {
                                return formatPrice(tooltipItem.value);
                            },
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                gridLines: {
                                    display: true,
                                    min: 20,
                                },
                                ticks: {
                                    autoSkip: true,
                                    beginAtZero: true,
                                    maxTicksLimit: 20,
                                    callback: function (value) {
                                        return formatPrice(value);
                                    },
                                },
                            },
                        ],
                        yAxes: [
                            {
                                gridLines: {
                                    display: true,
                                },
                                barPercentage: 0.2,
                                categoryPercentage: 0.6,
                                stacked: true,
                            },
                        ],
                    },
                    bezierCurve: false,
                }}
            />
        </div>
    );
};
