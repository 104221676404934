import React, { Fragment } from 'react';

import { useClearCache } from 'react-clear-cache';
import styled from 'styled-components';

import { Message } from '../../containers/MessageContainer';
import { colors } from '../../styles';
import { clearUserCache } from '../../utils/clear-cache';
import { RippleButton } from '../button';

const ClearCacheWrapper = styled.div`
    position: fixed;
    background: ${colors.white};
    padding: 10px;
    left: 0;
    margin: auto;
    bottom: 0;
    border-radius: 4px;
    border-top: 10px solid ${colors.themeColor1};
    z-index: 10000000000000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &.open {
        animation: openPopup 2s ease-out;
    }

    @keyframes openPopup {
        0% {
            transform: translateY(50px);
            opacity: 0;
        }

        60% {
            transform: translateY(50px);
            opacity: 0;
        }

        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
`;

export const ClearCache = () => {
    const cacheInfo = useClearCache({ duration: 60000 });
    const { isLatestVersion } = cacheInfo;

    const onClick = (e) => clearUserCache(e, cacheInfo);

    return (
        <Fragment>
            {!isLatestVersion && (
                <ClearCacheWrapper className={!isLatestVersion ? 'open' : ''}>
                    <div>
                        <Message>Refresh to use the latest version</Message>
                    </div>
                    <RippleButton
                        style={{
                            backgroundColor: `${colors.background.component}`,
                            color: `${colors.themeTextColor1}`,
                            paddingLeft: 20,
                            paddingRight: 20,
                            marginBottom: `20px`,
                            border: `1px solid ${colors.themeColor1}`,
                            width: '100%',
                        }}
                        onClick={onClick}
                    >
                        Refresh
                    </RippleButton>
                </ClearCacheWrapper>
            )}
        </Fragment>
    );
};
