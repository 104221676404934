import styled from 'styled-components';

import { ReactComponent as AddIcon } from '../../../../components/popup/merchbuy-add-to-cart-popup/assets/add.svg';
import { ReactComponent as SubstractIcon } from '../../../../components/popup/merchbuy-add-to-cart-popup/assets/substract.svg';
import { Info } from '../../../../containers/MessageContainer';
import { colors } from '../../../../styles';
import { ReactComponent as ParticipantsBuyIcon } from '../assets/participants-buy.svg';
import { ReactComponent as DiscountStrikethroughIcon } from '../assets/price-strikethrough.svg';
import { ReactComponent as QuantityBuyIcon } from '../assets/quantity-buy.svg';

export const Paragraph = styled.p`
    margin: ${({ margin }) => margin || '0'};
    padding: ${({ padding }) => padding || '0'};
    font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
    color: ${({ color }) => color || colors.black};
    font-size: ${({ fontSize }) => fontSize || '14px'};
    text-align: ${({ textAlign }) => textAlign || 'left'};
    width: ${({ width }) => width || 'initial'};
`;

export const Modal = styled.div`
    position: fixed;
    z-index: 100;
    padding: 50px 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: white;
`;

export const InfoMessage = styled(Info)`
    display: flex;
    align-items: center;
    padding: 32px;
    @media screen and (max-width: 420px) {
        padding: 16px;
    }
    margin-bottom: ${({ bottom }) => bottom || '8px'};
`;

export const ExclaimAmount = styled.p`
    color: #0350bb;
    width: 69px;
    height: 20px;
    position: absolute;
    top: 12%;
    left: 16%;
    font-size: 12px;
    padding: 3px 12px 3px 10px;
    @media screen and (max-width: 1264px) and (min-width: 1025px) {
        margin-top: 13px;
        margin-left: -15px;
    }
    @media only screen and (max-width: 600px) {
        margin-top: 8px;
        margin-left: -13px;
    }
    @media screen and (max-width: 823px) and (min-width: 411px) {
        margin-top: -8px;
        margin-left: 20px;
    }
    @media screen and (max-width: 568px) and (min-width: 320px) {
        margin-left: -19px;
        margin-top: -10px;
    }
    @media screen and (max-width: 812px) and (min-width: 375px) {
        margin-top: 6px;
        margin-left: 18px;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
        margin-top: 15px;
        margin-left: 10px;
    }
    @media screen and (max-width: 1366px) and (min-width: 1024px) {
        margin-top: 60px;
        margin-left: 5px;
    }
    @media screen and (max-width: 500px) and (min-width: 280px) {
        margin-left: -30px;
    }
    @media screen and (max-width: 640px) and (min-width: 360px) {
        margin-left: 10px;
        margin-top: -7px;
    }
    @media screen and (max-width: 720px) and (min-width: 540px) {
        margin-left: 55px;
    }
    @media screen and (max-width: 1369px) and (min-width: 1029px) {
        margin-top: -10px;
        margin-left: 10px;
    }
    @media only screen and (min-width: 1920px) {
        margin-top: 14px;
        margin-left: 4px;
    }
    @media only screen and (min-width: 1500px) {
        margin-top: 40px;
        margin-left: 4px;
    }
`;
export const RedAmount = styled.p`
    width: 70;
    color: #805500;
    position: absolute;
    width: 69px;
    height: 20px;
    top: 9%;
    left: 64%;
    width: 69px;
    height: 20px;
    padding: 3px 8px 3px 12px;
    font-size: 12px;
    @media screen and (max-width: 500px) and (min-width: 280px) {
        margin-left: -8px;
    }
    @media screen and (max-width: 1105px) and (min-width: 661px) {
        margin-left: -19px;
        margin-top: -9px;
    }
    @media screen and (max-width: 1264px) and (min-width: 1025px) {
        margin-top: -10px;
        margin-left: -15px;
    }
    @media only screen and (max-width: 600px) {
        margin-top: 7px;
        margin-left: 7px;
    }
    @media screen and (max-width: 568px) and (min-width: 320px) {
        margin-top: -5px;
    }
    @media screen and (max-width: 823px) and (min-width: 411px) {
        margin-top: 25px;
        margin-left: 12px;
    }
    @media screen and (max-width: 812px) and (min-width: 375px) {
        margin-left: 5px;
        margin-top: 20px;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
        margin-top: 40px;
        margin-left: 10px;
    }
    @media screen and (max-width: 1366px) and (min-width: 1024px) {
        margin-top: 85px;
        margin-left: 5px;
    }
    @media screen and (max-width: 720px) and (min-width: 540px) {
        margin-left: -20px;
        margin-top: 10px;
    }
    @media screen and (max-width: 1369px) and (min-width: 1029px) {
        margin-top: 5px;
        margin-left: 10px;
    }
    @media only screen and (min-width: 1920px) {
        margin-top: 50px;
        margin-left: 4px;
    }
    @media only screen and (min-width: 1500px) {
        margin-top: 50px;
        margin-left: 4px;
    }
`;
export const WinkAmount = styled.p`
    color: #290674;
    width: 70;
    position: absolute;
    top: 25%;
    left: 23%;
    width: 69px;
    height: 20px;
    padding: 3px 10px 3px 10px;
    font-size: 12px;
    @media screen and (max-width: 568px) and (min-width: 320px) {
        margin-left: -25px;
    }
    @media screen and (max-width: 1264px) and (min-width: 1025px) {
        margin-top: 12px;
        margin-left: -15px;
    }
    @media only screen and (max-width: 600px) {
        margin-top: 21px;
        margin-left: -12px;
    }
    @media screen and (max-width: 823px) and (min-width: 411px) {
        margin-top: 20px;
        margin-left: -5px;
    }
    @media screen and (max-width: 812px) and (min-width: 375px) {
        margin-top: 10px;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
        margin-top: -10px;
        margin-left: -17px;
    }
    @media screen and (max-width: 1366px) and (min-width: 1024px) {
        margin-left: 8px;
    }

    @media screen and (max-width: 720px) and (min-width: 540px) {
        margin-left: 35px;
    }
    @media screen and (max-width: 300px) and (min-width: 280px) {
        margin-left: -20px;
    }
    @media screen and (max-width: 1369px) and (min-width: 1029px) {
        margin-top: 20px;
        margin-left: 10px;
    }
`;
export const GirlAmount = styled.p`
    color: #2e4457;
    width: 70;
    position: absolute;
    width: 69px;
    height: 20px;
    padding: 3px 8px 3px 12px;
    top: 19%;
    left: 72%;
    font-size: 12px;
    @media screen and (max-width: 1366px) and (min-width: 1024px) {
        margin-left: 5px;
        margin-top: 20px;
    }
    @media screen and (max-width: 720px) and (min-width: 540px) {
        margin-left: -35px;
    }
    @media only screen and (max-width: 600px) {
        margin-top: 20px;
        margin-left: 9px;
    }
    @media screen and (max-width: 823px) and (min-width: 411px) {
        margin-top: 30px;
        margin-left: -3px;
    }

    @media screen and (max-width: 720px) and (min-width: 540px) {
        margin-left: -35px;
    }
`;

export const WinkItem = styled.img`
    padding: 5px;
    @media screen and (max-width: 1264px) and (min-width: 1025px) {
        margin-top: 2px;
        margin-left: -15px;
    }
    @media only screen and (max-width: 600px) {
        margin-top: 5px;
        margin-left: -5px;
    }
    @media screen and (max-width: 823px) and (min-width: 411px) {
        margin-top: -5px;
        margin-left: 1px;
    }
    @media screen and (max-width: 812px) and (min-width: 375px) {
        margin-top: -6px;
        margin-left: -10px;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
        margin-top: -30px;
        margin-left: -15px;
    }
    @media screen and (max-width: 1366px) and (min-width: 1024px) {
        margin-top: -30px;
        margin-left: 5px;
    }
    @media screen and (max-width: 720px) and (min-width: 540px) {
        margin-left: 20px;
    }
    @media screen and (max-width: 1369px) and (min-width: 1029px) {
        margin-top: 4px;
        margin-left: 10px;
    }

    @media only screen and (min-width: 1920px) {
        margin-top: -14px;
        margin-left: 4px;
    }
    @media only screen and (min-width: 1500px) {
        margin-top: -10px;
        margin-left: 4px;
    }
`;

export const ExclaimItem = styled.img`
    padding: 5px;
    @media screen and (max-width: 1264px) and (min-width: 1025px) {
        margin-top: 2px;
        margin-left: -15px;
    }
    @media screen and (max-width: 823px) and (min-width: 411px) {
        margin-top: -16px;
        margin-left: 20px;
    }
    @media screen and (max-width: 568px) and (min-width: 320px) {
        margin-left: -10px;
        margin-top: -20px;
    }
    @media screen and (max-width: 812px) and (min-width: 375px) {
        margin-left: 15px;
        margin-top: -6px;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
        margin-top: 10px;
        margin-left: 12px;
    }
    @media screen and (max-width: 1366px) and (min-width: 1024px) {
        margin-top: 50px;
        margin-left: 5px;
    }
    @media screen and (max-width: 500px) and (min-width: 280px) {
        margin-left: -18px;
    }
    @media screen and (max-width: 640px) and (min-width: 360px) {
        margin-left: 10px;
        margin-top: -15px;
    }
    @media screen and (max-width: 720px) and (min-width: 540px) {
        margin-left: 40px;
    }
    @media screen and (max-width: 1369px) and (min-width: 1029px) {
        margin-top: -16px;
        margin-left: 10px;
    }
    @media only screen and (min-width: 1920px) {
        margin-top: 14px;
        margin-left: 4px;
    }
    @media only screen and (min-width: 1500px) {
        margin-top: 30px;
        margin-left: 4px;
    }
`;
export const GirlItem = styled.img`
    padding: 5px;
    @media only screen and (max-width: 600px) {
        margin-top: 4px;
        margin-left: 4px;
    }
    @media screen and (max-width: 720px) and (min-width: 540px) {
        margin-left: -30px;
    }
    @media screen and (max-width: 823px) and (min-width: 411px) {
        margin-top: 10px;
        margin-left: -3px;
    }
    @media screen and (max-width: 720px) and (min-width: 540px) {
        margin-left: -20px;
    }
`;

export const RedItem = styled.img`
    padding: 5px;
    width: 10%;

    @media only screen and (max-width: 600px) {
        margin-top: -4px;
        margin-left: 5px;
    }
    @media screen and (max-width: 1264px) and (min-width: 1025px) {
        margin-top: -30px;
        margin-left: -15px;
    }
    @media screen and (max-width: 568px) and (min-width: 320px) {
        margin-left: 6px;
        margin-top: -13px;
    }
    @media screen and (max-width: 823px) and (min-width: 411px) {
        margin-top: 6px;
        margin-left: 10px;
    }
    @media screen and (max-width: 812px) and (min-width: 375px) {
        margin-top: 6px;
        margin-left: 5px;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
        margin-top: 28px;
        margin-left: 5px;
    }
    @media screen and (max-width: 1366px) and (min-width: 1024px) {
        margin-top: 64px;
        margin-left: 5px;
    }
    @media screen and (max-width: 720px) and (min-width: 540px) {
        margin-left: -15px;
        margin-top: -6px;
        width: 8%;
    }
    @media screen and (max-width: 300px) and (min-width: 280px) {
        width: 13%;
    }
    @media screen and (max-width: 1369px) and (min-width: 1029px) {
        margin-top: -4px;
        margin-left: 10px;
    }
    @media only screen and (min-width: 1920px) {
        margin-top: 35px;
        margin-left: 4px;
    }
    @media only screen and (min-width: 1500px) {
        margin-top: 40px;
        margin-left: 4px;
    }
`;

export const ConeItem = styled.img`
    padding: 5px;
    border-radius: 50%;
    background-color: #fff7e6;
    margin-bottom: -25px;
    margin-left: 25px;
    height: 28px;
    width: 22px;
`;

export const BlockWithBorders = styled.div`
    padding: ${({ padding }) => padding || '16px'};
    border: 1px solid #eeeeee;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 24px;
`;

export const QuantityControls = styled.div`
    display: flex;
`;

export const ModifiedSubstractIcon = styled(SubstractIcon)`
    margin: 0;
    margin-right: 5px;

    height: 40px;
    width: 40px;
    color: ${({ disabled }) => (disabled ? 'transparent' : colors.deepBlue)};
    stroke: ${({ disabled }) => (disabled ? '#BBC0C9' : 'white')};
`;

export const ModifiedAddIcon = styled(AddIcon)`
    margin: 0;
    margin-left: 5px;

    height: 40px;
    width: 40px;

    color: ${({ disabled }) => (disabled ? 'transparent' : colors.deepBlue)};
    stroke: ${({ disabled }) => (disabled ? '#BBC0C9' : 'white')};
`;

export const ModifiedQuantityBuyIcon = styled(QuantityBuyIcon)`
    position: absolute;
    left: -16px;
    top: 16px;
`;

export const ModifiedParticipantsBuyIcon = styled(ParticipantsBuyIcon)`
    position: absolute;
    left: -16px;
    top: 16px;
    color: ${({ color }) => (color ? color : '#FFC042')};
`;

export const UpdateCalculatorBlock = styled.div`
    margin: 16px 0;
    padding: 16px 0;

    border-top: ${({ borderTop }) => borderTop || `1px solid ${colors.gray4}`};

    display: flex;
    align-items: center;
    justify-content: space-between;

    & > svg {
        cursor: pointer;
    }
`;

export const MinKey = styled.div`
    margin: 16px 0;
    font-size: 10px;
    font-weight: 400;
    color: #56636d;
`;

export const MinValue = styled.span`
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    color: #56636d;
`;

export const Table = styled.table`
    border: none;
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    color: #56636d;
    font-size: 12px;
`;

export const TH = styled.th`
    border: none;
    border-collapse: collapse;
    font-weight: 400;
    padding: 10px;
    width: 50%;
`;

export const TD = styled.td`
    border: none;
    border-collapse: collapse;
    padding: 10px;
`;

export const TR = styled.tr`
    &.active {
        color: #62a9dc;
    }
`;

export const CenterBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SpacedBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: ${({ align }) => align || 'center'};
    margin-top: ${({ top }) => top || '0px'};
`;

export const Title = styled.p`
    font-weight: ${({ weight }) => weight || '500'};
    font-size: ${({ size }) => size || '12px'};
    color: ${({ color }) => color || colors.deepBlue};
    margin-bottom: 20px;
    margin: ${({ margin }) => margin || '0px'};
    text-transform: uppercase;
`;

export const SubTitle = styled.p`
    font-weight: ${({ weight }) => weight || '500'};
    font-size: 20px;
    font-weight: bold;
    color: ${colors.black};
    text-align: ${({ align }) => align || 'left'};
    margin: ${({ margin }) => margin || '10px 0'};
`;

export const ProductInfo = styled.div`
    flex: 1;
    font-size: ${({ size }) => size || '14px'};
    display: flex;
    align-items: ${({ alignItems }) => alignItems || 'center'};
    flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
`;

export const ProductBlock = styled.div`
    margin: ${({ margin }) => margin || '30px 0 0 0'};
    padding: ${({ padding }) => padding || '0'};
    display: flex;
    align-items: ${({ alignItems }) => alignItems || 'center'};
    flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
    box-shadow: ${({ shadow }) => shadow || '0px 0px 0px 0px #e8e8e8'};
    background: ${({ background }) => background || 'none'};
    height: ${({ height }) => height || 'auto'};
`;

export const ProductImageContainer = styled.div`
    width: ${({ width }) => width || '40px'};
    height: ${({ height }) => height || '40px'};
    padding: 4px 7px;
    background: ${colors.gray4};
    border-radius: 1.60131px;
    margin-right: ${({ marginRight }) => marginRight || '16px'};
`;

export const ProductImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`;

export const ChipSpan = styled.span`
    border-radius: 10px;
    color: #22a8ff;
    text-align: center;
    padding: 4px 12px 4px 12px;
    background-color: #22a8ff33;
    font-weight: 500;
    font-size: 12px;
`;

export const BuyDescription = styled.div`
    border-radius: 10px;
    font-weight: 700;
    font-size: 20px;
    margin-top: 10px;
    marginbottom: 20px;
    color: black;
    line-height: 30px;
`;

export const BuyFeature = styled.div`
    font-weight: 400;
    color: #cd4213;
    font-size: 12px;
    line-height: 18px;
`;
export const BuyProduct = styled.div`
    font-weight: 400;
    color: #718596;
    font-size: 14px;
    margin-top: 10px;
    line-height: 21px;
`;

export const BuyButtons = styled.div`
    display: flex;
    width: 90%;
`;

export const FixedBottom = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    position: fixed;
    bottom: 0;
    background: white;
    box-shadow: 0 1px 10px 0 #dddddd, 0 4px 5px 0 #23000000, 0 2px 4px -1px #33000000;
    width: 100%;
    height: 80px;
    justify-content: center;
    max-width: inherit;
    z-index: 100;
`;

export const RecommendItem = styled.div`
    background-repeat: no-repeat;
    align-item: center;
    display: block;
    background-position: center;
    width: 100%;
    height: 40%;
    backgroundsize: auto;
    position: relative;
`;

export const BuyCircle = styled.div`
    background-repeat: no-repeat;
    align-item: center;
    display: block;
    background-position: center;
    width: 100%;
    height: 100%;
    backgroundsize: auto;
    position: relative;
`;

export const MiloItem = styled.div`
    background-repeat: no-repeat;
    align-item: center;
    display: block;
    background-position: center;
    width: 100%;
    height: 100%;
    backgroundsize: auto;
    position: relative;
`;

export const PriceContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PriceLabelText = styled.p`
    color: ${colors.subGrey};
    font-size: 12px;
    margin: 0;
`;

export const PriceAndDiscount = styled.div`
    font-size: 16px;
    margin: 5px 0;

    display: flex;
`;

export const DiscountedPrice = styled.p`
    margin: 0;
    color: ${colors.black};
    font-weight: bold;
`;

export const DiscountStrikethrough = styled(DiscountStrikethroughIcon)`
    margin: 0;
    position: absolute;
    top: 5px;
    left: 0;
`;

export const OriginalPrice = styled.p`
    margin: 0;
    margin-left: 5px;
    color: ${colors.subGrey};
    font-weight: normal;
    font-size: 14px;

    position: relative;
`;

export const Card = styled.div`
    margin: ${({ margin }) => margin || '0'};
    padding: ${({ padding }) => padding || '0'};
    background: ${({ background }) => background || '#fff'};
    border-radius: ${({ borderRadius }) => borderRadius || '8px'};
    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
    align-items: ${({ alignItems }) => alignItems || 'center'};
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
    min-height: ${({ minHeight }) => minHeight || '74px'};
    position: relative;
    width: ${({ width }) => width || '100%'};
`;

export const PageIndicatorContainer = styled.div`
    background: ${colors.background.primaryPad};
    height: 4px;
    /* We need this negative margin because the container for this page has a width of 93% for whatever reason */
    margin: 0 -4%;
`;

export const PageIndicator = styled.div`
    background: ${colors.deepBlue};
    width: ${({ width }) => width || '100%'};
    height: 100%;
    transition: width 0.3s ease-in-out;
`;

export const FlexContainer = styled.div`
    display: flex;
    width: ${({ width }) => width || '100%'};
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
    align-items: ${({ alignItems }) => alignItems || 'flex-start'};
    flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
    font-size: ${({ fontSize }) => fontSize || 'inherit'};
    margin: ${({ margin }) => margin || '5px 0'};
`;

export const SuccessIconContainer = styled.div`
    margin: 0 0 23px;
    width: 140px;
    height: 140px;
    background: ${colors.bgBlue};
    border-radius: 100%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export const ShoppingBagImage = styled.img`
    display: inline-block;
    width: 79%;
    height: 79%;
`;

export const GreenCheckImage = styled.img`
    position: absolute;
    top: 4px;
    right: 18px;
`;
