import React from 'react';

import { array, number, string, func, any } from 'prop-types';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { colors } from '../../../../styles';
import { ReactComponent as SubstractIcon } from '../../merchbuy/assets/Minus.svg';
import { ReactComponent as AddIcon } from '../../merchbuy/assets/Plus.svg';
import { ReactComponent as SubstractBlueIcon } from '../../merchbuy/shopping-cart/assets/MinusBlue.svg';
import { ReactComponent as AddBlueIcon } from '../../merchbuy/shopping-cart/assets/PlusBlue.svg';
import { QuantityCount } from '../../merchbuy/styles';

const QuantityControls = styled.div`
    display: flex;
`;

const Add = styled(AddIcon)`
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-left: 8px !important;
`;
const AddBlue = styled(AddBlueIcon)`
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-left: 8px !important;
`;
const Substract = styled(SubstractIcon)`
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-right: 8px !important;
`;

const SubstractBlue = styled(SubstractBlueIcon)`
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-right: 8px !important;
`;

const Input = styled.input`
    font-size: 12px;
    font-weight: ${({ fontWeight }) => fontWeight || '500'};
    max-width: 27px;
    border: none;
    text-align: center;
    background-color: ${colors.themeColor3};
    height: 24px;
    border-radius: 2px;
`;

const changeQuantity = (availableQuantity, updateCart, productId, carts, action, e) => {
    const updatedcartsItems = carts?.map((product) => {
        if (product?.id === productId) {
            if (action === 'add') {
                const newQty = parseInt(product?.quantity) + 1;
                if (newQty > availableQuantity) {
                    return toast.info("You can't add more than the quantity in stock to your cart");
                }
                product.quantity = newQty;
            } else if (action === 'input') {
                const newQty = Number(e.target.value.replace(/\D/g, ''));
                if (newQty > availableQuantity) {
                    return toast.info("You can't add more than the quantity in stock to your cart");
                }
                product.quantity = newQty;
            } else {
                product.quantity = parseInt(product?.quantity) - (product?.quantity >= 1 ? 1 : 0);
            }
        }
        return product;
    });
    carts = updatedcartsItems;
    updateCart(carts);
};

export const SetAvailableQuantity = ({ updateCart, productQuantity, productId, carts, availableQuantity }) => {
    const handleEdit = (e, action) => {
        if (e.target.value && e.target.value < 1) {
            return toast.info("You can't have less than 1 quantity in cart");
        } else {
            changeQuantity(availableQuantity, updateCart, productId, carts, action, e);
        }
    };
    return (
        <QuantityControls>
            {productQuantity > 0 ? (
                <SubstractBlue onClick={(e) => handleEdit(e, 'substract')} style={{ marginRight: '8px' }} />
            ) : (
                <Substract style={{ marginRight: '8px' }} />
            )}
            <QuantityCount radius="0" padding="0" qtyHeight="24px">
                <Input
                    onChange={(e) => (availableQuantity > productQuantity ? handleEdit(e, 'input') : null)}
                    value={productQuantity}
                    type="text"
                    inputMode={'numeric'}
                />
            </QuantityCount>
            {availableQuantity > productQuantity ? (
                <AddBlue onClick={(e) => handleEdit(e, 'add')} style={{ marginLeft: '8px' }} />
            ) : (
                <Add style={{ marginLeft: '8px' }} />
            )}
        </QuantityControls>
    );
};

SetAvailableQuantity.propTypes = {
    selectedProducts: array,
    productId: string,
    productQuantity: number,
    carts: any,
    updateCart: func,
    moq: any,
};
