import styled, { keyframes, css } from 'styled-components';

import CheckedIcon from '../../assets/checkmark.svg';
import { InputWithValidation } from '../../containers/InputContainer';
import { Message, Title, SecondaryText } from '../../containers/MessageContainer';
import { colors } from '../../styles';
import { LightRippleButton, RippleButton, RippleLabel } from '../button';

export const PopUp = styled.div`
    display: ${(props) => (props.open ? 'flex' : 'none')};
    flex-direction: ${({ flex }) => flex || 'row'};
    align-items: center;
    justify-content: center;
    text-align: center;
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: ${({ bottom }) => bottom || '0px'};
    width: 100%;
    max-width: ${({ maxWidth }) => maxWidth || 'inherit'};
    margin: auto;
    z-index: ${({ zIndex }) => zIndex || '1000'};
    padding: ${({ padding }) => padding || '8px'};
    overflow: hidden;
    transition: opacity 0.5s linear 0s;
    opacity: 1;
    animation: openPopup 0.3s ease-out;
    @keyframes openPopup {
        0% {
            transform: translateY(100%);
        }

        100% {
            transform: translateY(0%);
        }
    }

    @media (max-width: 575px) {
        max-width: inherit;
    }
`;

const slideUp = keyframes`
 0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0%);
        }
`;

const slideDown = keyframes`
 0% {
            transform: translateY(0%);
        }
        100% {
            transform: translateY(100%);
        }
`;

export const PopUpContent = styled.div`
    width: 100%;
    z-index: 2000;
    border-radius: ${({ radius }) => radius || '10px'};
    border-top-left-radius: ${({ top }) => top || null};
    border-top-right-radius: ${({ top }) => top || null};
    border-bottom-right-radius: ${({ bottom }) => bottom || null};
    border-bottom-left-radius: ${({ bottom }) => bottom || null};
    height: ${({ height }) => height || null};
    max-height: ${({ height }) => height || null};
    overflow: ${({ overflow }) => overflow || 'hidden'};
    background-color: ${({ color }) => color || colors.white};
    box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 4px, rgba(0, 0, 0, 0.28) 0px 4px 8px;
    transition: all 0.3s ease 0s;
    padding: ${({ padding }) => padding};
    overflow-y: ${({ overflowY }) => overflowY || 'hidden'};
    max-height: ${({ maxHeight }) => maxHeight};

    @media (min-width: 576px) {
        width: ${({ width }) => width || '350px'};
        max-width: inherit;
    }

    transition: opacity 0.3s linear 0s;
    animation: ${(props) =>
        !props.animate
            ? css`
                  ${slideUp} 0s ease-out
              `
            : css`
                  ${slideDown} 0.3s ease-out
              `};
`;

export const PopUpHeader = styled.h3`
    display: flex;
    justify-content: center;
    font-size: ${({ size }) => size || '12px'};
    font-weight: ${({ weight }) => weight || '500'};
    padding: ${({ padding }) => padding || '32px 8px'};
    margin: ${({ margin }) => margin || null};
    color: ${({ color }) => color || colors.popup.header};
    border-bottom: ${({ noBorderBottom }) => (noBorderBottom ? null : `1px solid ${colors.border.bottom}`)};
    text-align: ${({ align }) => align};
`;

const ActionButton = styled(RippleButton)`
    text-align: center;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    font-size: 14px;
    font-weight: lighter;
    margin: 0;
    &:active:after {
        content: '';
        display: none;
    }
`;

export const CancelButton = styled(LightRippleButton)`
    background-color: ${({ bgColor }) => bgColor || colors.popup.cancelButton};
    color: ${({ color }) => color || colors.red};
    font-weight: ${({ weight }) => weight || 'lighter'};
    box-shadow: ${(props) =>
        props.boxShadow ? 'rgba(0, 0, 0, 0.14) 0px 0px 4px, rgba(0, 0, 0, 0.28) 0px 4px 8px' : ''};
    border: ${({ border }) => border && '1px solid #227EFF'};
    border-radius: ${({ radius }) => radius};
`;

export const ConfirmButton = styled(LightRippleButton)`
    background-color: ${({ bgColor }) => bgColor || colors.popup.cancelButton};
    text-align: center;
    color: ${({ color }) => color || colors.green};
    font-size: 14px;
    font-weight: ${({ weight }) => weight || 'lighter'};
`;

export const OkayCancelButton = styled(ActionButton)`
    color: ${colors.deepBlue};
    background-color: ${colors.popup.cancelButton};
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    border-radius: 4px;
    border: 1px solid #227eff;
`;

export const OptionList = styled.ul`
    display: flex;
    flex-direction: column;
    padding: ${({ padding }) => padding || null};
    overflow-y: ${({ overflow }) => overflow || 'scroll'};
    max-height: 425px;
`;

export const Item = styled.li`
    font-size: 14px;
    padding: ${({ padding }) => padding || '10px 1em'};
    margin: ${({ margin }) => margin || null};
    text-align: left;
    color: ${colors.themeTextColor3};
    position: relative;
    -webkit-tap-highlight-color: transparent;
    &:not(:first-of-type) {
        border-top: 1px solid ${colors.gray4};
    }

    & > button,
    & > a {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: unset;
        background: none;
        border: none;
        font-weight: unset;
        height: 2em;
        margin: 0;
        color: unset;
        text-align: left;
        outline: none;
        position: relative;
        & > svg {
            padding-right: 3px;
            padding-bottom: 3px;
            margin-right: 8px;
            width: 32px;
        }
    }
`;

export const LabelItem = styled(RippleLabel)`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    border-top: 1px solid #edf2f7;
    padding: ${({ padding }) => padding || '1em'};
    text-align: left;
    color: ${colors.themeTextColor3};
    position: relative;
    cursor: pointer;
    & > svg {
        margin-right: 16px;
        width: 32px;
    }
    & > button,
    & > a {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: unset;
        background: none;
        border: none;
        font-weight: unset;
        height: unset;
        margin: 0;
        color: unset;
        text-align: left;
        position: relative;
        outline: none;
    }
`;

export const LabelText = styled.span`
    font-size: 14px;
    color: #0b0c0e;
`;

export const Radio = styled.input`
    position: absolute;
    top: 16px;
    right: 16px;
    margin: 0;
    cursor: pointer;
    appearance: none;
    opacity: 0;
    &:focus {
        outline: none;
    }
    &:checked {
        opacity: 1;
        &:after {
            content: url(${CheckedIcon});
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }
`;

export const InfoMessage = styled(Message)`
    padding: ${({ padding }) => padding || '0 8px'};
    margin-bottom: ${({ bottom }) => bottom && '48px'};
    text-align: ${({ align }) => align};
`;

export const InfoHeader = styled(Title)``;

export const InputPopup = styled(InputWithValidation)`
    padding: 16px;
    margin: 16px;
    border-radius: ${({ radius }) => radius || '13px'};
    width: calc(100% - 32px);
    height: inherit;
    margin-bottom: ${({ nobottom }) => (nobottom ? '0' : '36px')};
`;

export const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AdditionalText = styled(SecondaryText)`
    margin: 0;
`;

export const ShareLinkWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ShareLinkBlock = styled.div`
    display: flex;
    width: 64px;
    height: 64px;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background: ${({ background }) => background || '#edf2f7'};
    border-radius: 16px;
    margin-bottom: ${({ bottom }) => bottom || '4px'};
    cursor: pointer;
`;
