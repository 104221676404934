import React from 'react';

import styled from 'styled-components';

import { Product } from '../productItem';

import ScrollContainer from './scrollContainer';

const CarouselContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
    ::-webkit-scrollbar {
        display: none;
    }
`;

export const ProductCarousel = ({ count, data, size, left, searchTerm }) => {
    const target = React.createRef();

    return (
        <div>
            <CarouselContainer ref={target}>
                {data?.length > 0 &&
                    data.map((item, index) => (
                        <Product
                            key={index}
                            gridWidth="135px"
                            data={item}
                            size={size}
                            left={left}
                            searchTerm={searchTerm}
                        />
                    ))}
            </CarouselContainer>
            <ScrollContainer count={count} target={target} />
        </div>
    );
};
