import React, { useState, useEffect, Fragment } from 'react';

import { Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { RippleButton, SelectBox, TopHeader } from '../../../../components';
import { NewAmountInputWithLabel } from '../../../../components/forms/input/new-amount-input';
import loaderSvg from '../../../../components/loader/assets/loader_animation.svg';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { getLgaByStateId, updateStore } from '../../../../redux/ducks/applications/store-front/actions/store';
import { colors } from '../../../../styles';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { ReactComponent as AddIcon } from '../assets/add.svg';
import { ReactComponent as BagIcon } from '../assets/bag.svg';
import { ReactComponent as CardIcon } from '../assets/card.svg';
import { ReactComponent as LineIcon } from '../assets/line.svg';
import { ReactComponent as LocationIcon } from '../assets/location-pin.svg';
import { ReactComponent as RingIcon } from '../assets/ring.svg';
import { ValidationSchema } from '../setup-store/formValidation';
import PaymentModeModal from '../setup-store/paymentModeModal';
import SetupStoreFrontFailure from '../setup-store/storeFrontFailure';
import SetupStoreFrontSuccess from '../setup-store/storeFrontSuccess';

import { ReactComponent as AddSvg } from './assets/add.svg';
import { ReactComponent as BackArrow } from './assets/back.svg';

const LineIconStyled = styled(LineIcon)`
    margin: 12px 0 12px 5px;
`;

const Text = styled.div`
    font-size: ${({ size }) => size || '12px'};
    font-weight: ${({ weight }) => weight || '400'};
    color: ${({ color }) => color || '#02075C'};
    margin-bottom: ${({ bottom }) => bottom || null};
    margin-top: ${({ top }) => top || null};
    margin-left: ${({ left }) => left || null};
    margin-right: ${({ right }) => right || null};
    display: ${({ display }) => display || null};
    max-width: ${({ maxWidth }) => maxWidth || null};
    overflow-wrap: ${({ wrap }) => wrap || null};
    text-transform: ${({ transform }) => transform || null};
    text-align: ${({ align }) => align || null};
    width: ${({ width }) => width || null};
    justify-content: ${({ justifyContent }) => justifyContent || null};
    align-items: ${({ alignItems }) => alignItems || null};
    position: ${({ position }) => position || null};
    right: ${({ relRight }) => relRight || null};
`;

const AddDeliveryDiv = styled.div`
    background: ${colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 67px;
    border-radius: 8px;
`;

const Padding = styled.div`
    padding: 12px;
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

const DeliveryItem = styled.div`
    height: 156px;
    background: ${colors.white};
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const LocationText = styled.div`
    font-size: 14px;
    line-height: 21px;
    color: ${colors.themeTextColor11};
    margin-left: 8px;
`;

const BottomContainer = styled.div`
    height: 43px;
    background: #edf2f7;
    border: 1px solid #edf2f7;
    border-radius: 0px 0px 5px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 16px;
`;

const Amount = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.boldDark};
`;

const ActionContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Edit = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #3c48fc;
    margin-right: 24px;
`;

const Delete = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #e01a00;
`;

const HalfLoader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    margin-top: 70%;
`;

const FooterWrapper = styled.div`
    height: 72px;
    position: fixed;
    background: #f7f8f9;
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 33px;
    width: 100%;
    max-width: 375px;
    margin: auto;
    @media (max-width: 576px) {
        max-width: 100%;
    }
`;

const SpaceFooter = styled.div`
    height: 150px;
`;

const FormTitle = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.boldDark};
    margin-top: 36px;
`;

const FormDesc = styled.div`
    line-height: 21px;
    font-size: 14px;
    color: ${colors.lightDark};
    margin: 8px 0 16px 0;
`;

const OptionContainer = styled.div`
    height: 56px;
    border: 1px solid ${colors.gray4};
    border-radius: 4px;
    background: ${colors.white};
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-top: ${({ index }) => index === 1 && '16px'};
`;

const OptionInnerContainer = styled.div`
    display: flex;
    align-items: center;
`;

const OptionInnerContainerText = styled.div`
    font-size: 14px;
    line-height: 21px;
    color: ${colors.boldDark};
    margin-left: 14px;
`;

const Container = styled.div`
    background: ${({ hasData }) => hasData && colors.white};
    padding: ${({ hasData }) => hasData && '20px 16px 10px 16px'};
`;

const AddText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.deepBlue};
    margin-left: 11.33px;
`;

const Space = styled.div`
    height: 10px;
`;

const AddContainer = styled.div`
    height: 67px;
    background: ${colors.white};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Back = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: absolute;
    left: 16px;
    top: 16px;
`;

const StoreFfrontDeliveryOptions = () => {
    const dispatch = useDispatch();
    const store = useSelector((state) => state.applications.storeFront.store);
    const country = useSelector((state) => state.applications.storeFront.country);
    const states = useSelector((state) => state.applications.storeFront.states);
    const lga = useSelector((state) => state.applications.storeFront.lga);
    const loading = useSelector((state) => state.applications.storeFront.loadingProducts);
    const [openPayment, setOpenPayment] = useState(false);
    const mapFees = store.deliveryFees.map((data) => {
        const findStateId = states?.filter((item) => data.state === item.name);
        const findStartId = lga?.filter((item) => data.startPoint === item.name);
        const findEndId = lga?.filter((item) => data.endPoint === item.name);
        return {
            countryId: country[0]?.id,
            startPointId: [data?.startPoint, findStartId[0]?.id],
            endPointId: [data?.endPoint, findEndId[0]?.id],
            stateId: [data?.state, findStateId[0]?.id],
            fee: data?.fee,
            id: Math.random(),
        };
    });
    const [selectedDelivery, setSelectedDelivery] = useState({});
    const [data, setData] = useState({
        name: store?.name,
        description: store?.description,
        link: store?.link,
        businessEmail: store?.businessEmail,
        whatsappNumber: store?.whatsappNumber,
        twitterUrl: store?.twitterUrl,
        facebookUrl: store?.facebookUrl,
        deliveryFees: [...mapFees],
        paymentMode: [],
        price: selectedDelivery?.fee || '',
    });
    const [state, setState] = useState({ name: '' });
    const [buttonText, setButtonText] = useState('Save Changes');
    const [startPoint, setStartPoint] = useState({ name: '' });
    const [endPoint, setEndPoint] = useState({ name: '' });
    const [chooseAddress, setChooseAddress] = useState(false);
    const [showSuccessPage, setShowSuccessPage] = useState(false);
    const [showFailurePage, setShowFailurePage] = useState(false);
    const findStateId = states.filter((data) => data.name === state.name);
    const findStartId = lga.filter((data) => data.name === startPoint.name);
    const findEndId = lga.filter((data) => data.name === endPoint.name);

    const handleEditDeliveryFees = (updatedDelivery) => {
        const delivery = [...data.deliveryFees];
        const selectedDeliveryIndex = delivery.findIndex((data) => data.id === selectedDelivery.id);
        delivery[selectedDeliveryIndex] = updatedDelivery;
        setData({ ...data, deliveryFees: delivery });
    };

    const handleDeleteDeliveryFees = (id) => {
        const delivery = [...data.deliveryFees];
        const newDeliveries = delivery.filter((data) => data.id !== id);
        setData({ ...data, deliveryFees: newDeliveries });
    };

    const handlePaymentModes = (paymentMode) => {
        setData({ ...data, paymentMode });
    };

    const handleAddLocation = (values) => {
        if (selectedDelivery?.id) {
            handleEditDeliveryFees({
                countryId: country[0].id,
                startPointId: [startPoint.name, findStartId[0]?.id],
                endPointId: [endPoint.name, findEndId[0]?.id],
                stateId: [state.name, findStateId[0]?.id],
                fee: values.price,
                id: Math.random(),
            });
        } else {
            const newInfo = {
                ...data,
                deliveryFees: [
                    ...data.deliveryFees,
                    {
                        countryId: country[0].id,
                        startPointId: [startPoint.name, findStartId[0].id],
                        endPointId: [endPoint.name, findEndId[0].id],
                        stateId: [state.name, findStateId[0]?.id],
                        fee: values.price,
                        id: Math.random(),
                    },
                ],
            };
            setData(newInfo);
        }
        setChooseAddress(!chooseAddress);
        setState({ name: '' });
        setStartPoint({ name: '' });
        setEndPoint({ name: '' });
        setSelectedDelivery({});
    };

    const handleSubmit = (data, storeId) => {
        setButtonText('Saving...');
        dispatch(updateStore(data, storeId)).then((res) => {
            if (res) {
                setShowSuccessPage(!showSuccessPage);
            } else {
                setButtonText('Save Changes');
                setShowFailurePage(!showFailurePage);
            }
        });
    };

    useEffect(() => {
        setState({ name: selectedDelivery?.stateId?.length > 0 ? selectedDelivery?.stateId[0] : '' });
        setStartPoint({ name: selectedDelivery?.startPointId?.length > 0 ? selectedDelivery?.startPointId[0] : '' });
        setEndPoint({ name: selectedDelivery?.endPointId?.length > 0 ? selectedDelivery?.endPointId[0] : '' });
        setData({ ...data, price: selectedDelivery?.fee });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDelivery]);

    useEffect(() => {
        if (state.name) {
            const lgaContent = states.filter((data) => data.name === state.name);
            dispatch(getLgaByStateId(lgaContent[0]?.id));
        }
    }, [dispatch, state.name, states]);

    if (loading)
        return (
            <HalfLoader>
                <img src={loaderSvg} alt="Loading..."></img>
            </HalfLoader>
        );

    return (
        <div>
            {showSuccessPage && (
                <SetupStoreFrontSuccess
                    update
                    open={showSuccessPage}
                    setOpen={() => setShowSuccessPage(!showSuccessPage)}
                />
            )}
            {showFailurePage && (
                <SetupStoreFrontFailure
                    update
                    open={showFailurePage}
                    setOpen={() => setShowFailurePage(!showFailurePage)}
                />
            )}
            {openPayment && (
                <PaymentModeModal
                    handlePaymentModes={handlePaymentModes}
                    cancel={() => {
                        setOpenPayment(false);
                    }}
                    open={openPayment}
                    zIndex={'100000'}
                />
            )}
            {chooseAddress && (
                <div>
                    <DesktopBackgroundLayout>
                        <TopHeader title={'Edit Delivery Fee'} weight={'400'}>
                            <Back
                                onClick={() => {
                                    setChooseAddress(!chooseAddress);
                                    setSelectedDelivery({});
                                }}
                            >
                                <BackArrow />
                            </Back>
                        </TopHeader>
                        <ScreenContainer top={'70px'}>
                            <Formik
                                initialValues={data}
                                validationSchema={ValidationSchema}
                                onSubmit={(values) => {
                                    handleSubmit(
                                        {
                                            ...values,
                                            deliveryFees: data.deliveryFees.map((data) => {
                                                return {
                                                    startPointId: data.startPointId[1],
                                                    endPointId: data.endPointId[1],
                                                    stateId: data.stateId,
                                                    id: data.id,
                                                    fee: data.fee,
                                                    countryId: data.countryId,
                                                };
                                            }),
                                            paymentModes: data.paymentModes.map((data) => data[1]),
                                        },
                                        store.id,
                                    );
                                }}
                            >
                                {({ values, setFieldValue, handleChange }) => (
                                    <Form>
                                        <div>
                                            <SelectBox
                                                value={state.name}
                                                handleChange={setState}
                                                placeholder="State"
                                                options={states}
                                                bottom="16px"
                                                height="90%"
                                            />
                                            <SelectBox
                                                value={startPoint.name}
                                                handleChange={setStartPoint}
                                                placeholder="Start Point"
                                                options={lga}
                                                bottom="16px"
                                                height="90%"
                                            />
                                            <SelectBox
                                                value={endPoint.name}
                                                handleChange={setEndPoint}
                                                placeholder="End Point"
                                                options={lga}
                                                bottom="16px"
                                                height="90%"
                                            />
                                            <NewAmountInputWithLabel
                                                value={values.price}
                                                label={'Price'}
                                                placeholder={'Price'}
                                                name="price"
                                            />
                                            <SpaceFooter />
                                            <FooterWrapper>
                                                <RippleButton
                                                    type={'button'}
                                                    top={'0'}
                                                    height="55px"
                                                    disabled={!state.name}
                                                    onClick={() => {
                                                        handleAddLocation(values);
                                                    }}
                                                >
                                                    Add Fee
                                                </RippleButton>
                                            </FooterWrapper>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </ScreenContainer>
                    </DesktopBackgroundLayout>
                </div>
            )}
            {!chooseAddress && !showFailurePage && !showSuccessPage && (
                <Fragment>
                    <DesktopBackgroundLayout>
                        <TopHeader
                            title={'Delivery Options'}
                            backLink={'/actions/storefront_settings'}
                            weight={'400'}
                        />
                        <ScreenContainer
                            top={'70px'}
                            color={colors.lightestGray}
                            minHeight={'100%'}
                            paddingBottom={'32px'}
                        >
                            <Text size={'16px'} weight={'700'} color={colors.themeTextColor10} bottom={'8px'}>
                                Delivery Fees
                            </Text>
                            <Text size={'14px'} color={'#5C6F7F'} bottom={'16px'}>
                                Set up your delivery fees for locations you deliever
                            </Text>
                            {data.deliveryFees.length > 0 && (
                                <div>
                                    {data?.deliveryFees?.map((data, index) => (
                                        <DeliveryItem key={index}>
                                            <Padding>
                                                <Flex>
                                                    <RingIcon />
                                                    <LocationText>{data?.startPointId[0]}</LocationText>
                                                </Flex>
                                                <LineIconStyled />
                                                <Flex>
                                                    <LocationIcon />
                                                    <LocationText>{data?.endPointId[0]}</LocationText>
                                                </Flex>
                                            </Padding>
                                            <BottomContainer>
                                                <Amount>{formatPrice(data?.fee)}</Amount>
                                                <ActionContainer>
                                                    <Edit
                                                        onClick={() => {
                                                            setSelectedDelivery(data);
                                                            setChooseAddress(!chooseAddress);
                                                        }}
                                                    >
                                                        Edit
                                                    </Edit>
                                                    <Delete onClick={() => handleDeleteDeliveryFees(data?.id)}>
                                                        Delete
                                                    </Delete>
                                                </ActionContainer>
                                            </BottomContainer>
                                        </DeliveryItem>
                                    ))}
                                </div>
                            )}
                            <AddDeliveryDiv onClick={() => setChooseAddress(!chooseAddress)}>
                                <AddSvg style={{ marginRight: '11.36px' }} />
                                <Text size={'14px'} weight={'500'} color={colors.themeTextColor12}>
                                    Add delivery fee
                                </Text>
                            </AddDeliveryDiv>
                            <FormTitle>Payment Mode</FormTitle>
                            <FormDesc>Select the payment mode you want your customers to pay with</FormDesc>
                            <Container hasData={data.paymentMode.length > 0}>
                                {data.paymentMode?.map((data, index) => (
                                    <OptionContainer index={index} key={index}>
                                        <OptionInnerContainer>
                                            {index === 0 ? <CardIcon /> : <BagIcon />}
                                            <OptionInnerContainerText>{data[0]}</OptionInnerContainerText>
                                        </OptionInnerContainer>
                                    </OptionContainer>
                                ))}
                                <Space />
                                <AddContainer onClick={() => setOpenPayment(true)}>
                                    <AddIcon />
                                    <AddText>Add payment mode</AddText>
                                </AddContainer>
                            </Container>
                            <RippleButton
                                type="button"
                                disabled={data.deliveryFees.length === 0 || data.paymentMode.length === 0}
                                height={'56px'}
                                onClick={() => {
                                    handleSubmit(
                                        {
                                            ...data,
                                            deliveryFees: data.deliveryFees?.map((data) => {
                                                const obj = {
                                                    startPointId: data.startPointId[1],
                                                    endPointId: data.endPointId[1],
                                                    stateId: data.stateId[1],
                                                    fee: data.fee,
                                                    countryId: data.countryId,
                                                };
                                                return obj;
                                            }),
                                            paymentMode: data.paymentMode.map((data) => data[1]),
                                        },
                                        store.id,
                                    );
                                }}
                            >
                                {buttonText}
                            </RippleButton>
                        </ScreenContainer>
                    </DesktopBackgroundLayout>
                </Fragment>
            )}
        </div>
    );
};

export default StoreFfrontDeliveryOptions;
