import React, { useMemo } from 'react';

import { useField } from 'formik';
import { string } from 'prop-types';

import {
    FormMetaData,
    InputLabel,
    InputLabelBlock,
    InputLabelContainer,
    TextInputField,
} from '../../../../containers/NewInputContainer';

export const NewTextInputWithLabel = ({
    label,
    description,
    width,
    left,
    minHeight,
    height,
    top,
    inputheight,
    component,
    bottom,
    color,
    countryselection,
    ...props
}) => {
    const [field, meta] = useField(props.name);
    const { error, touched } = meta;
    const formHasError = useMemo(() => touched && error && error.length > 0, [error, touched]);

    return (
        <InputLabelContainer top={top} minHeight={minHeight}>
            <InputLabelBlock
                width={width}
                left={left}
                bottom={bottom}
                id={props.id}
                valid={touched ? String(touched && !formHasError) : ''}
                error={error}
                height={height}
            >
                <TextInputField
                    touched={touched ? 'true' : 'false'}
                    showerror={String(formHasError)}
                    color={color}
                    component={component}
                    inputheight={inputheight}
                    valid={touched ? String(touched && !formHasError) : 'true'}
                    {...field}
                    {...props}
                />
                <InputLabel
                    valid={touched ? String(touched && !formHasError) : 'true'}
                    countryselection={countryselection && countryselection.toString()}
                >
                    {label}
                </InputLabel>
            </InputLabelBlock>

            {formHasError && <FormMetaData showerror={String(formHasError)}>{error}</FormMetaData>}

            {!formHasError && description && <FormMetaData>{description}</FormMetaData>}
        </InputLabelContainer>
    );
};

NewTextInputWithLabel.propTypes = {
    label: string.isRequired,
    countrySelection: string,
    description: string,
};
