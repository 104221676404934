import React from 'react';

import { string, any } from 'prop-types';
import styled from 'styled-components';

export const Logo = styled.div`
    width: ${({ width }) => width || '72px'};
    height: ${({ height }) => height || '72px'};
    background: ${({ background }) => background || null};
    border-radius: ${({ borderRadius }) => borderRadius || '50%'};
    margin: ${({ margin }) => margin || null};
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || null};
    background: ${({ background }) => background || null};
    overflow: hidden;
    background: ${({ background }) => background};
    @media (min-width: 576px) {
        margin-top: ${({ mtop }) => mtop || null};
    }
`;

export const Logos = styled.div`
    margin: ${({ margin }) => margin || null};
    display: ${({ display }) => display || null};
    text-align: center;
`;

export const LogoImage = styled.div`
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ImageWithFallback = styled.img`
    width: ${({ iconWidth }) => iconWidth || '65px'};
    height: ${({ iconHeight }) => iconHeight || '65px'};
    background-image: ${({ fallback }) => `url(${fallback})` || null};
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    object-fit: ${({ objectFit }) => objectFit || 'initial'};
`;

export const PageLogo = ({
    Icon,
    display,
    iconWidth,
    iconHeight,
    borderRadius,
    background,
    width,
    height,
    top,
    margin,
    id,
    fallback,
    objectFit,
    onError,
    className,
}) => {
    return (
        <Logo
            width={width}
            borderRadius={borderRadius}
            iconWidth={iconWidth}
            iconHeight={iconHeight}
            height={height}
            top={top}
            display={display}
            margin={margin}
            background={background}
            className={className}
        >
            <LogoImage
                onError={(e) => {
                    e.target.src = fallback || ' ';
                }}
            >
                <ImageWithFallback
                    fallback={fallback || null}
                    id={id || null}
                    src={Icon}
                    iconWidth={iconWidth}
                    iconHeight={iconHeight}
                    alt={''}
                    objectFit={objectFit}
                />
            </LogoImage>
        </Logo>
    );
};

PageLogo.propTypes = {
    Icon: string.isRequired,
    iconWidth: string,
    iconHeight: string,
    id: string,
    background: string,
    width: string,
    height: string,
    top: string,
    fallback: string,
    onError: any,
};
