import React from 'react';

import styled from 'styled-components';

import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Main } from './components/Main';

const Launch = styled.div`
    position: relative;
    background-color: #ffffff;
    overflow: hidden;
`;

export const LaunchScreen = () => {
    return (
        <Launch>
            <Header />
            <Main />
            <Footer />
        </Launch>
    );
};
