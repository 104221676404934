import React, { Fragment } from 'react';

import { SuccessPage } from '../../../../../components';
import { SlidingOverlay } from '../../../../../containers/OverlayContainer';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
const IdentityVerificationInitiated = ({ open, setOpen, cancel }) => {
    function handleClick() {
        setOpen();
        cancel();
    }

    return (
        open && (
            <Fragment>
                <SlidingOverlay>
                    <DesktopBackgroundLayout>
                        <SuccessPage
                            title={'Verification Initiated'}
                            subtitle={
                                'Your identity verification is currently in progress, and should take 1 - 2 working days.'
                            }
                            doneText={'Back to Settings'}
                            onDoneClick={handleClick}
                            mposition={'fixed'}
                            position={'absolute'}
                        />
                    </DesktopBackgroundLayout>
                </SlidingOverlay>
            </Fragment>
        )
    );
};

export default IdentityVerificationInitiated;
