import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import { axios, currentAPI } from '../../../../../../config';
import {
    LOADING_STORE,
    POST_STORE_FRONT,
    FETCH_STORE_PRODUCTS,
    LOADING_STORE_PRODUCTS,
    FETCH_STORE_PRODUCT,
    STOREFRONT_PRODUCT_LIST,
    STOREFRONT_PRODUCT,
    FETCH_STORE,
    FETCH_ORDERS,
    FETCH_ORDERS_SUMMARY,
    FETCH_MERCHANT_ORDER,
    FETCH_COUNTRY_BY_NAME,
    FETCH_STATES_BY_ID,
    FETCH_LGA_BY_STATE,
    FETCH_STORE_PUBLIC,
    FETCH_STORE_LINK,
    FETCH_PRODUCT_PUBLIC,
    POST_ORDER,
    SAVE_LAST_ORDER_PLACED,
    FETCH_CUSTOMER_ORDERS,
} from '../../constants';

export const postStores = (data) => async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_STORE, payload: true });

    try {
        const response = await axios.post(`${currentAPI}/api/store`, data);
        if (response.status === 200) {
            dispatch({
                type: POST_STORE_FRONT,
                payload: response.data.data || [],
            });
            dispatch(getStoreByUserId());
            dispatch({ type: LOADING_STORE, payload: false });
            return true;
        }
    } catch (error) {
        dispatch({ type: LOADING_STORE, payload: false });
        return false;
    }
};

export const changeStoreStatus =
    (storeId, status, showToast = true) =>
    async (dispatch) => {
        dispatch({ type: LOADING_STORE_PRODUCTS, payload: true });

        try {
            const response = await axios.patch(`${currentAPI}/api/store/${storeId}/status?status=${status}`);

            if (response.status === 200) {
                dispatch(getStoreByUserId());
                dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
            }
        } catch (error) {
            dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
            showToast && toast.success("We couldn't change the status of your store . Kindly try again");

            //
        }
    };

export const createOrder = (data, storeId, paymentMode) => async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_STORE, payload: true });

    try {
        const response = await axios.post(`${currentAPI}/api/store/public/${storeId}/orders`, data);
        if (response.status === 200) {
            const { data, status } = response.data || {};
            const {
                id,
                storeOrderId,
                orderReference,
                email,
                costOfGoods,
                totalCost,
                deliveryFee,
                fullName,
                phoneNumber,
                state,
                lga,
                address,
            } = data || {};
            const order = {
                id,
                storeOrderId,
                orderReference,
                email,
                costOfGoods,
                totalCost,
                deliveryFee,
                fullName,
                phoneNumber,
                state,
                lga,
                address,
            };
            dispatch({
                type: SAVE_LAST_ORDER_PLACED,
                payload: order,
            });
            dispatch({
                type: POST_ORDER,
                payload: response.data.data || [],
            });
            dispatch({ type: LOADING_STORE, payload: false });
            return {
                status,
                message: '',
                paymentMode,
                order,
            };
        }
    } catch (error) {
        const { message } = (error.response && error.response.data) || {};
        toast.error(message);
        return {
            status: false,
            message,
            paymentMode,
            order: null,
        };
    } finally {
        dispatch({ type: LOADING_STORE, payload: false });
    }
};

export const getStoreByUserId = () => async (dispatch) => {
    if (!navigator.onLine) return;

    try {
        const getStoreByUserResponse = await axios.get(`${currentAPI}/api/store/user`);

        if (getStoreByUserResponse.status === 200) {
            dispatch({
                type: FETCH_STORE,
                payload: getStoreByUserResponse.data.data || {},
            });
        }
    } catch (error) {
        console.error(error);
    }
};

export const getStoreByLink = (link) => async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_STORE, payload: true });

    try {
        const getStoreByUserResponse = await axios.get(`${currentAPI}/api/store/public?link=${link}`);

        if (getStoreByUserResponse.status === 200) {
            dispatch({
                type: FETCH_STORE_LINK,
                payload: getStoreByUserResponse.data.data || {},
            });
            dispatch({ type: LOADING_STORE, payload: false });
        }
    } catch (error) {
        console.error(error);
        dispatch({ type: LOADING_STORE, payload: false });
    }
};

export const getOrdersByPhoneNumber = (phoneNumber) => async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_STORE, payload: true });

    try {
        const response = await axios.get(`${currentAPI}/api/store/public/orders/${phoneNumber}`);

        if (response.status === 200) {
            dispatch({
                type: FETCH_CUSTOMER_ORDERS,
                payload: response.data.data || [],
            });
            dispatch({ type: LOADING_STORE, payload: false });
        }
    } catch (error) {
        console.error(error);
        dispatch({ type: LOADING_STORE, payload: false });
    }
};

export const unsubscribe = () => async (dispatch) => {
    dispatch({
        type: FETCH_CUSTOMER_ORDERS,
        payload: [],
    });
};

export const updateStore = (data, store_id) => async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_STORE, payload: true });

    try {
        const response = await axios.put(`${currentAPI}/api/store/${store_id}/settings`, data);
        if (response.status === 200) {
            dispatch({
                type: POST_STORE_FRONT,
                payload: response.data.data || [],
            });
            dispatch(getStoreByUserId());
            dispatch({ type: LOADING_STORE, payload: false });
            return true;
        }
    } catch (error) {
        dispatch({ type: LOADING_STORE, payload: false });
        return false;
    }
};

export const getProductsByPublicStoreId = (storeId) => async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_STORE_PRODUCTS, payload: true });

    try {
        const response = await axios.get(`${currentAPI}/api/store/public/${storeId}/product`);
        if (response.status === 200) {
            dispatch({
                type: FETCH_STORE_PUBLIC,
                payload: response.data.data || [],
            });
            dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
        }
    } catch (error) {
        dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
    }
};

export const getProductByPublicProductId = (productId) => async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_STORE_PRODUCTS, payload: true });

    try {
        const response = await axios.get(`${currentAPI}/api/store/public/product/${productId}`);
        if (response.status === 200) {
            dispatch({
                type: FETCH_PRODUCT_PUBLIC,
                payload: response.data.data || [],
            });
            dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
        }
    } catch (error) {
        dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
    }
};

export const getProductsByStoreLink = (link) => async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_STORE_PRODUCTS, payload: true });

    try {
        const response = await axios.get(`${currentAPI}/api/store/products/${link}`);
        if (response.status === 200) {
            dispatch({
                type: FETCH_STORE_PRODUCTS,
                payload: response.data.data || [],
            });
            dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
        }
    } catch (error) {
        dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
    }
};

export const getProductById = (product_id) => async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_STORE_PRODUCTS, payload: true });

    try {
        const response = await axios.get(`${currentAPI}/api/store/products/${product_id}`);
        if (response.status === 200) {
            dispatch({
                type: FETCH_STORE_PRODUCT,
                payload: response.data.data || [],
            });
            dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
        }
    } catch (error) {
        dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
    }
};

export const addStorefrontProduct = (payload, storeId) => async (dispatch) => {
    try {
        const response = await axios.post(`${currentAPI}/api/store/${storeId}/product `, JSON.stringify(payload));
        if (response.status === 200) {
            dispatch(getProductsByStoreId(storeId));
            return true;
        }
    } catch (error) {
        return false;
    }
};

export const editStorefrontProduct = (params, storeId, productId) => async (dispatch) => {
    try {
        const response = await axios.put(
            `${currentAPI}/api/store/${storeId}/product/${productId} `,
            JSON.stringify(params),
        );
        if (response.status === 200) {
            dispatch(getProductByProductId(productId));
            return true;
        }
    } catch (error) {
        return false;
        //
    }
};

export const getProductsByStoreId = (storeId) => async (dispatch) => {
    if (!navigator.onLine) return;

    try {
        const getProductsresponse = await axios.get(`${currentAPI}/api/store/${storeId}/product`);

        if (getProductsresponse.status === 200) {
            dispatch({
                type: STOREFRONT_PRODUCT_LIST,
                payload: getProductsresponse.data.data || [],
            });
        }
    } catch (error) {
        //
    }
};

export const getProductByProductId = (productId) => async (dispatch) => {
    if (!navigator.onLine) return;
    dispatch({ type: LOADING_STORE_PRODUCTS, payload: true });

    try {
        const getProductresponse = await axios.get(`${currentAPI}/api/store/product/${productId}`);

        if (getProductresponse.status === 200) {
            dispatch({
                type: STOREFRONT_PRODUCT,
                payload: getProductresponse.data.data || {},
            });
            dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
        }
    } catch (error) {
        console.error(error);
        dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
    }
};

export const deleteStorefrontProduct =
    (productId, redirect = '/my-shop/storefront', showToast = true) =>
    async (dispatch) => {
        dispatch({ type: LOADING_STORE_PRODUCTS, payload: true });

        try {
            const deleteProductResponse = await axios.patch(`${currentAPI}/api/store/product/${productId}`);

            if (deleteProductResponse.status === 200) {
                dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
                showToast && toast.success('You have successfully deleted a product');
                redirect && dispatch(push(redirect));
            }
        } catch (error) {
            dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
            showToast && toast.success("We couldn't delete the product . Kindly try again");

            //
        }
    };

export const changeOrderStatus =
    (storeId, orderId, status, showToast = true) =>
    async (dispatch) => {
        dispatch({ type: LOADING_STORE_PRODUCTS, payload: true });

        try {
            const response = await axios.patch(`${currentAPI}/api/store/${storeId}/orders/${orderId}?status=${status}`);

            if (response.status === 200) {
                dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
                dispatch(getOrderByOrderId(orderId));
                showToast && toast.success('Order status changed successfully');
            }
        } catch (error) {
            dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
            showToast && toast.success("We couldn't change the status of this order . Kindly try again");

            //
        }
    };

export const getOrdersByStoreId = (storeId) => async (dispatch) => {
    if (!navigator.onLine) return;

    try {
        const response = await axios.get(`${currentAPI}
/api/store/${storeId}/orders`);

        if (response.status === 200) {
            dispatch({
                type: FETCH_ORDERS,
                payload: response.data.data.sort(
                    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
                ),
            });
        }
    } catch (error) {
        console.error(error);
    }
};

export const getOrdersSummaryByStoreId = (storeId) => async (dispatch) => {
    if (!navigator.onLine) return;

    try {
        const response = await axios.get(`${currentAPI}
/api/store/${storeId}/summary`);

        if (response.status === 200) {
            dispatch({
                type: FETCH_ORDERS_SUMMARY,
                payload: response.data.data,
            });
        }
    } catch (error) {
        console.error(error);
    }
};

export const getOrderByOrderId = (orderId) => async (dispatch) => {
    if (!navigator.onLine) return;
    dispatch({ type: LOADING_STORE_PRODUCTS, payload: true });

    try {
        const response = await axios.get(`${currentAPI}
/api/store/orders/${orderId}`);

        if (response.status === 200) {
            dispatch({
                type: FETCH_MERCHANT_ORDER,
                payload: response.data.data,
            });
            dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
        }
    } catch (error) {
        console.error(error);
        dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
    }
};

export const getCountryByName = (name) => async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_STORE_PRODUCTS, payload: true });

    try {
        const response = await axios.get(`${currentAPI}/api/users/public/country?countryName=${name}`);
        if (response.status === 200) {
            dispatch({
                type: FETCH_COUNTRY_BY_NAME,
                payload: response.data.data.content || [],
            });
            dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
        }
    } catch (error) {
        dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
    }
};

export const getStateByCountryId = (countryId) => async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_STORE_PRODUCTS, payload: true });

    try {
        const response = await axios.get(`${currentAPI}/api/users/public/country/states/${countryId}`);
        if (response.status === 200) {
            dispatch({
                type: FETCH_STATES_BY_ID,
                payload: response.data.data.content || [],
            });
            dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
        }
    } catch (error) {
        dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
    }
};

export const getLgaByStateId = (stateId) => async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_STORE_PRODUCTS, payload: true });

    try {
        const response = await axios.get(`${currentAPI}/api/users/public/country/states/lga/${stateId}`);
        if (response.status === 200) {
            dispatch({
                type: FETCH_LGA_BY_STATE,
                payload: response.data.data.content || [],
            });
            dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
        }
    } catch (error) {
        dispatch({ type: LOADING_STORE_PRODUCTS, payload: false });
    }
};
