import React from 'react';

import { bool, string, shape, func, arrayOf } from 'prop-types';
import styled from 'styled-components';

import { Overlay } from '../../../containers/OverlayContainer';
import { colors } from '../../../styles';
import { LightRippleButton } from '../../button';
import { PopUp, PopUpContent, PopUpHeader } from '../common';

const ConfirmationText = styled.h4`
    font-size: 14px;
    width: 100%;
    color: ${colors.black};
    line-height: 20px;
    padding-top: 10px;
    text-align: left;
`;

const ConfirmOptions = styled.ul`
    display: flex;
    justify-content: space-around;
    background-color: ${colors.white};
    font-size: 14px;
    font-weight: lighter;
`;

const ConfirmOption = styled(LightRippleButton)`
    &:first-of-type {
        color: ${colors.black};
    }

    &:last-of-type {
        color: ${colors.white};
    }

    &:first-of-type {
        margin-right: 5px;
    }

    &:last-of-type {
        margin-left: 5px;
    }
    background: ${({ variant }) => (variant === 'OK' ? colors.deepBlue : colors.white)};
    font-weight: 500;
    border: ${({ variant }) => (variant === 'Cancel' ? `1px solid ${colors.faintGrey}` : null)};
`;

const Padding = styled.div`
    padding: 20px;
`;

const Bold = styled.span`
    font-weight: bold;
`;

export const ConfirmCartPopupDialog = ({
    open,
    title,
    answers,
    cancel,
    confirmationTextBegining,
    confirmationTextEnding,
    productName,
}) => {
    return (
        <>
            {open && <Overlay zIndex={'1000'} bgc={'rgba(0, 0, 0, 45%)'} onClick={cancel} />}
            <PopUp padding="0px" open={open} zIndex={'10000'}>
                <PopUpContent top="10px" radius="0px">
                    <Padding padding="20px">
                        <PopUpHeader noBorderBottom padding="12px 0" size="20px" align="left" weight="bold">
                            {title}
                        </PopUpHeader>
                        <ConfirmationText>
                            <span>{confirmationTextBegining}</span>
                            <Bold> {productName}</Bold>
                            <span> {confirmationTextEnding}</span>
                        </ConfirmationText>
                        <ConfirmOptions>
                            {answers.map(({ variant, action }, index) => (
                                <ConfirmOption variant={variant} key={index} onClick={action}>
                                    {variant}
                                </ConfirmOption>
                            ))}
                        </ConfirmOptions>
                    </Padding>
                </PopUpContent>
            </PopUp>
        </>
    );
};

ConfirmCartPopupDialog.propTypes = {
    open: bool,
    title: string,
    confirmationText: string,
    cancel: func,
    answers: arrayOf(
        shape({
            variant: string,
            action: func,
        }),
    ),
};
