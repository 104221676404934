import React, { Fragment, useEffect, useState } from 'react';

import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { TopHeader, PasswordGroup, RippleButton, SuccessPage, Loader } from '../../../../components';
import { useReasonForBlockingUserWallet } from '../../../../hooks/useReasonForBlockingUserWallet';
import { getPinTrialsLeft } from '../../../../redux/ducks/account/wallet/actions';
import { shsActions } from '../../../../redux/ducks/applications/shs/actions';
import { colors } from '../../../../styles';
import { getInputValues } from '../../../../utils/inputs/getInputValues';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { BlockedWalletPopUp } from '../wallet-transactions/blocked-wallet-popup';

import { CodeCheckFormValidationSchema } from './CodeCheckFormValidationSchema';
import { TransactionPinFormValidationSchema } from './transactionPinValidationShema';

const Title = styled.h4`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
    line-height: 24px;
    color: #071827;
`;

const Message = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    color: #718596;
    margin-bottom: 21px;
`;
const Label = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #718596;
    margin: 0;
    margin-top: ${({ top }) => top + 'px' || '0'};
`;

const ScreenContainer = styled.main`
    padding: 1em;
    padding-bottom: 65px;
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    height: 90%;
`;
const FormContainer = styled(Formik)`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const FormWrapper = styled(Form)`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const PinTrailsLeft = styled.div`
    color: ${colors.transactions.failed};
    font-weight: 400;
    font-size: 10px;
    padding-top: 4px;
`;

const UpdateTransactionPin = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const isLoading = useSelector((state) => state.applications.shs.isLoading);

    const [isCurrentPinValid, setIsCurrentPinValid] = useState(false);
    const [oldTransactionPin, setOldTransactionPin] = useState('');
    const [oldPin, setOldPin] = useState('');
    const [confirmNewTransactionPin, setConfirmNewTransactionPin] = useState('');
    const [newTransactionPin, setnewTransactionPin] = useState('');
    const [showSuccessPage, setShowSucessPage] = useState(false);
    const [pinTrialsLeft, setPinTrialsLeft] = useState({});
    const [wrongPin, setWrongPin] = useState('');
    const reasonForBlocking = useReasonForBlockingUserWallet();

    const getPinTrails = () => {
        dispatch(getPinTrialsLeft()).then((res) => {
            res && setPinTrialsLeft(res);
        });
    };

    useEffect(() => {
        getPinTrails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPinVerify = async (pin) => {
        setOldTransactionPin(pin);
        const res = await dispatch(shsActions.verifyPin(pin));
        getPinTrails();
        if (res) return setIsCurrentPinValid(true);
        setWrongPin(true);
    };

    const onPinUpdate = async (newPin) => {
        const res = await dispatch(shsActions.updateTransactionPin(oldTransactionPin, newPin));
        if (res) setShowSucessPage(true);
    };

    if (isLoading) return <Loader />;
    return (
        <DesktopBackgroundLayout>
            <Fragment>
                {showSuccessPage ? (
                    <SuccessPage
                        title="Update Succesful"
                        subtitle="Your transaction PIN has been succesfully updated."
                        doneText="Go to Home"
                        onDoneClick={() => history.push('/')}
                    />
                ) : (
                    <Fragment>
                        {(pinTrialsLeft?.pinTriesLeft === 0 || pinTrialsLeft?.status === 'BLOCKED') && (
                            <BlockedWalletPopUp showCancelIcon={false} reason={reasonForBlocking?.reason} />
                        )}
                        {!isCurrentPinValid ? (
                            <>
                                {<TopHeader title="Change PIN" />}
                                <ScreenContainer>
                                    <Title>Provide Current Transaction PIN</Title>
                                    <Message>Enter your current transaction PIN </Message>
                                    <FormContainer
                                        initialValues={{
                                            oldTransactionPin: '',
                                        }}
                                        validationSchema={CodeCheckFormValidationSchema}
                                        onSubmit={(values) => onPinVerify(values.oldTransactionPin)}
                                    >
                                        {({ values, errors, valid, touched, setFieldValue }) => (
                                            <FormWrapper>
                                                <Label>Enter Transaction PIN to continue</Label>
                                                <PasswordGroup
                                                    count={4}
                                                    startIndex={1}
                                                    type={'password'}
                                                    valid={valid}
                                                    errors={errors}
                                                    align={'left'}
                                                    name={'oldTransactionPin'}
                                                    touched={touched}
                                                    enteredValue={oldPin}
                                                    handleChange={(event) => {
                                                        setOldPin(event.target.value);
                                                        setFieldValue(
                                                            'oldTransactionPin',
                                                            getInputValues('oldTransactionPin'),
                                                        );
                                                    }}
                                                />
                                                <PinTrailsLeft>
                                                    {wrongPin && 'INCORRECT PIN. '}
                                                    {` You have ${pinTrialsLeft?.pinTriesLeft} 
                                                        ${pinTrialsLeft?.pinTriesLeft < 3 ? 'more' : ''}
                                                        ${pinTrialsLeft?.pinTriesLeft > 1 ? 'trials' : 'trial'}`}
                                                </PinTrailsLeft>
                                                <RippleButton
                                                    type="submit"
                                                    top={'auto'}
                                                    disabled={values.oldTransactionPin.length !== 4 || isLoading}
                                                >
                                                    Continue
                                                </RippleButton>
                                            </FormWrapper>
                                        )}
                                    </FormContainer>
                                </ScreenContainer>
                            </>
                        ) : (
                            <>
                                <TopHeader title={'Change Pin'} />
                                <ScreenContainer>
                                    <Title>Change your Transaction PIN</Title>
                                    <Message>Enter new transaction PIN to continue</Message>
                                    <FormContainer
                                        initialValues={{
                                            confirmNewTransactionPin: '',
                                            newTransactionPin: '',
                                        }}
                                        validationSchema={TransactionPinFormValidationSchema}
                                        onSubmit={(values) => onPinUpdate(values.newTransactionPin)}
                                    >
                                        {({ values, errors, valid, touched, setFieldValue }) => (
                                            <FormWrapper>
                                                <Label>Enter Transaction PIN to continue</Label>
                                                <PasswordGroup
                                                    count={4}
                                                    startIndex={5}
                                                    type={'password'}
                                                    valid={valid}
                                                    errors={errors}
                                                    name={'newTransactionPin'}
                                                    touched={touched}
                                                    align={'left'}
                                                    enteredValue={newTransactionPin}
                                                    handleChange={(event) => {
                                                        setnewTransactionPin(event.target.value);
                                                        setFieldValue(
                                                            'newTransactionPin',
                                                            getInputValues('newTransactionPin'),
                                                        );
                                                    }}
                                                />
                                                <Label top={'20'}>Enter Transaction PIN again to continue</Label>
                                                <PasswordGroup
                                                    count={4}
                                                    startIndex={10}
                                                    type={'password'}
                                                    valid={valid}
                                                    errors={errors}
                                                    name={'confirmNewTransactionPin'}
                                                    touched={touched}
                                                    align={'left'}
                                                    enteredValue={confirmNewTransactionPin}
                                                    handleChange={(event) => {
                                                        setConfirmNewTransactionPin(event.target.value);
                                                        setFieldValue(
                                                            'confirmNewTransactionPin',
                                                            getInputValues('confirmNewTransactionPin'),
                                                        );
                                                    }}
                                                />
                                                <RippleButton
                                                    type="submit"
                                                    top={'auto'}
                                                    disabled={
                                                        values.confirmNewTransactionPin !== values.newTransactionPin ||
                                                        values.confirmNewTransactionPin?.length !== 4 ||
                                                        values.newTransactionPin?.length !== 4 ||
                                                        isLoading
                                                    }
                                                >
                                                    Save
                                                </RippleButton>
                                            </FormWrapper>
                                        )}
                                    </FormContainer>
                                </ScreenContainer>
                            </>
                        )}
                    </Fragment>
                )}
            </Fragment>
        </DesktopBackgroundLayout>
    );
};

export default UpdateTransactionPin;
