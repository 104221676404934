import React from 'react';

import { bool, string, shape, func, arrayOf } from 'prop-types';
import styled from 'styled-components';

import { Overlay } from '../../../containers/OverlayContainer';
import { colors } from '../../../styles';
import { PopUp, PopUpContent, PopUpHeader } from '../common';

const ConfirmationText = styled.h4`
    font-size: 14px;
    width: 100%;
    color: ${colors.popup.body};
    border-bottom: ${({ borderBottom }) => borderBottom || `1px solid ${colors.border.bottom}`};
    padding: 14.5px 16px 15px 16px;
    text-align: left;
    margin-bottom: 23px;
    margin-top: 0px;
`;

const ConfirmOptions = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 14px;
    font-weight: lighter;
    margin: 0;
`;

const ConfirmOption = styled.a`
    &:first-of-type {
        color: #227eff;
        background: #ffffff;
        border: 1px solid #227eff;
        margin-right: 15px;
        padding: 12px 55px;
    }

    &:last-of-type {
        color: #ffffff;
        background: #e01a00;
        padding: 12px 50px 12px 60px;
    }

    border-radius: 4px;
`;

const Image = styled.img`
    position: absolute;
    right: 125px;
    bottom: 46px;
`;

export const ConfirmPopupDialog = ({ open, title, confirmationText, answers, cancel }) => {
    return (
        <>
            {open && <Overlay zIndex={'1000'} bgc={'rgba(0, 0, 0, 45%)'} onClick={cancel} />}
            <PopUp open={open} zIndex={'10000'}>
                <PopUpContent height={'200px'}>
                    <PopUpHeader weight="bold" noBorderBottom={true} padding={'24px 8px 23px 8px'}>
                        {title}
                    </PopUpHeader>
                    <ConfirmationText borderBottom={'none'}>{confirmationText}</ConfirmationText>
                    <ConfirmOptions>
                        {answers.map(({ variant, action, Icon }, index) => (
                            <ConfirmOption key={index} onClick={action}>
                                <Image src={Icon} />
                                {variant}
                            </ConfirmOption>
                        ))}
                    </ConfirmOptions>
                </PopUpContent>
            </PopUp>
        </>
    );
};

ConfirmPopupDialog.propTypes = {
    open: bool,
    Icon: string,
    title: string,
    confirmationText: string,
    cancel: func,
    answers: arrayOf(
        shape({
            variant: string,
            action: func,
        }),
    ),
};
