import React, { Fragment, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { RippleLink, SwitchTrigger, StorefrontLinkPopup } from '../../../../../components';
import { MenuOptionLogo, ArrowForward } from '../../../../../containers/MenuContainer';
import {
    toggleListingOptionsVisibility,
    getShopInfoFromMerchapp,
} from '../../../../../redux/ducks/applications/my-shop/actions/shop';
import { colors } from '../../../../../styles';
import SFCommissionIcon from '../../assets/commission.svg';
import SFEditIcon from '../../assets/edit.svg';
import SFMarketIcon from '../../assets/marketplace.svg';
import SFPaymentIcon from '../../assets/payment.svg';
import SFShareIcon from '../../assets/share.svg';

const OptionName = styled.h4`
    font-weight: 400;
    color: #071827;
    font-size: 14px;
    margin: auto 16px;
`;

const OptionSubText = styled.h4`
    font-weight: 400;
    color: #718596;
    font-size: 10px;
    margin: 4px 56px 0 16px;
`;

const OptionWithSubText = styled.div`
    display: flex;
    flex-direction: column;
`;

const ImageWrapper = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
`;

const MenuOption = styled.li`
    display: flex;
    flex-direction: row;
    padding: 8px 0 8px 1em;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    -webkit-tap-highlight-color: transparent;
    // &:not(:first-of-type) {
    //     border-top: 1px solid ${colors.border.top};
    // }
    // &:last-of-type {
    border-bottom: 1px solid ${colors.border.bottom};
    // }
`;

const StoreFrontSettings = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const shops = useSelector((state) => state.applications.myShop.shops);
    const role = useSelector((state) => state.user.role);

    const [openShareLinks, setOpenShareLinks] = useState(false);
    const [shop] = useState(shops[0]);

    const [merchbuyVisible, setMerchbuyVisible] = useState({
        oldState: (shop && shop?.listingOptions?.isOnMerchBuy) || false,
        newState: (shop && shop?.listingOptions?.isOnMerchBuy) || false,
    });

    useEffect(() => {
        const shop = shops[0];
        setMerchbuyVisible({
            oldState: (shop && shop?.listingOptions?.isOnMerchBuy) || false,
            newState: (shop && shop?.listingOptions?.isOnMerchBuy) || false,
        });
    }, [shops, setMerchbuyVisible]);

    useEffect(() => {
        shop && shop.branchId && dispatch(getShopInfoFromMerchapp(shop.id, shop.branchId));
    }, [shop, dispatch]);

    return (
        <Fragment>
            <RippleLink to={'/actions/shop_edit'}>
                <MenuOption style={{ alignItems: 'center' }}>
                    <ImageWrapper>
                        <MenuOptionLogo icon={SFEditIcon} />
                    </ImageWrapper>
                    <OptionName>Edit Shop Details</OptionName>
                    <ArrowForward />
                </MenuOption>
            </RippleLink>
            <MenuOption style={{ alignItems: 'center' }}>
                <ImageWrapper>
                    <MenuOptionLogo icon={SFMarketIcon} />
                </ImageWrapper>
                <OptionWithSubText>
                    <OptionName>Marketplace Visibility</OptionName>
                    <OptionSubText>Manage the visibility of your storefront on Merchbuy</OptionSubText>
                </OptionWithSubText>
                <SwitchTrigger
                    right={'16px'}
                    checkStatus={merchbuyVisible.newState}
                    switchStatus={() => {
                        const isOnMerchBuy = !merchbuyVisible.newState;
                        const isOnMerchList = (shop && shop?.listingOptions?.isOnMerchList) || false;

                        setMerchbuyVisible({ ...merchbuyVisible, newState: !merchbuyVisible.newState });

                        //Apply it
                        setMerchbuyVisible({ ...merchbuyVisible, newState: merchbuyVisible.oldState });

                        //if(!shop.listingOptions.isOnMerchBuy && (!shop.city || !shop.lga || !shop.businessPhoneNumber || !shop.shopName)) {
                        if (
                            !shop?.shopName ||
                            !shop?.details?.phoneNumber ||
                            !shop?.location?.address ||
                            !shop?.location?.localGovt ||
                            !shop?.location?.state
                        ) {
                            history.push('/actions/shop_edit');
                        } else {
                            dispatch(toggleListingOptionsVisibility(shop && shop.id, isOnMerchBuy, isOnMerchList));
                        }
                    }}
                />
            </MenuOption>
            <RippleLink to={'/actions/merchbuy/account/payment-method'}>
                <MenuOption style={{ alignItems: 'center' }}>
                    <ImageWrapper>
                        <MenuOptionLogo icon={SFPaymentIcon} />
                    </ImageWrapper>
                    <OptionWithSubText>
                        <OptionName>Payment Methods</OptionName>
                        <OptionSubText>Select where you want your money to go to when customers pay you.</OptionSubText>
                    </OptionWithSubText>
                    <ArrowForward />
                </MenuOption>
            </RippleLink>
            {role === 'ROLE_USER' && shop && shop?.listingOptions?.isOnMerchBuy && (
                <RippleLink to={'/actions/merchbuy/account/commission'}>
                    <MenuOption style={{ alignItems: 'center' }}>
                        <ImageWrapper>
                            <MenuOptionLogo icon={SFCommissionIcon} />
                        </ImageWrapper>
                        <OptionWithSubText>
                            <OptionName>Sabi Commission Breakdown</OptionName>
                            <OptionSubText>
                                This breakdown shows how much we charge per category for selling on spaces.
                            </OptionSubText>
                        </OptionWithSubText>
                        <ArrowForward />
                    </MenuOption>
                </RippleLink>
            )}
            <MenuOption onClick={() => setOpenShareLinks(!openShareLinks)} style={{ alignItems: 'center' }}>
                <ImageWrapper>
                    <MenuOptionLogo icon={SFShareIcon} />
                </ImageWrapper>
                <OptionName>Share Marketplace Links</OptionName>
            </MenuOption>
            {openShareLinks && (
                <StorefrontLinkPopup
                    open={openShareLinks}
                    cancel={() => setOpenShareLinks(!openShareLinks)}
                    link={shop && shop.listingOptions.isOnMerchBuy ? shop.merchbuyLink : ''}
                    shopName={shop && shop.shopName}
                />
            )}
        </Fragment>
    );
};

export default StoreFrontSettings;
