import React, { Fragment, useEffect, useMemo, useState } from 'react';

import { useMachine } from '@xstate/react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createMachine } from 'xstate';

import {
    InputWithOnchange,
    RippleButton,
    SelectBox,
    SuccessPage,
    TextInputWithLabel,
    TopHeader,
} from '../../../../../components';
import { WalletBalanceSection } from '../../../../../components/badges/wallet-badge2';
import animationData from '../../../../../components/badges/wallet-badge2/assets/balance_loader.json';
import { SearchInput } from '../../../../../components/forms/input/search-input/SearchInput';
import { LoaderWithoutHeader } from '../../../../../components/loader';
import { LottieControl } from '../../../../../components/lottie';
import { FormMetaData } from '../../../../../containers/NewInputContainer';
import { FormWrapper, ScreenContainer } from '../../../../../containers/ScreenContainer';
import {
    fetchAllBillCategories,
    fetchBillers,
    fetchBillerServices,
    submitBillPaymentRequest,
    verifyCustomerDetails,
} from '../../../../../redux/ducks/account/wallet/actions/bills-payment';
import { CUSTOMER_DETAILS } from '../../../../../redux/ducks/account/wallet/constants';
import { colors } from '../../../../../styles';
// import { getCommission } from '../../../../../utils/bill-payments/commissionRate';
import { formatCurrency } from '../../../../../utils/currency/parseBalance';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import RatingPopup from '../../../transactions/wallet-transactions/rating-popup';
import { VerifyTransactionPin } from '../airtime-recharge';
import { ReactComponent as InflowIcon } from '../airtime-recharge/assets/inflow.svg';
import { ReactComponent as OutflowIcon } from '../airtime-recharge/assets/outflow.svg';
import { ReactComponent as Spinner } from '../airtime-recharge/assets/spinner.svg';
import {
    AmountTitle,
    Artwork,
    Balance,
    // CommissionBlock,
    FlowTitle,
    LoadingContainer,
    // ModifiedDiscountIcon,
    ModifiedTransactionLinkIcon,
    NetworkProviderDetails,
    OutflowAccount,
    Paragraph,
    PhoneNumber,
    Subtitle,
    TotalAmountBlock,
    TotalTransactionAmount,
    TransactionDetails,
    TransactionWrapper,
    TransactionCharge,
    TransactionWrapperWithCharge,
    FormFooterWrap,
    ServiceChargeBlock,
} from '../airtime-recharge/assets/styles';
import validationSchema from '../bills-payment/validationSchema';

import {
    BoldText,
    FadedText,
    Bill,
    Bills,
    Abbr,
    BillContainer,
    BillTitle,
    TransactionWrapperDetails,
    NetworkProviderAndAmount,
    CustomerValidationContainer,
    CustomerDetails,
    CustomerReferenceBlock,
    LoaderContainer,
} from './styles';

const Loader = () => <LoaderWithoutHeader margin={'20% 0 0 0'} style={{ textAlign: 'center' }} />;

const getAbbrevatedName = (name) => {
    if (!name) return '';
    // get the first and second letters of each word
    const abbrevatedName = name.substr(0, 2).toUpperCase();

    return abbrevatedName;
};

const colorOptions = [
    colors.background.lightBlue,
    colors.lightYellowBg,
    colors.background.failedStatus,
    colors.newPurple,
    colors.lightGreen,
    colors.background.secondaryPad,
];

const pages = {
    BILL_SELECTION: 'BILL_SELECTION',
    BILLER_SELECTION: 'BILLER_SELECTION',
    CONFIGURATION: 'CONFIGURATION',
    CONFIRMATION: 'CONFIRMATION',
};

// This machine is completely decoupled from React
const changePageState = createMachine({
    id: 'changePage',
    initial: pages.BILL_SELECTION,
    states: {
        [pages.BILL_SELECTION]: {
            on: { NEXT: pages.BILLER_SELECTION },
        },
        [pages.BILLER_SELECTION]: {
            on: { NEXT: pages.CONFIGURATION, BACK: pages.BILL_SELECTION },
        },
        [pages.CONFIGURATION]: {
            on: { NEXT: pages.CONFIRMATION, BACK: pages.BILLER_SELECTION },
        },
        [pages.CONFIRMATION]: {
            on: {
                BACK: pages.CONFIGURATION,
            },
        },
    },
});

export function BillsPayment() {
    const transactions = useSelector((state) => state.account?.transactions.transactionsList);
    const walletBalance = useSelector((state) => state.account?.wallet?.balance);
    const walletErrorMessage = useSelector((state) => state.account?.wallet?.errorMsg);
    const customerDetails = useSelector((state) => state.account?.wallet?.customerDetails);

    const user = useSelector((state) => state?.user);
    const successfulTransactions = transactions.filter((x) => x.transactionStatus === 'SUCCESSFUL');
    const history = useHistory();
    const dispatch = useDispatch();
    const [currentPage, send] = useMachine(changePageState);
    const [transactionStatus, setTransactionStatus] = useState('');
    const [openRating, setOpenRating] = useState(false);
    const [selectedBill, setSelectedBill] = useState({ id: '', name: '' });
    const [selectedBiller, setSelectedBiller] = useState({ id: '', name: '' });
    const [transaction, setTransaction] = useState({
        plan: '',
        referenceNumber: '',
        amount: '',
    });

    const withBlueBackground = () => {
        if (currentPage.value === pages.CONFIRMATION) return true;
        return false;
    };

    const handleBackClick = () => {
        if (currentPage.value !== pages.BILL_SELECTION) {
            send('BACK');
            history.goBack();
            return;
        }
    };

    const updateTransaction = (transaction) => {
        setTransaction(transaction);
        send('NEXT');
    };

    const handleTransactionSubmission = async () => {
        const { billerCode, amount, billerId, referenceNumber, name } = transaction;
        const { msisdn, email, country } = user;
        const { additionalProperties } = customerDetails;

        const billRequest = {
            amount,
            billerCode,
            billerId,
            countryCode: country,
            customerEmail: email,
            customerIdentifier: referenceNumber,
            customerPhoneNumber: msisdn,
            paymentItemName: name,
            amountType: additionalProperties.amountType,
        };

        const response = !!(await dispatch(submitBillPaymentRequest(billRequest)));

        if (response) {
            setTransactionStatus('SUCCESS');
            return;
        }
        setTransactionStatus('FAILED');
    };

    return (
        <Fragment>
            <DesktopBackgroundLayout style={{ background: '#227EFF' }} withBlueBackground={withBlueBackground()}>
                <div style={{ visibility: transactionStatus === 'SUCCESS' ? 'visible' : 'hidden' }}>
                    <SuccessfulTransaction />
                    {(transactions.length === 1 || transactions.length % 10 === 0) && (
                        <div style={{ visibility: openRating ? 'visible' : 'hidden' }}>
                            <RatingPopup
                                refId={successfulTransactions[0]?.transactionReference}
                                setOpen={() => setOpenRating(!openRating)}
                            />
                        </div>
                    )}
                </div>

                <div style={{ visibility: transactionStatus === 'FAILED' ? 'visible' : 'hidden' }}>
                    <FailedTransaction setTransactionStatus={setTransactionStatus} errorMessage={walletErrorMessage} />
                </div>

                {transactionStatus === '' && (
                    <>
                        <TopHeader
                            backgroundColor={withBlueBackground() ? colors.deepBlue : ''}
                            title={selectedBill?.name ? `Pay ${selectedBill?.name}` : `Pay Bills`}
                            backAction={handleBackClick}
                            backLink={currentPage.value !== pages.BILL_SELECTION ? '#' : null}
                            color={withBlueBackground() ? colors.white : colors.black}
                        />
                        <ScreenContainer style={{ position: withBlueBackground() ? 'absolute' : 'static' }}>
                            {currentPage.value === pages.BILL_SELECTION && (
                                <AllBills send={send} setSelectedBill={setSelectedBill} />
                            )}

                            {currentPage.value === pages.BILLER_SELECTION && (
                                <BillerSelection
                                    send={send}
                                    selectedBill={selectedBill}
                                    setSelectedBiller={setSelectedBiller}
                                />
                            )}

                            {currentPage.value === pages.CONFIGURATION && (
                                <BillConfiguration
                                    send={send}
                                    selectedBiller={selectedBiller}
                                    onSubmit={updateTransaction}
                                    transactionDetails={transaction}
                                />
                            )}

                            {currentPage.value === pages.CONFIRMATION && (
                                <TransactionConfirmation
                                    transactionDetails={transaction}
                                    walletBalance={walletBalance}
                                    handleTransactionSubmission={handleTransactionSubmission}
                                />
                            )}
                        </ScreenContainer>
                    </>
                )}
            </DesktopBackgroundLayout>
        </Fragment>
    );
}

const AllBills = ({ send, setSelectedBill }) => {
    const dispatch = useDispatch();
    const [billColors, setBillColors] = useState([]);
    const billCategories = useSelector((state) => state.account?.wallet?.billCategories);
    const history = useHistory();
    const isLoading = useSelector((state) => state.account?.wallet?.isLoading) || false;

    useEffect(() => {
        dispatch(fetchAllBillCategories());
    }, [dispatch]);

    useEffect(() => {
        if (!billCategories.length) return;

        let colorIndex = 0;
        const items = [];

        if (billCategories.length > 0) {
            billCategories.forEach(() => {
                if (colorIndex === colorOptions.length) {
                    colorIndex = 0;
                }
                const color = colorOptions[colorIndex];
                items.push(color);
                colorIndex++;
            });

            setBillColors(items);
        }
    }, [billCategories]);

    const handleBillClick = ({ id, name }) => {
        if (name.includes('Airtime')) {
            history.push('/user/wallet/airtime-recharge');

            return;
        }

        setSelectedBill({ id, name });

        send('NEXT');
    };

    const sortBills = (bills) => {
        return bills.sort((previousBill, currentBill) =>
            previousBill?.categoryName.localeCompare(currentBill.categoryName),
        );
    };

    return (
        <Fragment>
            <BoldText>What bill would you like to pay?</BoldText>
            <FadedText>Select the type of payment you want to make</FadedText>

            {isLoading ? (
                <Loader />
            ) : (
                <Bills>
                    {sortBills(billCategories)?.map((billCategory, index) => (
                        <Bill
                            onClick={() =>
                                handleBillClick({ name: billCategory?.categoryName, id: billCategory?.categoryId })
                            }
                            key={billCategory?.categoryName}
                        >
                            <Abbr background={billColors[index]}>{getAbbrevatedName(billCategory?.categoryName)}</Abbr>
                            {billCategory?.categoryName}
                        </Bill>
                    ))}
                </Bills>
            )}
        </Fragment>
    );
};

const BillerSelection = ({ selectedBill, setSelectedBiller, send }) => {
    const dispatch = useDispatch();
    const [billColors, setBillColors] = useState([]);
    const billers = useSelector((state) => state.account?.wallet?.billers) || [];
    const isLoading = useSelector((state) => state.account?.wallet?.isLoading) || false;
    const user = useSelector((state) => state?.user);
    const [filteredBillers, setFilteredBillers] = useState(billers);
    const [searchString, setSearchString] = useState('');

    useEffect(() => {
        let colorIndex = 0;
        const items = [];

        if (billers.length > 0) {
            billers.forEach(() => {
                if (colorIndex === colorOptions.length) {
                    colorIndex = 0;
                }
                const color = colorOptions[colorIndex];
                items.push(color);
                colorIndex++;
            });
            setBillColors(items);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(fetchBillers(selectedBill?.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBill]);

    useEffect(() => {
        if (billers?.length === 0) {
            setFilteredBillers([]);
            return;
        }
        if (!searchString) setFilteredBillers(billers);

        const filtered = billers?.filter((biller) =>
            biller?.billerName?.toLowerCase()?.includes(searchString.toLowerCase()),
        );

        setFilteredBillers(filtered);
    }, [searchString, billers]);

    const handleBillerSelection = (biller) => {
        dispatch(fetchBillerServices(biller?.billerId, user.country));
        setSelectedBiller(biller);

        send('NEXT');
    };

    const sortedBillers = (billers) =>
        billers.sort((previousBiller, currentBiller) =>
            previousBiller?.billerName?.localeCompare(currentBiller.billerName),
        );

    return (
        <BillContainer>
            <WalletBalanceSection showWalletStatus={false} />

            <SearchInput
                placeholder={'Search for a biller'}
                top={'20px'}
                onChange={(event) => setSearchString(event.target.value)}
                value={searchString}
            />

            {isLoading ? (
                <Loader />
            ) : (
                <Bills>
                    {sortedBillers(filteredBillers)?.map((biller, index) => (
                        <Bill onClick={() => handleBillerSelection(biller)} key={index}>
                            <Abbr background={billColors[index]}>{getAbbrevatedName(biller?.displayName)}</Abbr>
                            {biller?.displayName}
                        </Bill>
                    ))}
                </Bills>
            )}
        </BillContainer>
    );
};

const BillConfiguration = ({
    send,
    selectedBiller,
    transactionDetails = { plan: '', referenceNumber: '', amount: null },
    onSubmit,
}) => {
    const dispatch = useDispatch();
    const [selectedPlan, setSelectedPlan] = useState({
        amount: 0,
        billerId: '',
        currency: '',
        fee: 0,
        name: '',
        billerCode: '',
        serviceId: '',
    });

    useEffect(() => {
        dispatch({ type: CUSTOMER_DETAILS, payload: null });
    }, [dispatch]);

    const walletBalance = useSelector((state) => state.account?.wallet?.balance) || 0;
    const billerServices = useSelector((state) => state.account?.wallet?.billerServices) || [];
    const isVerifyingCustomerDetails = useSelector((state) => state.account?.wallet?.verifyingCustomerDetails) || false;
    const customerDetails = useSelector((state) => state.account?.wallet?.customerDetails);
    const [showCustomer, setShowCustomer] = useState(false);

    const formHasError = (errors) => Object.values(errors).some((error) => error.length > 0);

    const handlePlanSelection = (plan, setFieldValue) => {
        setSelectedPlan(plan);
        setFieldValue('plan', plan.name, false);
        // we want to set the amount field if it's a bill with fixed amount
        if (plan.amount > 0) {
            setFieldValue('amount', plan.amount, false);
        }
    };

    // const getCommissionAmount = () => {
    //     if (selectedBiller?.commissionStrategy === 'FIXED') {
    //         return selectedBiller?.commissionRate;
    //     }
    //     const amount = selectedPlan?.amount;
    //     const { commissionRate, settlementPercent } = selectedBiller;

    //     return getCommission(amount, settlementPercent, commissionRate);
    // };

    // Fix the amount field not being sent to the transaction confirmation page and the bill submission

    const formatBillerServices = (billerServices) => {
        return billerServices.map((billerService) => {
            if (billerService.amount) {
                return {
                    ...billerService,
                    name: `${billerService.name} (${formatCurrency(billerService.amount, 'NG')})`,
                };
            }
            return billerService;
        });
    };

    const handleAmountInput = (event, setFieldValue, setFieldError, setFieldTouched) => {
        const {
            target: { value: inputValue = 0 },
        } = event;

        const value = parseInt(inputValue.replace(/\D/g, '') || 0, 10);
        setFieldValue('amount', value, false);
        validateAmount(value, setFieldError, setFieldTouched);
    };

    const validateAmount = (amountInput, setFieldError, setFieldTouched) => {
        const { additionalProperties, amount } = customerDetails;
        //these are the specs for the bills 'NONE', 'MINIMUM', 'GREATER_THAN_MINIMUM', 'MAXIMUM', 'LESS_THAN_MAXIMUM', 'EXACT'
        setFieldTouched('amount', true, false);
        const serviceCharge = 100;

        if (amountInput + serviceCharge > walletBalance) {
            setFieldError('amount', 'Insufficient balance!');
            return false;
        }

        if (amountInput === 0) {
            setFieldError('amount', 'Please enter a valid amount');
            return false;
        }

        switch (additionalProperties?.amountType) {
            case 'MINIMUM':
                if (amountInput < amount) {
                    setFieldError('amount', `Amount must be at least ${formatCurrency(amount, 'NG')}`);
                    return false;
                }
                break;

            case 'GREATER_THAN_MINIMUM':
                if (amountInput <= amount) {
                    setFieldError('amount', `Amount must be greater than ${formatCurrency(amount, 'NG')}`);
                    return false;
                }
                break;

            case 'MAXIMUM':
                if (amountInput > amount) {
                    setFieldError('amount', `Amount cannot be greater than ${formatCurrency(amount, 'NG')}`);
                    return false;
                }
                break;

            case 'LESS_THAN_MAXIMUM':
                if (amountInput >= amount) {
                    setFieldError('amount', `Amount must be less than ${formatCurrency(amount, 'NG')}`);
                    return false;
                }
                break;

            case 'EXACT':
                if (amountInput !== amount) {
                    setFieldError('amount', `Amount must be ${formatCurrency(amount, 'NG')}`);
                    return false;
                }
                break;

            default:
                setFieldError('amount', '');
                return true;
        }
    };

    const handleFormSubmission = (values, setFieldError) => {
        if (selectedPlan.amount > walletBalance) {
            setFieldError('plan', 'Insufficient funds in your wallet');
            return;
        }

        onSubmit({
            // this amount - we might need to check if the amount is fixed or not
            ...selectedPlan,
            ...values,
        });
    };

    const checkUserVerification = (referenceNumber) => {
        console.log(referenceNumber);
        if (!referenceNumber) return;
        setShowCustomer(true);
    };

    const handleFocus = () => {
        setShowCustomer(false);
        dispatch({ type: CUSTOMER_DETAILS, payload: null });
    };

    return (
        <Fragment>
            <BillContainer>
                <BoldText>What bill would you like to pay?</BoldText>
                <FadedText>Select the type of payment you want to make</FadedText>

                <WalletBalanceSection showWalletStatus={false} />

                <FormWrapper margin={'10px 0'} top="60vh">
                    <Formik
                        initialValues={{
                            plan: transactionDetails?.plan || '',
                            referenceNumber: transactionDetails?.referenceNumber || '',
                            amount: transactionDetails?.amount || '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setFieldError }) => {
                            handleFormSubmission(values, setFieldError);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            initialValues,
                            setFieldValue,
                            setFieldError,
                            setFieldTouched,
                        }) => (
                            <Form>
                                <FormWrapper gap={'8px'} top="auto">
                                    <SelectBox
                                        disabled={billerServices.length === 0}
                                        placeholder={'Select Plan'}
                                        value={values.plan}
                                        options={formatBillerServices(billerServices)}
                                        handleChange={(selected) => handlePlanSelection(selected, setFieldValue)}
                                        valid={`${!touched.plan && !errors.plan}`}
                                        error={touched && touched.plan && errors && errors.plan}
                                        itemKey="name"
                                    >
                                        <BillTitle>{values.plan}</BillTitle>
                                    </SelectBox>

                                    <TextInputWithLabel
                                        disabled={!selectedPlan?.billerId}
                                        placeholder={'Reference Number'}
                                        type={'text'}
                                        label={'Reference Number'}
                                        name={'referenceNumber'}
                                        onBlur={() => checkUserVerification(values.referenceNumber)}
                                        onFocus={handleFocus}
                                        top={'16px'}
                                    />

                                    {selectedPlan?.billerId &&
                                        customerDetails &&
                                        customerDetails?.additionalProperties?.amountType !== 'EXACT' && (
                                            <InputWithOnchange
                                                label={'Amount'}
                                                width="100%"
                                                type={'text'}
                                                borderRadius={'4px'}
                                                borderColor={'#E2E9F0'}
                                                placeholder={'Amount'}
                                                inputMode={'numeric'}
                                                name={'amount'}
                                                bottom={'20px'}
                                                height={'56px'}
                                                errors={errors && errors.amount}
                                                disabled={customerDetails?.additionalProperties?.amountType === 'EXACT'}
                                                value={formatCurrency(values.amount, 'NG')}
                                                onChange={(event) =>
                                                    handleAmountInput(
                                                        event,
                                                        setFieldValue,
                                                        setFieldError,
                                                        setFieldTouched,
                                                    )
                                                }
                                                noClearButton={true}
                                                initialValues={initialValues}
                                            />
                                        )}

                                    {showCustomer && (
                                        <CustomerValidation
                                            customerReference={values.referenceNumber}
                                            billerCode={selectedPlan.billerCode}
                                            billerId={selectedPlan.billerId}
                                            billerName={selectedBiller.billerName}
                                        />
                                    )}

                                    {/* <CommissionBlock top={'0'}>
                                        <Paragraph>
                                            <ModifiedDiscountIcon />
                                            Commission earned
                                        </Paragraph>
                                        <Paragraph>{formatCurrency(getCommissionAmount())}</Paragraph>
                                    </CommissionBlock> */}
                                    {!values.plan ? (
                                        <Subtitle margin={'10px 0 0'} size={'12px'}>
                                            Earn money for every recharge you make on sabi
                                        </Subtitle>
                                    ) : null}

                                    <FormFooterWrap>
                                        <ServiceChargeBlock>
                                            <Subtitle size={'12px'} textAlign={'left'} lineHeight={'18px'}>
                                                Service Charge
                                            </Subtitle>
                                            <Paragraph size="14px" weight="500">
                                                {formatCurrency(100, 'NG')}
                                            </Paragraph>
                                        </ServiceChargeBlock>
                                        <RippleButton
                                            type="submit"
                                            height={'48px'}
                                            top={'16px'}
                                            backgroundColor={colors.deepBlue}
                                            disabled={
                                                values.plan === '' ||
                                                formHasError(errors) ||
                                                isVerifyingCustomerDetails ||
                                                !customerDetails
                                            }
                                        >
                                            Continue
                                        </RippleButton>
                                    </FormFooterWrap>

                                    <FormMetaData showerror={true} top={'5px'} bottom={'3px'}>
                                        {errors.plan}
                                    </FormMetaData>
                                </FormWrapper>
                            </Form>
                        )}
                    </Formik>
                </FormWrapper>
            </BillContainer>
        </Fragment>
    );
};

const TransactionConfirmation = ({ transactionDetails, walletBalance, handleTransactionSubmission }) => {
    const isLoading = useSelector((state) => state.account.wallet.isLoading);

    const randomColor = () => {
        const index = Math.floor(Math.random() * colorOptions.length - 1);
        return colorOptions[index];
    };

    return (
        <>
            <TransactionDetails>
                <NetworkProviderDetails>
                    <Artwork>
                        <OutflowIcon />
                        <ModifiedTransactionLinkIcon />
                        <InflowIcon />
                    </Artwork>
                    <TransactionWrapperDetails>
                        <TransactionWrapper>
                            <FlowTitle>From</FlowTitle>
                            <OutflowAccount>My Wallet</OutflowAccount>
                            <Balance>Bal:{formatCurrency(walletBalance)}</Balance>
                        </TransactionWrapper>

                        <TransactionWrapper>
                            <FlowTitle>To</FlowTitle>
                            <NetworkProviderAndAmount>
                                <Abbr background={randomColor()}>{getAbbrevatedName('Seerbit')}</Abbr>
                                <TransactionWrapperWithCharge>
                                    <PhoneNumber>{transactionDetails?.referenceNumber}</PhoneNumber>
                                    <Paragraph>Amount: {formatCurrency(transactionDetails?.amount)}</Paragraph>
                                    <TransactionCharge>Charge: {formatCurrency(100)}</TransactionCharge>
                                </TransactionWrapperWithCharge>
                            </NetworkProviderAndAmount>
                        </TransactionWrapper>
                    </TransactionWrapperDetails>
                </NetworkProviderDetails>

                <TotalAmountBlock>
                    <AmountTitle>Amount</AmountTitle>
                    <TotalTransactionAmount>
                        {formatCurrency(Number(transactionDetails?.amount + 100))}
                    </TotalTransactionAmount>
                </TotalAmountBlock>
            </TransactionDetails>

            <VerifyTransactionPin
                transactionDetails={transactionDetails}
                handleTransactionSubmission={handleTransactionSubmission}
            />

            {isLoading ? (
                <LoadingContainer>
                    <Spinner />
                </LoadingContainer>
            ) : null}
        </>
    );
};

const FailedTransaction = ({ setTransactionStatus, errorMessage }) => {
    return (
        <SuccessPage
            title="Transaction failed!"
            subtitle="We couldn't complete your transaction. Kindly try again"
            doneText="Try Again"
            successfull={false}
            onDoneClick={() => {
                setTransactionStatus('');
                toast.error(errorMessage ?? 'Transaction failed');
            }}
        />
    );
};

const SuccessfulTransaction = () => {
    const history = useHistory();
    const billPaymentResponse = useSelector((state) => state.account?.wallet?.billPaymentResponse);

    return (
        <SuccessPage
            title="Transaction Successful"
            subtitle={billPaymentResponse?.message || 'Your payment was successful'}
            doneText="Done"
            detailText="View transaction details"
            showDetailButton={false}
            onDoneClick={() => history.push('/user/wallet_index')}
            onDetailClick={() => history.push('/user/wallet_index')}
        />
    );
};

const CustomerValidation = ({ customerReference, billerCode, billerId, billerName }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.account?.wallet?.verifyingCustomerDetails) || false;
    const customerDetails = useSelector((state) => state.account?.wallet?.customerDetails);

    useEffect(() => {
        if (!customerReference || !billerCode || !billerId) return;
        const verifyCustomer = async () => {
            const response = await dispatch(verifyCustomerDetails(customerReference, billerCode, billerId));
            if (!response) {
                toast.error('Invalid reference number, please try again');
            }
        };

        verifyCustomer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const randomColor = useMemo(() => {
        //FIXME we still don't have the icons for the bills yet
        return colorOptions[Math.floor(Math.random() * colorOptions.length)];
    }, []);

    if (isLoading) {
        return (
            <LoaderContainer>
                <LottieControl height={40} width={40} play={isLoading} animationData={animationData} />
            </LoaderContainer>
        );
    } else if (customerDetails) {
        return (
            <CustomerValidationContainer>
                <Abbr background={randomColor} width={'45px'} height={'45px'} marginBottom={'0'}>
                    {getAbbrevatedName(billerName)}
                </Abbr>
                <CustomerDetails>
                    <CustomerReferenceBlock>
                        <FadedText margin={'0'}>ID:</FadedText>
                        <BoldText fontSize={'14px'} margin={'0'}>
                            {customerDetails?.customerIdentifier}
                        </BoldText>
                    </CustomerReferenceBlock>
                    <FadedText fontSize={'12px'} margin={'0'}>
                        {customerDetails?.customerName || 'No Name'}
                    </FadedText>
                </CustomerDetails>
            </CustomerValidationContainer>
        );
    }

    return null;
};
