export const persistToken = (tokenData) => {
    localStorage.setItem(
        'token',
        JSON.stringify({
            token: tokenData.token,
            expiresIn: tokenData.expiresIn,
        }),
    );

    localStorage.setItem(
        'refreshToken',
        JSON.stringify({
            refreshToken: tokenData.refreshToken,
            expiresIn: tokenData.refreshExpiresIn,
        }),
    );

    if (tokenData && tokenData.fingerPrint) {
        localStorage.setItem('fingerPrint', tokenData.fingerPrint);
    }
};
