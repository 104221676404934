import React, { useState, useEffect, Fragment } from 'react';

import { Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Loader, RippleButton, TopHeader, usePaystackPayment } from '../../../../components';
import {
    createOrder,
    getCountryByName,
    getStateByCountryId,
} from '../../../../redux/ducks/applications/store-front/actions/store';
import { colors } from '../../../../styles';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import { PROCESS_PAYMENT } from '../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../utils/mix-panel/mixPanel';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import StoreFrontFooter from '../footer';

import StepOne from './stepOne';
import StepTwo from './stepTwo';
import { getStoreFrontProductInfoForMixPanel } from './storeFrontHelper';
import Success from './success';
import { ValidationSchema } from './validation';

const Space = styled.div`
    height: 24px;
`;

const AmountWrapper = styled.div`
    height: 108px;
    background: ${colors.white};
    padding: 16px;
    margin: 8px 0 7px 0;
`;

const AmountItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${({ top }) => top};
`;

const AmountTitle = styled.div`
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: ${colors.smoothGreyText};
`;

const Amount = styled.div`
    font-size: 14px;
    line-height: 21px;
    color: ${colors.boldDark};
`;

const TotalText = styled.div`
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #2e4457;
`;

const Total = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.themeTextColor1};
`;

const ButtonWrapper = styled.div`
    margin: 0 16px;
`;

const StoreFrontCheckout = () => {
    const dispatch = useDispatch();
    const country = useSelector((state) => state.applications.storeFront.country);
    const store = useSelector((state) => state.applications.storeFront.storeByLink);
    const loading = useSelector((state) => state.applications.storeFront.loadingStore);
    const [carts, setCarts] = useState([]);
    const [subTotal, setSubTotal] = useState(
        carts.reduce((accum, current) => Number(accum) + Number(current.price) * Number(current.quantity), 0),
    );
    const [data, setData] = useState({
        fullName: '',
        phoneNumber: '',
        email: '',
        stateId: '',
        lgaId: '',
        address: '',
        paymentMode: '',
    });
    const [state, setState] = useState('State');
    const [city, setCity] = useState('City');
    const [paystackKey] = useState(process.env.REACT_APP_PAYSTACK_KEY);
    const [findCity, setFindCity] = useState('');
    const [payloadProd, setPayloadProd] = useState({});
    const [payName, setPayName] = useState('');
    const [paymentOptions, setPaymentOptions] = useState([
        { title: 'Credit Card Payment', active: false, name: 'PayOnline' },
        { title: 'Pay on Delivery', active: false, name: 'PayOnDelivery' },
    ]);
    const [activeStep, setActiveStep] = useState(0);

    const handleActivePayment = (option) => {
        let newOptions = [...paymentOptions];
        newOptions[0].active = false;
        newOptions[1].active = false;
        let selectedOptionIndex = newOptions.findIndex((data) => data.name === option);
        newOptions[selectedOptionIndex].active = !newOptions[selectedOptionIndex].active;
        setPaymentOptions(newOptions);
        setData({ ...data, paymentMode: newOptions[selectedOptionIndex]?.name });
        setPayName(newOptions[selectedOptionIndex]?.title);
    };
    const fees = store.deliveryFees.filter((fee) => fee.endPoint === findCity);

    const isDisabled = (values) => {
        const { fullName, phoneNumber, address } = values;
        const disableCheck =
            fullName && phoneNumber && address && state !== 'State' && city !== 'City' && data.paymentMode;
        return !disableCheck;
    };

    const initializePayment = usePaystackPayment();

    const placeOrder = (data, storeId, paymentMode) => {
        return dispatch(createOrder(data, storeId, paymentMode));
    };

    const placeOrderHook = async (values, storeId, paymentMode) => {
        const response = await placeOrder(values, storeId, paymentMode);
        if (response.status) {
            const data = getStoreFrontProductInfoForMixPanel(carts);
            mixPanel.track(PROCESS_PAYMENT, {
                'Total cart value': fees[0]?.fee ? subTotal + fees[0]?.fee : subTotal,
                'Total item count': data.totalItemCount,
                'Total unique item': carts.length,
                'Item ids': data.productIds,
                'Item names': data.productNames,
                Time: new Date().toLocaleString(),
            });
            let paymentDetail = {
                orderId: response.order.storeOrderId,
                orderReference: response.order.orderReference,
                partnerCode: '',
                payment: {
                    deliveryCharge: response.order.deliveryFee,
                    email: response.order.email,
                    message: '',
                    redirecturl: '',
                    reference: null,
                    response: '',
                    responseString: '',
                    serviceCharge: 0, //serviceCharge,
                    status: '',
                    subTotal: response.order.costOfGoods,
                    total: response.order.totalCost,
                    transactionId: null,
                    transactionReference: null,
                },
                referralCode: '',
            };
            const config = {
                email: values.email,
                fullName: values.fullName,
                amount: Math.ceil(fees[0]?.fee ? (subTotal + fees[0]?.fee) * 100 : subTotal * 100),
                publicKey: paystackKey,
            };
            initializePayment(
                config,
                (reference) => {
                    paymentDetail = {
                        ...paymentDetail,
                        payment: {
                            ...paymentDetail.payment,
                            reference: reference.reference,
                            transactionId: reference.trans,
                            transactionReference: reference.trxref,
                        },
                    };
                    if (reference.status === 'success' && reference.message === 'Approved') {
                        localStorage.removeItem('storeFrontCart');
                        setActiveStep(activeStep + 1);
                    } else {
                        localStorage.removeItem('storeFrontCart');
                    }
                },
                () => console.log('payment closed'),
            );
        }
    };

    const handleStep = (data, storeId, paymentMode) => {
        if (activeStep === 0) {
            setFindCity(city.name);
            setActiveStep(activeStep + 1);
        } else if (data.paymentMode === 'PayOnDelivery' && activeStep === 1) {
            dispatch(createOrder(data, storeId, paymentMode)).then((res) => {
                if (res.status) {
                    localStorage.removeItem('storeFrontCart');
                    setActiveStep(activeStep + 1);
                } else {
                    localStorage.removeItem('storeFrontCart');
                }
            });
        } else if (data.paymentMode === 'PayOnline' && activeStep === 1) {
            placeOrderHook(data, storeId, paymentMode);
        }
    };

    useEffect(() => {
        dispatch(getCountryByName('Nigeria'));
    }, [dispatch]);

    useEffect(() => {
        if (country[0]?.id) {
            dispatch(getStateByCountryId(country[0]?.id));
        }
    }, [country, dispatch]);

    useEffect(() => {
        const carts = JSON.parse(localStorage.getItem('storeFrontCart'));
        if (carts) {
            setCarts(carts);
            const newSubTotal = carts.reduce(
                (accum, current) => Number(accum) + Number(current.price) * Number(current.quantity),
                0,
            );
            setSubTotal(newSubTotal);
            const newCart = carts.map((data) => {
                return {
                    productId: data.id,
                    quantity: data.quantity,
                };
            });
            setPayloadProd(newCart);
        }
    }, []);

    const title = ['Checkout', 'Order Summary'];

    if (loading) return <Loader />;

    return (
        <Fragment>
            <DesktopBackgroundLayout fullHeight bgColor="#F9FAFC">
                {activeStep === 2 ? (
                    <Success />
                ) : (
                    <>
                        <TopHeader title={title[activeStep]} noBorderBottom />
                        <Formik
                            initialValues={data}
                            validationSchema={ValidationSchema}
                            onSubmit={(values, { setErrors }) => {}}
                        >
                            {({ values }) => (
                                <Form>
                                    <Steps
                                        values={values}
                                        activeStep={activeStep}
                                        state={state}
                                        setState={setState}
                                        city={city}
                                        setCity={setCity}
                                        paymentOptions={paymentOptions}
                                        handleActivePayment={handleActivePayment}
                                        setActiveStep={setActiveStep}
                                        paymentMethod={data.paymentMethod}
                                        payName={payName}
                                    />
                                    <AmountWrapper>
                                        <AmountItem>
                                            <AmountTitle>Items Total</AmountTitle>
                                            <Amount>{formatPrice(subTotal)}</Amount>
                                        </AmountItem>
                                        <AmountItem top="11px">
                                            <AmountTitle>Delivery Fee</AmountTitle>
                                            <Amount>{formatPrice(fees[0]?.fee || 0.0)}</Amount>
                                        </AmountItem>
                                        <AmountItem top="11px">
                                            <TotalText>Total</TotalText>
                                            <Total>
                                                {formatPrice(fees[0]?.fee ? subTotal + fees[0]?.fee : subTotal)}
                                            </Total>
                                        </AmountItem>
                                    </AmountWrapper>
                                    <ButtonWrapper>
                                        <RippleButton
                                            type={'button'}
                                            disabled={isDisabled(values)}
                                            onClick={() =>
                                                handleStep(
                                                    {
                                                        ...values,
                                                        paymentMode: data.paymentMode,
                                                        productOrderDetailsDto: payloadProd,
                                                        stateId: state.id,
                                                        lgaId: city.id,
                                                    },
                                                    store.id,
                                                    data.paymentMode,
                                                )
                                            }
                                            size="16px"
                                            height="56px"
                                            top="0"
                                        >
                                            {activeStep === 0 ? 'Continue' : 'Pay'}
                                        </RippleButton>
                                    </ButtonWrapper>
                                </Form>
                            )}
                        </Formik>
                    </>
                )}
                <Space />
                <StoreFrontFooter />
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

const Steps = ({
    values,
    activeStep,
    state,
    setState,
    city,
    setCity,
    setFindCity,
    paymentOptions,
    handleActivePayment,
    setActiveStep,
    paymentMethod,
    payName,
}) => {
    const steps = [
        <StepOne
            state={state}
            setState={setState}
            city={city}
            setCity={setCity}
            setFindCity={setFindCity}
            paymentOptions={paymentOptions}
            handleActivePayment={handleActivePayment}
        />,
        <StepTwo
            paymentMethod={paymentMethod}
            state={state}
            city={city}
            setActiveStep={setActiveStep}
            values={values}
            payName={payName}
        />,
    ];

    return steps[activeStep];
};

export default StoreFrontCheckout;
