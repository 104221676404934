import React, { Fragment, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
    WalletBadge2,
    ApprovalStatusPopup,
    ComingSoon,
    SwitchTrigger,
    RippleButton,
    NotificationBell,
} from '../../../../components';
import { LightButton } from '../../../../components/button';
import { FeatureUndergoingFixes } from '../../../../components/popup/feature-undergoing-fixes';
import { BlurBackground } from '../../../../containers/BlurBackground';
import { List } from '../../../../containers/ListContainer';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { getAgencyBankingSignupState } from '../../../../redux/ducks/account/agency-banking/actions';
import { getNotifications } from '../../../../redux/ducks/account/notifications/actions';
import { getWalletTransactions, getValidationOnPin } from '../../../../redux/ducks/account/transactions/actions';
import {
    getWalletBalance,
    getWalletActivationStatus,
    saveLastActionUrl,
    getWalletBalanceAsync,
    getUserUnpaidCommission,
    hideBalance,
    getUserWalletData,
} from '../../../../redux/ducks/account/wallet/actions';
import { getCustomers } from '../../../../redux/ducks/applications/my-shop/actions/customers';
import { getUserAdditionalInfo } from '../../../../redux/ducks/user/actions';
import { colors } from '../../../../styles';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import History from '../../../../utils/History';
import { WALLET_LANDING_PAGE } from '../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../utils/mix-panel/mixPanel';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { Navigation } from '../../../home';
import TermsInfoOverlay from '../../dashboard/termsInfoOverlay';
import TransactionCard from '../transaction-card';

import { ReactComponent as ActivateIcon } from './assets/activate_icon.svg';
import { ReactComponent as CommissionIcon } from './assets/commission.svg';
import { ReactComponent as MedalIcon } from './assets/medal.svg';
import { ReactComponent as ModalWalletIcon } from './assets/modal-wallet-icon.svg';
import { ReactComponent as RightArrowSvg } from './assets/right-arrow.svg';
import { ReactComponent as ShoppingOptionSvg } from './assets/shopping-options.svg';
import { ReactComponent as TimeLimitIcon } from './assets/time-limit.svg';
// import { ReactComponent as TransactionLimitSvg } from './assets/transaction-limit.svg';
import { ReactComponent as TransactionPinSvg } from './assets/transaction-pin.svg';
import WalletIcon from './assets/wallet_unavailable.svg';
import ComingSoonPage from './coming-soon';
import EmptyTransactionPage from './empty-transactions';

const DashboardWrapper = styled(ScreenContainer)`
    animation: fromRightTransform 0.5s ease;
    margin-bottom: 18px;
    margin-top: 0px;
    @keyframes fromRightTransform {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(0%);
        }
    }
`;

const PageTitle = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.themeTextColor1};
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: ${({ direction }) => (direction ? 'Row' : 'column')};
    align-items: ${({ align }) => align || null};
`;

const PageSubSectionsTop = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    margin: 5px 0;
`;

const PageSubSectionsTopTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 21px;
    color: #718596;
`;

const PageSubSectionsTopLink = styled(Link)`
    padding-top: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    color: #227eff;
    -webkit-tap-highlight-color: transparent;
`;

const WalletActivationContainer = styled.div`
    height: 299px;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background-color: white;
    border-radius: 15px 21px 0 0;
    padding: 42px 21px 32px 24px;
`;

const DeactivateTitleText = styled.h3`
    color: ${({ color }) => color || '#0350bb'};
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 16px;
`;

const DeactivateTitleDescription = styled.h5`
    color: #718596;
    color: ${({ color }) => color || '#718596'};
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
`;

const ActivateWalletText = styled.h6`
    color: #cd4213;
    font-size: 12px;
    font-weight: 400;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
`;

const BlurPopUpBackground = styled(BlurBackground)`
    bottom: 0;
    position: fixed;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 8px 0 16px;
    align-items: center;
`;

const ItemsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 0;
`;

const ItemText = styled.p`
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    color: #071827;
`;

const ShoppingOptionWrapper = styled.div`
    height: calc(306px + 66px);
    width: 100%;
    background-color: white;
    position: fixed;
    bottom: 0;
    padding: 24px 0;
    border-radius: 16px 16px 0 0;
    @media (max-width: 576px) {
        height: auto;
    }
`;

const ShoppingOptionItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 16px;
`;

const ShoppingOptionTitle = styled.p`
    font-size: 16px;
    font-weight: 500;
    text-align: center;
`;

const RightBar = styled.div`
    display: flex;
    align-items: center;
`;

const Backdrop = styled.div`
    height: 100%;
    width: 100%;
`;

const ModalContainer = styled.div`
    height: calc(306px + 66px);
    width: 100%;
    background-color: white;
    position: fixed;
    bottom: 0;
    padding: 24px 0;
    border-radius: 16px 16px 0 0;
    @media (max-width: 576px) {
        height: auto;
    }
`;

const TimeLimitWrapper = styled.div`
    display: flex;
    align-items: centre;
    gap: 8px;
    margin: 0px 21px 0px 24px;
`;
const TimeLimit = styled.p`
    margin-top: 45px;
    margin-bottom: 0px;
    color: #cd4213;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
`;

const StyledTimeLimitIcon = styled(TimeLimitIcon)`
    margin-top: 42px;
`;

const TextWrapper = styled.div`
    margin: 0px 21px 0px 24px;
`;

const Text1 = styled.p`
    color: #0350bb;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    margin-top: 11px;
`;

const Text2 = styled.p`
    color: #718596;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-top: 16px;
    margin-bottom: 0px;
`;

const CommissionContainer = styled.div`
    background: linear-gradient(91.32deg, #03b860 -4.11%, #227eff 188.19%);
    box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: grid;
    grid-template-columns: 32px 1fr;
    align-items: center;
    column-gap: 12px;
    padding: 14px 12px;
    color: ${colors.white};
    font-size: 12px;
`;

const CommissionTextContainer = styled.div`
    display: grid;
    grid-template-rows: 1fr;
    row-gap: 4px;
`;

const CommissionText = styled.p`
    color: inherit;
    margin: 0;
    padding: 0;
    font-weight: ${({ weight }) => weight || '400'};
`;

const CommissionMetaText = styled(CommissionText)`
    line-height: 16px;
    font-size: 10px;
`;

const CommissionAmount = styled.div`
    display: flex;
    justify-content: space-between;
    line-height: 18px;
    font-weight: 500;
`;

const WalletTransactions = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector((state) => state.user);
    const role = useSelector((state) => state.user.role);
    const status = useSelector((state) => state.account.wallet.status);
    const transactions = useSelector((state) => state.account.transactions.transactionsList);
    const canResetPin = useSelector((state) => state.account.transactions.canResetPin);
    const agentState = useSelector((state) =>
        state.user.agentState ? state.user.agentState.toUpperCase() : 'PENDING',
    );
    const balanceHidden = useSelector((state) => state.account.wallet.hideBalance);
    const realms = useSelector((state) => state.user.realmNames);
    const commission = useSelector((state) => state?.account?.wallet?.commission);
    const notifications = useSelector((state) => state.account.notifications.allNotifications);
    const features = useSelector((state) => state.user.features);

    const [hasAnsweredQuestion, setHasAnswereQuestion] = useState({});
    const [classNames, setClassNames] = useState('');
    const [statusPopup, setStatusPopup] = useState(false);
    const [openTermsOverlay, setOpenTermsOverlay] = useState(false);
    const [openWallet, setOpenWallet] = useState(false);
    const [openActivatePopup, setOpenActivatePopup] = useState(false);
    const [openSecretQuestionPopup, setOpenSecretQuestionPopup] = useState(false);
    const [showWalletOption, setShowWalletOption] = useState(false);
    const [show24HrsPopup, setShow24HrsPopup] = useState(false);
    const [openFeatureFixes, setOpenFeatureFixes] = useState(false);

    useEffect(() => {
        dispatch(getUserWalletData()).then((res) => {
            res && setHasAnswereQuestion(res);
        });
        if (
            hasAnsweredQuestion?.data?.hasAnsweredSecurityQuestion === false &&
            hasAnsweredQuestion?.data?.bvnVerified === true
        ) {
            setOpenSecretQuestionPopup(true);
        }

        if (
            hasAnsweredQuestion?.data?.hasAnsweredSecurityQuestion === false &&
            hasAnsweredQuestion?.data?.agentState === 'INCOMPLETE'
        ) {
            setOpenSecretQuestionPopup(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, hasAnsweredQuestion?.data?.hasAnsweredSecurityQuestion]);

    useEffect(() => {
        mixPanel.track(WALLET_LANDING_PAGE);
        window.addEventListener('scroll', handleScroll);
        return (_) => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        dispatch(getCustomers());
        dispatch(getWalletBalance());
        dispatch(getWalletBalanceAsync());
        dispatch(getAgencyBankingSignupState());
        dispatch(getUserAdditionalInfo());
        dispatch(getWalletTransactions());
        dispatch(getNotifications());
        dispatch(getValidationOnPin());
        // adding user commissions to the request here
        dispatch(getUserUnpaidCommission());
    }, [dispatch]);

    useEffect(() => {
        if (status !== 'ACTIVE') {
            dispatch(getWalletActivationStatus());
        }
    }, [dispatch, status]);

    const handleScroll = () => {
        const scrollY = window.scrollY;
        if (scrollY < 50) setClassNames('');
        if (scrollY > 50) setClassNames('firstStep');
        if (scrollY > 100) setClassNames('secondStep');
        if (scrollY > 120) setClassNames('thirdStep');
        if (scrollY > 140) setClassNames('fourthStep');
    };

    const buttonAction = async (url, feature) => {
        if (!features?.[feature]?.status) return setOpenFeatureFixes(true);

        if (role === 'ROLE_AGENT' && agentState !== 'APPROVED') {
            setStatusPopup(!statusPopup);
        } else if (status === 'ACTIVE' || status === 'BLOCKED') {
            History.push(url);
        } else {
            setOpenActivatePopup(true);
            dispatch(saveLastActionUrl(url));
        }
    };

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                {openFeatureFixes && <FeatureUndergoingFixes cancel={() => setOpenFeatureFixes(false)} />}
                {openSecretQuestionPopup && (
                    <BlurPopUpBackground onClick={() => setOpenSecretQuestionPopup(false)}>
                        <WalletActivationContainer>
                            <IconWrapper>
                                <MedalIcon />
                                <ActivateWalletText>New Security Feature</ActivateWalletText>
                            </IconWrapper>
                            <DeactivateTitleText color={colors.themeTxtColor10}>
                                Add Security Question
                            </DeactivateTitleText>
                            <DeactivateTitleDescription color={colors.themeTextColor11}>
                                This helps to identify you when you're trying to regain access to your wallet if
                                blocked.
                            </DeactivateTitleDescription>
                            <LightButton
                                onClick={() => {
                                    history.push('/user/wallet_index/security-question');
                                }}
                                size="16px"
                                weight="500"
                                color={colors.popup.cancelButton}
                                background={colors.border.primary}
                            >
                                Continue
                            </LightButton>
                        </WalletActivationContainer>
                    </BlurPopUpBackground>
                )}
                {openActivatePopup && (
                    <BlurPopUpBackground onClick={() => setOpenActivatePopup(false)}>
                        <WalletActivationContainer>
                            <IconWrapper>
                                <ActivateIcon width="24px" height="24px" />
                                <ActivateWalletText>Activate your wallet</ActivateWalletText>
                            </IconWrapper>
                            <DeactivateTitleText>Your wallet has not been activated</DeactivateTitleText>
                            <DeactivateTitleDescription>
                                Provide more information about yourself so we can help verify and activate your wallet.
                            </DeactivateTitleDescription>
                            <LightButton
                                onClick={() => {
                                    History.push({
                                        pathname: '/user/account_bank_data',
                                        state: '/user/wallet_fund',
                                    });
                                }}
                                size="16px"
                                weight="500"
                                color="#FFFFFF"
                                background="#227EFF"
                            >
                                Activate Wallet
                            </LightButton>
                        </WalletActivationContainer>
                    </BlurPopUpBackground>
                )}
                {showWalletOption && (
                    <BlurBackground position="fixed">
                        <Backdrop onClick={() => setShowWalletOption(false)} />
                        <ShoppingOptionWrapper>
                            <ShoppingOptionTitle>Wallet Options</ShoppingOptionTitle>
                            <ShoppingOptionItemWrapper>
                                <ItemsContainer>
                                    <ModalWalletIcon />
                                    <ItemText>Hide wallet balance</ItemText>
                                </ItemsContainer>
                                <ItemsContainer>
                                    <SwitchTrigger
                                        checkStatus={balanceHidden}
                                        switchStatus={() => {
                                            if (balanceHidden === true) {
                                                dispatch(hideBalance(!balanceHidden));
                                            } else {
                                                dispatch(hideBalance(!balanceHidden));
                                            }
                                        }}
                                        top={'90px'}
                                    />
                                </ItemsContainer>
                            </ShoppingOptionItemWrapper>
                            {/* <ShoppingOptionItemWrapper
                                onClick={() => {
                                    history.push('/');
                                }}
                            >
                                <ItemsContainer>
                                    <TransactionLimitSvg />
                                    <ItemText>Transaction Limit</ItemText>
                                </ItemsContainer>
                                <ItemsContainer>
                                    <RightArrowSvg />
                                </ItemsContainer>
                            </ShoppingOptionItemWrapper> */}
                            <ShoppingOptionItemWrapper
                                onClick={() => {
                                    if (canResetPin) {
                                        history.push('/user/wallet_index/forgot-transaction-pin');
                                    } else if (!user.hasTransactionPin && status === 'INACTIVE') {
                                        setShowWalletOption(false);
                                        setOpenActivatePopup(true);
                                    } else {
                                        setShowWalletOption(false);
                                        setShow24HrsPopup(true);
                                    }
                                }}
                            >
                                <ItemsContainer>
                                    <TransactionPinSvg />
                                    <ItemText>Forgot transaction PIN</ItemText>
                                </ItemsContainer>
                                <ItemsContainer>
                                    <RightArrowSvg />
                                </ItemsContainer>
                            </ShoppingOptionItemWrapper>
                            <ShoppingOptionItemWrapper
                                onClick={() => {
                                    if (!user.hasTransactionPin && status === 'INACTIVE') {
                                        setShowWalletOption(false);
                                        setOpenActivatePopup(true);
                                    } else {
                                        history.push('/user/update_transaction_pin');
                                    }
                                }}
                            >
                                <ItemsContainer>
                                    <TransactionPinSvg />
                                    <ItemText>Update transaction PIN</ItemText>
                                </ItemsContainer>
                                <ItemsContainer>
                                    <RightArrowSvg />
                                </ItemsContainer>
                            </ShoppingOptionItemWrapper>
                        </ShoppingOptionWrapper>
                    </BlurBackground>
                )}
                {show24HrsPopup && (
                    <BlurBackground position="fixed">
                        <Backdrop onClick={() => setShow24HrsPopup(false)} />
                        <ModalContainer>
                            <TimeLimitWrapper>
                                <StyledTimeLimitIcon />
                                <TimeLimit> 24 hour limit </TimeLimit>
                            </TimeLimitWrapper>
                            <TextWrapper>
                                <Text1>Your LOGIN PIN has been changed within the past 24 hours</Text1>
                                <Text2>
                                    This prevents you from using it to generate a new transaction PIN, try again later.
                                </Text2>
                                <RippleButton
                                    top={'32px'}
                                    bottom={'32px'}
                                    onClick={() => {
                                        setShow24HrsPopup(false);
                                    }}
                                >
                                    Try Later
                                </RippleButton>
                            </TextWrapper>
                        </ModalContainer>
                    </BlurBackground>
                )}
                <DashboardWrapper>
                    <HeaderWrapper>
                        <PageTitle>Wallet</PageTitle>
                        <RightBar>
                            <NotificationBell
                                onClick={() => {
                                    if (!features?.notifications.status) return setOpenFeatureFixes(true);
                                    history.push('/user/notifications');
                                }}
                                notifications={notifications}
                            />
                            <ShoppingOptionSvg
                                onClick={() => {
                                    if (role === 'ROLE_AGENT' && agentState !== 'APPROVED') {
                                        setStatusPopup(!statusPopup);
                                        return;
                                    }
                                    if (status === 'INACTIVE') {
                                        setOpenActivatePopup(true);
                                        return;
                                    }

                                    setShowWalletOption(!showWalletOption);
                                }}
                            />
                        </RightBar>
                    </HeaderWrapper>
                    {realms && realms.includes('WALLET REALM') ? (
                        <FlexContainer>
                            <ComingSoonPage />
                        </FlexContainer>
                    ) : (
                        <Fragment>
                            {features?.wallet?.status ? (
                                <FlexContainer>
                                    <WalletBadge2
                                        classNames={classNames}
                                        addMoney={() => buttonAction('/user/wallet_fund', 'walletTopUp')}
                                        sendMoney={() => buttonAction('/user/wallet_account', 'walletTransfer')}
                                        buyAirtime={() =>
                                            buttonAction('/user/wallet/airtime-recharge', 'walletAirtime')
                                        }
                                        payBills={() => buttonAction('/user/wallet/pay-bills', 'walletPayBills')}
                                    />
                                    <Fragment>
                                        {transactions && transactions.length > 0 ? (
                                            <FlexContainer>
                                                <PageSubSectionsTop padding={'0'} margin={'5px 0 7px'}>
                                                    <PageSubSectionsTopTitle>
                                                        Pending Commission
                                                    </PageSubSectionsTopTitle>
                                                    <PageSubSectionsTopLink to={'/user/wallet/commission'}>
                                                        See Breakdown
                                                    </PageSubSectionsTopLink>
                                                </PageSubSectionsTop>
                                                <CommissionContainer>
                                                    <CommissionIcon />
                                                    <CommissionTextContainer>
                                                        <CommissionAmount>
                                                            <CommissionText>Sabi Commissions</CommissionText>
                                                            <CommissionText weight={'bold'}>
                                                                {commission
                                                                    ? formatPrice(commission?.totalCommissionEarned)
                                                                    : '0'}
                                                            </CommissionText>
                                                        </CommissionAmount>
                                                        <CommissionMetaText>
                                                            **Commisions are paid at the end of each month
                                                        </CommissionMetaText>
                                                    </CommissionTextContainer>
                                                </CommissionContainer>

                                                <PageSubSectionsTop>
                                                    <PageSubSectionsTopTitle>
                                                        Transaction History
                                                    </PageSubSectionsTopTitle>
                                                    <PageSubSectionsTopLink to={'/user/wallet_transactions'}>
                                                        View All
                                                    </PageSubSectionsTopLink>
                                                </PageSubSectionsTop>
                                                <List noBorderBottom fullScreen>
                                                    {transactions &&
                                                        transactions
                                                            .sort(
                                                                (a, b) =>
                                                                    new Date(b.createdAt).getTime() -
                                                                    new Date(a.createdAt).getTime(),
                                                            )
                                                            .slice(0, 6)
                                                            .map((transaction, index) => (
                                                                <TransactionCard
                                                                    transaction={transaction}
                                                                    key={index}
                                                                />
                                                            ))}
                                                </List>
                                            </FlexContainer>
                                        ) : (
                                            <EmptyTransactionPage />
                                        )}
                                    </Fragment>
                                </FlexContainer>
                            ) : (
                                <FlexContainer>
                                    <ComingSoonPage
                                        title="Wallet is temporarily unavailable"
                                        subtitle="We are currently working on creating a better experience for you"
                                    />
                                </FlexContainer>
                            )}
                        </Fragment>
                    )}
                </DashboardWrapper>
                <Navigation />

                <ApprovalStatusPopup
                    open={statusPopup}
                    cancel={() => setStatusPopup(!statusPopup)}
                    status={agentState}
                />
                <TermsInfoOverlay
                    open={openTermsOverlay}
                    cancel={() => {
                        setOpenTermsOverlay(!openTermsOverlay);
                    }}
                    confirm={() => {
                        setOpenTermsOverlay(!openTermsOverlay);
                        History.push('/user/account_agency_banking_signup');
                    }}
                />
                <ComingSoon
                    open={openWallet}
                    cancel={() => setOpenWallet(!openWallet)}
                    icon={WalletIcon}
                    title={'Wallet'}
                    subtitle={'You can use the wallet to send money to other users and bank accounts.'}
                    notifyText={'We will notify you as soon as the wallet is ready for use'}
                />
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default WalletTransactions;
