import React, { Fragment, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { TopHeader, Loader, StorefrontAddBankAccount, ConfirmPopupDialog } from '../../../../../components';
import { MenuOptionLogo } from '../../../../../containers/MenuContainer';
import { getAvailableBanksNoAuth } from '../../../../../redux/ducks/account/wallet/actions/bank-account';
import {
    updateBankAccountDetails,
    togglePaymentOptions,
    getShopInfoFromMerchapp,
} from '../../../../../redux/ducks/applications/my-shop/actions/shop';
import { colors } from '../../../../../styles';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import BankIcon from '../../assets/banks.svg';
import CheckedIcon from '../../assets/checkmark2.svg';
import WalletIcon from '../../assets/wallet.svg';
import { Main } from '../../styles';

const Menu = styled.section`
    margin: 16px 0;
    & > ul:not(:last-of-type) {
        margin-bottom: 32px;
    }
`;

const OptionName = styled.h4`
    font-weight: 400;
    color: ${colors.themeTextColor3};
    font-size: 14px;
    margin: 0 16px;
`;

const OptionSubText = styled.h4`
    font-weight: 400;
    color: #212c3d80;
    font-size: 10px;
    margin: 4px 56px 0 16px;
`;

const OptionDeleteText = styled.h4`
    width: fit-content;
    font-weight: 400;
    color: #e02020;
    font-size: 12px;
    margin-left: 16px;
    padding: 8px 8px 0 0;
`;

const OptionWithSubText = styled.div`
    display: flex;
    flex-direction: column;
`;

const MenuOption = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0 8px 1em;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    -webkit-tap-highlight-color: transparent;
    &:not(:first-of-type) {
        border-top: 1px solid ${colors.border.top};
    }
    &:last-of-type {
        border-bottom: 1px solid ${colors.border.bottom};
    }
`;

const Radio = styled.input`
    position: absolute;
    top: 10px;
    right: 16px;
    margin: 0;
    cursor: pointer;
    appearance: none;
    &:focus {
        outline: none;
    }
    &:checked {
        &:after {
            content: url(${CheckedIcon});
            position: absolute;
            top: 0px;
            right: 8px;
        }
    }
`;

const PaymentMethod = () => {
    const dispatch = useDispatch();
    const shops = useSelector((state) => state.applications.myShop.shops);
    const isLoading = useSelector((state) => state.applications.myShop.isLoading);

    const [shop, setShop] = useState(shops[0]);
    const [openAddBankAccount, setOpenAddBankAccount] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const selectedPaymentMethod = (shop && shop?.paymentOption) || '';
    const userHasBank =
        (shop &&
            shop.accountDetails &&
            Object.values(shop.accountDetails).some((value) => value && value.length !== 0)) ||
        false;

    useEffect(() => {
        var shop = shops[0];
        shop && setShop(shop);
    }, [shops, dispatch]);

    useEffect(() => {
        shop && shop.branchId && dispatch(getShopInfoFromMerchapp(shop.id, shop.branchId));
    }, [shop, dispatch]);

    useEffect(() => {
        dispatch(getAvailableBanksNoAuth());
    }, [dispatch]);

    if (isLoading) {
        return <Loader />;
    } else
        return (
            <Fragment>
                <DesktopBackgroundLayout>
                    <TopHeader title={'Payment Method'} />
                    <Main>
                        <Menu>
                            <MenuOption
                                onClick={() => {
                                    if (selectedPaymentMethod !== 'WALLET') {
                                        dispatch(togglePaymentOptions(shop && shop.id, 'WALLET'));
                                    }
                                }}
                            >
                                <MenuOptionLogo icon={WalletIcon} />
                                <OptionName>Sabi Wallet</OptionName>
                                {selectedPaymentMethod && (
                                    <Radio
                                        top={'4px'}
                                        right={'4px'}
                                        name={'wallet'}
                                        type={'radio'}
                                        value={'WALLET'}
                                        checked={selectedPaymentMethod === 'WALLET'}
                                        readOnly
                                    />
                                )}
                            </MenuOption>

                            <MenuOption
                                onClick={() => {
                                    if (userHasBank) {
                                        if (selectedPaymentMethod !== 'BANK') {
                                            dispatch(togglePaymentOptions(shop && shop.id, 'BANK'));
                                        }
                                    } else {
                                        setOpenAddBankAccount(!openAddBankAccount);
                                    }
                                }}
                            >
                                <MenuOptionLogo icon={BankIcon} />
                                <OptionWithSubText>
                                    <OptionName>Bank Account</OptionName>
                                    <OptionSubText>
                                        {userHasBank ? Object.values(shop.accountDetails).join(' | ') : ''}
                                    </OptionSubText>
                                    <OptionDeleteText
                                        onClick={(e) => {
                                            setConfirmDelete(!confirmDelete);
                                            e.stopPropagation();
                                        }}
                                    >
                                        {userHasBank && 'Delete'}
                                    </OptionDeleteText>
                                </OptionWithSubText>
                                {selectedPaymentMethod && (
                                    <Radio
                                        top={'4px'}
                                        right={'4px'}
                                        name={'bank'}
                                        type={'radio'}
                                        value={'BANK'}
                                        checked={selectedPaymentMethod === 'BANK' && userHasBank}
                                        readOnly
                                    />
                                )}
                            </MenuOption>
                        </Menu>
                    </Main>
                    {openAddBankAccount && (
                        <StorefrontAddBankAccount
                            open={openAddBankAccount}
                            cancel={() => setOpenAddBankAccount(!openAddBankAccount)}
                            accountDetails={shop && shop.accountDetails}
                            addBankAccount={(bankAccount) => {
                                setOpenAddBankAccount(!openAddBankAccount);
                                dispatch(updateBankAccountDetails(shop && shop.id, bankAccount));
                            }}
                        />
                    )}
                    <ConfirmPopupDialog
                        open={confirmDelete}
                        title={'Delete bank account'}
                        confirmationText={'Are you sure you want to delete this bank account?'}
                        answers={[
                            {
                                variant: 'No',
                                action: () => setConfirmDelete(!confirmDelete),
                            },
                            {
                                variant: 'Yes',
                                action: () => {
                                    setConfirmDelete(!confirmDelete);
                                    dispatch(
                                        updateBankAccountDetails(
                                            shop && shop.id,
                                            { bankName: '', accountNumber: '', accountName: '' },
                                            selectedPaymentMethod,
                                        ),
                                    );
                                },
                            },
                        ]}
                    />
                </DesktopBackgroundLayout>
            </Fragment>
        );
};

export default PaymentMethod;
