import React from 'react';

import styled from 'styled-components';

import starIconBold from '../../../src/assets/star-bold.png';
import starIconLight from '../../../src/assets/star-light.png';

const ReviewItem = styled.div`
    margin-buttom: 8px;
`;

const ReviewHeader = styled.span`
    padding-right: 7px;
    font-weight: bold;
`;

const StarIcon = styled.img`
    width: 7px;
    margin-left: 5px;
`;

export const CustomerReview = ({ Header, Body, Ratings }) => (
    <ReviewItem>
        <ReviewHeader>{Header}</ReviewHeader>
        {[...Array(Ratings)].map((e, i) => (
            <StarIcon key={i} width="7px" src={starIconBold} />
        ))}
        {[...Array(5 - Ratings)].map((e, i) => (
            <StarIcon key={i} width="7px" src={starIconLight} />
        ))}
        <p>{Body}</p>
    </ReviewItem>
);
