export const LOADING_STORE = '/actions/storefront_loading_store';
export const POST_STORE_FRONT = '/actions/post store front';
export const LOADING_STORE_PRODUCTS = '/actions/loading store front products';
export const FETCH_STORE_PRODUCTS = '/actions/fetch store front products';
export const FETCH_STORE_PRODUCT = '/actions/fetch store front product';
export const FETCH_STORE = '/actions/fetch store';
export const STOREFRONT_PRODUCT_LIST = '/actions/storefront product list';
export const STOREFRONT_PRODUCT = '/actions/storefront product';
export const LOADING = 'actions/loading';
export const DELETE_PRODUCT = 'actions/delete_product';
export const FETCH_ORDERS = 'actions/ fetch orders';
export const FETCH_ORDERS_SUMMARY = 'actions/ fetch orders summary';
export const FETCH_MERCHANT_ORDER = '/actions/ fetch merchant order';
export const FETCH_COUNTRY_BY_NAME = '/actions/fetch country by name';
export const FETCH_STATES_BY_ID = '/actions/fetch states by id';
export const FETCH_LGA_BY_STATE = '/actions/fetch lga by state';
export const FETCH_STORE_PUBLIC = '/actions/ fetch store public';
export const FETCH_STORE_LINK = '/actions/ fetch store link';
export const FETCH_PRODUCT_PUBLIC = '/actions/ fetch product public';
export const POST_ORDER = '/actions/ post order';
export const SAVE_LAST_ORDER_PLACED = '/actions/save last order placed';
export const FETCH_CUSTOMER_ORDERS = '/actions/ fetch customer order';
