import React, { Fragment, useState } from 'react';

import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { TopHeader, PageLogo, RippleButton, SelectBox, FileInput3, UploadPicture } from '../../../../../components';
import { ImageCropper } from '../../../../../components/popup/image-cropper';
import { Message, PageTitleWrapper, PageTitle, PageSubTitle } from '../../../../../containers/MessageContainer';
import {
    ScreenContainer,
    PageProgressWrap,
    PageProgressLine,
    PageProgressStep,
} from '../../../../../containers/ScreenContainer';
import { sendUserDocumentsOnRegistration } from '../../../../../redux/ducks/auth/signup/agent/actions';
import { uploadPassportOnboarding } from '../../../../../redux/ducks/user/actions';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';

import { AgentIdentityValidationSchema } from './AgentIdentityValidationSchema';
import UserIdentity from './assets/agent_identity.svg';
import Avatar from './assets/avatar.svg';

const IdentityWrapper = styled.div`
    padding-bottom: 24px;
`;

const InputBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const AgentIdentityCheck = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loading = useSelector((state) => state.auth.signup.agent.isLoading);
    const userId = useSelector((state) => state.user.userId);

    const [tempAvatar, setTempAvatar] = useState('');
    const [avatar, setAvatar] = useState('');

    const [showCropper, setShowCropper] = useState(false);

    const pAction = (image) => {
        const url = URL.createObjectURL(image);
        setShowCropper(true);
        setTempAvatar(url);
    };

    const onCropperCancel = () => {
        setShowCropper(false);
        setTempAvatar('');
    };

    const onCropperSave = async (url) => {
        if (url) {
            setShowCropper(false);
            uploadFile(url);
        } else {
            toast.error('Image is still processing, wait for a sec...');
        }
    };

    const uploadFile = async (uri) => {
        setTempAvatar(uri);
        setAvatar('');

        const url = await dispatch(uploadPassportOnboarding(uri, 'PassportPhotograph'));
        if (url) {
            setAvatar(url);
        } else {
            setAvatar('');
        }
    };

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                {showCropper && <ImageCropper avatar={tempAvatar} onCancel={onCropperCancel} onSave={onCropperSave} />}
                <TopHeader title={'Agent Account'}>
                    <PageProgressWrap>
                        <PageProgressStep>3</PageProgressStep>/6
                    </PageProgressWrap>
                    <PageProgressLine lineWidth={'45%'} />
                </TopHeader>

                <ScreenContainer>
                    <PageLogo
                        top={'26px'}
                        margin={'auto'}
                        borderRadius={'0'}
                        Icon={UserIdentity}
                        iconWidth="72px"
                        iconHeight="72px"
                    />
                    <PageTitleWrapper mtop={'10px'} mbottom={'35px'}>
                        <PageTitle size={'16px'} top={'16px'} bottom={'4px'}>
                            Identification
                        </PageTitle>
                        <PageSubTitle margin={'0'}>Let’s find out more about you</PageSubTitle>
                    </PageTitleWrapper>

                    <Message weight={400} lineHeight={'21px'} align={'center'} bottom={'16px'}>
                        Upload a passport photograph of yourself.
                    </Message>

                    <UploadPicture
                        text={tempAvatar ? 'Tap to change image' : 'Tap to upload image'}
                        pictureAction={pAction}
                        defaultPicture={Avatar}
                        showCropper={showCropper}
                        picture={tempAvatar}
                    />

                    <Formik
                        initialValues={{
                            passportPhotograph: 'PassportPhotograph',
                            avatar: '',
                            selectedDocument: '',
                            selectedDocumentUrl: '',
                        }}
                        validationSchema={AgentIdentityValidationSchema}
                        onSubmit={async (values) => {
                            const res = await dispatch(
                                sendUserDocumentsOnRegistration(
                                    userId,
                                    avatar,
                                    values.passportPhotograph,
                                    values.selectedDocumentUrl,
                                    values.selectedDocument,
                                ),
                            );
                            res && history.push('/user/create_agent_bank_account');
                        }}
                    >
                        {({ values, handleChange, setFieldValue }) => (
                            <Form encType="multipart/form-data">
                                <InputBlock>
                                    <Message top={'0px'} weight={400} lineHeight={'21px'} align={'left'} bottom={'8px'}>
                                        Select a means of identification and upload the document.
                                    </Message>

                                    <SelectBox
                                        name="selectedDocument"
                                        placeholder={'Select a means of identification'}
                                        value={values.selectedDocument}
                                        handleChange={handleChange}
                                        options={[
                                            {
                                                value: 'driversLicense',
                                                label: "Driver's License",
                                            },
                                            {
                                                value: 'personalId',
                                                label: 'Personal ID',
                                            },
                                            {
                                                value: 'workId',
                                                label: 'Work ID',
                                            },
                                        ]}
                                    />
                                    {values.selectedDocument && (
                                        <IdentityWrapper>
                                            <Message weight={400} lineHeight={'21px'} align={'left'} bottom={'8px'}>
                                                Upload your means of identification
                                            </Message>
                                            <FileInput3
                                                mimeType={'image/*'}
                                                name="selectedDocumentUrl"
                                                compressFile
                                                value={values.selectedDocumentUrl}
                                                label={values.selectedDocument}
                                                handleChange={(value) => {
                                                    setFieldValue('selectedDocumentUrl', value);
                                                }}
                                            />
                                        </IdentityWrapper>
                                    )}

                                    <RippleButton
                                        type="submit"
                                        disabled={
                                            !values.selectedDocument ||
                                            !values.selectedDocumentUrl ||
                                            !avatar ||
                                            loading
                                        }
                                    >
                                        {loading ? 'loading' : 'save & continue'}
                                    </RippleButton>
                                </InputBlock>
                            </Form>
                        )}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default AgentIdentityCheck;
