/* eslint-disable no-unused-vars */
import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { useMachine } from '@xstate/react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { createMachine } from 'xstate';

import {
    InputWithOnchange,
    PasswordGroup,
    RippleButton,
    SelectBox,
    SuccessPage,
    TopHeader,
} from '../../../../../components';
import animationData from '../../../../../components/badges/wallet-badge2/assets/balance_loader.json';
import { LottieControl } from '../../../../../components/lottie';
import { PopUp, PopUpContent, PopUpHeader, ConfirmButton } from '../../../../../components/popup/common';
import { Badge } from '../../../../../containers/BadgeContainer';
import { Message } from '../../../../../containers/MessageContainer';
import { PopUpOverlay } from '../../../../../containers/OverlayContainer';
import { FloatingBottomSection, ScreenContainer } from '../../../../../containers/ScreenContainer';
import { useReasonForBlockingUserWallet } from '../../../../../hooks/useReasonForBlockingUserWallet';
import { getWalletBalance, verifyPin, getPinTrialsLeft } from '../../../../../redux/ducks/account/wallet/actions';
import {
    submitAirtimePurchaseRequest,
    fetchAirtimeTransactionHistory,
    fetchAirtimeProviderServices,
} from '../../../../../redux/ducks/account/wallet/actions/bills-payment';
import { colors } from '../../../../../styles';
import { breakUpFormatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import { formatCurrency } from '../../../../../utils/currency/parseBalance';
import { getInputValues } from '../../../../../utils/inputs/getInputValues';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import { BlockedWalletPopUp } from '../../../transactions/wallet-transactions/blocked-wallet-popup';
import RatingPopup from '../../../transactions/wallet-transactions/rating-popup';
import { CodeCheckFormValidationSchema } from '../wallet-to-wallet/pin/CodeCheckFormValidationSchema';

import { AirtimePurchaseValidationSchema } from './AirtimeRechargeValidationSchema';
import NineMobileLogo from './assets/9-mobile-logo.svg';
import AirtelLogo from './assets/airtel-logo.svg';
import GloLogo from './assets/glo-logo.svg';
import { ReactComponent as InflowIcon } from './assets/inflow.svg';
import MtnLogo from './assets/mtn-logo.svg';
import { ReactComponent as OutflowIcon } from './assets/outflow.svg';
import { ReactComponent as RefreshIcon } from './assets/refresh-icon.svg';
import { ReactComponent as Spinner } from './assets/spinner.svg';
import {
    Title,
    ModifiedTransactionLinkIcon,
    ModifiedDiscountIcon,
    Artwork,
    Subtitle,
    CommissionBlock,
    FormWrapper,
    LinkSelectField,
    SelectTitleValue,
    NetworkProviderDetails,
    Paragraph,
    TransactionDetails,
    AmountTitle,
    Balance,
    Details,
    OutflowAccount,
    FlowTitle,
    SelectTitleText,
    ListTransactionItem,
    NetworkProviderAndAmount,
    PhoneNumber,
    TransactionPinFormWrapper,
    TotalAmountBlock,
    NetworkProviderLogo,
    TransactionWrapper,
    TotalTransactionAmount,
    Dot,
    WalletBalance,
    WalletBalanceTitle,
    SelectedNetworkProvider,
    SelectedNetworkProviderLogo,
    LoadingContainer,
    TransactionIcon,
} from './assets/styles';
import { getCommissionForPurchaseAmount } from './getCommission';

const FlexContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: ${({ alignItems }) => alignItems || 'center'};
    justify-content: ${({ content }) => content || 'center'};
`;

const WalletBalanceWrapper = styled.span`
    display: flex;
`;

const WalletBalanceAmount = styled.h3`
    margin-left: ${({ left }) => left || null};
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: ${colors.white};
`;

const TopRightCorner = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

const Header = styled(PopUpHeader)`
    font-weight: 700;
    line-height: 21px;
`;

const PopUpConfirmButton = styled(ConfirmButton)`
    background-color: ${({ background }) => background || colors.popup.cancelButton};
    color: ${({ color }) => color || colors.deepBlue};
    font-size: 16px;
    font-weight: 500;
    padding: 16px;
    height: inherit;
    border: ${({ border }) => border || null};
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || null};
`;

const networkProviders = [
    {
        label: 'Airtel',
        icon: <AirtelLogo />,
        value: 'AIRTEL',
    },
    { label: 'MTN', icon: <MtnLogo />, value: 'MTN' },
    { label: 'Glo', icon: <GloLogo />, value: 'GLO' },
    { label: '9Mobile', icon: <NineMobileLogo />, value: '9MOBILE' },
];

const networkProviderLogos = new Map([
    ['AIRTEL', AirtelLogo],
    ['MTN', MtnLogo],
    ['GLO', GloLogo],
    ['9MOBILE', NineMobileLogo],
]);

const pages = {
    BENEFICIARY: 'BENEFICIARY',
    CONFIGURATION: 'CONFIGURATION',
    CONFIRMATION: 'CONFIRMATION',
};

// This machine is completely decoupled from React
const changePageState = createMachine({
    id: 'changePage',
    initial: pages.BENEFICIARY,
    states: {
        [pages.BENEFICIARY]: {
            on: { NEXT: pages.CONFIGURATION, GOTO_CONFIRMATION: pages.CONFIRMATION },
        },
        [pages.CONFIGURATION]: {
            on: { NEXT: pages.CONFIRMATION, BACK: pages.BENEFICIARY },
        },
        [pages.CONFIRMATION]: {
            on: {
                BACK: pages.CONFIGURATION,
            },
        },
    },
});

// when there is a range per network provider
// just put them in here and validation will run
const rangeForNetworkProviders = new Map([
    ['AIRTEL', { min: 100, max: 20000 }],
    ['MTN', { min: 100, max: 20000 }],
    ['GLO', { min: 100, max: 20000 }],
    ['9MOBILE', { min: 100, max: 20000 }],
]);

const PinTrialsLeft = styled.div`
    color: ${colors.transactions.failed};
    font-weight: 400;
    font-size: 10px;
    padding-top: 4px;
`;

export function AirtimeRecharge() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [currentPage, send] = useMachine(changePageState);
    const transactions = useSelector((state) => state.account.transactions.transactionsList);
    const successfulTransactions = transactions.filter((x) => x.transactionStatus === 'SUCCESSFUL');
    const walletBalance = useSelector((state) => state.account.wallet.balance);
    const country = useSelector((state) => state.user.country);
    const [transaction, setTransaction] = useState({ amount: '0', phoneNumber: '', networkProvider: '' });
    const [transactionStatus, setTransactionStatus] = useState('');
    const [openRating, setOpenRating] = useState(false);
    const airtimeProviderServices = useSelector((state) => state.account.wallet?.airtimeProviderServices) || [];
    const walletErrorMessage = useSelector((state) => state.account.wallet?.errorMsg) || '';
    const walletPhoneNumber = useSelector((state) => state.user.msisdn);
    const user = useSelector((state) => state.user);

    useEffect(() => {
        // we are making this request already on the previous page
        // but for whatever reason, if it is not available, we will dispatch here again

        if (airtimeProviderServices.length === 0) {
            dispatch(fetchAirtimeProviderServices());
        }
    }, [dispatch, airtimeProviderServices]);

    const updateTransaction = (values) => {
        setTransaction(values);
        send('NEXT');
    };

    const updateRecentTransaction = (values) => {
        setTransaction(values);
        send('GOTO_CONFIRMATION');
    };

    const updateBeneficiaryPhoneNumber = (phoneNumber = '') => {
        setTransaction({ ...transaction, phoneNumber });
        send('NEXT');
    };

    const handleRecentTransactions = (amount, phoneNumber, networkProvider) => {
        setTransaction({ ...transaction, amount, phoneNumber, networkProvider });
    };

    const handleTransactionSubmission = async () => {
        const { amount, phoneNumber, networkProvider } = transaction;
        const { email } = user;

        const requestPayload = {
            amount,
            customerEmail: email,
            recipientPhoneNumber: phoneNumber,
            serviceName: networkProvider,
        };

        const purchaseWasSuccessful = await dispatch(submitAirtimePurchaseRequest(requestPayload));

        if (purchaseWasSuccessful) {
            setTransactionStatus('success');
            setOpenRating(!openRating);
            return;
        }

        setTransactionStatus('failed');
    };

    const handleBackClick = () => {
        if (currentPage.value === pages.CONFIGURATION) {
            setTransaction({ amount: '', phoneNumber: '', networkProvider: '' });
        }

        if (currentPage.value === pages.CONFIGURATION || currentPage.value === pages.CONFIRMATION) {
            send('BACK');
            history.goBack();
            return;
        }
    };

    const withBlueBackground = () => {
        if (currentPage.value === pages.BENEFICIARY || currentPage.value === pages.CONFIGURATION) return false;

        return true;
    };

    const handleAirtimeHeaderTitle = () => {
        if (currentPage.value === pages.BENEFICIARY) {
            return 'Buy Airtime';
        } else {
            return transaction.phoneNumber === walletPhoneNumber ? 'Buy Airtime for Myself' : 'Buy Airtime';
        }
    };

    return (
        <DesktopBackgroundLayout style={{ background: '#227EFF' }} withBlueBackground={withBlueBackground()}>
            <div style={{ visibility: transactionStatus === 'success' ? 'visible' : 'hidden' }}>
                <SuccessPage
                    title="Transaction Successful"
                    subtitle="Your payment was successful"
                    doneText="Done"
                    detailText="View transaction details"
                    showDetailButton={false}
                    onDoneClick={() => history.push('/user/wallet_index')}
                    onDetailClick={() => history.push('/user/wallet_index')}
                />
                {(transactions.length === 1 || transactions.length % 10 === 0) && (
                    <div style={{ visibility: openRating ? 'visible' : 'hidden' }}>
                        <RatingPopup
                            refId={successfulTransactions[0]?.transactionReference}
                            setOpen={() => setOpenRating(!openRating)}
                        />
                    </div>
                )}
            </div>

            <div style={{ visibility: transactionStatus === 'failed' ? 'visible' : 'hidden' }}>
                <SuccessPage
                    title="Transaction failed!"
                    subtitle="We couldn't complete your transaction. Kindly try again"
                    doneText="Try Again"
                    successfull={false}
                    onDoneClick={() => {
                        setTransactionStatus('');
                        toast.error(walletErrorMessage);
                    }}
                />
            </div>

            {transactionStatus === '' && (
                <>
                    <TopHeader
                        backgroundColor={withBlueBackground() ? colors.deepBlue : ''}
                        title={handleAirtimeHeaderTitle()}
                        backAction={handleBackClick}
                        backLink={
                            currentPage.value === pages.CONFIGURATION
                                ? '#'
                                : currentPage.value === pages.CONFIRMATION
                                ? '#'
                                : null
                        }
                        color={withBlueBackground() ? colors.white : colors.boldDark}
                        noBorderBottom
                    />
                    <ScreenContainer
                        style={{ position: withBlueBackground() ? 'absolute' : 'static' }}
                        paddingBottom={'21px'}
                    >
                        {currentPage.value === pages.BENEFICIARY && (
                            <BeneficiarySection
                                walletPhoneNumber={walletPhoneNumber}
                                updateBeneficiaryPhoneNumber={updateBeneficiaryPhoneNumber}
                                transactionDetails={transaction}
                                handleRecentTransactions={handleRecentTransactions}
                                onSubmitClick={updateRecentTransaction}
                            />
                        )}
                        {currentPage.value === pages.CONFIGURATION && (
                            <AmountConfiguration
                                country={country}
                                onSubmit={updateTransaction}
                                walletBalance={walletBalance}
                                transactionDetails={transaction}
                            />
                        )}
                        {currentPage.value === pages.CONFIRMATION && (
                            <TransactionConfirmation
                                transactionDetails={transaction}
                                walletBalance={walletBalance}
                                handleTransactionSubmission={handleTransactionSubmission}
                            />
                        )}
                    </ScreenContainer>
                </>
            )}
        </DesktopBackgroundLayout>
    );
}

export function VerifyTransactionPin({ handleTransactionSubmission }) {
    const [pin, setPin] = useState('');
    const [value, setValue] = useState('');
    const dispatch = useDispatch();
    const [pinTrialsLeft, setPinTrialsLeft] = useState({});
    const [wrongPin, setWrongPin] = useState('');
    const reasonForBlocking = useReasonForBlockingUserWallet();

    const pinTrials = () => {
        dispatch(getPinTrialsLeft()).then((res) => {
            res && setPinTrialsLeft(res);
        });
    };

    useEffect(() => {
        pinTrials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const verifyTransactionPin = useCallback(async () => {
        await pinTrials();
        // make the call to verify PIN here
        const pinVerificationSuccess = await dispatch(verifyPin(pin));
        if (pinVerificationSuccess) {
            handleTransactionSubmission();
            return;
        } else {
            setWrongPin(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pin]);

    const handlePinInput = (event, setFieldValue) => {
        const pinInput = getInputValues('transactionPin');
        setValue(event.target.value);
        setFieldValue('transactionPin', pinInput);
        setPin(pinInput);
    };

    useEffect(() => {
        if (String(pin).length === 4) {
            verifyTransactionPin();
        }
    }, [pin, verifyTransactionPin]);

    return (
        <ScreenContainer height="100vh">
            {(pinTrialsLeft?.pinTriesLeft === 0 || pinTrialsLeft.status === 'BLOCKED') && (
                <BlockedWalletPopUp
                    showCancelIcon={false}
                    position={'absolute'}
                    left={'-9%'}
                    reason={reasonForBlocking?.reason}
                />
            )}
            <TransactionPinFormWrapper>
                <Message color={colors.boldDark} top={'0'} bottom={'4px'} size={'10px'}>
                    Enter Transaction PIN to continue
                    <Formik
                        initialValues={{
                            transactionPin: '',
                        }}
                        validationSchema={CodeCheckFormValidationSchema}
                    >
                        {({ touched, valid, errors, setFieldValue }) => (
                            <PasswordGroup
                                count={4}
                                startIndex={1}
                                type={'password'}
                                valid={valid}
                                errors={errors}
                                name={'transactionPin'}
                                touched={touched}
                                align={'center'}
                                enteredValue={value || ''}
                                handleChange={(event) => handlePinInput(event, setFieldValue)}
                            />
                        )}
                    </Formik>
                    <PinTrialsLeft>
                        {wrongPin && 'INCORRECT PIN. '}
                        {` You have ${pinTrialsLeft?.pinTriesLeft} 
                            ${pinTrialsLeft?.pinTriesLeft < 3 ? 'more' : ''}
                            ${pinTrialsLeft?.pinTriesLeft > 1 ? 'trials' : 'trial'}`}
                    </PinTrialsLeft>
                </Message>
            </TransactionPinFormWrapper>
        </ScreenContainer>
    );
}

function TransactionConfirmation({ transactionDetails, walletBalance, handleTransactionSubmission }) {
    const isLoading = useSelector((state) => state.account.wallet.isLoading);
    const { networkProvider } = transactionDetails;

    return (
        <>
            <TransactionDetails>
                <NetworkProviderDetails>
                    <Artwork>
                        <OutflowIcon />
                        <ModifiedTransactionLinkIcon />
                        <InflowIcon />
                    </Artwork>
                    <Details>
                        <TransactionWrapper>
                            <FlowTitle>From</FlowTitle>
                            <OutflowAccount>My Wallet</OutflowAccount>
                            <Balance>Bal: {formatCurrency(walletBalance)}</Balance>
                        </TransactionWrapper>

                        <TransactionWrapper>
                            <FlowTitle>To</FlowTitle>
                            <NetworkProviderAndAmount>
                                <NetworkProviderLogo src={networkProviderLogos.get(networkProvider)} />
                                <TransactionWrapper>
                                    <PhoneNumber>{transactionDetails.phoneNumber}</PhoneNumber>
                                    <Paragraph>Amount: {formatCurrency(transactionDetails.amount)}</Paragraph>
                                </TransactionWrapper>
                            </NetworkProviderAndAmount>
                        </TransactionWrapper>
                    </Details>
                </NetworkProviderDetails>

                <TotalAmountBlock>
                    <AmountTitle>Amount</AmountTitle>
                    <TotalTransactionAmount>{formatCurrency(transactionDetails.amount)}</TotalTransactionAmount>
                </TotalAmountBlock>
            </TransactionDetails>

            <VerifyTransactionPin
                transactionDetails={transactionDetails}
                handleTransactionSubmission={handleTransactionSubmission}
            />

            {isLoading ? (
                <LoadingContainer>
                    <Spinner />
                </LoadingContainer>
            ) : null}
        </>
    );
}

function AmountConfiguration({ country, onSubmit, walletBalance, transactionDetails }) {
    const dispatch = useDispatch();
    const fetchingWalletBalance = useSelector((state) => state.account.wallet.fetchingWalletBalance);
    const reasonForBlocking = useReasonForBlockingUserWallet();
    const formHasError = (errors) => Object.values(errors).some((error) => error.length > 0);
    const [pinTrialsLeft, setPinTrialsLeft] = useState('');
    const hideBalance = useSelector((state) => state.account.wallet.hideBalance);
    const balance = useSelector((state) => state.account.wallet.balance);
    const airtimeProviderServices = useSelector((state) => state.account.wallet?.airtimeProviderServices) || [];
    const [amount, setAmount] = useState([]);

    const handleAmountInput = (event, networkProvider, setFieldValue, setFieldError, setFieldTouched) => {
        const {
            target: { value: inputValue = 0 },
        } = event;

        const value = parseInt(inputValue.replace(/\D/g, '') || 0, 10);
        setFieldValue('amount', value, false);
        validateAmount(networkProvider, value, setFieldError, setFieldTouched);
    };

    const validateAmount = (networkProvider, amount, setFieldError, setFieldTouched) => {
        const { min, max } = rangeForNetworkProviders.get(networkProvider);

        setFieldTouched('amount', true, false);

        if (!amount) {
            setFieldError('amount', 'Please enter a valid amount');

            return;
        }

        if (amount > walletBalance) {
            setFieldError('amount', 'Insufficient balance!');
            return false;
        }

        if (amount < min) {
            setFieldError('amount', `Amount must be at least ${formatCurrency(min, 'NG')}`);

            return false;
        } else if (amount > max) {
            setFieldError('amount', `The maximum amount is ${formatCurrency(max, 'NG')}`);
            return false;
        } else {
            setFieldError('amount', '');
            return true;
        }
    };

    const PinTrials = () => {
        dispatch(getPinTrialsLeft()).then((res) => {
            res && setPinTrialsLeft(res);
        });
    };

    useEffect(() => {
        dispatch(getWalletBalance());
        PinTrials();
        setAmount(breakUpFormatPrice(balance || 0));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <Fragment>
            {/* {(pinTrialsLeft?.pinTriesLeft === 0 || pinTrialsLeft.status === 'BLOCKED') && (
                <BlockedWalletPopUp
                    showCancelIcon={false}
                    position={'absolute'}
                    left={'-4%'}
                    reason={reasonForBlocking?.reason}
                />
            )} */}
            <Title>How much airtime would you like to buy?</Title>
            <Subtitle margin={'0 0 24px'}>Kindly provide the following details for your recharge.</Subtitle>

            <Badge
                boxShadow
                height="96px"
                background={colors.background.walletBadge}
                backgroundImage={'linear-gradient(94.68deg, #227eff 27.32%, #7e42f5 72.68%)'}
                justifyContent="center"
                bottom="8px"
            >
                <FlexContainer>
                    <WalletBalanceTitle>Wallet Balance</WalletBalanceTitle>
                    {hideBalance ? (
                        <WalletBalanceAmount>
                            <Dot />
                            <Dot />
                            .
                            <Dot />
                            <Dot />
                        </WalletBalanceAmount>
                    ) : (
                        <WalletBalanceWrapper>
                            <WalletBalance size={'16px'} lineHeight={'24px'} marginLeft={'0'}>
                                {amount[0]}
                            </WalletBalance>
                            <WalletBalanceAmount>{amount[1]}</WalletBalanceAmount>
                            <WalletBalanceAmount>{amount[2]}</WalletBalanceAmount>
                        </WalletBalanceWrapper>
                    )}
                </FlexContainer>
                {fetchingWalletBalance && (
                    <TopRightCorner>
                        <LottieControl
                            height={40}
                            width={40}
                            play={fetchingWalletBalance}
                            animationData={animationData}
                        />
                    </TopRightCorner>
                )}
            </Badge>
            <FormWrapper>
                <Formik
                    initialValues={{
                        networkProvider: transactionDetails.networkProvider || '',
                        phoneNumber: transactionDetails.phoneNumber || '',
                        amount: transactionDetails.amount || '',
                    }}
                    validationSchema={AirtimePurchaseValidationSchema}
                    onSubmit={(values, { setFieldError, setFieldTouched }) => {
                        const amountIsValid = validateAmount(
                            values.networkProvider,
                            values.amount,
                            setFieldError,
                            setFieldTouched,
                        );

                        if (amountIsValid) {
                            onSubmit(values);
                        }
                    }}
                >
                    {({ values, errors, touched, setFieldValue, initialValues, setFieldError, setFieldTouched }) => (
                        <Form>
                            <FormWrapper>
                                <SelectBox
                                    placeholder={'Network Provider'}
                                    value={values.networkProvider}
                                    options={networkProviders}
                                    handleChange={(selected) => {
                                        setFieldValue('networkProvider', selected.value, false);
                                    }}
                                    valid={`${!touched.networkProvider && !errors.networkProvider}`}
                                    error={touched && touched.networkProvider && errors && errors.networkProvider}
                                >
                                    <SelectedNetworkProvider>
                                        <SelectedNetworkProviderLogo
                                            src={networkProviderLogos.get(values.networkProvider)}
                                            alt={values.networkProvider}
                                        />
                                        {values.networkProvider}
                                    </SelectedNetworkProvider>
                                </SelectBox>

                                <InputWithOnchange
                                    label={'Phone Number'}
                                    type={'text'}
                                    inputMode={'tel'}
                                    value={values.phoneNumber}
                                    placeholder={'Phone Number'}
                                    name="phoneNumber"
                                    valid={`${touched.phoneNumber && !errors.phoneNumber}`}
                                    autoComplete={'off'}
                                    errors={touched.phoneNumber && errors && errors.phoneNumber}
                                    bottom="0px"
                                    top="0"
                                    height={'56px'}
                                    maxLength={11}
                                    background="#F2F5FA"
                                    onChange={(e) =>
                                        setFieldValue('phoneNumber', e.target.value.replace(/\s/g, ''), true)
                                    }
                                    noClearButton={true}
                                    disabled={values.networkProvider === ''}
                                    initialValues={initialValues}
                                />

                                <InputWithOnchange
                                    label={'Amount'}
                                    width="100%"
                                    borderRadius="4px"
                                    country={country}
                                    type={'text'}
                                    placeholder={'Amount'}
                                    autoComplete={'off'}
                                    inputMode={'numeric'}
                                    name="amount"
                                    bottom="0"
                                    height={'56px'}
                                    errors={errors && errors.amount}
                                    disabled={values.networkProvider === ''}
                                    value={formatCurrency(values.amount, 'NG')}
                                    onChange={(event) =>
                                        handleAmountInput(
                                            event,
                                            values.networkProvider,
                                            setFieldValue,
                                            setFieldError,
                                            setFieldTouched,
                                        )
                                    }
                                    noClearButton={true}
                                    initialValues={initialValues}
                                />

                                <CommissionBlock>
                                    <Paragraph>
                                        <ModifiedDiscountIcon />
                                        Commission earned
                                    </Paragraph>
                                    <Paragraph>
                                        {formatCurrency(
                                            getCommissionForPurchaseAmount(
                                                airtimeProviderServices,
                                                values.networkProvider,
                                                values.amount,
                                            ),
                                        )}
                                    </Paragraph>
                                </CommissionBlock>
                                {!values.amount ? (
                                    <Subtitle margin={'-10px 0 0'} size={'12px'}>
                                        Earn money for every recharge you make on sabi
                                    </Subtitle>
                                ) : null}

                                <FloatingBottomSection>
                                    <RippleButton
                                        type="submit"
                                        top={'2px'}
                                        backgroundColor={colors.deepBlue}
                                        disabled={values.networkProvider === '' || formHasError(errors)}
                                    >
                                        Continue
                                    </RippleButton>
                                </FloatingBottomSection>
                            </FormWrapper>
                        </Form>
                    )}
                </Formik>
            </FormWrapper>
        </Fragment>
    );
}

function BeneficiarySection({
    updateBeneficiaryPhoneNumber,
    walletPhoneNumber,
    handleRecentTransactions,
    transactionDetails,
    onSubmitClick,
}) {
    const dispatch = useDispatch();
    const fetchingWalletBalance = useSelector((state) => state.account?.wallet?.fetchingWalletBalance);
    const hideBalance = useSelector((state) => state.account?.wallet?.hideBalance);
    const balance = useSelector((state) => state.account?.wallet?.balance);
    const [amount, setAmount] = useState([]);
    const [repeatPopUp, setRepeatPopUp] = useState(false);
    const previousTransactions = useSelector((state) => state.account?.wallet?.airtimePurchaseTransactions);
    const airtimeProviderServices = useSelector((state) => state.account.wallet?.airtimeProviderServices) || [];
    const userId = useSelector((state) => state.user.userId);

    useEffect(() => {
        dispatch(getWalletBalance());
        setAmount(breakUpFormatPrice(balance || 0));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchAirtimeTransactionHistory(userId));
    }, [dispatch, userId]);

    return (
        <Fragment>
            <Badge
                boxShadow
                height="96px"
                background={colors.background.walletBadge}
                backgroundImage={'linear-gradient(94.68deg, #227eff 27.32%, #7e42f5 72.68%)'}
                justifyContent="center"
                bottom="8px"
                top="24px"
            >
                <FlexContainer>
                    <WalletBalanceTitle bottom={'0'}>Wallet Balance</WalletBalanceTitle>
                    {hideBalance ? (
                        <WalletBalanceAmount>
                            <Dot />
                            <Dot />
                            .
                            <Dot />
                            <Dot />
                        </WalletBalanceAmount>
                    ) : (
                        <WalletBalanceWrapper>
                            <WalletBalance size={'16px'} lineHeight={'24px'} marginLeft={'0'}>
                                {amount[0]}
                            </WalletBalance>
                            <WalletBalanceAmount>{amount[1]}</WalletBalanceAmount>
                            <WalletBalanceAmount>{amount[2]}</WalletBalanceAmount>
                        </WalletBalanceWrapper>
                    )}
                </FlexContainer>
                {fetchingWalletBalance && (
                    <TopRightCorner>
                        <LottieControl
                            height={40}
                            width={40}
                            play={fetchingWalletBalance}
                            animationData={animationData}
                        />
                    </TopRightCorner>
                )}
            </Badge>
            <Title margin={'30px 0 24px'}>Who are you purchasing airtime for?</Title>

            <FormWrapper>
                <Formik>
                    <Form>
                        <FormWrapper gap={'8px'}>
                            <LinkSelectField onClick={() => updateBeneficiaryPhoneNumber(walletPhoneNumber)}>
                                <SelectTitleText>Myself</SelectTitleText>
                                <SelectTitleValue>{walletPhoneNumber}</SelectTitleValue>
                            </LinkSelectField>
                            <LinkSelectField onClick={() => updateBeneficiaryPhoneNumber('')}>
                                <SelectTitleText>Others</SelectTitleText>
                            </LinkSelectField>
                        </FormWrapper>
                    </Form>
                </Formik>
            </FormWrapper>
            <>
                <Title margin={'32px 0 16px'}>Recent Transactions</Title>
                {previousTransactions?.length ? (
                    previousTransactions?.map((transaction, index) => {
                        return (
                            <ListTransactionItem align={'center'} background={'#EDF2F7'} key={index}>
                                <NetworkProviderLogo
                                    width={'40px'}
                                    height={'40px'}
                                    margin={'0 12px 0 0'}
                                    src={networkProviderLogos.get(transaction.serviceName)}
                                />
                                <TransactionWrapper>
                                    <SelectTitleText>{transaction?.destinationNumber}</SelectTitleText>
                                    <SelectTitleValue>{transaction?.amount}</SelectTitleValue>
                                </TransactionWrapper>
                                <TransactionIcon>
                                    <RefreshIcon
                                        onClick={() => {
                                            setRepeatPopUp(true);
                                            handleRecentTransactions(
                                                transaction?.amount,
                                                transaction?.customerPhoneNumber,
                                                transaction.serviceName,
                                            );
                                        }}
                                    />
                                </TransactionIcon>
                            </ListTransactionItem>
                        );
                    })
                ) : (
                    <Subtitle margin={'-5px 0 0'} size={'12px'}>
                        You have no recent airtime transaction
                    </Subtitle>
                )}
            </>
            {repeatPopUp && (
                <PopUp open={'open'} padding={'8px 0 0'} maxWidth={'375px'}>
                    <PopUpOverlay></PopUpOverlay>
                    <PopUpContent radius={'8px 8px 0 0'} width={'100%'} padding={'24px 16px 32px'}>
                        <Header align={'left'} size={'14px'} padding={'0px 8px 32px'}>
                            Purchase {formatCurrency(transactionDetails?.amount, 'NG')}{' '}
                            {transactionDetails.networkProvider} for {transactionDetails?.phoneNumber}?
                        </Header>
                        <CommissionBlock top={'0'}>
                            <Paragraph>
                                <ModifiedDiscountIcon />
                                Commission earned
                            </Paragraph>
                            <Paragraph>
                                {formatCurrency(
                                    getCommissionForPurchaseAmount(
                                        airtimeProviderServices,
                                        transactionDetails.networkProvider,
                                        transactionDetails?.amount,
                                    ),
                                )}
                            </Paragraph>
                        </CommissionBlock>
                        <Subtitle margin={'0px 0 0'} size={'12px'} textAlign={'left'} lineHeight={'18px'}>
                            You will earn{' '}
                            {formatCurrency(
                                getCommissionForPurchaseAmount(
                                    airtimeProviderServices,
                                    transactionDetails.networkProvider,
                                    transactionDetails?.amount,
                                ),
                            )}{' '}
                            in commission
                        </Subtitle>
                        <PopUpConfirmButton
                            border={`1px solid ${colors.deepBlue}`}
                            bottom={'16px'}
                            type="button"
                            onClick={() => setRepeatPopUp(!repeatPopUp)}
                        >
                            No, Cancel
                        </PopUpConfirmButton>
                        <PopUpConfirmButton
                            top={'0'}
                            background={colors.deepBlue}
                            color={colors.white}
                            type="button"
                            onClick={() => {
                                setRepeatPopUp(!repeatPopUp);
                                onSubmitClick(transactionDetails);
                            }}
                        >
                            Yes
                        </PopUpConfirmButton>
                    </PopUpContent>
                </PopUp>
            )}
        </Fragment>
    );
}
