import React, { Fragment, useEffect, useState } from 'react';

import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Loader, TopHeader, PasswordGroup, ResendCodeTimer } from '../../../../components';
import { Message, Title } from '../../../../containers/MessageContainer';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { sendUssd } from '../../../../redux/ducks/auth/check/actions';
import { forgotPasswordCheck } from '../../../../redux/ducks/auth/password/actions';
import { tick, setExpired } from '../../../../redux/ducks/timer/actions';
import { colors } from '../../../../styles';
import { insertZero } from '../../../../utils/inputs/formatPhoneNumber';
import { getInputValues } from '../../../../utils/inputs/getInputValues';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { ReactComponent as VerificationLogoSvg } from '../../check/assets/verificationLogo.svg';

import { ForgotPasswordValidationSchema } from './ForgotPasswordValidationSchema';

const VerificationLogo = styled(VerificationLogoSvg)`
    margin-top: 59px;
    width: 100%;
`;

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const phoneNumber = useSelector((state) => state.user.msisdn);
    const isLoading = useSelector((state) => state.auth.password.isLoading);
    const expired = useSelector((state) => state.timer.expired);
    const counter = useSelector((state) => state.timer.counter);
    const [value, setValue] = useState('');
    const [code, setCode] = useState('');

    useEffect(() => {
        if (code && code.length === 6) {
            dispatch(forgotPasswordCheck(code, 'RESET_LOGIN_PIN'));
        }
    }, [dispatch, code, phoneNumber]);

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader />
                <ScreenContainer>
                    <VerificationLogo />
                    <Title textAlign={'left'} top={'30px'} size="24px" weight="bold" color={colors.boldDark}>
                        Enter OTP
                    </Title>
                    <Message align={'left'} bottom={'24px'} top={'8px'} color={colors.lightDark}>
                        Kindly provide the 6- digit OTP was sent to your phone number{' '}
                        <strong style={{ color: colors.deepBlue }}>{insertZero(phoneNumber)}</strong>
                    </Message>
                    <Formik
                        initialValues={{
                            code: '',
                        }}
                        validationSchema={ForgotPasswordValidationSchema}
                        onSubmit={(values, { resetForm }) => {
                            setTimeout(() => {
                                resetForm({ code: '' });
                                forgotPasswordCheck(values.code);
                            }, 1000);
                        }}
                    >
                        {({ values, errors, valid, touched, setFieldValue }) => (
                            <Form>
                                <Message size={'10px'} align="left">
                                    {' '}
                                    OTP{' '}
                                </Message>
                                <PasswordGroup
                                    count={6}
                                    startIndex={1}
                                    type="number"
                                    valid={valid}
                                    name="code"
                                    align={'center'}
                                    errors={errors}
                                    touched={touched}
                                    enteredValue={value || ''}
                                    handleChange={(event) => {
                                        setValue(event.target.value);
                                        setFieldValue('code', getInputValues('code'));
                                        setCode(getInputValues('code'));
                                    }}
                                />
                                <ResendCodeTimer
                                    showWhatsAppButton={false}
                                    counter={counter}
                                    expired={expired}
                                    sendUssd={() => dispatch(sendUssd())}
                                    setExpired={(condition) => dispatch(setExpired(condition))}
                                    tick={(counterValue) => dispatch(tick(counterValue))}
                                    resendCode={() =>
                                        history.push({
                                            pathname: '/user/password-otp-options',
                                            state: { resend: true },
                                        })
                                    }
                                />
                            </Form>
                        )}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default ForgotPassword;
