import * as Yup from 'yup';

import { containsLettersRegExp } from '../../../../utils/regexp/containsLettersRegExp';

export const MultipleRemitValidationSchema = Yup.object().shape({
    amount: Yup.string()
        .matches(containsLettersRegExp, 'Amount cannot contain letters')
        .required('Required')
        .test('check last character', 'Invalid Amount', function (value) {
            return !value?.endsWith('.');
        }),
    paymentMethodEnum: Yup.string().nullable(),
    receiptBase64String: Yup.string()
        .nullable()
        .when('paymentMethodEnum', {
            is: '1',
            then: Yup.string().required('Please upload a proof of payment'),
        }),
    bankAccount: Yup.string()
        .nullable()
        .when('paymentMethodEnum', {
            is: '1',
            then: Yup.string().required('Please indicate the bank account paid into'),
        }),
    transactionReference: Yup.string()
        .nullable()
        .when('paymentMethodEnum', {
            is: '1',
            then: Yup.string().required('Please enter a transaction reference'),
        }),
});
