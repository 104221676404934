import React, { Fragment, useCallback, useState, useEffect } from 'react';

import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import {
    TopHeader,
    TextInputWithLabel,
    AmountInputWithLabel,
    UploadPicture,
    RippleButton,
    Loader,
} from '../../../../../components';
import { ImageCropper, toDataURL } from '../../../../../components/popup/image-cropper';
import { InputBlock } from '../../../../../containers/InputContainer';
import { FlexContainer, ScreenContainer } from '../../../../../containers/ScreenContainer';
import {
    editStorefrontProduct,
    getStoreByUserId,
} from '../../../../../redux/ducks/applications/store-front/actions/store';
import { colors } from '../../../../../styles';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';

import StorefrontAddProductFailure from './addProductFailure';
import StorefrontAddProductSuccess from './addProductSuccess';
import { AddProductValidationSchema } from './AddProductValidationSchema';
import { ReactComponent as Info } from './assets/info.svg';
import ThresholdPopup from './thresholdPopup';

const AddProductText = styled.div`
    color: ${({ color }) => color || null};
    font-size: ${({ size }) => size || null};
    font-weight: ${({ weight }) => weight || null};
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || null};
`;

const AddProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
`;

const CountParagraph = styled.p`
    color: #718596;
    font-weight: 400;
    font-size: 10px;
    text-align: right;
    padding-right: 20px;
    margin-bottom: 16px;
`;

const ThresholdCover = styled.div`
    position: relative;
`;

const InfoStyle = styled(Info)`
    position: absolute;
    top: 16px;
    right: 16px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 74px;
`;

const StorefrontEditProduct = () => {
    const limit = 20;
    const dispatch = useDispatch();
    const store = useSelector((state) => state.applications.storeFront.store);
    const product = useSelector((state) => state.applications.storeFront.storeFrontSingleproduct);
    const [openThresholdPopup, setOpenThresholdPopup] = useState(false);
    const [altLoading, setAltLoading] = useState(false);
    const [showSuccessPage, setShowSuccessPage] = useState(false);
    const [showFailurePage, setShowFailurePage] = useState(false);
    const [pImages, setPImages] = useState({
        upload0: product?.images[product?.images?.length - 3]?.imageUrl,
        upload1: product?.images[product?.images?.length - 2]?.imageUrl,
        upload2: product?.images[product?.images?.length - 1]?.imageUrl,
    });
    const [showCropper, setShowCropper] = useState(false);
    const [picture, setPicture] = useState();
    const [previewObject, setPreviewObject] = useState({});
    const [formName, setFormName] = useState();
    const [{ content, wordCount }, setContent] = useState({
        content: product.description || '',
        wordCount: 0,
    });
    const onCropperCancel = () => {
        setShowCropper(false);
        setPicture('');
    };

    const onCropperSave = async (url) => {
        if (url) {
            const image = await toDataURL(url);
            setPImages({ ...pImages, [formName]: image });
            setShowCropper(false);
        } else {
            toast.error('Image is still processing, wait for a sec...');
        }
    };

    const pAction = (formName, image) => {
        setPicture(image);
        setFormName(formName);
        setShowCropper(true);
    };
    const filteredObject = Object.entries(pImages).filter((entries) => entries[1].includes('base64'));

    const handleSubmit = (values, storeId, productId) => {
        const params = {
            ...values,
            imageUrl: filteredObject.map((entries) => entries && entries[1]),
        };
        setAltLoading(true);
        dispatch(editStorefrontProduct(params, storeId, productId)).then((res) => {
            if (res) {
                setAltLoading(false);
                setShowSuccessPage(!showSuccessPage);
            } else {
                setAltLoading(false);
                setShowFailurePage(!showFailurePage);
            }
        });
    };

    useEffect(() => {
        dispatch(getStoreByUserId());
    }, [dispatch]);

    const onImageRemove = (formName) => {
        const images = { ...pImages };
        delete images[formName];

        const entries = Object.keys(images).flatMap((key) => [images[key]]);
        var newEntry = {};
        for (let i = 0; i < entries.length; i++) {
            newEntry = { ...newEntry, [`upload${i}`]: entries[i] };
        }
        setPImages(newEntry);
    };

    const setFormattedContent = useCallback(
        (text) => {
            let words = text.split(' ').filter(Boolean);
            if (words.length > limit) {
                setContent({
                    content: words.slice(0, limit).join(' '),
                    wordCount: limit,
                });
            } else {
                setContent({ content: text, wordCount: words.length });
            }
        },
        [limit, setContent],
    );

    const productImages = Object.entries(pImages).map((entries) => entries && entries[1]);
    const toFindDuplicates = (productImages) =>
        productImages.filter((item, index) => productImages.indexOf(item) !== index);
    const duplicateElements = toFindDuplicates(productImages);

    useEffect(() => {
        setFormattedContent(content);
    }, [content, setFormattedContent]);

    if (altLoading) return <Loader />;

    return (
        <Fragment>
            {showSuccessPage && (
                <StorefrontAddProductSuccess
                    open={showSuccessPage}
                    setOpen={() => setShowSuccessPage(!showSuccessPage)}
                    update
                    product={product}
                />
            )}
            {showFailurePage && (
                <StorefrontAddProductFailure
                    open={showFailurePage}
                    setOpen={() => setShowFailurePage(!showFailurePage)}
                    update
                />
            )}

            {!showFailurePage && !showSuccessPage && (
                <DesktopBackgroundLayout>
                    {showCropper && <ImageCropper avatar={picture} onCancel={onCropperCancel} onSave={onCropperSave} />}
                    <TopHeader title={'Edit Product'} noBorderBottom={true} backLink={'/my-shop/storefront'} />
                    <ScreenContainer top={'65px'} paddingBottom={'19px'}>
                        <AddProductText size={'16px'} color={colors.themeTxtColor10} weight={'700'} bottom={'5px'}>
                            Product Information
                        </AddProductText>
                        <AddProductText size={'14px'} color={'#5C6F7F'} weight={'400'}>
                            Kindly provide the following product details
                        </AddProductText>
                        <AddProductContainer>
                            <Formik
                                initialValues={{
                                    name: previewObject.name || product.name,
                                    description: product.description || '',
                                    price: previewObject.price || product.price,
                                    quantityInStock: previewObject.quantityInStock || product.quantityInStock,
                                    stockQuantityThreshold:
                                        previewObject.stockQuantityThreshold || product.stockQuantityThreshold,
                                }}
                                validationSchema={AddProductValidationSchema}
                                onSubmit={(values, { setErrors }) => {
                                    if (duplicateElements.length > 0) {
                                        toast.error('Each image must be unique');
                                    } else if (Object.keys(pImages).length === 1) {
                                        toast.error('Minimum of two product images');
                                    } else {
                                        values.description = content;
                                        setPreviewObject({
                                            ...values,
                                        });

                                        handleSubmit(values, store.id, product.id);
                                    }
                                }}
                            >
                                {({ errors, touched, setFieldValue, initialValues, handleChange, values }) => (
                                    <Form>
                                        <InputBlock>
                                            <TextInputWithLabel
                                                label={'Product Name'}
                                                type={'text'}
                                                value={values.name}
                                                placeholder={'Product Name'}
                                                name="name"
                                                valid={`${!touched.name && !errors.name}`}
                                                errors={touched && touched.name && errors && errors.name}
                                            />

                                            <TextInputWithLabel
                                                label={'Product Description'}
                                                type={'text'}
                                                value={content}
                                                onChange={(event) => setFormattedContent(event.target.value)}
                                                placeholder={'Product Description'}
                                                minHeight={'0'}
                                                name="description"
                                                valid={`${!touched.description && !errors.description}`}
                                                errors={touched && touched.description && errors && errors.description}
                                            />
                                            <CountParagraph>
                                                {wordCount}/{limit}
                                            </CountParagraph>

                                            <AmountInputWithLabel
                                                label={'Price'}
                                                type={'text'}
                                                value={values.price}
                                                placeholder={'Price'}
                                                name="price"
                                                valid={`${!touched.price && !errors.price}`}
                                                errors={touched && touched.price && errors && errors.price}
                                            />

                                            <TextInputWithLabel
                                                label={'Quantity in stock'}
                                                type={'text'}
                                                value={values.quantityInStock}
                                                placeholder={'Quantity in stock'}
                                                name="quantityInStock"
                                                valid={`${!touched.quantityInStock && !errors.quantityInStock}`}
                                                errors={
                                                    touched &&
                                                    touched.quantityInStock &&
                                                    errors &&
                                                    errors.quantityInStock
                                                }
                                            />
                                            <ThresholdCover>
                                                <TextInputWithLabel
                                                    label={'Stock quantity threshold'}
                                                    type={'text'}
                                                    value={values.stockQuantityThreshold}
                                                    placeholder={'Stock quantity threshold'}
                                                    name="stockQuantityThreshold"
                                                    valid={`${
                                                        !touched.stockQuantityThreshold &&
                                                        !errors.stockQuantityThreshold
                                                    }`}
                                                    errors={
                                                        touched &&
                                                        touched.stockQuantityThreshold &&
                                                        errors &&
                                                        errors.stockQuantityThreshold
                                                    }
                                                />
                                                <InfoStyle onClick={() => setOpenThresholdPopup(!openThresholdPopup)} />
                                            </ThresholdCover>

                                            <AddProductText
                                                size={'16px'}
                                                color={colors.themeTxtColor10}
                                                weight={'700'}
                                                bottom={'5px'}
                                                top={'30px'}
                                            >
                                                Product Pictures
                                            </AddProductText>
                                            <AddProductText
                                                size={'14px'}
                                                color={'#5C6F7F'}
                                                weight={'400'}
                                                bottom={'16px'}
                                            >
                                                Upload a maximum of 3 pictures of your product
                                            </AddProductText>
                                            <FlexContainer justifyContent="initial">
                                                <UploadPicture
                                                    text={'Upload'}
                                                    margin="0 12px 0 0"
                                                    changeCancelIcon={true}
                                                    pictureAction={pAction}
                                                    formName={'upload0'}
                                                    version2={true}
                                                    removeImage={onImageRemove}
                                                    picture={pImages && pImages['upload0']}
                                                />
                                                <UploadPicture
                                                    text={'Upload'}
                                                    margin="0 12px 0 0"
                                                    pictureAction={pAction}
                                                    formName={'upload1'}
                                                    version2={true}
                                                    changeCancelIcon={true}
                                                    removeImage={onImageRemove}
                                                    picture={pImages && pImages['upload1']}
                                                    disabled={pImages && !pImages['upload0']}
                                                />
                                                <UploadPicture
                                                    text={'Upload'}
                                                    margin="0 12px 0 0"
                                                    pictureAction={pAction}
                                                    formName={'upload2'}
                                                    version2={true}
                                                    changeCancelIcon={true}
                                                    removeImage={onImageRemove}
                                                    picture={pImages && pImages['upload2']}
                                                    disabled={pImages && (!pImages['upload0'] || !pImages['upload1'])}
                                                />
                                            </FlexContainer>

                                            <ButtonWrapper>
                                                <RippleButton
                                                    size={'16px'}
                                                    type={'submit'}
                                                    height={'56px'}
                                                    disabled={
                                                        !values.name ||
                                                        !values.price ||
                                                        !values.quantityInStock ||
                                                        !content ||
                                                        !values.stockQuantityThreshold ||
                                                        Object.keys(pImages).length < 3
                                                    }
                                                >
                                                    Update Product
                                                </RippleButton>
                                            </ButtonWrapper>
                                        </InputBlock>
                                    </Form>
                                )}
                            </Formik>
                        </AddProductContainer>
                    </ScreenContainer>
                </DesktopBackgroundLayout>
            )}
            {openThresholdPopup && <ThresholdPopup open={openThresholdPopup} setOpen={setOpenThresholdPopup} />}
        </Fragment>
    );
};

export default StorefrontEditProduct;
