import React from 'react';

import { string, func } from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as TrashIcon } from './assets/trash.svg';

const LabelBadge = styled.span`
    line-height: 16px;
    color: #56636d;
    font-size: 12px;
    font-weight: 500;
    margin-right: 8px;
`;

const Wrapper = styled.div`
    min-height: ${({ height }) => height || '24px'};
    min-width: ${({ width }) => width || '118px'};
    display: flex;
    flex-direction: row;
    margin: 0 8px 8px 0;
    padding: 4px 8px;
    border-radius: 13px;
    border: solid 1px #eeeeee;
    background-color: #ffffff;
    justify-content: space-between;
`;

const RightIcon = styled.span`
    cursor: pointer;
`;

export const Chip = ({ text, deleteChip }) => {
    return (
        <Wrapper>
            <LabelBadge>{text}</LabelBadge>
            <RightIcon>{<TrashIcon onClick={deleteChip} />}</RightIcon>
        </Wrapper>
    );
};

Chip.propTypes = {
    text: string,
    deleteChip: func,
};
