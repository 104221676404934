import React, { Fragment, useState } from 'react';

import ReactCardFlip from 'react-card-flip';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { RippleButton, TopHeader, SharePopup } from '../../../../components';
import { ScreenContainer, FlexContainer } from '../../../../containers/ScreenContainer';
import {
    uploadContentToBucketBase64 /*, uploadContentToBucket */,
} from '../../../../redux/ducks/applications/my-shop/actions/sales';
import { htmlToPdf } from '../../../../utils/files/htmlToFormData';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';

import SpacesIcon from './assets/logo.svg';
import SignatureImage from './assets/signature.png';
import { ReactComponent as SVGUploadShare } from './assets/UploadShare.svg';
import UserImage from './assets/user.svg';

const FrontContainer = styled.div`
    position: relative;
    margin: 0 auto;
    border-radius: 8px;
    background-color: #227eff;
    width: 328px;
    height: 500px;
`;

const IdHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #e9f2ff;
    padding: 32px;
    width: 328px;
    height: 139px;
`;

const FrontCardElementContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0px;
    padding-top: 32px;
    width: 328px;
    height: 500px;
`;

const HeaderLogo = styled.img``;

const AgentDp = styled.img`
    width: ${({ iconWidth }) => iconWidth || '178px'};
    height: ${({ iconHeight }) => iconHeight || '178px'};
    border-radius: ${({ borderRadius }) => borderRadius || '50%'};
    margin-top: ${({ top }) => top || '24px'};
    object-fit: ${({ objectFit }) => objectFit || 'cover'};
    background-image: ${({ fallback }) => `url(${fallback})` || null};
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 4px solid #ffffff;
`;

const AgentName = styled.h3`
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: #f2f7ff;
    margin-top: 32px;
    margin-left: 46px;
    margin-right: 46px;
    margin-bottom: 32px;
`;

const AgentIdContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    border-radius: 8px;
    background-color: #f2f5fa;
    padding: 16px;
    min-width: 216px;
`;

const SpacesAgent = styled.h2`
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #1f2138;
`;

const AgentIdNumber = styled.h2`
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    color: #1f2138;
    margin-top: 8px;
`;

const ContractState = styled.h2`
    text-align: center;
    font-size: 8px;
    font-weight: 700;
    color: #03b361;
    margin-top: 4px;
`;

const BackContainer = styled.div`
    position: relative;
    margin: 0 auto;
    border-radius: 8px;
    background-color: #227eff;
    width: 328px;
    height: 502px;
`;

const BackCardElementContainer = styled.div`
    position: absolute;
    top: 24px;
    left: 24px;
    border-radius: 8px;
    background-color: #f2f5fa;
    padding: 16px;
    width: 280px;
    height: 453px;
`;

const TermsConditionHeader = styled.h2`
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #212c3d;
    margin-bottom: 16px;
`;

const TermsCondition = styled.div`
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #56636d;
`;

const AgentPosition = styled.h2`
    text-align: center;
    font-size: ${({ fontSize }) => fontSize || '16px'};
    font-weight: 600;
    color: #56636d;
`;

const UploadSvg = styled(SVGUploadShare)`
    margin-right: 8px;
    height: 16px;
    width: 16px;
`;

const FlipButton = styled(RippleButton)`
    white-space: nowrap;
    width: min-content;
    background-color: #ffffff;
    color: #22a8ff;
`;

const IDCard = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [isFlipped, setIsFlipped] = useState(false);
    const [url, setUrl] = useState('');
    const [openShare, setOpenShare] = useState(false);
    const [loading, setLoading] = useState(false);

    const onShareClick = async () => {
        setLoading(true);
        if (user && user.passport && user.passport.url) {
            const link = await htmlToPdf('front', 'back', uploadContentToBucketBase64, dispatch);
            link && setUrl(link[0] + ' ' + link[1]);
            link && setOpenShare(!openShare);
        } else {
            toast.error('No profile image found. please update your profile');
        }
        setLoading(false);
    };

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'ID Card'} />
                <ScreenContainer padding={'0px'} top={'96px'} paddingBottom={'20px'}>
                    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                        <FrontContainer id={'front'}>
                            <IdHeader />
                            <FrontCardElementContainer>
                                <HeaderLogo src={SpacesIcon} alt="Sabi Logo" />
                                <AgentDp
                                    crossOrigin={'anonymous'}
                                    fallback={UserImage || null}
                                    id={'picture'}
                                    src={user && user.passport && user.passport.url}
                                    alt={''}
                                />
                                <AgentName>{user && user.firstName + ' ' + user.lastName}</AgentName>
                                <AgentIdContainer>
                                    <SpacesAgent>Sabi Agent</SpacesAgent>
                                    <AgentIdNumber>ID No. {user && user.agentCodeToShare}</AgentIdNumber>
                                    <ContractState>Contract Active</ContractState>
                                </AgentIdContainer>
                            </FrontCardElementContainer>
                        </FrontContainer>
                        <BackContainer id={'back'}>
                            <BackCardElementContainer>
                                <TermsConditionHeader>TERMS & CONDITIONS</TermsConditionHeader>
                                <TermsCondition>
                                    No. 3b Tiamiyu Savage Street, Victoria Island, Lagos.
                                    <br />
                                    <br />
                                    support@rensource.energy
                                    <br />
                                    <br />
                                    This is to certify that the holder whose name and photograph appear on this card is
                                    a<AgentPosition>Sales Consultant</AgentPosition> of Sabi, a subsidiary of Rensource
                                    Energy.
                                    <br />
                                    Please if found, return to the address above or the nearest police station.
                                    <br />
                                    <br />
                                    <img
                                        style={{ height: '48px', width: '48px' }}
                                        src={SignatureImage}
                                        alt="signature"
                                    />
                                    <br />
                                    -------------------------------
                                    <AgentPosition fontSize={'14px'}>Authorised Signature</AgentPosition>
                                </TermsCondition>
                            </BackCardElementContainer>
                        </BackContainer>
                    </ReactCardFlip>
                    <FlexContainer
                        style={{
                            flexDirection: 'column',
                            padding: '16px',
                        }}
                    >
                        <FlipButton onClick={() => setIsFlipped(!isFlipped)}>
                            {isFlipped ? 'Hide the back' : 'Show the back'}
                        </FlipButton>
                        <RippleButton top={'24px'} type={'button'} disabled={loading} onClick={onShareClick}>
                            {loading ? (
                                'Generating link...'
                            ) : (
                                <div>
                                    <UploadSvg />
                                    Share
                                </div>
                            )}
                        </RippleButton>
                    </FlexContainer>
                </ScreenContainer>
                <SharePopup
                    url={url}
                    marketingMessage={'Share your ID card'}
                    open={openShare}
                    setOpen={setOpenShare}
                    shareMessage={'Share your ID card with friends'}
                />
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default IDCard;
