export const LOADING_SHOP = '/actions/myshop_loading_shop';
export const FETCH_SHOP_SALES_DATA = '/actions/myshop_fetch_shop_sales_data';
export const FETCH_SHOP_INVENTRY_DATA = '/actions/myshop_fetch_shop_inventory_data';
export const STOP_LOADING = '/actions/myshop_stop_loading';
export const UPDATE_ERROR = '/actions/myshop_update_error';
export const LOADING_SHOP_ORDER = '/actions/myshop_loading_shop_order';
export const MY_SHOP_SUCCESS = '/actions/myshop_my_shop_success';
export const MY_SHOP_ERROR = '/actions/myshop_my_shop_error';
export const SAVE_SHOP = '/actions/myshop_save_shop';
export const DELETE_SHOP = '/actions/myshop_delete_shop';
export const UPDATE_SHOP = '/actions/myshop_update_shop';
export const ADD_INVENTORY = '/actions/myshop_add_inventory';
export const ADD_PRODUCT = '/actions/myshop_add_product';
export const UPDATE_PRODUCT = '/actions/myshop_update_product';
export const DELETE_PRODUCT = '/actions/myshop_delete_product';
export const UPDATE_PRODUCT_QUANTITY = '/actions/myshop_update_product_quantity';
export const UPDATE_INVENTORY_PRODUCT_ID = '/actions/myshop_update_inventory_product_id';
export const UPDATE_PRODUCT_ID = '/actions/myshop_update_product_id';
export const UPDATE_PRODUCT_IMAGE = '/actions/myshop_update_product_image';
export const SAVE_SALE = '/actions/myshop_save_sale';
export const UPDATE_SALE = '/actions/myshop_update_sale';
export const UPDATE_SALE_ID = '/actions/myshop_update_sale_id';
export const DELETE_SALE = '/actions/myshop_delete_sale';
export const SAVE_CUSTOMER = '/actions/myshop_save_customer';
export const GET_CUSTOMERS = '/actions/myshop_get_customers';
export const GET_TOTAL = '/actions/myshop_get_total';
export const DELETE_CUSTOMER = '/actions/myshop_delete_customer';
export const UPDATE_CUSTOMER = '/actions/myshop_update_customer';
export const UPDATE_CUSTOMER_ID = '/actions/myshop_update_customer_id';
export const UPDATE_CUSTOMER_OWING_STATUS = '/actions/myshop_update_customer_owing_status';
export const MY_SHOP_CATEGORIES = '/actions/myshop_get_categories';
export const MY_SHOP_ORDERS_SUCCESS = '/actions/myshop_orders_success';
export const MY_SHOP_ORDERS_FAILURE = '/actions/myshop_orders_failure';
export const MY_SHOP_ORDER_BY_ID_SUCCESS = '/actions/myshop_order_by_id_success';
export const MY_SHOP_ORDER_BY_ID_FAILURE = '/actions/myshop_order_by_id_failure';
export const MY_SHOP_ORDER_UPDATE_PRODUCTS = '/actions/myshop_order_update_products';
export const FETCH_PRODUCTS_MASTER_LIST = '/actions/products_master_list';
export const NO_PRODUCTS_ON_MASTER_LIST = '/actions/no_products_on_master_list';
export const MY_SHOP_PRODUCT_CATEGORIES = '/actions/myshop_get_product_categories';
export const PROCESS_SHOP_ORDERS_SUCCESS = '/action/process_shop_order_success';
export const PROCESS_SHOP_ORDERS_FAILURE = '/action/process_shop_order_failure';
export const BULK_PRICE_UPDATE = '/actions/myshop_bulk_price_update';
export const TOGGLE_MERCH_VISIBLE = '/actions/myshop_toggle_merch_visible';
export const UPDATE_BANK_ACCOUNT_DETAILS = '/actions/update_bank_account_details';
export const TOGGLE_PAYMENT_OPTIONS = '/actions/toggle_payment_options';
export const LOADING_CUSTOMERS_AND_REFERRALS = '/actions/loading_customers_and_referrals';
export const LOADING_DELIVERY_DETAILS = '/actions/loading_delivery_details';
export const FETCH_DELIVERY_DETAILS_SUCCESS = '/actions/fetch_delivery_details_success';
export const FETCH_CUSTOMERS_AND_REFERRALS_SUCCESS = 'fetch_customers_and_referrals_success';
export const UPDATE_CUSTOMERS_AND_REFERRALS = 'update_customers_and_referrals';
export const LOADING_SALES_REPORT = '/action/LOADING_SALES_REPORT';
export const YEARLY_SALES_REPORT_SUCCESS = '/action/YEARLY_SALES_REPORT_SUCCESS';
export const MONTHLY_SALES_REPORT_SUCCESS = '/action/MONTHLY_SALES_REPORT_SUCCESS';
export const WEEKLY_SALES_REPORT_SUCCESS = '/action/WEEKLY_SALES_REPORT_SUCCESS';
export const DAILY_SALES_REPORT_SUCCESS = '/action/DAILY_SALES_REPORT_SUCCESS';
export const SALES_REPORTS_EXTRA_DATA_SUCCESS = '/action/SALES_REPORTS_EXTRA_DATA_SUCCESS';
export const SALES_REPORT_FAILURE = '/action/SALES_REPORT_FAILURE';
export const LOADING_PRODUCT_REPORT = '/action/loading_product_report';
export const PRODUCT_REPORT_SUCCESS = '/action/product_report_success';
export const PRODUCT_REPORT_FAILURE = '/action/product_report_failure';
export const DATED_SALES_REPORT_SUCCESS = '/action/DATED_SALES_REPORT_SUCCESS';
export const DATE_RANGE_SALES_REPORT_SUCCESS = '/action/DATE_RANGE_SALES_REPORT_SUCCESS';
export const LOADING_DEBTORS = '/action/loading_debtors';
export const FETCH_LIST_OF_DEBTORS = '/action/fetch_list_of_debtors';
export const FETCH_DEBTOR = '/action/fetch_debtor';
export const FETCH_TOTAL_DEBT_AMOUNT = '/action/fetch_total_debt_amount';
export const FETCH_DEBT_REMINDER = '/action/fetch_debt_reminder';
export const SET_REMINDER_SUCCESS = '/action/set_reminder_success';
export const FETCH_CUSTOMER = '/actions/myshop_fetch_customer';
