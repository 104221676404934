import React, { Fragment, useState, useEffect } from 'react';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import OrderIcon from '../../../../assets/order-icon.svg';
import { Loader, PageLogo, RippleButton, TopHeader } from '../../../../components';
import { List, ListHeading, ListItem, ListLeftBlock, ListSubHeading } from '../../../../containers/ListContainer';
import { Message } from '../../../../containers/MessageContainer';
import { FlexCenteredBlock, ScreenContainer } from '../../../../containers/ScreenContainer';
import { merchbuyActions } from '../../../../redux/ducks/applications/merchbuy/actions';
import { colors } from '../../../../styles';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import { OrderBadge, OrderBadges, ParticipantText } from '../../my-shop/containers/OrderBadgesContainer';
import { ReactComponent as Stopwatch } from '../assets/stopwatch.svg';
import { GroupBuySplitFriends } from '../group-buy/split-friends';
import { Space } from '../styles';

const StopwatchIcon = styled(Stopwatch)`
    width: 16px;
    height: 16px;
    margin: 0 4px 0 0;
    object-fit: contain;
`;

const Tag = styled.span`
    font-size: 10px;
`;

const DescriptionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 18px 16px 15.5px 16px;
`;

const GroupBuyOrderDetails = ({ close, groupBuyOrderId }) => {
    const dispatch = useDispatch();
    const [order, setOrder] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const msisdn = useSelector((state) => state.user.msisdn);
    const [invitedFriends, setInvitedFriends] = useState([]);
    const [inviteFriendPopup, setInviteFriendPopup] = useState(false);
    const [countDown, setCountDown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    const { id, allParticipants, splits, productName, productUnitValue, images, productBulkPrice } = order || {};
    const myShare = allParticipants && allParticipants.find((x) => x.phoneNumber === msisdn);
    const mySplit = splits && splits[myShare.groupBuySplitId];
    const organizer = allParticipants && allParticipants.find((x) => x.organizer);
    const paidParticipants = allParticipants && allParticipants.filter((x) => x.paid);
    const userPriceToPay = mySplit && mySplit * order.productBulkPrice;
    const expiresAt = (order && order.expiresAt) || 0;
    const hasAllPaid = (allParticipants && allParticipants.length) - (paidParticipants && paidParticipants.length) < 0;
    const product = { images, name: productName, unitValue: productUnitValue, id };
    const isOrganizer = organizer && organizer.phoneNumber === msisdn;

    useEffect(() => {
        let interval;
        const getOrders = async () => {
            const data = await dispatch(merchbuyActions.getGroupBuyOrderById(groupBuyOrderId));
            if (data) {
                setIsLoading(false);
                setOrder(data);
                const diff = new Date(data.expiresAt).getTime() - new Date().getTime();
                if (diff > 0) {
                    interval = setInterval(() => {
                        const timeDiffrence = new Date(data.expiresAt).getTime() - new Date().getTime();
                        const days = Math.floor(timeDiffrence / (1000 * 60 * 60 * 24));
                        const hours = Math.floor((timeDiffrence % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        const minutes = Math.floor((timeDiffrence % (1000 * 60 * 60)) / (1000 * 60));
                        const seconds = Math.floor((timeDiffrence % (1000 * 60)) / 1000);
                        setCountDown({ days, hours, minutes, seconds });
                    }, 1000);
                }
            }
        };
        getOrders();
        return () => clearInterval(interval);
    }, [dispatch, groupBuyOrderId]);

    if (isLoading) {
        return <Loader />;
    } else
        return (
            <Fragment>
                <GroupBuySplitFriends
                    open={inviteFriendPopup}
                    cancel={setInviteFriendPopup}
                    product={product}
                    invitedFriends={invitedFriends}
                    setInvitedFriends={setInvitedFriends}
                    unitPrice={productBulkPrice}
                    sendInvite={true}
                />
                <TopHeader
                    backAction={() => close()}
                    backLink="#"
                    withSpacesHeader
                    title={(order && moment(order.createdAt).format('llll')) || ''}
                />
                <ScreenContainer>
                    <FlexCenteredBlock top={'50px'}>
                        <PageLogo
                            top={'22px'}
                            background={'transparent'}
                            width={'48px'}
                            height={'48px'}
                            iconWidth={'48px'}
                            iconHeight={'48px'}
                            Icon={(images && images.baseImageUrl) || OrderIcon}
                        />
                        {new Date(expiresAt).getTime() - new Date().getTime() > 0 ? (
                            <OrderBadge size="12px" fontWeight="500" height="28px" top="8px" nature="Rejected">
                                <StopwatchIcon />
                                {`${countDown.days}:${countDown.hours}:${countDown.minutes}:${countDown.seconds}`}
                            </OrderBadge>
                        ) : (
                            <OrderBadge top="15px" bottom="8px" nature="Attended">
                                Completed
                            </OrderBadge>
                        )}
                        <Space height="20px" />
                        <OrderBadges>
                            <ListSubHeading>Status:</ListSubHeading>
                            <OrderBadge>
                                <ParticipantText
                                    color={hasAllPaid ? colors.smoothGreyText : null}
                                    padding="0 2px 0 0"
                                >{`${paidParticipants && paidParticipants.length} of`}</ParticipantText>
                                {`${allParticipants && allParticipants.length} paid`}
                            </OrderBadge>
                            <OrderBadge nature="Simple">{`${order && order.totalQuantity} Products`}</OrderBadge>
                        </OrderBadges>
                    </FlexCenteredBlock>
                    <Message top="25px" color="#253246" weight="500" bottom="15.5px">
                        Order Details
                    </Message>
                    <List fullScreen>
                        <ListItem top="16.5px" bottom="15.5px">
                            <PageLogo
                                width={'32px'}
                                height={'32px'}
                                iconWidth={'32px'}
                                iconHeight={'32px'}
                                Icon={(images && images.baseImageUrl) || OrderIcon}
                            />
                            <ListLeftBlock width="100%">
                                <div>
                                    <ListHeading maxWidth="280px" noHeadingWrap>
                                        {order && order.productName}
                                    </ListHeading>
                                    <Tag>{`${formatPrice(order && order.productBulkPrice)}/${productUnitValue}`}</Tag>
                                </div>
                            </ListLeftBlock>
                            <OrderBadge color={colors.themeColor1} size="14px" height="32px">
                                {mySplit && mySplit}
                            </OrderBadge>
                        </ListItem>
                    </List>
                    <List fullScreen>
                        <DescriptionContainer>
                            <Message size="12px" top="0">
                                Your Own Share
                            </Message>
                            <Message weight="500" top="0" color={colors.themeTextColor1}>
                                {formatPrice(userPriceToPay)}
                            </Message>
                        </DescriptionContainer>
                    </List>
                    <List fullScreen>
                        <DescriptionContainer>
                            <Message size="12px" top="0">
                                Date Created
                            </Message>
                            <Message weight="500" top="0" color={colors.themeTextColor1}>
                                {order && moment(order.createdAt).format('llll')}
                            </Message>
                        </DescriptionContainer>
                    </List>
                    <List fullScreen>
                        <DescriptionContainer>
                            <Message size="12px" top="0">
                                Date Closed
                            </Message>
                            <Message weight="500" top="0" color={colors.themeTextColor1}>
                                {order && moment(order.expiresAt).format('llll')}
                            </Message>
                        </DescriptionContainer>
                    </List>
                    <List fullScreen>
                        <Message left="16px" top="32.5px" color="#253246" weight="500" bottom="15.5px">
                            Split Details
                        </Message>
                    </List>
                    {organizer && (
                        <List fullScreen>
                            <DescriptionContainer>
                                <Message size="12px" top="0">
                                    {`Organizer ${isOrganizer ? '(You)' : ''}`}{' '}
                                </Message>
                                <div>
                                    <Message weight="500" top="0" color={colors.themeTextColor1}>
                                        {order.organizerName}
                                    </Message>
                                    <Message align="right" size="12px" top="0">
                                        {organizer.phoneNumber}
                                    </Message>
                                </div>
                            </DescriptionContainer>
                        </List>
                    )}
                    <List fullScreen>
                        <DescriptionContainer>
                            <Message size="12px" top="0">
                                Split By
                            </Message>
                            <Message weight="500" top="0" color={colors.themeTextColor1}>{`${
                                splits && splits.length
                            } Persons`}</Message>
                        </DescriptionContainer>
                    </List>
                    {paidParticipants && paidParticipants.length > 0 && (
                        <List fullScreen>
                            <DescriptionContainer>
                                <Message size="12px" top="0">
                                    Paid Friends
                                </Message>
                                <div>
                                    {paidParticipants.map((x) => (
                                        <div key={x.userId}>
                                            <Message weight="500" top="0" color={colors.themeTextColor1}>
                                                {x.name}
                                            </Message>
                                            <Message align="right" size="12px" top="0">
                                                {x.phoneNumber}
                                            </Message>
                                        </div>
                                    ))}
                                </div>
                            </DescriptionContainer>
                        </List>
                    )}
                    {!hasAllPaid && (
                        <List fullScreen>
                            <DescriptionContainer>
                                <Message size="12px" top="0">
                                    Friends left to pay
                                </Message>
                                <Message weight="500" top="0" color={colors.red}>{`${
                                    allParticipants && allParticipants.length - paidParticipants.length
                                } Person(s)`}</Message>
                            </DescriptionContainer>
                        </List>
                    )}
                    {new Date(expiresAt).getTime() - new Date().getTime() > 0 && isOrganizer && (
                        <RippleButton
                            onClick={() => {
                                setInviteFriendPopup(true);
                            }}
                        >
                            Share to more friends
                        </RippleButton>
                    )}
                </ScreenContainer>
            </Fragment>
        );
};

export default GroupBuyOrderDetails;
