import React, { Fragment, useState, useEffect } from 'react';

import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import AgentBankAccountIcon from '../../../../assets/bank.svg';
import { TopHeader, InputWithLabel, PageProgress, RippleButton, PageLogo, SelectBox } from '../../../../components';
import { InputBlock } from '../../../../containers/InputContainer';
import { Message } from '../../../../containers/MessageContainer';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import {
    getAvailableBanksNoAuth,
    addBankDetailsOnUpgrade,
} from '../../../../redux/ducks/account/wallet/actions/bank-account';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';

import { BankAccountValidationSchema } from './BankAccountValidationSchema';

const UMAgentBankAccount = () => {
    const dispatch = useDispatch();
    const banks = useSelector((state) => state.account.wallet.availableBanks);
    const [modifiedBanksList, setModifiedBankList] = useState([]);

    useEffect(() => {
        dispatch(getAvailableBanksNoAuth());
    }, [dispatch]);

    useEffect(() => {
        banks && setModifiedBankList(banks.map((bank) => ({ value: bank['code'], label: bank['name'] })) || []);
    }, [banks]);

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Bank Account'} />
                <ScreenContainer>
                    <PageLogo top={'64px'} Icon={AgentBankAccountIcon} />
                    <PageProgress step={3} amount={4}></PageProgress>
                    <Message align={'left'}>Please provide your bank account details.</Message>
                    <Formik
                        initialValues={{
                            accountNumber: '',
                            bank: '',
                            bankCode: '',
                            currency: 'NGN',
                        }}
                        validationSchema={BankAccountValidationSchema}
                        onSubmit={(values) => {
                            dispatch(addBankDetailsOnUpgrade(values));
                        }}
                    >
                        {({ values, errors, touched, setFieldValue, initialValues }) => (
                            <Form style={{ marginTop: '16px' }}>
                                <InputBlock>
                                    <InputWithLabel
                                        label={'Account number'}
                                        type={'text'}
                                        value={values.accountNumber}
                                        placeholder={'Account number'}
                                        inputMode={'numeric'}
                                        name="accountNumber"
                                        maxLength="10"
                                        valid={`${!touched.accountNumber && !errors.accountNumber}`}
                                        errors={touched && touched.accountNumber && errors && errors.accountNumber}
                                        clear={() => {
                                            document.querySelector(`input[name='accountNumber']`).value = '';
                                            setFieldValue('accountNumber', initialValues.accountNumber);
                                        }}
                                    />
                                </InputBlock>
                                <SelectBox
                                    placeholder={'Bank'}
                                    value={values.bank}
                                    options={modifiedBanksList}
                                    handleChange={(selected) => {
                                        setFieldValue('bank', selected.label);
                                        setFieldValue('bankCode', selected.value);
                                    }}
                                    valid={`${!touched.bank && !errors.bank}`}
                                    error={touched && touched.bank && errors && errors.bank}
                                />
                                <RippleButton
                                    type="submit"
                                    top={'24px'}
                                    disabled={!values.bank || String(values.accountNumber).length !== 10}
                                >
                                    Continue
                                </RippleButton>
                            </Form>
                        )}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default UMAgentBankAccount;
