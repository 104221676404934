import React, { Fragment, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button, LightButton } from '../../../../components';
import { FeatureUndergoingFixes } from '../../../../components/popup/feature-undergoing-fixes';
import { Title } from '../../../../containers/MessageContainer';
import { ActionBlock } from '../../../../containers/OverlayContainer';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { remittanceActions } from '../../../../redux/ducks/applications/remittance/actions';
import { colors } from '../../../../styles';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { Header, Navigation } from '../../../home';
import { ReactComponent as Bulb } from '../assets/bulb.svg';
import NoRemittanceIcon from '../assets/no_remittance.svg';
import { ReactComponent as Spinner } from '../assets/spinner.svg';
import PaymentCard from '../component/payment-card';
import { NoRemittanceWrapper, NoRemittanceLogo } from '../styles';

const DashboardCard = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 164px;
    background: ${colors.white};
    box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 24px 16px;
    margin: 16px 0;
    align-items: center;
    justify-content: center;
`;

const Text = styled.h2`
    font-size: ${({ size }) => size || '12px'};
    font-weight: ${({ weight }) => weight || '700'};
    color: ${({ color }) => color || colors.lightDark};
    text-align: ${({ align }) => align || 'center'};
    line-height: ${({ lineHeight }) => lineHeight || '21px'};
    padding: 2px;
    margin-left: ${({ left }) => left || null};
    margin-top: ${({ top }) => top || null};
`;

const ListHolder = styled.div`
    margin-bottom: 32px;
`;

const ApprovalWrapper = styled.span`
    display: flex;
    width: fit-content;
    border-radius: 24px;
    margin-top: 8px;
    padding: 6px 9px;
    align-items: center;
    justify-content: center;
    background-color: ${colors.lightGreen};
`;

export const RemittanceDashboard = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const avatar = useSelector((state) => state.user.avatar);
    const notifications = useSelector((state) => state.account.notifications.allNotifications);
    const unpaidOrders = useSelector((state) => state.applications.remittance.unpaidOrders);
    const amountPendingApproval = useSelector((state) => state.applications.remittance.amountPendingApproval);
    const outstandingAmount = useSelector((state) => state.applications.remittance.outstandingAmount);
    const [openFeatureFixes, setOpenFeatureFixes] = useState(false);

    useEffect(() => {
        dispatch(remittanceActions.getRemittanceMetrics());
        dispatch(remittanceActions.getPendingRemittanceOrder());
    }, [dispatch]);

    const handleClose = () => {
        setTimeout(() => {}, 1000);
    };

    const handleOrderDetails = (order) => {
        history.push({ pathname: '/actions/remittance/remittance-order-details', state: order });
    };

    const handlePaymentHistory = () => history.push('/actions/remittance/remmitance-history');

    const handleRemitPayment = () => history.push('/actions/remittance/remitting-multiple-orders');

    return (
        <DesktopBackgroundLayout>
            <Fragment onClick={handleClose}>
                <Header avatar={avatar} notifications={notifications} setOpenFeatureFixes={setOpenFeatureFixes} />
                {openFeatureFixes && <FeatureUndergoingFixes cancel={() => setOpenFeatureFixes(false)} />}
                <ScreenContainer height="100%" top="45px" color={colors.lightestGray}>
                    <Title top="23px" textAlign="left" size="20px" weight="bold" color={colors.subGrey}>
                        Hello, <strong style={{ color: 'black' }}>{user?.firstName || ''}</strong>
                    </Title>
                    <DashboardCard>
                        <Text size="10px" weight="400" lineHeight="16px">
                            Outstanding remittance
                        </Text>
                        <Text size="16px" lineHeight="24px" color={colors.boldDark}>
                            {formatPrice(outstandingAmount || 0)}
                        </Text>
                        <ApprovalWrapper>
                            <Spinner />
                            <Text lineHeight="16px" weight="400" size="10px" left="5px" color={colors.textGreen}>
                                Pending approval
                            </Text>
                            <Text lineHeight="16px" size="10px" left="5px" color={colors.darkGreen1}>
                                {formatPrice(amountPendingApproval || 0)}
                            </Text>
                        </ApprovalWrapper>

                        <ActionBlock direction={'row'} top={'16px'}>
                            <LightButton
                                top="0px"
                                type="button"
                                margin="0 8px 0 8px "
                                radius="4px"
                                color={colors.lightGray2}
                                background={colors.white}
                                borderColor={colors.lightDark}
                                onClick={handlePaymentHistory}
                            >
                                Payment History
                            </LightButton>
                            <Button
                                top="0px"
                                type="button"
                                margin="0 8px 24px 0"
                                backgroundColor={colors.deepBlue}
                                radius="4px"
                                onClick={handleRemitPayment}
                            >
                                Remit payment
                            </Button>
                        </ActionBlock>
                    </DashboardCard>
                    <Text align="left" lineHeight="18px">
                        Orders with outstanding payments
                    </Text>
                    {!unpaidOrders || unpaidOrders?.length === 0 ? (
                        <NoRemittanceWrapper>
                            <NoRemittanceLogo icon={NoRemittanceIcon} />
                            <Text top="18px" size="14px" color={colors.boldDark}>
                                You don’t have any orders
                            </Text>
                            <Bulb width={'100%'} />
                            <Text weight="400" top="8px" lineHeight="18px">
                                When you make a purchase, you can track your items in orders.
                            </Text>
                        </NoRemittanceWrapper>
                    ) : (
                        <ListHolder>
                            {unpaidOrders &&
                                unpaidOrders.map((order) => (
                                    <PaymentCard
                                        order={order}
                                        onClick={() => {
                                            handleOrderDetails(order);
                                        }}
                                        noBorderBottom
                                    />
                                ))}
                        </ListHolder>
                    )}
                </ScreenContainer>
            </Fragment>
            <Navigation />
        </DesktopBackgroundLayout>
    );
};
