import React, { Fragment, useState } from 'react';

import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import {
    Loader,
    TopHeader,
    InputWithLabel,
    TextareaWithLabel,
    RippleButton,
    TeamCreationResult,
} from '../../../../components';
import { InputBlock } from '../../../../containers/InputContainer';
import { Message } from '../../../../containers/MessageContainer';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { createTeam } from '../../../../redux/ducks/applications/agents/actions';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import AddTeamAgents from '../add-agents';

import { CreateTeamValidationSchema } from './CreateTeamValidationSchema';

const CreateTeam = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.applications.agents.isLoading);
    const referrals = useSelector((state) => state.applications.agents.referrals);
    const [createTeamSuccess, setCreateTeamSuccess] = useState(false);
    const [openAddAgentOverlay, setOpenAddAgentOverlay] = useState(false);
    const [teamId, setTeamId] = useState(undefined);
    const [teamData, saveTeamData] = useState({});

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Create A Team'} />
                <ScreenContainer>
                    <Message bottom={'24px'}>Enter the details of the team you want to create.</Message>
                    <TeamCreationResult
                        open={createTeamSuccess}
                        toggleOpen={() => setCreateTeamSuccess(!createTeamSuccess)}
                        teamName={teamData.name}
                        addTeamAgents={() => {
                            setCreateTeamSuccess(!createTeamSuccess);
                            setOpenAddAgentOverlay(!openAddAgentOverlay);
                        }}
                    />
                    <Formik
                        initialValues={{
                            name: '',
                            description: '',
                        }}
                        validationSchema={CreateTeamValidationSchema}
                        onSubmit={(values) => {
                            setTimeout(async () => {
                                saveTeamData(values);
                                const id = await dispatch(createTeam(values));
                                if (id) setTeamId(id);
                                if (id) setCreateTeamSuccess(!createTeamSuccess);
                            }, 200);
                        }}
                    >
                        {({ errors, touched, setFieldValue, initialValues, values }) => (
                            <Form>
                                <InputBlock>
                                    <InputWithLabel
                                        label={'Team name'}
                                        type={'text'}
                                        value={values.name}
                                        placeholder={'Team name'}
                                        name={'name'}
                                        valid={`${!touched.name && !errors.name}`}
                                        errors={touched && touched.name && errors && errors.name}
                                        setFieldValue={setFieldValue}
                                        initialValues={initialValues}
                                    />
                                    <TextareaWithLabel
                                        name={'description'}
                                        value={values.description}
                                        disabled={isLoading}
                                        placeholder={
                                            'Add a team description that will be visible to all agents of this team.'
                                        }
                                        height={'96px'}
                                        valid={`${!touched.description && !errors.description}`}
                                        errors={touched && touched.description && errors && errors.description}
                                    />
                                </InputBlock>
                                <RippleButton disabled={isLoading} top={'36px'} type={'submit'}>
                                    Create team
                                </RippleButton>
                            </Form>
                        )}
                    </Formik>
                    {openAddAgentOverlay && (
                        <AddTeamAgents
                            referrals={referrals}
                            open={openAddAgentOverlay}
                            setOpen={setCreateTeamSuccess}
                            teamId={teamId}
                        />
                    )}
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default CreateTeam;
