import React, { Fragment, useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { TopHeader, RippleButton, ButtonWithShareIcon } from '../../../../../components';
import { Message } from '../../../../../containers/MessageContainer';
import { payWithTransferStatus } from '../../../../../redux/ducks/applications/merchbuy/actions';
import { colors } from '../../../../../styles';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import { ReactComponent as CopyIcon } from '../assets/copy-icon.svg';

const ParentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(${colors.deepBlue} 40%, ${colors.white} 40%);
    position: absolute;
    top: 22px;
    bottom: 0;
    left: 0;
    right: 0;
`;

const LinearLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    width: 100%;
    height: 100%;
`;

const TopCard = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    background: ${colors.white};
    padding: 32px 16px 24px 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
    z-index: 1;
`;

const HorizontalDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${({ top }) => top || null};
`;

const VerticalDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${({ top }) => top || null};
    margin-right: ${({ right }) => right || null};
`;

const SubText = styled(Message)`
    color: ${colors.lightDark};
    font-weight: 400;
    font-size: 14px;
    margin-top: 0px;
`;

const MainText = styled(Message)`
    color: ${colors.boldDark};
    font-weight: 700;
    font-size: 16px;
    margin-right: ${({ right }) => right || null};
    margin-top: 0px;
`;

const Div = styled.div`
    display: flex;
`;

const LoaderDiv = styled.div`
    border: 3px solid ${colors.gray7};
    border-top: 3px solid ${colors.deepBlue};
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 2s linear infinite;
    margin-right: 8px;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const CheckoutWithBankTranfer = ({
    amount,
    setShowBankTransfer,
    accountNumber,
    bankName,
    savePayment,
    orderId,
    failedPayment,
}) => {
    const dispatch = useDispatch();

    const [showProgress, setShowProgress] = useState(false);

    useEffect(() => {
        let interv;

        const checkFunc = async () => {
            if (showProgress) {
                const res = await dispatch(payWithTransferStatus(orderId));
                if (res === 'SUCCESSFUL' || res === 'PARTIAL') {
                    setShowBankTransfer(false);
                    return savePayment();
                } else if (res === 'FAILED') {
                    setShowBankTransfer(false);
                    return failedPayment();
                }
            }
        };
        checkFunc();

        interv = setInterval(checkFunc, 5000);

        return () => clearInterval(interv);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, showProgress]);

    const copyAccountNumber = () => {
        if ('clipboard' in navigator) {
            toast.success('copied to clipboard!');
            return navigator.clipboard.writeText(accountNumber);
        } else {
            toast.success('copied to clipboard!');
            return document.execCommand('copy', true, accountNumber);
        }
    };

    return (
        <Fragment>
            <TopHeader
                title={'Pay with Bank'}
                backgroundColor={colors.deepBlue}
                color={colors.white}
                opaqueBackButton
                noBorderBottom
                backAction={() => setShowBankTransfer(false)}
                backLink="#"
            />
            <ParentWrapper>
                <LinearLayout>
                    <TopCard>
                        <SubText color={colors.themeTextColor11} align={'center'}>
                            Please make a bank tranfer to
                        </SubText>
                        <SubText color={colors.themeTextColor11} align={'center'}>
                            complete this order
                        </SubText>

                        <HorizontalDiv top={'40px'}>
                            <SubText color={colors.defaultGray}>Amount</SubText>
                            <MainText>{formatPrice(amount || 0)}</MainText>
                        </HorizontalDiv>
                        <HorizontalDiv top={'20px'}>
                            <SubText color={colors.defaultGray}>Account Number</SubText>
                            <Div>
                                <MainText right={'10px'}>{accountNumber}</MainText>
                                <CopyIcon onClick={copyAccountNumber} />
                            </Div>
                        </HorizontalDiv>
                        <HorizontalDiv top={'20px'}>
                            <SubText color={colors.defaultGray}>Bank Name</SubText>
                            <MainText>{bankName}</MainText>
                        </HorizontalDiv>

                        {!showProgress ? (
                            <RippleButton onClick={() => setShowProgress(true)} top={'100px'} height={'48px'}>
                                I have made the transfer
                            </RippleButton>
                        ) : (
                            <VerticalDiv top={'66px'}>
                                <SubText align={'center'}>Please hold on</SubText>
                                <ButtonWithShareIcon
                                    top={'16px'}
                                    backgroundColor={colors.faintGrey}
                                    height={'48px'}
                                    icon={<LoaderDiv />}
                                    title={'Checking for your payment'}
                                />
                            </VerticalDiv>
                        )}
                    </TopCard>
                </LinearLayout>
            </ParentWrapper>
        </Fragment>
    );
};
