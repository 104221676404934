export const paymentType = (type) => {
    if (type === 1) {
        return 'Payment on delivery';
    } else if (type === 2) {
        return 'Paystack';
    } else if (type === 3) {
        return 'Sabi wallet';
    } else if (type === 5) {
        return 'Payment with transfer';
    }
};
