import React, { Fragment } from 'react';

import { useHistory } from 'react-router';

import { SuccessPage } from '../../../../components';
import { SlidingOverlay } from '../../../../containers/OverlayContainer';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';

const SetupStoreFrontSuccess = ({ open, setOpen, update }) => {
    const history = useHistory();
    function handleClick() {
        setOpen();
        history.push('/my-shop/storefront');
    }

    return (
        <Fragment>
            <SlidingOverlay>
                <DesktopBackgroundLayout>
                    <SuccessPage
                        title={update ? 'Store Updated!' : 'Store Created!'}
                        subtitle={
                            update ? 'You’ve successfully updated your store' : 'You’ve successfully created a store'
                        }
                        doneText={'Done'}
                        onDoneClick={handleClick}
                        mposition={'fixed'}
                        position={'absolute'}
                    />
                </DesktopBackgroundLayout>
            </SlidingOverlay>
        </Fragment>
    );
};

export default SetupStoreFrontSuccess;
