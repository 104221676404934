import React, { Fragment, useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

//import { getAgentReferrals } from '../../../../redux/ducks/applications/agents/actions';
import {
    TopHeader,
    // RippleButton,
    UserAvatar,
    OptionsPopupDialog,
    InfoPopupDialog,
    RippleLink,
    Loader,
    CopyReferralBadge,
    ButtonWithShareIcon,
    QrShare,
    SharePopup,
} from '../../../../components';
import { currentHost } from '../../../../config/API';
import { Add } from '../../../../containers/HeaderContainer';
import { List, ListItem, ListHeading, ListLeftBlock, ListSubHeading } from '../../../../containers/ListContainer';
import { Message } from '../../../../containers/MessageContainer';
import { MODULE_INTERACTION } from '../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../utils/mix-panel/mixPanel';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import {
    InfoIntroduction,
    Step,
    StepsBlock,
    SubInfoIntroduction,
    StepText,
    StepHeading,
    StepDetails,
    BrokenLine,
    IconBlock,
} from '../../merchants/styles';
import { getDate } from '../../merchants/utils/date';
import { PageTitle, PageCount, PageList, PageHeading } from '../containers/AgentsItemsContainer';

import { ReactComponent as AddIcon } from './assets/add.svg';
import agentsBanner from './assets/agents-banner-new.png';
import { ReactComponent as AntiClockWise } from './assets/anti-clockwise.svg';
import { ReactComponent as CartIcon } from './assets/cart.svg';
import { ReactComponent as ClockWise } from './assets/clockwise.svg';
import { ReactComponent as DownChevron } from './assets/down-chevron.svg';
import { ReactComponent as EarnIcon } from './assets/earn-more.svg';
import { ReactComponent as LinkIcon } from './assets/link.svg';
import { ReactComponent as QrIcon } from './assets/qr-icon.svg';
import { ReactComponent as ShareIcon } from './assets/share.svg';

const AgentsTeamsWrapper = styled.div`
    padding: 0 16px;
    display: flex;
    flex-direction: column;
`;

const SortContainer = styled.div`
    display: flex;
    align-items: center;
    border-top: 1px solid #edf2f7;
    border-bottom: 1px solid #edf2f7;
    margin-top: 78px;
    padding: 11px 0;
`;

const SortName = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: #071827;
    line-height: 18px;
    margin-right: 6.67px;
`;

const ListHeader = styled(ListHeading)`
    font-weight: 700;
    margin: ${({ margin }) => margin};
    line-height: 21px;
`;

const BoldText = styled.span`
    font-weight: 700;
    color: #071827;
`;

const Agent = styled.img``;

const ModifiedListHeading = styled(ListHeading)`
    margin: 0px;
`;

const AgentsAndTeamsPage = () => {
    const isLoading = useSelector((state) => state.applications.agents.isLoading);
    const [loading, setLoading] = useState(true);
    const referrals = useSelector((state) => state.applications.agents.referrals);
    const [currentReferrals, setCurrentReferrals] = useState([]);
    //const teams = useSelector(state => state.applications.agents.teams);
    const agentCodeToShare = useSelector((state) => state.user.agentCodeToShare);
    const avatar = useSelector((state) => state.user.avatar);
    const firstName = useSelector((state) => state.user.firstName);
    const lastName = useSelector((state) => state.user.lastName);
    const url = `${currentHost}/launch?referralCode=${agentCodeToShare}`;
    const marketingMessage = `Hello, join in on spaces where you can easily earn money as you support merchants to use the app and grow their businesses to new levels. \n\nUse the link below to get the app. \n${currentHost}/launch?referralCode=${agentCodeToShare}. \n\nKindly use my referral code ${agentCodeToShare} while signing up.`;
    const [openReferralOption, setOpenReferralOption] = useState(false);
    const [openSortOptions, setSortOpenSortOptions] = useState(false);
    const role = useSelector((state) => state.user.role);
    const [selectedSort, setSelectedSort] = useState('Most Recent');
    const [openShareOptions, setOpenShareOptions] = useState(false);
    const [openQrShare, setOpenQrShare] = useState(false);
    const [openShareLinks, setOpenShareLinks] = useState(false);

    useEffect(() => {
        if (referrals.length > 0) {
            setCurrentReferrals(referrals);
        }
        if (!isLoading) {
            setLoading(false);
        }
    }, [isLoading, referrals]);

    useEffect(() => {
        mixPanel.track(MODULE_INTERACTION, {
            'Icon Name': 'My Agents & Teams',
            Time: new Date().toLocaleString(),
        });
    }, []);

    if (loading) {
        return <Loader />;
    } else {
        return (
            <Fragment>
                <DesktopBackgroundLayout>
                    {currentReferrals && currentReferrals.length > 0 ? (
                        <Fragment>
                            {!openQrShare && (
                                <TopHeader
                                    title={'My Agents'}
                                    right={'40px'}
                                    size="14px"
                                    weight="400"
                                    noBorderBottom
                                    color="#071827"
                                    backLink="/more"
                                >
                                    <Add right={'true'} onClick={() => setOpenReferralOption(!openReferralOption)} />
                                </TopHeader>
                            )}
                            <AgentsTeamsWrapper>
                                <SortContainer>
                                    <SortName onClick={() => setSortOpenSortOptions(!openSortOptions)}>
                                        {selectedSort}
                                    </SortName>
                                    <DownChevron />
                                </SortContainer>
                                <PageHeading top="16px">
                                    <PageCount color="#212C3D" lineHeight="30px" size="20px" weight="700">
                                        {currentReferrals && currentReferrals.length > 0 && currentReferrals.length}
                                    </PageCount>
                                    <PageTitle>My Agents</PageTitle>
                                </PageHeading>
                                <ListHeader color="#253246" margin="24px 0">
                                    Your Agents
                                </ListHeader>
                                <List>
                                    {currentReferrals &&
                                        currentReferrals.map((referral, index) => (
                                            <RippleLink
                                                key={index}
                                                to={{
                                                    pathname: '/actions/agents_agent_details',
                                                    state: currentReferrals[index],
                                                }}
                                            >
                                                <ListItem bottom={'8px'}>
                                                    <UserAvatar
                                                        avatar={referral.avatar}
                                                        width={'32px'}
                                                        height={'32px'}
                                                    />
                                                    <ListLeftBlock>
                                                        <ModifiedListHeading color="#071827">{`${referral.firstName} ${referral.lastName}`}</ModifiedListHeading>
                                                        <ListSubHeading
                                                            fontSize="12px"
                                                            fontWeight="400"
                                                            color="#718596"
                                                            maxWidth="100%"
                                                        >
                                                            Added: {getDate(referral.createdAt)}
                                                        </ListSubHeading>
                                                    </ListLeftBlock>
                                                </ListItem>
                                            </RippleLink>
                                        ))}
                                </List>
                            </AgentsTeamsWrapper>
                            <OptionsPopupDialog
                                open={openSortOptions}
                                title={'Sort by'}
                                cancel={() => setSortOpenSortOptions(!openSortOptions)}
                                padding="16px 16px 24px"
                                items={[
                                    {
                                        Icon: ClockWise,
                                        title: 'Most Recent',
                                        click: () => {
                                            setSortOpenSortOptions(!openSortOptions);
                                            setSelectedSort('Most Recent');
                                            setCurrentReferrals(referrals);
                                        },
                                    },
                                    {
                                        Icon: AntiClockWise,
                                        title: 'Oldest',
                                        click: () => {
                                            setSortOpenSortOptions(!openSortOptions);
                                            setSelectedSort('Oldest');
                                            setCurrentReferrals(
                                                currentReferrals
                                                    .slice()
                                                    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
                                            );
                                        },
                                    },
                                ]}
                            />
                            {/* <OptionsPopupDialog
                        open={openOptions}
                        arrows={false}
                        title="Options"
                        cancel={() => setOpenOptions(!openOptions)}
                        items={[
                            {
                                Icon: SingleAgentIcon,
                                title: "Add an Agent",
                                click: () => {
                                    console.log("🚀 ~ file: index.js ~ line 209 ~ AgentsAndTeamsPage ~ currentReferrals.slice().sort((a, b) => new Date(a.date) - new Date(b.date))", currentReferrals.slice().sort((a, b) => new Date(a.date) - new Date(b.date)))
                                    console.log("🚀 ~ file: index.js ~ line 209 ~ AgentsAndTeamsPage ~ currentReferrals.slice().sort((a, b) => new Date(a.date) - new Date(b.date))", currentReferrals.slice().sort((a, b) => new Date(a.date) - new Date(b.date)))
                                    setOpenOptions(!openOptions)
                                    setReferralOption(!openReferralOption)
                                }
                            },
                            // {
                            //     Icon: CreateTeamIcon,
                            //     title: "Create A Team",
                            //     link: "/actions/agents_create_team"
                            // }
                        ]}
                    /> */}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {!openQrShare && (
                                <TopHeader
                                    title={'Agents & Teams'}
                                    size="14px"
                                    weight="400"
                                    noBorderBottom
                                    color="#071827"
                                    backLink="/more"
                                />
                            )}
                            <AgentsTeamsWrapper>
                                <PageHeading>
                                    <PageCount color="#212C3D" lineHeight="30px" size="20px" weight="700">
                                        0
                                    </PageCount>
                                    <PageTitle>My Agents</PageTitle>
                                </PageHeading>
                                <ListHeader color="#253246" margin="35px 0 0">
                                    Your Agents
                                </ListHeader>
                                <PageList margin="16px 0 0" bgColor="#EDF2F7">
                                    <Agent src={agentsBanner} />
                                    <ListHeader color="#253246" margin="16px 0 4px">
                                        No Agents
                                    </ListHeader>
                                    <Message align={'center'} weight="400" color="#718596" top="0px">
                                        You’ve not added any agent. To add an agent, send your referral code to the
                                        person.
                                    </Message>
                                    {role === 'ROLE_AGENT' && (
                                        <CopyReferralBadge referralCode={agentCodeToShare} top="16px" />
                                    )}
                                    <ButtonWithShareIcon
                                        title="Share Referral Code"
                                        top="24px"
                                        color="#227EFF"
                                        backgroundColor="#EDF2F7"
                                        onClick={() => setOpenReferralOption(!openReferralOption)}
                                        size="16px"
                                    />
                                </PageList>
                            </AgentsTeamsWrapper>
                        </Fragment>
                    )}
                    <InfoPopupDialog
                        open={openReferralOption}
                        cancel={() => setOpenReferralOption(!openReferralOption)}
                        title={'Refer & Earn'}
                        withConfirmation={true}
                        shareButton={true}
                        confirm={() => {
                            setOpenShareOptions(!openShareOptions);
                            setOpenReferralOption(!openReferralOption);
                        }}
                        confirmText="Share"
                        headerPadding="32px 0px 16px"
                        color="#071827"
                        weight="700"
                        size="20px"
                        noBorderBottom={true}
                        contentPadding="0 16px"
                        textLeft="left"
                        messagePadding="0px"
                        buttonIcon={<ShareIcon />}
                        overflowY="auto"
                        maxHeight="80vh"
                    >
                        <InfoIntroduction align="left" margin="0px">
                            You can <BoldText>add an Agent</BoldText> using your referral code or link.
                        </InfoIntroduction>
                        <SubInfoIntroduction font="16px">How it works</SubInfoIntroduction>
                        <StepsBlock>
                            <Step>
                                <IconBlock bgColor="#FDEDE7">
                                    <AddIcon />
                                </IconBlock>
                                <StepText>
                                    <StepHeading>Invite an Agent</StepHeading>
                                    <StepDetails>Share your referral link or code.</StepDetails>
                                </StepText>
                            </Step>
                            <BrokenLine height="5px" />
                            <BrokenLine />
                            <BrokenLine />
                            <BrokenLine />
                            <BrokenLine height="5px" />

                            <Step>
                                <IconBlock bgColor="#EDE6FE">
                                    <CartIcon />
                                </IconBlock>
                                <StepText>
                                    <StepHeading>Make sure they're active</StepHeading>
                                    <StepDetails>Send them activation reminders.</StepDetails>
                                </StepText>
                            </Step>
                            <BrokenLine height="5px" />
                            <BrokenLine />
                            <BrokenLine />
                            <BrokenLine />
                            <BrokenLine height="5px" />

                            <Step>
                                <IconBlock bgColor="#D3FDE9">
                                    <EarnIcon />
                                </IconBlock>
                                <StepText>
                                    <StepHeading>Earn More!</StepHeading>
                                    <StepDetails>Earn commissions from their activities!</StepDetails>
                                </StepText>
                            </Step>
                        </StepsBlock>
                        {role === 'ROLE_AGENT' && <CopyReferralBadge referralCode={agentCodeToShare} top="36px" />}
                    </InfoPopupDialog>
                    <OptionsPopupDialog
                        open={openShareOptions}
                        title={'Options'}
                        padding="16px 16px 24px"
                        cancel={() => setOpenShareOptions(!openShareOptions)}
                        items={[
                            {
                                Icon: QrIcon,
                                title: 'QR Code',
                                more: false,
                                click: () => {
                                    setOpenShareOptions(!openShareOptions);
                                    setOpenQrShare(!openQrShare);
                                },
                            },
                            {
                                Icon: LinkIcon,
                                title: 'Share App Link',
                                more: false,
                                click: () => {
                                    setOpenShareOptions(!openShareOptions);
                                    setOpenShareLinks(!openShareLinks);
                                },
                            },
                        ]}
                    />
                    {openShareLinks && (
                        <SharePopup
                            url={url}
                            marketingMessage={marketingMessage}
                            open={openShareLinks}
                            setOpen={setOpenShareLinks}
                        />
                    )}
                    {openQrShare && (
                        <QrShare
                            open={openQrShare}
                            cancel={() => setOpenQrShare(!openQrShare)}
                            referralCode={agentCodeToShare}
                            avatar={avatar}
                            fullName={`${firstName} ${lastName}`}
                            backLink={'/actions/agents'}
                        />
                    )}
                    {/* {(teams && teams.length === 0) ? (
                    <Fragment>
                    {(referrals.length > 0) && (
                        <Fragment>
                            <PageHeading>
                                <PageTitle>Your Teams</PageTitle>
                                <PageCount>0 Teams</PageCount>
                            </PageHeading>
                            <PageList>
                                <TeamsBanner />
                                <Message align={"center"}>
                                    You’ve not created any teams. Create teams to better manage your agents.
                                </Message>
                                <CreateTeamButton to="/actions/agents_create_team">
                                    <RippleButton>Create a team</RippleButton>
                                </CreateTeamButton>
                            </PageList>
                        </Fragment>
                    )}
                </Fragment>
            ) : (
                <Fragment>
                    <PageHeading>
                        <PageTitle>Your Teams</PageTitle>
                        <PageCount>{teams.length} {(teams.length !== 1) ? "Teams" : "Team"}</PageCount>
                            <PageSeeAll>See All</PageSeeAll>
                        <RippleLink to="/actions/agents_my_teams" />
                    </PageHeading>
                    <TeamsList>
                    {teams && teams.slice(0, 4).map((team, index) => (
                        <TeamItem key={index}>
                            <RippleLink
                                to={{
                                    pathname: "/actions/agents_team_details",
                                    state: teams[index]
                                }}
                            >
                                <Options right={"true"} top={"16px"} />
                            </RippleLink>
                            <TeamItemHeading>{team.name}</TeamItemHeading>
                            <TeamItemSubHeading>{team.capitalization}</TeamItemSubHeading>
                            <TeamsAvatarsReel>
                            {team.members && team.members.slice(0, 3).map((agent, index) => (
                                <UserAvatar
                                    key={index}
                                    avatar={agent.avatar}
                                    width={"32px"}
                                    height={"32px"}
                                />
                            ))}
                            </TeamsAvatarsReel>
                            <TeamItemSubHeading>{team.members ? team.members.length : 0} Agents</TeamItemSubHeading>
                        </TeamItem>
                    ))}
                    </TeamsList>
                </Fragment>
            )} */}
                </DesktopBackgroundLayout>
            </Fragment>
        );
    }
};

export default AgentsAndTeamsPage;
