import React, { Fragment, useState, useEffect } from 'react';

import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import {
    Loader,
    TopHeader,
    SelectBox,
    FileInput3,
    RippleButton,
    PageLogo,
    SuccessPage,
} from '../../../../../components';
import { ImageCropper } from '../../../../../components/popup/image-cropper';
import { VerificationStatus } from '../../../../../components/verification-status';
import { InputBlock } from '../../../../../containers/InputContainer';
import { Message } from '../../../../../containers/MessageContainer';
import { ScreenContainer, FlexContainer, FlexDiv } from '../../../../../containers/ScreenContainer';
import { getAgentActivationStatus } from '../../../../../redux/ducks/applications/agents/actions';
import {
    sendUserDocument2,
    getRejectedReasons,
    uploadPassportOnboarding,
} from '../../../../../redux/ducks/user/actions';
import { colors } from '../../../../../styles';
import { compressImage } from '../../../../../utils/files/compressImage';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import { ReactComponent as ErrorIcon } from '../assets/alert_error.svg';
import { ReactComponent as CircleOne } from '../assets/circle_one.svg';
import { ReactComponent as CircleTwo } from '../assets/circle_two.svg';
import { ReactComponent as FileIcon } from '../assets/file_icon.svg';

import { UserMeansOfIDValidationSchema } from './UserMeansOfIDValidationSchema';

const SectionTitle = styled(Message)`
    font-size: 14px;
    font-weight: 700;
    color: #0b0c0e;
    margin: 0px 8px;
    line-height: 21px;
    text-align: left;
`;

const Wrapper = styled.div`
    display: flex;
    margin: ${({ margin }) => margin || '16px 0 0 32px'};
    flex-direction: ${({ direction }) => direction || 'row'};
    align-items: ${({ alignItem }) => alignItem || 'center'};
    justify-content: ${({ justify }) => justify || 'space-between'};
    padding: ${({ padding }) => padding && '16px'};
    background: #f2f5fa;
    border-radius: 4px;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px;
`;

const DocText = styled.h3`
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 2px;
    color: #227eff;
`;

const DocName = styled.h3`
    font-size: 10px;
    color: #071827;
    font-weight: 400;
    line-height: 16px;
`;

const RejectedReasonWrapper = styled.div`
    display: flex;
    height: 18px;
    width: fit-content;
    align-items: center;
    margin: ${({ margin }) => margin || '8px 0 0 32px'};
`;

const RejectedReasonText = styled.span`
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: 0.01em;
    color: #718596;
    margin: 0px 6px;
`;

const UploadButton = styled.label`
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    padding: 8px;
    color: ${colors.deepBlue};
    cursor: pointer;
    border: 1px solid #227eff;
    border-radius: 4px;
    margin: ${({ margin }) => margin || '24px 16px'};
    width: 121px;
    align-self: center;
    opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    & > input {
        height: 0;
        width: 0;
        overflow: hidden;
        background: transparent;
    }
`;

const Divider = styled.div`
    margin: 24px 0px;
    height: 1px;
    background: #e2e9f0;
`;

const ModifiedForm = styled(Form)`
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    height: 100%;
    justify-content: space-between;
`;

const Title = styled(Message)`
    margin-top: 31px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
`;

const SuccessMessage = styled.span`
    display: block;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #e9f2ff;
    text-align: center;
    margin-top: -24px;
`;

const Bold = styled.b`
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    color: #e9f2ff;
    align-items: center;
`;

const DOCUMENT_STATES = {
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
};

const AGENT_STATES = {
    INCOMPLETE: 'INCOMPLETE',
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    BLACKLISTED: 'BLACKLISTED',
};

const KYCMeansOfID = () => {
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.account.kyc.isLoading);
    const user = useSelector((state) => state.user);
    const userId = useSelector((state) => state.user.userId);
    const rejectedReasons = useSelector((state) => state.user.rejectedReasons);
    const agentState = useSelector((state) => state.user.agentState || AGENT_STATES.PENDING);
    const userDocumentData = useSelector((state) => state.user.documentData);

    const [documentList, setDocumentList] = useState([]);
    const [passportData, setPassportData] = useState({});
    const [passportState, setPassportState] = useState(DOCUMENT_STATES.PENDING);
    const [documentData, setDocumentData] = useState({});
    const [documentState, setDocumentState] = useState(DOCUMENT_STATES.PENDING);
    const [uploadNewId, setUploadNewId] = useState(false);
    const [showSuccessPage, setShowSucessPage] = useState(false);
    const [tempAvatar, setTempAvatar] = useState('');
    const [avatar, setAvatar] = useState('');
    const [showCropper, setShowCropper] = useState(false);

    useEffect(() => {
        if (user.documentList) {
            setDocumentList(
                user.documentList.reduce((accId, document) => {
                    return document.type === 'PassportPhotograph'
                        ? { ...accId, passport: document }
                        : { ...accId, others: document };
                }, {}),
            );
        }
    }, [user.documentList]);

    useEffect(() => {
        if (rejectedReasons && agentState !== AGENT_STATES.PENDING) {
            const passportReason = rejectedReasons.find((element) => element.name === 'passportPhoto');
            setPassportData(passportReason);
            //making it backward compactible with v1
            !passportReason &&
                setPassportState(agentState === AGENT_STATES.INCOMPLETE ? DOCUMENT_STATES.ACCEPTED : agentState);
            passportReason &&
                setPassportState(
                    passportReason?.details?.documentStatus ||
                        (passportReason.resolved ? DOCUMENT_STATES.ACCEPTED : DOCUMENT_STATES.REJECTED),
                );

            const docReason = rejectedReasons.find(
                (element) => element.name === getDocType(documentList?.others?.type || ''),
            );
            setDocumentData(docReason);
            //making it backward compactible with v1
            !docReason &&
                setDocumentState(agentState === AGENT_STATES.INCOMPLETE ? DOCUMENT_STATES.ACCEPTED : agentState);
            docReason &&
                setDocumentState(
                    docReason?.details?.documentStatus ||
                        (docReason.resolved ? DOCUMENT_STATES.ACCEPTED : DOCUMENT_STATES.REJECTED),
                );
        }
    }, [rejectedReasons, agentState, documentList]);

    useEffect(() => {
        dispatch(getAgentActivationStatus());
        if (agentState !== AGENT_STATES.APPROVED) {
            dispatch(getRejectedReasons(userId));
        }
    }, [agentState, dispatch, userId]);

    const getDocType = (name) => {
        switch (name) {
            case "Driver's License":
                return 'driversLicense';
            case 'Personal ID':
                return 'personalId';
            case 'Work ID':
                return 'workId';
            case 'PassportPhotograph':
                return 'passportPhoto';
            default:
                return '';
        }
    };

    const onCropperCancel = () => {
        setShowCropper(false);
        setTempAvatar('');
    };

    const onCropperSave = async (url) => {
        if (url) {
            setShowCropper(false);
            setTempAvatar(url);
            setAvatar('');

            const res = await dispatch(uploadPassportOnboarding(url, 'PassportPhotograph'));
            if (res) {
                setAvatar(res);
            } else {
                setAvatar('');
            }
        } else {
            toast.error('Image is still processing, wait for a sec...');
        }
    };

    if (loading) return <Loader text="Processing" />;
    return (
        <Fragment>
            <DesktopBackgroundLayout>
                {showCropper && <ImageCropper avatar={tempAvatar} onCancel={onCropperCancel} onSave={onCropperSave} />}
                <TopHeader title={'Means of Identification'} />
                <ScreenContainer height={'100%'}>
                    <Formik
                        initialValues={{
                            selectedDocument: documentList?.others?.type || '',
                            selectedDocumentUrl: documentList?.others?.documentName || '',
                            passportPhotograph: 'PassportPhotograph',
                            avatar: avatar || documentList?.passport?.documentName || '',
                        }}
                        enableReinitialize
                        validationSchema={UserMeansOfIDValidationSchema}
                        onSubmit={async (values) => {
                            const res = await dispatch(sendUserDocument2(values));
                            if (res) {
                                setUploadNewId(false);
                                setShowSucessPage(true);
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1000);
                            }
                        }}
                    >
                        {({ values, handleChange, setFieldValue }) => (
                            <ModifiedForm encType="multipart/form-data">
                                <InputBlock>
                                    <FlexContainer>
                                        <FlexDiv align={'center'}>
                                            <CircleOne />
                                            <SectionTitle>Passport photograph</SectionTitle>
                                        </FlexDiv>
                                        <VerificationStatus status={passportState} />
                                    </FlexContainer>

                                    <Wrapper padding>
                                        <PageLogo
                                            id="picture"
                                            Icon={`${values.avatar}?${Date.now()}`}
                                            width="56px"
                                            height="56px"
                                            borderRadius={'50%'}
                                            iconHeight={'56px'}
                                            iconWidth={'56px'}
                                        />
                                        {passportState === DOCUMENT_STATES.REJECTED && (
                                            <UploadButton margin={'0px'}>
                                                {'Upload New'}
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(event) => {
                                                        if (event.target.files[0]) {
                                                            const file = event.target.files[0];
                                                            if (file.size >= 100000000) {
                                                                toast.error('File size cannot be more than 10MB');
                                                                return;
                                                            } else {
                                                                compressImage(file).then((data) => {
                                                                    const url = URL.createObjectURL(data);
                                                                    setShowCropper(true);
                                                                    setTempAvatar(url);
                                                                });
                                                            }
                                                        } else return;
                                                    }}
                                                />
                                            </UploadButton>
                                        )}
                                    </Wrapper>

                                    {passportState === DOCUMENT_STATES.REJECTED && (
                                        <RejectedReasonWrapper>
                                            <ErrorIcon />
                                            <RejectedReasonText>{passportData?.details?.text || ''}</RejectedReasonText>
                                        </RejectedReasonWrapper>
                                    )}

                                    <Divider />

                                    <FlexContainer>
                                        <FlexDiv align={'center'}>
                                            <CircleTwo />
                                            <SectionTitle>Means of ID</SectionTitle>
                                        </FlexDiv>
                                        <VerificationStatus status={documentState} />
                                    </FlexContainer>

                                    {!uploadNewId && (
                                        <InputBlock>
                                            <Wrapper justify={'left'}>
                                                <IconWrapper>
                                                    <FileIcon />
                                                </IconWrapper>
                                                <Wrapper margin={'14px 0px'} direction={'column'} alignItem={'left'}>
                                                    <DocText>{documentList?.others?.type || ''}</DocText>
                                                    <DocName>{userDocumentData?.name || ''}</DocName>
                                                </Wrapper>
                                            </Wrapper>

                                            {documentState === DOCUMENT_STATES.REJECTED && (
                                                <InputBlock>
                                                    <RejectedReasonWrapper>
                                                        <ErrorIcon />
                                                        <RejectedReasonText>
                                                            {documentData?.details?.text || ''}
                                                        </RejectedReasonText>
                                                    </RejectedReasonWrapper>

                                                    <UploadButton
                                                        onClick={() => {
                                                            setFieldValue('selectedDocumentUrl', '');
                                                            setUploadNewId(true);
                                                        }}
                                                    >
                                                        Upload New ID
                                                    </UploadButton>
                                                </InputBlock>
                                            )}
                                        </InputBlock>
                                    )}

                                    {uploadNewId && (
                                        <InputBlock>
                                            <Title color={'#071827'}>
                                                Select a means of identification and upload the document.
                                            </Title>

                                            <SelectBox
                                                name="selectedDocument"
                                                placeholder={'Select a means of identification'}
                                                value={values.selectedDocument}
                                                handleChange={handleChange}
                                                disabled={
                                                    documentState === DOCUMENT_STATES.ACCEPTED ||
                                                    documentState === AGENT_STATES.APPROVED
                                                }
                                                options={[
                                                    {
                                                        value: 'driversLicense',
                                                        label: "Driver's License",
                                                    },
                                                    {
                                                        value: 'personalId',
                                                        label: 'Personal ID',
                                                    },
                                                    {
                                                        value: 'workId',
                                                        label: 'Work ID',
                                                    },
                                                ]}
                                            />

                                            <Title color={'#0B0C0E'}>Upload your means of identification</Title>

                                            <FileInput3
                                                mimeType={'image/*'}
                                                name="selectedDocumentUrl"
                                                compressFile
                                                placeholder={'Click to Upload Document here'}
                                                value={values.selectedDocumentUrl}
                                                label={values.selectedDocument}
                                                handleChange={(value) => {
                                                    setFieldValue('selectedDocumentUrl', value);
                                                }}
                                            />
                                        </InputBlock>
                                    )}
                                </InputBlock>

                                {agentState === AGENT_STATES.INCOMPLETE && (
                                    <RippleButton
                                        type="submit"
                                        disabled={
                                            !values.selectedDocument ||
                                            !values.selectedDocumentUrl ||
                                            !values.avatar ||
                                            loading
                                        }
                                    >
                                        {loading ? 'Loading' : 'Submit'}
                                    </RippleButton>
                                )}
                            </ModifiedForm>
                        )}
                    </Formik>
                </ScreenContainer>

                {showSuccessPage && (
                    <SuccessPage
                        title={'Submitted successfully'}
                        subtitle={'Your document has been submitted successfully.'}
                        doneText={'Ok'}
                        onDoneClick={() => setShowSucessPage(false)}
                    >
                        <SuccessMessage>
                            Please Note, It will take <Bold>1-3 working days</Bold> to approve and Verify your status as
                            an agent.
                        </SuccessMessage>
                    </SuccessPage>
                )}
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default KYCMeansOfID;
