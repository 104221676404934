import React from 'react';

import styled from 'styled-components';

import { colors } from '../../../../styles';
import { ReactComponent as BagIcon } from '../assets/bag.svg';
import { ReactComponent as CardIcon } from '../assets/card.svg';

const Label = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #5c6f7f;
`;
const LabelWrapper = styled.div`
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`;
const Change = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: ${colors.deepBlue};
    cursor: pointer;
`;
const Container = styled.div`
    width: 375px;
    height: 111px;
    background: ${colors.white};
    padding: 16px;
    margin-bottom: 16px;
`;
const ContainerTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.boldDark};
`;
const Space = styled.div`
    height: 4px;
`;
const ContainerDesc = styled.div`
    font-size: 12px;
    line-height: 18px;
    color: #5c6f7f;
    margin-top: 2px;
`;
const CreditContainer = styled.div`
    width: 375px;
    height: 56px;
    background: ${colors.white};
    padding: 16px;
    display: flex;
    align-items: center;
`;

const OptionInnerContainer = styled.div`
    display: flex;
    align-items: center;
`;

const OptionInnerContainerText = styled.div`
    font-size: 14px;
    line-height: 21px;
    color: ${colors.boldDark};
    margin-left: 10px;
`;

const Body = styled.div`
    margin-top: 80px;
`;

const StepTwo = ({ values, state, city, setActiveStep, paymentMethod, payName }) => {
    return (
        <Body>
            <LabelWrapper>
                <Label>DELIVERY DETAILS</Label>
                <Change onClick={() => setActiveStep(0)}>Change</Change>
            </LabelWrapper>
            <Container>
                <ContainerTitle>{values?.fullName}</ContainerTitle>
                <Space />
                <ContainerDesc>{values?.address}</ContainerDesc>
                <ContainerDesc>{city?.name}</ContainerDesc>
                <ContainerDesc>{state?.name} State</ContainerDesc>
            </Container>
            <LabelWrapper>
                <Label>PAYMENT METHOD</Label>
                <Change onClick={() => setActiveStep(0)}>Change</Change>
            </LabelWrapper>
            <CreditContainer>
                <OptionInnerContainer>
                    {payName === 'Pay on Delivery' ? <BagIcon /> : <CardIcon />}
                    <OptionInnerContainerText>{payName}</OptionInnerContainerText>
                </OptionInnerContainer>
            </CreditContainer>
        </Body>
    );
};

export default StepTwo;
