import React, { Fragment, useState } from 'react';

import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import {
    TopHeader,
    UploadPicture,
    SelectBox,
    RippleButton,
    Loader,
    TextInputWithLabel,
    PhoneNumberInput,
    FileInput3,
} from '../../../../../components';
import { ReactComponent as BackArrow } from '../../../../../components/header/assets/back_icon.svg';
import { ImageCropper } from '../../../../../components/popup/image-cropper';
import { InputBlock } from '../../../../../containers/InputContainer';
import { Error } from '../../../../../containers/MessageContainer';
import { SlidingOverlay } from '../../../../../containers/OverlayContainer';
import { ScreenContainer } from '../../../../../containers/ScreenContainer';
import { verifyIdentity } from '../../../../../redux/ducks/applications/merchants/actions';
import { sendAvatar } from '../../../../../redux/ducks/user/actions/avatar';
import { colors } from '../../../../../styles';
import { toTitleCase } from '../../../../../utils/toTitleCase';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import { getStatusColor, getTextColor } from '../../../../home/utils';
import { Back } from '../../customers/customer-details';

import Avatar from './assets/avatar.svg';
import { ReactComponent as Close } from './assets/close.svg';
import { ReactComponent as File } from './assets/file.svg';
import IdentityVerificationInitiated from './verifyIdentityinitiate';
import { VerifyIdentityValidationSchema } from './VerifyIdentityValidationSchema';

export const VerifyIdentityContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 25px;
`;

const TextWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${({ top }) => top || '25px'};
    margin-bottom: ${({ bottom }) => bottom || '2px'};
`;

const Text = styled.h2`
    color: ${({ color }) => color || '#0b0c0e'};
    font-weight: ${({ weight }) => weight || '700'};
    font-size: ${({ size }) => size || '14px'};
    cursor: ${({ cursor }) => cursor || null};
`;

const VerificationStatus = styled.div`
    background-color: ${({ color }) => color};
    padding: 4px 8px;
    display: flex;
    align-items: center;
    height: 24px;
    border-radius: 24px;
    margin-left: auto;
    text-align: center;
`;

const ButtonContainer = styled.div`
    position: relative;
`;

const DisabledBlock = styled.div`
    opacity: 0.5;
`;

const DivText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const UploadBox = styled.div`
    background: ${colors.myShop.packed.bg};
    border-radius: 4px;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
`;

const TextDiv = styled.div`
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 8px;
`;

const TextCover = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const FileDiv = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 62px 0 48px;
`;

const FileStyle = styled(File)`
    position: absolute;
    left: 19px;
`;

const Circle = styled.div`
    border-radius: 50%;
    background: #e2e9f0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 16px;
`;

const VerifyIdentity = ({ open, setOpen }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.applications.merchants.isLoading);
    const merchVerify = useSelector((state) => state.applications.merchants.merchVerify);
    const firstName = useSelector((state) => state.user.firstName);
    const lastName = useSelector((state) => state.user.lastName);
    const msisdn = useSelector((state) => state.user.msisdn);
    const email = useSelector((state) => state.user.email);

    const [showCropper, setShowCropper] = useState(false);

    const [showVerificationInit, setShowVerificationInit] = useState(false);
    const [avatarbase64URL, setAvatarBase64URL] = useState('');
    const [doc, setDoc] = useState(merchVerify?.identificationDocUrl ? merchVerify?.identificationDocUrl : '');
    const [imageBase64, setImageBase64] = useState('');
    const [docBase64, setDocBase64] = useState('');
    const [picture, setPicture] = useState(merchVerify?.imageUrl ? merchVerify?.imageUrl : Avatar);

    const [altLoading, setAltLoading] = useState(false);
    const identificationOption = [
        { label: 'National ID', value: 'NATIONAL_ID' },
        { label: 'Work ID', value: 'WORK_ID' },
        { label: 'Driver License', value: 'DRIVER_LICENSE' },
        { label: 'International Passport', value: 'INTERNATIONAL_PASSPORT' },
    ];

    const displayPick = identificationOption.filter((data) => data.value === merchVerify?.identificationDocType);

    const onCropperCancel = () => {
        setShowCropper(false);
        setPicture(merchVerify?.imageUrl ? imageBase64 : '');
    };
    const onCropperSave = async (url) => {
        if (url) {
            setShowCropper(false);
            setPicture(url);
            dispatch(sendAvatar(url));
        } else {
            toast.error('Image is still processing, wait for a sec...');
        }
    };

    const pAction = (image) => {
        const url = URL.createObjectURL(image);
        setShowCropper(true);
        setPicture(url);
    };

    const getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            };
        });
    };

    getBase64FromUrl(merchVerify?.imageUrl).then(setImageBase64);
    getBase64FromUrl(merchVerify?.identificationDocUrl).then(setDocBase64);
    getBase64FromUrl(picture).then(setAvatarBase64URL);

    const handleSubmit = (values) => {
        const params = {
            avatarImage: values.avatarImage,
            firstName: values.firstName,
            lastName: values.lastName,
            identificationDocType: values.identificationDocType.toUpperCase().split(' ').join('_'),
            identificationDoc: values.identificationDoc,
        };
        setAltLoading(true);
        dispatch(verifyIdentity(params)).then((res) => {
            if (res) {
                setAltLoading(false);
                setShowVerificationInit(true);
            } else {
                setAltLoading(false);
            }
        });
    };

    if (isLoading || altLoading)
        return (
            <SlidingOverlay>
                <Loader />
            </SlidingOverlay>
        );

    return (
        open && (
            <Fragment>
                {showVerificationInit && (
                    <IdentityVerificationInitiated
                        open={showVerificationInit}
                        setOpen={() => setShowVerificationInit(!showVerificationInit)}
                        cancel={setOpen}
                    />
                )}
                {!showVerificationInit && (
                    <SlidingOverlay>
                        <DesktopBackgroundLayout>
                            {showCropper && (
                                <ImageCropper avatar={picture} onCancel={onCropperCancel} onSave={onCropperSave} />
                            )}

                            <TopHeader title={'Verify your identity'}>
                                <Back
                                    onClick={() => {
                                        setOpen();
                                    }}
                                >
                                    <BackArrow />
                                </Back>
                            </TopHeader>
                            <ScreenContainer>
                                <VerifyIdentityContainer>
                                    <UploadPicture
                                        text={'Tap to change image'}
                                        pictureAction={pAction}
                                        showCropper={showCropper}
                                        picture={picture}
                                    />
                                    <Formik
                                        initialValues={{
                                            firstName: firstName,
                                            lastName: lastName,
                                            phoneNumber: msisdn,
                                            avatarImage: merchVerify?.imageUrl ? imageBase64 : picture,
                                            country: 'NG',
                                            email: email,
                                            identificationDocType: displayPick.length > 0 ? displayPick[0].label : '',
                                            identificationDoc: merchVerify?.identificationDocUrl ? docBase64 : '',
                                        }}
                                        validationSchema={VerifyIdentityValidationSchema}
                                        onSubmit={(values, { setErrors }) => {
                                            if (!merchVerify?.imageUrl && picture === Avatar) {
                                                toast.error('Avatar Image is required');
                                            } else {
                                                values.avatarImage = picture ? avatarbase64URL : imageBase64;
                                                handleSubmit(values);
                                            }
                                        }}
                                    >
                                        {({ errors, touched, values, setFieldValue, handleChange, initialValues }) => (
                                            <Form>
                                                <InputBlock>
                                                    <TextInputWithLabel
                                                        label={'First Name'}
                                                        type={'text'}
                                                        color={'#2E4457'}
                                                        value={values.firstName}
                                                        placeholder={'First name'}
                                                        name="firstName"
                                                        valid={`${!touched.firstName && !errors.firstName}`}
                                                        errors={
                                                            touched && touched.firstName && errors && errors.firstName
                                                        }
                                                    />

                                                    <TextInputWithLabel
                                                        label={'Last Name'}
                                                        type={'text'}
                                                        value={values.lastName}
                                                        color={'#2E4457'}
                                                        placeholder={'Last name'}
                                                        name="lastName"
                                                        valid={`${touched.lastName && !errors.lastName}`}
                                                        errors={
                                                            touched && touched.lastName && errors && errors.lastName
                                                        }
                                                    />
                                                    <DisabledBlock>
                                                        <PhoneNumberInput
                                                            label={'Phone Number'}
                                                            type={'number'}
                                                            color={'#2E4457'}
                                                            value={values.phoneNumber}
                                                            placeholder={'Phone Number'}
                                                            name={'phoneNumber'}
                                                            onKeyUp={(e) =>
                                                                (e.target.value = e.target.value.replace(/\s/g, ''))
                                                            }
                                                            valid={`${!touched.phoneNumber && !errors.phoneNumber}`}
                                                            errors={
                                                                touched &&
                                                                touched.phoneNumber &&
                                                                errors &&
                                                                errors.phoneNumber
                                                            }
                                                            readOnly
                                                        />
                                                    </DisabledBlock>
                                                    <DisabledBlock>
                                                        <TextInputWithLabel
                                                            label={'Email address'}
                                                            type={'text'}
                                                            color={'#2E4457'}
                                                            value={values.email}
                                                            placeholder={'Email address'}
                                                            name={'email'}
                                                            inputMode={'email'}
                                                            autoComplete={'email'}
                                                            valid={`${touched.email && !errors.email}`}
                                                            errors={touched && touched.email && errors && errors.email}
                                                            onBlur={(e) => {
                                                                if (!e.target.value) {
                                                                    e.target.value = email;
                                                                }
                                                            }}
                                                            onFocus={(e) => {
                                                                if (values.email.startsWith(msisdn)) {
                                                                    e.target.value = '';
                                                                }
                                                            }}
                                                            readOnly
                                                        />
                                                    </DisabledBlock>

                                                    <TextWrapper>
                                                        <DivText>
                                                            <Text>Means of ID</Text>
                                                        </DivText>
                                                        <VerificationStatus
                                                            color={
                                                                merchVerify?.verificationStatus
                                                                    ? getStatusColor(merchVerify?.verificationStatus)
                                                                    : '#FFC3BD'
                                                            }
                                                        >
                                                            <Text
                                                                color={
                                                                    merchVerify?.verificationStatus
                                                                        ? getTextColor(merchVerify?.verificationStatus)
                                                                        : '#E01A00'
                                                                }
                                                                size={'10px'}
                                                                weight={'400'}
                                                            >
                                                                {merchVerify?.verificationStatus
                                                                    ? toTitleCase(
                                                                          merchVerify?.verificationStatus?.toLowerCase(),
                                                                      )
                                                                    : 'Unverified'}
                                                            </Text>
                                                        </VerificationStatus>
                                                    </TextWrapper>
                                                    <div>
                                                        <SelectBox
                                                            name={'identificationDocType'}
                                                            placeholder={'Select means of Identification'}
                                                            value={values.identificationDocType}
                                                            options={identificationOption}
                                                            handleChange={handleChange}
                                                            valid={`${
                                                                !touched.identificationDocType &&
                                                                !errors.identificationDocType
                                                            }`}
                                                            errors={
                                                                touched &&
                                                                touched.identificationDocType &&
                                                                errors &&
                                                                errors.identificationDocType
                                                            }
                                                            noSearch={true}
                                                            mbottom={'0'}
                                                            padding={'0'}
                                                            ListHeight={'100%'}
                                                            height={'60%'}
                                                        />
                                                        {errors &&
                                                            errors.identificationDocType &&
                                                            errors.identificationDocType && (
                                                                <Error>{errors.identificationDocType}</Error>
                                                            )}
                                                    </div>

                                                    <TextWrapper bottom={'6px'} top={'24px'}>
                                                        <Text weight={'400'}>Upload your ID card here</Text>
                                                    </TextWrapper>
                                                    {!doc && (
                                                        <FileInput3
                                                            mimeType={'image/*, .pdf'}
                                                            name="identificationDoc"
                                                            compressFile
                                                            base64String
                                                            useFileName
                                                            value={values.identificationDoc}
                                                            label={values.identificationDocType}
                                                            handleChange={(value) => {
                                                                setFieldValue('identificationDoc', value);
                                                            }}
                                                            placeholder="Click to Upload Document here"
                                                        />
                                                    )}
                                                    {doc && (
                                                        <UploadBox>
                                                            <FileDiv>
                                                                <FileStyle />
                                                                <TextCover>
                                                                    <TextDiv>{values.identificationDocType}</TextDiv>
                                                                    <Text
                                                                        color={colors.transactions.credit}
                                                                        size={'10px'}
                                                                        weight={'400'}
                                                                    >
                                                                        Upload Successful
                                                                    </Text>
                                                                </TextCover>

                                                                <Circle
                                                                    onClick={() => {
                                                                        setDoc('');
                                                                    }}
                                                                >
                                                                    <Close />
                                                                </Circle>
                                                            </FileDiv>
                                                        </UploadBox>
                                                    )}
                                                </InputBlock>
                                                <ButtonContainer>
                                                    <RippleButton
                                                        type="submit"
                                                        top={'40px'}
                                                        disabled={
                                                            !values.firstName ||
                                                            !values.lastName ||
                                                            !values.identificationDocType === '' ||
                                                            !values.identificationDoc
                                                        }
                                                        height="56px"
                                                    >
                                                        Save
                                                    </RippleButton>
                                                </ButtonContainer>
                                            </Form>
                                        )}
                                    </Formik>
                                </VerifyIdentityContainer>
                            </ScreenContainer>
                        </DesktopBackgroundLayout>
                    </SlidingOverlay>
                )}
            </Fragment>
        )
    );
};

export default VerifyIdentity;
