import React from 'react';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as PlusIcon } from '../assets/plus.svg';

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3c48fc;
    border-radius: 44px;
    box-shadow: 0px 12px 52px rgb(249 215 195 / 50%);
    height: 48px;
    width: 211px;
    position: fixed;
    right: calc(50vw - 170px);
    bottom: 48px;

    @media (max-width: 576px) {
        right: 16px;
    }
`;

const ButtonText = styled.p`
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    margin-left: 16px;
`;

const CreatePaymentButton = () => {
    const history = useHistory();
    return (
        <ButtonWrapper onClick={() => history.push('/actions/payment-links/create')}>
            <PlusIcon />
            <ButtonText>Create Payment Link</ButtonText>
        </ButtonWrapper>
    );
};

export default CreatePaymentButton;
