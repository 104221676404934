import React, { Fragment, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { SwitchTrigger2, TopHeader } from '../../../../components';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import {
    changeStoreStatus,
    getCountryByName,
    getStateByCountryId,
} from '../../../../redux/ducks/applications/store-front/actions/store';
import { colors } from '../../../../styles';
import History from '../../../../utils/History';
import { toTitleCase } from '../../../../utils/toTitleCase';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';

import { ReactComponent as ArrowSvg } from './assets/arrow.svg';
import { ReactComponent as CopySvg } from './assets/copy.svg';
import { ReactComponent as LinkSvg } from './assets/link.svg';
import { ReactComponent as StoreSvg } from './assets/store.svg';

const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

const IconCover = styled.div`
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
`;
const FlexContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${({ bottom }) => bottom || null};
`;

const StatusInnerWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const CopyLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ebecff;
    border: 1px solid #cdd0fe;
    border-radius: 60px;
    width: 94px;
    height: 26px;
`;

const StatusBox = styled.div`
    height: 53px;
    background: #e2fef0;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #93ecc1;
    border-radius: 4px;
    width: 100%;
    margin-top: 37px;
    margin-bottom: 24px;
`;

const Text = styled.div`
    font-size: ${({ size }) => size || '12px'};
    font-weight: ${({ weight }) => weight || '400'};
    color: ${({ color }) => color || '#02075C'};
    margin-bottom: ${({ bottom }) => bottom || null};
    margin-top: ${({ top }) => top || null};
    margin-left: ${({ left }) => left || null};
    margin-right: ${({ right }) => right || null};
    display: ${({ display }) => display || null};
    max-width: ${({ maxWidth }) => maxWidth || null};
    overflow-wrap: ${({ wrap }) => wrap || null};
    text-transform: ${({ transform }) => transform || null};
    text-align: ${({ align }) => align || null};
    width: ${({ width }) => width || null};
    justify-content: ${({ justifyContent }) => justifyContent || null};
    align-items: ${({ alignItems }) => alignItems || null};
    position: ${({ position }) => position || null};
    right: ${({ relRight }) => relRight || null};
`;

const DetailsBox = styled.div`
    background: ${colors.white};
    margin-bottom: 8px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const StoreFrontSettings = () => {
    const dispatch = useDispatch();
    const store = useSelector((state) => state.applications.storeFront.store);
    const country = useSelector((state) => state.applications.storeFront.country);
    const [checkStatus, setCheckStatus] = useState(false);
    const settingsList = ['Store details', 'Delivery Options'];
    const [text] = useState(`${window.location.host}/actions/store_front/${store?.link}`);
    const [copy, setCopy] = useState('Copy link');

    const copyText = async () => {
        await navigator.clipboard.writeText(text);
        setCopy('copied');
        setTimeout(() => {
            setCopy('Copy link');
        }, 2000);
    };

    useEffect(() => {
        dispatch(getCountryByName('Nigeria'));
    }, [dispatch]);

    useEffect(() => {
        if (country[0]?.id) {
            dispatch(getStateByCountryId(country[0]?.id));
        }
    }, [country, dispatch]);

    useEffect(() => {
        if (store.status === 'ACTIVE') {
            setCheckStatus(true);
        }
    }, [store.status]);

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Online Store Settings'} backLink={'/my-shop/storefront'} weight={'400'} />
                <ScreenContainer top={'70px'} color={colors.lightestGray} minHeight={'100%'}>
                    <TopContainer>
                        <IconCover>
                            <StoreSvg />
                        </IconCover>
                        <Text
                            align={'center'}
                            size={'16px'}
                            weight={'500'}
                            color={colors.themeTxtColor10}
                            bottom={'3px'}
                        >
                            {toTitleCase(store.name)}
                        </Text>
                        <FlexContainer bottom={'17px'}>
                            <LinkSvg style={{ marginRight: '10px' }} />
                            <Text size={'14px'} weight={'400'} color={'#5C6F7F'}>
                                {store.link}
                            </Text>
                        </FlexContainer>
                        <FlexContainer>
                            <CopyLink onClick={() => copyText()}>
                                <CopySvg style={{ marginRight: '4px' }} />
                                <Text color={'#3C48FC'}>{copy}</Text>
                            </CopyLink>
                        </FlexContainer>
                    </TopContainer>
                    <StatusBox>
                        <Text
                            size={'14px'}
                            weight={'400'}
                            color={'#05944F'}
                            display={'flex'}
                            alignItems={'center'}
                        >{`Your store is currently ${store.status === 'ACTIVE' ? 'online' : 'offline'}`}</Text>
                        <StatusInnerWrapper>
                            <SwitchTrigger2
                                checkStatus={checkStatus}
                                switchStatus={() => {
                                    if (checkStatus) {
                                        dispatch(changeStoreStatus(store.id, 'INACTIVE'));
                                        setCheckStatus(!checkStatus);
                                    } else {
                                        dispatch(changeStoreStatus(store.id, 'ACTIVE'));
                                        setCheckStatus(!checkStatus);
                                    }
                                }}
                                width={42}
                                height={24}
                                switchOffColor="#BBC0C9"
                                switchOnColor={'#07D571'}
                            />
                        </StatusInnerWrapper>
                    </StatusBox>
                    {settingsList.map((data, index) => (
                        <DetailsBox
                            key={index}
                            onClick={() =>
                                index === 0
                                    ? History.push('/actions/storefront_details')
                                    : History.push('/actions/storefront_delivery_options')
                            }
                        >
                            <Text size={'14px'} weight={'500'} color={colors.themeTextColor10}>
                                {data}
                            </Text>
                            <ArrowSvg />
                        </DetailsBox>
                    ))}
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default StoreFrontSettings;
