import { Field } from 'formik';
import styled from 'styled-components';

import { colors } from '../../../../../styles';

export const Space = styled.div`
    height: ${({ val }) => val || '10px'};
`;

export const Text = styled.div`
    font-size: 14px;
    color: #56636d;
    margin: 15px 0 20px;
`;

export const MapBox = styled.div`
    height: 477px;
    width: 100%;
    margin: 20px 0;
`;

export const InputField = styled(Field)`
    border: none;
    padding: 0 14px;
    height: 48px;
    font-size: 14px;
    width: 100%;
    position: relative;
    background-color: ${colors.border.default};
    outline: none;

    &:not(:placeholder-shown) {
        margin-top: 6px;
    }

    &:not(:placeholder-shown) + label {
        display: block;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 30px ${colors.border.default} inset !important;
    }
`;

export const MarkerContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    &:hover {
      z-index: 1;
    }
  }
`;

export const MarkerPin = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #00cae9;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
    &:after {
        content: '';
        width: 14px;
        height: 14px;
        margin: 8px 0 0 8px;
        background: #e6e6e6;
        position: absolute;
        border-radius: 50%;
    }
    @keyframes bounce {
        0% {
            opacity: 0;
            transform: translateY(-2000px) rotate(-45deg);
        }

        60% {
            opacity: 1;
            transform: translateY(30px) rotate(-45deg);
        }

        80% {
            transform: translateY(-10px) rotate(-45deg);
        }

        100% {
            transform: translateY(0) rotate(-45deg);
        }
    }
`;

export const Pulse = styled.div`
    background: #d6d4d4;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
    &:after {
        content: '';
        border-radius: 50%;
        height: 40px;
        width: 40px;
        position: absolute;
        margin: -13px 0 0 -13px;
        animation: pulsate 1s ease-out;
        animation-iteration-count: infinite;
        opacity: 0;
        box-shadow: 0 0 1px 2px #00cae9;
        animation-delay: 1.1s;
    }

    @keyframes pulsate {
        0% {
            transform: scale(0.1, 0.1);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            transform: scale(1.2, 1.2);
            opacity: 0;
        }
    }
`;
