import React from 'react';

import styled from 'styled-components';

import { RippleButton } from '../../../../../components';
import { BlurBackground } from '../../../../../containers/BlurBackground';
import { SubTitle, Title } from '../../../../../containers/MessageContainer';
import { FlexContainer } from '../../../../../containers/ScreenContainer';
import { colors } from '../../../../../styles';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import { ReactComponent as BlueDeleteIcon } from '../assets/blue-delete.svg';
import { ReactComponent as CheckedBox } from '../assets/selected.svg';

import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { ReactComponent as Close } from './assets/close.svg';

const CardContainer = styled.div`
    position: absolute;
    top: ${({ top }) => top || null};
    width: 100%;
    padding: 0 16px;
    transition: top 0.5s;
`;

const Card = styled.div`
    width: 100%;
    height: 114.89px;
    border-radius: 4px;
    padding: 16px;
    position: relative;
    background-color: rgba(255, 255, 255, 0.15);
`;

const CloseIcon = styled(Close)`
    position: absolute;
    right: 16px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Button = styled(RippleButton)`
    border-radius: 17px;
    height: 24px;
    width: 47px;
    margin-left: 16px;
    font-size: 10px;
    margin-top: 0px;
`;

const BlurPopUpBackground = styled(BlurBackground)`
    bottom: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.84);
    z-index: 100;
`;

const SmallText = styled(SubTitle)`
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
`;

const StyledDelete = styled(BlueDeleteIcon)`
    position: absolute;
    right: 18px;
    top: 85px;
`;

const InnerContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
`;

const TotalWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const CheckoutContainer = styled(FlexContainer)`
    position: absolute;
    bottom: 22px;
    width: 100%;
    padding: 0 16px;
`;

const StyledCheckBox = styled(CheckedBox)`
    position: absolute;
    top: 210px;
    left: 32px;
`;

const StyledArrow = styled(ArrowIcon)`
    position: absolute;
    transition: top 0.5s, bottom 0.5s, left 0.5s, right 0.5s;
`;

const ITEMS = [
    {
        title: 'Select checkbox:',
        subtitle: 'Click on this to select an item in your cart',
    },
    {
        title: 'Deleted:',
        subtitle: 'Remove item from your cart',
    },
    {
        title: 'Checkout:',
        subtitle: 'Checkout with selected items in your cart',
    },
    {
        title: 'Cart Total:',
        subtitle: 'This shows the total value of all selected items in your cart',
    },
];

const CARD_POSITION = {
    1: '280px',
    2: '180px',
    3: 'calc(100vh - 250px)',
    4: 'calc(100vh - 250px)',
};

const ARROW_STYLES = {
    1: {
        top: '-25px',
        left: '23px',
    },
    2: {
        top: '-27px',
        right: '25px',
        transform: 'scale(-1, 1)',
    },
    3: {
        bottom: '-25px',
        transform: 'rotate(180deg)',
        left: '50%',
    },
    4: {
        bottom: '-25px',
        transform: 'rotate(180deg) scale(-1, 1)',
        left: '23px',
    },
};

const SelectiveCheckoutWalkthrough = ({ page = 1, setPage, handleDone, totalPrice, items }) => {
    // Doing this because arrays start from zero
    const position = page - 1;

    return (
        <BlurPopUpBackground>
            <InnerContainer>
                {page === 2 && <StyledDelete />}
                {page === 1 && <StyledCheckBox />}
                <CardContainer top={CARD_POSITION[page]}>
                    <Card>
                        <StyledArrow style={ARROW_STYLES[page]} />
                        <CloseIcon onClick={handleDone} />
                        <Title
                            top={'15px'}
                            color={colors.white}
                            weight={700}
                            size={'12px'}
                            bottom={'0px'}
                            textAlign={'left'}
                        >
                            {ITEMS[position]?.title}
                        </Title>
                        <SmallText color={colors.white}>{ITEMS[position]?.subtitle}</SmallText>

                        <FlexContainer top={'13px'}>
                            <SmallText color={colors.white}>{`${page}/${ITEMS.length}`}</SmallText>
                            <ButtonsContainer>
                                {page > 1 && (
                                    <SmallText color={colors.lightBlue} onClick={() => setPage(page - 1)}>
                                        Back
                                    </SmallText>
                                )}
                                {page < ITEMS.length ? (
                                    <Button onClick={() => setPage(page + 1)}>Next</Button>
                                ) : (
                                    <Button onClick={handleDone}>Done</Button>
                                )}
                            </ButtonsContainer>
                        </FlexContainer>
                    </Card>
                </CardContainer>

                <CheckoutContainer>
                    {page === 4 && (
                        <TotalWrapper>
                            <SubTitle weight={400} font={'12px'} color={colors.white}>
                                Cart Total
                            </SubTitle>
                            <SubTitle color={colors.white} font={'16px'} weight={'700'}>
                                {formatPrice(totalPrice)}
                            </SubTitle>
                        </TotalWrapper>
                    )}
                    {page === 3 && (
                        <RippleButton style={{ marginLeft: 'auto' }} width="50%" top={'1px'} onClick={null}>
                            Checkout ({items?.length || 0})
                        </RippleButton>
                    )}
                </CheckoutContainer>
            </InnerContainer>
        </BlurPopUpBackground>
    );
};

export default SelectiveCheckoutWalkthrough;
