import { sortOrderItems } from '../../../../utils/orders/sortOrderItems';
import { USER_LOGOUT } from '../../user/constants';

import {
    LOADING_SHOP,
    UPDATE_ERROR,
    FETCH_SHOP_SALES_DATA,
    FETCH_SHOP_INVENTRY_DATA,
    STOP_LOADING,
    LOADING_SHOP_ORDER,
    MY_SHOP_SUCCESS,
    MY_SHOP_ERROR,
    SAVE_SHOP,
    SAVE_SALE,
    DELETE_SHOP,
    UPDATE_SHOP,
    ADD_INVENTORY,
    ADD_PRODUCT,
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_ID,
    UPDATE_INVENTORY_PRODUCT_ID,
    UPDATE_PRODUCT_IMAGE,
    DELETE_PRODUCT,
    UPDATE_PRODUCT_QUANTITY,
    TOGGLE_MERCH_VISIBLE,
    DELETE_SALE,
    UPDATE_SALE,
    UPDATE_SALE_ID,
    SAVE_CUSTOMER,
    GET_CUSTOMERS,
    GET_TOTAL,
    DELETE_CUSTOMER,
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_ID,
    UPDATE_CUSTOMER_OWING_STATUS,
    MY_SHOP_CATEGORIES,
    MY_SHOP_ORDERS_SUCCESS,
    MY_SHOP_ORDERS_FAILURE,
    MY_SHOP_ORDER_BY_ID_SUCCESS,
    MY_SHOP_ORDER_BY_ID_FAILURE,
    MY_SHOP_ORDER_UPDATE_PRODUCTS,
    FETCH_PRODUCTS_MASTER_LIST,
    NO_PRODUCTS_ON_MASTER_LIST,
    MY_SHOP_PRODUCT_CATEGORIES,
    PROCESS_SHOP_ORDERS_SUCCESS,
    PROCESS_SHOP_ORDERS_FAILURE,
    BULK_PRICE_UPDATE,
    UPDATE_BANK_ACCOUNT_DETAILS,
    TOGGLE_PAYMENT_OPTIONS,
    LOADING_SALES_REPORT,
    YEARLY_SALES_REPORT_SUCCESS,
    MONTHLY_SALES_REPORT_SUCCESS,
    WEEKLY_SALES_REPORT_SUCCESS,
    DAILY_SALES_REPORT_SUCCESS,
    SALES_REPORTS_EXTRA_DATA_SUCCESS,
    SALES_REPORT_FAILURE,
    LOADING_PRODUCT_REPORT,
    PRODUCT_REPORT_SUCCESS,
    PRODUCT_REPORT_FAILURE,
    DATED_SALES_REPORT_SUCCESS,
    DATE_RANGE_SALES_REPORT_SUCCESS,
    LOADING_CUSTOMERS_AND_REFERRALS,
    FETCH_CUSTOMERS_AND_REFERRALS_SUCCESS,
    UPDATE_CUSTOMERS_AND_REFERRALS,
    LOADING_DELIVERY_DETAILS,
    FETCH_DELIVERY_DETAILS_SUCCESS,
    FETCH_LIST_OF_DEBTORS,
    FETCH_DEBTOR,
    LOADING_DEBTORS,
    FETCH_TOTAL_DEBT_AMOUNT,
    FETCH_DEBT_REMINDER,
    SET_REMINDER_SUCCESS,
    FETCH_CUSTOMER,
} from './constants';

const initialState = {
    isLoading: false,
    loadingShopOrder: false,
    loadingSalesReport: false,
    loadingProductsReport: false,
    errorMsg: '',
    error: '',
    shops: [],
    sales: [],
    orders: [],
    customers: [],
    total: 0,
    totalCustomers: [],
    businessCategories: [],
    productsMasterList: [],
    productCategories: [],
    yearlySalesReport: [],
    monthlySalesReport: [],
    weeklySalesReport: [],
    dailySalesReport: [],
    salesReportExtra: {},
    productsReport: [],
    productsReportExtra: {},
    dateRangeSalesReport: [],
    specificDaySalesReport: [],
    customersAndReferrals: [],
    deliveryDetails: {},
    debtors: [],
    debtor: {},
    loadingDebtors: false,
    customer: {},
};

export default function myShopReducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_CUSTOMERS_AND_REFERRALS: {
            if (state.customersAndReferrals && state.customersAndReferrals.length > 0) {
                return {
                    ...state,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    isLoading: true,
                };
            }
        }

        case FETCH_CUSTOMERS_AND_REFERRALS_SUCCESS: {
            return {
                ...state,
                customersAndReferrals: action.payload,
            };
        }

        case LOADING_DELIVERY_DETAILS: {
            if (state.deliveryDetails && Object.keys(state.deliveryDetails).length > 0) {
                return {
                    ...state,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    isLoading: true,
                };
            }
        }

        case FETCH_DELIVERY_DETAILS_SUCCESS: {
            return {
                ...state,
                deliveryDetails: action.payload,
            };
        }

        case UPDATE_CUSTOMERS_AND_REFERRALS: {
            const { id, customerInfo } = action.payload;
            // Get the index of the of item to update;
            const index = state.customersAndReferrals.findIndex((contact) => contact.userId === id);

            // Create a new copy of the state to mutate;
            const customersAndReferrals = [...state.customersAndReferrals];

            /* Mutate the state by the index */
            customersAndReferrals[index].fullName = customerInfo.name || customerInfo.receiverName;
            customersAndReferrals[index].address = customerInfo.address || customerInfo.homeAddress.address;
            customersAndReferrals[index].localGovt = customerInfo.lga || customerInfo.homeAddress.lga;
            customersAndReferrals[index].state = customerInfo.state || customerInfo.homeAddress.state;
            customersAndReferrals[index].city = customerInfo.city || customerInfo.homeAddress.city;
            customersAndReferrals[index].phoneNumber = customerInfo.phoneNumber;
            customersAndReferrals[index].deliveryInstruction = customerInfo.deliveryInstruction;
            return {
                ...state,
                customersAndReferrals,
            };
        }

        case FETCH_SHOP_SALES_DATA: {
            if (state.sales && state.sales.length > 0) {
                return {
                    ...state,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    isLoading: true,
                };
            }
        }

        case FETCH_SHOP_INVENTRY_DATA: {
            const shopId = action.payload;
            const shop = state.shops.find((shop) => shop.id === shopId);
            if (shop && shop.inventory && shop.inventory.length > 0) {
                return {
                    ...state,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    isLoading: true,
                };
            }
        }

        case LOADING_SHOP: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case LOADING_SHOP_ORDER: {
            return {
                ...state,
                loadingShopOrder: true,
            };
        }

        case LOADING_SALES_REPORT: {
            return {
                ...state,
                loadingSalesReport: true,
            };
        }

        case LOADING_PRODUCT_REPORT: {
            return {
                ...state,
                loadingProductsReport: true,
            };
        }

        case MY_SHOP_SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case MY_SHOP_ERROR: {
            return {
                ...state,
                isLoading: false,
                errorMsg: action.payload,
            };
        }

        case UPDATE_ERROR: {
            return {
                ...state,
                error: action.payload,
            };
        }

        case SAVE_SHOP: {
            return {
                ...state,
                shops: action.payload,
            };
        }

        case BULK_PRICE_UPDATE: {
            const shopId = action.payload.shopId;
            const products = action.payload.payload;
            return {
                ...state,
                shops: state.shops.map((shop) => {
                    if (shop.id === shopId) {
                        shop.inventory.map((product) => {
                            const foundProduct = products.find((x) => x.productId === product.inventoryProductId);
                            if (foundProduct) {
                                product.retailUnitPrice = foundProduct.retailPrice;
                            }
                            return product;
                        });
                    }
                    return shop;
                }),
            };
        }

        case ADD_INVENTORY: {
            const shopId = action.payload.shopId;
            const inventory = action.payload.inventory;

            return {
                ...state,
                shops: state.shops.map((shop) => {
                    if (shop.id === shopId) {
                        shop.inventory = inventory;
                    }

                    return shop;
                }),
            };
        }

        case UPDATE_SHOP: {
            const shopId = action.payload.shopId;
            const {
                businessCategories,
                //businessCategoryNames,
                //productCategories,
                //available,
                avatar,
                details,
                base64BranchImageString,
                slug,
                businessSlug,
                location,
                listingOptions,
                accountDetails,
                paymentOption,
                shopName,
                merchbuyLink,
                updatedAt,
            } = action.payload.newData;

            return {
                ...state,
                shops: state.shops.map((shop) => {
                    if (shop.id === shopId) {
                        //shop.streetAddress = location && location.address;
                        //shop.countryState = location && location.state;
                        //shop.lga = location && location.localGovt;
                        shop.base64BranchImageString = base64BranchImageString;
                        shop.businessCategories = businessCategories;
                        shop.businessSlug = businessSlug;
                        shop.location = location;
                        shop.slug = slug;
                        shop.details = details;
                        shop.avatar = avatar;
                        shop.listingOptions = listingOptions;
                        shop.accountDetails = accountDetails;
                        shop.paymentOption = paymentOption;
                        shop.shopName = shopName;
                        shop.merchbuyLink = merchbuyLink;
                        shop.updatedAt = updatedAt;
                    }
                    return shop;
                }),
            };
        }

        case DELETE_SHOP: {
            return {
                ...state,
                shops: state.shops.filter((shop) => shop.id !== action.payload),
            };
        }

        case TOGGLE_MERCH_VISIBLE: {
            const shopId = action.payload.shopId;
            const isOnMerchBuy = action.payload.isOnMerchBuy;
            const isOnMerchList = action.payload.isOnMerchList;

            return {
                ...state,
                shops: state.shops.map((shop) => {
                    if (shop.id === shopId) {
                        shop.listingOptions = {
                            isOnMerchBuy: isOnMerchBuy,
                            isOnMerchList: isOnMerchList,
                        };
                    }

                    return shop;
                }),
            };
        }

        case UPDATE_BANK_ACCOUNT_DETAILS: {
            const shopId = action.payload.shopId;
            const accountDetails = action.payload.accountDetails;
            const paymentOption = action.payload.paymentOption;

            return {
                ...state,
                shops: state.shops.map((shop) => {
                    if (shop.id === shopId) {
                        shop.accountDetails = accountDetails;
                        shop.paymentOption = paymentOption;
                    }

                    return shop;
                }),
            };
        }

        case TOGGLE_PAYMENT_OPTIONS: {
            const shopId = action.payload.shopId;
            const paymentOption = action.payload.paymentOption;

            return {
                ...state,
                shops: state.shops.map((shop) => {
                    if (shop.id === shopId) {
                        shop.paymentOption = paymentOption;
                    }

                    return shop;
                }),
            };
        }

        case SAVE_SALE: {
            const branchId = action.payload.branchId;
            const salesInfo = action.payload.salesInfo;

            return {
                ...state,
                sales: state.sales.concat({ branchId, salesInfo }),
            };
        }

        case UPDATE_SALE: {
            const sales = state.sales.slice();
            const saleId = action.payload.id;
            const amountCollected = action.payload.amountCollected;
            const amountOutstanding = action.payload.amountOutstanding;
            const paymentCompleteStatus = action.payload.paymentCompleteStatus;

            return {
                ...state,
                sales: sales.map((sale) => {
                    if (sale.salesInfo.id === saleId) {
                        sale.salesInfo.amountCollected = amountCollected;
                        sale.salesInfo.amountOutstanding = amountOutstanding;
                        sale.salesInfo.paymentCompleteStatus = paymentCompleteStatus;
                    }

                    return sale;
                }),
            };
        }

        case UPDATE_SALE_ID: {
            const request = action.payload.request;
            const response = action.payload.response;

            return {
                ...state,
                sales: state.sales.map((sale) => {
                    if (sale.id === request.payload.id) {
                        sale.id = response.data.data.salesRecordViewDto.id;
                    }

                    return sale;
                }),
            };
        }

        case DELETE_SALE: {
            return {
                ...state,
                sales: state.sales.filter((sale) => sale.salesInfo.id !== action.payload),
            };
        }

        case ADD_PRODUCT: {
            const shopId = action.payload.shopId;
            const productData = action.payload.productData;

            return {
                ...state,
                shops: state.shops.map((shop) => {
                    if (shop.id === shopId) {
                        shop.inventory.push(productData);
                    }

                    return shop;
                }),
            };
        }

        case UPDATE_PRODUCT: {
            const shopId = action.payload.shopId;
            const productId = action.payload.productId;
            const {
                productName,
                productDescription,
                productCategory,
                productUnit,
                costPrice,
                availableAtRetailPrice,
                retailUnitPrice,
                availableAtWholesalePrice,
                wholesaleUnitPrice,
                moq,
                bulkPrices,
                base64ProductImageString,
                otherImagesBase64String,
                images,
                localSettings,
                masterListImageUrl,
            } = action.payload.productDTO || {};

            return {
                ...state,
                shops: state.shops.map((shop) => {
                    if (shop.id === shopId) {
                        shop.inventory.map((product) => {
                            if (product.id === productId) {
                                product.productName = productName;
                                product.productDescription = productDescription;
                                product.productCategory = productCategory;
                                product.costPrice = costPrice;
                                product.productUnit = productUnit;
                                product.costPrice = costPrice;
                                product.availableAtRetailPrice = availableAtRetailPrice;
                                product.retailUnitPrice = retailUnitPrice;
                                product.availableAtWholesalePrice = availableAtWholesalePrice;
                                product.wholesaleUnitPrice = wholesaleUnitPrice;
                                product.moq = moq;
                                product.bulkPrices = bulkPrices;
                                product.base64ProductImageString = base64ProductImageString;
                                product.otherImagesBase64String = otherImagesBase64String;
                                if (product.images) {
                                    product.images.baseImageUrl =
                                        images && images.baseImageUrl ? images.baseImageUrl : '';
                                    product.images.others = images && images.others;
                                }
                                product.masterListImageUrl = masterListImageUrl;
                                product.localSettings = localSettings;
                            }

                            return product;
                        });
                    }

                    return shop;
                }),
            };
        }

        case UPDATE_PRODUCT_QUANTITY: {
            const shopId = action.payload.shopId;
            const productId = action.payload.productId;
            const quantity = action.payload.quantity;

            return {
                ...state,
                shops: state.shops.map((shop) => {
                    if (shop.id === shopId) {
                        shop.inventory.map((product) => {
                            if (product.id === productId) {
                                product.quantity = quantity;
                            }

                            return product;
                        });
                    }

                    return shop;
                }),
            };
        }

        case UPDATE_PRODUCT_ID: {
            const request = action.payload.request;
            const response = action.payload.response;
            const [, , shopId] = new URL(request.url).pathname.split('/')[3];

            return {
                ...state,
                shops: state.shops.map((shop) => {
                    if (shop.id === shopId) {
                        shop.inventory.map((product) => {
                            if (product.id === request.payload.id) {
                                product.id = response.data.id;
                            }

                            return product;
                        });
                    }

                    return shop;
                }),
            };
        }

        case UPDATE_INVENTORY_PRODUCT_ID: {
            const request = action.payload.request;
            const response = action.payload.response;
            const [, , shopId] = new URL(request.url).pathname.split('/')[3];

            return {
                ...state,
                shops: state.shops.map((shop) => {
                    if (shop.id === shopId) {
                        shop.inventory.map((product) => {
                            if (product.inventoryProductId === request.payload.inventoryProductId) {
                                product.inventoryProductId = response.data.inventoryProductId;
                            }

                            return product;
                        });
                    }

                    return shop;
                }),
            };
        }

        case UPDATE_PRODUCT_IMAGE: {
            const request = action.payload.request;
            const response = action.payload.response;
            const [, , shopId] = new URL(request.url).pathname.split('/')[3];

            return {
                ...state,
                shops: state.shops.map((shop) => {
                    if (shop.id === shopId) {
                        shop.inventory.map((product) => {
                            if (product.id === response.data.id) {
                                product.base64ProductImageString = response.data.base64ProductImageString;
                            }

                            return product;
                        });
                    }

                    return shop;
                }),
            };
        }

        case DELETE_PRODUCT: {
            const shopId = action.payload.shopId;
            const productId = action.payload.productId;

            return {
                ...state,
                shops: state.shops.map((shop) => {
                    if (shop.id === shopId) {
                        shop.inventory = shop.inventory.filter((product) => product.id !== productId);
                    }

                    return shop;
                }),
            };
        }

        case SAVE_CUSTOMER: {
            return {
                ...state,
                customers: state.customers.concat(action.payload),
            };
        }

        case GET_CUSTOMERS: {
            return {
                ...state,
                customers: action.payload,
            };
        }

        case GET_TOTAL: {
            return {
                ...state,
                total: action.payload,
            };
        }

        case UPDATE_CUSTOMER: {
            const customerId = action.payload.id;
            const customerInfo = action.payload.customerInfo;

            return {
                ...state,
                customers: state.customers.map((customer) => {
                    if (customer.id === customerId) {
                        customer.name = customerInfo.name;
                        customer.phoneNumber = customerInfo.phoneNumber;
                        customer.phoneNumber2 = customerInfo.phoneNumber2;
                        customer.email = customerInfo.email;

                        customer.homeAddress = {
                            address: customerInfo.homeAddress.address,
                            state: customerInfo.homeAddress.state,
                            lga: customerInfo.homeAddress.lga,
                            city: customerInfo.homeAddress.city,
                        };

                        customer.bank = {
                            name: customerInfo.bank.name,
                            accountNumber: customerInfo.bank.accountNumber,
                        };
                        customer.deliveryInstruction = customerInfo.deliveryInstruction;
                    }

                    return customer;
                }),
            };
        }

        case UPDATE_CUSTOMER_ID: {
            const previousId = action.payload.previousId;
            const currentId = action.payload.currentId;

            return {
                ...state,
                customers: state.customers.map((customer) => {
                    if (customer.id === previousId) {
                        customer.id = currentId;
                    }

                    return customer;
                }),
            };
        }

        case UPDATE_CUSTOMER_OWING_STATUS: {
            const customerId = action.payload.customerId;
            const status = action.payload.status;

            return {
                ...state,
                customers: state.customers.map((customer) => {
                    if (customer.id === customerId) {
                        customer.owing = status;
                    }

                    return customer;
                }),
            };
        }

        case DELETE_CUSTOMER: {
            return {
                ...state,
                customers: state.customers.filter((customer) => customer.id !== action.payload),
            };
        }

        case MY_SHOP_CATEGORIES: {
            return {
                ...state,
                businessCategories: action.payload,
            };
        }

        case MY_SHOP_PRODUCT_CATEGORIES: {
            return {
                ...state,
                productCategories: action.payload,
            };
        }

        case MY_SHOP_ORDERS_SUCCESS: {
            return {
                ...state,
                loadingShopOrder: false,
                orders: action.payload,
                errorMsg: '',
            };
        }

        case MY_SHOP_ORDERS_FAILURE: {
            return {
                ...state,
                loadingShopOrder: false,
                orders: [],
                errorMsg: action.payload,
            };
        }

        case MY_SHOP_ORDER_BY_ID_SUCCESS: {
            return {
                ...state,
                loadingShopOrder: false,
                orders: state.orders.map((order) => {
                    if (order.orderNumber === action.payload.orderNumber && order.orderItems === null) {
                        order.orderItems = sortOrderItems([...action.payload.orderItems]);
                    }

                    return order;
                }),
                errorMsg: '',
            };
        }

        case MY_SHOP_ORDER_BY_ID_FAILURE: {
            return {
                ...state,
                loadingShopOrder: false,
                orders: state.orders.map((order) => {
                    if (order.orderNumber === action.payload.orderNumber && order.orderItems === null) {
                        order.orderItems = {};
                    }
                    return order;
                }),
                errorMsg: action.payload,
            };
        }

        case MY_SHOP_ORDER_UPDATE_PRODUCTS: {
            return {
                ...state,
                loadingShopOrder: false,
                orders: state.orders.map((order) => {
                    if (order.orderNumber === action.payload.orderNumber) {
                        order.orderItems = action.payload.orderItems;
                    }

                    return order;
                }),
                errorMsg: '',
            };
        }

        case FETCH_PRODUCTS_MASTER_LIST: {
            return {
                ...state,
                productsMasterList: action.payload,
            };
        }

        case NO_PRODUCTS_ON_MASTER_LIST: {
            return {
                ...state,
                productsMasterList: [],
            };
        }

        case PROCESS_SHOP_ORDERS_SUCCESS: {
            return {
                ...state,
                loadingShopOrder: false,
            };
        }

        case PROCESS_SHOP_ORDERS_FAILURE: {
            return {
                ...state,
                loadingShopOrder: false,
                errorMsg: action.payload,
            };
        }

        case YEARLY_SALES_REPORT_SUCCESS: {
            return {
                ...state,
                loadingSalesReport: false,
                yearlySalesReport: action.payload,
            };
        }
        case MONTHLY_SALES_REPORT_SUCCESS: {
            return {
                ...state,
                loadingSalesReport: false,
                monthlySalesReport: action.payload,
            };
        }
        case WEEKLY_SALES_REPORT_SUCCESS: {
            return {
                ...state,
                loadingSalesReport: false,
                weeklySalesReport: action.payload,
            };
        }
        case DAILY_SALES_REPORT_SUCCESS: {
            return {
                ...state,
                loadingSalesReport: false,
                dailySalesReport: action.payload,
            };
        }
        case DATED_SALES_REPORT_SUCCESS: {
            return {
                ...state,
                loadingSalesReport: false,
                specificDaySalesReport: action.payload,
            };
        }
        case DATE_RANGE_SALES_REPORT_SUCCESS: {
            return {
                ...state,
                loadingSalesReport: false,
                dateRangeSalesReport: action.payload,
            };
        }
        case SALES_REPORTS_EXTRA_DATA_SUCCESS: {
            return {
                ...state,
                salesReportExtra: action.payload,
            };
        }

        case SALES_REPORT_FAILURE: {
            return {
                ...state,
                loadingSalesReport: false,
                salesReportExtra: {},
            };
        }

        case PRODUCT_REPORT_SUCCESS: {
            return {
                ...state,
                loadingProductsReport: false,
                productsReport: action.payload.data,
                productsReportExtra: action.payload.extras,
            };
        }

        case PRODUCT_REPORT_FAILURE: {
            return {
                ...state,
                loadingProductsReport: false,
                productsReport: [],
                productsReportExtra: {},
            };
        }

        case LOADING_DEBTORS: {
            return {
                ...state,
                loadingDebtors: action.payload,
            };
        }

        case FETCH_LIST_OF_DEBTORS: {
            const newState = { ...state.debtors, allDebtors: [...action.payload] };
            return {
                ...state,
                debtors: newState,
            };
        }

        case FETCH_DEBTOR: {
            return {
                ...state,
                debtor: { ...state.debtor, debtorInfo: action.payload },
            };
        }

        case FETCH_DEBT_REMINDER: {
            return {
                ...state,
                debtor: { ...state.debtor, reminderStatus: action.payload },
            };
        }

        case SET_REMINDER_SUCCESS: {
            return {
                ...state,
                debtor: { ...state.debtor, reminderSuccess: action.payload },
            };
        }

        case FETCH_TOTAL_DEBT_AMOUNT: {
            return {
                ...state,
                debtors: { ...state.debtors, totalDebtAmount: action.payload },
            };
        }

        case FETCH_CUSTOMER: {
            const newState = { ...action.payload };
            return {
                ...state,
                customer: newState,
            };
        }

        case USER_LOGOUT: {
            return {
                isLoading: false,
                loadingShopOrder: false,
                errorMsg: '',
                shops: [],
                sales: [],
                orders: [],
                customers: [],
                yearlySalesReport: [],
                monthlySalesReport: [],
                weeklySalesReport: [],
                dailySalesReport: [],
                salesReportExtra: {},
                productsReport: [],
                productsReportExtra: {},
                dateRangeSalesReport: [],
                specificDaySalesReport: [],
                businessCategories: [],
                debtors: [],
                customer: {},
            };
        }

        default: {
            return state;
        }
    }
}
