import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { getReasonForBlockedUser } from '../redux/ducks/account/wallet/actions';

export const useReasonForBlockingUserWallet = () => {
    const dispatch = useDispatch();
    const [reasonForBlocking, setReasonForBlocking] = useState({});

    useEffect(() => {
        dispatch(getReasonForBlockedUser()).then((res) => {
            res && setReasonForBlocking(res);
        });
    }, [dispatch]);

    return reasonForBlocking;
};
