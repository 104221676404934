import { toast } from 'react-toastify';

import { axios, currentAPI } from '../../../../../config';
import history from '../../../../../utils/History';
import { resetCounter } from '../../../timer/actions';
import {
    SAVE_TRANSACTIONS,
    LOADING_TRANSACTIONS,
    TRANSACTIONS_LOADED,
    TRANSACTIONS_LOAD_ERROR,
    CAN_RESET_TRANSACTION_PIN,
    SAVE_PAYMENT_TRANSACTIONS,
} from '../constants';

export const saveTransactions = (transactions) => (dispatch) => {
    dispatch({ type: SAVE_TRANSACTIONS, payload: transactions });
};

export const getWalletTransactions = () => async (dispatch, getState) => {
    if (!navigator.onLine) return;

    try {
        const getWalletTransactionsResponse = await axios.get(
            `${currentAPI}/api/wallet/v2/user/walletTransactionDetails?pageSize=10000`,
        );
        if (getWalletTransactionsResponse.status === 200) {
            const receivedTransactions = getWalletTransactionsResponse?.data?.content || [];
            dispatch({ type: SAVE_TRANSACTIONS, payload: receivedTransactions });
        }
    } catch (error) {
        //
    }
};

export const getPaymentLinkTransactions = () => async (dispatch) => {
    if (!navigator.onLine) return;

    try {
        const response = await axios.get(`${currentAPI}/api/paymentLink/user/list?pageSize=50`);
        if (response.status === 200) {
            let transactions = response?.data?.content || [];
            transactions.forEach((transaction) => {
                if (transaction.status === 'OPENED') {
                    transaction.transactionType = 'PAYMENT_LINK_OPEN';
                    transaction.status = 'PENDING';
                } else {
                    transaction.transactionType = 'PAYMENT_LINK_CLOSED';
                }
            });
            dispatch({ type: SAVE_PAYMENT_TRANSACTIONS, payload: transactions });
        }
    } catch (error) {
        //
    }
};

export const getTransactionDetail = (ref) => async (dispatch) => {
    dispatch({ type: LOADING_TRANSACTIONS });
    try {
        const response = await axios.get(
            `${currentAPI}/api/wallet/v2/user/walletTransactionDetails?transactionReference=${ref}&walletTransactionsSearchTerm=TRANSACTION_REFERENCE&userTransactionsSortCriteria=DESC`,
        );
        if (response.status === 200) {
            const { content } = response.data || {};
            dispatch({ type: TRANSACTIONS_LOADED });
            if (content && content.length > 0) {
                return content[0];
            }
        }
    } catch (error) {
        dispatch({ type: TRANSACTIONS_LOAD_ERROR, payload: 'error' });
        if (error.response && error.response.status === 400) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.message);
        }
    }
};

export const verifyAppPassword = (payload) => async (dispatch) => {
    dispatch({ type: LOADING_TRANSACTIONS });

    try {
        const response = await axios.post(`${currentAPI}/api/users/verifyAppPassword`, payload);
        if (response.status === 200) {
            return response.data || {};
        }
    } catch (error) {
        return {
            message: '',
            status: false,
        };
    } finally {
        dispatch({ type: TRANSACTIONS_LOADED });
    }
};

export const verifyOtp = (payload) => async (dispatch) => {
    dispatch({ type: LOADING_TRANSACTIONS });

    try {
        const response = await axios.post(`${currentAPI}/api/users/verifyTransactionPinResetOtp`, payload);

        if (response.status === 200) {
            return response.data || {};
        }
    } catch (error) {
        return {
            message: 'Incorrect PIN',
            status: false,
        };
    } finally {
        dispatch({ type: TRANSACTIONS_LOADED });
    }
};

export const resetPin = (payload) => async (dispatch) => {
    dispatch({ type: LOADING_TRANSACTIONS });

    try {
        const response = await axios.post(`${currentAPI}/api/users/resetTransactionPin`, payload);

        if (response.status === 200) {
            return response.data || {};
        }
    } catch (error) {
        return {
            message: '',
            status: false,
        };
    } finally {
        dispatch({ type: TRANSACTIONS_LOADED });
    }
};

export const resendOtp = () => async (dispatch) => {
    dispatch({ type: LOADING_TRANSACTIONS });

    try {
        const response = await axios.post(`${currentAPI}/api/users/resendTransactionResetOtp`);

        if (response.status === 200) {
            dispatch(resetCounter());
            return true;
        }
    } catch (error) {
        if (error.response && error.response.status === 400) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.message);
            dispatch(resetCounter());
            history.goBack();
        }
    } finally {
        dispatch({ type: TRANSACTIONS_LOADED });
    }
};

export const getValidationOnPin = () => async (dispatch) => {
    try {
        const response = await axios.get(`${currentAPI}/api/users/validateResetTransactionPinRequest`);

        if (response.status === 200) {
            const { status } = response.data || {};
            dispatch({ type: CAN_RESET_TRANSACTION_PIN, payload: status });
        }
    } catch (error) {
        if (error.response && error.response.status === 400) {
            dispatch({ type: CAN_RESET_TRANSACTION_PIN, payload: false });
        }
    }
};
