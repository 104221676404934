const GET_ALL_CHALLENGES = '/api/games/challenges-for-today';
const GET_QUESTION_ANSWER = '/api/games/get-question-answer';
const SUBMIT_CHALLENGE = '/api/games/update-user-submission';
const CREATE_CHALLENGE = '/api/games/create_challenge';
const UPDATE_USERNAME = '/api/users/update_games_username';
const GET_SUBMISSIONS_FOR_TODAY = '/api/games/submissions-for-today';
const GET_GAMES_USERNAME = '/api/users/get-user-games-username';
const GET_TOP_USERS = '/api/games/top-users';

export const api = {
    GET_ALL_CHALLENGES,
    GET_QUESTION_ANSWER,
    SUBMIT_CHALLENGE,
    CREATE_CHALLENGE,
    UPDATE_USERNAME,
    GET_SUBMISSIONS_FOR_TODAY,
    GET_GAMES_USERNAME,
    GET_TOP_USERS,
};
