import { goBack } from 'connected-react-router';
import { toast } from 'react-toastify';

import { SETTINGS_KYC } from '../../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../../utils/mix-panel/mixPanel';
import { sendUserInfo, getUserAdditionalInfo } from '../../../user/actions';
import { SAVE_USER_REGION, SAVE_USER_ADDRESS } from '../../../user/constants';
import { USER_KYC_UPLOADING } from '../constants';

export const sendKYCRegion = (regionData) => async (dispatch, getState) => {
    if (!navigator.onLine) {
        toast.error('You can not perform this action offline...');
        return;
    }

    dispatch({ type: USER_KYC_UPLOADING, payload: true });
    const userId = getState().user.userId;
    const role = getState().user.role;

    try {
        const sendKYCRegionResponse = await dispatch(sendUserInfo(regionData));

        if (sendKYCRegionResponse.status === 200) {
            await dispatch(getUserAdditionalInfo());
            dispatch({ type: USER_KYC_UPLOADING, payload: false });
            dispatch({ type: SAVE_USER_REGION, payload: regionData });
            mixPanel.track(SETTINGS_KYC, { 'User ID': userId, Role: role });
            dispatch(goBack());
            toast.success('Your region information was successfully updated');
        }
    } catch (error) {
        dispatch({ type: USER_KYC_UPLOADING, payload: false });
        if (error.response && error.response.status === 400) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.message);
        }
    }
};

export const sendKYCAddress = (address) => async (dispatch, getState) => {
    if (!navigator.onLine) {
        toast.error('You can not perform this action offline...');
        return;
    }

    dispatch({ type: USER_KYC_UPLOADING, payload: true });

    try {
        const houseAddress = getState().account.kyc.houseAddress;
        const sendKYCAddressResponse = await dispatch(sendUserInfo({ houseAddress }));

        if (sendKYCAddressResponse.status === 200) {
            dispatch({ type: USER_KYC_UPLOADING, payload: false });
            dispatch({ type: SAVE_USER_ADDRESS, payload: address });
            dispatch(goBack());
            toast.success('Your address was successfully updated');
        }
    } catch (error) {
        dispatch({ type: USER_KYC_UPLOADING, payload: false });
        if (error.response && error.response.status === 400) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.message);
        }
    }
};
