import React, { useState, Fragment, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { LightButton } from '../../../../../components/button';
import { List } from '../../../../../containers/ListContainer';
import { Overlay } from '../../../../../containers/OverlayContainer';
import { FlexContainer } from '../../../../../containers/ScreenContainer';
import { orderStatus } from '../../../../../enums';
import { saveLastActionUrl, redirectedFromMerchbuy } from '../../../../../redux/ducks/account/wallet/actions';
import { merchbuyActions } from '../../../../../redux/ducks/applications/merchbuy/actions';
import { colors } from '../../../../../styles';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import History from '../../../../../utils/History';
import { ReactComponent as Check } from '../../assets/radio.svg';
import { ReactComponent as UnCheck } from '../../assets/radio_uncheck.svg';
import { PaymentDetailsWrap } from '../../styles';
import { ReactComponent as ActivateIcon } from '../assets/activate_icon.svg';
import { ReactComponent as Bank } from '../assets/bank.svg';
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { ReactComponent as Debit } from '../assets/debit_card.svg';
import { ReactComponent as Delivery } from '../assets/pay_on_delivery.svg';
import { ReactComponent as Wallet } from '../assets/spaces_wallet.svg';

const ContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
`;

const PaymentOptionWrapper = styled.div`
    min-height: 56px;
    max-height: 73px;
    border-radius: 4px;
    background: ${colors.white};
    margin: 0 1em 8px 1em;
    border: 1px solid ${({ borderColor }) => borderColor || colors.gray4};
`;

const DetailWrapper = styled.div`
    display: grid;
    gap: 6px;
`;

const Text = styled.p`
    margin: ${({ margin }) => margin || '0'};
    font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
    font-size: ${({ font }) => font || '12px'};
    color: ${({ color }) => color || null};
    text-align: ${({ align }) => align || null};
    width: ${({ width }) => width || null};
    padding-top: ${({ top }) => top || null};
    text-align: ${({ align }) => align || null};
`;

const TextBalance = styled.span`
    color: ${colors.red};
`;

const TextWrapper = styled.div`
    display: flex;
    align-items: ${({ align }) => align};
    justify-content: flex-start;
`;

const ActivationContainer = styled.div`
    min-height: 377px;
    background-color: white;
    border-radius: 15px 21px 0 0;
    padding: 42px 21px 32px 24px;
    z-index: 100000;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 375px;

    @media (max-width: 576px) {
        width: 100%;
    }
`;

const ActivateWalletText = styled.h6`
    color: ${colors.textActivate};
    font-size: 12px;
    font-weight: 400;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
`;

const DeactivateTitleText = styled.h3`
    color: ${colors.bgBlue};
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 16px;
`;

const DeactivateTitleDescription = styled.h5`
    color: ${colors.lightDark};
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
`;

const Payment = ({
    priceInfo,
    setPaymentMethod,
    setStep,
    deliveryDetails,
    setOpenPayment,
    openPayment,
    setOpenFeatureFixes,
}) => {
    const dispatch = useDispatch();
    const realms = useSelector((state) => state.user.realmNames);
    const walletBalance = useSelector((state) => state.account.wallet.balance || 0);
    const walletStatus = useSelector((state) => state.account.wallet.status);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
    const [canUserPayWithWallet] = useState(walletBalance >= priceInfo.totalPrice);
    const features = useSelector((state) => state.user.features);
    const [openActivatePopup, setOpenActivatePopup] = useState(false);

    const paymentMethods = [
        {
            value: 'wallet',
            name: 'Sabi Wallet',
            balance: walletBalance,
            icon: <Wallet />,
            enum: 3,
            featureName: 'payWithWallet',
        },
        {
            icon: <Bank />,
            value: 'transfer',
            name: 'Bank Transfer',
            enum: 5,
            featureName: 'payWithBankTransfer',
        },
        {
            icon: <Debit />,
            value: 'paystack',
            name: 'Debit / Credit Card',
            enum: 2,
            featureName: 'payWithCard',
        },
    ];

    realms &&
        !realms.includes('PAYMENT ON DELIVERY') &&
        paymentMethods.push({
            icon: <Delivery />,
            name: 'Pay on Delivery',
            value: 'pod',
            enum: 1,
            featureName: 'payOnDelivery',
        });

    useEffect(() => {
        const handleOnContinue = () => {
            if (selectedPaymentMethod?.value === 'wallet' && walletStatus === 'INACTIVE') {
                setOpenActivatePopup(true);
            } else if (selectedPaymentMethod?.value === 'wallet' && !canUserPayWithWallet) {
                toast.error('Insufficient balance in wallet. Please top-up your wallet');
                setOpenPayment(false);
            } else {
                setPaymentMethod(selectedPaymentMethod);
                setOpenPayment(false);
            }
        };
        if (selectedPaymentMethod) {
            handleOnContinue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPaymentMethod]);

    const onCancel = () => {
        if (!selectedPaymentMethod) {
            setStep(orderStatus.delivery);
            setOpenPayment(false);
        } else {
            setOpenPayment(false);
        }
    };

    const clickAction = (method) => {
        if ((method.value === 'wallet' && !features?.wallet?.status) || !features?.[method?.featureName]?.status) {
            setOpenPayment(false);
            return setOpenFeatureFixes(true);
        }
        setSelectedPaymentMethod(method);
    };

    return (
        <Fragment>
            {!openActivatePopup ? (
                <>
                    {openPayment && <Overlay onClick={onCancel} bgc={'rgba(0,0,0,0.4)'} zIndex="99999" />}
                    <ActivationContainer>
                        <FlexContainer bottom={'28px'}>
                            <Text color={colors.themeTextColor3} font={'16px'} fontWeight={700}>
                                Select Payment Method
                            </Text>
                            <CloseIcon onClick={onCancel} />
                        </FlexContainer>

                        <List fullScreen>
                            {paymentMethods &&
                                paymentMethods.map((method) => (
                                    <PaymentOptionWrapper
                                        key={method.value}
                                        onClick={() => clickAction(method)}
                                        borderColor={
                                            selectedPaymentMethod && selectedPaymentMethod.value === method.value
                                                ? colors.deepBlue
                                                : null
                                        }
                                    >
                                        <ContentWrapper>
                                            <DetailWrapper>
                                                <TextWrapper align={method?.name === 'Sabi Wallet' ? null : 'center'}>
                                                    {method.icon}
                                                    <PaymentDetailsWrap>
                                                        <Text color={colors.boldDark} font="14px">
                                                            {method.name}
                                                        </Text>
                                                        {method.balance >= 0 && (
                                                            <Text margin="2px 0" color={colors.lightDark}>
                                                                Balance{' '}
                                                                <TextBalance>{formatPrice(method.balance)}</TextBalance>
                                                            </Text>
                                                        )}
                                                    </PaymentDetailsWrap>
                                                </TextWrapper>
                                            </DetailWrapper>
                                            {selectedPaymentMethod && selectedPaymentMethod.value === method.value ? (
                                                <Check />
                                            ) : (
                                                <UnCheck />
                                            )}
                                        </ContentWrapper>
                                    </PaymentOptionWrapper>
                                ))}
                        </List>
                    </ActivationContainer>
                </>
            ) : (
                <>
                    {openActivatePopup && (
                        <Overlay onClick={() => setOpenActivatePopup(false)} bgc={'rgba(0,0,0,0.4)'} zIndex="99999" />
                    )}
                    <ActivationContainer>
                        <IconWrapper>
                            <ActivateIcon />
                            <ActivateWalletText>Activate your wallet</ActivateWalletText>
                        </IconWrapper>
                        <DeactivateTitleText>Your wallet has not been activated</DeactivateTitleText>
                        <DeactivateTitleDescription>
                            Provide more information about yourself so we can help verify and activate your wallet.
                        </DeactivateTitleDescription>
                        <LightButton
                            onClick={() => {
                                dispatch(saveLastActionUrl('/actions/merchbuy/checkout'));
                                dispatch(redirectedFromMerchbuy(true));
                                dispatch(merchbuyActions.saveLastDeliveryAddress(deliveryDetails));
                                History.push('/user/account_bank_data');
                            }}
                            size="16px"
                            weight="500"
                            color={colors.white}
                            background={colors.deepBlue}
                        >
                            Activate Wallet
                        </LightButton>
                    </ActivationContainer>
                </>
            )}
        </Fragment>
    );
};

export default Payment;
