import { USER_LOGOUT } from '../../user/constants';

import {
    LOADING,
    FETCH_ALL_PRODUCTS_BY_CATEGORY_ID_SUCCESS,
    FETCH_ALL_PRODUCTS_BY_CATEGORY_ID_FAILURE,
    FETCH_RECOMMENDED_PRODUCTS_SUCCESS,
    FETCH_RECOMMENDED_PRODUCTS_FAILURE,
    FETCH_POPULAR_PRODUCTS_SUCCESS,
    FETCH_POPULAR_PRODUCTS_FAILURE,
    FETCH_PRODUCTS_CATEGORIES_SUCCESS,
    FETCH_PRODUCTS_CATEGORIES_FAILURE,
    FETCH_SHOPS_SUCCESS,
    FETCH_SHOP,
    FETCH_SHOPS,
    FETCH_SHOPS_FAILURE,
    FETCH_PRODUCT_BY_ID_SUCCESS,
    FETCH_PRODUCT_BY_ID_FAILURE,
    FETCH_SHOP_BY_ID_SUCCESS,
    FETCH_SHOP_BY_ID_FAILURE,
    FETCH_AVAILABLE_STATES_SUCCESS,
    FETCH_AVAILABLE_STATES_FAILURE,
    FETCH_PRODUCTS_BY_SHOP_ID_SUCCESS,
    FETCH_PRODUCTS_BY_SHOP_ID_FAILURE,
    FETCH_SHOP_CART,
    FETCH_SHIPPING_OPTIONS,
    SAVE_LAST_ORDER_PLACED,
    SERVICE_CHARGE_PERCENT,
    MERCHBUY_DELIVERY_LOCATION,
    MERCHBUY_ORDER_HISTORY_SUCCESS,
    MERCHBUY_ORDER_HISTORY_FAILURE,
    PROCESSING_ORDER,
    SET_CHOSEN_ORDER_HISTORY,
    FETCH_CHOSEN_ORDER_HISTORY_FAILURE,
    CLEAR_CHOSEN_ORDER_HISTORY,
    LOADING_ORDER_HISTORY,
    LOADING_ORDER_HISTORIES,
    GROUPBUY_ORDER_HISTORY_SUCCESS,
    LOADING_GROUPBUY_ORDER_HISTORY,
    GROUPBUY_ORDER_HISTORY_FAILURE,
    FETCH_ALL_CITIES_SUCCESS,
    FETCH_ALL_CITIES_FAILURE,
    GROUP_BUY_EDUCATION,
    MERCHBUY_PAGINATED_SHOP_HISTORY_FAILURE,
    MERCHBUY_PAGINATED_PRODUCT_HISTORY_FAILURE,
    MERCHBUY_PAGINATED_PRODUCT_HISTORY_SUCCESS,
    MERCHBUY_PAGINATED_SHOP_HISTORY_SUCCESS,
    FETCH_SHIPPING_CONFIGURATION,
    SAVE_PRODUCT_SEARCH_TERM,
    FETCH_MERCHBUY_DEALS_SUCCESS,
    FETCH_MERCHBUY_DEALS_FAILURE,
    FETCH_MERCHBUY_SEARCH_HISTORY_SUCCESS,
    FETCH_MERCHBUY_SEARCH_HISTORY_FAILURE,
    FETCH_MERCHBUY_CATEGORY_BANNER_SUCCESS,
    FETCH_MERCHBUY_CATEGORY_BANNER_FAILURE,
    FETCH_MERCHBUY_ALL_PRODUCTS_SUCCESS,
    FETCH_MERCHBUY_ALL_PRODUCTS_FAILURE,
    FETCH_MERCHBUY_CART_CATEGORY_SUCCESS,
    FETCH_MERCHBUY_CART_CATEGORY_FAILURE,
    FETCH_CATEGORIES,
    MERCHBUY_PAGINATED_ORDER_HISTORY_SUCCESS,
    MERCHBUY_FILTER_ORDER_HISTORY_SUCCESS,
    MERCHBUY_ORDER_HISTORY_BY_ITEMS_SUCCESS,
    MERCHBUY_PAGINATED_ORDER_HISTORY_BY_ITEMS_SUCCESS,
    MERCHBUY_FILTER_ORDER_HISTORY_BY_ITEMS_SUCCESS,
    DELIVERY_ADDRESS_EDIT_LIMIT,
    SAVE_LAST_DELIVERY_ADDRESS,
    MINIMUM_ORDER_VALUE,
    FETCH_AVAILABLE_COUNTRIES_FAILURE,
    FETCH_AVAILABLE_COUNTRIES_SUCCESS,
    FETCH_AVAILABLE_STATE_SUCCESS,
    FETCH_AVAILABLE_STATE_FAILURE,
    FETCH_AVAILABLE_LGA_SUCCESS,
    FETCH_AVAILABLE_LGA_FAILURE,
    FETCH_AVAILABLE_CITIES_SUCCESS,
    FETCH_SAVED_LIST,
    FETCH_PRODUCT_NOTIFICATION_LIST,
    FETCH_PRODUCTS_WITH_CATEGORIES,
    FETCH_PRODUCTS_WITH_CATEGORIES_SUCCESS,
    FETCH_PRODUCTS_WITH_CATEGORIES_FAILURE,
} from './constants';

const initialState = {
    isLoading: false,
    processingOrder: false,
    errorMsg: '',
    availableStates: [],
    cities: [],
    countries: {},
    states: {},
    lga: [],
    details: [],
    merchbuyShops: [],
    merchbuyProducts: [], //TODO remove
    merchbuyCategoryProducts: [],
    merchbuyProductsByShopId: [],
    merchbuyProductsByShopTotal: 0,
    merchbuyRecommendedProducts: [],
    merchbuyPopularProducts: [],
    merchbuyProductCategeries: [],
    merchbuyProductCategoriesTotalElements: 0,
    merchbuyProductWithCategeries: [],
    merchbuyProductWithCategoriesTotalElements: 0,
    merchbuyDealProducts: [],
    merchbuySearchHistoryProducts: {
        products: [],
        searchTerms: [],
    },
    merchbuyCategoryBanners: [],
    merchbuyAllProducts: [],
    merchbuyCartCategory: [],
    shop: {},
    product: {},
    shopCart: [],
    deliveryLocation: {},
    shippingOptions: [],
    order: {},
    serviceChargePercentage: 0,
    chosenOrderHistory: {},
    orderHistory: [],
    totalOrders: 0,
    groupBuyOrderHistory: [],
    totalElements: 0,
    minimumOrderValue: {},
    deliveryAddressEditLimit: {},
    shippingConfiguration: {},
    groupBuyEducation: false,
    productsSearch: '',
    city: [],
    totalOrdersByItems: 0,
    orderHistoryByItems: [],
    lastSavedDeliveryAddress: {},
    savedList: [],
    savedListPages: 1,
    productNotificationList: [],
};

export default function merchBuyReducer(state = initialState, action) {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }

        case PROCESSING_ORDER: {
            return {
                ...state,
                processingOrder: action.payload,
            };
        }

        case FETCH_CATEGORIES: {
            if (state.merchbuyProductCategeries && state.merchbuyProductCategeries.length > 0) {
                return {
                    ...state,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    isLoading: true,
                };
            }
        }

        case FETCH_PRODUCTS_WITH_CATEGORIES: {
            if (state.merchbuyProductByCategeries && state.merchbuyProductByCategeries.length > 0) {
                return {
                    ...state,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    isLoading: true,
                };
            }
        }

        case FETCH_SHIPPING_CONFIGURATION: {
            return {
                ...state,
                shippingConfiguration: action.payload,
            };
        }

        case CLEAR_CHOSEN_ORDER_HISTORY:
            return {
                ...state,
                chosenOrderHistory: {},
            };

        case FETCH_AVAILABLE_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: action.payload,
            };
        case FETCH_SAVED_LIST:
            return {
                ...state,
                savedList: action.payload,
                savedListPages: action.size,
            };

        case FETCH_AVAILABLE_COUNTRIES_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorMsg: action.payload,
            };

        case FETCH_AVAILABLE_STATE_SUCCESS:
            return {
                ...state,
                states: action.payload,
            };

        case FETCH_AVAILABLE_STATE_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorMsg: action.payload,
            };

        case FETCH_AVAILABLE_LGA_SUCCESS:
            return {
                ...state,
                lga: action.payload,
            };

        case FETCH_AVAILABLE_CITIES_SUCCESS:
            return {
                ...state,
                city: action.payload,
            };

        case FETCH_AVAILABLE_LGA_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorMsg: action.payload,
            };

        case FETCH_CHOSEN_ORDER_HISTORY_FAILURE: {
            return {
                ...state,
                isLoading: false,
                errorMsg: action.payload,
            };
        }

        case SET_CHOSEN_ORDER_HISTORY: {
            return {
                ...state,
                isLoading: false,
                chosenOrderHistory: action.payload,
            };
        }

        case LOADING_ORDER_HISTORY: {
            if (state.orderHistory && state.orderHistory.length > 0) {
                const orderHistoryById = state.orderHistory.find((x) => x.id === action.payload);
                return {
                    ...state,
                    orderHistoryById,
                };
            } else {
                return {
                    ...state,
                    isLoading: true,
                };
            }
        }

        case FETCH_ALL_PRODUCTS_BY_CATEGORY_ID_SUCCESS: {
            const { categoryId, content, totalElements, isFirstPage } = action.payload || {};
            const categories = state.merchbuyCategoryProducts || [];
            const filteredCategories = categories.filter((x) => x.categoryId !== categoryId) || [];
            let insertCategory = { categoryId, totalElements, products: content };

            if (!isFirstPage) {
                const products = (categories.find((x) => x.categoryId === categoryId) || {})?.products || [];
                let result = products.filter((x) => !content.some((i) => i.id === x.id));
                insertCategory = { ...insertCategory, products: result.concat(content) };
            }

            return {
                ...state,
                merchbuyProducts: content,
                merchbuyCategoryProducts: filteredCategories.concat(insertCategory),
            };
        }

        case FETCH_ALL_PRODUCTS_BY_CATEGORY_ID_FAILURE: {
            const { errorMsg } = action.payload || {};
            return {
                ...state,
                merchbuyProducts: [],
                errorMsg: errorMsg,
            };
        }

        case FETCH_RECOMMENDED_PRODUCTS_SUCCESS: {
            return {
                ...state,
                merchbuyRecommendedProducts: action.payload,
                errorMsg: '',
            };
        }

        case FETCH_RECOMMENDED_PRODUCTS_FAILURE: {
            return {
                ...state,
                merchbuyRecommendedProducts: [],
                errorMsg: action.payload,
            };
        }

        case FETCH_POPULAR_PRODUCTS_SUCCESS: {
            return {
                ...state,
                merchbuyPopularProducts: action.payload,
                errorMsg: '',
            };
        }

        case FETCH_POPULAR_PRODUCTS_FAILURE: {
            return {
                ...state,
                merchbuyPopularProducts: [],
                errorMsg: action.payload,
            };
        }

        case FETCH_PRODUCTS_CATEGORIES_SUCCESS: {
            const { productCategory, isFirstPage, totalElements } = action.payload || {};
            let insertCategories = [...productCategory];

            if (!isFirstPage) {
                const result =
                    state.merchbuyProductCategeries.filter((x) => !productCategory.some((i) => i.id === x.id)) || [];
                insertCategories = result.concat(productCategory);
            }

            return {
                ...state,
                merchbuyProductCategeries: insertCategories,
                merchbuyProductCategoriesTotalElements: totalElements,
            };
        }

        case FETCH_PRODUCTS_CATEGORIES_FAILURE: {
            return {
                ...state,
                merchbuyProductCategeries: [],
                errorMsg: action.payload,
            };
        }

        case FETCH_PRODUCTS_WITH_CATEGORIES_SUCCESS: {
            return {
                ...state,
                merchbuyProductWithCategeries: action.payload,
                // merchbuyProductWithCategoriesTotalElements: totalElements,
            };
        }

        case FETCH_PRODUCTS_WITH_CATEGORIES_FAILURE: {
            return {
                ...state,
                merchbuyProductWithCategeries: [],
                errorMsg: action.payload,
            };
        }

        case FETCH_SHOPS: {
            if (state.merchbuyShops && state.merchbuyShops.length > 0) {
                return {
                    ...state,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    isLoading: true,
                };
            }
        }

        case MERCHBUY_PAGINATED_SHOP_HISTORY_SUCCESS: {
            return {
                ...state,
                totalElements: action.payload,
                errorMsg: '',
            };
        }

        case MERCHBUY_PAGINATED_SHOP_HISTORY_FAILURE: {
            return {
                ...state,
                totalElements: 0,
                errorMsg: action.payload,
            };
        }

        case MERCHBUY_PAGINATED_PRODUCT_HISTORY_SUCCESS: {
            return {
                ...state,
                totalElements: action.payload,
                errorMsg: '',
            };
        }
        case MERCHBUY_PAGINATED_PRODUCT_HISTORY_FAILURE: {
            return {
                ...state,
                totalElements: 0,
                errorMsg: action.payload,
            };
        }

        case FETCH_SHOPS_SUCCESS: {
            return {
                ...state,
                merchbuyShops: action.payload.shops,
                totalElements: action.payload.totalElements,
                errorMsg: '',
            };
        }

        case FETCH_SHOPS_FAILURE: {
            return {
                ...state,
                merchbuyShops: [],
                errorMsg: action.payload,
            };
        }

        case FETCH_SHOP_BY_ID_SUCCESS: {
            return {
                ...state,
                shop: action.payload,
                errorMsg: '',
            };
        }

        case FETCH_SHOP_BY_ID_FAILURE: {
            return {
                ...state,
                errorMsg: action.payload,
            };
        }

        case FETCH_SHOP: {
            if (state.merchbuyShops && state.merchbuyShops.length > 0) {
                const shopById = state.merchbuyShops.find((x) => x.id === action.payload);
                return {
                    ...state,
                    shopById,
                };
            } else {
                return {
                    ...state,
                    isLoading: true,
                };
            }
        }

        case FETCH_PRODUCT_BY_ID_SUCCESS: {
            return {
                ...state,
                product: action.payload,
                errorMsg: '',
            };
        }

        case FETCH_PRODUCT_BY_ID_FAILURE: {
            return {
                ...state,
                errorMsg: action.payload,
            };
        }

        case FETCH_MERCHBUY_DEALS_SUCCESS: {
            return {
                ...state,
                merchbuyDealProducts: action.payload,
            };
        }

        case FETCH_MERCHBUY_DEALS_FAILURE: {
            return {
                ...state,
                merchbuyDealProducts: [],
            };
        }

        case FETCH_MERCHBUY_SEARCH_HISTORY_SUCCESS: {
            return {
                ...state,
                merchbuySearchHistoryProducts: { ...action.payload },
            };
        }

        case FETCH_MERCHBUY_SEARCH_HISTORY_FAILURE: {
            return {
                ...state,
                merchbuySearchHistoryProducts: [],
            };
        }

        case FETCH_MERCHBUY_ALL_PRODUCTS_SUCCESS: {
            return {
                ...state,
                merchbuyAllProducts: action.payload,
            };
        }

        case FETCH_MERCHBUY_ALL_PRODUCTS_FAILURE: {
            return {
                ...state,
                merchbuyAllProducts: [],
            };
        }

        case FETCH_MERCHBUY_CART_CATEGORY_SUCCESS: {
            return {
                ...state,
                merchbuyCartCategory: action.payload,
            };
        }

        case FETCH_MERCHBUY_CART_CATEGORY_FAILURE: {
            return {
                ...state,
                merchbuyCartCategory: [],
            };
        }

        case FETCH_MERCHBUY_CATEGORY_BANNER_SUCCESS: {
            return {
                ...state,
                merchbuyCategoryBanners: action.payload,
            };
        }

        case FETCH_MERCHBUY_CATEGORY_BANNER_FAILURE: {
            return {
                ...state,
                merchbuyCategoryBanners: [],
            };
        }

        case FETCH_AVAILABLE_STATES_SUCCESS: {
            return {
                ...state,
                availableStates: action.payload,
                errorMsg: '',
            };
        }

        case FETCH_AVAILABLE_STATES_FAILURE: {
            return {
                ...state,
                product: [],
                errorMsg: action.payload,
            };
        }

        case FETCH_ALL_CITIES_SUCCESS: {
            return {
                ...state,
                cities: action.payload,
            };
        }
        case FETCH_ALL_CITIES_FAILURE: {
            return {
                ...state,
                cities: [],
                errorMsg: action.payload,
            };
        }

        case FETCH_PRODUCTS_BY_SHOP_ID_SUCCESS: {
            return {
                ...state,
                merchbuyProductsByShopId: action.payload.products,
                merchbuyProductsByShopTotal: action.payload.totalItems,
                errorMsg: '',
            };
        }
        case FETCH_PRODUCTS_BY_SHOP_ID_FAILURE: {
            return {
                ...state,
                merchbuyProductsByShopId: [],
                merchbuyProductsByShopTotal: 0,
                errorMsg: action.payload,
            };
        }

        case FETCH_SHOP_CART: {
            return {
                ...state,
                shopCart: action.payload,
            };
        }

        case SERVICE_CHARGE_PERCENT: {
            return {
                ...state,
                serviceChargePercentage: action.payload,
            };
        }

        case FETCH_SHIPPING_OPTIONS: {
            return {
                ...state,
                shippingOptions: action.payload,
            };
        }

        case MERCHBUY_DELIVERY_LOCATION: {
            return {
                ...state,
                deliveryLocation: action.payload,
            };
        }

        case SAVE_LAST_ORDER_PLACED: {
            return {
                ...state,
                order: action.payload,
            };
        }

        case LOADING_ORDER_HISTORIES: {
            if (state.orderHistory && state.orderHistory.length > 0) {
                return {
                    ...state,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    isLoading: true,
                };
            }
        }

        case LOADING_GROUPBUY_ORDER_HISTORY: {
            if (state.groupBuyOrderHistory && state.groupBuyOrderHistory.length > 0) {
                return {
                    ...state,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    isLoading: true,
                };
            }
        }

        case MERCHBUY_ORDER_HISTORY_SUCCESS: {
            return {
                ...state,
                orderHistory: action.payload.orders,
                totalOrders: action.payload.totalOrders,
                errorMsg: '',
            };
        }

        case MERCHBUY_PAGINATED_ORDER_HISTORY_SUCCESS:
        case MERCHBUY_FILTER_ORDER_HISTORY_SUCCESS: {
            return {
                ...state,
                totalOrders: action.payload,
                errorMsg: '',
            };
        }

        case MERCHBUY_ORDER_HISTORY_BY_ITEMS_SUCCESS: {
            return {
                ...state,
                orderHistoryByItems: action.payload.orders,
                totalOrdersByItems: action.payload.totalOrders,
                errorMsg: '',
            };
        }

        case MERCHBUY_PAGINATED_ORDER_HISTORY_BY_ITEMS_SUCCESS:
        case MERCHBUY_FILTER_ORDER_HISTORY_BY_ITEMS_SUCCESS: {
            return {
                ...state,
                totalOrdersByItems: action.payload,
                errorMsg: '',
            };
        }

        case GROUPBUY_ORDER_HISTORY_SUCCESS: {
            return {
                ...state,
                groupBuyOrderHistory: action.payload,
                errorMsg: '',
            };
        }

        case MERCHBUY_ORDER_HISTORY_FAILURE: {
            return {
                ...state,
                orderHistory: [],
                errorMsg: action.payload,
            };
        }

        case GROUPBUY_ORDER_HISTORY_FAILURE: {
            return {
                ...state,
                groupBuyOrderHistory: [],
                errorMsg: action.payload,
            };
        }

        case GROUP_BUY_EDUCATION: {
            return {
                ...state,
                groupBuyEducation: true,
            };
        }

        case SAVE_PRODUCT_SEARCH_TERM: {
            return {
                ...state,
                productsSearch: action.payload,
            };
        }

        case SAVE_LAST_DELIVERY_ADDRESS: {
            return {
                ...state,
                lastSavedDeliveryAddress: action.payload,
            };
        }

        case DELIVERY_ADDRESS_EDIT_LIMIT: {
            return {
                ...state,
                deliveryAddressEditLimit: action.payload,
            };
        }

        case MINIMUM_ORDER_VALUE: {
            return {
                ...state,
                minimumOrderValue: action.payload,
            };
        }

        case FETCH_PRODUCT_NOTIFICATION_LIST: {
            return {
                ...state,
                productNotificationList: action.payload,
            };
        }

        case USER_LOGOUT: {
            return {
                isLoading: false,
                processingOrder: false,
                errorMsg: '',
                availableStates: [],
                merchbuyShops: [],
                merchbuyProducts: [],
                merchbuyCategoryProducts: [],
                merchbuyRecommendedProducts: [],
                merchbuyPopularProducts: [],
                merchbuyProductCategeries: [],
                shop: {},
                //deliveryLocation: {},
                product: {},
                productsSearch: '',
                deliveryAddressEditLimit: {},
            };
        }

        default: {
            return state;
        }
    }
}
