import React, { Fragment, useState, useEffect } from 'react';

import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Loader, TopHeader, PasswordGroup, PageLogo, ResendCodeTimer } from '../../../components';
import { Message, PageCaption, Strong } from '../../../containers/MessageContainer';
import { ScreenContainer } from '../../../containers/ScreenContainer';
import { sendUssd, sendCode } from '../../../redux/ducks/auth/check/actions';
import { tick, setExpired } from '../../../redux/ducks/timer/actions';
import { insertZero } from '../../../utils/inputs/formatPhoneNumber';
import { getInputValues } from '../../../utils/inputs/getInputValues';
import DesktopBackgroundLayout from '../../DesktopBackgroundLayout';

import MessageLogo from './assets/phone-digit.svg';
import { CodeCheckFormValidationSchema } from './CodeCheckFormValidationSchema';

const Check = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const phoneNumber = useSelector((state) => state.user.msisdn);
    const isLoading = useSelector((state) => state.auth.check.isLoading);
    const expired = useSelector((state) => state.timer.expired);
    const counter = useSelector((state) => state.timer.counter);
    const [value, setValue] = useState('');
    const [startTime, setStartTime] = useState('');
    const [code, setCode] = useState('');

    useEffect(() => {
        setStartTime(new Date());
    }, []);

    useEffect(() => {
        if (code.length === 6) {
            dispatch(sendCode(code, startTime, 'ONBOARDING'));
        }
    }, [dispatch, code, startTime]);

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader noTitle />
                <ScreenContainer top={'64px'}>
                    <PageLogo
                        background={'transparent'}
                        borderRadius={'0'}
                        margin={'20px auto 35px'}
                        width={'100px'}
                        height={'100px'}
                        iconWidth={'100px'}
                        iconHeight={'100px'}
                        Icon={MessageLogo}
                    />
                    <PageCaption>Enter OTP</PageCaption>
                    <Message align={'left'} top={'8px'} bottom={'24px'} maxWidth={'328px'} color={'#718596'}>
                        Kindly provide the 6-digit OTP was sent to your phone number{' '}
                        <Strong>{insertZero(phoneNumber)}</Strong>
                    </Message>
                    <Message size={'10px'} align={'left'} top={'8px'} lineHeight={'inherit'} color={'#718596'}>
                        OTP
                    </Message>
                    <Formik
                        initialValues={{
                            code: '',
                        }}
                        validationSchema={CodeCheckFormValidationSchema}
                        onSubmit={(values) => {}}
                    >
                        {({ errors, values, valid, touched, setFieldValue }) => {
                            const handlePasswordChange = (event) => {
                                setValue(event.target.value);
                                setFieldValue('code', getInputValues('code'));
                                setCode(getInputValues('code'));
                            };
                            return (
                                <Form>
                                    <PasswordGroup
                                        align={'center'}
                                        count={6}
                                        startIndex={1}
                                        type="number"
                                        valid={valid}
                                        name="code"
                                        errors={errors}
                                        touched={touched}
                                        enteredValue={value}
                                        handleChange={handlePasswordChange}
                                    />
                                    <ResendCodeTimer
                                        showWhatsAppButton={false}
                                        counter={counter}
                                        expired={expired}
                                        sendUssd={() => dispatch(sendUssd())}
                                        setExpired={(condition) => dispatch(setExpired(condition))}
                                        tick={(counter) => dispatch(tick(counter))}
                                        resendCode={() =>
                                            history.push({
                                                pathname: '/otp_options',
                                                state: { resend: true },
                                            })
                                        }
                                    />
                                </Form>
                            );
                        }}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default Check;
