import React, { Fragment, useState } from 'react';

import { Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ConfirmPopupDialog, RippleButton, SelectBox, TopHeader } from '../../../../../components';
import { ScreenContainer } from '../../../../../containers/ScreenContainer';
import { merchbuyActions } from '../../../../../redux/ducks/applications/merchbuy/actions';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import { ReactComponent as EditSvg } from '../../assets/edit.svg';

import { ValidationSchema } from './ValidationSchema';

const EditIcon = styled(EditSvg)`
    right: 0;
    position: absolute;
    margin-right: 16px;
    width: 17px;
`;

const ShoppingLocation = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [showDialog, setShowDialog] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [dialogText, setDialogText] = useState('Are you sure you want to update your shopping location?');
    const [dialogConfirmButtonText, setDialogConfirmButtonText] = useState('No');
    const currentLocation =
        localStorage.getItem('deliveryLocation') && JSON.parse(localStorage.getItem('deliveryLocation'));

    const listOfAvailableStates = useSelector((state) => state.applications.merchbuy.availableStates);

    const cartItems = useSelector((state) => state.applications.merchbuy.shopCart);

    const onSubmit = (location) => {
        if (cartItems.length > 0) {
            setDialogText('You have items in your cart. Changing your shopping location will clear your cart.');
            setDialogConfirmButtonText('Checkout');
        }
        setShowDialog(true);
        setSelectedLocation(location);
    };

    const updateLocationAction = (variant) => {
        setShowDialog(false);
        if (variant === 'Checkout') return history.push('/actions/merchbuy/cart');
        else if (variant === 'Update location') {
            localStorage.setItem('deliveryLocation', JSON.stringify(selectedLocation));
            dispatch(merchbuyActions.deleteAllItemsFromCart());
            return history.push('/actions/merchbuy');
        }
    };

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title="Shopping Location" backLink="/actions/merchbuy/account" withSpacesHeader>
                    <EditIcon />
                </TopHeader>
                <ScreenContainer top="70px">
                    <ConfirmPopupDialog
                        open={showDialog}
                        title={'Update shopping location'}
                        confirmationText={dialogText}
                        answers={[
                            {
                                variant: `${dialogConfirmButtonText}`,
                                action: () => updateLocationAction(`${dialogConfirmButtonText}`),
                            },
                            {
                                variant: 'Update location',
                                action: () => updateLocationAction('Update location'),
                            },
                        ]}
                    />
                    <Formik
                        initialValues={{ state: (currentLocation && currentLocation.state) || '' }}
                        validationSchema={ValidationSchema}
                        onSubmit={(values) => onSubmit(values)}
                    >
                        {({ touched, errors, values, isValid, handleChange }) => (
                            <Form>
                                <SelectBox
                                    name={'state'}
                                    placeholder={'State'}
                                    value={values.state}
                                    options={listOfAvailableStates}
                                    handleChange={handleChange}
                                    valid={`${!touched.state && !errors.state}`}
                                    errors={touched && touched.state && errors && errors.state}
                                />
                                <RippleButton
                                    top="14px"
                                    disabled={!isValid || currentLocation.state === values.state}
                                    type="submit"
                                >
                                    Update
                                </RippleButton>
                            </Form>
                        )}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default ShoppingLocation;
