import React, { Fragment, useEffect, useState } from 'react';

import algoliasearch from 'algoliasearch';
import { useSelector, useDispatch } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { useHistory } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';

import { DeliveryLocationPopup, InfiniteScrollList } from '../../../../components/';
import { SearchInput } from '../../../../components/forms/input/search-input/SearchInput';
import { BlurBackground } from '../../../../containers/BlurBackground';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { bannerInteraction } from '../../../../redux/ducks/applications/banners/actions';
import { merchbuyActions } from '../../../../redux/ducks/applications/merchbuy/actions';
import { colors } from '../../../../styles';
import { MERCHBUY_INTERACTION, MERCHBUY_LANDING_PAGE } from '../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../utils/mix-panel/mixPanel';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import Navigation from '../../../home/navigation';
import PendingActivation from '../../../home/pending-activation';
import { ReactComponent as BuySvg } from '../assets/buy.svg';
import { ReactComponent as ShoppingLocationSvg } from '../assets/map-location.svg';
import { ReactComponent as MapSvg } from '../assets/map.svg';
import { ReactComponent as MyOrderIcon } from '../assets/my_order.svg';
import { ReactComponent as RecommendedBlueIcon } from '../assets/recommended_blue.svg';
import { ReactComponent as RightArrowSvg } from '../assets/right-arrow.svg';
import { ReactComponent as SavedItemIcon } from '../assets/saved-items-icon.svg';
import { ReactComponent as ShoppingOptionSvg } from '../assets/shopping-options.svg';
import { TopNavIconsMenu, ListProducts } from '../components';
import EducateSearchUser from '../search-educate';
import { PageSubSections, PageSubSectionsTop, PageSubSectionsTopLeft, Space, Container } from '../styles';

import SkeletonCard from './skeleton';

export const PageTitle = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.themeTextColor1};
`;

const ProductTitleHeader = styled.h4`
    font-weight: 700;
    font-size: 16px;
    color: ${colors.boldDark};
    margin-left: 4px;
`;

const PageSection = styled(PageSubSections)`
    padding: 0px;
    cursor: pointer;
`;

const ShoppingOptionTitle = styled.p`
    font-size: 16px;
    font-weight: 500;
    text-align: center;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
    align-items: center;
    padding: 16px 16px;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    margin: auto;
    width: 373px;
    @media (max-width: 576px) {
        width: calc(100% - 32px);
        top: 0px;
    }
    background-color: #fff;
    z-index: 99;
`;

const InputWrapper = styled.div`
    width: 70%;
    margin-right: 10px;
`;

const BuyIcon = styled(BuySvg)`
    width: 32px;
    height: 35px;
`;

const CartCount = styled.div`
    width: 13px;
    height: 13px;
    background-color: #ee6f44;
    border-radius: 26px;
    position: absolute;
    margin-top: -43px;
    margin-left: 19px;
    text-align: center;
    font-size: 8px;
    font-weight: bold;
    color: ${colors.white};
    padding-top: 2px;
`;

const ShoppingOptionWrapper = styled.div`
    height: calc(320px + 66px);
    width: 100%;
    background-color: white;
    position: fixed;
    bottom: 0;
    padding: 24px 0;
    border-radius: 16px 16px 0 0;
    @media (max-width: 576px) {
        height: auto;
    }

    transition: opacity 0.5s linear 0s;
    animation: openPopup 0.3s ease-out;
    @keyframes openPopup {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0%);
        }
    }
    z-index: ${({ zIndex }) => zIndex || '1000'};
`;

const ShoppingOptionItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 16px;
`;

const ItemsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 0;
    position: relative;
`;

const ItemText = styled.p`
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    color: #071827;
`;

const algoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY,
);

const searchIndex = process.env.REACT_APP_ALGOLIA_SEARCH_INDEX_V2;
const searchClient = algoliaClient.initIndex(searchIndex);

const MerchbuyIndex = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const role = useSelector((state) => state.user.role);
    const agentState = useSelector((state) =>
        state.user.agentState ? state.user.agentState.toUpperCase() : 'PENDING',
    );

    const allBanners = useSelector((state) => state.applications.banners.allBanners);
    const user = useSelector((state) => state.user);

    const userEducations = (user && user.userEducations) || [];
    const hasCompletedSearchEdu = userEducations.includes('Search Education');

    const [merchbuyAllProducts, setAllMerchbuyAllProducts] = useState([]);
    const listOfAvailableStates = useSelector((state) => state.applications.merchbuy.availableStates);
    const cartItems = useSelector((state) => state.applications.merchbuy.shopCart);
    const merchbuyProductCategories = useSelector((state) => state.applications.merchbuy.merchbuyProductCategeries);
    const [deliveryLocation, setDeliveryLocation] = useState(JSON.parse(localStorage.getItem('deliveryLocation')));
    const [showPendingActivationPage, setShowPendingActivationPage] = useState(false);

    const [openDeliverLocationPopup, setOpenDeliverLocationPopup] = useState(deliveryLocation === null);
    const [topBanner, setTopBanner] = useState([]);
    const [bannerName] = useState('MERCHBUY_TOP_LANDING_PAGE');
    const [showShoppingOption, setShowShoppingOption] = useState(false);
    const [shoppingOptions, setShoppingOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [allPages, setAllPages] = useState(0);
    const [allCategoryFacets, setAllCategoryFacets] = useState([]);
    const [topCategories, setTopCategories] = useState([]);
    const pageScroll = sessionStorage.getItem('PAGE_SCROLL') && JSON.parse(sessionStorage.getItem('PAGE_SCROLL'));

    const setScroll = () => {
        sessionStorage.setItem('PAGE_SCROLL', JSON.stringify({ x: window.scrollX, y: window.scrollY }));
    };

    useEffect(() => {
        if (pageScroll) {
            window.scrollTo(parseInt(pageScroll.x), parseInt(pageScroll.y));
            setTimeout(() => {
                sessionStorage.removeItem('PAGE_SCROLL');
            }, 1000);
        }
    }, [pageScroll]);

    useEffect(() => {
        const state = listOfAvailableStates.find((state) => state?.name === deliveryLocation?.state);
        if (state) dispatch(merchbuyActions.getAllLga(state?.name));
    }, [dispatch, listOfAvailableStates, deliveryLocation]);

    useEffect(() => {
        const options = [
            {
                id: 1,
                title: 'My Orders',
                icon: MyOrderIcon,
                onClick: () => {
                    mixPanel.track(MERCHBUY_INTERACTION, {
                        'Icon Name': 'my orders',
                        Time: new Date().toLocaleString(),
                    });
                    history.push('/actions/merchbuy/order-history');
                },
            },
            {
                id: 2,
                title: 'My Saved Items',
                icon: SavedItemIcon,
                onClick: () => {
                    history.push('/actions/merchbuy/saved-items');
                },
            },
            {
                id: 3,
                title: 'Shopping location',
                icon: ShoppingLocationSvg,
                onClick: () => {
                    mixPanel.track(MERCHBUY_INTERACTION, {
                        'Icon Name': 'shipping location',
                        Time: new Date().toLocaleString(),
                    });
                    history.push('/actions/merchbuy/shopping-location');
                },
                value: deliveryLocation && deliveryLocation.state,
            },
        ];

        if (role !== 'ROLE_AGENT') {
            options.push({
                id: 4,
                title: 'Delivery address',
                icon: MapSvg,
                onClick: () => history.push('/actions/merchbuy/delivery-address'),
            });
        }
        setShoppingOptions(options);
    }, [deliveryLocation, history, role]);

    const getLocation = (data) => {
        if (data.state) {
            setDeliveryLocation(data);
            setOpenDeliverLocationPopup(!openDeliverLocationPopup);
        }
    };

    useEffect(() => {
        if (allBanners && allBanners?.length > 0) {
            setTopBanner(
                allBanners.filter(
                    (x) =>
                        x.bannerConfig?.bannerName === bannerName &&
                        (x?.states || []).includes(deliveryLocation?.state),
                ),
            );
        }
    }, [allBanners, bannerName, deliveryLocation]);

    useEffect(() => {
        if (role === 'ROLE_AGENT' && agentState !== 'APPROVED') {
            setShowPendingActivationPage(true);
        }
    }, [agentState, role]);

    useEffect(() => {
        dispatch(merchbuyActions.getProductCategories(1, 1000));
    }, [dispatch]);

    useEffect(() => {
        if (merchbuyProductCategories && merchbuyProductCategories.length > 0) {
            const categories =
                Object.keys(allCategoryFacets || [])
                    .map((category) => ({ id: category, hits: allCategoryFacets[category] }))
                    .sort((item1, item2) => {
                        if (item1.hits > item2.hits) return -1;
                        if (item2.hits > item1.hits) return 1;
                        return 0;
                    }) || [];

            const categoryObjects = categories?.map((category) =>
                merchbuyProductCategories?.find((productCategory) => productCategory.id === category.id),
            );

            const topCategoryObjects = categoryObjects.filter((x) => x?.id);
            setTopCategories(topCategoryObjects);
        }
    }, [allCategoryFacets, merchbuyProductCategories]);

    const fetchProductsData = async () => {
        const chosenDeliveryLocation = deliveryLocation || { state: 'Lagos' };

        const query = () =>
            searchClient.search('', {
                filters: `state:${chosenDeliveryLocation.state} AND onMerchBuy:true AND quantityInStock > 0`,
                clickAnalytics: true,
                userToken: user.userId,
                enablePersonalization: true,
                distinct: true,
                hitsPerPage: 30,
                facets: ['categoryId'],
                page,
            });

        query().then((res) => {
            setLoading(false);
            setPage(page + 1);
            setAllPages(res.nbPages);
            setAllCategoryFacets(res?.facets?.categoryId);
            setAllMerchbuyAllProducts([...merchbuyAllProducts, ...res.hits]);
        });
    };

    useEffect(() => {
        window.addEventListener('storage', () => {
            setDeliveryLocation(JSON.parse(localStorage.getItem('deliveryLocation')));
        });

        if (deliveryLocation !== null) {
            fetchProductsData();
            dispatch(merchbuyActions.getShopCart());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, deliveryLocation]);

    useEffect(() => {
        mixPanel.track(MERCHBUY_LANDING_PAGE);
    }, []);

    const onImageClick = (imageId, interactiveLink) => {
        dispatch(bannerInteraction(bannerName, imageId));
        window.location.href = `${interactiveLink}`;
    };

    const handleClose = () => {
        setTimeout(() => {
            setShowShoppingOption(false);
        }, 1000);
    };

    return (
        <DesktopBackgroundLayout onClick={handleClose}>
            {showPendingActivationPage ? (
                <PendingActivation pageTitle="MerchBuy" />
            ) : (
                <Fragment>
                    {showShoppingOption && (
                        <BlurBackground position="fixed" onClick={handleClose}>
                            <ShoppingOptionWrapper>
                                <ShoppingOptionTitle>Shopping Options</ShoppingOptionTitle>
                                {shoppingOptions.map((shoppingOption) => (
                                    <ShoppingOptionItemWrapper
                                        onClick={shoppingOption.onClick}
                                        key={shoppingOption?.id}
                                    >
                                        <ItemsContainer>
                                            <shoppingOption.icon />
                                            <ItemText>{shoppingOption.title}</ItemText>
                                        </ItemsContainer>
                                        <ItemsContainer>
                                            <ItemText>{shoppingOption.value}</ItemText>
                                            <RightArrowSvg />
                                        </ItemsContainer>
                                    </ShoppingOptionItemWrapper>
                                ))}
                            </ShoppingOptionWrapper>
                        </BlurBackground>
                    )}

                    {!hasCompletedSearchEdu && <EducateSearchUser />}
                    <ScreenContainer top={'0px'}>
                        <HeaderWrapper>
                            <InputWrapper>
                                <SearchInput
                                    iconLeft={'25px'}
                                    onClick={() => history.push('/actions/merchbuy/search')}
                                    placeholder="Search for a product"
                                />
                            </InputWrapper>
                            <div
                                onClick={() => {
                                    mixPanel.track(MERCHBUY_INTERACTION, {
                                        'Icon Name': 'cart',
                                        Time: new Date().toLocaleString(),
                                    });
                                    history.push('/actions/merchbuy/cart');
                                }}
                            >
                                <BuyIcon />
                                <CartCount>{cartItems ? cartItems?.length : 0}</CartCount>
                            </div>
                            <ShoppingOptionSvg onClick={() => setShowShoppingOption(true)} />
                        </HeaderWrapper>
                        {loading ? (
                            <SkeletonCard />
                        ) : (
                            <Fragment>
                                <Container width="100%" top="60px">
                                    {topBanner && (
                                        <Carousel showThumbs={false} autoPlay infiniteLoop>
                                            {topBanner.map((banner) => {
                                                const { imageId, interactiveLink, imageUrl } = banner;
                                                return (
                                                    <div
                                                        onClick={() => {
                                                            onImageClick(imageId, interactiveLink);
                                                            mixPanel.track(MERCHBUY_INTERACTION, {
                                                                'Icon Name': 'slider',
                                                                Time: new Date().toLocaleString(),
                                                            });
                                                        }}
                                                        key={imageId}
                                                    >
                                                        <img src={imageUrl} alt="" />
                                                    </div>
                                                );
                                            })}
                                        </Carousel>
                                    )}
                                </Container>
                                <Space height="20px" />
                                <TopNavIconsMenu categories={topCategories.splice(0, 4)} />
                                <PageSection>
                                    <PageSubSectionsTop padding="0">
                                        <PageSubSectionsTopLeft>
                                            <RecommendedBlueIcon />
                                            <ProductTitleHeader>Recommended For You</ProductTitleHeader>
                                        </PageSubSectionsTopLeft>
                                    </PageSubSectionsTop>
                                    {/* Replace this with algolia cart recommended product */}
                                    <InfiniteScrollList
                                        data={merchbuyAllProducts}
                                        fetchMore={fetchProductsData}
                                        hasMore={page < allPages}
                                        endMessage="Well! That's all"
                                    >
                                        <ListProducts
                                            data={merchbuyAllProducts}
                                            size={{ width: '105px', height: '98px' }}
                                            setScroll={setScroll}
                                            showStock
                                        />
                                    </InfiniteScrollList>
                                </PageSection>
                            </Fragment>
                        )}
                        <Space height={'50px'} />
                        {openDeliverLocationPopup && (
                            <DeliveryLocationPopup
                                open={openDeliverLocationPopup}
                                getLocation={getLocation}
                                cancel={() => {
                                    setOpenDeliverLocationPopup(!openDeliverLocationPopup);
                                }}
                            />
                        )}
                    </ScreenContainer>
                </Fragment>
            )}
            <Navigation />
        </DesktopBackgroundLayout>
    );
};

export default MerchbuyIndex;
