import React from 'react';

import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const List = styled.ul`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 15px;
`;

const CategoryList = styled.ul`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 44px;
    margin-top: 15px;
    align-items: center;
`;

const Card = styled.div`
    display: grid;
    width: 100%;
    gap: 10px;
`;

const Category = styled.div`
    display: grid;
    gap: 4px;
`;

const CardImagePlaceHolder = styled.div`
    width: 100%;
    height: 100px;
`;

const SkeletonCard = () => {
    return (
        <div>
            <Skeleton width={`100%`} height={`139px`} />
            <CategoryList>
                {Array(4)
                    .fill()
                    .map((_item, index) => (
                        <Card key={index}>
                            <Category>
                                <Skeleton width={`48px`} height={`48px`} />
                                <Skeleton width={`48px`} height={15} />
                            </Category>
                        </Card>
                    ))}
            </CategoryList>
            <List>
                {Array(9)
                    .fill()
                    .map((_item, index) => (
                        <Card key={index}>
                            <CardImagePlaceHolder>
                                <Skeleton width={`100%`} height={`100%`} />
                            </CardImagePlaceHolder>
                            <Skeleton width={`90%`} height={10} />
                            <Skeleton width={`48px`} height={12} />
                        </Card>
                    ))}
            </List>
        </div>
    );
};
export default SkeletonCard;
