import React from 'react';

import { ShsTransfer, ShsRecharge, ShsRechargePin } from '../../views/actions/shs';

export const shsRoutes = [
    {
        path: '/actions/shs_transfer',
        exact: true,
        main: () => <ShsTransfer />,
        public: false,
    },
    {
        path: '/actions/shs_recharge',
        exact: true,
        main: () => <ShsRecharge />,
        public: false,
    },
    {
        path: '/actions/shs_recharge_pin',
        exact: true,
        main: () => <ShsRechargePin />,
        public: false,
    },
];
