import React, { Fragment, useState } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { RippleButton } from '../../../../../components';
import { PopUp, PopUpContent } from '../../../../../components/popup/common';
import { Overlay } from '../../../../../containers/OverlayContainer';
import { updateReminder } from '../../../../../redux/ducks/applications/my-shop/actions/debtors';
import { colors } from '../../../../../styles';

const Title = styled.h2`
    font-weight: bold;
    font-size: 20px;
    color: ${colors.themeTxtColor10};
    text-align: left;
    margin: 24px 24px 20px 24px;
`;

const Text = styled.p`
    font-weight: 500;
    font-size: 16px;
    color: ${colors.themeTxtColor10};
    text-align: left;
    margin: 0 24px 20px 24px;
`;

const TurnOffConfirmPopup = ({
    open,
    cancel,
    debtid,
    reminderSuccess,
    customerId,
    customerName,
    onButtonClick,
    checkStatus,
    setCheckStatus,
}) => {
    const dispatch = useDispatch();
    const [body] = useState({ active: false });
    return (
        <Fragment>
            <Overlay bgc="rgba(0, 0, 0, 0.4)">
                <PopUp padding="0" open={open} zIndex={'10000'}>
                    <PopUpContent radius="8px 8px 0px 0px">
                        <Title>Turn-Off Reminder</Title>
                        <Text>Are you sure you want to turn-off reminder for {customerName?.split(' ')[0]}</Text>
                        <RippleButton
                            top={'0'}
                            width={'90%'}
                            bottom={'10px'}
                            onClick={() => {
                                onButtonClick();
                                setCheckStatus(!checkStatus);
                                dispatch(updateReminder(customerId, { ...body }));
                            }}
                        >
                            Yes, Turn-Off
                        </RippleButton>
                        <RippleButton
                            color={colors.themeSubColor1}
                            backgroundColor={colors.white}
                            border={`1px solid ${colors.themeSubColor1}`}
                            top={'0'}
                            bottom={'10px'}
                            width={'90%'}
                            onClick={() => {
                                onButtonClick();
                            }}
                        >
                            Cancel
                        </RippleButton>
                    </PopUpContent>
                </PopUp>
            </Overlay>
        </Fragment>
    );
};

export default TurnOffConfirmPopup;
