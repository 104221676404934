import * as Yup from 'yup';

export const VerifyStoreValidationSchema = Yup.object().shape({
    state: Yup.string().nullable().required('Required'),
    lga: Yup.string().nullable().required('Required'),
    city: Yup.string().nullable().required('Required'),
    shopNumber: Yup.string().nullable().required('Required'),
    streetName: Yup.string().nullable().required('Required'),
    address: Yup.string().nullable().required('Required'),
    supportDocType: Yup.string().nullable().required('Required'),
    supportDocImage: Yup.string().nullable().required('Required'),
});
