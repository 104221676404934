import React, { useState } from 'react';

import { useField } from 'formik';
import { string } from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from '../../../../assets/arrowDown.svg';
import { flags } from '../../../../data/countries';
import { NewTextInputWithLabel } from '../new-text-input';

import Dropdown from './dropdown';

const CountryFlag = styled.img`
    width: 18px;
    height: 12px;
`;

const CurrentSelectedCountry = styled.div`
    position: absolute;
    left: 18px;
    top: 22px;
    z-index: 2;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

const CountryPhoneBlock = styled.div`
    position: relative;
    -webkit-align-items: center;
    align-items: center;
    background: transparent;
    font-size: 14px;
    padding: 1px;
    line-height: normal;
    margin-bottom: ${({ bottomMargin }) => bottomMargin || '15px'};
    margin-right: 8px;
    border-radius: 4px;
    width: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 20px;
        left: 94px;
        height: 20px;
        width: 1px;
        background: #cbd6e0;
        z-index: 1;
    }
`;

const TextInputWrapper = styled(NewTextInputWithLabel)`
    padding-left: 83px;
`;

const Number = styled.div`
    margin: 0 6.67px 0 3.68px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #5c6f7f;
`;

export const NewPhoneNumberInput = ({ label, description, ...props }) => {
    const [field] = useField(props.name);
    const [country, setCountry] = useState('NG');
    const [code, setCode] = useState('+234');
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <CountryPhoneBlock {...props}>
                <CurrentSelectedCountry onClick={() => setOpenModal(!openModal)}>
                    <CountryFlag
                        src={flags.filter((flag) => flag.customAbbreviation === country)[0].value}
                        alt={flags.filter((flag) => flag.customAbbreviation === country)[0].label}
                    />
                    <Number>{code}</Number>
                    <ArrowIcon />
                </CurrentSelectedCountry>
                <TextInputWrapper
                    {...props}
                    {...field}
                    label={label}
                    description={description}
                    autoComplete={`tel`}
                    inputMode={'numeric'}
                    pattern={'[0-9]*'}
                    countryselection={true}
                />
            </CountryPhoneBlock>
            {openModal && (
                <Dropdown
                    setCode={setCode}
                    setCountry={setCountry}
                    open={openModal}
                    cancel={() => setOpenModal(false)}
                />
            )}
        </>
    );
};

NewPhoneNumberInput.propTypes = {
    label: string.isRequired,
    countrySelection: string,
    description: string,
};
