import React, { Fragment, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Loader, RippleButton, TopHeader } from '../../../../../components';
import { ReactComponent as BackArrow } from '../../../../../components/header/assets/back_icon.svg';
import { SlidingOverlay } from '../../../../../containers/OverlayContainer';
import { addStorefrontProduct } from '../../../../../redux/ducks/applications/store-front/actions/store';
import { colors } from '../../../../../styles';
import { formatCurrency } from '../../../../../utils/currency/parseBalance';
import { toTitleCase } from '../../../../../utils/toTitleCase';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';

import StorefrontAddProductFailure from './addProductFailure';
import StorefrontAddProductSuccess from './addProductSuccess';

const StyleOverrideCarousel = styled(Carousel)`
    .carousel .slide img {
        width: 184px;
        height: 207px;
    }

    .carousel .control-dots {
        position: absolute;
        bottom: auto;
        margin-top: 50px;
        top: 230px;
    }

    .carousel .control-dots .dot {
        transition: opacity 0.25s ease-in;
        opacity: 1;
        box-shadow: none;
        cursor: pointer;
        margin: 0 4px;
        width: 6px;
        height: 6px;
        background: #d6e6f0;
    }

    .carousel .control-dots .dot.selected {
        box-shadow: none;
        width: 18px;
        height: 6px;
        background: #fe8f49;
        border-radius: 4px;
    }
`;

const SliderHolder = styled.div`
    position: relative;
    padding: ${({ padding }) => padding || '52px 16px 60px'};
    background: ${({ background }) => background || '#F9F9FA'};
    overflow: hidden;
`;

const CarouselLayout = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    height: ${({ height }) => height || '45%'};
`;

const SliderImageWrapper = styled.div`
    position: relative;
    justify-content: ${({ content }) => content || null};
    right: ${({ right }) => right || null};
    height: ${({ height }) => height || null};
    text-align: ${({ textAlign }) => textAlign || null};
`;

const SliderImage = styled.img`
    display: flex;
    align-items: center;
    position: relative;
`;

const TextDiv = styled.div`
    padding: 0 16px;
    overflow-wrap: break-word;
    margin-bottom: 16px;
`;

const ContentBox = styled.div`
    background: ${colors.white};
    padding: 16px;
    display: ${({ display }) => display || null};
    margin-bottom: ${({ bottom }) => bottom || null};
`;

const InnerContent = styled.div`
    display: ${({ display }) => display || null};
    justify-content: ${({ justifyContent }) => justifyContent || null};
    align-items: ${({ alignItems }) => alignItems || null};
`;

const Title = styled.div`
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || null};
    font-weight: ${({ weight }) => weight || null};
    font-size: ${({ size }) => size || null};
    line-height: ${({ lineHeight }) => lineHeight || null};
    color: ${({ color }) => color || null};
`;

const PreviewContainer = styled.div`
    width: 100%;
    padding-bottom: ${({ paddingBottom }) => paddingBottom || '19px'};
    background: ${colors.lightestGray};

    @media (min-width: 576px) {
        margin-top: 100px;
    }
`;

const ButtonWrapper = styled.div`
    padding: 0 16px;
    margin-top: 100px;
`;

const Back = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: absolute;
    left: 16px;
    top: 16px;
`;

const StoreFrontPreviewProduct = ({ open, setOpen, pImages, values, store, content, duplicateElements }) => {
    const dispatch = useDispatch();
    const [altLoading, setAltLoading] = useState(false);
    const [showSuccessPage, setShowSuccessPage] = useState(false);
    const [showFailurePage, setShowFailurePage] = useState(false);

    const dataURLtoFile = (dataurl) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], { type: mime });
    };

    const carouselItem = [
        {
            id: 1,
            image: (
                <SliderImage
                    src={URL.createObjectURL(dataURLtoFile(Object.entries(pImages)[0][1]))}
                    alt="product picture 1"
                />
            ),
            content: 'flex-end',
        },
        {
            id: 2,
            image: (
                <SliderImage
                    src={URL.createObjectURL(dataURLtoFile(Object.entries(pImages)[1][1]))}
                    alt="product picture 2"
                />
            ),
            content: 'flex-end',
        },
        {
            id: 3,
            image: (
                <SliderImage
                    src={URL.createObjectURL(dataURLtoFile(Object.entries(pImages)[2][1]))}
                    alt="product picture 3"
                />
            ),
            content: 'center',
        },
    ];

    const handleSubmit = (values, id) => {
        if (duplicateElements.length > 0) {
            toast.error('Each image must be unique');
        } else {
            values.description = content;
            const params = {
                ...values,
                storeProductImages: Object.entries(pImages).map((entries) => entries && entries[1]),
            };
            setAltLoading(true);
            dispatch(addStorefrontProduct(params, id)).then((res) => {
                if (res) {
                    setAltLoading(false);
                    setShowSuccessPage(!showSuccessPage);
                } else {
                    setAltLoading(false);
                    setShowFailurePage(!showFailurePage);
                }
            });
        }
    };

    if (altLoading) return open && <Loader />;

    return (
        <Fragment>
            {showSuccessPage && (
                <StorefrontAddProductSuccess
                    open={showSuccessPage}
                    setOpen={() => setShowSuccessPage(!showSuccessPage)}
                />
            )}
            {showFailurePage && (
                <StorefrontAddProductFailure
                    open={showFailurePage}
                    setOpen={() => setShowFailurePage(!showFailurePage)}
                />
            )}
            {open && !showFailurePage && !showSuccessPage && (
                <DesktopBackgroundLayout bgColor="#F9FAFC">
                    <TopHeader title={'Product Preview'} noBorderBottom noArrow>
                        <Back onClick={() => setOpen()}>
                            <BackArrow />
                        </Back>
                    </TopHeader>
                    <SlidingOverlay>
                        <PreviewContainer>
                            <CarouselLayout>
                                <StyleOverrideCarousel
                                    showThumbs={false}
                                    showArrows={false}
                                    showStatus={false}
                                    swipeable
                                    autoPlay
                                    infiniteLoop
                                >
                                    {carouselItem.map((carousel) => (
                                        <SliderHolder key={carousel.id}>
                                            <SliderImageWrapper content={carousel.content}>
                                                {carousel.image}
                                            </SliderImageWrapper>
                                        </SliderHolder>
                                    ))}
                                </StyleOverrideCarousel>
                            </CarouselLayout>
                            <TextDiv>
                                <Title
                                    size={'16px'}
                                    weight={'700'}
                                    lineHeight={'24px'}
                                    color={colors.themeTextColor1}
                                    top={'44px'}
                                    bottom={'10px'}
                                >
                                    {toTitleCase(values.name)}
                                </Title>
                                <Title size={'12px'} weight={'400'} lineHeight={'18px'} color={'#5C6F7F'}>
                                    {content}
                                </Title>
                            </TextDiv>
                            <ContentBox bottom={'8px'}>
                                <InnerContent display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Title size={'10px'} weight={'700'} color={'#5C6F7F'} lineHeight={'16px'}>
                                        COST PRICE
                                    </Title>
                                    <Title size={'10px'} weight={'700'} color={'#5C6F7F'} lineHeight={'16px'}>
                                        STOCK QUANTITY THRESHOLD
                                    </Title>
                                </InnerContent>
                                <InnerContent display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Title
                                        size={'12px'}
                                        weight={'500'}
                                        color={colors.themeTxtColor10}
                                        lineHeight={'18px'}
                                    >
                                        {formatCurrency(values.price)}
                                    </Title>
                                    <Title
                                        size={'12px'}
                                        weight={'500'}
                                        color={colors.themeTxtColor10}
                                        lineHeight={'18px'}
                                    >
                                        {values.stockQuantityThreshold}
                                    </Title>
                                </InnerContent>
                            </ContentBox>
                            <ContentBox>
                                <Title size={'10px'} weight={'700'} color={'#5C6F7F'} lineHeight={'16px'}>
                                    QUANTITY IN STOCK
                                </Title>
                                <Title size={'12px'} weight={'500'} color={colors.themeTxtColor10} lineHeight={'18px'}>
                                    {values.quantityInStock}
                                </Title>
                            </ContentBox>
                            <ButtonWrapper>
                                <RippleButton
                                    size={'16px'}
                                    height={'45px'}
                                    onClick={() => handleSubmit(values, store.id)}
                                >
                                    Save Product
                                </RippleButton>
                            </ButtonWrapper>
                        </PreviewContainer>
                    </SlidingOverlay>
                </DesktopBackgroundLayout>
            )}
        </Fragment>
    );
};

export default StoreFrontPreviewProduct;
