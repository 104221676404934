import React, { Fragment } from 'react';

import { bool, string, func } from 'prop-types';
import styled from 'styled-components';

import { TopHeader } from '../../../../../../components';
import { PopUp, PopUpContent } from '../../../../../../components/popup/common';
import { Overlay } from '../../../../../../containers/OverlayContainer';
import { ScreenContainer, ViewContainer } from '../../../../../../containers/ScreenContainer';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';

const Back = styled.div`
    width: 32px;
    height: 32px;
    position: absolute;
    left: 4px;
    top: 22px;
`;

export const SalesModal = ({ open, cancel = () => null, children, title, height }) => {
    return (
        <Fragment>
            <Overlay bgc="rgba(0, 0, 0, 0.4);">
                <PopUp padding="0" open={open} zIndex={'10000'}>
                    <PopUpContent bottom="0" height={height || '550px'}>
                        <TopHeader
                            title={title}
                            size={'16px'}
                            weight={'bold'}
                            x
                            position={'relative'}
                            width={'null'}
                            margin={'0 5px'}
                            top={'0'}
                            noArrow
                        >
                            <Back onClick={cancel}>
                                <CloseIcon />
                            </Back>
                        </TopHeader>
                        <ScreenContainer top={'16px'}>
                            <ViewContainer top={'-16px'}>{children}</ViewContainer>
                        </ScreenContainer>
                    </PopUpContent>
                </PopUp>
            </Overlay>
        </Fragment>
    );
};

SalesModal.propTypes = {
    open: bool,
    title: string,
    cancel: func,
};
