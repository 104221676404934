import React, { Fragment, useState, useEffect } from 'react';

import { Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
    TopHeader,
    AmountInputWithLabel,
    TextInputWithLabel,
    SelectBox,
    RippleButton,
    FileInput3,
    SuccessPage,
    PasswordGroup,
    Loader,
} from '../../../../components';
import { BlurBackground } from '../../../../containers/BlurBackground';
import { Message } from '../../../../containers/MessageContainer';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { getPinTrialsLeft, verifyPin, getReasonForBlockedUser } from '../../../../redux/ducks/account/wallet/actions';
import { remittanceActions } from '../../../../redux/ducks/applications/remittance/actions';
import { colors } from '../../../../styles';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import { formatCurrency } from '../../../../utils/currency/parseBalance';
import { getInputValues } from '../../../../utils/inputs/getInputValues';
import {
    REMITTANCE_MULTIPLE_ORDER_START,
    REMITTANCE_MULTIPLE_ORDER_FINISH,
} from '../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../utils/mix-panel/mixPanel';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { BlockedWalletPopUp } from '../../../home/transactions/wallet-transactions/blocked-wallet-popup';
import { ReactComponent as Down } from '../assets/arrow_bottom_right.svg';
import { ReactComponent as Up } from '../assets/arrow_top_right.svg';
import { ReactComponent as FromSvg } from '../assets/from.svg';
import { ReactComponent as SpacesLogo } from '../assets/small_spaces_logo.svg';
import { ReactComponent as ToSvg } from '../assets/to.svg';
import { banks, paymentMethods, remitStatus } from '../constants';
import {
    OrderDetailCards,
    FlexContainer,
    Text,
    PageText,
    PageSubText,
    InputBlock,
    StyledAddOrderIcon,
    Backdrop,
    ModalContainer,
    EmptyStateText,
    OrderNumber,
    ContainerDiv,
    RemittanceAmountStatus,
    AmountText,
    ParentWrapper,
    LinearLayout,
    TopCard,
    HorizontalDiv,
    VerticalDiv,
    BottomParentWrapper,
    BottomCard,
    MLineSvg,
} from '../styles';

import { MultipleRemitValidationSchema } from './MultipleRemitValidationSchema';
import { OrdersList } from './ordersList';

//TODO remove when OMS remove the required field for receiptBase64String
const image =
    'iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MEVBMTczNDg3QzA5MTFFNjk3ODM5NjQyRjE2RjA3QTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MEVBMTczNDk3QzA5MTFFNjk3ODM5NjQyRjE2RjA3QTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowRUExNzM0NjdDMDkxMUU2OTc4Mzk2NDJGMTZGMDdBOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowRUExNzM0NzdDMDkxMUU2OTc4Mzk2NDJGMTZGMDdBOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjjUmssAAAGASURBVHjatJaxTsMwEIbpIzDA6FaMMPYJkDKzVYU+QFeEGPIKfYU8AETkCYI6wANkZQwIKRNDB1hA0Jrf0rk6WXZ8BvWkb4kv99vn89kDrfVexBSYgVNwDA7AN+jAK3gEd+AlGMGIBFDgFvzouK3JV/lihQTOwLtOtw9wIRG5pJn91Tbgqk9kSk7GViADrTD4HCyZ0NQnomi51sb0fUyCMQEbp2WpU67IjfNjwcYyoUDhjJVcZBjYBy40j4wXgaobWoe8Z6Y80CJBwFpunepIzt2AUgFjtXXshNXjVmMh+K+zzp/CMs0CqeuzrxSRpbOKfdCkiMTS1VBQ41uxMyQR2qbrXiiwYN3ACh1FDmsdK2Eu4J6Tlo31dYVtCY88h5ELZIJJ+IRMzBHfyJINrigNkt5VsRiub9nXICdsYyVd2NcVvA3ScE5t2rb5JuEeyZnAhmLt9NK63vX1O5Pe8XaPSuGq1uTrfUgMEp9EJ+CQvr+BJ/AAKvAcCiAR+bf9CjAAluzmdX4AEIIAAAAASUVORK5CYII=';

const FlexFormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const PinTrailsLeft = styled.div`
    color: ${colors.transactions.failed};
    font-weight: 400;
    font-size: 10px;
    padding-top: 4px;
    margin-right: 12%;
`;

export const RemittingMultipleOrder = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.applications.remittance.isLoading);
    const walletLoading = useSelector((state) => state.account.wallet.isLoading);
    const walletBalance = useSelector((state) => state.account.wallet.balance || 0);

    const [showSuccessPage, setShowSucessPage] = useState(false);
    const [remittanceStatus, setRemittanceStatus] = useState(false);
    const [wrongPin, setWrongPin] = useState('');
    const [pin, setPin] = useState('');
    const [value, setValue] = useState('');
    const [step, setStep] = useState(remitStatus.REMIT);

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [openOrderList, setOpenOrderList] = useState(false);
    const [openModalPopUp, setOpenModalPopUp] = useState(false);
    const [clickedOrder, setClickedOrder] = useState({});
    const [total, setTotal] = useState(0);
    const [values, setValues] = useState({});
    const [amountDiff, setAmountDiff] = useState(0);
    const [pinTrailsLeft, setPinTrailsLeft] = useState({});
    const [reasonForBlocking, setPinReason] = useState({});
    const [count, setCount] = useState('');

    const pinTrials = () => {
        dispatch(getPinTrialsLeft()).then((res) => {
            res && setPinTrailsLeft(res);
        });
    };

    useEffect(() => {
        dispatch(getReasonForBlockedUser()).then((res) => {
            res && setPinReason(res);
        });
        pinTrials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (pin && pin.length === 4) {
                const verified = await dispatch(verifyPin(pin));
                pinTrials();
                if (verified) {
                    const orders = selectedOrders.reduce(
                        (accum, current) => [
                            ...accum,
                            {
                                orderId: current.orderId,
                                orderNumber: current.orderNumber,
                                paidAmount: current.amount,
                                collectionAmounts: [
                                    {
                                        collectionMedium: 1,
                                        amount: current.amount,
                                    },
                                ],
                            },
                        ],
                        [],
                    );

                    const payload = {
                        id: orders[0].orderId,
                        totalPrice: values.amount,
                    };

                    const res = await dispatch(remittanceActions.payWithWallet(payload));
                    if (res) {
                        const payload = {
                            outstandingOrders: orders,
                            //TODO ask to be non-required for pay with wallet
                            receiptBase64String: image,
                            totalPaidAmount: values.amount,
                            transactionReference: res,
                        };

                        logRemittance(payload, 'Sabi wallet');
                    } else {
                        feedbackState(true, false, false);
                    }
                } else {
                    feedbackState(true, false, true);
                    setCount(true);
                }
            }
        }, 500);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, pin]);

    useEffect(() => {
        selectedOrders &&
            setTotal(selectedOrders.reduce((accum, current) => Number(accum) + Number(current.amount), 0));

        setAmountDiff(total - (values.amount || 0));
    }, [selectedOrders, values, total]);

    const onSubmit = async (values, { setErrors }) => {
        if (Number(values.amount) > Number(convertToPositive(total))) {
            return setErrors({
                amount: 'Paid amount is greater than remitted amount',
            });
        }

        if (values.paymentMethodEnum === 2) {
            setStep(remitStatus.PIN);
            return;
        }

        const orders = selectedOrders.reduce(
            (accum, current) => [
                ...accum,
                {
                    orderId: current.orderId,
                    orderNumber: current.orderNumber,
                    paidAmount: current.amount,
                    collectionAmounts: [
                        {
                            collectionMedium: 2,
                            amount: current.amount,
                        },
                    ],
                },
            ],
            [],
        );

        const payload = {
            outstandingOrders: orders,
            partnerReference: values.bankAccount,
            receiptBase64String: values.receiptBase64String,
            totalPaidAmount: values.amount,
            transactionReference: values.transactionReference,
        };

        logRemittance(payload, 'Bank transfer');
    };

    const logRemittance = async (payload, action) => {
        const res = await dispatch(remittanceActions.logRemittance(payload));
        feedbackState(true, res, false);
        res &&
            mixPanel.track(REMITTANCE_MULTIPLE_ORDER_FINISH, {
                'Remittance method': action,
            });
    };

    const onSubmitModal = (values, { setErrors }) => {
        if (Number(values.amount) > Number(convertToPositive(clickedOrder.outstandingAmount))) {
            return setErrors({
                amount: 'Paid amount is greater than outstanding amount',
            });
        }
        const filteredOrders = selectedOrders.filter((x) => x.orderId !== clickedOrder.orderId);
        setSelectedOrders([...filteredOrders, { ...clickedOrder, amount: values.amount }]);
        setOpenModalPopUp(false);
    };

    const feedbackState = (success, status, pin) => {
        setShowSucessPage(success);
        setRemittanceStatus(status);
        setWrongPin(pin);
    };

    const convertToPositive = (amount) => {
        if (amount < 0) {
            return amount * -1;
        }
        return amount;
    };

    useEffect(() => {
        mixPanel.track(REMITTANCE_MULTIPLE_ORDER_START, {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const disableSubmitButton =
        isLoading ||
        !values.amount ||
        !selectedOrders?.length ||
        amountDiff !== 0 ||
        Number(values.amount) === 0 ||
        !values.paymentMethodEnum ||
        (values.paymentMethodEnum === 1 &&
            (!values.receiptBase64String || !values.bankAccount || !values.transactionReference)) ||
        (values.paymentMethodEnum === 2 && Number(values.amount) > Number(walletBalance));

    if (walletLoading || isLoading) return <Loader text="Processing" />;
    return (
        <DesktopBackgroundLayout>
            <Fragment>
                {showSuccessPage ? (
                    <SuccessPage
                        title={`${
                            wrongPin ? 'Wrong Pin' : remittanceStatus ? 'Remittance Successful' : 'Remittance Failed!'
                        }`}
                        subtitle={
                            wrongPin
                                ? 'Please check the PIN entered'
                                : remittanceStatus
                                ? 'Your payment has been logged successfully. You will be notified once it’s been reviewed.'
                                : "We couldn't complete your transaction. Kindy try again"
                        }
                        successfull={remittanceStatus}
                        doneText={remittanceStatus ? 'Go to Home' : 'Try Again'}
                        detailText="View remittance"
                        onDoneClick={() => {
                            if (remittanceStatus) {
                                history.push('/actions/remittance/dashboard');
                            } else {
                                feedbackState(false, false, false);
                            }
                        }}
                        onDetailClick={async () => {
                            history.push({
                                pathname: '/actions/remittance/remittance-details/',
                                state: null,
                            });
                        }}
                    />
                ) : (
                    <Fragment>
                        {step === remitStatus.REMIT && (
                            <Fragment>
                                {!openOrderList && (
                                    <>
                                        {!openModalPopUp && (
                                            <TopHeader title="Remit Order" size="14px" noBorderBottom />
                                        )}
                                        {openModalPopUp && (
                                            <BlurBackground position="fixed">
                                                <Backdrop onClick={() => setOpenModalPopUp(false)} />
                                                <ModalContainer>
                                                    <EmptyStateText align="start" size="14px" color={colors.popupText}>
                                                        How much are you paying for this order?
                                                    </EmptyStateText>
                                                    <OrderNumber margin="19px 0 4px 0">
                                                        {`Order No. ${clickedOrder.orderNumber} - ${
                                                            clickedOrder.customer?.name || ''
                                                        }`}
                                                    </OrderNumber>
                                                    <ContainerDiv bottom="24px">
                                                        <Text right="4px">Balance left</Text>
                                                        <Text color={colors.darkRed}>
                                                            {formatPrice(
                                                                convertToPositive(clickedOrder.outstandingAmount) || 0,
                                                            )}
                                                        </Text>
                                                    </ContainerDiv>

                                                    <Formik
                                                        initialValues={{
                                                            amount: clickedOrder.amount || '',
                                                        }}
                                                        onSubmit={onSubmitModal}
                                                    >
                                                        {({ initialValues }) => {
                                                            return (
                                                                <Form>
                                                                    <AmountInputWithLabel
                                                                        label="Amount"
                                                                        inputMode={'numeric'}
                                                                        placeholder={'Amount'}
                                                                        name="amount"
                                                                        autoComplete="off"
                                                                        initialValues={initialValues}
                                                                    />
                                                                    <RippleButton
                                                                        width="100%"
                                                                        top="32px"
                                                                        type="submit"
                                                                        bottom="32px"
                                                                    >
                                                                        Add Order
                                                                    </RippleButton>
                                                                </Form>
                                                            );
                                                        }}
                                                    </Formik>
                                                </ModalContainer>
                                            </BlurBackground>
                                        )}

                                        <ScreenContainer top="65px">
                                            <Formik
                                                initialValues={{
                                                    amount: values.amount || '',
                                                    receiptBase64String: values.receiptBase64String || '',
                                                    bankAccount: values.bankAccount || '',
                                                    transactionReference: values.transactionReference || '',
                                                    paymentMethod: values.paymentMethod || '',
                                                    paymentMethodEnum: values.paymentMethodEnum || '',
                                                }}
                                                validationSchema={MultipleRemitValidationSchema}
                                                onSubmit={onSubmit}
                                            >
                                                {({ errors, touched, setFieldValue, values }) => {
                                                    setValues(values);

                                                    return (
                                                        <FlexFormContainer>
                                                            <InputBlock>
                                                                <PageText> How much do you want to remit?</PageText>
                                                                <PageSubText>
                                                                    Make sure the amount matches your provided proof of
                                                                    payment
                                                                </PageSubText>

                                                                <AmountInputWithLabel
                                                                    label="Amount"
                                                                    placeholder={'Amount'}
                                                                    name="amount"
                                                                    autocomplete={'off'}
                                                                    allowDecimal
                                                                />

                                                                <PageText>Orders</PageText>
                                                                <PageSubText bottom="0">
                                                                    Select which of the orders you are paying for
                                                                </PageSubText>
                                                                {selectedOrders &&
                                                                    selectedOrders.map((item, index) => (
                                                                        <OrderDetailCards key={index}>
                                                                            <FlexContainer>
                                                                                <Text color={colors.themeTxtColor10}>
                                                                                    {`Order ${item.orderId}`}
                                                                                </Text>
                                                                                <Text
                                                                                    weight="500"
                                                                                    color={colors.deepBlue}
                                                                                    size="10px"
                                                                                    lineHeight="16px"
                                                                                    onClick={() => {
                                                                                        setClickedOrder(item);
                                                                                        setOpenModalPopUp(true);
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </Text>
                                                                            </FlexContainer>
                                                                            <FlexContainer>
                                                                                <Text opacity={0.8}>
                                                                                    {item.customer?.name || ''}
                                                                                </Text>
                                                                                <Text
                                                                                    weight="500"
                                                                                    color={colors.themeTxtColor10}
                                                                                >
                                                                                    {formatPrice(item.amount || 0)}
                                                                                </Text>
                                                                            </FlexContainer>
                                                                        </OrderDetailCards>
                                                                    ))}

                                                                <StyledAddOrderIcon
                                                                    onClick={() => setOpenOrderList(true)}
                                                                />

                                                                {selectedOrders?.length > 0 && (
                                                                    <>
                                                                        <FlexContainer padding="40px 0 16px">
                                                                            <Text
                                                                                size="14px"
                                                                                lineHeight="21px"
                                                                                weight="700"
                                                                                color={colors.boldDark}
                                                                            >
                                                                                Total Order
                                                                            </Text>
                                                                            <Text
                                                                                size="14px"
                                                                                lineHeight="21px"
                                                                                weight="700"
                                                                                color={colors.boldDark}
                                                                            >
                                                                                {formatPrice(total || 0)}
                                                                            </Text>
                                                                        </FlexContainer>
                                                                        {amountDiff !== 0 && (
                                                                            <RemittanceAmountStatus>
                                                                                {amountDiff > 0 && <Up />}
                                                                                {amountDiff < 0 && <Down />}
                                                                                <div>
                                                                                    <AmountText>
                                                                                        <Text
                                                                                            diplay="flex"
                                                                                            left="8px"
                                                                                            size="14px"
                                                                                            lineHeight="21px"
                                                                                        >
                                                                                            Your order is
                                                                                            <Text
                                                                                                diplay="inline"
                                                                                                left="2px"
                                                                                                right="2px"
                                                                                                weight="700"
                                                                                                color={
                                                                                                    amountDiff > 0
                                                                                                        ? colors.textGreen
                                                                                                        : colors.red
                                                                                                }
                                                                                                size="14px"
                                                                                                lineHeight="21px"
                                                                                            >
                                                                                                {formatCurrency(
                                                                                                    convertToPositive(
                                                                                                        amountDiff,
                                                                                                    ),
                                                                                                )}
                                                                                            </Text>
                                                                                            {amountDiff > 0
                                                                                                ? 'above'
                                                                                                : 'below'}{' '}
                                                                                            remitted amount
                                                                                        </Text>
                                                                                    </AmountText>
                                                                                    <Text left="8px" top="4px">
                                                                                        click
                                                                                        <Text
                                                                                            left="2px"
                                                                                            right="2px"
                                                                                            weight="700"
                                                                                        >
                                                                                            edit
                                                                                        </Text>
                                                                                        to correct amount
                                                                                    </Text>
                                                                                </div>
                                                                            </RemittanceAmountStatus>
                                                                        )}
                                                                    </>
                                                                )}

                                                                <PageText>
                                                                    What payment method would you like to use?
                                                                </PageText>
                                                                <SelectBox
                                                                    top="16px"
                                                                    placeholder="Select Payment Method"
                                                                    value={values.paymentMethod}
                                                                    title="Select Payment Method"
                                                                    options={paymentMethods}
                                                                    checkable
                                                                    showSearchBar={false}
                                                                    buttonText="Continue"
                                                                    handleChange={(selected) => {
                                                                        setFieldValue('paymentMethod', selected.label);
                                                                        setFieldValue(
                                                                            'paymentMethodEnum',
                                                                            selected.value,
                                                                        );
                                                                    }}
                                                                    valid={`${
                                                                        !touched.paymentMethod && !errors.paymentMethod
                                                                    }`}
                                                                    error={
                                                                        touched &&
                                                                        touched.paymentMethod &&
                                                                        errors &&
                                                                        errors.paymentMethod
                                                                    }
                                                                />
                                                                {values.paymentMethodEnum === 2 && (
                                                                    <ContainerDiv>
                                                                        <Text
                                                                            size="10px"
                                                                            lineHeight="16px"
                                                                            left="16px"
                                                                            right="4px"
                                                                        >
                                                                            Wallet Balance:
                                                                        </Text>
                                                                        <Text
                                                                            size="10px"
                                                                            lineHeight="16px"
                                                                            color={colors.remittance.successful}
                                                                        >
                                                                            {formatPrice(walletBalance || 0)}
                                                                        </Text>
                                                                    </ContainerDiv>
                                                                )}

                                                                {values.paymentMethodEnum === 1 && (
                                                                    <InputBlock>
                                                                        <PageText>Enter transaction reference</PageText>
                                                                        <PageSubText>
                                                                            Kindly provide the transaction reference on
                                                                            your deposit receipt
                                                                        </PageSubText>
                                                                        <TextInputWithLabel
                                                                            label="Reference number"
                                                                            placeholder="Reference number"
                                                                            name="transactionReference"
                                                                        />

                                                                        <PageText>Upload transaction receipt</PageText>
                                                                        <PageSubText>
                                                                            Upload a proof of payment (bank teller or
                                                                            transfer receipt) so we can start remit your
                                                                            payment
                                                                        </PageSubText>
                                                                        <FileInput3
                                                                            mimeType={'image/*'}
                                                                            name="receiptBase64String"
                                                                            compressFile
                                                                            base64String
                                                                            placeholder={
                                                                                'Click to Upload Document here'
                                                                            }
                                                                            value={values.receiptBase64String}
                                                                            label="Proof of payment"
                                                                            handleChange={(value) => {
                                                                                if (value) {
                                                                                    var base64result = value.substr(
                                                                                        value.indexOf(',') + 1,
                                                                                    );
                                                                                    setFieldValue(
                                                                                        'receiptBase64String',
                                                                                        base64result,
                                                                                    );
                                                                                } else {
                                                                                    setFieldValue(
                                                                                        'receiptBase64String',
                                                                                        '',
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />

                                                                        <PageText>
                                                                            {' '}
                                                                            What account did you remit to?
                                                                        </PageText>
                                                                        <PageSubText>
                                                                            {' '}
                                                                            Kindly provide the bank details that you
                                                                            made payment to
                                                                        </PageSubText>
                                                                        <SelectBox
                                                                            top="0px"
                                                                            placeholder={'Bank Name'}
                                                                            value={values.bankAccount}
                                                                            title="Select Account"
                                                                            options={banks}
                                                                            checkable
                                                                            showSearchBar={false}
                                                                            handleChange={(selected) => {
                                                                                setFieldValue(
                                                                                    'bankAccount',
                                                                                    selected.label,
                                                                                );
                                                                            }}
                                                                            valid={`${
                                                                                !touched.bankAccount &&
                                                                                !errors.bankAccount
                                                                            }`}
                                                                            error={
                                                                                touched &&
                                                                                touched.bankAccount &&
                                                                                errors &&
                                                                                errors.bankAccount
                                                                            }
                                                                        />
                                                                    </InputBlock>
                                                                )}
                                                            </InputBlock>

                                                            <RippleButton disabled={disableSubmitButton} type="submit">
                                                                Submit
                                                            </RippleButton>
                                                        </FlexFormContainer>
                                                    );
                                                }}
                                            </Formik>
                                        </ScreenContainer>
                                    </>
                                )}

                                {openOrderList && (
                                    <OrdersList
                                        open={openOrderList}
                                        setOpen={setOpenOrderList}
                                        setSelectedOrders={setSelectedOrders}
                                        selectedOrders={selectedOrders}
                                    />
                                )}
                            </Fragment>
                        )}

                        {step === remitStatus.PIN && (
                            <Fragment>
                                {(pinTrailsLeft?.pinTriesLeft === 0 || pinTrailsLeft.status === 'BLOCKED') && (
                                    <BlockedWalletPopUp showCancelIcon={false} reason={reasonForBlocking?.reason} />
                                )}
                                <TopHeader
                                    title={'Remit Order'}
                                    backgroundColor={colors.bgBlue}
                                    color={colors.white}
                                    opaqueBackButton
                                    noBorderBottom
                                    backLink="#"
                                    backAction={() => setStep(remitStatus.REMIT)}
                                />
                                <ParentWrapper>
                                    <LinearLayout>
                                        <TopCard>
                                            <HorizontalDiv>
                                                <VerticalDiv right="22px">
                                                    <FromSvg />
                                                    <MLineSvg />
                                                    <ToSvg />
                                                </VerticalDiv>
                                                <VerticalDiv>
                                                    <VerticalDiv>
                                                        <Message top="-5px" color="#718596" size="10px" weight="400">
                                                            FROM
                                                        </Message>
                                                        <Message top="0px" color="#1A2630" size="14px" weight="500">
                                                            My Wallet
                                                        </Message>
                                                        <Message
                                                            top="0px"
                                                            color={colors.boldDark}
                                                            size="12px"
                                                            weight="400"
                                                        >
                                                            {`Bal: ${formatPrice(walletBalance || 0)}`}
                                                        </Message>
                                                    </VerticalDiv>
                                                    <VerticalDiv>
                                                        <Message
                                                            top="22px"
                                                            bottom="6px"
                                                            color="#516C82"
                                                            size="10px"
                                                            weight="400"
                                                        >
                                                            TO
                                                        </Message>
                                                        <HorizontalDiv>
                                                            <SpacesLogo />
                                                            <VerticalDiv>
                                                                <Message
                                                                    left="8px"
                                                                    top="0px"
                                                                    color="#1A2630"
                                                                    size="12px"
                                                                    weight="500"
                                                                >
                                                                    Merchbuy Purchase
                                                                </Message>
                                                            </VerticalDiv>
                                                        </HorizontalDiv>
                                                    </VerticalDiv>
                                                </VerticalDiv>
                                            </HorizontalDiv>
                                        </TopCard>
                                        <BottomParentWrapper>
                                            <BottomCard>
                                                <Message top="0px" color={colors.lightDark} size="12px" weight="400">
                                                    Amount
                                                </Message>
                                                <Message top="0px" color={colors.boldDark} size="16px" weight="700">
                                                    {`${formatPrice(values.amount || 0)}`}
                                                </Message>
                                            </BottomCard>
                                            <Message color={colors.boldDark} top={'24px'} bottom={'4px'}>
                                                Enter Transaction PIN to continue
                                            </Message>
                                            <Formik
                                                initialValues={{
                                                    transactionPin: '',
                                                }}
                                            >
                                                {({ touched, valid, errors, setFieldValue }) => (
                                                    <FlexFormContainer>
                                                        <PasswordGroup
                                                            count={4}
                                                            startIndex={1}
                                                            type={'password'}
                                                            valid={valid}
                                                            errors={errors}
                                                            name={'transactionPin'}
                                                            touched={touched}
                                                            align={'center'}
                                                            enteredValue={value || ''}
                                                            handleChange={(event) => {
                                                                setValue(event.target.value);
                                                                setPin(getInputValues('transactionPin'));
                                                            }}
                                                        />
                                                    </FlexFormContainer>
                                                )}
                                            </Formik>
                                            <PinTrailsLeft>
                                                {count && 'INCORRECT PIN. '}
                                                {` You have ${pinTrailsLeft?.pinTriesLeft} 
                                                        ${pinTrailsLeft?.pinTriesLeft < 3 ? 'more' : ''}
                                                        ${pinTrailsLeft?.pinTriesLeft > 1 ? 'trials' : 'trial'}`}
                                            </PinTrailsLeft>
                                        </BottomParentWrapper>
                                    </LinearLayout>
                                </ParentWrapper>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Fragment>
        </DesktopBackgroundLayout>
    );
};

// =======
//                                                             placeholder={'Bank Name'}
//                                                             value={values.bankAccount}
//                                                             title="Select Account"
//                                                             options={banks}
//                                                             padding={'20px 0 10px'}
//                                                             mbottom={'0px'}
//                                                             checkable
//                                                             showSearchBar={false}
//                                                             handleChange={(selected) => {
//                                                                 setFieldValue('bankAccount', selected.label);
// >>>>>>> 6e43adf91bda1d8033ccb0a7ebf1bb140b726e5e
