import React, { Fragment, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { RippleButton } from '../../../../../components';
import {
    getOrdersByStoreId,
    getProductsByStoreId,
} from '../../../../../redux/ducks/applications/store-front/actions/store';
import { colors } from '../../../../../styles';
import { formatCurrency } from '../../../../../utils/currency/parseBalance';
import { toTitleCase } from '../../../../../utils/toTitleCase';
import { ReactComponent as Illustration } from '../../assets/illustration.svg';
import { ReactComponent as PlusIcon } from '../../assets/lightplus.svg';
import { TabContent, Text } from '../../storeFront';

import { ReactComponent as Path } from './assets/path.svg';

const PlusStyleIcon = styled(PlusIcon)`
    margin-right: 8px;
`;

const GridTabContent = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 162px);
    justify-content: space-between;
    grid-row-gap: 16px;

    @media (max-width: 320px) {
        grid-template-columns: repeat(2, 138px);
    }
`;

const TheImage = styled.img`
    height: 98px;
    margin-bottom: 10px;
`;

const ImageDiv = styled.div`
    padding: 8px 8px 16px 8px;
    background: ${colors.white};
`;

const ProductList = styled.div`
    margin-top: 16px;
    width: 100%;
    padding: 0 16px;
`;

export const TotalContainer = styled.div`
    background: ${colors.lightestGray};
    width: 100%;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const AddProductButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 15vh;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background: ${colors.border.primary};
    position: fixed;
`;

const ListedProducts = () => {
    const dispatch = useDispatch();
    const storefrontProducts = useSelector((state) => state.applications.storeFront.storefrontProductsList);
    const store = useSelector((state) => state.applications.storeFront.store);

    useEffect(() => {
        dispatch(getProductsByStoreId(store?.id));
    }, [dispatch, store.id]);

    useEffect(() => {
        dispatch(getOrdersByStoreId(store?.id));
    }, [dispatch, store.id]);

    return (
        <Fragment>
            <TotalContainer>
                {storefrontProducts?.length === 0 ? (
                    <TabContent top="70px">
                        <Illustration />
                        <Text size="14px" weight="500" color={colors.themeTxtColor10} bottom="4px">
                            No Products yet
                        </Text>
                        <Text color={colors.themeSubColor1}>Start selling your products online...</Text>
                        <Link to="/actions/storefront/add_product">
                            <RippleButton
                                height="48px"
                                width="295px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                size="16px"
                            >
                                <PlusStyleIcon />
                                <Text color={colors.white}>Add Products</Text>
                            </RippleButton>
                        </Link>
                    </TabContent>
                ) : (
                    <ProductList>
                        <GridTabContent>
                            {storefrontProducts.map((product, index) => (
                                <ImageDiv key={index}>
                                    <Link to={`/actions/storefront/product/${product.id}`}>
                                        <TheImage
                                            src={product?.images[product?.images?.length - 3]?.imageUrl}
                                            alt="product picture"
                                        />
                                        <Text bottom={'12px'} size={'12px'} weight={'500'} color={'#2E4457'}>
                                            {toTitleCase(product.name)}
                                        </Text>
                                        <Text size={'14px'} weight={'700'} color={colors.themeTxtColor10}>
                                            {formatCurrency(product.price)}
                                        </Text>
                                    </Link>
                                </ImageDiv>
                            ))}
                        </GridTabContent>
                        <Link to="/actions/storefront/add_product">
                            <ButtonWrapper>
                                <AddProductButton>
                                    <Path />
                                </AddProductButton>
                            </ButtonWrapper>
                        </Link>
                    </ProductList>
                )}
            </TotalContainer>
        </Fragment>
    );
};

export default ListedProducts;
