import { toast } from 'react-toastify';

import { currentAPI, axios } from '../../../../../config';
import { getWalletBalance } from '../../../account/wallet/actions';
import {
    LOADING,
    FECTHING_PAYMENT_HISTORY_SUCCESS,
    FETCHING_BY_ORDER_ID,
    UNPAID_REMITTANCE_ORDER,
    REMITTANCE_METRICS,
    FETCHING_DETAILS_BY_ORDER_ID,
} from '../constants';

const getPaymentHistory = (status) => async (dispatch) => {
    try {
        const params = {
            remittanceApprovalStatus: status,
        };
        const response = await axios.get(`${currentAPI}/api/merchantAppIntegration/remittance/status?page=1&size=500`, {
            params,
        });
        if (response.status === 200) {
            const { data } = response.data || {};
            dispatch({
                type: FECTHING_PAYMENT_HISTORY_SUCCESS,
                payload: data,
            });
            return data || [];
        }
    } catch (error) {
        if (error.response && (error.response.status === 400 || error.response.status === 404)) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.error);
        }
    }
};

const getPaymentHistoryByOrderId = (orderId, status) => async (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    try {
        const response = await axios.get(
            `${currentAPI}/api/merchantAppIntegration/remittance/order/${orderId}/payment/status?remittanceApprovalStatus=${status}`,
        );

        if (response.status === 200) {
            const { data } = response.data || {};
            dispatch({
                type: FETCHING_BY_ORDER_ID,
                payload: data,
            });
            return data || {};
        }
    } catch (error) {
        if (error.response && (error.response.status === 400 || error.response.status === 404)) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.error);
        }
    } finally {
        dispatch({ type: LOADING, payload: false });
    }
};

const getOrderDetails = (orderId) => async (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    try {
        const response = await axios.get(`${currentAPI}/api/merchantAppIntegration/orders/Order/${orderId}`);
        if (response.status === 200) {
            const { data } = response.data || {};
            dispatch({
                type: FETCHING_DETAILS_BY_ORDER_ID,
                payload: data,
            });
            return data || {};
        }
    } catch (error) {
        if (error.response && (error.response.status === 400 || error.response.status === 404)) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.error);
        }
    } finally {
        dispatch({ type: LOADING, payload: false });
    }
};

const getPendingRemittanceOrder = () => async (dispatch) => {
    dispatch({ type: LOADING, payload: true });

    try {
        const response = await axios.get(`${currentAPI}/api/merchantAppIntegration/remittance/pending`);
        if (response.status === 200) {
            const { data } = response.data || {};
            dispatch({ type: UNPAID_REMITTANCE_ORDER, payload: data });
        }
    } catch (error) {
        if (error.response && (error.response.status === 400 || error.response.status === 404)) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.error);
        }
    } finally {
        dispatch({ type: LOADING, payload: false });
    }
};

const getRemittanceMetrics = () => async (dispatch) => {
    try {
        const response = await axios.get(`${currentAPI}/api/merchantAppIntegration/remittance/order/payment/metrics`);
        if (response.status === 200) {
            const { data } = response.data || {};
            dispatch({ type: REMITTANCE_METRICS, payload: data });
        }
    } catch (error) {
        if (error.response && (error.response.status === 400 || error.response.status === 404)) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.error);
        }
    }
};

const logRemittance = (payload) => async (dispatch) => {
    dispatch({ type: LOADING, payload: true });

    try {
        const response = await axios.post(
            `${currentAPI}/api/merchantAppIntegration/remittance/logOrderPayments`,
            JSON.stringify(payload),
        );

        if (response.status === 200) {
            const { status } = response?.data || {};
            return status;
        }
    } catch (error) {
        if (error.response && (error.response.status === 400 || error.response.status === 404)) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.error);
        }
    } finally {
        dispatch({ type: LOADING, payload: false });
    }
};

const payWithWallet = (params) => async (dispatch) => {
    dispatch({ type: LOADING, payload: true });

    const { id, totalPrice } = params || {};

    const orderDetail = {
        amount: totalPrice,
        externalProcessCode: id,
        transactionCharge: 0,
        transactionServiceType: 'MERCHBUY',
        narration: 'ORDER|PAYMENT|' + totalPrice + '|' + id,
    };

    try {
        const paymentDetailResponse = await axios.post(
            `${currentAPI}/api/merchantAppIntegration/orders/Order/payWithWallet`,
            JSON.stringify(orderDetail),
        );

        if (paymentDetailResponse.status === 200) {
            const { internalTransactionReference } = paymentDetailResponse?.data?.transaction || {};
            dispatch(getWalletBalance());
            return internalTransactionReference;
        }
    } catch (error) {
        if (error.response && error.response.status === 400) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(data.message);
        }
    } finally {
        dispatch({ type: LOADING, payload: false });
    }
};

export const remittanceActions = {
    getPaymentHistory,
    getOrderDetails,
    getPaymentHistoryByOrderId,
    getRemittanceMetrics,
    getPendingRemittanceOrder,
    logRemittance,
    payWithWallet,
};
