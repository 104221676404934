import React, { Fragment, useEffect, useState } from 'react';

import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
    TopHeader,
    RippleButton,
    FlatProgressBar,
    SuccessBadge,
    PasswordGroup,
    SuccessPage,
    Loader,
    SelectBox,
    NewTextAreaWithLabel,
} from '../../../../../../components';
import { InputBlock } from '../../../../../../containers/InputContainer';
import { ScreenContainer } from '../../../../../../containers/ScreenContainer';
import {
    createTransactionPin,
    activateWalletV2,
    saveLastActionUrl,
    getWalletActivationStatus,
    sendSecurityAnswer,
    getSecurityQuestions,
} from '../../../../../../redux/ducks/account/wallet/actions';
import { colors } from '../../../../../../styles';
import { getInputValues } from '../../../../../../utils/inputs/getInputValues';
import DesktopBackgroundLayout from '../../../../../DesktopBackgroundLayout';

import { CodeCheckFormValidationSchema } from './CodeCheckFormValidationSchema';

const Title = styled.h2`
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #071827;
    text-align: left;
    margin-bottom: 6px;
    margin-top: ${({ top }) => top || null};
`;

const Message = styled.h2`
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #718596;
    text-align: left;
    margin-bottom: ${({ bottom }) => bottom || '6px'};
    margin-top: ${({ top }) => top || null};
`;

const Tip = styled.h3`
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: #718596;
    text-align: left;
    margin-bottom: ${({ bottom }) => bottom || null};
    margin-top: ${({ top }) => top || null};
`;

const PageCount = styled(Message)`
    position: absolute;
    right: 0;
    margin-right: 16px;
`;

const FloatingBottomSection = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0px;
    padding: 0px 16px 40px 16px;
    background-color: #ffffff;
`;

const CallButtonSection = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 100px;
`;

const SecurityHeader = styled.div`
    font-size: 16px;
    font-weight: 700;
    align-items: 'inherit';
    text-align: 'inherit';
    color: ${colors.themeTxtColor10};
    margin-top: -32px;
`;
const SubText = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: ${colors.themeTextColor11};
    align-items: 'inherit';
    text-align: 'inherit';
    padding-top: 10px;
    padding-bottom: 24px;
`;
const Container = styled.div`
    padding-top: 16px;
    padding-bottom: 91px;
`;

const WalletPin = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const isLoading = useSelector((state) => state.account.wallet.isLoading);
    const lastActionUrl = useSelector((state) => state.account.wallet.lastActionUrl);
    const [questionId, setQuestionId] = useState('');
    const [value, setValue] = useState(0);
    const [isSelected, setIsSelected] = useState(false);
    const [confirmValue, setConfirmValue] = useState(0);
    const [showSuccessPage, setShowSucessPage] = useState(false);
    const [transactionStatus, setTransactionStatus] = useState(false);
    const [successBadge, setSuccessBage] = useState(true);
    const [bvnInfo] = useState(location.state);
    const [securityQuestions, setSecurityQuestions] = useState({});

    useEffect(() => {
        dispatch(getSecurityQuestions()).then((res) => {
            res && setSecurityQuestions(res);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitForm = async (values) => {
        dispatch(sendSecurityAnswer(questionId, values.answerDetails));

        const createdPin = await dispatch(
            createTransactionPin({
                transactionPin: values.pin,
            }),
        );

        if (createdPin) {
            const payload = {
                ...bvnInfo,
                transactionPin: values.pin,
            };

            const isWalletCreated = await dispatch(activateWalletV2(payload));
            setShowSucessPage(true);
            setTransactionStatus(isWalletCreated);
        }
    };

    const onSuccessDoneClick = () => {
        dispatch(getWalletActivationStatus());
        if (transactionStatus) {
            if (lastActionUrl) {
                history.push(lastActionUrl);
                dispatch(saveLastActionUrl(''));
            } else {
                history.push('/user/wallet_index');
            }
        } else {
            history.push('/user/wallet_index');
        }
    };

    if (isLoading) return <Loader text="Processing" />;
    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Wallet PIN'}>
                    <PageCount>3/3</PageCount>
                </TopHeader>
                <ScreenContainer padding="0px" top="64px">
                    <FlatProgressBar step={3} amount={3} />
                    {successBadge && (
                        <SuccessBadge
                            message="Your Information was Verified succesfully"
                            onCancelClick={() => setSuccessBage(false)}
                        />
                    )}

                    <Formik
                        initialValues={{
                            pin: '',
                            confirmPin: '',
                            securityQuestion: '',
                            answerDetails: '',
                        }}
                        validationSchema={CodeCheckFormValidationSchema}
                        onSubmit={onSubmitForm}
                    >
                        {({ values, valid, errors, handleChange, touched, setFieldValue, initialValues }) => (
                            <Form style={{ padding: '24px 16px 0px 16px' }}>
                                <InputBlock bottom="64px">
                                    <Title>Create wallet PIN</Title>
                                    <Message bottom="40px">Create a 4-digit PIN to secure your transactions</Message>
                                    <Tip top={'0px'} bottom={'0px'}>
                                        Enter PIN
                                    </Tip>
                                    <PasswordGroup
                                        count={4}
                                        startIndex={1}
                                        type={'password'}
                                        valid={valid}
                                        errors={errors}
                                        name={'pin'}
                                        touched={touched}
                                        align={'center'}
                                        enteredValue={value}
                                        handleChange={(event) => {
                                            setValue(event.target.value);
                                            setFieldValue('pin', getInputValues('pin'));
                                        }}
                                    />

                                    <Tip top={'16px'} bottom={'4px'}>
                                        Re-type PIN
                                    </Tip>
                                    <PasswordGroup
                                        count={4}
                                        startIndex={5}
                                        type={'password'}
                                        valid={valid}
                                        errors={errors}
                                        name={'confirmPin'}
                                        touched={touched}
                                        align={'center'}
                                        enteredValue={confirmValue}
                                        handleChange={(event) => {
                                            setConfirmValue(event.target.value);
                                            setFieldValue('confirmPin', getInputValues('confirmPin'));
                                        }}
                                    />
                                </InputBlock>
                                <SecurityHeader>Security Question</SecurityHeader>
                                <SubText>
                                    This helps to identify you when you're trying to regain access to your wallet if
                                    blocked.
                                </SubText>
                                <SelectBox
                                    placeholder={'Select a Security Question'}
                                    value={values.securityQuestion}
                                    padding="16px"
                                    showSearchBar={false}
                                    showCancelIcon={true}
                                    left={'-10px'}
                                    buttonText={' '}
                                    paddingLeft={'100px'}
                                    height="90%"
                                    title="Select a Security Question"
                                    alignItems={'inherit'}
                                    textAlign={'inherit'}
                                    isSelected={isSelected}
                                    setIsSelected={setIsSelected}
                                    options={securityQuestions}
                                    handleChange={(value) => {
                                        setFieldValue('securityQuestion', value.question);
                                        setQuestionId(value.id);
                                    }}
                                    valid={`${!touched.securityQuestion && !errors.securityQuestion}`}
                                    errors={touched && touched.securityQuestion && errors && errors.securityQuestion}
                                />

                                <Container>
                                    <NewTextAreaWithLabel
                                        color={'#0B0C0E'}
                                        weight={'500'}
                                        size={'14px'}
                                        label={'Answer to Selected Security Question'}
                                        name={'answerDetails'}
                                        placeholder={'Answer to Selected Security Question'}
                                        height={'53px'}
                                        padding={'16px 16px'}
                                    />
                                </Container>

                                <FloatingBottomSection>
                                    <RippleButton
                                        type="submit"
                                        top={'2px'}
                                        backgroundColor={colors.deepBlue}
                                        disabled={
                                            isLoading ||
                                            !values.answerDetails ||
                                            !values.securityQuestion ||
                                            !values.pin ||
                                            !values.confirmPin
                                        }
                                    >
                                        Continue
                                    </RippleButton>
                                </FloatingBottomSection>
                            </Form>
                        )}
                    </Formik>
                </ScreenContainer>
                {showSuccessPage && (
                    <SuccessPage
                        title={`${transactionStatus ? 'Wallet Activated' : 'Wallet Verification Error'}`}
                        subtitle={
                            transactionStatus
                                ? 'Your PIN has been succesfully created.'
                                : 'We are currently unable to confirm your wallet activation. Please try again or reach out to our customer support'
                        }
                        doneText={transactionStatus ? 'Got It' : 'Try Again'}
                        successfull={transactionStatus}
                        onDoneClick={onSuccessDoneClick}
                    >
                        {!transactionStatus && (
                            <CallButtonSection>
                                <a href="tel:01 888 5070">
                                    <RippleButton backgroundColor="transparent">Reach out to support</RippleButton>
                                </a>
                            </CallButtonSection>
                        )}
                    </SuccessPage>
                )}
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default WalletPin;
