import React from 'react';

import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import { colors } from '../../styles';

import { ReactComponent as CloseIcon } from './assets/close.svg';

import 'react-toastify/dist/ReactToastify.minimal.css';

const StyledToast = styled(ToastContainer).attrs({
    className: 'toast-container',
    toastClassName: 'toast',
    bodyClassName: 'body',
    progressClassName: 'progress',
})`
    z-index: 9999999;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 125px;
    left: 0;
    margin-left: 0px;

    @media (max-width: 576px) {
        top: 64px;
    }

    a {
        color: white;
    }

    p {
        text-transform: uppercase;
    }

    .toast {
        display: flex;
        min-height: 34px;
        max-width: 50%;
        padding: 8px 16px;
        width: 343px;

        @media (max-width: 576px) {
            max-width: 368px;
            width: 369px;
            margin-bottom: 10px;
        }
    }

    .Toastify__toast--success {
        background-color: ${colors.lightGreen};
    }

    .Toastify__toast--success .body {
        color: #012816;
    }

    .Toastify__toast--error {
        background-color: ${colors.background.failedStatus};
    }

    .Toastify__toast--error .body {
        color: ${colors.textRed};
    }
`;

const CloseButton = ({ closeToast }) => {
    return <CloseIcon onClick={closeToast} />;
};

const Toast = () => {
    return (
        <StyledToast
            position="top-center"
            autoClose={5000}
            closeButton={<CloseButton />}
            hideProgressBar={true}
            closeOnClick={false}
            draggable={false}
            pauseOnHover
            newestOnTop
        />
    );
};

export default Toast;
