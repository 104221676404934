import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { getParameterByName } from '../utils/getUrlParamsByName';

const useConfirmPayement = (action) => {
    const dispatch = useDispatch();
    const [response, setResponse] = useState(null);

    useEffect(() => {
        const confirmTransaction = async () => {
            const linkingReference = getParameterByName('linkingreference');
            const code = getParameterByName('code');
            const message = getParameterByName('message');
            const reference = getParameterByName('reference');

            const payload = { code, linkingReference, message, reference };
            const status = await dispatch(action(payload));
            setResponse(status);
        };
        confirmTransaction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return response;
};

export default useConfirmPayement;
