import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import DelIcon from '../../../../../assets/del1.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/delete1.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/edit1.svg';
import EmailIcon from '../../../../../assets/emailmessage.svg';
import PhoneIcon from '../../../../../assets/phone.svg';
import SMSIcon from '../../../../../assets/smsmessage.svg';
import WhatsAppIcon from '../../../../../assets/whatsappmessage.svg';
import { TopHeader, PageLogo, OptionsPopupDialog, ConfirmPopupDialog } from '../../../../../components';
import { ReactComponent as BackArrow } from '../../../../../components/header/assets/back_icon.svg';
import {
    DetailsName,
    DetailsContactOption,
    DetailsContactBlock,
    DetailsContactLink,
    DetailsBusinessDescription,
    DetailsBusinessDescriptionHeading,
} from '../../../../../containers/DetailsContainer';
import { Options } from '../../../../../containers/HeaderContainer';
import { ListHeading, ListItem } from '../../../../../containers/ListContainer';
import { SecondaryText, PageCount } from '../../../../../containers/MessageContainer';
import { SlidingOverlay } from '../../../../../containers/OverlayContainer';
import { ScreenContainer, FlexCenteredBlock } from '../../../../../containers/ScreenContainer';
import { deleteCustomer } from '../../../../../redux/ducks/applications/my-shop/actions/customers';
import { colors } from '../../../../../styles';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import { ReactComponent as AvatarIcon } from '../assets/avatar.svg';
import CustomersList from '../customers-list';

export const Back = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: absolute;
    left: 16px;
    top: 16px;
`;

const DetailItem = styled(ListItem)`
    display: flex;
    flex-direction: column;
    margin-top: 0;
    padding: 16px 16px 0 0;
    margin-bottom: ${({ mbottom }) => mbottom || '32px'};
    margin-left: 16px;
    margin-right: 16px;
`;

const DetailValue = styled(SecondaryText)`
    color: ${colors.themeTxtColor10};
    line-height: 5px;
    font-weight: normal;
    font-size: 14px;
`;

const ValueText = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #718596;
    opacity: 0.5;
    margin: 0;
    line-height: 2px;
`;

const DeliveryNote = styled.div`
    background: #f2f5fa;
    border-radius: 4px;
    height: 74px;
    width: 295px;
`;

const NoteText = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    max-width: 290px;
    padding-left: 16px;
    color: #6f798b;
`;

const CustomerDetails = ({ open, setOpen, customer, fromReminder }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { name, id, phoneNumber, phoneNumber2 } = customer;
    const [openOptions, setOpenOptions] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const handleClick = () => {
        history.push('/actions/shop_customers');
        setOpen(!open);
    };

    return (
        open && (
            <SlidingOverlay>
                <DesktopBackgroundLayout>
                    <TopHeader title={'Customer’s Details'}>
                        <Back onClick={() => (fromReminder ? history.goBack() : handleClick())}>
                            <BackArrow />
                        </Back>
                        <Options right={'16px'} onClick={() => setOpenOptions(!openOptions)} />
                    </TopHeader>
                    <ScreenContainer top={'90px'}>
                        <FlexCenteredBlock>
                            <AvatarIcon />
                            <DetailsName>{name}</DetailsName>
                        </FlexCenteredBlock>
                        <ListHeading weight={'bold'}>User Information</ListHeading>
                        <DetailsBusinessDescription top={'16px'} noBottomPadding={true}>
                            <DetailsBusinessDescriptionHeading top={'0px'} bottom={'15px'}>
                                <PageCount weight={'bold'}>ADDRESS</PageCount>
                            </DetailsBusinessDescriptionHeading>
                            {customer.homeAddress &&
                                Object.values(customer.homeAddress).every((value) => value !== '') && (
                                    <DetailItem>
                                        <DetailValue top={'0px'}>{customer.homeAddress.address},</DetailValue>
                                        <DetailValue top={'0px'}>{customer.homeAddress.city},</DetailValue>
                                        <DetailValue top={'0px'}>{customer.homeAddress.lga} L.G.A.</DetailValue>
                                        <DetailValue top={'0px'}>{customer.homeAddress.state} State.</DetailValue>
                                    </DetailItem>
                                )}
                            <DetailsBusinessDescriptionHeading top={'0px'} bottom={'15px'}>
                                <PageCount weight={'bold'}>CONTACT</PageCount>
                            </DetailsBusinessDescriptionHeading>
                            {phoneNumber && (
                                <DetailItem mbottom={'5px'}>
                                    <DetailValue top={'0px'}>{phoneNumber}</DetailValue>
                                    <ValueText>Phone Number 1</ValueText>
                                    <DetailsContactBlock right={'4px'}>
                                        <DetailsContactOption>
                                            <DetailsContactLink href={`tel:${phoneNumber}`}>
                                                <PageLogo
                                                    background={'#E9F2FF'}
                                                    width={'36px'}
                                                    height={'36px'}
                                                    iconHeight={'18px'}
                                                    iconWidth={'18px'}
                                                    Icon={PhoneIcon}
                                                />
                                            </DetailsContactLink>
                                        </DetailsContactOption>
                                        <DetailsContactOption>
                                            <DetailsContactLink href={`sms:${phoneNumber}`}>
                                                <PageLogo
                                                    background={'#E9F2FF'}
                                                    width={'36px'}
                                                    height={'36px'}
                                                    iconHeight={'18px'}
                                                    iconWidth={'18px'}
                                                    Icon={SMSIcon}
                                                />
                                            </DetailsContactLink>
                                        </DetailsContactOption>
                                        <DetailsContactOption>
                                            <DetailsContactLink href={`https://wa.me/+234${phoneNumber}`}>
                                                <PageLogo
                                                    background={'#E9F2FF'}
                                                    width={'36px'}
                                                    height={'36px'}
                                                    iconHeight={'24px'}
                                                    iconWidth={'24px'}
                                                    Icon={WhatsAppIcon}
                                                />
                                            </DetailsContactLink>
                                        </DetailsContactOption>
                                    </DetailsContactBlock>
                                </DetailItem>
                            )}
                            {phoneNumber2 && (
                                <DetailItem mbottom={'5px'}>
                                    <DetailValue top={'0px'}>{phoneNumber2}</DetailValue>
                                    <ValueText>Phone Number 2</ValueText>
                                    <DetailsContactBlock right={'4px'}>
                                        <DetailsContactOption>
                                            <DetailsContactLink href={`tel:${phoneNumber2}`}>
                                                <PageLogo
                                                    background={'#E9F2FF'}
                                                    width={'36px'}
                                                    height={'36px'}
                                                    iconHeight={'18px'}
                                                    iconWidth={'18px'}
                                                    Icon={PhoneIcon}
                                                />
                                            </DetailsContactLink>
                                        </DetailsContactOption>
                                        <DetailsContactOption>
                                            <DetailsContactLink href={`sms:${phoneNumber2}`}>
                                                <PageLogo
                                                    background={'#E9F2FF'}
                                                    width={'36px'}
                                                    height={'36px'}
                                                    iconHeight={'18px'}
                                                    iconWidth={'18px'}
                                                    Icon={SMSIcon}
                                                />
                                            </DetailsContactLink>
                                        </DetailsContactOption>
                                        <DetailsContactOption>
                                            <DetailsContactLink href={`https://wa.me/+234${phoneNumber2}`}>
                                                <PageLogo
                                                    background={'#E9F2FF'}
                                                    width={'36px'}
                                                    height={'36px'}
                                                    iconHeight={'24px'}
                                                    iconWidth={'24px'}
                                                    Icon={WhatsAppIcon}
                                                />
                                            </DetailsContactLink>
                                        </DetailsContactOption>
                                    </DetailsContactBlock>
                                </DetailItem>
                            )}
                            {customer.email && (
                                <DetailItem>
                                    <DetailValue top={'0px'}>{customer.email}</DetailValue>
                                    <ValueText>E-Mail Address</ValueText>
                                    <DetailsContactBlock>
                                        <DetailsContactOption>
                                            <DetailsContactLink href={`mailto:${customer.email}`}>
                                                <PageLogo
                                                    background={'#E9F2FF'}
                                                    width={'36px'}
                                                    height={'36px'}
                                                    iconHeight={'20px'}
                                                    iconWidth={'20px'}
                                                    Icon={EmailIcon}
                                                />
                                            </DetailsContactLink>
                                        </DetailsContactOption>
                                    </DetailsContactBlock>
                                </DetailItem>
                            )}

                            <DetailsBusinessDescriptionHeading top={'0px'} bottom={'15px'}>
                                <PageCount weight={'bold'}>BANK INFORMATION</PageCount>
                            </DetailsBusinessDescriptionHeading>
                            {customer.bank && Object.values(customer.bank).every((value) => value !== '') && (
                                <DetailItem>
                                    <DetailValue top={'0px'}>{customer.bank.accountNumber}</DetailValue>
                                    <ValueText>{customer.bank.name}</ValueText>
                                </DetailItem>
                            )}

                            <DetailsBusinessDescriptionHeading top={'0px'} bottom={'15px'}>
                                <PageCount weight={'bold'}>DELIVERY NOTE</PageCount>
                            </DetailsBusinessDescriptionHeading>
                            {customer.deliveryInstruction && (
                                <DetailItem mbottom={'24px'}>
                                    <DeliveryNote>
                                        <NoteText>{customer.deliveryInstruction}</NoteText>
                                    </DeliveryNote>
                                </DetailItem>
                            )}
                        </DetailsBusinessDescription>
                    </ScreenContainer>
                    <OptionsPopupDialog
                        open={openOptions}
                        size={'14px'}
                        cancel={() => setOpenOptions(!openOptions)}
                        title={'Customer Options'}
                        items={[
                            {
                                Icon: EditIcon,
                                title: 'Edit Customer details',
                                link: '/actions/shop_customers_edit',
                                linkProp: customer,
                                click: () => {
                                    setOpen(!open);
                                },
                            },
                            {
                                Icon: DeleteIcon,
                                title: 'Delete Customer',
                                click: () => {
                                    setOpenOptions(!openOptions);
                                    setOpenConfirmDelete(!openConfirmDelete);
                                },
                            },
                        ]}
                    />
                    <ConfirmPopupDialog
                        open={openConfirmDelete}
                        confirmationText={'Are you sure you want to delete this customer?'}
                        title={'Delete Customer'}
                        answers={[
                            {
                                variant: 'Cancel',
                                action: () => setOpenConfirmDelete(!openConfirmDelete),
                            },
                            {
                                Icon: DelIcon,
                                variant: 'Delete',
                                action: () => {
                                    setOpenConfirmDelete(!openConfirmDelete);
                                    setOpen(!open);
                                    dispatch(deleteCustomer(id));
                                },
                            },
                        ]}
                    />
                    {!open && <CustomersList />}
                </DesktopBackgroundLayout>
            </SlidingOverlay>
        )
    );
};

export default CustomerDetails;
