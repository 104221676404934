import React from 'react';

import { useField, ErrorMessage } from 'formik';
import { string, bool, any, func, object } from 'prop-types';
import styled from 'styled-components';

import { Clear } from '../../../../containers/HeaderContainer';
import { InputLabelBlock, InputWithValidation } from '../../../../containers/InputContainer';

const InputLabel = styled.label`
    color: ${({ color }) => color || '#718596'};
    font-size: 10px;
    position: absolute;
    top: 8px;
    height: 13px;
    margin-left: ${({ countryselection }) => (countryselection ? '50px' : '0')};
    display: none;
`;

export const InputWithLabel = (props) => {
    const {
        onChange,
        label,
        color,
        id,
        placeholder,
        valid,
        errors,
        name,
        width,
        right,
        left,
        textColor,
        top,
        height,
        textCenter,
        readOnly,
        inputMode,
        type,
        disabled,
        onKeyUp,
        onBlur,
        onFocus,
        countryselection,
        autoComplete,
        touched,
        setFieldValue,
        initialValues,
        noClearButton,
        as,
        size,
        weight,
        maxLength,
        bottom,
        onKeyPress,
        pattern,
        step,
        list,
        borderColor,
        border,
        mtop,
        padTopLeft,
        borderRadius,
        textLeft,
        mheight,
        placeHolderColor,
    } = props;
    const [input, meta] = useField(name);

    return (
        <InputLabelBlock
            border={border}
            borderRadius={borderRadius}
            left={left}
            right={right}
            id={id}
            height={height}
            width={width}
            valid={valid}
            error={errors}
            bottom={bottom}
            top={top}
            textCenter={textCenter}
            borderColor={borderColor}
            padTopLeft={padTopLeft}
            textLeft={textLeft}
        >
            <InputWithValidation
                placeholder={placeholder}
                placeHolderColor={placeHolderColor}
                countryselection={countryselection && countryselection.toString()}
                touched={touched && touched.toString()}
                inputMode={inputMode && inputMode.toString()}
                autoComplete={autoComplete && autoComplete.toString()}
                valid={valid && valid.toString()}
                errors={errors}
                readOnly={readOnly}
                onBlur={onBlur}
                textColor={textColor}
                size={size}
                weight={weight}
                onKeyUp={onKeyUp}
                onFocus={onFocus}
                onChange={onChange}
                value={input.value}
                name={name}
                mheight={mheight}
                as={as}
                type={type}
                disabled={disabled}
                maxLength={maxLength}
                onKeyPress={onKeyPress}
                pattern={pattern}
                step={step}
                list={list}
                mtop={mtop}
                {...input}
            />
            <InputLabel countryselection={countryselection} touched={touched && touched.toString()} color={color}>
                {label}
            </InputLabel>
            {(type === 'text' || type === 'number') && meta.touched && !!noClearButton === false && (
                <Clear
                    right={'true'}
                    onClick={() => {
                        document.querySelector(`input[name=${name}]`).value = '';
                        setFieldValue(name, initialValues[name]);
                    }}
                />
            )}
            <ErrorMessage name={name} component="div" className="form-error" />
        </InputLabelBlock>
    );
};

InputWithLabel.propTypes = {
    label: string.isRequired,
    placeholder: string.isRequired,
    name: string.isRequired,
    id: string,
    countrySelection: string,
    autoComplete: string,
    inputMode: string,
    valid: string,
    mheight: string,
    errors: string,
    width: string,
    height: string,
    type: string,
    right: string,
    left: string,
    top: string,
    as: string,
    borderColor: string,
    noClearButton: bool,
    readOnly: bool,
    disabled: bool,
    touched: bool,
    onKeyUp: func,
    onChange: func,
    setFieldValue: func,
    initialValues: object,
    value: any,
    list: any,
};
