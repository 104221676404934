import React, { useEffect } from 'react';

import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { PhoneNumberInput } from '../../../components';
import { getCategories } from '../../../redux/ducks/applications/my-shop/actions/shop';
import { sendTelephone } from '../../../redux/ducks/auth/phone/actions';
import { PhoneNumberSignUpValidationSchema } from '../../auth/phone/PhoneNumberSignUpValidationSchema';
import BizImg from '../assets/Biz-Group.png';
import WelcomeImg from '../assets/Hero-Images.png';
import CustomerIcon from '../assets/Icon/2 Friends.png';
import ArrowLink from '../assets/Icon/arrowVector.svg';
import CartIcon from '../assets/Icon/cart-icon.png';
import RecordIcon from '../assets/Icon/Edit.png';
import InventoryIcon from '../assets/Icon/Inventory-Vector.png';
import OrderIcon from '../assets/Icon/Order-Vector.png';
import ReportIcon from '../assets/Icon/Report-Vector.png';
import SellIcon from '../assets/Icon/Sell-Vector.png';
import AppStoreIcon from '../assets/on-AppStore.svg';
import PlayStoreIcon from '../assets/on-PlayStore.svg';
import PatternImage from '../assets/pattern.png';
import MobileAppImage from '../assets/Phone.png';
import RetailImg from '../assets/retail-seller.png';

const StyledContainer = styled.div`
    display: block;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 425px) {
        max-width: 100%;
    }
    @media (min-width: 576px) {
        max-width: 540px;
    }
    @media (min-width: 768px) {
        max-width: 720px;
    }
    @media (min-width: 992px) {
        max-width: 960px;
    }
    @media (min-width: 1200px) {
        max-width: 1140px;
    }
    @media (min-width: 1366px) {
        max-width: 1248px;
    }
`;
const StyledSection = styled.section`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    background: #ffffff;
    padding: 0px 15px 0;
    @media (max-width: 992px) {
        text-align: center;
    }
    @media (max-width: 768px) {
        position: relative;
        width: 100%;
        min-height: 1px;
        text-align: center;
        padding: 50px 25px 0px;
    }
`;
const WelcomeContent = styled.div`
    position: relative;
    -ms-flex: 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    padding-top: 185px;
    margin-top: 25px;
    @media (max-width: 992px) {
        max-width: 600px;
        margin: 0 auto;
    }
    @media (max-width: 768px) {
        -ms-flex: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding-top: 100px;
    }
`;
const WelcomeHeading = styled.h1`
    position: relative;
    font-weight: 900;
    font-size: 48px;
    color: #071827;
    line-height: 65px;
    margin-bottom: 32px;
    @media (max-width: 992px) {
        font-size: 32px;
        font-weight: 900;
        max-width: 600px;
        margin: 0 auto 32px;
        line-height: 45px;
    }
    @media (max-width: 767px) {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        max-width: 350px;
        padding: 10px 15px;
    }
`;
const StyledParagragh = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    color: #718596;
    margin-bottom: 74px;
    @media (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 40px;
        line-height: 22px;
    }
`;
const WelcomeImgWrap = styled.div`
    position: relative;
    flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    max-width: 58.333333%;
    padding-left: 15px;
    padding-right: 15px;
    @media (max-width: 768px) {
        flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
        margin-left: -15px;
        margin-right: -15px;
    }
`;
const WelcomeImage = styled.img`
    position: relative;
    max-width: 851px;
    @media (max-width: 768px) {
        max-width: 100%;
    }
`;
const GetStartedWrap = styled.div`
    position: relative;
    margin: 15px 0;
`;
export const CountryPhoneBlock = styled.div`
    position: relative;
    -webkit-align-items: center;
    align-items: center;
    background: transparent;
    font-size: 14px;
    width: 60%;
    max-width: 350px;
    max-width: 350px;
    padding: 1px;
    line-height: normal;
    margin-bottom: 15px;
    margin-right: 8px;
    border-radius: 4px;
    &::before {
        content: '';
        position: absolute;
        top: 18px;
        left: 42px;
        height: 20px;
        width: 1px;
        background: #cbd6e0;
        z-index: 1;
    }
    @media (max-width: 1200px) {
        max-width: initial;
        margin-right: 0;
        width: 100%;
    }
    @media (max-width: 768px) {
        width: 100%;
        max-width: 700px;
        margin-right: 0;
        line-height: normal;
        margin-bottom: 20px;
    }
`;
export const CurrentSelectedCountry = styled.div`
    position: absolute;
    left: 16px;
    top: 18px;
    z-index: 2;
    cursor: pointer;
`;
export const CountryFlag = styled.img`
    width: 18px;
    height: 12px;
`;
const GetStartedForm = styled(Form)`
    position: relative;
    display: flex;
    flex-wrap: wrap;
`;

const RippleButton = styled.button`
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    max-height: 56px;
    color: #ffffff;
    background: #227eff;
    padding: 16px 41px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: none !important;
    line-height: 24px;
    -webkit-box-shadow: 0px 12px 48px rgba(34, 126, 255, 0.2);
    box-shadow: 0px 12px 48px rgba(34, 126, 255, 0.2);
    outline: none;
    cursor: pointer;
    &:hover {
        color: #bfd7f8;
    }
    &:disabled {
        opacity: 1;
    }
    @media (max-width: 1200px) {
        max-width: initial;
        width: auto;
        margin: 0 auto 15px;
    }
    @media (max-width: 768px) {
        width: auto;
        margin: 0 auto 15px;
    }
`;
const StyledThemeSection = styled.section`
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    padding-top: 80px;
    padding-bottom: 90px;
    background: ${(props) => props.theme.bgColor};
    @media (max-width: 768px) {
        padding: 60px 25px 50px;
    }
`;
const Row = styled.div`
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
`;
const Tagline = styled.h2`
    font-size: 32px;
    font-weight: bold;
    line-height: 46px;
    color: #000000;
    padding: 0 5px;
    margin-bottom: 40px;
    text-align: left;
    @media (max-width: 576px) {
        font-size: 24px;
        line-height: 36px;
        text-align: center;
    }
`;
const BizImgWrap = styled.div`
    position: relative;
    -ms-flex: 50%;
    flex: 0 0 50%;
    max-width: 50%;
    left: -30px;
    @media (max-width: 992px) {
        display: none;
    }
    @media (max-width: 768px) {
        display: none;
    }
`;
const BizImage = styled.img`
    position: relative;
    max-width: 100%;
`;
const StyledFeatureList = styled.div`
    position: relative;
    -ms-flex: 50%;
    flex: 0 0 50%;
    max-width: 50%;
    @media (max-width: 992px) {
        -ms-flex: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    @media (max-width: 768px) {
        -ms-flex: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
`;
const ListRow = styled.div`
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
`;
const ListedFeature = styled.div`
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 10px;
    padding-right: 25px;
    margin-bottom: 20px;
    @media (max-width: 992px) {
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding: 15px;
    }
    @media (max-width: 767px) {
        -ms-flex: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        &:last-child {
            margin-bottom: 0px;
        }
    }
`;
const StyledIconWrap = styled.div`
    display: inline-block;
    height: 48px;
    width: 48px;
    background-color: ${(props) => props.theme.bgColor || { theme }};
    border-radius: 8px;
    text-align: center;
    padding: 13px;
    margin-bottom: 16px;
`;

const theme = {
    bgColor: '#227EFF',
};

const IconImg = styled.img`
    position: relative;
`;
const FeatureTitle = styled.h3`
    position: relative;
    font-weight: bold;
    font-size: 14px;
    color: ${(props) => props.theme.textColor};
    line-height: inherit;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: -13px;
        height: 16px;
        width: 3px;
        background: ${(props) => props.theme.border};
    }
`;
const FeatureBody = styled.p`
    position: relative;
    font-size: 14px;
    color: ${(props) => props.theme.textColor};
    line-height: 24px;
    margin: 10px 0;
`;
const StyledFeaturedList = styled.div`
    position: relative;
    -ms-flex: 50%;
    flex: 0 0 50%;
    max-width: 50%;
    right: 49%;
    @media (max-width: 992px) {
        -ms-flex: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        right: 0;
    }
    @media (max-width: 768px) {
        -ms-flex: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        right: 0;
    }
`;
const Title = styled.h4`
    position: relative;
    font-size: 16px;
    font-weight: normal;
    line-height: 23px;
    color: #ffffff;
    margin-bottom: 23px;
    @media (max-width: 768px) {
        color: rgb(255 255 255 / 70%);
        font-size: 14px;
        max-width: 300px;
        padding: 0px 15px;
        line-height: 21px;
        margin-bottom: 10px;
    }
`;
const Header = styled.h1`
    position: relative;
    font-weight: 900;
    font-size: 40px;
    color: #ffffff;
    max-width: 450px;
    line-height: 58px;
    margin-bottom: 30px;
    @media (max-width: 992px) {
        max-width: 550px;
    }
    @media (max-width: 768px) {
        font-size: 24px;
        max-width: 300px;
        padding: 0px 15px;
        line-height: 36px;
    }
`;
const StyledSpan = styled.span`
    color: ${(props) => props.theme.textColor};
`;
const IconWrap = styled.div`
    display: inline-block;
    background-color: ${(props) => props.theme.bgColor || { theme }};
    margin-bottom: 10px;
`;
const StyledLinkWrap = styled.div`
    position: relative;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
`;

const StyledLink = styled(Link)`
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #ede6fe;
    line-height: 24px;
    padding: 5px 8px;
    cursor: pointer;
    margin-bottom: 10px;
    outline: none;
    &:hover,
    &:focus {
        color: #bfd7f8;
        outline: none;
    }
    &::after {
        content: '';
        position: absolute;
        top: 10px;
        right: -18px;
        height: 14px;
        width: 18px;
        background-image: url(${ArrowLink});
    }
`;
const FeaturedImgWrap = styled.div`
    position: relative;
    -ms-flex: 50%;
    flex: 0 0 50%;
    max-width: 50%;
    left: 46%;
    @media (max-width: 992px) {
        -ms-flex: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        left: 0;
    }
    @media (max-width: 768px) {
        -ms-flex: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        left: 0;
    }
`;
const RetailerImg = styled.img`
    position: relative;
    right: -100px;
    max-width: 100%;
    padding-top: 50px;
    margin-bottom: 20px;
    @media (max-width: 992px) {
        right: 0px;
        padding-top: 10px;
    }
    @media (max-width: 768px) {
        right: 0px;
        padding-top: 10px;
    }
`;
const StyledAppDownloadWrap = styled.div`
    position: relative;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    background-color: #227eff;
    background-image: url(${PatternImage});
    padding: 42px 110px 0px;
    border-radius: 16px;
    @media (max-width: 992px) {
        padding: 42px 20px 40px;
        text-align: center;
    }
`;
const AppImgWrap = styled.div`
    position: relative;
    display: flex;
    -ms-flex: 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    @media (max-width: 992px) {
        display: none;
    }
`;
const AppContentWrap = styled.div`
    position: relative;
    flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    max-width: 58.333333%;
    padding-left: 15px;
    padding-right: 15px;
    @media (max-width: 992px) {
        -ms-flex: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
    }
    @media (max-width: 768px) {
        -ms-flex: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
    }
`;
const AppImg = styled.img`
    position: relative;
    bottom: 0px;
    max-width: 100%;
`;
const Heading = styled.h3`
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 12px;
    line-height: 46px;
    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 30px;
    }
`;
const SubHeading = styled.p`
    font-size: 16px;
    font-weight: 300;
    max-width: 350px;
    color: #e9f2ff;
    line-height: 24px;
    margin-bottom: 30px;
    @media (max-width: 992px) {
        max-width: 600px;
        margin: 0 auto 30px;
    }
    @media (max-width: 767px) {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 40px;
    }
`;
const DownloadLink = styled.a`
    position: relative;
    display: inline-block;
    width: 197px;
    height: 65.55px;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(88px);
    margin-right: 16px;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    z-index: 1;
    @media (max-width: 768px) {
        margin: 0;
        margin-bottom: 16px;
    }
`;
const DownloadImg = styled.img`
    display: block;
`;

export const Main = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCategories());
    }, [dispatch]);
    return (
        <div>
            <StyledContainer>
                <Row>
                    <StyledSection>
                        <WelcomeContent>
                            <WelcomeHeading>
                                Create, Manage and <StyledSpan theme={{ textColor: '#227EFF' }}>Grow</StyledSpan> your
                                Store.
                            </WelcomeHeading>
                            <StyledParagragh>
                                Sabi is a super application that allows you to manage your storefront, record your daily
                                sales, sell online and order for goods at wholesale prices.
                            </StyledParagragh>
                            <GetStartedWrap>
                                <Formik
                                    initialValues={{
                                        phoneNumber: '',
                                        country: 'NG',
                                    }}
                                    validationSchema={PhoneNumberSignUpValidationSchema}
                                    onSubmit={(values) => {
                                        setTimeout(() => {
                                            dispatch(sendTelephone(values.phoneNumber, values.country));
                                        }, 1000);
                                    }}
                                >
                                    {({ values, errors, initialValues }) => (
                                        <GetStartedForm>
                                            <PhoneNumberInput
                                                label="Phone Number"
                                                placeholder="Enter Phone"
                                                name="phoneNumber"
                                                type="number"
                                                inputMode={'tel'}
                                            />

                                            <RippleButton
                                                type="submit"
                                                disabled={values.phoneNumber.length < 10 || errors.phoneNumber}
                                                top={'8px'}
                                            >
                                                Get Started
                                            </RippleButton>
                                        </GetStartedForm>
                                    )}
                                </Formik>
                            </GetStartedWrap>
                        </WelcomeContent>
                        <WelcomeImgWrap>
                            <WelcomeImage src={WelcomeImg} alt="Sabi" />
                        </WelcomeImgWrap>
                    </StyledSection>
                </Row>
            </StyledContainer>
            <StyledThemeSection theme={{ bgColor: 'rgb(237 242 247 / 30%)' }}>
                <StyledContainer>
                    <Row>
                        <BizImgWrap>
                            <BizImage src={BizImg} alt="Sabi Business" />
                        </BizImgWrap>
                        <StyledFeatureList>
                            <Tagline>What we offer</Tagline>
                            <ListRow>
                                <ListedFeature>
                                    <StyledIconWrap theme={{ bgColor: '#E9F2FF' }}>
                                        <IconImg src={InventoryIcon} alt="Inventory Management" />
                                    </StyledIconWrap>
                                    <FeatureTitle
                                        theme={{
                                            border: '#227EFF',
                                            textColor: '#000000',
                                        }}
                                    >
                                        Inventory Management
                                    </FeatureTitle>
                                    <FeatureBody theme={{ textColor: '#718596' }}>
                                        Effectively and Efficiently list and manage all your products
                                    </FeatureBody>
                                </ListedFeature>
                                <ListedFeature>
                                    <StyledIconWrap theme={{ bgColor: '#FFF7E6' }}>
                                        <IconImg src={CustomerIcon} alt="Customer Management" />
                                    </StyledIconWrap>
                                    <FeatureTitle
                                        theme={{
                                            border: '#FFC042',
                                            textColor: '#000000',
                                        }}
                                    >
                                        Customer Management
                                    </FeatureTitle>
                                    <FeatureBody theme={{ textColor: '#718596' }}>
                                        Add and Engage with customers via SMS and Whatsapp
                                    </FeatureBody>
                                </ListedFeature>
                                <ListedFeature>
                                    <StyledIconWrap theme={{ bgColor: '#FDEDE7' }}>
                                        <IconImg src={RecordIcon} alt="Record daily sales" />
                                    </StyledIconWrap>
                                    <FeatureTitle
                                        theme={{
                                            border: '#EE6F44',
                                            textColor: '#000000',
                                        }}
                                    >
                                        Record Daily Sales
                                    </FeatureTitle>
                                    <FeatureBody theme={{ textColor: '#718596' }}>
                                        Easily record all your daily transactions including pending payments
                                    </FeatureBody>
                                </ListedFeature>
                                <ListedFeature>
                                    <StyledIconWrap theme={{ bgColor: '#D3FDE9' }}>
                                        <IconImg src={SellIcon} alt="Sell Stock Online" />
                                    </StyledIconWrap>
                                    <FeatureTitle
                                        theme={{
                                            border: '#07D571',
                                            textColor: '#000000',
                                        }}
                                    >
                                        Sell Online
                                    </FeatureTitle>
                                    <FeatureBody theme={{ textColor: '#718596' }}>
                                        List products on our online Marketplace, Merchbuy and Merchlist
                                    </FeatureBody>
                                </ListedFeature>
                                <ListedFeature>
                                    <StyledIconWrap theme={{ bgColor: '#EDE6FE' }}>
                                        <IconImg src={ReportIcon} alt="Sales Report and analytics" />
                                    </StyledIconWrap>
                                    <FeatureTitle
                                        theme={{
                                            border: '#7E42F5',
                                            textColor: '#000000',
                                        }}
                                    >
                                        Sales Report and Analytics
                                    </FeatureTitle>
                                    <FeatureBody theme={{ textColor: '#718596' }}>
                                        Get detailed reports and analytics on your business growth
                                    </FeatureBody>
                                </ListedFeature>
                                <ListedFeature>
                                    <StyledIconWrap theme={{ bgColor: '#FFDFDB' }}>
                                        <IconImg src={OrderIcon} alt="Order for goods" />
                                    </StyledIconWrap>
                                    <FeatureTitle
                                        theme={{
                                            border: '#E01A00',
                                            textColor: '#000000',
                                        }}
                                    >
                                        Order for goods
                                    </FeatureTitle>
                                    <FeatureBody theme={{ textColor: '#718596' }}>
                                        Order for all kinds of products at wholesale prices
                                    </FeatureBody>
                                </ListedFeature>
                            </ListRow>
                        </StyledFeatureList>
                    </Row>
                </StyledContainer>
            </StyledThemeSection>
            <StyledThemeSection theme={{ bgColor: '#003277' }}>
                <StyledContainer>
                    <Row>
                        <FeaturedImgWrap>
                            <RetailerImg src={RetailImg} alt="Spaceso2o Wholesalers and Retailers" />
                        </FeaturedImgWrap>
                        <StyledFeaturedList>
                            <Title>Who can Use Us</Title>
                            <Header>
                                We exist for <StyledSpan theme={{ textColor: '#EE6F44' }}>everyone</StyledSpan> who owns
                                a store
                            </Header>
                            <ListRow>
                                <ListedFeature>
                                    <IconWrap>
                                        <IconImg src={CartIcon} alt="Wholesalers" />
                                    </IconWrap>
                                    <FeatureTitle
                                        theme={{
                                            border: '#EE6F44',
                                            textColor: '#FFFFFF',
                                        }}
                                    >
                                        Wholesalers
                                    </FeatureTitle>
                                    <FeatureBody theme={{ textColor: '#EDE6FE' }}>
                                        Merchants who own storefronts that sell products in bulk quantities to
                                        retailers. Products can be listed on Sabi wholesale marketplace, Merchbuy.
                                    </FeatureBody>
                                </ListedFeature>
                                <ListedFeature>
                                    <IconWrap>
                                        <IconImg src={CartIcon} alt="Retailers" />
                                    </IconWrap>
                                    <FeatureTitle
                                        theme={{
                                            border: '#EE6F44',
                                            textColor: '#FFFFFF',
                                        }}
                                    >
                                        Retailers
                                    </FeatureTitle>
                                    <FeatureBody theme={{ textColor: '#EDE6FE' }}>
                                        Merchants who own storefronts that sell products in small quantities to end
                                        consumers. Products can be restocked from Sabi wholesale marketplace, Merchbuy.
                                    </FeatureBody>
                                </ListedFeature>
                            </ListRow>
                            <StyledLinkWrap>
                                <StyledLink to="/phone-signup">Get Started</StyledLink>
                            </StyledLinkWrap>
                        </StyledFeaturedList>
                    </Row>
                </StyledContainer>
            </StyledThemeSection>

            <StyledThemeSection theme={{ bgColor: '#FFFFFF' }}>
                <StyledContainer>
                    <Row>
                        <StyledAppDownloadWrap>
                            <AppImgWrap>
                                <AppImg src={MobileAppImage} alt="Sabi Mobile App" />
                            </AppImgWrap>
                            <AppContentWrap>
                                <Heading>Get started on the side</Heading>
                                <SubHeading>Get detailed reports and analytics on your business growth</SubHeading>
                                <DownloadLink href="https://play.google.com/store/apps/details?id=com.spaceso2o.superapp&hl=en&gl=US">
                                    <DownloadImg src={PlayStoreIcon} alt="Sabi am on Play Store" />
                                </DownloadLink>
                                <DownloadLink href="https://apps.apple.com/ng/app/sabi-am/id1598168157">
                                    <DownloadImg src={AppStoreIcon} alt="Sabi am on App Store" />
                                </DownloadLink>
                            </AppContentWrap>
                        </StyledAppDownloadWrap>
                    </Row>
                </StyledContainer>
            </StyledThemeSection>
        </div>
    );
};
