import moment from 'moment';

import { months } from './months';

const formattedTransactionDate = (date) => {
    const parsedDate = new Date(date);
    return `${months[parsedDate.getMonth()]} ${parsedDate.getFullYear()}`;
};

const formattedTransactionDay = (date) => {
    let day;

    if (moment(new Date(date)).isSame(new Date(), 'day')) {
        day = 'Today';
    } else if (moment(new Date(date)).isSame(moment().subtract(1, 'day'), 'day')) {
        day = 'Yesterday';
    } else {
        day = moment(new Date(date)).format('D MMMM');
    }

    return `${day}`;
};

export const parseTransactionsByDate = (transactions, dateParam = 'createdAt') => {
    if (!transactions || !transactions.length || transactions.length === 0) return [];

    const formattedTransactions = [];

    const transactionsDates = new Set(
        transactions.map((transaction) => formattedTransactionDate(transaction[dateParam])),
    );

    [...transactionsDates.values()].forEach((date) => {
        const savedDates = formattedTransactions.map((savedTransaction) => savedTransaction.date);

        if (!savedDates.includes(date)) {
            const transactionsByDate = [];

            transactions.forEach((transaction) => {
                const transactionDate = formattedTransactionDate(transaction[dateParam]);

                if (transactionDate === date) {
                    transactionsByDate.push(transaction);
                }
            });

            formattedTransactions.push({ date, transactions: transactionsByDate });
        }
    });

    return formattedTransactions;
};

export const parseTransactionsByDay = (transactions, dateParam = 'createdAt') => {
    if (!transactions || !transactions.length || transactions.length === 0) return [];

    const formattedTransactions = [];

    const transactionsDates = new Set(
        transactions.map((transaction) => formattedTransactionDay(transaction[dateParam])),
    );

    [...transactionsDates.values()].forEach((day) => {
        const savedDates = formattedTransactions.map((savedTransaction) => savedTransaction.day);

        if (!savedDates.includes(day)) {
            const transactionsByDay = [];

            transactions.forEach((transaction) => {
                const transactionDate = formattedTransactionDay(transaction[dateParam]);

                if (transactionDate === day) {
                    transactionsByDay.push(transaction);
                }
            });

            formattedTransactions.push({ day, transactions: transactionsByDay });
        }
    });

    return formattedTransactions;
};
