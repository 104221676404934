import React from 'react';

import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../../assets/close3.svg';
import { BlurBackground } from '../../../containers/BlurBackground';
import { Title, SubTitle } from '../../../containers/MessageContainer';
import { FlexContainer } from '../../../containers/ScreenContainer';
import { colors } from '../../../styles';
import { RippleButton } from '../../button';

const BlurPopUpBackground = styled(BlurBackground)`
    bottom: 0;
    position: fixed;
`;

const WalletActivationContainer = styled.div`
    height: 299px;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background-color: white;
    border-radius: 16px 16px 0 0;
    padding: 33px 16px 32px;
`;

export const RemoveSelectedCartItems = ({ title, subtitle, close, btn1Action, btn2Action }) => {
    return (
        <BlurPopUpBackground onClick={close}>
            <WalletActivationContainer>
                <FlexContainer bottom={'28px'}>
                    <Title bottom={'0px'} size={'16px'} weight={'700'} color={colors.themeTextColor3}>
                        {title}
                    </Title>
                    <CloseIcon onClick={close} />
                </FlexContainer>

                <SubTitle weight={'400'} lineHeight={'24px'} color={colors.boldDark} size={'16px'}>
                    {subtitle}
                </SubTitle>
                <RippleButton onClick={btn1Action} size={'16px'} height={'48px'} top={'24px'}>
                    Yes, add to my saved items
                </RippleButton>
                <RippleButton
                    onClick={btn2Action}
                    backgroundColor={colors.gray4}
                    size={'16px'}
                    height={'48px'}
                    color={colors.deepBlue}
                    top={'24px'}
                >
                    No, remove item(s)
                </RippleButton>
            </WalletActivationContainer>
        </BlurPopUpBackground>
    );
};
