import React, { Fragment } from 'react';

import { SuccessPage } from '../../../../components';
import { SlidingOverlay } from '../../../../containers/OverlayContainer';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';

const SetupStoreFrontFailure = ({ open, setOpen, update }) => {
    function handleClick() {
        setOpen();
    }

    return (
        open && (
            <Fragment>
                <SlidingOverlay>
                    <DesktopBackgroundLayout>
                        <SuccessPage
                            title={update ? 'Store Update Failed' : 'Store Creation Failed'}
                            subtitle={
                                update
                                    ? "We couldn't update your Store . Kindly try again"
                                    : "We couldn't create your Store . Kindly try again"
                            }
                            doneText={'Try Again'}
                            onDoneClick={handleClick}
                            mposition={'fixed'}
                            position={'absolute'}
                            successfull={false}
                        />
                    </DesktopBackgroundLayout>
                </SlidingOverlay>
            </Fragment>
        )
    );
};

export default SetupStoreFrontFailure;
