import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { SearchHeader } from '../../../../../../../components';
import { Close } from '../../../../../../../containers/HeaderContainer';
import { List, ListHeading, ListSubHeading, ListItem } from '../../../../../../../containers/ListContainer';
import { SlidingOverlay } from '../../../../../../../containers/OverlayContainer';
import { ScreenContainer } from '../../../../../../../containers/ScreenContainer';
import DefaultIcon from '../../assets/default_product.svg';
import { ReactComponent as Add } from '../../assets/unit_add.svg';

const SpacedBlock = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: ${({ align }) => align || 'center'};
    margin: ${({ top }) => top || '0px'};
    padding: 4px;
`;

const ModifiedListItem = styled(ListItem)`
    display: flex;
    flex-direction: column;
    margin: 8px 16px 8px 16px;
    padding: 8px 8px 8px 8px;
    border-radius: 10px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    overflow: hidden;
    cursor: pointer;
    -webkit-tap-highlight-color: #f9fafc;

    &:hover {
        background: transparent;
    }
`;

export const ListLeftBlock = styled.div`
    display: flex;
    flex-direction: row;
`;

const ListAdditionalInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const ProductIcon = styled.img`
    width: 32px;
    height: 32px;
    margin-right: 8px;
`;

export const AddProductCategory = ({ open, setOpen, category }) => {
    // const productCategeries = useSelector(
    //      state => state.applications.merchbuy.MerchbuyProductCategeries
    // );
    const productCategeries = useSelector((state) => state.applications.myShop.productCategories);
    const [filteredProductCategory] = useState(
        productCategeries &&
            productCategeries.filter((data) => data.listingOptions && data.listingOptions.isOnMerchBuy),
    );
    const [categories, setCategories] = useState(filteredProductCategory);

    const handleSearch = (searchValue) => {
        setCategories(
            filteredProductCategory &&
                filteredProductCategory.filter(
                    (data) => data.name && data.name.toLowerCase().includes(searchValue.toLowerCase()),
                ),
        );
    };

    return (
        open && (
            <SlidingOverlay>
                <SearchHeader
                    title={'Product Categories'}
                    placeholder={'Search product category...'}
                    handleSearch={handleSearch}
                    noArrow={true}
                    withSpacesHeader
                >
                    <Close left={'16px'} onClick={() => setOpen(false)} />
                </SearchHeader>
                <ScreenContainer top="120px">
                    <List fullScreen noBorderBottom>
                        {categories &&
                            categories.map((item, index) => (
                                <ModifiedListItem key={index}>
                                    <ListLeftBlock
                                        value={item}
                                        onClick={() => {
                                            category(item);
                                            setOpen(false);
                                            setCategories(filteredProductCategory);
                                        }}
                                    >
                                        <SpacedBlock>
                                            <ListLeftBlock>
                                                <ProductIcon
                                                    src={item.image || DefaultIcon || ''}
                                                    alt="product_image"
                                                />
                                                <ListAdditionalInfo>
                                                    <ListHeading fontSize={'12px'}>{item.name}</ListHeading>
                                                    <ListSubHeading>{item.description}</ListSubHeading>
                                                </ListAdditionalInfo>
                                            </ListLeftBlock>
                                            <Add />
                                        </SpacedBlock>
                                    </ListLeftBlock>
                                </ModifiedListItem>
                            ))}
                    </List>
                </ScreenContainer>
            </SlidingOverlay>
        )
    );
};
