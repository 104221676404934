import React, { Fragment } from 'react';

import { TopHeader } from '../../components';
import { Message, BoldText, SubTitle } from '../../containers/MessageContainer';
import { ScreenContainer } from '../../containers/ScreenContainer';
import DesktopBackgroundLayout from '../DesktopBackgroundLayout';

export const TermsAndConditions = () => (
    <Fragment>
        <DesktopBackgroundLayout>
            <TopHeader title={'Terms & Conditions'} />
            <ScreenContainer>
                <SubTitle textAlign="left" top="30px">
                    1. Introduction
                </SubTitle>
                <Message top="10px">
                    Welcome to <BoldText>O2O Network Limited</BoldText> (“Company”, “we”, “our”, “us”)!
                    <br />
                    <br />
                    These Terms of Service (“Terms”, “Terms of Service”) govern your use of our website located at{' '}
                    <BoldText>
                        <a href="www.sabi.am/App" rel="noreferrer noopener" target="_blank">
                            www.sabi.am/App:{' '}
                        </a>
                    </BoldText>
                    <BoldText>Sabi </BoldText>
                    (together or individually “Service”) operated by <BoldText>O2O Network Limited</BoldText>.<br />
                    Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and
                    disclose information that results from your use of our web pages.
                    <br />
                    Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge
                    that you have read and understood Agreements, and agree to be bound of them.
                    <br />
                    If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but
                    please let us know by emailing at{' '}
                    <BoldText>
                        <a href="mailto: support@sabi.am"> support@sabi.am </a>
                    </BoldText>{' '}
                    so we can try to find a solution. These Terms apply to all visitors, users and others who wish to
                    access or use Service.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    2. Communications
                </SubTitle>
                <Message top="3px">
                    By using our Service, you agree to subscribe to newsletters, marketing or promotional materials and
                    other information we may send. However, you may opt out of receiving any, or all, of these
                    communications from us by following the unsubscribe link or by emailing at
                    <BoldText>
                        <a href="mailto: support@sabi.am"> support@sabi.am </a>
                    </BoldText>
                </Message>

                <SubTitle textAlign="left" top="30px">
                    3. Purchases
                </SubTitle>
                <Message top="3px">
                    If you wish to purchase any product or service made available through Service (“Purchase”), you may
                    be asked to supply certain information relevant to your Purchase including but not limited to, your
                    credit or debit card number, the expiration date of your card, your billing address, and your
                    shipping information.
                    <br />
                    You represent and warrant that: (i) you have the legal right to use any card(s) or other payment
                    method(s) in connection with any Purchase; and that (ii) the information you supply to us is true,
                    correct and complete.
                    <br />
                    We may employ the use of third party services for the purpose of facilitating payment and the
                    completion of Purchases. By submitting your information, you grant us the right to provide the
                    information to these third parties subject to our Privacy Policy.
                    <br />
                    We reserve the right to refuse or cancel your order at any time for reasons including but not
                    limited to: product or service availability, errors in the description or price of the product or
                    service, error in your order or other reasons.
                    <br />
                    We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal
                    transaction is suspected.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    4. Promotions
                </SubTitle>
                <Message top="3px">
                    Any promotions (collectively, “Promotions”) made available through Service may be governed by rules
                    that are separate from these Terms of Service. If you participate in any Promotions, please review
                    the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these
                    Terms of Service, Promotion rules will apply.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    5. Refunds
                </SubTitle>
                <Message top="3px">
                    We issue refunds for Contracts within <BoldText>30 days</BoldText> of the original purchase of the
                    Contract.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    6. Content
                </SubTitle>
                <Message top="3px">
                    Our Service does not allow you to post, link, store, share and otherwise make available certain
                    information, text, graphics, videos, or other material (“Content”). You are responsible for Content
                    that you post on or through Service, including its legality, reliability, and appropriateness.
                    <br />
                    By posting Content on or through Service, you represent and warrant that: (i) Content is yours (you
                    own it) and/or you have the right to use it and the right to grant us the rights and license as
                    provided in these Terms, and (ii) that the posting of your Content on or through Service does not
                    violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any
                    person or entity. We reserve the right to terminate the account of anyone found to be infringing on
                    a copyright.
                    <br />
                    You retain any and all of your rights to any Content you submit, post or display on or through
                    Service and you are responsible for protecting those rights. We take no responsibility and assume no
                    liability for Content you or any third party posts on or through Service. However, by posting
                    Content using Service you grant us the right and license to use, modify, publicly perform, publicly
                    display, reproduce, and distribute such Content on and through Service. You agree that this license
                    includes the right for us to make your Content available to other users of Service, who may also use
                    your Content subject to these Terms.
                    <br />
                    O2O Network Limited has the right but not the obligation to monitor and edit all Content provided by
                    users. In addition, Content found on or through this Service are the property of O2O Network Limited
                    or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or
                    use said Content, whether in whole or in part, for commercial purposes or for personal gain, without
                    express advance written permission from us.
                    <br />
                </Message>

                <SubTitle textAlign="left" top="30px">
                    7. Prohibited Uses
                </SubTitle>
                <Message top="3px">
                    You may use Service only for lawful purposes and in accordance with Terms. You agree not to use
                    Service:
                    <Message left="10px" top="5px">
                        i. In any way that violates any applicable national or international law or regulation.
                        <br />
                        ii. For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way
                        by exposing them to inappropriate content or otherwise.
                        <br />
                        iii. To transmit, or procure the sending of, any advertising or promotional material, including
                        any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.
                        <br />
                        iv. To impersonate or attempt to impersonate Company, a Company employee, another user, or any
                        other person or entity.
                        <br />
                        v. In any way that infringes upon the rights of others, or in any way is illegal, threatening,
                        fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful
                        purpose or activity.
                        <br />
                        vi. To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of
                        Service, or which, as determined by us, may harm or offend Company or users of Service or expose
                        them to liability.
                        <br />
                        <br />
                    </Message>
                    <SubTitle textAlign="left" top="5px">
                        Additionally, you agree not to:
                    </SubTitle>
                    <Message left="10px" top="5px">
                        i. Use Service in any manner that could disable, overburden, damage, or impair Service or
                        interfere with any other party’s use of Service, including their ability to engage in real time
                        activities through Service.
                        <br />
                        ii. Use any robot, spider, or other automatic device, process, or means to access Service for
                        any purpose, including monitoring or copying any of the material on Service.
                        <br />
                        iii. Use any manual process to monitor or copy any of the material on Service or for any other
                        unauthorized purpose without our prior written consent.
                        <br />
                        iv. Use any device, software, or routine that interferes with the proper working of Service.
                        <br />
                        v. Introduce any viruses, trojan horses, worms, logic bombs, or other material which is
                        malicious or technologically harmful.
                        <br />
                        vi. Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of
                        Service, the server on which Service is stored, or any server, computer, or database connected
                        to Service.
                        <br />
                        vii. Attack Service via a denial-of-service attack or a distributed denial-of-service attack.
                        <br />
                        viii. Take any action that may damage or falsify Company rating.
                        <br />
                        ix. Otherwise attempt to interfere with the proper working of Service.
                        <br />
                    </Message>
                </Message>

                <SubTitle textAlign="left" top="30px">
                    8. Analytics
                </SubTitle>
                <Message top="3px">
                    We may use third-party Service Providers to monitor and analyze the use of our Service.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    9. No Use by Minors
                </SubTitle>
                <Message top="3px">
                    Service is intended only for access and use by individuals at least eighteen (18) years old. By
                    accessing or using Service, you warrant and represent that you are at least eighteen (18) years of
                    age and with the full authority, right, and capacity to enter into this agreement and abide by all
                    of the terms and conditions of Terms. If you are not at least eighteen (18) years old, you are
                    prohibited from both the access and usage of Service.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    10. Accounts
                </SubTitle>
                <Message top="3px">
                    When you create an account with us, you guarantee that you are above the age of 18, and that the
                    information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete,
                    or obsolete information may result in the immediate termination of your account on Service. You are
                    responsible for maintaining the confidentiality of your account and password, including but not
                    limited to the restriction of access to your computer and/or account. You agree to accept
                    responsibility for any and all activities or actions that occur under your account and/or password,
                    whether your password is with our Service or a third-party service. You must notify us immediately
                    upon becoming aware of any breach of security or unauthorized use of your account. You may not use
                    as a username the name of another person or entity or that is not lawfully available for use, a name
                    or trademark that is subject to any rights of another person or entity other than you, without
                    appropriate authorization. You may not use as a username any name that is offensive, vulgar or
                    obscene. We reserve the right to refuse service, terminate accounts, remove or edit content, or
                    cancel orders in our sole discretion.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    11. Intellectual Property
                </SubTitle>
                <Message top="3px">
                    Service and its original content (excluding Content provided by users), features and functionality
                    are and will remain the exclusive property of O2O Network Limited and its licensors. Service is
                    protected by copyright, trademark, and other laws of and foreign countries. Our trademarks may not
                    be used in connection with any product or service without the prior written consent of O2O Network
                    Limited.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    12. Copyright Policy
                </SubTitle>
                <Message top="3px">
                    We respect the intellectual property rights of others. It is our policy to respond to any claim that
                    Content posted on Service infringes on the copyright or other intellectual property rights
                    (“Infringement”) of any person or entity. If you are a copyright owner, or authorized on behalf of
                    one, and you believe that the copyrighted work has been copied in a way that constitutes copyright
                    infringement, please submit your claim via email to
                    <BoldText>
                        <a href="mailto: support@sabi.am"> support@sabi.am </a>
                    </BoldText>
                    , with the subject line: “Copyright Infringement” and include in your claim a detailed description
                    of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright
                    Infringement Claims” You may be held accountable for damages (including costs and attorneys’ fees)
                    for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through
                    Service on your copyright.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    13. Notice and Procedure for Copyright Infringement Claims
                </SubTitle>
                <Message top="3px">
                    You may submit a notification by providing our Compliance Officer with the following information in
                    writing:
                    <Message left="10px" top="5px">
                        i. an electronic or physical signature of the person authorized to act on behalf of the owner of
                        the copyright’s interest;
                        <br />
                        ii. a description of the copyrighted work that you claim has been infringed, including the URL
                        (i.e., web page address) of the location where the copyrighted work exists or a copy of the
                        copyrighted work;
                        <br />
                        iii. identification of the URL or other specific location on Service where the material that you
                        claim is infringing is located;
                        <br />
                        iv. your address, telephone number, and email address;
                        <br />
                        v. a statement by you that you have a good faith belief that the disputed use is not authorized
                        by the copyright owner, its agent, or the law;
                        <br />
                        vi. a statement by you, made under penalty of perjury, that the above information in your notice
                        is accurate and that you are the copyright owner or authorized to act on the copyright owner’s
                        behalf.
                        <br />
                        <br />
                    </Message>
                    You can contact our Compliance Officer via email at
                    <BoldText>
                        <a href="mailto:compliance@sabi.am."> compliance@sabi.am.</a>
                    </BoldText>
                </Message>

                <SubTitle textAlign="left" top="30px">
                    14. Error Reporting and Feedback
                </SubTitle>
                <Message top="3px">
                    You may provide us either directly at support@sabi.am or via third party sites and tools with
                    information and feedback concerning errors, suggestions for improvements, ideas, problems,
                    complaints, and other matters related to our Service (“Feedback”). You acknowledge and agree that:
                    (i) you shall not retain, acquire or assert any intellectual property right or other right, title or
                    interest in or to the Feedback; (ii) Company may have development ideas similar to the Feedback;
                    (iii) Feedback does not contain confidential information or proprietary information from you or any
                    third party; and (iv) Company is not under any obligation of confidentiality with respect to the
                    Feedback. In the event the transfer of the ownership to the Feedback is not possible due to
                    applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable,
                    irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy,
                    modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and
                    for any purpose.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    15. Links to Other Web Sites
                </SubTitle>
                <Message top="3px">
                    Our Service may contain links to third party web sites or services that are not owned or controlled
                    by O2O Network Limited. O2O Network Limited has no control over, and assumes no responsibility for
                    the content, privacy policies, or practices of any third party web sites or services. We do not
                    warrant the offerings of any of these entities/individuals or their websites.
                    <br />
                    YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY,
                    FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON
                    ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR
                    SERVICES.
                    <br /> WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY
                    WEB SITES OR SERVICES THAT YOU VISIT.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    16. Disclaimer of Warranty
                </SubTitle>
                <Message top="3px">
                    THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO
                    REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR
                    SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR
                    USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE
                    RISK. NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION
                    WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
                    SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY
                    REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH
                    THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
                    CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
                    HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL
                    OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND,
                    WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                    MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT
                    ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    17. Limitation of Liability
                </SubTitle>
                <Message top="3px">
                    EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS
                    HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES
                    (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT
                    TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN
                    ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH
                    THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE,
                    ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES,
                    RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH
                    DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE
                    LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE
                    BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                    PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO
                    YOU.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    18. Termination
                </SubTitle>
                <Message top="3px">
                    We may terminate or suspend your account and bar access to Service immediately, without prior notice
                    or liability, under our sole discretion, for any reason whatsoever and without limitation, including
                    but not limited to a breach of Terms. If you wish to terminate your account, you may simply
                    discontinue using Service.All provisions of Terms which by their nature should survive termination
                    shall survive termination, including, without limitation, ownership provisions, warranty
                    disclaimers, indemnity and limitations of liability.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    19. Governing Law
                </SubTitle>
                <Message top="3px">
                    These Terms shall be governed and construed in accordance with the laws of the Federal Republic of
                    Nigeria, which governing law applies to agreement without regard to its conflict of law
                    provisions.Our failure to enforce any right or provision of these Terms will not be considered a
                    waiver of those rights.If any provision of these Terms is held to be invalid or unenforceable by a
                    court, the remaining provisions of these Terms will remain in effect. These Terms constitute the
                    entire agreement between us regarding our Service and supersede and replace any prior agreements we
                    might have had between us regarding Service.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    20. Changes to Service
                </SubTitle>
                <Message top="3px">
                    We reserve the right to withdraw or amend our Service, and any service or material we provide via
                    Service, in our sole discretion without notice. We will not be liable if for any reason all or any
                    part of Service is unavailable at any time or for any period. From time to time, we may restrict
                    access to some parts of Service, or the entire Service, to users, including registered users.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    21. Amendments to Terms
                </SubTitle>
                <Message top="3px">
                    We may amend Terms at any time by posting the amended terms on this site. It is your responsibility
                    to review these Terms periodically.Your continued use of the Platform following the posting of
                    revised Terms means that you accept and agree to the changes. You are expected to check this page
                    frequently so you are aware of any changes, as they are binding on you.By continuing to access or
                    use our Service after any revisions become effective, you agree to be bound by the revised terms. If
                    you do not agree to the new terms, you are no longer authorized to use Service.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    22. Waiver and Severability
                </SubTitle>
                <Message top="3px">
                    No waiver by Company of any term or condition set forth in Terms shall be deemed a further or
                    continuing waiver of such term or condition or a waiver of any other term or condition, and any
                    failure of Company to assert a right or provision under Terms shall not constitute a waiver of such
                    right or provision. If any provision of Terms is held by a court or other tribunal of competent
                    jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be
                    eliminated or limited to the minimum extent such that the remaining provisions of Terms will
                    continue in full force and effect.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    23. Acknowledgement
                </SubTitle>
                <Message top="3px">
                    BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF
                    SERVICE AND AGREE TO BE BOUND BY THEM.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    24. Contact Us
                </SubTitle>
                <Message top="3px">
                    Please send your feedback, comments, requests for technical support by email:
                    <BoldText>
                        <a href="mailto: support@sabi.am"> support@sabi.am </a>
                    </BoldText>
                    .
                </Message>

                <SubTitle font="18px" textAlign="left" top="60px">
                    Legal Disclaimer & Terms of Use and Photo Disclaimer
                </SubTitle>
                <SubTitle textAlign="left" top="16px">
                    PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE
                </SubTitle>
                <Message top="10px">
                    O2O Network Limited’s website (under the trading name “Sabi”/App name: Sabi) may include links
                    providing direct access to other Internet sites or images from other website. O2O Network Limited
                    takes no responsibility for the content or information contained on those other sites, and does not
                    exert any editorial, monitoring or other control over those other sites and therefore does not
                    assume any liability for those sites, their content or their privacy practices. All images used are
                    for illustrative and advertisement purposes and are not intended to be relied upon for, nor to form
                    part of, any contract unless specifically incorporated in writing into the contract
                </Message>

                <SubTitle textAlign="left" top="30px">
                    How You May Use Material on Our Site
                </SubTitle>
                <Message top="3px">
                    We are not the owner or the licensee of all intellectual property rights to photos/images in our
                    site, and in the material published on it. Those works are protected by copyright laws and treaties
                    around the world. All such rights are reserved.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    We Are Not Responsible for Websites We Link To
                </SubTitle>
                <Message top="3px">
                    Where our site contains links to other sites and resources provided by third parties, these links
                    are provided for your information only. Such links should not be interpreted as approval by us of
                    those linked websites or information you may obtain from them. We have no control over the contents
                    of those sites or resources.
                </Message>

                <SubTitle textAlign="left" top="30px">
                    User-generated Content Is Not Approved by Us
                </SubTitle>
                <Message top="3px">
                    This website may include information and materials uploaded by other users of the site, including
                    photos. This information and these materials have not been verified or approved by us. The views
                    expressed by other users on our site do not represent our views or values. If you wish to complain
                    about information and materials uploaded by other users, please contact us on info@sabi.am
                </Message>

                <SubTitle textAlign="left" top="30px">
                    We Are Not Responsible for Viruses and You Must Not Introduce Them
                </SubTitle>
                <Message top="3px">
                    We do not guarantee that our site will be secure or free from bugs or viruses. You are responsible
                    for configuring your information technology, computer programmes and platform to access our site.
                    You should use your own virus protection software. You must not misuse our site by knowingly
                    introducing viruses, Trojans, worms, logic bombs or other material that is malicious or
                    technologically harmful. You must not attempt to gain unauthorised access to our site, the server on
                    which our site is stored or any server, computer or database connected to our site. In the event of
                    such a breach, your right to use our site will cease immediately.
                </Message>
            </ScreenContainer>
        </DesktopBackgroundLayout>
    </Fragment>
);
