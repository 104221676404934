import React, { useState, useEffect, Fragment } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { matchPath } from 'react-router-dom';
import styled from 'styled-components';
import useRouter from 'use-react-router';

import { Loader } from '../../../../components';
import {
    getProductsByPublicStoreId,
    getStoreByLink,
} from '../../../../redux/ducks/applications/store-front/actions/store';
import { colors } from '../../../../styles';
import { formatCurrency } from '../../../../utils/currency/parseBalance';
import History from '../../../../utils/History';
import { toTitleCase } from '../../../../utils/toTitleCase';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { ReactComponent as Cart } from '../assets/cart.svg';
import { ReactComponent as Empty } from '../assets/empty.svg';
import { ReactComponent as Wallet } from '../assets/wallet.svg';
import StoreFrontFooter from '../footer';
import StoreFrontHeader from '../header';

const ProductOutterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 8px;
    padding: 0 16px;
`;

const ProductWrapper = styled.div`
    width: 47%;
    height: 176px;
    background: ${colors.white};
    border-radius: 8px;
    padding: 8px;
    margin-top: 16px;
`;

const Image = styled.img`
    width: 100%;
    height: 98px;
    border-radius: 4px;
`;

const ProductTitle = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #2e4457;
    margin-top: 10px;
`;

const ProductAmount = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.boldDark};
    margin-top: 12px;
`;

const Space = styled.div`
    height: 24px;
`;

const TabWrapper = styled.div`
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
    padding-right: 20px;
    background: ${colors.white};
`;

const TabInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: ${({ index }) => index === 1 && '10px'};
`;

const Tab = styled.div`
    font-size: 12px;
    line-height: 18px;
    color: ${colors.boldDark};
    margin-left: 7px;
    padding-top: ${({ index }) => index === 1 && '10px'};
`;

const CartWrapper = styled.div`
    width: 16px;
    height: 16px;
    padding: 0px 4px;
    background: #ee6f44;
    border-radius: 26px;
    position: relative;
    top: 10px;
    left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CartItem = styled.div`
    font-size: 8px;
    line-height: 16px;
    color: ${colors.white};
`;

const EmptyDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: center;
    align-items: center;
`;

const EmptyText = styled.div`
    font-size: 14px;
    font-weight: 700;
    color: ${colors.themeTxtColor10};
    margin-top: 19px;
`;

const TabLine = styled.div`
    width: 22px;
    height: 0px;
    border: 0.5px solid #cbd6e0;
    transform: rotate(90deg);
    margin-left: 41px;
    margin-right: 61px;
`;

const CustomersListProducts = () => {
    const dispatch = useDispatch();
    const { location } = useRouter();
    const { pathname } = location;
    const pattern = `(.*)?${'/actions/store_front/:store_link'}`;
    const match = matchPath(pathname, { path: pattern }) || {};
    const store = useSelector((state) => state.applications.storeFront.storeByLink);
    const products = useSelector((state) => state.applications.storeFront.publicProducts);
    const loading = useSelector((state) => state.applications.storeFront.loadingStore);
    const loadingProducts = useSelector((state) => state.applications.storeFront.loadingProducts);

    const [tabs] = useState(['My Orders', 'Cart']);
    const [cartLength, setCartLength] = useState(0);

    useEffect(() => {
        dispatch(getStoreByLink(match.params.store_link));
    }, [dispatch, match.params.store_link]);

    useEffect(() => {
        if (store?.id) {
            dispatch(getProductsByPublicStoreId(store?.id));
        }
    }, [dispatch, store]);

    useEffect(() => {
        const carts = JSON.parse(localStorage.getItem('storeFrontCart'));
        if (carts) {
            setCartLength(carts?.length);
        }
    }, []);

    if (loading || loadingProducts) return <Loader />;

    return (
        <Fragment>
            {store.status === 'ACTIVE' ? (
                <DesktopBackgroundLayout fullHeight bgColor="#F9FAFC">
                    <StoreFrontHeader />
                    <TabWrapper>
                        {tabs.map((data, index) => (
                            <TabInnerWrapper index={index} key={index}>
                                {index === 0 ? (
                                    <Wallet onClick={() => History.push('/actions/store_front/store_link/orders')} />
                                ) : (
                                    <div onClick={() => History.push('/actions/store_front/store_link/cart')}>
                                        <CartWrapper>
                                            <CartItem>{cartLength}</CartItem>
                                        </CartWrapper>
                                        <Cart />
                                    </div>
                                )}
                                <Tab
                                    onClick={() =>
                                        History.push(
                                            `${
                                                index === 0
                                                    ? '/customer/phone_check'
                                                    : '/actions/store_front/store_link/cart'
                                            }`,
                                        )
                                    }
                                    index={index}
                                >
                                    {data}
                                </Tab>
                                {index === 0 && <TabLine />}
                            </TabInnerWrapper>
                        ))}
                    </TabWrapper>
                    <ProductOutterWrapper>
                        {products?.map((data, index) => (
                            <ProductWrapper
                                onClick={() => History.push(`/actions/store_front/store_id/${data.id}`)}
                                key={index}
                            >
                                <Image src={data?.images[data?.images?.length - 3]?.imageUrl} />
                                <ProductTitle>{toTitleCase(data?.name)}</ProductTitle>
                                <ProductAmount>{formatCurrency(data?.price)}</ProductAmount>
                            </ProductWrapper>
                        ))}
                    </ProductOutterWrapper>
                    <Space />
                    <StoreFrontFooter />
                </DesktopBackgroundLayout>
            ) : (
                <DesktopBackgroundLayout>
                    <StoreFrontHeader />
                    <EmptyDiv>
                        <Empty />
                        <EmptyText>This store is currently offline</EmptyText>
                    </EmptyDiv>
                </DesktopBackgroundLayout>
            )}
        </Fragment>
    );
};

export default CustomersListProducts;
