import React, { Fragment, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { LightButton } from '../../../../components/button';
import { BlurBackground } from '../../../../containers/BlurBackground';
import { Message } from '../../../../containers/MessageContainer';
import { saveUserEducation } from '../../../../redux/ducks/applications/merchbuy/actions';
import { START_SEARCH_EDUCATION, FINISH_SEARCH_EDUCATION } from '../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../utils/mix-panel/mixPanel';
import { ReactComponent as CloseIcon } from '../assets/close-educate.svg';
import educate1 from '../assets/educate1.png';
import educate2 from '../assets/educate2.png';
import educate3 from '../assets/educate3.png';
import educate4 from '../assets/educate4.png';
import { ReactComponent as WhatsNewIcon } from '../assets/what-new.svg';

const HowToSlider = styled.div`
    height: 471px;
    width: 100%;
    max-width: 340px;
    background-color: white;
    position: ${({ position }) => position || 'fixed'};
    top: ${({ top }) => top || '55px'};
    transform: ${({ transform }) => transform || null};
    z-index: 5;
    display: grid;
    border-radius: 8px;
    justify-content: center;

    @media (max-width: 576px) {
        max-width: 380px;
    }
    @media (max-width: 400px) {
        max-width: 350px;
    }

    @media (max-width: 370px) {
        height: 453px;
        max-width: 300px;
    }
`;

const BottomView = styled.div`
    position: relative;
    bottom: 0px;
    padding: 0 19px;
    min-height: 290px;
    @media (max-width: 370px) {
        height: 270px;
    }
`;

const LinkWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    @media (max-width: 370px) {
        margin-top: 50px;
    }
`;

const WhatsNewWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 24px 0 0 0;
    gap: 8px;
`;

const CancelWrapper = styled.div`
    position: absolute;
    top: -56px;
    right: 0;
    opacity: 0;
    transition: all 1s ease-out;
    -webkit-animation: fadeIn 1s ease both;
    animation: fadeIn 1s ease both;
    animation-delay: 3s;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @media (max-width: 370px) {
        top: -50px;
    }
`;

const EducateSearchUser = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const [step, setStep] = useState(0);
    const [close, setClose] = useState(false);
    const [fadeIn, setFadeIn] = useState('');
    const [stepInfo] = useState([
        {
            stepText: "What's new",
            title: 'Find products faster with our improved search feature',
            description: 'Find products faster and easier on our new improved search feature',
            links: ['', 'See how'],
            image: educate1,
        },
        {
            stepText: 'Step 1',
            title: 'Enter the product you are looking for.',
            description: 'Find products faster and easier on our new improved search feature',
            links: ['Previous', 'Next'],
            image: educate2,
        },
        {
            stepText: 'Step 2',
            title: 'Select from a list of related search terms',
            description: 'Find products faster and easier on our new improved search feature',
            links: ['Previous', 'Next'],
            image: educate3,
        },
        {
            stepText: 'Step 3',
            title: 'Compare and select from the result list.',
            description: 'Find products faster and easier on our new improved search feature',
            links: ['Previous', 'Start  Searching'],
            image: educate4,
        },
    ]);

    useEffect(() => {
        mixPanel.track(START_SEARCH_EDUCATION, {
            'User ID': user.userId,
            Time: new Date().toLocaleString(),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const previousLink = () => {
        if (step !== 0) {
            setStep(step - 1);
        }
    };

    const payload = {
        education: 'Search Education',
    };

    const nextLink = () => {
        if (step !== 3) {
            setStep(step + 1);
        }

        if (step === 3) {
            mixPanel.track(FINISH_SEARCH_EDUCATION, {
                'User ID': user.userId,
                Time: new Date().toLocaleString(),
            });
            setClose(true);
            dispatch(saveUserEducation(payload));
            setTimeout(() => {
                history.push('/actions/merchbuy/search');
            });
        }
    };

    const { stepText, title, description, links, image } = stepInfo[step] || {};
    const [leftLink, rightLink] = links || [];

    if (close) return null;

    const handleClose = () => {
        setFadeIn('fadeOut');
        setTimeout(() => {
            setClose(true);
            dispatch(saveUserEducation(payload));
        }, 1000);
    };

    return (
        <Fragment>
            <BlurBackground position="fixed" className={fadeIn}>
                <HowToSlider position="absolute" top="50%" transform="translate(0, -50%)">
                    <CancelWrapper
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <CloseIcon />
                    </CancelWrapper>
                    <img alt="edu" width="100%" height="180px" src={image} />
                    <BottomView>
                        <WhatsNewWrapper>
                            <WhatsNewIcon />
                            <Message top="0" color="#CD4213" size="12px" weight="400">
                                {stepText}
                            </Message>
                        </WhatsNewWrapper>
                        <Message
                            top="10px"
                            bottom="15px"
                            lineHeight="30px"
                            weight="700"
                            size="20px"
                            font="18px"
                            color="#0350BB"
                        >
                            {title}
                        </Message>
                        <Message top="0" color="#718596" lineHeight="21px" size="14px" weight="400">
                            {description}
                        </Message>
                        <LinkWrapper>
                            <Message
                                onClick={() => previousLink()}
                                top="0"
                                color="#227EFF"
                                size="16px"
                                weight="500"
                                cursor="pointer"
                            >
                                {leftLink}
                            </Message>
                            {step === 3 ? (
                                <LightButton
                                    onClick={() => nextLink()}
                                    position="absolute"
                                    right="19px"
                                    color="white"
                                    background="#227EFF"
                                    width="166px"
                                    margin="0"
                                    cursor="pointer"
                                >
                                    {rightLink}
                                </LightButton>
                            ) : (
                                <Message
                                    onClick={() => nextLink()}
                                    top="0"
                                    color="#227EFF"
                                    size="16px"
                                    weight="500"
                                    cursor="pointer"
                                >
                                    {rightLink}
                                </Message>
                            )}
                        </LinkWrapper>
                    </BottomView>
                </HowToSlider>
            </BlurBackground>
        </Fragment>
    );
};

export default EducateSearchUser;
