import React, { Fragment, useState, useEffect } from 'react';

import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
    TopHeader,
    RippleButton,
    FlatProgressBar,
    ErrorBadge,
    InfoBadge,
    SelectBox,
    InputWithOnchange,
    Loader,
} from '../../../../../../components';
import { ScreenContainer } from '../../../../../../containers/ScreenContainer';
import { checkKYCV2, saveTempBvnDetails } from '../../../../../../redux/ducks/account/wallet/actions';
import { colors } from '../../../../../../styles';
import DesktopBackgroundLayout from '../../../../../DesktopBackgroundLayout';

import { BankAccountValidationSchema } from './BankAccountValidationSchema';

const Title = styled.h2`
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #071827;
    text-align: left;
    margin-bottom: 6px;
    margin-top: ${({ top }) => top || null};
`;

const Message = styled.h2`
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #718596;
    text-align: left;
    margin-bottom: 6px;
    margin-top: ${({ top }) => top || null};
`;

const PageCount = styled(Message)`
    position: absolute;
    right: 0;
    margin-right: 16px;
`;

const InputBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const FormWrapper = styled(Form)`
    display: flex;
    flex-direction: column;
    padding: 20px 16px 0px 16px;
`;

const FloatingBottomSection = styled.div`
    position: absolute;
    width: 100%;
    bottom: -18px;
    left: 0px;
    padding: 0px 16px 40px 16px;
    background-color: #ffffff;
`;

const AddBankAccount = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const banks = useSelector((state) => state.account.wallet.availableBanks);
    const isLoading = useSelector((state) => state.account.wallet.isLoading);
    const tempBvnDetail = useSelector((state) => state.account.wallet.tempBvnDetail);
    const [modifiedBanksList] = useState(banks.map((bank) => ({ value: bank['code'], label: bank['name'] })) || []);
    const [verifyBankError, setVerifyBankError] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            tempBvnDetail?.verifyBankError && setVerifyBankError(true);
        }, 500);
    }, [tempBvnDetail]);

    const callback = () => {
        history.goBack();
    };

    const onCancelClick = () => {
        setVerifyBankError(false);
        const temp = {
            ...tempBvnDetail,
            verifyBankError: '',
        };
        dispatch(saveTempBvnDetails(temp));
    };

    if (isLoading) return <Loader text="Verifying Information" />;
    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Withdrawal Account'}>
                    <PageCount>2/3</PageCount>
                </TopHeader>
                <ScreenContainer height="100%" padding="0px" top="64px">
                    <FlatProgressBar step={2} amount={3} />
                    {verifyBankError && (
                        <ErrorBadge
                            title="We couldn't Verify your data"
                            message="The bank detail provided doesn't match your BVN"
                            onCancelClick={onCancelClick}
                        />
                    )}
                    <Formik
                        initialValues={{
                            bvn: tempBvnDetail?.bvn || '',
                            firstName: tempBvnDetail?.firstName || '',
                            lastName: tempBvnDetail?.lastName || '',
                            accountNumber: tempBvnDetail?.accountNumber || '',
                            bank: tempBvnDetail?.bank || '',
                            bankCode: tempBvnDetail?.bankCode || '',
                        }}
                        validationSchema={BankAccountValidationSchema}
                        onSubmit={(values) => {
                            setTimeout(async () => {
                                const temp = {
                                    ...tempBvnDetail,
                                    ...values,
                                    verifyBankError: '',
                                };
                                dispatch(saveTempBvnDetails(temp));

                                const result = await dispatch(checkKYCV2(values, callback));
                                result && history.push({ pathname: '/user/wallet_pin', state: values });
                            }, 200);
                        }}
                    >
                        {({ values, errors, touched, setFieldValue, initialValues }) => (
                            <FormWrapper>
                                <InputBlock>
                                    <Title>Withdrawal Account</Title>
                                    <Message>
                                        Kindly provide the bank details that you will like to withdraw to.
                                    </Message>

                                    <SelectBox
                                        placeholder={'Bank Name'}
                                        value={values.bank}
                                        options={modifiedBanksList}
                                        handleChange={(selected) => {
                                            setFieldValue('bank', selected.label);
                                            setFieldValue('bankCode', selected.value);
                                        }}
                                        valid={`${!touched.bank && !errors.bank}`}
                                        error={touched && touched.bank && errors && errors.bank}
                                    />

                                    <InputWithOnchange
                                        label={'Account number'}
                                        type={'text'}
                                        value={values.accountNumber}
                                        placeholder={'Account number'}
                                        name="accountNumber"
                                        valid={`${!touched.accountNumber && !errors.accountNumber}`}
                                        autoComplete={'off'}
                                        errors={touched && touched.accountNumber && errors && errors.accountNumber}
                                        bottom="0px"
                                        height="56px"
                                        top="16px"
                                        maxLength={10}
                                        background="#F2F5FA"
                                        onChange={(e) => setFieldValue('accountNumber', e.target.value)}
                                        onKeyUp={(e) => {}}
                                        onFocus={(e) => {}}
                                        noClearButton={true}
                                        initialValues={initialValues}
                                    />
                                    <InfoBadge
                                        margin="16px 0px"
                                        message="The bank details provided must be linked to your submitted BVN"
                                    />
                                </InputBlock>

                                <FloatingBottomSection>
                                    <RippleButton
                                        type="submit"
                                        top={'2px'}
                                        backgroundColor={colors.deepBlue}
                                        disabled={isLoading}
                                    >
                                        Continue
                                    </RippleButton>
                                </FloatingBottomSection>
                            </FormWrapper>
                        )}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default AddBankAccount;
