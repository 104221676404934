import React, { Fragment } from 'react';

import { useHistory } from 'react-router';

import { SuccessPage } from '../../../../../components';
import { SlidingOverlay } from '../../../../../containers/OverlayContainer';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
const StorefrontAddProductSuccess = ({ open, setOpen, update }) => {
    const history = useHistory();
    function handleClick() {
        setOpen();
        history.push('/my-shop/storefront');
    }

    return (
        open && (
            <Fragment>
                <DesktopBackgroundLayout>
                    <SlidingOverlay>
                        <SuccessPage
                            title={update ? 'Product Updated!' : 'Product Created!'}
                            subtitle={
                                update
                                    ? 'You’ve successfully updated a product'
                                    : 'You’ve successfully created a product'
                            }
                            doneText={'Done'}
                            onDoneClick={handleClick}
                            mposition={'fixed'}
                            position={'absolute'}
                        />
                    </SlidingOverlay>
                </DesktopBackgroundLayout>
            </Fragment>
        )
    );
};

export default StorefrontAddProductSuccess;
