import { combineReducers } from 'redux';

import agencyBanking from './agency-banking';
import kyc from './kyc';
import notifications from './notifications';
import transactions from './transactions';
import wallet from './wallet';

const account = combineReducers({
    wallet,
    kyc,
    transactions,
    notifications,
    agencyBanking,
});

export default account;
