import styled from 'styled-components';

import { SubTitle, SecondaryText } from '../../../../containers/MessageContainer';
import { colors } from '../../../../styles';

import { ReactComponent as DownwardsIcon } from './assets/down.svg';
import { ReactComponent as UpwardsIcon } from './assets/upward.svg';

export const SummaryText = styled.div`
    font-size: 14px;
    font-weight: 700;
    color: #56636d;
`;

export const Relative = styled.div`
    position: relative;
`;

export const Space = styled.div`
    height: ${({ val }) => val || '10px'};
`;

export const SortDropdownBox = styled.div`
    background-color: #e5f5ff;
    border-radius: 16px;
    padding: 4px 8px;
    display: flex;
    cursor: pointer;
    & > span {
        font-size: 12px;
        color: #579fd7;
        padding: 0;
        margin-right: 4px;
    }
`;
export const Container = styled.div`
    width: 93%;
    margin: 0 auto;
    margin-top: ${({ top }) => top || '0'};
`;

export const DropdownBodyInactiveMain = styled.div`
    position: relative;
    margin: 24px 0px;
    padding: 16px;
    border-radius: 13px;
    border: 1px solid ${colors.gray3};
`;

export const DropdownBodyInactive = styled.div`
    position: relative;
    height: 104px;
`;

export const DropdownBodyActive = styled.div`
    position: relative;
    margin: 24px 0 54px 0;
`;

export const DropdownTitle = styled(SubTitle)`
    color: ${colors.themeTextColor5};
    font-size: 16px;
    margin-bottom: 10px;
`;

export const PercentageCount = styled(SecondaryText)`
    color: ${({ color }) => (color ? color : colors.themeTextColor5)};
    margin: ${({ top, bottom }) => `${top}px 0 ${bottom}px 0`};
    font-size: 10px;
`;

export const Amount = styled.div`
    position: absolute;
    bottom: ${({ open }) => (open ? '0' : '12px')};
    right: ${({ open }) => (open ? '0' : '10px')};
    font-size: 16px;
    color: ${colors.themeTextColor7};
    font-weight: 600;
`;

export const CollapseIcon = styled(UpwardsIcon)`
    position: absolute;
    top: 4px;
    right: 0px;
    cursor: pointer;
`;

export const ShowIcon = styled(DownwardsIcon)`
    position: absolute;
    top: 4px;
    right: 16px;
    cursor: pointer;
`;

export const Ruler = styled.div`
    width: 186px;
    height: 8px;
    background-color: ${colors.themeTextColor8};
    border-radius: 16px;
    margin-top: 25px;
`;

export const SemiRulerBox = styled.div`
    width: 186px;
    display: flex;
`;

export const SemiRuler = styled.div`
    width: ${({ width }) => (width ? `${width}%` : 0)};
    height: 8px;
    background-color: ${({ color }) => color};
    border-radius: 16px;
    margin-top: 25px;
    margin-right: 3px;
`;

export const LeftBox = styled.div`
    position: absolute;
    bottom: ${({ open }) => (open ? '-15px' : '12px')};
    right: ${({ open }) => (open ? '0' : '10px')};
    text-align: right;
    & > span {
        color: ${colors.themeTextColor7};
        font-size: 16px;
        font-weight: 600;
}
}
`;

export const DropdownContainer = styled.div`
    margin-top: ${({ top }) => top || null};
`;

export const NoDataContainer = styled.div`
    position: relative;
    margin: 20px 0;
    width:100%;
    height: 242px;
    background-color: ${colors.themeTextColor9};
    display: flex;
    align-items: center;
}
  
`;

export const PieChartBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.themeTextColor9};
    text-align: center;
    font-weight: 500;
    & > span {
        color: ${colors.themeTextColor1};
        font-size: 14px;
    }

    & > div {
        color: ${colors.themeTextColor1};
        padding-top: 24px;
        font-size: 14px;
    }
`;

export const BubbleBox = styled.div`
    padding-bottom: 50px;
`;

export const LegendProductPercentage = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 10px;
    color: #fdfeff;
    font-weight: 300;
    font-size: 10px;
    background-color: ${({ color }) => color};
    margin: 4px 16px 4px 0;
    display: flex;
    align-items: center;
    padding-left: 5px;
`;

export const LegendProductName = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${colors.themeTextColor1};
    text-transform: capitalize;
`;

export const LegendProductCount = styled.div`
    color: ${colors.themeTextColor3};
    margin-top: 5px;
    font-size: 10px;
`;

export const BubbleChartLegendBox = styled.div`
    margin: ${({ open }) => (open ? '20px 10px' : ' 20px 0')};
`;

export const AddFlex = styled.div`
    display: flex;
    margin: 7px 0;
`;

export const LegendFlexItem = styled.div`
    padding-top: 6px;
    flex: 1;
`;
