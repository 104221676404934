import React, { useEffect, useState } from 'react';

import { bool, func } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ListItem, ListHeading } from '../../../containers/ListContainer';
import { Overlay } from '../../../containers/OverlayContainer';
import {
    fetchDeliveryDetails,
    getCustomersAndReferrals,
} from '../../../redux/ducks/applications/my-shop/actions/customers';
import { colors } from '../../../styles';
import { PopUp, PopUpContent, PopUpHeader, ConfirmButton } from '../common';

const Header = styled(PopUpHeader)`
    font-weight: 700;
`;

const StyledListItemContainer = styled.div`
    overflow-y: scroll;
    max-height: 200px;
`;

const ModifiedListItem = styled(ListItem)`
    align-items: center;
    padding-top: 0px;
    background: ${({ selected }) => (selected ? colors.themeColor3 : colors.white)};
`;

const ModifiedConfirmButton = styled(ConfirmButton)`
    background-color: ${colors.popup.cancelButton};
    color: ${colors.deepBlue};
`;

export const DeliveryLocationPopup = ({ open, getLocation }) => {
    const dispatch = useDispatch();
    const listOfAvailableStates = useSelector((state) => state.applications.merchbuy.availableStates);
    const [selected, setSelected] = useState({});

    useEffect(() => {
        dispatch(getCustomersAndReferrals());
        dispatch(fetchDeliveryDetails());
    }, [dispatch]);

    const onOkayClick = () => {
        getLocation(selected);
        localStorage.setItem('deliveryLocation', JSON.stringify(selected));
    };

    return (
        <PopUp open={open}>
            <Overlay nonSliding={true}></Overlay>
            <PopUpContent height={'350px'}>
                <Header align={'left'}>Delivery Location</Header>
                <StyledListItemContainer>
                    {listOfAvailableStates.map((entry, index) => (
                        <ModifiedListItem
                            key={index}
                            height={'48px'}
                            pressedUpList
                            selected={entry.name === selected.state}
                            onClick={() => setSelected({ state: entry.name })}
                        >
                            <ListHeading>{entry.name}</ListHeading>
                        </ModifiedListItem>
                    ))}
                </StyledListItemContainer>
                <ModifiedConfirmButton
                    type="button"
                    disabled={!selected || Object.keys(selected).length === 0}
                    onClick={onOkayClick}
                >
                    Okay
                </ModifiedConfirmButton>
            </PopUpContent>
        </PopUp>
    );
};

DeliveryLocationPopup.propTypes = {
    open: bool,
    getLocation: func,
};
