import * as Yup from 'yup';

import { containsLettersRegExp } from '../../../../utils/regexp/containsLettersRegExp';
import { emailRegExp } from '../../../../utils/regexp/emailRegExp';
import { phoneRegExp } from '../../../../utils/regexp/phoneRegExp';

export const ValidationSchema = Yup.object().shape({
    whatsappNumber: Yup.string()
        .matches(containsLettersRegExp, 'Cannot contain letters')
        .when('fullPay', {
            is: false,
            then: Yup.string().matches(phoneRegExp, 'Phone Number is not valid').required('Required'),
            otherwise: Yup.string().when(['customerName', 'customerPhoneNumber'], {
                is: (customerName, customerPhoneNumber) => customerName && !customerPhoneNumber,
                then: Yup.string().required('Customer phone is required'),
            }),
        })
        .test('case 1', 'Phone number is not valid!', (value) => {
            if (value && value.trim().startsWith('0') && value.trim().length !== 11) {
                return false;
            } else return true;
        })
        .test('case 2', 'Phone number is not valid!', (value) => {
            if (
                value &&
                !value.trim().startsWith('234') &&
                !value.trim().startsWith('0') &&
                value.trim().length !== 10
            ) {
                return false;
            } else return true;
        })
        .test('case 3', 'Phone number is not valid!', (value) => {
            if (value && value.trim().startsWith('234') && value.trim().length !== 13) {
                return false;
            } else return true;
        }),
    name: Yup.string().required('Store name is a required field'),
    businessEmail: Yup.string()
        .required('Business email is a required field')
        .matches(emailRegExp, 'Email is not valid'),
});
