import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import { user, auth, timer, account, applications, offline, help } from './ducks';

export default function rootReducer(history) {
    return combineReducers({
        router: connectRouter(history),
        auth,
        user,
        timer,
        account,
        applications,
        offline,
        help,
    });
}
