import React from 'react';

import {
    RemittanceDashboard,
    SingleRemittanceHistory,
    MultipleRemittanceHistory,
    RemittancePaymentDetails,
    RemittanceSingleOrder,
    RemittanceOrderDetails,
    RemittingMultipleOrder,
} from '../../views/actions/remittance';

export const remittanceRoutes = [
    {
        path: '/actions/remittance/dashboard',
        exact: true,
        main: () => <RemittanceDashboard />,
        public: false,
    },
    {
        path: '/actions/remittance/remittance-order-details',
        exact: true,
        main: () => <RemittanceOrderDetails />,
        public: false,
    },
    {
        path: '/actions/remittance/remittance-order',
        exact: true,
        main: () => <SingleRemittanceHistory />,
        public: false,
    },
    {
        path: '/actions/remittance/remmitance-history',
        exact: true,
        main: () => <MultipleRemittanceHistory />,
        public: false,
    },
    {
        path: '/actions/remittance/remitting-multiple-orders',
        exact: true,
        main: () => <RemittingMultipleOrder />,
        public: false,
    },
    {
        path: '/actions/remittance/remittance-details/:id?',
        exact: true,
        main: () => <RemittancePaymentDetails />,
        public: false,
    },
    {
        path: '/actions/remittance/remittance-single-order',
        exact: true,
        main: () => <RemittanceSingleOrder />,
        public: false,
    },
];
