export const LOADING_MERCHANTS_REFERRALS_DATA = 'actions/loading_merchants_referrals_data';
export const LOADING_MERCHANTS_REFERRALS_COMPLETED = 'actions/loading_merchants_referrals_completed';
export const LOADING_MERCHANTS_VERIFICATION_COMPLETED = 'actions/loading_merchants_verification_completed';
export const LOADING_MERCHANTS_VERIFICATION_DATA = 'actions/loading_merchants_verification_data';
export const LOADING_AGENT_REFERRALS_MERCHANTS_DATA = 'actions/loading_agent_referrals_merchant_data';
export const LOADING_AGENT_REFERRALS_MERCHANTS_COMPLETED = 'actions/loading_agent_referrals_merchant_completed';
export const SAVE_AGENTS_MERCHANTS = 'actions/save_agents_merchants';
export const SAVE_MERCHANTS_COMMISSIONS = 'actions/save_merchants_commissions';
export const MERCHANT_LAST_SEEN = 'actions/merchant_last_seen';
export const SAVE_MERCHANT_REFERRALS = 'actions/save_merchant_referrals';
export const SAVE_REFERRED_MERCHANT_COUNT = 'actions/save_referred_merchant_count';
export const VERIFY_IDENTITY_DETAILS = '/actions/verify_identity_details';
