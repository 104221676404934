import { toast } from 'react-toastify';

import { axios, currentAPI } from '../../../../../../config';
import * as constants from '../../constants';

const baseUrl = `${currentAPI}/api/eazymoni/utilities`;

export const getUtilityProviders = (utility) => async (dispatch) => {
    dispatch({ type: constants.LOADING_ELECTRICITY_PROVIDERS });

    try {
        const url = `${baseUrl}/getUtilitiesProviders`;
        const utilityProvidersResponse = await axios.get(url);

        if (utilityProvidersResponse.status === 200) {
            let data = utilityProvidersResponse.data.responseData.filter((provider) => provider.name.includes(utility));
            dispatch({ type: constants.SET_PROVIDERS, payload: data });
            return data;
        }
    } catch (error) {
        dispatch({ type: constants.PAYMENT_ERROR, payload: error.message });
    }
};

export const getUtilitiesProvidersItems = (utilityProviderId) => async (dispatch) => {
    dispatch({ type: constants.PAYMENT_LOADING });
    try {
        const url = `${baseUrl}/getUtilitiesProvidersItems?utilityProviderId=${utilityProviderId}`;
        const utilitiesItemsResponse = await axios.get(url);

        if (!utilitiesItemsResponse.data.requestSuccessful) {
            dispatch({ type: constants.PAYMENT_ERROR, payload: utilitiesItemsResponse.data.message });
            toast.error(utilitiesItemsResponse.data.message);
            return !utilitiesItemsResponse.data;
        }
        if (utilitiesItemsResponse.status === 200) {
            dispatch({ type: constants.SET_PROVIDERS_ITEMS, payload: utilitiesItemsResponse.data.responseData });
            return utilitiesItemsResponse.data.responseData;
        }
    } catch (error) {
        dispatch({ type: constants.PAYMENT_ERROR, payload: error.message });
        if (error.response && error.response.status === 400) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.message);
        }
    }
};

export const utilityPayment = (amount, billerId, paymentItemCode, requesterId) => async (dispatch) => {
    dispatch({ type: constants.PAYMENT_LOADING });
    try {
        const url = `${baseUrl}/makePaymentForUtility`;
        const utilityPaymentResponse = await axios.post(url, { amount, billerId, paymentItemCode, requesterId });

        dispatch({ type: constants.PAYMENT_SUCCESS });
        if (!utilityPaymentResponse.data.requestSuccessful) {
            dispatch({ type: constants.PAYMENT_ERROR, payload: utilityPaymentResponse.data.message });
        }

        if (utilityPaymentResponse.status === 200) {
            return utilityPaymentResponse;
        }
    } catch (error) {
        dispatch({ type: constants.PAYMENT_ERROR, payload: error.message });
        if (error.response && error.response.status === 400) {
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.message);
        }
    }
};

export const validateUtilityCustomer = (billerId, paymentItemCode, requesterId, setErrors) => async (dispatch) => {
    dispatch({ type: constants.PAYMENT_LOADING });

    try {
        const url = `${baseUrl}/validateUtilityCustomer`;
        const validateCustomerResponse = await axios.post(url, { billerId, paymentItemCode, requesterId });

        dispatch({ type: constants.PAYMENT_SUCCESS });
        const { requestSuccess, responseData } = validateCustomerResponse.data || {};
        if (!(requestSuccess || (responseData && responseData.fullName))) {
            dispatch({ type: constants.PAYMENT_ERROR, payload: validateCustomerResponse.data.message });
            toast.error('Meter number was not found');
            return false;
        }
        return validateCustomerResponse.data.responseData;
    } catch (error) {
        dispatch({ type: constants.PAYMENT_ERROR, payload: error.message });
        if (error.response && error.response.status === 400) {
            setErrors({ name: 'Value is not valid' });
            const { data } = (error && error.response) || {};
            data.message && toast.error(error.response.data.message);
        }

        return false;
    }
};
