import React, { Fragment } from 'react';

import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { TopHeader, SelectBox, RippleButton, Loader } from '../../../../../components';
import { ScreenContainer } from '../../../../../containers/ScreenContainer';
import { countriesNames } from '../../../../../data/countries';
import { localAreas } from '../../../../../data/countries/nigeria/localAreas';
import { states } from '../../../../../data/countries/nigeria/states';
import { sendKYCRegion } from '../../../../../redux/ducks/account/kyc/actions';
import { emptyValues } from '../../../../../utils/inputs/conditions';
import { selectAreasByState } from '../../../../../utils/inputs/selectAreasByState';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';

import { KYCRegionValidationSchema } from './KYCRegionValidationSchema';

const FlexContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const KYCRegionSelection = () => {
    const INCOMPLETE = 'INCOMPLETE';
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.account.kyc.isLoading);
    const country = useSelector((state) => state.user.country);
    const regionData = useSelector((state) => state.user.regionData);
    const agentState = useSelector((state) => (state.user.agentState ? state.user.agentState : 'PENDING'));

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Region Selection'} />
                <ScreenContainer height="100%">
                    <Formik
                        initialValues={{
                            country: regionData.country ? regionData.country : country ? country : '',
                            state: regionData.state || '',
                            lga: regionData.lga || '',
                        }}
                        enableReinitialize
                        validationSchema={KYCRegionValidationSchema}
                        onSubmit={(values) => {
                            setTimeout(() => {
                                dispatch(sendKYCRegion(values));
                            }, 200);
                        }}
                    >
                        {({ errors, touched, values, handleChange, setFieldValue }) => (
                            <FlexContainer>
                                <Container>
                                    <SelectBox
                                        name={'country'}
                                        placeholder={'country'}
                                        value={values.country}
                                        options={countriesNames}
                                        disabled={agentState !== INCOMPLETE}
                                        handleChange={handleChange}
                                        valid={`${!touched.country && !errors.country}`}
                                        error={touched && touched.country && errors && errors.country}
                                    />
                                    <SelectBox
                                        name={'state'}
                                        placeholder={'State'}
                                        value={values.state}
                                        options={states}
                                        disabled={agentState !== INCOMPLETE}
                                        handleChange={handleChange}
                                        valid={`${!touched.state && !errors.state}`}
                                        error={touched && touched.state && errors && errors.state}
                                    />
                                    <SelectBox
                                        name={'lga'}
                                        placeholder={'Local Government Area'}
                                        handleChange={handleChange}
                                        value={values.lga}
                                        disabled={agentState !== INCOMPLETE}
                                        options={values.state ? selectAreasByState(values.state, localAreas) : []}
                                        valid={`${!touched.lga && !errors.lga}`}
                                        error={touched && touched.lga && errors && errors.lga}
                                    />
                                </Container>

                                {agentState === INCOMPLETE && (
                                    <RippleButton type="submit" top={'24px'} disabled={!emptyValues(values)}>
                                        Save
                                    </RippleButton>
                                )}
                            </FlexContainer>
                        )}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default KYCRegionSelection;
