import {
    SENDING_CODE,
    SENDING_CODE_SUCCESS,
    SENDING_CODE_ERROR,
    LOADING,
    STOP_LOADING,
    INITIAL_PATH,
} from './constants';

const initialState = {
    isLoading: false,
    errorMsg: '',
    initialPath: null,
};

export default function sendCodeReducer(state = initialState, action) {
    switch (action.type) {
        case SENDING_CODE: {
            return {
                ...state,
                isLoading: true,
                errorMsg: '',
            };
        }

        case SENDING_CODE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case LOADING: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case SENDING_CODE_ERROR: {
            return {
                ...state,
                isLoading: false,
                errorMsg: action.payload,
            };
        }
        case INITIAL_PATH: {
            return {
                ...state,
                initialPath: action.payload,
            };
        }
        default: {
            return state;
        }
    }
}
