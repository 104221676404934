import React, { Fragment } from 'react';

import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';

import { TopHeader, RippleButton, PageLogo, PhoneNumberInput } from '../../../../components';
import { Message, Title } from '../../../../containers/MessageContainer';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { colors } from '../../../../styles';
import History from '../../../../utils/History';
import Avatar from '../../../auth/phone/assets/avatar.svg';
import { PhoneNumberSignUpValidationSchema } from '../../../auth/phone/PhoneNumberSignUpValidationSchema';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';

const CustomerPhoneNumberSignUp = () => {
    const handlePhoneNumber = (phoneNumber) => {
        History.push(`/actions/store_front/store_link/orders/${phoneNumber}`);
    };
    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader noTitle noBorderBottom />
                <ScreenContainer>
                    <PageLogo top={'14px'} Icon={Avatar} background={colors.lightPink} />
                    <Title textAlign={'left'} color={colors.themeTextColor10} top={'10px'}>
                        Welcome to Sabi
                    </Title>
                    <Message bottom={'24px'} top={'20px'}>
                        {' '}
                        Please provide your phone number below to view your orders
                    </Message>
                    <Formik
                        initialValues={{
                            phoneNumber: '',
                            country: 'NG',
                        }}
                        validationSchema={PhoneNumberSignUpValidationSchema}
                        onSubmit={(values, { resetForm }) => {
                            setTimeout(() => {
                                resetForm();
                            }, 1000);
                            handlePhoneNumber(values.phoneNumber);
                        }}
                    >
                        {({ touched, values, errors, initialValues, setFieldValue }) => (
                            <Form>
                                <PhoneNumberInput
                                    label="Phone number"
                                    placeholder="Phone number"
                                    name="phoneNumber"
                                    autoComplete={'tel'}
                                />
                                <RippleButton
                                    type="submit"
                                    disabled={values.phoneNumber.length < 10 || errors.phoneNumber}
                                    top={'8px'}
                                >
                                    Continue
                                </RippleButton>
                                <Message size={'12px'} align={'center'}>
                                    By continuing, you agree to our{' '}
                                    <strong style={{ color: colors.blue }}>
                                        <Link to="/termsAndConditions">Terms and Conditions</Link>
                                    </strong>{' '}
                                    and{' '}
                                    <strong style={{ color: colors.blue }}>
                                        <Link to="/privacyPolicy">Privacy Policy</Link>
                                    </strong>
                                </Message>
                            </Form>
                        )}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default CustomerPhoneNumberSignUp;
