import NigeriaFlag from '../../../assets/flags/nigeria.svg';

export const NigeriaInfo = {
    name: 'Nigeria',
    code: '+234',
    flag: NigeriaFlag,
    currency: {
        sign: '₦',
        code: 'NGN',
    },
};
