import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import SabiLogo from '../assets/Sabi.svg';

const StyledContainer = styled.div`
    display: block;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    padding-right: 15px;
    padding-left: 15px;
    @media (min-width: 425px) {
        max-width: 100%;
    }
    @media (min-width: 576px) {
        max-width: 540px;
    }
    @media (min-width: 768px) {
        max-width: 720px;
    }
    @media (min-width: 992px) {
        max-width: 960px;
    }
    @media (min-width: 1200px) {
        max-width: 1140px;
    }
    @media (min-width: 1366px) {
        max-width: 1248px;
    }
`;

const Row = styled.div`
    position: relative;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
`;

const StyledHeader = styled.header`
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    background: #ffffff;
    align-items: center;
    width: 100%;
    padding: 30px 15px 20px;

    @media (max-width: 768px) {
        position: fixed;
        top: 0;
        padding: 20px 25px;
        z-index: 100;
        -webkit-box-shadow: 0px 0px 9px 3px rgb(172 172 172 / 5%);
        box-shadow: 0px 0px 9px 3px rgb(172 172 172 / 5%);
    }
`;

const LogoWrap = styled.div`
    display: flex;
    justify-content: flex-start;
    white-space: nowrap;

    @media (max-width: 576px) {
        height: 30px;
    }
`;

const StyleLogo = styled.img`
    height: 100%;
    z-index: 1;
    width: 90px;
`;

const StyledBtnWrap = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
`;

const StyledBtn = styled(Link)`
    position: relative;
    font-size: 14px;
    font-weight: bold;
    color: #227eff;
    background: #ffffff;
    line-height: 1;
    text-align: center;
    padding: 16px 62px;
    border: 1px solid #227eff;
    border-radius: 4px;
    cursor: pointer;
    outline: 0 none;
    z-index: 1;
    transition: 0.3s ease-out all;

    &:hover {
        color: #fff;
        background: #227eff;
    }
    @media (max-width: 768px) {
        padding: 8px 19px;
    }
`;

export const Header = () => {
    return (
        <StyledContainer>
            <Row>
                <StyledHeader>
                    <LogoWrap>
                        <StyleLogo src={SabiLogo} alt="Sabi Logo" />
                    </LogoWrap>
                    <StyledBtnWrap>
                        <StyledBtn to="/phone-signup">Log In</StyledBtn>
                    </StyledBtnWrap>
                </StyledHeader>
            </Row>
        </StyledContainer>
    );
};
