import React, { Fragment, useState, useEffect } from 'react';

import moment from 'moment';
import { Calendar, utils } from 'react-modern-calendar-datepicker';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import styled from 'styled-components';

import { OptionsPopupDialog } from '../../../../../components';
import { SearchInput } from '../../../../../components/forms/input/search-input/SearchInput';
import { Overlay } from '../../../../../containers/OverlayContainer';
import { FilterAndSortSection, SortFilterCell } from '../../../../../containers/ScreenContainer';
import { colors } from '../../../../../styles';
import { formatCurrency } from '../../../../../utils/currency/parseBalance';
import { toTitleCase } from '../../../../../utils/toTitleCase';
import {
    CalendarCover,
    CalendarHeaderCover,
    CalendarParagraph,
    CalendarStyleOverride,
} from '../../../../actions/my-shop/sales/add-sale';
import { CustomWeekdays } from '../../../../actions/my-shop/sales/add-sale/components/locale';
import { ReactComponent as IllustrationOne } from '../../assets/illustration1.svg';
import { TabContent, Text } from '../../storeFront';
import { TotalContainer } from '../products';

import { ReactComponent as Arrow } from './assets/arrow.svg';
import { ReactComponent as DateIcon } from './assets/date.svg';
import { ReactComponent as MostRecent } from './assets/mostrecent.svg';
import { ReactComponent as Oldest } from './assets/oldest.svg';
import { FilterOrders } from './filterOrders';
import { SortOrders } from './sortOrders';

const OrdersList = styled.div`
    margin-top: 16px;
`;

const OrderBox = styled.div`
    margin-top: 12px;
    padding: 0 16px;
`;

const InnerDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ bottom }) => bottom || null};
`;

const OrderDiv = styled.div`
    margin-bottom: 11px;
    background: ${colors.white};
    box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 18px 16px 14px 16px;
`;

const InputWrapper = styled.div`
    width: 100%;
    margin-bottom: 12px;
    padding: 0 16px;
`;

const VerticalLine = styled.div`
    height: 41px;
    border: 1px solid #edf2f7;
    position: absolute;
    left: 47%;
`;

const StorefrontOrders = () => {
    const storeFrontOrders = useSelector((state) => state.applications.storeFront.storeFrontOrdersList);
    const [openFilterOptions, setOpenFilterOptions] = useState(false);
    const [filterType, setFilterType] = useState('Today');
    const [sortOptionsOpen, setSortOptionsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [ordersList, setOrdersList] = useState(storeFrontOrders);
    const [sortType, setSortType] = useState('Most Recent');
    const [openDateRangePopup, setOpenDateRangePopup] = useState(false);
    const [openDatePopup, setOpenDatePopup] = useState(false);
    const [singleDate, setSingleDate] = useState(null);
    const [dateRange, setDateRange] = useState({
        from: null,
        to: null,
    });

    useEffect(() => {
        storeFrontOrders &&
            setOrdersList(
                storeFrontOrders.filter(
                    (data) =>
                        data?.fullName.toLowerCase().includes(searchValue.toLowerCase()) ||
                        data?.orderReference.toLowerCase().includes(searchValue.toLowerCase()),
                ),
            );
    }, [searchValue, storeFrontOrders]);

    useEffect(() => {
        SortOrders(sortType, storeFrontOrders, setOrdersList);
    }, [sortType, storeFrontOrders]);

    useEffect(() => {
        FilterOrders(filterType, storeFrontOrders, dateRange, singleDate, setOrdersList);
    }, [dateRange, filterType, singleDate, storeFrontOrders]);

    return (
        <Fragment>
            <TotalContainer>
                {storeFrontOrders?.length === 0 ? (
                    <TabContent top="70px">
                        <IllustrationOne />
                        <Text size="14px" weight="500" color={colors.themeTxtColor10} bottom="4px">
                            No Orders yet
                        </Text>
                        <Text color="#718596">You don’t have any orders at the moment...</Text>
                    </TabContent>
                ) : (
                    <OrdersList>
                        <InputWrapper>
                            <SearchInput
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                placeholder="Search customer or order"
                            />
                        </InputWrapper>
                        <FilterAndSortSection
                            top={'0'}
                            justifyContent={'space-around'}
                            background={'white'}
                            padding={'11px 0'}
                            borderTop={'1px solid #EDF2F7'}
                            borderBottom={'1px solid #EDF2F7'}
                        >
                            <SortFilterCell
                                onClick={() => setOpenFilterOptions(!openFilterOptions)}
                                style={{ color: colors.themeTxtColor10 }}
                                weight={'500'}
                                svgRight={'0'}
                                svgLeft={'57px'}
                            >
                                {filterType}
                                <Arrow />
                            </SortFilterCell>
                            <VerticalLine />
                            <SortFilterCell
                                onClick={() => setSortOptionsOpen(!sortOptionsOpen)}
                                style={{ color: colors.themeTxtColor10 }}
                                weight={'500'}
                                svgRight={'0'}
                                svgLeft={'11px'}
                            >
                                {sortType}
                                <Arrow />
                            </SortFilterCell>
                        </FilterAndSortSection>
                        <OrderBox>
                            {ordersList.map((order, index) => (
                                <Link key={index} to={`/actions/storefront/order/${order.id}`}>
                                    <OrderDiv>
                                        <InnerDiv bottom={'5px'}>
                                            <Text size={'12px'} weight={'400'} color={colors.themeTxtColor10}>
                                                {toTitleCase(order?.fullName)}
                                            </Text>
                                            <Text size={'14px'} weight={'500'} color={colors.themeTxtColor10}>
                                                {formatCurrency(order?.totalCost)}
                                            </Text>
                                        </InnerDiv>
                                        <InnerDiv>
                                            <Text size={'12px'} weight={'400'} color={'#5C6F7F'}>
                                                {moment(new Date(order?.createdAt)).format('MMMM D, YYYY')}
                                            </Text>
                                            <Text
                                                size={'12px'}
                                                weight={'400'}
                                                color={'#5C6F7F'}
                                            >{`x${order.productsOrdered.length} Items`}</Text>
                                        </InnerDiv>
                                    </OrderDiv>
                                </Link>
                            ))}
                        </OrderBox>
                    </OrdersList>
                )}
            </TotalContainer>
            {sortOptionsOpen && (
                <OptionsPopupDialog
                    open={sortOptionsOpen}
                    title={'Sort by'}
                    cancel={() => {
                        setSortOptionsOpen(!sortOptionsOpen);
                    }}
                    items={[
                        {
                            title: 'Most Recent',
                            Icon: MostRecent,
                            click: () => {
                                setSortOptionsOpen(!sortOptionsOpen);
                                sortType !== 'Most Recent' && setSortType('Most Recent');
                            },
                        },
                        {
                            title: 'Oldest',
                            Icon: Oldest,
                            click: () => {
                                setSortOptionsOpen(!sortOptionsOpen);
                                sortType !== 'Oldest' && setSortType('Oldest');
                            },
                        },
                    ]}
                />
            )}

            {openFilterOptions && (
                <OptionsPopupDialog
                    open={openFilterOptions}
                    title={'FIlter by'}
                    cancel={() => {
                        setFilterType('Today');
                        setOpenFilterOptions(!openFilterOptions);
                    }}
                    items={[
                        {
                            Icon: DateIcon,
                            title: 'Lifetime',
                            click: () => {
                                setOpenFilterOptions(!openFilterOptions);
                                filterType !== 'Lifetime' && setFilterType('Lifetime');
                            },
                        },
                        {
                            Icon: DateIcon,
                            title: 'Today',
                            click: () => {
                                setOpenFilterOptions(!openFilterOptions);
                                filterType !== 'Today' && setFilterType('Today');
                            },
                        },
                        {
                            Icon: DateIcon,
                            title: 'This Week',
                            click: () => {
                                setOpenFilterOptions(!openFilterOptions);
                                filterType !== 'This Week' && setFilterType('This Week');
                            },
                        },
                        {
                            Icon: DateIcon,
                            title: 'This Month',
                            click: () => {
                                setOpenFilterOptions(!openFilterOptions);
                                filterType !== 'This Month' && setFilterType('This Month');
                            },
                        },
                        {
                            Icon: DateIcon,
                            title: 'This Year',
                            click: () => {
                                setOpenFilterOptions(!openFilterOptions);
                                filterType !== 'This Year' && setFilterType('This Year');
                            },
                        },
                        {
                            Icon: DateIcon,
                            title: 'Specific Day',
                            click: () => {
                                setOpenFilterOptions(!openFilterOptions);
                                setOpenDatePopup(!openDatePopup);
                            },
                        },
                        {
                            Icon: DateIcon,
                            title: 'Date Range',
                            click: () => {
                                setOpenFilterOptions(!openFilterOptions);
                                setOpenDateRangePopup(!openDateRangePopup);
                            },
                        },
                    ]}
                />
            )}
            {openDateRangePopup && (
                <Overlay bgc={'rgba(0, 0, 0, 0.4)'} zIndex={'99999'}>
                    <CalendarCover>
                        <CalendarHeaderCover>
                            <CalendarParagraph>Select Date</CalendarParagraph>
                        </CalendarHeaderCover>
                        <CalendarStyleOverride>
                            <Calendar
                                value={dateRange}
                                onChange={setDateRange}
                                colorPrimary="#227EFF"
                                locale={CustomWeekdays}
                                maximumDate={utils().getToday()}
                                calendarClassName="custom-calendar"
                                calendarSelectedDayClassName="custom-today-day"
                                renderFooter={() => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '16px',
                                        }}
                                    >
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setDateRange(null);
                                                setOpenDateRangePopup((prev) => !prev);
                                            }}
                                            style={{
                                                border: '1px solid #2E4457',
                                                background: '#fff',
                                                borderRadius: '4px',
                                                color: '#2E4457',
                                                width: '100px',
                                                padding: '13px 15px',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setDateRange(dateRange);
                                                setOpenDateRangePopup((prev) => !prev);
                                                setFilterType('Date Range');
                                            }}
                                            style={{
                                                border: 'none',
                                                background: '#227EFF',
                                                borderRadius: '4px',
                                                color: '#FFFFFF',
                                                padding: '13px 40px',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                )}
                            />
                        </CalendarStyleOverride>
                    </CalendarCover>
                </Overlay>
            )}
            {openDatePopup && (
                <Overlay bgc={'rgba(0, 0, 0, 0.4)'} zIndex={'99999'}>
                    <CalendarCover>
                        <CalendarHeaderCover>
                            <CalendarParagraph>Select Date</CalendarParagraph>
                        </CalendarHeaderCover>
                        <CalendarStyleOverride>
                            <Calendar
                                value={singleDate}
                                onChange={setSingleDate}
                                colorPrimary="#227EFF"
                                locale={CustomWeekdays}
                                maximumDate={utils().getToday()}
                                calendarClassName="custom-calendar"
                                calendarSelectedDayClassName="custom-today-day"
                                renderFooter={() => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '16px',
                                        }}
                                    >
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setSingleDate(null);
                                                setOpenDatePopup((prev) => !prev);
                                            }}
                                            style={{
                                                border: '1px solid #2E4457',
                                                background: '#fff',
                                                borderRadius: '4px',
                                                color: '#2E4457',
                                                width: '100px',
                                                padding: '13px 15px',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setSingleDate(singleDate);
                                                setOpenDatePopup((prev) => !prev);
                                                setFilterType('Specific Day');
                                            }}
                                            style={{
                                                border: 'none',
                                                background: '#227EFF',
                                                borderRadius: '4px',
                                                color: '#FFFFFF',
                                                padding: '13px 40px',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                )}
                            />
                        </CalendarStyleOverride>
                    </CalendarCover>
                </Overlay>
            )}
        </Fragment>
    );
};

export default StorefrontOrders;
