import KenyaFlag from '../../../assets/flags/kenya.svg';

export const KenyaInfo = {
    name: 'Kenya',
    code: '+254',
    flag: KenyaFlag,
    currency: {
        sign: 'KSh',
        code: 'KES',
    },
};
