import React from 'react';

import styled from 'styled-components';

import { NewTextInputWithLabel } from '../../../../components/forms/input/new-text-input';
import { NewPhoneNumberInput } from '../../../../components/forms/input/phone-number-input';
import { colors } from '../../../../styles';

const FormSeperator = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: ${colors.subGrey};
    margin-bottom: 16px;
`;

const Counter = styled.div`
    font-size: 10px;
    line-height: 16px;
    text-align: right;
    color: ${colors.subGrey};
    margin: 4px 16px 16px 0;
`;

const DisabledBlock = styled.div`
    opacity: 0.8;
`;

const FirstStep = ({ name, wordCount, limit, content, setFormattedContent }) => {
    return (
        <div>
            <NewTextInputWithLabel label={'Store Name'} placeholder={'Store Name'} name="name" />
            <NewTextInputWithLabel
                value={content}
                label={'Store Description'}
                placeholder={'Store Description'}
                onChange={(event) => setFormattedContent(event.target.value)}
                name="description"
                minHeight="56px"
            />
            <Counter>
                {wordCount}/{limit}
            </Counter>
            <DisabledBlock>
                <NewTextInputWithLabel
                    value={name.split(' ').join('-')}
                    label={'Store Link'}
                    placeholder={'Store Link'}
                    disabled
                    name="link"
                />
            </DisabledBlock>
            <FormSeperator>OTHER DETAILS</FormSeperator>
            <NewTextInputWithLabel label={'Business Email'} placeholder={'Business Email'} name="businessEmail" />
            <NewPhoneNumberInput
                label={'Whatsapp Phone Number'}
                name="whatsappNumber"
                placeholder={'Whatsapp Phone Number'}
                bottomMargin="0"
            />
            <NewTextInputWithLabel label={'Twitter URL'} placeholder={'Twitter URL'} name="twitterUrl" />
            <NewTextInputWithLabel label={'Facebook URL'} placeholder={'Facebook URL'} name="facebookUrl" />
        </div>
    );
};

export default FirstStep;
