import React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { colors } from '../../../../styles';
import { ReactComponent as FacebookIcon } from '../assets/facebook.svg';
import { ReactComponent as SabiLogoIcon } from '../assets/logo.svg';
import { ReactComponent as ThankYouIcon } from '../assets/thank_you.svg';
import { ReactComponent as TwitterIcon } from '../assets/twitter.svg';
import { ReactComponent as WhatsappIcon } from '../assets/whatsapp.svg';

const ThankYouWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    bottom: -10px;
`;

const BodyWrapper = styled.div`
    min-height: 234px;
    width: 100%;
    background: ${colors.white};
    padding: 0 17px 0 15px;
`;

const FooterWrapper = styled.div`
    height: 52px;
    width: 100%;
    background: ${colors.gray4};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Wrapper = styled.div`
    width: 100%;
    position: ${({ noPosition }) => (noPosition ? 'relative' : 'absolute')};
    left: 0;
    bottom: 0;
    min-height: 286px;
`;

const Title = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.boldDark};
    padding-top: ${({ top }) => top};
`;

const Description = styled.div`
    font-size: 14px;
    line-height: 21px;
    color: ${colors.lightDark};
    padding-top: 8px;
`;

const SocialWrapper = styled.div`
    display: flex;
    padding: 8px 0 35px 0;
`;

const Space = styled.div`
    width: 16px;
`;

const FooterText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #2e4457;
    margin-right: 8px;
`;

const FooterLink = styled.a``;

const StoreFrontFooter = ({ noPosition }) => {
    const store = useSelector((state) => state.applications.storeFront.storeByLink);
    return (
        <Wrapper noPosition>
            <ThankYouWrapper>
                <ThankYouIcon />
            </ThankYouWrapper>
            <BodyWrapper>
                <Title top="35px">About Business</Title>
                <Description>{store?.description}</Description>
                <Title top="32px">Social Media</Title>
                <SocialWrapper>
                    <FooterLink href={`https://wa.me/+234${store?.whatsappNumber}`}>
                        <WhatsappIcon />
                    </FooterLink>
                    <Space />
                    <FooterLink href={store?.twitterUrl}>
                        <TwitterIcon />
                    </FooterLink>
                    <Space />
                    <FooterLink href={store?.facebookUrl}>
                        <FacebookIcon />
                    </FooterLink>
                </SocialWrapper>
            </BodyWrapper>
            <FooterWrapper>
                <FooterText>Powered by</FooterText>
                <SabiLogoIcon />
            </FooterWrapper>
        </Wrapper>
    );
};

export default StoreFrontFooter;
