import React, { Fragment, useEffect, useState } from 'react';

import { func, bool } from 'prop-types';
import styled from 'styled-components';

import { TopHeader, RippleButton, UserAvatar, InfoBadge } from '../../../../../../components';
import { Close } from '../../../../../../containers/HeaderContainer';
import { ScreenContainer, FlexCenteredBlock, FlexContainer } from '../../../../../../containers/ScreenContainer';
import { colors } from '../../../../../../styles';
import { toAbbr, toTitleCase, upperCaseToTitleCase } from '../../../../../../utils/toTitleCase';
import UserImage from '../../../assets/default_user.svg';
import ShopperImage from '../../../assets/shopper.svg';
import { Space } from '../../../styles';

const Padding = styled.div`
    padding: 0 1em;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.boldDark};
    margin-top: 14px;
`;

const Subtitle = styled.div`
    font-size: 14px;
    color: ${colors.lightDark};
    line-height: 21px;
    text-align: center;
    margin-top: 8px;
`;

const CardDetailsWrapper = styled.div`
    position: relative;
    display: flex;
    min-height: 180px;
    min-width: 343px;
    background-color: ${colors.gray4};
    border-radius: 8px;
    height: 200px;
    overflow: hidden;
    margin-top: 20px;
    &::after {
        content: url(${ShopperImage});
        position: absolute;
        bottom: -4px;
        right: 0px;
    }
`;

const UserAcronymn = styled.div`
    background-color: ${colors.newPurple};
    border-radius: 50%;
    color: ${colors.purple};
    width: 32px;
    font-size: 10px;
    height: 32px;
    padding: 12px 5px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    margin: 24px 0 0 16px;
    font-weight: 500;
`;
const CardShopName = styled.div`
    position: absolute;
    color: ${colors.bgBlue};
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-right: 10px;
    left: 56px;
    top: 24px;
    float: left;
`;

const CardUserName = styled.div`
    position: absolute;
    color: ${colors.themeTextColor11};
    font-weight: bold;
    font-size: 10px;
    line-height: 24px;
    font-weight: 400;
    margin: ${({ margin }) => margin || '45px 0 0 56px'};
`;

const CardAddressHolder = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 16px;
    top: 78px;
`;

const CardAddress = styled.span`
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: ${colors.lightDark};
`;

const CardPhoneNumber = styled.p`
    position: absolute;
    color: ${colors.boldDark};
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    left: 16px;
    bottom: 0px;
`;

const ButtonWrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 375px;
    width: inherit;
    padding: 15px 15px 40px;
`;

export const MerchantFound = ({ open, cancel, sabiUser, setSabiUserEdit, setSabiNewEdit, setSabiUserUse }) => {
    const deliveryLocation =
        localStorage.getItem('deliveryLocation') && JSON.parse(localStorage.getItem('deliveryLocation'));
    const { state: deliveryState } = deliveryLocation || {};
    const checkRequiredFields = (address, lga, phoneNumber, state, city, shopName) => {
        return !address || !lga || !phoneNumber || !state || !city || !shopName || !checkDeliveryLocation(state);
    };

    const checkDeliveryLocation = (state) => {
        return String(deliveryState).toLowerCase() === String(state).toLowerCase();
    };

    const matchShoppingLocation = deliveryState === sabiUser?.state;
    const [requireEdit, setRequireEdit] = useState(false);

    useEffect(() => {
        if (sabiUser) {
            const edit = checkRequiredFields(
                sabiUser?.shopAddress,
                sabiUser?.lga,
                sabiUser?.phoneNumber,
                sabiUser?.shopName,
                sabiUser?.state,
                sabiUser?.city,
            );
            setRequireEdit(edit);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sabiUser]);

    const isAddressComplete = () => {
        const { shopAddress, state, lga, city } = sabiUser;
        return !!(shopAddress && state && lga && city);
    };

    const getName = () => {
        return `${`${sabiUser?.firstName.toUpperCase() || ''} ${sabiUser.lastName.toUpperCase() || ''}`}`;
    };

    return (
        open && (
            <Fragment>
                <TopHeader title={'Merchant Found'} withSpacesHeader>
                    <Close left={'16px'} onClick={cancel} />
                </TopHeader>

                <ScreenContainer top="0" padding="0">
                    <Space height="90px" />
                    <Padding>
                        <FlexCenteredBlock>
                            <UserAvatar avatar={UserImage} width="72px" height="72px" borderRadius={'50%'} />
                            <Title>We found a Sabi Merchant</Title>
                            <Subtitle>The phone number you provided belongs to a sabi Merchant</Subtitle>
                        </FlexCenteredBlock>
                        <CardDetailsWrapper>
                            {sabiUser?.shopName && <UserAcronymn>{`${toAbbr(sabiUser?.shopName) || ''}`}</UserAcronymn>}
                            <CardShopName>{`${toTitleCase(sabiUser?.shopName || '')}`}</CardShopName>
                            {sabiUser?.shopName ? (
                                <CardUserName>{getName(sabiUser).toUpperCase()}</CardUserName>
                            ) : (
                                <CardUserName margin="45px 0 0 15px">{getName(sabiUser).toUpperCase()}</CardUserName>
                            )}
                            <CardAddressHolder>
                                <CardAddress>{`${sabiUser?.shopNumber || ''} ${
                                    sabiUser?.shopAddress || ''
                                }`}</CardAddress>
                                {sabiUser?.lga && sabiUser.city && (
                                    <CardAddress>{`${upperCaseToTitleCase(sabiUser?.city)}, ${
                                        sabiUser?.lga
                                    } L.G.A.`}</CardAddress>
                                )}
                                {sabiUser?.state && <CardAddress>{`${sabiUser.state} State`}</CardAddress>}
                            </CardAddressHolder>
                            <CardPhoneNumber>{`+234${sabiUser?.businessPhoneNumber?.substring(1)}`}</CardPhoneNumber>
                        </CardDetailsWrapper>
                        {!sabiUser.shopName && isAddressComplete() && matchShoppingLocation && (
                            <InfoBadge
                                margin="16px 0 0 0"
                                width={'343px'}
                                weight={'700'}
                                titleSize={'12px'}
                                titleColor={colors.themeTxtColor10}
                                title={'Shop Name is incomplete,'}
                                message="fill in the complete address to continue."
                                color={colors.themeTxtColor10}
                                backgroundColor={colors.lightYellowBg}
                            />
                        )}
                        {!isAddressComplete() && sabiUser?.shopName && matchShoppingLocation && (
                            <InfoBadge
                                margin="16px 0 0 0"
                                width={'343px'}
                                weight={'700'}
                                titleSize={'12px'}
                                titleColor={colors.themeTxtColor10}
                                title={'Address is incomplete,'}
                                message="fill in the complete address to continue."
                                color={colors.themeTxtColor10}
                                backgroundColor={colors.lightYellowBg}
                            />
                        )}

                        {!isAddressComplete() && !sabiUser?.shopName && matchShoppingLocation && (
                            <InfoBadge
                                margin="16px 0 0 0"
                                width={'343px'}
                                weight={'700'}
                                titleSize={'12px'}
                                titleColor={colors.themeTxtColor10}
                                title={'Shop Name & Address is incomplete,'}
                                message="fill in the complete address to continue."
                                color={colors.themeTxtColor10}
                                backgroundColor={colors.lightYellowBg}
                            />
                        )}
                        {requireEdit && !matchShoppingLocation && (
                            <InfoBadge
                                margin={'16px 0px'}
                                title={!matchShoppingLocation && 'Address error'}
                                message={
                                    !matchShoppingLocation && "The user's state does not match your shopping location."
                                }
                            />
                        )}
                        {isAddressComplete() && sabiUser?.shopName ? (
                            <ButtonWrapper>
                                {requireEdit && matchShoppingLocation && (
                                    <FlexContainer>
                                        <RippleButton
                                            backgroundColor={colors.popup.cancelButton}
                                            color={colors.themeSubColor1}
                                            border="1px solid #718596"
                                            margin="32px 4px 0 0"
                                            onClick={() => {
                                                setSabiNewEdit && setSabiNewEdit(sabiUser);
                                            }}
                                        >
                                            Edit Address
                                        </RippleButton>
                                        <RippleButton
                                            margin="32px 0 0 4px"
                                            onClick={() => {
                                                setSabiUserUse && setSabiUserUse(sabiUser);
                                            }}
                                        >
                                            Use Address
                                        </RippleButton>
                                    </FlexContainer>
                                )}
                            </ButtonWrapper>
                        ) : (
                            <ButtonWrapper>
                                {requireEdit && matchShoppingLocation && (
                                    <RippleButton
                                        onClick={() => {
                                            setSabiNewEdit && setSabiNewEdit(sabiUser);
                                        }}
                                    >
                                        Edit Merchant Information
                                    </RippleButton>
                                )}
                            </ButtonWrapper>
                        )}
                    </Padding>
                </ScreenContainer>
            </Fragment>
        )
    );
};

MerchantFound.propTypes = {
    open: bool,
    cancel: func,
};
