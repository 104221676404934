import React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { colors } from '../../../../styles';
import History from '../../../../utils/History';
import { toTitleCase } from '../../../../utils/toTitleCase';
import Bg from '../assets/bg.png';
import { ReactComponent as CartIcon } from '../assets/blue-cart.svg';
import { ReactComponent as StoreLogo } from '../assets/store_logo.svg';

const Header = styled.div`
    width: 100%;
    height: 68px;
    background-image: url('${Bg}');
    background-color: #4569f3;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
`;

const HeaderTextWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const HeaderText = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.white};
    margin-left: 9.2px;
`;

const MenuWrapper = styled.div`
    width: 32px;
    height: 32px;
    background: #2d49dd;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const CartWrapper = styled.div`
    width: 13px;
    height: 12px;
    padding: 0px 4px;
    background: #ee6f44;
    border-radius: 26px;
    position: relative;
    top: 10px;
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CartItem = styled.div`
    font-size: 8px;
    line-height: 16px;
    color: ${colors.white};
`;

const StoreFrontHeader = ({ showMenu, noNumber, cartItemNumber }) => {
    const store = useSelector((state) => state.applications.storeFront.storeByLink);
    return (
        <Header>
            <HeaderTextWrapper>
                <StoreLogo />
                <HeaderText>{toTitleCase(store?.name)}</HeaderText>
            </HeaderTextWrapper>
            {showMenu && (
                <div onClick={() => History.push('/actions/store_front/djnjd/cart')}>
                    {!noNumber && (
                        <CartWrapper>
                            <CartItem>{cartItemNumber || 0}</CartItem>
                        </CartWrapper>
                    )}
                    <MenuWrapper>
                        <CartIcon />
                    </MenuWrapper>
                </div>
            )}
        </Header>
    );
};

export default StoreFrontHeader;
