import { colors } from '../../../../styles';

export const getStatusText = (status) => {
    switch (status) {
        case 'PENDING':
        case 'ACCEPTED':
            return 'Pending';
        case 'DECLINED':
            return 'Declined';
        case 'CANCELLED':
            return 'Cancelled';
        case 'MARK_AS_DELIVERED':
            return 'Delivered';
        default:
            return null;
    }
};

export const getCircleColor = (status) => {
    switch (status) {
        case 'PENDING':
        case 'ACCEPTED':
            return '#FFC042';
        case 'CANCELLED':
        case 'DECLINED':
            return `${colors.textRed}`;
        case 'MARK_AS_DELIVERED':
            return '#00B361';
        default:
            return null;
    }
};
