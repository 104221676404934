import React from 'react';

import styled from 'styled-components';

import { RippleButton } from '../../../../../components';
import { DebtReminderModal } from '../../../../../components/popup';
import { colors } from '../../../../../styles';

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding: 0 16px;
`;

const ButtonSpace = styled.div`
    width: 20px;
`;

const ReminderConfirmPopup = ({ open, cancel, customerName, onButtonClick, setActiveBtn, isEdit, reminderStatus }) => {
    return (
        <DebtReminderModal
            reminderStatus={reminderStatus}
            isEdit={isEdit}
            customerName={customerName}
            height="185px"
            open={open}
            cancel={cancel}
        >
            <ButtonWrapper>
                <RippleButton
                    top="17px"
                    color={colors.boldDark}
                    backgroundColor={colors.white}
                    radius="60px"
                    border="1px solid #E2E9F0"
                    height="38px"
                    onClick={() => {
                        onButtonClick();
                        setActiveBtn('One-Time Reminder');
                    }}
                >
                    One-Time Reminder
                </RippleButton>
                <ButtonSpace />
                <RippleButton
                    top="17px"
                    color={colors.boldDark}
                    backgroundColor={colors.white}
                    radius="60px"
                    border="1px solid #E2E9F0"
                    height="38px"
                    onClick={() => {
                        onButtonClick();
                        setActiveBtn('Frequent Reminder');
                    }}
                >
                    Frequent Reminder
                </RippleButton>
            </ButtonWrapper>
        </DebtReminderModal>
    );
};

export default ReminderConfirmPopup;
