import { combineReducers } from 'redux';

import agent from './agent';
import merchant from './merchant';

const signup = combineReducers({
    merchant,
    agent,
});

export default signup;
