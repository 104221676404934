import React from 'react';

import { any } from 'prop-types';

import { AddFlexBox } from '../../styles';
import { ProductCategoryItem } from '../ProductCategoryItem';

export const ListProductCategories = ({ data }) => {
    return (
        <AddFlexBox justifyContent={'center'}>
            {data &&
                data
                    .filter((category) => category.length > 0)
                    .map((item, index) => {
                        return <ProductCategoryItem key={index} data={item} />;
                    })}
        </AddFlexBox>
    );
};

ListProductCategories.propTypes = {
    data: any,
};
