import NigerFlag from '../../../assets/flags/niger.svg';

export const NigerInfo = {
    name: 'Niger',
    code: '+213',
    flag: NigerFlag,
    currency: {
        sign: 'CFA',
        code: 'XOF',
    },
};
