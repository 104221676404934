import React, { useState, useEffect } from 'react';

import { bool, func, object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { toast } from 'react-toastify';
import aa from 'search-insights';
import styled from 'styled-components';
import useRouter from 'use-react-router';

import { Overlay, ActionBlock } from '../../../containers/OverlayContainer';
import { colors } from '../../../styles';
import { formatPrice } from '../../../utils/currency/formatPriceWithComma';
import { COMPLETE_ADD_TO_CART } from '../../../utils/mix-panel/constants';
import { mixPanel } from '../../../utils/mix-panel/mixPanel';
import { toTitleCasePerWord } from '../../../utils/toTitleCase';
import { is_url } from '../../../utils/urls/isUrl';
import { PopUp, PopUpContent, CancelButton, ConfirmButton } from '../common';

import { ReactComponent as AddIcon } from './assets/add.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import FallbackProductImage from './assets/productImage.svg';
import { ReactComponent as SubstractIcon } from './assets/substract.svg';

const ProductBlock = styled.div`
    margin: 0px 0 34px;
    display: flex;
    width: 100%;
`;

const ProductImage = styled.img`
    width: 44px;
    height: 44px;
    margin-top: 0;
    border-radius: 4px;
    object-fit: cover;
    background-color: ${colors.themeColor3};
`;

const ProductInfo = styled.div`
    flex: 1;
    font-size: 14px;
    padding: 0px 15% 0px 10px;
`;
const ModifiedSubstractIcon = styled(SubstractIcon)`
    margin: 0 8px 0 0;
`;
const ModifiedAddIcon = styled(AddIcon)`
    margin: 0px 8px;
`;

const Title = styled.div`
    font-size: 12px;
    font-weight: 500;
    margin: 0 0 8px;
    text-align: left;
    color: ${colors.boldDark};
`;

const ProductDesc = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    text-align: left;
`;

const UpdateCalculatorBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    & > svg {
        cursor: pointer;
    }
`;

const QuantityDisplay = styled.div`
    border-radius: 8px;
    width: 64px;
    height: 32px;
    background-color: ${colors.themeColor3};
    text-align: center;
`;

const CurrentQuantity = styled.input`
    position: relative;
    top: calc(50% - 8px);
    font-weight: 100;
    font-size: 14px;
    font-weight: 500;
    color: ${({ color }) => color || colors.themeTextColor1};
    margin-top: ${({ top }) => top || null};
    margin-left: ${({ left }) => left || null};
    width: 70%;
    background-color: inherit;
    border: none;
    text-align: center;
    &:focus {
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
`;

const MoqParagraph = styled.div`
    font-size: 12px;
    color: ${colors.themeSubColor1};
    font-weight: 700;
    text-align: left;
    &:first-child {
        margin-bottom: 2px;
    }
`;

const OuterQuantityHold = styled.div`
    display: flex;
    flex-direction: column;
`;

const QuantityHold = styled.div`
    line-height: 18px;
    display: flex;
`;
const PriceHolder = styled.p`
    margin-top: 8px;
    font-size: 12px;
    color: ${colors.boldDark};
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 0;
`;

const ProductDescText = styled.h3`
    font-size: 12px;
    color: ${colors.boldDark};
    font-weight: 400;
    line-height: 18px;
`;

const StyledCloseIcon = styled(CloseIcon)`
    margin-left: auto;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 8px;
`;

export const MerchbuyAddtoCartPopup = ({ open, cancel, data, productDetails, confirm, animate }) => {
    const [selectedMoq, setSelectedMoq] = useState(0);
    const [IDX, setIDEX] = useState(0);
    const [isDisabled, setIsDisDisabled] = useState(false);

    const dispatch = useDispatch();
    const { location } = useRouter();
    const { pathname } = location;
    const pattern = `(.*)?${'/actions/merchbuy/product/:id'}`;
    const match = matchPath(pathname, { path: pattern }) || {};

    const userId = useSelector((state) => state.user.userId);
    const productIndex = process.env.REACT_APP_ALGOLIA_SEARCH_INDEX_V2;

    useEffect(() => {
        if (data?.bulkPrices?.length > 0) {
            setSelectedMoq(data?.bulkPrices[0]?.moq || data?.bulkPrices[0]?.quantity);
        } else {
            setSelectedMoq(data?.moq);
        }
    }, [data]);

    const setPosition = (val) => {
        for (let i = 0; i < data?.bulkPrices?.length; i++) {
            if (!data.bulkPrices[i + 1]) {
                return setIDEX(i);
            } else if (val >= data?.bulkPrices[i]?.moq && val < data?.bulkPrices[i + 1]?.moq) {
                return setIDEX(i);
            }
        }
    };

    const handleAddToCart = () => {
        const locationQuery = new URLSearchParams(location.search);
        // we need to fire different events so we know where the user is coming from
        // if the user started his journey from the landing page, then the event name should be different
        // if it is from the search page, then the event name should be different too
        const queryId = locationQuery.get('queryId');

        const eventType = queryId ? 'convertedObjectIDsAfterSearch' : 'convertedObjectIDs';
        const eventName = queryId ? 'Product Added to Cart after Search' : 'Product Added to Cart';

        const eventProperties = {
            objectIDs: match?.params?.id ? [match.params.id] : [data?.productId] || [data?.id],
            userToken: userId,
            index: productIndex,
            eventName,
        };

        if (queryId) {
            eventProperties.queryID = queryId;
        }

        aa(eventType, {
            ...eventProperties,
        });

        const { bulkPrices } = data || {};
        const selectedBulkPrices = bulkPrices && bulkPrices[IDX];
        const { price } = selectedBulkPrices || {};
        const formatedPrice = formatPrice(price || 0);

        const newData = {
            ...data,
            productId: data.productId || data.id,
            quantity: selectedMoq,
        };

        setIsDisDisabled(true);

        mixPanel.track(COMPLETE_ADD_TO_CART, {
            'Product id': data.productId || data?.id,
            'Product name': data?.productName || data?.name,
            'Product quantity': selectedMoq,
            'Product unit price': formatedPrice,
        });

        dispatch(confirm(newData, false));
        cancel();
        setIsDisDisabled(false);
    };

    const disabled = (data, productDetails) => {
        if (
            selectedMoq > (productDetails?.quantity || data?.quantityInStock) ||
            isDisabled ||
            !selectedMoq ||
            (data?.bulkPrices.length > 0 &&
                selectedMoq < (data?.bulkPrices[0]?.moq || data?.bulkPrices[0]?.quantity)) ||
            (data?.moq && selectedMoq < data?.moq)
        ) {
            return true;
        }
    };

    return (
        <PopUp open={open} padding={'0px'}>
            <Overlay onClick={cancel} bgc={'rgba(0,0,0,.4)'} />
            <PopUpContent width={'375px'} radius={'8px'} padding={'24px 16px'} animate={animate}>
                <ProductBlock>
                    <ProductImage
                        src={
                            is_url(data?.images ? data.images.baseImageUrl : '')
                                ? data?.images?.baseImageUrl
                                : data?.image || FallbackProductImage
                        }
                        alt="product image"
                    />
                    <ProductInfo>
                        <ProductDesc>
                            <ProductDescText>
                                {' '}
                                {`${toTitleCasePerWord(data?.brandName)} ${toTitleCasePerWord(
                                    data?.productName,
                                )} - ${toTitleCasePerWord(data?.productVariantName)}`}{' '}
                                <br />{' '}
                            </ProductDescText>
                            <PriceHolder>
                                {(productDetails?.bulkPrices?.length > 0 ||
                                    data?.bulkPrices?.length > 0 ||
                                    data?.maxPrice ||
                                    data?.price?.retail) &&
                                    formatPrice(
                                        productDetails?.bulkPrices?.[IDX]?.price ||
                                            data?.bulkPrices?.[IDX]?.price ||
                                            data?.maxPrice ||
                                            data?.price?.retail,
                                    ) +
                                        ('/' + (data?.unitValue || 'Unit'))}
                            </PriceHolder>
                        </ProductDesc>
                    </ProductInfo>
                    <StyledCloseIcon onClick={cancel} />
                </ProductBlock>
                <hr
                    style={{
                        margin: '0 auto 16px',
                        width: '100%',
                        backgroundColor: colors.gray4,
                        border: `solid 1px ${colors.gray4}`,
                    }}
                />

                <OuterQuantityHold>
                    <Title> Select Quantity</Title>

                    <QuantityHold>
                        <UpdateCalculatorBlock>
                            <ModifiedSubstractIcon
                                onClick={() => {
                                    setSelectedMoq(
                                        (data?.bulkPrices.length > 0 &&
                                            parseInt(selectedMoq) - 1 <=
                                                (data?.bulkPrices[0]?.moq || data?.bulkPrices[0]?.quantity)) ||
                                            (data?.moq && parseInt(selectedMoq) - 1 <= data?.moq)
                                            ? (data?.bulkPrices.length > 0 &&
                                                  (data?.bulkPrices[0]?.moq || data?.bulkPrices[0]?.quantity)) ||
                                                  (data?.moq && data.moq)
                                            : parseInt(selectedMoq) - 1,
                                    );
                                    setPosition(parseInt(selectedMoq) - 1);
                                }}
                            />
                            <QuantityDisplay>
                                <CurrentQuantity
                                    value={selectedMoq}
                                    type={'number'}
                                    onChange={(e) => {
                                        setSelectedMoq(
                                            e.target.value < 0
                                                ? parseInt(
                                                      data?.moq ||
                                                          data?.bulkPrices[0]?.moq ||
                                                          data?.bulkPrices[0]?.quantity,
                                                  )
                                                : parseInt(e.target.value),
                                        );
                                        setPosition(
                                            e.target.value < 0
                                                ? parseInt(
                                                      data?.moq ||
                                                          data?.bulkPrices[0]?.moq ||
                                                          data?.bulkPrices[0]?.quantity,
                                                  )
                                                : parseInt(e.target.value),
                                        );
                                        if (
                                            e.target.value > data?.quantity?.retail ||
                                            e.target.value > (productDetails?.quantity || data?.quantityInStock)
                                        ) {
                                            toast.error(
                                                `You cannot add more than ${
                                                    productDetails?.quantity || data?.quantityInStock
                                                } to your cart at this time`,
                                            );
                                        }
                                        if (
                                            e.target.value < data?.moq ||
                                            e.target.value < data?.bulkPrices[0]?.moq ||
                                            e.target.value < data?.bulkPrices[0]?.quantity
                                        ) {
                                            toast.error(
                                                `You cannot add less than ${
                                                    data?.bulkPrices[0]?.moq ||
                                                    data?.moq ||
                                                    data?.bulkPrices[0]?.quantity
                                                } to your cart at this time`,
                                            );
                                        }
                                    }}
                                />
                            </QuantityDisplay>
                            <ModifiedAddIcon
                                onClick={() => {
                                    setSelectedMoq(parseInt(selectedMoq) + 1);
                                    setPosition(parseInt(selectedMoq) + 1);
                                }}
                            />
                        </UpdateCalculatorBlock>
                        <Column>
                            {/* <MoqParagraph>
                                {data?.bulkPrices && data?.bulkPrices[0].moq + ' ' + (data?.unitValue || 'Units')}{' '}
                                minimum order
                            </MoqParagraph> */}
                            <MoqParagraph>
                                {productDetails?.quantity || data?.quantityInStock || data?.quantity} in stock
                            </MoqParagraph>
                        </Column>
                    </QuantityHold>
                </OuterQuantityHold>

                <ActionBlock direction={'row'} top={'40px'}>
                    <CancelButton type={'button'} onClick={cancel} border color="#227EFF" weight="500" top={'0px'}>
                        Cancel
                    </CancelButton>
                    <ConfirmButton
                        disabled={disabled(data, productDetails)}
                        onClick={handleAddToCart}
                        bgColor="#227EFF"
                        color="#fff"
                        weight="500"
                        top={'0px'}
                    >
                        Add to Cart
                    </ConfirmButton>
                </ActionBlock>
            </PopUpContent>
        </PopUp>
    );
};

MerchbuyAddtoCartPopup.propTypes = {
    open: bool,
    cancel: func,
    data: object,
    shopDetails: object,
    confirm: func,
};
