const initYearlyData = [
    {
        id: 0,
        totalAmount: 0,
        profit: 0,
        month: 'Jan',
    },
    { id: 1, totalAmount: 0, profit: 0, month: 'Feb' },
    {
        id: 2,
        totalAmount: 0,
        profit: 0,
        month: 'Mar',
    },
    {
        id: 3,
        totalAmount: 0,
        profit: 0,
        month: 'Apr',
    },
    {
        id: 4,
        totalAmount: 0,
        profit: 0,
        month: 'May',
    },
    {
        id: 5,
        totalAmount: 0,
        profit: 0,
        month: 'Jun',
    },
    {
        id: 6,
        totalAmount: 0,
        profit: 0,
        month: 'Jul',
    },
    {
        id: 7,
        totalAmount: 0,
        profit: 0,
        month: 'Aug',
    },
    {
        id: 8,
        totalAmount: 0,
        profit: 0,
        month: 'Sep',
    },
    {
        id: 9,
        totalAmount: 0,
        profit: 0,
        month: 'Oct',
    },
    {
        id: 10,
        totalAmount: 0,
        profit: 0,
        month: 'Nov',
    },
    {
        id: 11,
        totalAmount: 0,
        profit: 0,
        month: 'Dec',
    },
];

const initMonthlyData = [
    {
        totalAmount: 0,
        profit: 0,
        recordType: 'Week 1',
    },
    {
        totalAmount: 0,
        profit: 0,
        recordType: 'Week 2',
    },
    {
        totalAmount: 0,
        profit: 0,
        recordType: 'Week 3',
    },
    {
        totalAmount: 0,
        profit: 0,
        recordType: 'Week 4',
    },
];

const initWeeklyData = [
    {
        totalAmount: 0,
        profit: 0,
        recordType: 'Monday',
    },
    {
        totalAmount: 0,
        profit: 0,
        recordType: 'Tuesday',
    },
    {
        totalAmount: 0,
        profit: 0,
        recordType: 'Wednesday',
    },
    {
        totalAmount: 0,
        profit: 0,
        recordType: 'Thursday',
    },
    {
        totalAmount: 0,
        profit: 0,
        recordType: 'Friday',
    },
    {
        totalAmount: 0,
        profit: 0,
        recordType: 'Saturday',
    },
    {
        totalAmount: 0,
        profit: 0,
        recordType: 'Sunday',
    },
];

const initDailyData = [
    {
        totalAmount: 0,
        profit: 0,
        recordType: '12am-6am',
    },
    {
        totalAmount: 0,
        profit: 0,
        recordType: '6am-12pm',
    },
    {
        totalAmount: 0,
        profit: 0,
        recordType: '12pm-6pm',
    },
    {
        totalAmount: 0,
        profit: 0,
        recordType: '6pm-12am',
    },
];

export const initialData = {
    initYearlyData,
    initMonthlyData,
    initWeeklyData,
    initDailyData,
};
