import React from 'react';

import { useSelector } from 'react-redux';

import { RippleLink } from '../../../../../components';
import { BuyIcon, NoOfItemsOnCart, Circle } from '../../styles';

export const Cart = ({ color, right, circleRight }) => {
    const cartItems = useSelector((state) => state.applications.merchbuy.shopCart);
    return (
        <RippleLink to={'/actions/merchbuy/cart'}>
            <Circle circleRight={circleRight}>
                <BuyIcon />
            </Circle>
            <NoOfItemsOnCart right={right} color={color}>
                {cartItems ? cartItems.length : 0}
            </NoOfItemsOnCart>
        </RippleLink>
    );
};
