import React, { Fragment, useState } from 'react';

import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Loader, RippleButton, TopHeader, PasswordGroup, SuccessPage } from '../../../../components';
import { Message, PageTitle, PageSubTitle } from '../../../../containers/MessageContainer';
import { ScreenContainer, FloatingBottomSection, FormWrapper } from '../../../../containers/ScreenContainer';
import { resetPin } from '../../../../redux/ducks/account/transactions/actions';
import { getInputValues } from '../../../../utils/inputs/getInputValues';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';

import { PinCreationValidationSchema } from './PinCreationValidationSchema';

const TransactionChangePin = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isLoading = useSelector((state) => state.account.transactions.isLoading);
    const [value, setValue] = useState('');
    const [confirmValue, setConfirmValue] = useState(undefined);
    const [showSuccessPage, setShowSucessPage] = useState(false);
    const [pinChangeStatus, setPinChangeStatus] = useState(false);

    const handlePinCreationSubmit = async (values) => {
        const res = await dispatch(resetPin(values));
        setShowSucessPage(true);
        setPinChangeStatus(res?.status);
    };

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Change PIN'} noBorderBottom />
                <ScreenContainer paddingBottom={'41px'}>
                    <PageTitle align={'left'} size={'16px'} top={'40px'} bottom={'8px'}>
                        Change your Transaction PIN
                    </PageTitle>
                    <PageSubTitle align={'left'} margin={'0'}>
                        Create a 4-digit PIN to secure your account
                    </PageSubTitle>

                    <Formik
                        initialValues={{
                            transactionPin: '',
                            confirmTransactionPin: '',
                        }}
                        validationSchema={PinCreationValidationSchema}
                        onSubmit={handlePinCreationSubmit}
                    >
                        {({ valid, touched, errors, setFieldValue, values }) => (
                            <Form>
                                <FormWrapper>
                                    <Message top={'32px'} bottom={'0px'} color={'#718596'} size={'12px'}>
                                        Enter New Transaction PIN to continue
                                    </Message>
                                    <PasswordGroup
                                        count={4}
                                        startIndex={1}
                                        type={'password'}
                                        valid={valid}
                                        errors={errors}
                                        name={'transactionPin'}
                                        touched={touched}
                                        align={'left'}
                                        disabled={isLoading}
                                        enteredValue={value}
                                        handleChange={(event) => {
                                            setValue(event.target.value);
                                            setFieldValue('transactionPin', getInputValues('transactionPin'));
                                        }}
                                    />
                                    <Message top={'20px'} bottom={'0px'} color={'#718596'} size={'12px'}>
                                        Re-enter New Transaction PIN to continue
                                    </Message>
                                    <PasswordGroup
                                        count={4}
                                        startIndex={5}
                                        type={'password'}
                                        valid={valid}
                                        errors={errors}
                                        name={'confirmTransactionPin'}
                                        touched={touched}
                                        align={'left'}
                                        enteredValue={confirmValue}
                                        handleChange={(event) => {
                                            setConfirmValue(event.target.value);
                                            setFieldValue(
                                                'confirmTransactionPin',
                                                getInputValues('confirmTransactionPin'),
                                            );
                                        }}
                                    />

                                    <FloatingBottomSection>
                                        <RippleButton
                                            type="submit"
                                            disabled={
                                                values.transactionPin.length < 4 ||
                                                values.confirmTransactionPin.length < 4 ||
                                                values.transactionPin !== values.confirmTransactionPin ||
                                                isLoading
                                            }
                                        >
                                            Save
                                        </RippleButton>
                                    </FloatingBottomSection>
                                </FormWrapper>
                            </Form>
                        )}
                    </Formik>

                    {showSuccessPage && (
                        <SuccessPage
                            title={`${pinChangeStatus ? 'Update Successful' : 'Update Failed!'}`}
                            subtitle={
                                pinChangeStatus
                                    ? 'Your transaction PIN has been successfully updated.'
                                    : 'Your transaction PIN was not updated. Try again'
                            }
                            showDetailButton={false}
                            successfull={pinChangeStatus}
                            doneText={pinChangeStatus ? 'Go to Home' : 'Try again'}
                            onDoneClick={() => {
                                if (pinChangeStatus) {
                                    history.push('/user/wallet_index');
                                } else {
                                    setShowSucessPage(false);
                                    setPinChangeStatus(false);
                                }
                            }}
                        ></SuccessPage>
                    )}
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default TransactionChangePin;
