import React, { Fragment, useState } from 'react';

import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import BadgeLogo from '../../../../assets/passport.svg';
import {
    //Loader,
    TopHeader,
    PageProgress,
    PageLogo,
    RippleButton,
    SelectBox,
    FileInput2,
} from '../../../../components';
import { NORMAL, SUCCESS } from '../../../../components/forms/input/file2';
import { InputBlock } from '../../../../containers/InputContainer';
import { Message } from '../../../../containers/MessageContainer';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { sendUserDocumentsOnUpgrade } from '../../../../redux/ducks/auth/signup/agent/actions';
import {
    saveUserDocument,
    saveUserDocumentData,
    uploadUserDocument,
    savePassport,
    savePassportData,
    uploadPassport,
} from '../../../../redux/ducks/user/actions';
import { compressImage } from '../../../../utils/files/compressImage';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';

import { AgentIdentityValidationSchema } from './AgentIdentityValidationSchema';

const UMAgentIdentityCheck = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.auth.signup.agent.isLoading);
    const userDocumentData = useSelector((state) => state.user.documentData);
    const passportData = useSelector((state) => state.user.passportData);

    //Passport
    const [passportRetryCount, setPassportRetryCount] = useState(0);
    const passportUploadProgress = useSelector((state) =>
        state.user.passportState && state.user.passportState.progress ? state.user.passportState.progress : 0,
    );
    const passportState = useSelector((state) =>
        state.user.passportState && state.user.passportState.state ? state.user.passportState.state : NORMAL,
    );

    //Document
    const [userDocumentRetryCount, setUserDocumentRetryCount] = useState(0);
    const userDocumentUploadProgress = useSelector((state) =>
        state.user.documentState && state.user.documentState.progress ? state.user.documentState.progress : 0,
    );
    const userDocumentState = useSelector((state) =>
        state.user.documentState && state.user.documentState.state ? state.user.documentState.state : NORMAL,
    );

    return (
        //isLoading ?
        //<Loader /> :
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Means of Identification'} />
                <ScreenContainer>
                    <PageLogo top={'64px'} Icon={BadgeLogo} />
                    <PageProgress step={2} amount={4} />
                    <Formik
                        initialValues={{
                            selectedDocument: '',
                            passportPhotograph: 'PassportPhotograph',
                        }}
                        validationSchema={AgentIdentityValidationSchema}
                        onSubmit={(values) => {
                            setTimeout(() => {
                                dispatch(
                                    sendUserDocumentsOnUpgrade(
                                        values.selectedDocument,
                                        '/actions/um_agent_region_selection',
                                    ),
                                );
                            }, 1000);
                        }}
                    >
                        {({ values, handleChange }) => (
                            <Form encType="multipart/form-data">
                                <Message align={'left'} bottom={'24px'}>
                                    Upload a passport photograph.
                                </Message>

                                <FileInput2
                                    accept={'image/*'}
                                    defaultFileName={'Passport photograph'}
                                    fileName={passportData && passportData.name}
                                    progress={passportUploadProgress}
                                    documentState={passportState}
                                    deleteFile={() => {
                                        setTimeout(() => {
                                            dispatch(savePassport({ label: '', url: '' }));
                                            dispatch(savePassportData({ name: '', type: '' }));
                                        }, 300);
                                    }}
                                    cancelFile={() => {
                                        setTimeout(() => {
                                            //intercept the call and cancel
                                            //dispatch(savePassport({ label: '', url: '' }));
                                            //dispatch(savePassportData({ name: '', type: '' }));
                                        }, 300);
                                    }}
                                    handleFile={(event) => {
                                        if (event.target.files[0]) {
                                            const file = event.target.files[0];
                                            compressImage(event.target.files[0]).then((data) => {
                                                dispatch(
                                                    savePassport({
                                                        label: values.passportPhotograph,
                                                        url: URL.createObjectURL(data),
                                                    }),
                                                );
                                                dispatch(savePassportData({ name: file.name, type: file.type }));
                                                dispatch(uploadPassport(values.passportPhotograph));
                                            });
                                        }
                                    }}
                                    retryFile={() => {
                                        setPassportRetryCount(passportRetryCount + 1);
                                        if (passportRetryCount >= 2) {
                                            setPassportRetryCount(0);
                                            dispatch(savePassport({ label: '', url: '' }));
                                            dispatch(savePassportData({ name: '', type: '' }));
                                        } else {
                                            dispatch(uploadPassport(values.passportPhotograph));
                                        }
                                    }}
                                />

                                <Message align={'left'} bottom={'24px'}>
                                    Select a means of identification and upload the document.
                                </Message>

                                <InputBlock>
                                    <SelectBox
                                        name="selectedDocument"
                                        placeholder={'Select a means of identification'}
                                        value={values.selectedDocument}
                                        handleChange={handleChange}
                                        options={[
                                            {
                                                value: 'driversLicense',
                                                label: "Driver's License",
                                            },
                                            {
                                                value: 'personalId',
                                                label: 'Personal ID',
                                            },
                                            {
                                                value: 'workId',
                                                label: 'Work ID',
                                            },
                                        ]}
                                    />

                                    <FileInput2
                                        accept={'image/*'}
                                        fileName={userDocumentData && userDocumentData.name}
                                        progress={userDocumentUploadProgress}
                                        documentState={userDocumentState}
                                        disabled={!values.selectedDocument}
                                        deleteFile={() => {
                                            setTimeout(() => {
                                                dispatch(saveUserDocument({ label: '', url: '' }));
                                                dispatch(saveUserDocumentData({ name: '', type: '' }));
                                            }, 300);
                                        }}
                                        cancelFile={() => {
                                            setTimeout(() => {
                                                //intercept the call and cancel
                                                //dispatch(saveUserDocument({ label: '', url: '' }));
                                                //dispatch(saveUserDocumentData({ name: '', type: '' }));
                                            }, 300);
                                        }}
                                        handleFile={(event) => {
                                            if (event.target.files[0]) {
                                                const file = event.target.files[0];
                                                dispatch(
                                                    saveUserDocument({
                                                        label: values.selectedDocument,
                                                        url: URL.createObjectURL(event.target.files[0]),
                                                    }),
                                                );
                                                dispatch(saveUserDocumentData({ name: file.name, type: file.type }));
                                                dispatch(uploadUserDocument(values.selectedDocument));
                                            }
                                        }}
                                        retryFile={() => {
                                            setUserDocumentRetryCount(userDocumentRetryCount + 1);
                                            if (userDocumentRetryCount >= 2) {
                                                setUserDocumentRetryCount(0);
                                                dispatch(saveUserDocument({ label: '', url: '' }));
                                                dispatch(saveUserDocumentData({ name: '', type: '' }));
                                            } else {
                                                dispatch(uploadUserDocument(values.selectedDocument));
                                            }
                                        }}
                                    />

                                    <RippleButton
                                        type="submit"
                                        disabled={
                                            !values.selectedDocument ||
                                            userDocumentState !== SUCCESS ||
                                            passportState !== SUCCESS ||
                                            loading

                                            //!values.selectedDocument ||
                                            //!documentLoaded ||
                                            //!passportLoaded ||
                                            //loading
                                        }
                                    >
                                        {loading ? 'loading' : 'save & continue'}
                                    </RippleButton>
                                </InputBlock>
                            </Form>
                        )}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default UMAgentIdentityCheck;
