/* eslint-disable array-callback-return */
import React, { Fragment, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import {
    TopHeader,
    RippleButton,
    PageLogo,
    DeliveryLocationPopup,
    ProductDetailsPopup,
    RippleLink,
    Loader,
} from '../../../../../components';
import { FixedButton } from '../../../../../components/button';
import { RemoveSelectedCartItems } from '../../../../../components/popup/remove-selected-cart-items';
import { SubList, SubListValue } from '../../../../../containers/CheckoutContainer';
import {
    List,
    ListItemNoDivider,
    ListHeading,
    ListLeftBlock,
    ListSubHeading,
} from '../../../../../containers/ListContainer';
import { Title } from '../../../../../containers/MessageContainer';
import { FlexCenteredBlock, FlexContainer, ScreenContainer } from '../../../../../containers/ScreenContainer';
import { merchbuyActions } from '../../../../../redux/ducks/applications/merchbuy/actions';
import { fetchDeliveryDetails } from '../../../../../redux/ducks/applications/my-shop/actions/customers';
import { getUserAdditionalInfo } from '../../../../../redux/ducks/user/actions';
import { colors } from '../../../../../styles';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import History from '../../../../../utils/History';
import { parseTextCountRender } from '../../../../../utils/inputs/parseTextCountRender';
import { CART_OPEN, START_CHECKOUT } from '../../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../../utils/mix-panel/mixPanel';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import { ReactComponent as NotificationIcon } from '../../assets/mov.svg';
import FallbackProductImage from '../../assets/productImage.svg';
import { ReactComponent as CartbasketIcon } from '../../assets/shopping-cart-basket.svg';
import SupermarketIcon from '../../assets/supermarket.svg';
import { Space, Container, Text } from '../../styles';
import { getCartItemsSubTotal } from '../../utils/getCartItemsSubTotal';
import { getPriceFromBulkPrices } from '../../utils/getPriceFromBulkPrices';
import { productCategoryName } from '../../utils/getProductCatNameById';
import { ReactComponent as GroupBuyBg } from '../assets/groupBuyIcon.svg';
import { ReactComponent as DeleteIcon } from '../assets/new-delete.svg';
import { ReactComponent as RemovedItem } from '../assets/removedItem.svg';
import { ReactComponent as CheckedBox } from '../assets/selected.svg';
import Countdown from '../countdown';
import { getProductInfoForMixPanel } from '../helper';
import SelectiveCheckoutWalkthrough from '../selective-checkout-walkthrough';

import { SetAvailableQuantity } from './set-available-quantity';

const CheckoutOuterWrapper = styled.div`
    padding: 18px 20px 10px 20px;
`;

const ListItemChild = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    padding-top: 8px;
    opacity: ${({ grayOut }) => grayOut || null};
`;

const ModifiedListItem = styled(ListItemNoDivider)``;

const CartUpdate = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
`;

const ModifiedContainer = styled(Container)`
    width: 100%;
    padding: 1em;
    padding-top: 0;
`;

const TotalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const TotalAmount = styled(ListSubHeading)`
    font-weight: 400;
    color: ${colors.themeTextColor11};
    font-size: 12px;
    line-height: 18px;
`;

const ShopBlock = styled.section`
    margin: 4px 16px
        ${({ index, cartLength }) =>
            cartLength === 3 && index === 2 ? '250px' : index === cartLength - 1 ? '200px' : '4px'}
        16px;
    background: ${({ expired }) => (expired ? colors.gray4 : colors.white)};
    border: ${({ expired }) => (expired ? `1px solid ${colors.gray5}` : 'none')};
    border-radius: 4px;
    padding: 3px 15px 15px 15px;
    box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
`;

const TitleText = styled.p`
    background-color: ${colors.white};
    font-size: 14px;
    text-align: center;
    padding: 15px 0;
    margin-top: 68px;
    margin-bottom: 8px;
    box-shadow: 0px 15px 84px rgb(0 0 0 / 5%);
`;

const GroupBuyWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const GroupBuyBoxWrapper = styled.div`
    width: 78px;
    height: 20px;
    background: linear-gradient(94.68deg, #7e42f5 16.08%, #227eff 262.55%);
    border-radius: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const RemovedItemWrapper = styled.div`
    background: ${colors.lightYellow};
    height: 60px;
    margin: 15px 0 0 0;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
`;

const BottomFixedButton = styled.div`
    position: fixed;
    bottom: 0;
    width: 343px;
    margin: 25px;
`;

const GroupBuyBoxBgWrapper = styled.div`
    background: ${colors.background.lightCircle};
    width: 15px;
    height: 15px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px 1px 2px;
`;

const GroupBuyBoxText = styled.div`
    color: ${colors.white};
    font-size: 10px;
    margin-bottom: 1px;
`;

const GroupBuyEditText = styled.div`
    color: ${({ expired }) => (expired ? colors.red : colors.deepBlue)};
    font-size: 10px;
`;

const CheckoutWrapper = styled.div`
    background: ${colors.white};
    box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.04);
    position: fixed;
    bottom: 0;
    width: 375px;

    @media (max-width: 576px) {
        width: 100%;
    }
`;

const NotificationItemWrapper = styled.div`
    background: ${colors.lightYellow};
    height: ${({ height }) => height || '60px'};
    margin: 3px 0 20px 0;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    animation: breathing 2s ease-out infinite normal;
`;
const NotificationText = styled.div`
    font-size: 12px;
    line-height: 18px;
    margin-left: 10px;
    font-weight: 400;
    color: ${colors.boldDark};
    width: 80%;
`;

const Amount = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: ${({ color }) => color || colors.themeTextColor1};
`;

let currentDate = new Date().toISOString();

const ValidatedContainer = styled.div`
    background-color: ${({ bgColor }) => bgColor || colors.lightYellowBg};
    border-radius: 10px;
    padding: 2px 8px;
    min-width: 120px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const EmptyCheckBox = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid ${colors.gray8};
    margin-right: 12px;
`;

const StyledDelete = styled(DeleteIcon)`
    position: absolute;
    right: 16px;
    cursor: pointer;
`;

const Validate = styled.div``;

const CartItems = () => {
    const dispatch = useDispatch();

    const cartItems = useSelector((state) => state.applications.merchbuy.shopCart || []);
    const walletStatus = useSelector((state) => state.account.wallet.status);
    const isLoading = useSelector((state) => state.applications.merchbuy.isLoading);
    const cartDeletedItemsInfo = useSelector((state) => state.applications.merchbuy.shopCartDeletedInfo);
    const minimumOrderValue = useSelector((state) => state.applications.merchbuy.minimumOrderValue);
    const deliveryLocation = useSelector((state) => state.applications.merchbuy.deliveryLocation);
    const currentLocation =
        localStorage.getItem('deliveryLocation') && JSON.parse(localStorage.getItem('deliveryLocation'));
    const user = useSelector((state) => state.user);
    const shopInfo = useSelector((state) => state.applications.myShop.shops[0]);
    const productCategeries = useSelector((state) => state.applications.merchbuy.merchbuyProductCategeries);
    const [deliveryStateLga] = useState(JSON.parse(localStorage.getItem('deliveryLocation')));
    const [deliveryDetails] = useState(
        deliveryLocation && Object.entries(deliveryLocation).length !== 0
            ? deliveryLocation
            : {
                  receiverName: user && user.firstName + ' ' + user.lastName,
                  shopName: shopInfo && shopInfo.shopName,
                  streetAddress: shopInfo && shopInfo.streetAddress,
                  lga: deliveryStateLga && deliveryStateLga.lga,
                  state: deliveryStateLga && deliveryStateLga.state,
                  msisdn: shopInfo && shopInfo.businessPhoneNumber,
                  instruction: '',
              },
    );
    const [openProductDetailsPopup, setOpenProductDetailsPopup] = useState(false);
    const [subTotal, setSubTotal] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [deliveryFee] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [carts, setCarts] = useState([...cartItems]);
    const [deliveryLoc, setDeliveryLoc] = useState(JSON.parse(localStorage.getItem('deliveryLocation')));
    const [currentProduct, setCurrentProduct] = useState({});
    const [openDeliverLocationPopup, setOpenDeliverLocationPopup] = useState(deliveryLoc === null);
    const [deleteNotification, setDeleteNotification] = useState(false);
    // const [deliveryDetails, setDeliveryDetails] = useState(false);
    // const [deliveryDetails, setDeliveryDetails] = useState(null);
    const [loaderText, setLoaderText] = useState('Loading');
    const [loading, setLoading] = useState(false);
    const [validatedItems, setValidatedItems] = useState([]);
    const [dupeValidatedItems, setDupeValidatedItems] = useState([]);
    const [showItemsRequiringAttention, setShowItemsRequiringAttention] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [selectedItems, setSelectedItems] = useState([...carts]);
    const [itemsToCheckout, setItemsToCheckout] = useState([]);
    const [openRemoveItemsPopup, setOpenRemoveItemsPopup] = useState(false);
    const [showTutorial, setShowTutorial] = useState(false);
    const [tutorialPage, setTutorialPage] = useState(1);

    const getLocation = (data) => {
        if (data.state /*&& data.lga*/) {
            setDeliveryLoc(data);
            setOpenDeliverLocationPopup(!openDeliverLocationPopup);
        }
    };

    useEffect(() => {
        const data = getProductInfoForMixPanel(carts);
        mixPanel.track(CART_OPEN, {
            'Total cart value': subTotal,
            'Total item count': data.totalItemCount,
            'Total unique item': cartItems.length,
            'Total shops': data.sortedItems.length,
            'Item ids': data.productIds,
            'Item names': data.productNames,
            'Wallet Status': walletStatus,
            Time: new Date().toLocaleString(),
        });
        document.title = 'Sabi';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCarts([...cartItems]);
        if (cartDeletedItemsInfo) {
            setTimeout(() => {
                setDeleteNotification(cartDeletedItemsInfo.cartItemsDeleted);
                setTimeout(() => {
                    setDeleteNotification(false);
                }, 3500);
            }, 1000);
        }
    }, [cartItems, cartDeletedItemsInfo, setCarts]);

    useEffect(() => {
        setTotalPrice(subTotal + deliveryFee);
    }, [subTotal, setTotalPrice, deliveryFee]);

    useEffect(() => {
        // getShippingPrice();
        dispatch(getUserAdditionalInfo());
        dispatch(merchbuyActions.getMinimumOrderValue());
        dispatch(merchbuyActions.getShopCart());
        dispatch(fetchDeliveryDetails());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (!(deliveryLocation && Object.entries(deliveryLocation).length !== 0)) {
            dispatch(merchbuyActions.updateDeliveryLocation(deliveryDetails));
        }
    }, [deliveryLocation, dispatch, deliveryDetails]);

    const payload = {
        location: {
            destination: {
                address: deliveryDetails.streetAddress,
                city: deliveryDetails.state,
                country: 'NG',
                email: '',
                name: deliveryDetails.receiverName,
                phone: deliveryDetails.msisdn,
                state: deliveryDetails.state,
            },
            origin: {
                address: '',
                city: 'Lagos',
                country: 'NG',
                email: '',
                name: '',
                phone: '',
                state: 'Lagos',
            },
        },
        shipmentInfo: [
            {
                quantity: totalQuantity,
                weight: 1,
            },
        ],
    };

    const updateCart = (data) => {
        dispatch(merchbuyActions.updateCartLocally(data));
    };

    const getShippingPrice = () => {
        if (totalQuantity > 0) {
            dispatch(merchbuyActions.getWareNextShippingPrices(payload));
        } else {
            dispatch(merchbuyActions.updateShippingLocally([]));
        }
    };

    const showProductDescription = (product) => {
        const { productName, bulkPrices, unitValue, productDescription, categoryId } = product || {};
        setCurrentProduct({ productName, bulkPrices, unitValue, categoryId, productDescription });
        setOpenProductDetailsPopup(true);
    };

    const findDiff = (a, b) => {
        return a.filter((x) => !b.some((i) => i.productId === x.productId)) || [];
    };

    const handleCheckout = async () => {
        setDupeValidatedItems([]);
        setValidatedItems([]);

        const data = getProductInfoForMixPanel(cartItems);
        mixPanel.track(START_CHECKOUT, {
            'Total cart value': subTotal,
            'Total item count': data.totalItemCount,
            'Total unique item': cartItems.length,
            'Total shops': data.sortedItems.length,
            'Item ids': data.productIds,
            'Item names': data.productNames,
            'Wallet Status': walletStatus,
            Time: new Date().toLocaleString(),
        });

        const ids = selectedItems.map((item) => item.productId || item.id);
        const params = {
            productVariantIds: ids,
            warehouseId: carts[0].warehouseId,
        };

        setLoaderText('We are validating the items in your cart');
        setLoading(true);
        const res = await dispatch(merchbuyActions.cartItemsValidation(params));

        if (res) {
            let newCartItems;

            newCartItems = itemsToCheckout.map((item) => {
                const itemCopy = res.find((itemCopy) => item?.productId === itemCopy?.id || item?.id === itemCopy?.id);

                const newItem =
                    item?.quantity > itemCopy?.quantity
                        ? { ...item, quantity: itemCopy?.quantity }
                        : item?.quantity < itemCopy?.bulkPrices[0]?.quantity && itemCopy.quantity
                        ? { ...item, quantity: itemCopy?.bulkPrices[0]?.quantity }
                        : item;
                if (
                    item?.quantity > itemCopy?.quantity ||
                    item?.quantity < itemCopy?.bulkPrices[0]?.quantity ||
                    !itemCopy?.isOnMerchBuy ||
                    itemCopy?.stateName !== currentLocation.state
                ) {
                    let validationType;

                    if (item?.quantity < itemCopy?.bulkPrices[0]?.quantity && itemCopy?.quantity) {
                        validationType = 'increase';
                    } else if (item?.quantity > itemCopy?.quantity || !itemCopy?.quantity) {
                        validationType = 'decrease';
                    } else if (itemCopy?.stateName !== currentLocation?.state) {
                        validationType = 'not available';
                    }

                    setSelectedItems((oldItems) => oldItems.filter((itm) => itm.productId !== item?.productId));
                    dupeValidatedItems.push(newItem);
                    setValidatedItems((oldItems) => [
                        ...oldItems,
                        {
                            ...newItem,
                            availQty: itemCopy?.quantity,
                            validationType,
                            availMoq: itemCopy?.bulkPrices[0]?.quantity,
                            isAvailable: itemCopy?.isOnMerchBuy,
                        },
                    ]);
                }

                return newItem;
            });

            if (dupeValidatedItems.length) {
                const diff = findDiff(cartItems, selectedItems);
                const newCart = [...diff, ...newCartItems];
                setCarts(newCart);
                localStorage.setItem('cartItems', JSON.stringify(newCart));
                setLoading(false);
            } else {
                History.push({
                    pathname: '/actions/merchbuy/checkout',
                    state: {
                        items: itemsToCheckout,
                    },
                });
            }
        } else {
            setLoading(false);
        }
    };

    const backAction = async () => {
        await dispatch(merchbuyActions.getShopCart());
        setCarts(cartItems);
        setShowItemsRequiringAttention(false);
    };

    const handleViewItemsRequiringAttention = () => {
        setCarts(validatedItems);
        setShowItemsRequiringAttention(true);
    };

    useEffect(() => {
        if (!cartItems.length > 0) return;
        const intersection = cartItems.filter((x) => selectedItems.some((i) => i.productId === x.productId)) || [];
        setItemsToCheckout(intersection);
        const total = getCartItemsSubTotal(intersection);
        setSubTotal(total);

        const newTotalQuantity = [...intersection].reduce(
            (accum, current) => Number(accum) + Number(current.quantity),
            0,
        );
        setTotalQuantity(newTotalQuantity);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carts, selectedItems]);

    useEffect(() => {
        setIsDisabled(minimumOrderValue?.value > totalPrice ? true : false);
    }, [minimumOrderValue, totalPrice]);

    const saveItems = async () => {
        const param = {
            request: itemsToCheckout.map((item) => {
                return {
                    productId: item.productId,
                    warehouseId: item.warehouseId,
                };
            }),
        };

        const res = await dispatch(merchbuyActions.addSavedItemsBulk(param));
        if (res) {
            deleteItems();
            setOpenRemoveItemsPopup(false);
        }
    };

    const deleteItems = () => {
        const diff = findDiff(cartItems, selectedItems);
        updateCart(diff);

        //remove items(s) from list of items requiring attention
        selectedItems.map((item) => {
            const idx = validatedItems.findIndex((x) => x.productId === item.productId);
            validatedItems.splice(idx, 1);
        });
        getShippingPrice();
    };

    useEffect(() => {
        const isDone =
            localStorage.getItem('selectiveCheckoutTutorial') &&
            JSON.parse(localStorage.getItem('selectiveCheckoutTutorial'));
        if (!isDone && cartItems.length > 0) return setShowTutorial(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTutorialDone = () => {
        setShowTutorial(false);
        localStorage.setItem('selectiveCheckoutTutorial', JSON.stringify(true));
    };

    useEffect(() => {
        if (showTutorial) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [showTutorial]);

    return (
        <Fragment>
            {isLoading || loading ? (
                <Loader text={loaderText} width={'140px'} />
            ) : (
                <DesktopBackgroundLayout>
                    {carts && carts.length === 0 ? (
                        <Fragment>
                            <TopHeader withCancelIcon={true} title={'Cart'} />
                            <ScreenContainer>
                                <FlexCenteredBlock top={'30px'}>
                                    <Space height={'80px'} />
                                    <CartbasketIcon />
                                    <Space height={'32px'} />

                                    <Title>There is nothing in your cart</Title>
                                    <BottomFixedButton>
                                        <RippleButton top={'110px'} onClick={() => History.push('/actions/merchbuy')}>
                                            Start shopping
                                        </RippleButton>
                                    </BottomFixedButton>
                                </FlexCenteredBlock>
                            </ScreenContainer>
                        </Fragment>
                    ) : (
                        <Fragment>
                            {!showItemsRequiringAttention ? (
                                <TopHeader
                                    backLink="/actions/merchbuy"
                                    title={`Cart (${carts && carts.length})`}
                                    withCancelIcon={true}
                                    zIndex={showTutorial ? '1' : '1000'}
                                >
                                    <StyledDelete
                                        onClick={() => {
                                            if (selectedItems.length > 0) {
                                                setOpenRemoveItemsPopup(true);
                                            } else {
                                                toast.success('You need to select at least one item');
                                            }
                                        }}
                                    />
                                </TopHeader>
                            ) : (
                                <TopHeader
                                    backLink="/actions/merchbuy/cart"
                                    backAction={backAction}
                                    title={`Items Requiring Attention (${validatedItems?.length})`}
                                    withCancelIcon={true}
                                />
                            )}
                            <ScreenContainer
                                padding={showItemsRequiringAttention ? '26px 0 40px' : '0px'}
                                paddingBottom="40px"
                                color={colors.lightestGray}
                            >
                                {!showItemsRequiringAttention && (
                                    <TitleText>Minimum order value {formatPrice(minimumOrderValue?.value)}</TitleText>
                                )}
                                <ModifiedContainer>
                                    <List noBorderBottom={true} fullScreen>
                                        <Fragment>
                                            {carts
                                                ?.sort((a, b) => a.productName.localeCompare(b.productName))
                                                .map((product, index) => {
                                                    const validatedItem = validatedItems?.find(
                                                        (item) => item.productId === product?.productId,
                                                    );
                                                    const price = getPriceFromBulkPrices(product);
                                                    return (
                                                        <Fragment key={index}>
                                                            <ShopBlock
                                                                index={index}
                                                                cartLength={carts.length}
                                                                expired={
                                                                    product?.groupBuyTimer &&
                                                                    product?.groupBuyTimer < currentDate
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                <ModifiedListItem
                                                                    top={'4px'}
                                                                    bottom={'4px'}
                                                                    direction={'column'}
                                                                >
                                                                    <ListItemChild key={index}>
                                                                        {selectedItems.find(
                                                                            (item) =>
                                                                                item.productId === product?.productId,
                                                                        ) ? (
                                                                            <CheckedBox
                                                                                onClick={() =>
                                                                                    setSelectedItems((oldItems) =>
                                                                                        oldItems.filter(
                                                                                            (itm) =>
                                                                                                itm.productId !==
                                                                                                product?.productId,
                                                                                        ),
                                                                                    )
                                                                                }
                                                                                style={{ marginRight: '12px' }}
                                                                            />
                                                                        ) : (
                                                                            <EmptyCheckBox
                                                                                onClick={() =>
                                                                                    setSelectedItems([
                                                                                        ...selectedItems,
                                                                                        product,
                                                                                    ])
                                                                                }
                                                                            />
                                                                        )}
                                                                        <RippleLink
                                                                            to={{
                                                                                pathname: `/actions/merchbuy/product/${product?.productId}`,
                                                                                state: product,
                                                                            }}
                                                                            onClick={() => {
                                                                                dispatch(
                                                                                    merchbuyActions.setInitProductDetials(
                                                                                        product,
                                                                                    ),
                                                                                );
                                                                            }}
                                                                        >
                                                                            <PageLogo
                                                                                width={'44px'}
                                                                                height={'44px'}
                                                                                iconWidth={'44px'}
                                                                                iconHeight={'44px'}
                                                                                Icon={
                                                                                    product?.images?.baseImageUrl ||
                                                                                    product?.image ||
                                                                                    FallbackProductImage
                                                                                }
                                                                                fallback={SupermarketIcon}
                                                                                borderRadius="2px"
                                                                            />
                                                                        </RippleLink>

                                                                        <ListLeftBlock width="100%">
                                                                            {product?.groupBuyId && (
                                                                                <GroupBuyWrapper>
                                                                                    <GroupBuyBoxWrapper>
                                                                                        <GroupBuyBoxBgWrapper>
                                                                                            <GroupBuyBg />
                                                                                        </GroupBuyBoxBgWrapper>
                                                                                        <GroupBuyBoxText>
                                                                                            Groupbuy
                                                                                        </GroupBuyBoxText>
                                                                                    </GroupBuyBoxWrapper>
                                                                                    <GroupBuyEditText
                                                                                        expired={
                                                                                            product?.groupBuyTimer &&
                                                                                            product?.groupBuyTimer <
                                                                                                currentDate
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                    >
                                                                                        {product?.isGroupBuyOwner ? (
                                                                                            `${
                                                                                                product?.groupBuyTimer &&
                                                                                                product?.groupBuyTimer <
                                                                                                    currentDate
                                                                                                    ? 'EXPIRED'
                                                                                                    : 'EDIT'
                                                                                            }`
                                                                                        ) : (
                                                                                            <Countdown
                                                                                                date={
                                                                                                    product?.groupBuyTimer
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                    </GroupBuyEditText>
                                                                                </GroupBuyWrapper>
                                                                            )}
                                                                            <ListHeading
                                                                                bottom="8px"
                                                                                color={
                                                                                    product?.groupBuyTimer &&
                                                                                    product?.groupBuyTimer < currentDate
                                                                                        ? colors.lightDark
                                                                                        : colors.themeTextColor1
                                                                                }
                                                                                top="5px"
                                                                                weight={400}
                                                                                maxWidth="100%"
                                                                                fontSize="13px"
                                                                                noHeadingWrap
                                                                                onClick={() =>
                                                                                    showProductDescription(product)
                                                                                }
                                                                            >
                                                                                {product &&
                                                                                    `${product?.brandName} - ${product?.productName} - ${product?.productVariantName}`.toLowerCase()}
                                                                            </ListHeading>
                                                                            <FlexContainer>
                                                                                <Amount
                                                                                    color={
                                                                                        product?.groupBuyTimer &&
                                                                                        product?.groupBuyTimer <
                                                                                            currentDate
                                                                                            ? colors.lightDark
                                                                                            : colors.black
                                                                                    }
                                                                                    top="8px"
                                                                                >
                                                                                    {formatPrice(price)}
                                                                                </Amount>
                                                                            </FlexContainer>
                                                                        </ListLeftBlock>
                                                                    </ListItemChild>
                                                                </ModifiedListItem>
                                                                <CartUpdate>
                                                                    <Validate>
                                                                        {validatedItem && (
                                                                            <>
                                                                                {(!validatedItem.isAvailable ||
                                                                                    (validatedItem.validationType ===
                                                                                        'not available' &&
                                                                                        validatedItem.isAvailable)) && (
                                                                                    <ValidatedContainer
                                                                                        bgColor={colors.bgAmount}
                                                                                    >
                                                                                        <Text
                                                                                            color={colors.deepRed}
                                                                                            font={'10px'}
                                                                                            fontWeight={400}
                                                                                        >
                                                                                            Product is not available
                                                                                        </Text>
                                                                                    </ValidatedContainer>
                                                                                )}
                                                                                {validatedItem.availQty > 0 &&
                                                                                    validatedItem.validationType ===
                                                                                        'decrease' &&
                                                                                    validatedItem.isAvailable && (
                                                                                        <ValidatedContainer>
                                                                                            <Text
                                                                                                color={
                                                                                                    colors.deepYellow
                                                                                                }
                                                                                                font={'10px'}
                                                                                                fontWeight={400}
                                                                                            >
                                                                                                {`Only ${parseTextCountRender(
                                                                                                    validatedItem.availQty,
                                                                                                    'unit',
                                                                                                )} available`}
                                                                                            </Text>
                                                                                        </ValidatedContainer>
                                                                                    )}
                                                                                {validatedItem.availQty <= 0 &&
                                                                                    validatedItem.validationType ===
                                                                                        'decrease' &&
                                                                                    validatedItem.isAvailable && (
                                                                                        <ValidatedContainer
                                                                                            bgColor={colors.bgAmount}
                                                                                        >
                                                                                            <Text
                                                                                                color={colors.deepRed}
                                                                                                font={'10px'}
                                                                                                fontWeight={400}
                                                                                            >
                                                                                                Product out of stock
                                                                                            </Text>
                                                                                        </ValidatedContainer>
                                                                                    )}
                                                                                {validatedItem.validationType ===
                                                                                    'increase' &&
                                                                                    validatedItem.isAvailable && (
                                                                                        <ValidatedContainer
                                                                                            bgColor={colors.bgAmount}
                                                                                        >
                                                                                            <Text
                                                                                                color={colors.deepRed}
                                                                                                font={'10px'}
                                                                                                fontWeight={400}
                                                                                            >
                                                                                                {`You can't have less than ${parseTextCountRender(
                                                                                                    validatedItem.availMoq,
                                                                                                    'unit',
                                                                                                )}`}
                                                                                            </Text>
                                                                                        </ValidatedContainer>
                                                                                    )}
                                                                            </>
                                                                        )}
                                                                    </Validate>
                                                                    <SetAvailableQuantity
                                                                        isEditable={product?.groupBuyId ? false : true}
                                                                        position="relative"
                                                                        right="0px"
                                                                        width="30%"
                                                                        qtyHeight="27px"
                                                                        top="0"
                                                                        productQuantity={product?.quantity}
                                                                        availableQuantity={product?.productQuantity}
                                                                        updateCart={updateCart}
                                                                        productId={product?.productId || product?.id}
                                                                        carts={carts}
                                                                        moq={
                                                                            product?.bulkPrices[0]?.moq || product?.moq
                                                                        }
                                                                    />
                                                                </CartUpdate>
                                                            </ShopBlock>
                                                        </Fragment>
                                                    );
                                                })}
                                        </Fragment>
                                    </List>
                                </ModifiedContainer>
                            </ScreenContainer>
                            {!showItemsRequiringAttention ? (
                                <CheckoutWrapper>
                                    <CheckoutOuterWrapper>
                                        {minimumOrderValue?.value > totalPrice && (
                                            <NotificationItemWrapper>
                                                <NotificationIcon />
                                                <NotificationText>
                                                    You must order items worth at least{' '}
                                                    {formatPrice(minimumOrderValue.value)} to checkout. Add more items
                                                    to checkout
                                                </NotificationText>
                                            </NotificationItemWrapper>
                                        )}
                                        {validatedItems.length > 0 && (
                                            <NotificationItemWrapper height={'48px'}>
                                                <NotificationIcon />
                                                <NotificationText>
                                                    {parseTextCountRender(validatedItems.length, 'item')} need
                                                    {validatedItems.length > 1 ? '' : 's'} your attention
                                                </NotificationText>
                                                <Text color={colors.bgBlue} onClick={handleViewItemsRequiringAttention}>
                                                    View
                                                </Text>
                                            </NotificationItemWrapper>
                                        )}
                                        {deleteNotification && (
                                            <RemovedItemWrapper>
                                                <RemovedItem />
                                            </RemovedItemWrapper>
                                        )}

                                        <Fragment>
                                            <List noBorderBottom={true}>
                                                <SubList>
                                                    <TotalWrapper>
                                                        <TotalAmount>Cart Total</TotalAmount>
                                                        <SubListValue size={'16px'} weight={'700'}>
                                                            {formatPrice(totalPrice)}
                                                        </SubListValue>
                                                    </TotalWrapper>
                                                    <RippleButton
                                                        width="50%"
                                                        backgroundColor={isDisabled ? '#A6CBFF' : colors.deepBlue}
                                                        top={'1px'}
                                                        disabled={isDisabled}
                                                        onClick={handleCheckout}
                                                    >
                                                        Checkout ({itemsToCheckout?.length || 0})
                                                    </RippleButton>
                                                </SubList>
                                            </List>
                                        </Fragment>
                                    </CheckoutOuterWrapper>
                                </CheckoutWrapper>
                            ) : (
                                <FixedButton
                                    onClick={backAction}
                                    backgroundColor={colors.gray4}
                                    size={'16px'}
                                    height={'48px'}
                                    color={colors.deepBlue}
                                >
                                    Go Back to Cart
                                </FixedButton>
                            )}
                        </Fragment>
                    )}
                    {openDeliverLocationPopup && (
                        <DeliveryLocationPopup
                            open={openDeliverLocationPopup}
                            getLocation={getLocation}
                            cancel={() => {
                                setOpenDeliverLocationPopup(!openDeliverLocationPopup);
                            }}
                        />
                    )}
                    <ProductDetailsPopup
                        open={openProductDetailsPopup}
                        data={currentProduct}
                        productCategoryName={productCategoryName}
                        productCategeries={productCategeries}
                        cancel={() => setOpenProductDetailsPopup(false)}
                    />
                    {openRemoveItemsPopup && (
                        <RemoveSelectedCartItems
                            title={`Remove (${itemsToCheckout.length}) Cart Item(s)`}
                            subtitle={`Would you like to add selected items(s) to your saved items?`}
                            close={() => setOpenRemoveItemsPopup(false)}
                            btn1Action={saveItems}
                            btn2Action={deleteItems}
                        />
                    )}

                    {showTutorial && (
                        <SelectiveCheckoutWalkthrough
                            page={tutorialPage}
                            setPage={setTutorialPage}
                            handleDone={handleTutorialDone}
                            totalPrice={totalPrice}
                            items={itemsToCheckout}
                        />
                    )}
                </DesktopBackgroundLayout>
            )}
        </Fragment>
    );
};

export default CartItems;
