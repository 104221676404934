import React, { Fragment } from 'react';

import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Loader, TopHeader, InputWithLabel, TextareaWithLabel, RippleButton } from '../../../../components';
import { InputBlock } from '../../../../containers/InputContainer';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { updateTeam } from '../../../../redux/ducks/applications/agents/actions';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';

import { UpdateTeamValidationSchema } from './UpdateTeamValidationSchema';

const UpdateTeam = () => {
    let location = useLocation();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.applications.agents.isLoading);
    const teams = useSelector((state) => state.applications.agents.teams);
    const teamId = location.state;
    const team = teams.filter((team) => team.id === teamId)[0];

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Update Team Details'} />
                <ScreenContainer>
                    <Formik
                        initialValues={{
                            name: '',
                            description: '',
                        }}
                        validationSchema={UpdateTeamValidationSchema}
                        onSubmit={(values) => {
                            setTimeout(() => {
                                dispatch(updateTeam(teamId, values));
                            }, 200);
                        }}
                    >
                        {({ errors, touched, setFieldValue, initialValues, values }) => (
                            <Form>
                                <InputBlock>
                                    <InputWithLabel
                                        label={'Team name'}
                                        type={'text'}
                                        value={values.name}
                                        name={'name'}
                                        placeholder={team.name}
                                        valid={`${!touched.name && !errors.name}`}
                                        errors={touched && touched.name && errors && errors.name}
                                        setFieldValue={setFieldValue}
                                        initialValues={initialValues}
                                    />
                                    <TextareaWithLabel
                                        name={'description'}
                                        value={values.description}
                                        placeholder={team.description}
                                        height={'96px'}
                                        valid={`${!touched.description && !errors.description}`}
                                        errors={touched && touched.description && errors && errors.description}
                                    />
                                </InputBlock>
                                <RippleButton disabled={isLoading} top={'36px'} type={'submit'}>
                                    Update
                                </RippleButton>
                            </Form>
                        )}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default UpdateTeam;
