import { toast } from 'react-toastify';

import { axios, currentAPI } from '../../../../../config';
import {
    GET_USER_NPS_DETAILS,
    UPDATE_USER_NPS_DETAILS,
    GET_USER_LAST_SURVEY,
    CHECK_ORDER_SURVEY,
    GET_WINDOW,
    GET_SURVEY_TYPES,
} from '../../constants';

export const updateNps = (info) => async (dispatch, getState) => {
    const userId = getState().user.userId;

    try {
        const response = await axios.post(`${currentAPI}/api/userNetPromoterFeedback/promoterFeedBack`, {
            feedbackDtos: info,
            userId,
            publicId: '',
        });

        if (response.status === 200) {
            dispatch({ type: UPDATE_USER_NPS_DETAILS, payload: response.data });
            return response.data;
        }
    } catch (error) {
        toast.error(error.message === 'Network Error' ? error.message : "Sorry, we couldn't send your feedback!");
    }
};

export const getUserNpsDetails = (id) => async (dispatch, getState) => {
    if (!navigator.onLine) return;
    try {
        let userId;
        if (id) {
            userId = id;
        } else {
            userId = getState().user.userId;
        }

        const response = await axios.get(`${currentAPI}/api/userNetPromoterFeedback/validateNPSRecord/${userId}`);

        if (response.status === 200) {
            dispatch(saveNps(response.data));

            return response.data;
        }
    } catch (error) {
        console.error(error);
    }
};

export const getLastSurvey = (surveyTypeId) => async (dispatch) => {
    if (!navigator.onLine) return;
    try {
        const response = await axios.get(`${currentAPI}/api/users/survey/lastSurvey/${surveyTypeId}`);

        if (response.status === 200) {
            dispatch({ type: GET_USER_LAST_SURVEY, payload: response?.data?.data || {} });
        }
    } catch (error) {
        console.error(error);
    }
};

export const getSurveyTypes = () => async (dispatch) => {
    if (!navigator.onLine) return;
    try {
        const response = await axios.get(`${currentAPI}/api/users/survey`);

        if (response.status === 200) {
            dispatch({ type: GET_SURVEY_TYPES, payload: response?.data?.data?.content });
        }
    } catch (error) {
        //
    }
};

export const getSurveyWindow = () => async (dispatch) => {
    if (!navigator.onLine) return;
    try {
        const response = await axios.get(`${currentAPI}/api/users/survey/config`);

        if (response.status === 200) {
            dispatch({ type: GET_WINDOW, payload: response?.data?.data });
        }
    } catch (error) {
        console.error(error);
    }
};

export const checkOrderSurvey = (orderId) => async (dispatch) => {
    if (!navigator.onLine) return;
    try {
        const response = await axios.get(`${currentAPI}/api/users/survey/checkOrder?orderId=${orderId}`);

        if (response.status === 200) {
            dispatch({ type: CHECK_ORDER_SURVEY, payload: response?.data?.data });
            return true;
        }
    } catch (error) {
        return false;
    }
};

export const postSurvey = (params) => async (dispatch) => {
    if (!navigator.onLine) return;
    try {
        const { surveyId } = params;
        const response = await axios.post(`${currentAPI}/api/users/survey`, params);

        if (response.status === 200) {
            dispatch(getLastSurvey(surveyId));
            toast.success('Feedback successfully sent');
            return true;
        }
    } catch (error) {
        toast.error(error.message === 'Network Error' ? error.message : "Sorry, we couldn't send your feedback!");
        return false;
    }
};

export const saveNps = (nps) => (dispatch) => {
    dispatch({ type: GET_USER_NPS_DETAILS, payload: nps });
};
