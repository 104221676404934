import styled, { css } from 'styled-components';

import CheckedIcon from '../assets/checkmark.svg';
import { colors } from '../styles';

import { SecondaryText, SmallLightText, SubTitle } from './MessageContainer';

export const List = styled.ul`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: ${({ top }) => top || 0};
    border-bottom: ${({ noBorderBottom }) => (noBorderBottom ? 'none' : `1px solid ${colors.border.bottom}`)};
    margin: ${({ fullScreen }) => fullScreen && '0 -16px'};
    ${({ childLink }) =>
        childLink &&
        css`
            & > a:first-child {
                border-top: 1px solid ${colors.border.top};
            }
            & > a:not(:last-child) {
                border-bottom: 1px solid ${colors.border.bottom};
            }
        `};
`;

export const ListWithLetters = styled.ol`
    list-style-type: lower-latin;
`;

export const ListItem = styled.li`
    display: flex;
    flex-direction: ${({ direction }) => direction || 'row'};
    justify-content: ${({ justifyContent }) => justifyContent || null};
    -webkit-tap-highlight-color: transparent;
    position: relative;
    margin: ${({ margin }) => margin || null};
    background: ${({ background }) => background || null};
    padding: ${({ padding }) => padding || '0 16px'};
    padding-top: ${({ top }) => top || '8px'};
    padding-bottom: ${({ bottom }) => bottom || null};
    min-height: ${({ height }) => height || '56px'};
    height: ${({ height }) => height || null};
    align-items: ${({ align }) => align || null};
    border-bottom: ${({ noBorderBottom }) => (noBorderBottom ? 'none' : `1px solid ${colors.border.bottom}`)};
    ${({ pressedUpList }) =>
        pressedUpList
            ? css`
                  &:not(:first-of-type) {
                      border-top: 1px solid ${colors.border.top};
                  }
              `
            : css`
                  border-top: ${({ borderTop }) => borderTop && `1px solid ${colors.border.top}`};
              `};
    &:not(:last-of-type) {
        border-bottom: ${({ borderBottom }) => borderBottom && `1px solid ${colors.border.bottom}`};
    }

    &:hover {
        background: ${({ hover }) => hover || '#f2f5fa'};
    }
`;

export const ListItemNoDivider = styled.li`
    display: flex;
    flex-direction: ${({ direction }) => direction || 'row'};
    position: relative;
    padding: ${({ padding }) => padding || '0 1px'};
    padding-top: ${({ top }) => top || '8px'};
    padding-bottom: ${({ bottom }) => bottom || null};
`;

export const ListLeftBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: ${({ width }) => width || null};
    margin-left: ${({ left }) => left || '16px'};
`;

export const ListHeading = styled.p`
    margin-top: ${({ top }) => top || '0px'};
    margin-bottom: ${({ bottom }) => bottom || '0px'};
    margin-left: ${({ left }) => left || null};
    max-width: ${({ maxWidth }) => maxWidth || '220px'};
    font-size: ${({ fontSize }) => fontSize || '14px'};
    font-weight: ${({ weight }) => weight || '500'};
    line-height: ${({ lineHeight }) => lineHeight || '18px'};
    color: ${({ color }) => color || colors.themeTextColor1};
    opacity: ${({ opacity }) => opacity || null};
    line-break: loose;
    height: fit-content;
    text-transform: capitalize;
    text-align: ${({ textAlign }) => textAlign || 'left'};

    ${({ noHeadingWrap }) =>
        noHeadingWrap
            ? null
            : css`
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
              `}
`;

export const ListSubHeading = styled(SecondaryText)`
    display: inline;
    color: ${({ color }) => color || null};
    font-weight: ${({ fontWeight }) => fontWeight || '100'};
    height: fit-content;
    font-size: ${({ fontSize }) => fontSize || '10px'};
    overflow: ${({ overFlow }) => overFlow || 'hidden'};
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: ${({ top }) => (top ? `${top} 0 0 0` : '4px 0 0 0')};
    margin-left: ${({ left }) => left || null};
    max-width: ${({ maxWidth }) => maxWidth || '180px'};
    padding: ${({ padding }) => padding || null};
`;

export const ListSubDetail = styled.span`
    ${SmallLightText}
    margin-top: 8px;
`;

export const ListHighlight = styled(ListHeading)`
    position: absolute;
    right: 16px;
`;

export const TransactionDetail = styled(ListHighlight)`
    top: ${({ top }) => top || null};
    min-width: auto;
    text-transform: uppercase;
    max-width: 240px;
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const ListCheckedRow = styled(ListLeftBlock)`
    flex-direction: row;
    width: 100%;

    &.active {
        &:after {
            content: url(${CheckedIcon});
            position: absolute;
            top: 16px;
            right: 24px;
        }
    }
`;

export const ListPopUp = styled(ListLeftBlock)`
    width: 100%;
`;

export const AlphabetList = styled.ul`
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const AlphabetItems = styled.ul`
    flex-direction: column;
    margin: 0;
    padding: 0;
    position: relative;
`;

export const AlphabetSortedItems = styled(ListItem)`
    position: relative;
    align-items: center;
    padding: 16px;
    margin-left: 0;
    border-top: 1px solid ${colors.border.top};
    border-bottom: 1px solid ${colors.border.bottom};
`;

export const AlphabetLetter = styled(SubTitle)`
    font-weight: 100;
    margin: 24px 16px;
`;

export const ListWithCounter = styled.ol`
    counter-reset: item;
    padding-left: 10px;
    & li {
        display: block;
        margin-top: 30px;
        &::before {
            content: counters(item, '.') '.';
            counter-increment: item;
            margin-right: 10px;
        }
        ol {
            padding-left: 0;
        }
        li {
            margin-top: ${({ top }) => top || '30px'};
            display: flex;
            ol {
                padding-left: 0;
            }
        }
    }
`;

export const EmptyList = styled.div`
    margin-top: 32px;
`;

export const NoRecordFoundTitle = styled(SecondaryText)`
    margin-left: 16px;
`;
