import React, { useMemo } from 'react';

import ProductFallbackImage from '../../../../components/popup/merchbuy-add-to-cart-popup/assets/productImage.svg';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import { is_url } from '../../../../utils/urls/isUrl';

import { CurrentQuantity, QuantityDisplay } from './split-configuration';
import {
    DiscountedPrice,
    DiscountStrikethrough,
    ModifiedAddIcon,
    ModifiedSubstractIcon,
    OriginalPrice,
    Paragraph,
    PriceAndDiscount,
    PriceContainer,
    PriceLabelText,
    ProductBlock,
    ProductImage,
    ProductImageContainer,
    ProductInfo,
    QuantityControls,
    SubTitle,
    Title,
    UpdateCalculatorBlock,
} from './styles';

function SelectQuantityToBuy({
    product,
    orderQuantity,
    reduceQuantity,
    increaseQuantity,
    handleQuantityManualInput,
    currentPrice,
}) {
    const { images, name, unitValue, bulkPrices } = product;

    const disableSubstractQuantityButton = useMemo(() => {
        if (bulkPrices && bulkPrices.length > 0) {
            return orderQuantity <= bulkPrices[0].moq;
        }
        return true;
    }, [bulkPrices, orderQuantity]);

    return (
        <>
            <Title margin={'24px 0 10px'}>Quantity</Title>
            <SubTitle>How many do you want to buy?</SubTitle>

            <ProductBlock>
                <ProductImageContainer>
                    <ProductImage
                        src={is_url(images && images.baseImageUrl) ? images.baseImageUrl : ProductFallbackImage}
                        alt="product image"
                        onError={(e) => {
                            e.target.src = ProductFallbackImage;
                        }}
                    />
                </ProductImageContainer>

                <ProductInfo>
                    {name} ({orderQuantity} {unitValue}
                    {' inside'})
                </ProductInfo>
            </ProductBlock>

            <UpdateCalculatorBlock>
                <PriceContainer>
                    <PriceLabelText>Price</PriceLabelText>
                    <PriceAndDiscount>
                        <DiscountedPrice>{formatPrice(currentPrice)}</DiscountedPrice>
                        {product.minPrice > currentPrice && (
                            <OriginalPrice>
                                {formatPrice(product.minPrice)}
                                <DiscountStrikethrough />
                            </OriginalPrice>
                        )}
                    </PriceAndDiscount>
                </PriceContainer>

                <QuantityControls>
                    <ModifiedSubstractIcon disabled={disableSubstractQuantityButton} onClick={reduceQuantity} />
                    <QuantityDisplay background={'none'}>
                        <CurrentQuantity
                            value={orderQuantity}
                            type={'number'}
                            width={'100%'}
                            background={'none'}
                            onChange={handleQuantityManualInput}
                        />
                    </QuantityDisplay>
                    <ModifiedAddIcon onClick={increaseQuantity} />
                </QuantityControls>
            </UpdateCalculatorBlock>
        </>
    );
}

function SelectNumberOfBuyers({
    noOfInvitees,
    handleInviteeQuantityManualInput,
    reduceQuantityToShare,
    orderQuantity,
    increaseQuantityToShare,
}) {
    return (
        <>
            <Title margin={'40px 0 10px'}>Participants</Title>

            <SubTitle>How many of you want to share it?</SubTitle>
            <UpdateCalculatorBlock borderTop={'none'}>
                <QuantityControls>
                    <ModifiedSubstractIcon disabled={noOfInvitees === 1} onClick={reduceQuantityToShare} />
                    <QuantityDisplay background={'none'}>
                        <CurrentQuantity
                            value={noOfInvitees}
                            type={'number'}
                            width={'100%'}
                            background={'none'}
                            onChange={handleInviteeQuantityManualInput}
                        />
                    </QuantityDisplay>
                    <ModifiedAddIcon disabled={orderQuantity === noOfInvitees} onClick={increaseQuantityToShare} />
                </QuantityControls>
            </UpdateCalculatorBlock>
        </>
    );
}

function ProductImageBlock({ product, currentPrice, shadow, margin, padding, background, height }) {
    const { images, unitValue, name } = product;

    return (
        <ProductBlock
            margin={margin || '0 -4% 10px'}
            padding={padding || '10px'}
            shadow={shadow || '0px 4px 36px rgba(0, 0, 0, 0.08)'}
            background={background}
            height={height}
        >
            <ProductImageContainer>
                <ProductImage
                    src={is_url(images ? images.baseImageUrl : '') ? images.baseImageUrl : ProductFallbackImage}
                    onError={(e) => {
                        e.target.src = ProductFallbackImage;
                    }}
                    alt="product image"
                />
            </ProductImageContainer>

            <ProductInfo flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <Paragraph fontSize={'12px'}>{name}</Paragraph>
                <Paragraph fontSize={'12px'} margin={'8px 0'} fontWeight={'600'}>
                    {formatPrice(currentPrice)}/{unitValue}
                </Paragraph>
            </ProductInfo>
        </ProductBlock>
    );
}

export { SelectQuantityToBuy, SelectNumberOfBuyers, ProductImageBlock };
