import React, { Fragment } from 'react';

import styled from 'styled-components';

import { PopUp, PopUpContent } from '../../../../../components/popup/common';
import { Overlay } from '../../../../../containers/OverlayContainer';
import { colors } from '../../../../../styles';

import { ReactComponent as Bulb } from './assets/bulb.svg';
import { ReactComponent as Close } from './assets/close.svg';

const InfoContainer = styled.div`
    padding: 33px 16px 58px 16px;
    text-align: left;
`;

const BulbStyle = styled(Bulb)`
    margin-bottom: 16px;
`;

const ThresholdText = styled.h3`
    color: ${({ color }) => color || null};
    font-size: ${({ size }) => size || null};
    font-weight: ${({ weight }) => weight || null};
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || null};
`;

const Circle = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: ${colors.white};
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: -150px;
`;

const ThresholdPopup = ({ open, setOpen }) => {
    return (
        <Fragment>
            <Overlay bgc="rgba(0, 0, 0, 0.4)" zIndex={'1000'}>
                <PopUp padding="0" open={open} zIndex={'10000'} flex={'column'}>
                    <Circle onClick={() => setOpen(!open)}>
                        <Close />
                    </Circle>
                    <PopUpContent radius="8px 8px 0px 0px">
                        <InfoContainer>
                            <BulbStyle />
                            <ThresholdText color={colors.themeTxtColor10} size={'16px'} weight={'700'} bottom={'16px'}>
                                What is Stock quantity threshold?
                            </ThresholdText>
                            <ThresholdText color={'#5C6F7F'} weight={'400'} size={'14px'}>
                                This is the minimum count of an item you keep on hand. When inventory levels fall below
                                the threshold quantity, you reorder stock to replenish your items on hand.
                            </ThresholdText>
                        </InfoContainer>
                    </PopUpContent>
                </PopUp>
            </Overlay>
        </Fragment>
    );
};

export default ThresholdPopup;
