import React from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import { colors } from '../../../../../styles';
import { ReactComponent as AddIcon } from '../../assets/add_sign.svg';
import { ReactComponent as SubstractIcon } from '../../assets/subtract_sign.svg';

const UpdateCalculatorBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    & > svg {
        cursor: pointer;
    }
    margin-left: 10px;
`;

const QuantityDisplay = styled.div`
    border-radius: ${({ radius }) => radius || '8px'};
    text-align: center;
    width: ${({ width }) => width || '64px'};
    height: ${({ height }) => height || '32px'};
    background: ${({ bg }) => bg || colors.themeTextColor1};
`;

const CurrentQuantity = styled.input`
    position: relative;
    top: calc(50% - 8px);
    font-size: 14px;
    font-weight: 500;
    color: ${({ color }) => color || colors.themeTextColor1};
    margin-top: ${({ top }) => top || null};
    margin-left: ${({ left }) => left || null};
    width: ${({ width }) => width || '70%'};
    background: ${({ bg }) => bg || 'inherit'};
    height: ${({ height }) => height || null};
    border: none;
    text-align: center;
    &:focus {
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
`;

const ModifiedSubstractIcon = styled(SubstractIcon)`
    color: ${({ disabled }) => (disabled ? colors.lightGray3 : colors.white)};
`;

const ModifiedAddIcon = styled(AddIcon)`
    color: ${({ disabled }) => (disabled ? colors.lightGray3 : colors.white)};
`;

const CounterButton = styled.div`
    display: flex;
    align-items: center;
    height: 32px;
    width: 32px;
    padding: 10px;
    border-radius: 50%;
    background: ${({ disabled }) => (disabled ? colors.white : colors.deepBlue)};
    border: 0.8px solid ${({ disabled }) => (disabled ? colors.lightGray3 : colors.deepBlue)};
    margin-left: ${({ left }) => left || '0px'};
    margin-right: ${({ right }) => right || '0px'};
    pointer-events: ${({ disabled }) => disabled && 'none'};
`;

/* 
    aoq = Available order quantity (Quantity in Stock)
*/
export const CartProductCounter = ({ moq, aoq, isGroupBuy, selectedMoq, setSelectedMoq }) => {
    const substract = () => {
        setSelectedMoq(parseInt(selectedMoq) - 1 <= moq ? moq : parseInt(selectedMoq) - 1);
    };

    const add = () => {
        setSelectedMoq(parseInt(selectedMoq) + 1);
    };

    const disableSubstractButton = selectedMoq <= moq || isGroupBuy || !selectedMoq;

    const disableAddButton = isGroupBuy || selectedMoq >= aoq;

    const disableInputField = isGroupBuy;

    return (
        <UpdateCalculatorBlock>
            <CounterButton disabled={disableSubstractButton} left="6px" right="6px" onClick={substract}>
                <ModifiedSubstractIcon disabled={disableSubstractButton} />
            </CounterButton>
            <QuantityDisplay width="30.6px" radius="1.6px" bg="none">
                <CurrentQuantity
                    width="120%"
                    top="-10px"
                    left="-2px"
                    bg={colors.themeColor3}
                    height="32px"
                    value={selectedMoq}
                    disabled={disableInputField}
                    type={'number'}
                    onChange={(e) => {
                        const number = e.target.value < 0 ? parseInt(moq) : parseInt(e.target.value);
                        if (number > parseInt(aoq)) {
                            return toast.info("You can't enter quantity more than what we have in stock");
                        }

                        setSelectedMoq(number);
                    }}
                />
            </QuantityDisplay>
            <CounterButton right="15px" disabled={disableAddButton} left="6px" onClick={add}>
                <ModifiedAddIcon disabled={disableAddButton} />
            </CounterButton>
        </UpdateCalculatorBlock>
    );
};
