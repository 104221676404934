import React, { Fragment, useEffect, useState } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useRouter from 'use-react-router';

import { SwitchTrigger2, TopHeader } from '../../../../../components';
import { getDebtor, getDebtReminderStatus } from '../../../../../redux/ducks/applications/my-shop/actions/debtors';
import { colors } from '../../../../../styles';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import { formatCurrency } from '../../../../../utils/currency/parseBalance';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import baseImg from '../assets/base.png';
import Bg from '../assets/bg.png';
import { ReactComponent as FlowerIcon } from '../assets/flower.svg';
import { ReactComponent as NotificationIcon } from '../assets/notification.svg';
import { ReactComponent as ReminderUnsentIcon } from '../assets/unsent.svg';

import ReminderConfirmPopup from './ReminderConfirm';
import ReminderHistoryPopup from './ReminderDetails';
import SetReminderPopup from './SetReminder';
import TurnOffConfirmPopup from './TurnOffReminder';
import WalletPopup from './walletPopup';

const Header = styled.div`
    width: 100%;
    height: 150px;
    background-image: url('${Bg}');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const ProfileWrapper = styled.div`
    height: 42px;
    width: 100%;
    border-radius: 0 0 10px 10px;
    background: #5978fb;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Wrapper = styled.div`
    padding: 16px 16px 0 16px;
    margin-top: 55px;
    background: ${colors.white};
`;

const Text = styled.div`
    font-size: ${({ size }) => size || '14px'};
    color: ${({ color }) => color || colors.black};
    padding: ${({ padding }) => padding || null};
    line-height: ${({ lineHeight }) => lineHeight || null};
    font-weight: ${({ weight }) => weight || '400'};
    text-align: ${({ align }) => align || null};
    opacity: ${({ opacity }) => opacity || null};
`;

const TextWrapper = styled.div`
    display: flex;
    justify-content: ${({ justify }) => justify || 'space-between'};
    align-items: center;
    margin-top: ${({ top }) => top};
    background: ${({ bgColor }) => bgColor || null};
    height: ${({ height }) => height || null};
    padding: ${({ noPadding }) => (noPadding ? '0' : '0 16px')};
`;

const ReminderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${({ top }) => top || '4px'};
    background: ${colors.white};
    height: 54px;
    box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
`;

const ReminderInnerWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
`;

const TabWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
`;

const TabInnerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${colors.gray4};
    border-radius: 12px;
    padding: 5px;
    width: 228px;
    height: 40px;
`;

const Tab = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ active }) => (active ? colors.white : 'transparent')};
    padding: 5px;
    min-width: 92px;
    height: 30px;
    box-shadow: ${({ active }) => (active ? '1px 1px 4px rgba(0, 0, 0, 0.05)' : null)};
    border-radius: 8px;
`;

const OutterWrapper = styled.div`
    background: #f7f8f9;
`;

const TabItemsWrapper = styled.div`
    padding-bottom: 100px;
`;

const daysOfTheWeek = [
    { title: 'Monday', id: 1 },
    { title: 'Tuesday', id: 2 },
    { title: 'Wednesday', id: 3 },
    { title: 'Thursday', id: 4 },
    { title: 'Friday', id: 5 },
    { title: 'Saturday', id: 6 },
    { title: 'Sunday', id: 7 },
];

function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
}

const fullYear = new Date().getFullYear();

const DebtorInfo = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const debtorInfo = useSelector((state) => state.applications.myShop.debtor?.debtorInfo || {});
    const reminderSuccess = useSelector((state) => state.applications.myShop.debtor?.reminderSuccess);
    const reminderStatus = useSelector((state) => state.applications.myShop.debtor?.reminderStatus);
    const isLoading = useSelector((state) => state.applications.myShop.loadingDebtors);
    const isWalletActive = useSelector((state) => state.account?.wallet?.status);

    const [openReminderConfirm, setOpenReminderConfirm] = useState(false);
    const [openReminder, setOpenReminder] = useState(false);
    const [openTurnOff, setOpenTurnOff] = useState(false);
    const [openWallet, setOpenWallet] = useState(false);
    const [checkStatus, setCheckStatus] = useState(false);
    const [activeBtn, setActiveBtn] = useState('');
    const [showDebtorDetails, setShowDebtorDetails] = useState(false);
    const [selectedReminder, setSelectedReminder] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [monthly, setMonthly] = useState('');
    const [weekly, setWeekly] = useState('');

    const { location } = useRouter();
    const { pathname } = location;
    const pattern = `(.*)?${'/actions/debtors/:id'}`;
    const match = matchPath(pathname, { path: pattern }) || {};
    const [tabs, setTabs] = useState([
        {
            title: 'Debt History',
            active: true,
        },
        {
            title: 'Reminder History',
            active: false,
        },
    ]);

    useEffect(() => {
        dispatch(getDebtor(match.params.id));
        dispatch(getDebtReminderStatus(match.params.id));
    }, [dispatch, match.params.id]);

    useEffect(() => {
        if (reminderStatus?.active) {
            setIsEdit(true);
            setCheckStatus(reminderStatus?.active);
            const frequency = capitalizeFirstLetter(reminderStatus?.frequency);
            const monthDay = moment(`${fullYear}-12-${reminderStatus?.day}`).format('Do');
            setMonthly(`${monthDay}, ${frequency}`);
            const weekDay = daysOfTheWeek.find((week) => week.id === reminderStatus?.day)?.title;
            setWeekly(`${weekDay}, ${frequency}`);
        } else {
            setCheckStatus(false);
        }
    }, [reminderStatus]);

    const handleTabClick = (index) => {
        const newTabs = [...tabs];
        newTabs[0].active = false;
        newTabs[1].active = false;
        newTabs[index].active = true;
        setTabs(newTabs);
    };

    useEffect(() => {
        if (reminderSuccess) {
            setCheckStatus(true);
            setOpenReminder(false);
        }
    }, [reminderSuccess]);

    useEffect(() => {
        if (isWalletActive !== 'ACTIVE') {
            setOpenWallet(true);
        }
    }, [isWalletActive]);

    const reminderDate = moment(reminderStatus?.oneTimeDate, 'DD-MM-YYYY').format('MMM D, YYYY');
    return (
        <Fragment>
            <DesktopBackgroundLayout bgColor="#f7f8f9" fullHeight>
                <TopHeader
                    backgroundColor={
                        openReminder || openReminderConfirm || openWallet || openTurnOff || showDebtorDetails
                            ? 'transparent'
                            : null
                    }
                    noBorderBottom={true}
                    noTitle={true}
                    noArrow={
                        openReminder || openReminderConfirm || openWallet || openTurnOff || showDebtorDetails
                            ? true
                            : false
                    }
                />
                <Wrapper>
                    <Header>
                        <div>
                            <Text
                                align="center"
                                opacity={0.8}
                                lineHeight="18px"
                                size="12px"
                                color={colors.white}
                                padding="24px 0 0 0"
                            >
                                {debtorInfo?.name?.split(' ')[0]} owes you
                            </Text>
                            <Text
                                align="center"
                                lineHeight="36px"
                                size="24px"
                                weight="bold"
                                color={colors.white}
                                padding="4px 0 0 0"
                            >
                                {formatCurrency(debtorInfo?.totalAmountOwed)}
                            </Text>
                        </div>
                        <ProfileWrapper>
                            <Text
                                onClick={() => history.push('/actions/shop_customers?fromReminder=true')}
                                lineHeight="18px"
                                size="12px"
                                weight="bold"
                                color={colors.white}
                            >
                                VIEW {debtorInfo?.name?.split(' ')[0]?.toUpperCase()}’S PROFILE
                            </Text>
                        </ProfileWrapper>
                    </Header>
                </Wrapper>
                <OutterWrapper>
                    <Text lineHeight="18px" size="12px" weight="700" color={colors.subGrey} padding="20px 0 0 16px">
                        AUTOMATIC REMINDER
                    </Text>
                    <ReminderWrapper>
                        <ReminderInnerWrapper>
                            <img alt="reminder icon" height="40" width="40" src={baseImg} />
                            <Text
                                padding="0 0 0 12.33px"
                                weight="bold"
                                lineHeight="18px"
                                size="12px"
                                color={colors.boldDark}
                            >
                                REMINDER OFF
                            </Text>
                        </ReminderInnerWrapper>
                        <ReminderInnerWrapper>
                            <SwitchTrigger2
                                checkStatus={checkStatus}
                                switchStatus={() => {
                                    if (checkStatus) {
                                        setOpenTurnOff(true);
                                    } else {
                                        if (isEdit) {
                                            setOpenReminder(!openReminder);
                                        } else {
                                            setOpenReminderConfirm(!openReminderConfirm);
                                        }
                                    }
                                }}
                                width={42}
                                height={24}
                                switchOffColor="#BBC0C9"
                                switchOnColor={colors.deepBlue}
                            />
                        </ReminderInnerWrapper>
                    </ReminderWrapper>
                    {reminderStatus?.active && (
                        <ReminderWrapper top="2px">
                            <Text padding="0 16px" weight="500" lineHeight="21px" size="14px" color={colors.boldDark}>
                                {reminderStatus?.oneTime
                                    ? reminderDate
                                    : reminderStatus?.frequency === 'MONTHLY'
                                    ? monthly
                                    : weekly}
                            </Text>
                            <Text
                                onClick={() => {
                                    setIsEdit(true);
                                    setOpenReminder(true);
                                }}
                                padding="0 16px"
                                weight="500"
                                lineHeight="21px"
                                size="14px"
                                color={colors.deepBlue}
                            >
                                Edit Reminder
                            </Text>
                        </ReminderWrapper>
                    )}
                    <TabWrapper>
                        <TabInnerWrapper>
                            {tabs.map((data, index) => (
                                <Tab
                                    lineHeight="18px"
                                    active={data.active}
                                    onClick={() => handleTabClick(index)}
                                    key={index}
                                >
                                    <Text size="12px" weight="500" color={colors.themeTextColor1}>
                                        {data.title}
                                    </Text>
                                </Tab>
                            ))}
                        </TabInnerWrapper>
                    </TabWrapper>
                    {tabs[0].active ? (
                        <TabItemsWrapper>
                            {debtorInfo?.debtHistory?.map((data, index) => (
                                <TextWrapper
                                    height="68px"
                                    bgColor={colors.white}
                                    noPadding
                                    key={data.id}
                                    top={index === 0 ? '15px' : '2px'}
                                >
                                    <TextWrapper justify="center">
                                        <FlowerIcon />
                                        <div>
                                            <Text
                                                padding="0 0 0 16px"
                                                weight="500"
                                                lineHeight="21px"
                                                size="14px"
                                                color={colors.boldDark}
                                            >
                                                {formatPrice(data.amountOutstanding)}
                                            </Text>
                                            <Text
                                                padding="0 0 0 16px"
                                                lineHeight="21px"
                                                size="14px"
                                                color={colors.boldDark}
                                            >
                                                {moment(data.localSalesDate).format('MMM D, YYYY')}
                                            </Text>
                                        </div>
                                    </TextWrapper>

                                    <Text
                                        padding="0 16px 0 0"
                                        weight="500"
                                        lineHeight="21px"
                                        size="14px"
                                        color={colors.deepBlue}
                                        onClick={() => history.push(`/actions/debtors/${data.id}/update`)}
                                    >
                                        Update
                                    </Text>
                                </TextWrapper>
                            ))}
                        </TabItemsWrapper>
                    ) : (
                        <TabItemsWrapper>
                            {debtorInfo?.reminderHistory?.map((data, index) => (
                                <TextWrapper
                                    height="68px"
                                    bgColor={colors.white}
                                    noPadding
                                    key={index}
                                    top={index === 0 ? '15px' : '2px'}
                                    onClick={() => {
                                        setSelectedReminder(data);
                                        setShowDebtorDetails(!showDebtorDetails);
                                    }}
                                >
                                    <TextWrapper justify="center">
                                        {!data.sent ? <ReminderUnsentIcon /> : <NotificationIcon />}

                                        <div>
                                            <Text
                                                padding="0 0 0 16px"
                                                lineHeight="21px"
                                                size="14px"
                                                color={colors.boldDark}
                                            >
                                                {data.sent ? 'Reminder Sent' : 'Reminder Not Sent'}
                                            </Text>
                                            <Text
                                                padding="2px 0 0 16px"
                                                lineHeight="18px"
                                                size="12px"
                                                color={colors.subGrey}
                                            >
                                                {moment(data?.dateSent, 'DD-MM-YYYY hh:mm:ss').fromNow()}
                                            </Text>
                                        </div>
                                    </TextWrapper>
                                    <Text padding="0 16px 0 0" lineHeight="21px" size="14px" color={colors.lightDark}>
                                        {moment(data.dateSent, 'DD-MM-YYYY hh:mm:ss').format('MMM D')}
                                    </Text>
                                </TextWrapper>
                            ))}
                        </TabItemsWrapper>
                    )}
                </OutterWrapper>
            </DesktopBackgroundLayout>
            {openReminderConfirm && (
                <ReminderConfirmPopup
                    customerName={debtorInfo?.name}
                    open={openReminderConfirm}
                    cancel={() => setOpenReminderConfirm(false)}
                    onButtonClick={() => {
                        setOpenReminderConfirm(false);
                        setOpenReminder(true);
                    }}
                    setActiveBtn={setActiveBtn}
                    isEdit={isEdit}
                    reminderStatus={reminderStatus}
                />
            )}
            {showDebtorDetails && (
                <ReminderHistoryPopup
                    debtorInfo={debtorInfo}
                    reminder={selectedReminder}
                    open={showDebtorDetails}
                    setOpen={setShowDebtorDetails}
                />
            )}
            {openTurnOff && (
                <TurnOffConfirmPopup
                    debtid={debtorInfo?.id}
                    customerName={debtorInfo?.name}
                    reminderSuccess={reminderSuccess}
                    customerId={match.params.id}
                    open={openTurnOff}
                    cancel={() => setOpenTurnOff(false)}
                    onButtonClick={() => {
                        setOpenTurnOff(!openTurnOff);
                    }}
                    checkStatus={checkStatus}
                    setCheckStatus={setCheckStatus}
                />
            )}
            {openReminder && (
                <SetReminderPopup
                    customerName={debtorInfo?.name}
                    customerId={match.params.id}
                    open={openReminder}
                    cancel={() => setOpenReminder(false)}
                    activeButton={
                        activeBtn
                            ? activeBtn
                            : reminderStatus?.active && reminderStatus?.oneTime
                            ? 'One-Time Reminder'
                            : 'Frequent Reminder'
                    }
                    isLoading={isLoading}
                    daysOfTheWeek={daysOfTheWeek}
                    isEdit={isEdit}
                    reminderStatus={reminderStatus}
                />
            )}
            {openWallet && <WalletPopup debtorId={match.params?.id} open={openWallet} />}
        </Fragment>
    );
};

export default DebtorInfo;
