import React from 'react';

import { string, func } from 'prop-types';

import { BadgeWrapper, HorizontalLayout, CancelWrapper, ErrorMessage, ErrorTitle } from '../styles';

import { ReactComponent as CancelIcon } from './assets/cancel.svg';
import { ReactComponent as ErrorIcon } from './assets/error.svg';

const ErrorBadge = ({ title, message, onCancelClick }) => {
    return (
        <BadgeWrapper>
            <ErrorIcon />
            <HorizontalLayout>
                <ErrorTitle>{title}</ErrorTitle>
                <ErrorMessage>{message}</ErrorMessage>
            </HorizontalLayout>
            <CancelWrapper onClick={() => onCancelClick && onCancelClick()}>
                <CancelIcon />
            </CancelWrapper>
        </BadgeWrapper>
    );
};

ErrorBadge.propTypes = {
    title: string,
    message: string,
    onCancelClick: func,
};

export default ErrorBadge;
