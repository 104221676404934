import React, { Fragment, useState } from 'react';

import { useHistory } from 'react-router';
import styled from 'styled-components';

import { LightButton } from '../../../../../components/button';
import { colors } from '../../../../../styles';
import BlockedIcon from '../assets/blocked-wallet.svg';
import { ReactComponent as CancelIcon } from '../assets/cancel-icon.svg';

export const BlurBackground = styled.div`
    height: 100%;
    position: ${({ position }) => position || 'relative'};
    width: ${({ width }) => width || '100%'};
    z-index: 200;
    max-width: inherit;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(1.5px);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    -webkit-animation: fadeIn 0.2s ease-in-out both;
    animation: fadeIn 0.2s ease-in-out both;
    animation-delay: 0s;
    transition: all 0.2s ease-in;
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    &.fadeOut {
        -webkit-animation: fadeOut 0.3s ease-in-out both;
        animation: fadeOut 0.3s ease-in-out both;
        transition: all 0.3s ease-in-out;

        @keyframes fadeOut {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }
    }
`;

const BlurPopUpBackground = styled(BlurBackground)`
    bottom: 0;
    position: ${({ position }) => position || 'fixed'};
    margin-left: ${({ left }) => left || null};
`;

const WalletBlockedContainer = styled.div`
    height: 333px;
    width: 343px;
    position: absolute;
    background-color: white;
    border-radius: 16px;
    top: 200px;
    bottom: 70px;
    padding: 16px 0 16px 0;
`;
const BlockedIconWrapper = styled.img.attrs({
    src: `${BlockedIcon}`,
})`
    height: 78px;
    width: 86.15px;
`;

const ReasonDescription = styled.div`
    align-items: center;
    text-align: center;
    display: flex;
    padding-bottom: 32px;
    font-size: 14px;
    font-weight: 400;
    color: ${colors.themeTextColor11};
`;
const BlockedText = styled.div`
    font-size: 20px;
    font-weight: 700;
    color: ${colors.bgBlue};
    margin: auto;
    justify-content: center;
    display: flex;
    padding-bottom: 8px;
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: auto;
    padding-top: 32px;
    padding-bottom: 12px;
`;
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: auto;
    padding-bottom: 40px;
`;

const StyledCancelIcon = styled(CancelIcon)`
    height: 40px;
    width: 40px;
`;
const CancelIconWrapper = styled.div`
    position: absolute;
    margin-top: -68px;
    right: 1px;
`;
export const BlockedWalletPopUp = ({ reason, src, left, position, showCancelIcon = true }) => {
    const [showBlockPopUp, setShowBlockPopUp] = useState(true);
    const history = useHistory();

    return (
        <Fragment>
            {showBlockPopUp && (
                <BlurPopUpBackground position={position} left={left}>
                    <WalletBlockedContainer>
                        {showCancelIcon && (
                            <CancelIconWrapper onClick={() => setShowBlockPopUp(false)}>
                                <StyledCancelIcon />
                            </CancelIconWrapper>
                        )}
                        <IconWrapper>
                            <BlockedIconWrapper src={src} />
                        </IconWrapper>
                        <BlockedText>Your wallet is blocked</BlockedText>
                        <ReasonDescription>
                            Due to {reason}, your wallet has been blocked. Please reach out to our support team to help
                            resolve this issue.
                        </ReasonDescription>
                        <ButtonWrapper>
                            <LightButton
                                width="176px"
                                height="38px"
                                weight="500"
                                color={colors.popup.cancelButton}
                                background={colors.border.primary}
                                onClick={() => {
                                    history.push('/help');
                                }}
                            >
                                Contact Support
                            </LightButton>
                        </ButtonWrapper>
                    </WalletBlockedContainer>
                </BlurPopUpBackground>
            )}
        </Fragment>
    );
};
