import React from 'react';

import { any } from 'prop-types';
import styled from 'styled-components';

import { RippleLink } from '../../../../../components/';
import { colors } from '../../../../../styles';
import { ListSampleProducts } from '../../components/listProducts';
import {
    ProductNameContainer,
    ProductName,
    ItemFlex,
    ProductIconContainer,
    Icon,
    LinkText,
    CategoryWrapper,
    CategoryFlex,
} from '../../styles';

const SpecificItem = styled(ItemFlex)`
    width: 100%;
    &:nth-of-type(3n + 1) {
        > div {
            &:first-child {
                > div {
                    &:first-child {
                        > div {
                            &:first-child {
                                background-color: #227eff;
                            }
                        }
                    }
                }
            }
        }
    }
    &:nth-of-type(3n + 2) {
        > div {
            &:first-child {
                > div {
                    &:first-child {
                        > div {
                            &:first-child {
                                background-color: #7e42f5;
                            }
                        }
                    }
                }
            }
        }
    }
    &:nth-of-type(3n + 3) {
        > div {
            &:first-child {
                > div {
                    &:first-child {
                        > div {
                            &:first-child {
                                background-color: #05944f;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const ProductCategoryItem = ({ data }) => {
    return (
        <SpecificItem>
            <CategoryFlex borderBottom={`1px solid ${colors.lightGray}`}>
                <CategoryWrapper bottom={'0px'}>
                    <ProductIconContainer radius={'4px 0 0 4px'}>
                        <Icon />
                    </ProductIconContainer>
                    <ProductNameContainer>
                        <ProductName>{data[0].categoryName}</ProductName>
                    </ProductNameContainer>
                </CategoryWrapper>
                <RippleLink to={`/actions/merchbuy/products/${data[0].categoryName}/${data[0].categoryId}`}>
                    <LinkText>See All</LinkText>
                </RippleLink>
            </CategoryFlex>
            <ListSampleProducts
                id={data[0].categoryId}
                name={data[0].categoryName}
                data={data}
                size={{ width: '103px', height: '98px' }}
                gap={'8px'}
                fromCategory={true}
            />
        </SpecificItem>
    );
};

ProductCategoryItem.propTypes = {
    data: any,
};
