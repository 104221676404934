import { sortCartItems } from '../utils/sortCartItems';

export const getProductInfoForMixPanel = (carts) => {
    let totalItemCount = 0;
    const productIds = [];
    const productNames = [];
    const categoryNames = [];
    const sortedItems = sortCartItems(carts);
    carts.map((x) => {
        totalItemCount += x?.quantity;
        productIds.push(x?.product?.productId);
        productNames.push(x?.product?.productName);
        categoryNames.push(x?.product?.categoryId);
        return null;
    });

    return { totalItemCount, categoryNames, productNames, productIds, sortedItems };
};
