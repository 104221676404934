import React, { Fragment, useEffect, useState } from 'react';

import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { InputBlock } from '../../../../containers/InputContainer';
import { merchbuyActions } from '../../../../redux/ducks/applications/merchbuy/actions';
import { fetchDeliveryDetails } from '../../../../redux/ducks/applications/my-shop/actions/customers';
import * as actions from '../../../../redux/ducks/applications/my-shop/actions/customers';
import { toTitleCase, upperCaseToTitleCase } from '../../../../utils/toTitleCase';
import ConstantInfo from '../../merchbuy/assets/constant-info.svg';
import { ReactComponent as YourAddressIcon } from '../../merchbuy/assets/your-address.svg';
import SimpleMap from '../components/google-map/simpleMap';
import { Space, FullAddressHolder, ShopAddressInfo, Divider, InnerTitle } from '../styles';

import { Loader, TopHeader, SelectBox, InputWithLabel, RippleButton } from './../../../../components';
import { colors } from './../../../../styles';
import DesktopBackgroundLayout from './../../../DesktopBackgroundLayout';
import { DeliveryAddressValidationSchema } from './DeliveryAddressValidationSchema';

const ShopAddressWrapper = styled.div`
    display: flex;
`;

const Padding = styled.div`
    padding: 0 1em;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.boldDark};
`;

const Subtitle = styled.div`
    font-size: 14px;
    line-height: 21px;
    color: ${colors.lightDark};
    font-weight: 400;
    margin-top: 6px;
`;

const InLineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const InfoText = styled.div`
    font-size: 10px;
    color: #5c6f7f;
    font-weight: 400;
    margin: 4px 0 16px 5px;
`;

const DeliveryAddress = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const listOfAvailableStatesAndLga = useSelector((state) => state.applications.merchbuy.availableStates);
    const lgas = useSelector((state) => state.applications.merchbuy.lga);
    const deliveryLocation = useSelector((state) => state.applications.myShop.deliveryDetails);

    const isLoading = useSelector((state) => state.applications.myShop.isLoading);
    const UserDeliveryLocation =
        localStorage.getItem('deliveryLocation') && JSON.parse(localStorage.getItem('deliveryLocation'));
    const { state: UserDeliveryState } = UserDeliveryLocation || {};
    const [isSelected, setIsSelected] = useState(false);
    const [coordinates, setCoordinates] = useState({});
    const [cities, setCities] = useState([]);
    const [selectedLga, setSelectedLga] = useState('');
    const [lgaChanged, setLgaChanged] = useState(false);

    const getCities = async () => {
        if (lgaChanged) {
            const response = await dispatch(merchbuyActions.getAllCities(UserDeliveryState, selectedLga));
            response && setCities(response?.data?.content);
        } else {
            const res = await dispatch(merchbuyActions.getAllCities(UserDeliveryState, deliveryLocation?.lga));
            res && setCities(res?.data?.content);
        }
    };

    useEffect(() => {
        getCities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [UserDeliveryState, selectedLga]);

    useEffect(() => {
        setCoordinates(deliveryLocation?.cordinates || { longitude: 0, latitude: 0 });
    }, [deliveryLocation]);

    useEffect(() => {
        dispatch(fetchDeliveryDetails());
    }, [dispatch]);

    const onSubmit = async (values, { setErrors }) => {
        const payload = {
            deliveryInstruction: values.deliveryInstruction || null,
            phoneNumber: values.msisdn,
            receiverName: deliveryLocation?.receiverName,
            firstName: values.firstName,
            lastName: values.lastName,
            state: values.state,
            city: values.city,
            lga: values.lga,
            address: values.shopAddress,
            shopNumber: values.shopNumber,
            cordinates: {
                longitude: coordinates.longitude.toString(),
                latitude: coordinates.latitude.toString(),
            },
        };

        const res = await dispatch(
            actions.updateMerchantDelivery(values.userId, payload, deliveryLocation.contactType),
        );
        res && history.goBack();
    };

    const initialFormValues = {
        firstName: deliveryLocation?.firstName || '',
        lastName: deliveryLocation?.lastName || '',
        middleName: '',
        country: 'NG',
        state: UserDeliveryState || '',
        lga:
            UserDeliveryState === deliveryLocation?.state
                ? deliveryLocation?.lga || deliveryLocation?.localGovt || ''
                : '',
        city:
            UserDeliveryState === deliveryLocation?.state
                ? (deliveryLocation?.city && deliveryLocation?.city.toUpperCase()) || ''
                : '',
        cordinates: deliveryLocation?.cordinates || '',
        msisdn: deliveryLocation?.phoneNumber || '',
        phoneNumber: deliveryLocation?.phoneNumber || '',
        shopAddress: toTitleCase(deliveryLocation?.address) || toTitleCase(deliveryLocation?.shopAddress) || '',
        deliveryInstruction: deliveryLocation?.deliveryInstruction || deliveryLocation?.deliveryInstructions || '',
        shopNumber: deliveryLocation?.shopNumber || '',
        userId: deliveryLocation?.userId || '',
    };

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title="Update Address" backLink="/actions/merchbuy" withCancelIcon />
                <Space height="90px" />
                <Padding>
                    <Title>Update Your Address</Title>
                    <Subtitle>Kindly update your delivery address.</Subtitle>
                </Padding>
                <Formik
                    initialValues={initialFormValues}
                    validationSchema={DeliveryAddressValidationSchema}
                    onSubmit={onSubmit}
                >
                    {({ handleChange, errors, values, touched, setFieldValue, initialValues }) => {
                        return (
                            <Form>
                                <InputBlock>
                                    <Padding>
                                        <InputWithLabel
                                            label={'State'}
                                            type={'text'}
                                            value={values.state}
                                            placeholder={'State'}
                                            textColor={colors.themeTextColor11}
                                            bottom="8px"
                                            top="8px"
                                            size="16px"
                                            weight="500"
                                            height="56px"
                                            name="state"
                                            disabled
                                            noClearButton
                                            options={listOfAvailableStatesAndLga}
                                            handleChange={(selected) => {
                                                setFieldValue('state', selected.label);
                                                setFieldValue('lga', '');
                                                setFieldValue('city', '');
                                            }}
                                            valid={`${touched.shopAddress && !errors.shopAddress}`}
                                            errors={touched && touched.shopAddress && errors && errors.shopAddress}
                                            setFieldValue={setFieldValue}
                                            initialValues={initialValues}
                                        />
                                        <InLineContainer>
                                            <img src={ConstantInfo} alt="" style={{ margin: '0px 0 16px 16px' }} />
                                            <InfoText>
                                                Delivery state is fixed to your current shopping location
                                            </InfoText>
                                        </InLineContainer>
                                        <SelectBox
                                            placeholder={'local government'}
                                            value={values.lga}
                                            top="8px"
                                            title="Select L.G.A"
                                            height="90%"
                                            isSelected={isSelected}
                                            setIsSelected={setIsSelected}
                                            options={lgas}
                                            handleChange={(selected) => {
                                                setFieldValue('lga', selected.name);
                                                setSelectedLga(selected.name);
                                                setLgaChanged(true);
                                            }}
                                            valid={`${!touched.lga && !errors.lga}`}
                                            errors={touched && touched.lga && errors && errors.lga}
                                        />
                                        <SelectBox
                                            name={'city'}
                                            placeholder={'Select City'}
                                            value={upperCaseToTitleCase(values.city)}
                                            top="8px"
                                            height="90%"
                                            title="Select City"
                                            isSelected={isSelected}
                                            setIsSelected={setIsSelected}
                                            options={cities}
                                            handleChange={handleChange}
                                            valid={`${!touched.city && !errors.city}`}
                                            errors={touched && touched.city && errors && errors.city}
                                        />
                                        <InputWithLabel
                                            label={'Street Name'}
                                            type={'text'}
                                            value={values.shopAddress}
                                            placeholder={'Street Name'}
                                            bottom="8px"
                                            top="8px"
                                            height="56px"
                                            name="shopAddress"
                                            noClearButton
                                            valid={`${touched.shopAddress && !errors.shopAddress}`}
                                            errors={touched && touched.shopAddress && errors && errors.shopAddress}
                                            setFieldValue={setFieldValue}
                                            initialValues={initialValues}
                                        />
                                    </Padding>

                                    <FullAddressHolder>
                                        <ShopAddressWrapper>
                                            <YourAddressIcon />
                                            <ShopAddressInfo left="6px">Your Shop address</ShopAddressInfo>
                                        </ShopAddressWrapper>
                                        <Divider />
                                        <ShopAddressInfo>
                                            {`
                                                ${values.shopNumber}
                                                ${`${upperCaseToTitleCase(values.shopAddress)} `}
                                                ${`${values.city.toLowerCase()}, `}
                                                ${`${values.lga.toLowerCase()} LGA,`}
                                                ${`${values.state} state. `}
                                            `}
                                        </ShopAddressInfo>
                                    </FullAddressHolder>
                                    <InnerTitle>Drag the marker to the exact location</InnerTitle>
                                    <Padding>
                                        <SimpleMap
                                            coordinates={coordinates}
                                            setCoordinates={setCoordinates}
                                            address={`${values.shopNumber} ${values.shopAddress} ${values.city} ${values.lga} ${values.state}`}
                                        />
                                    </Padding>
                                    <Padding>
                                        <RippleButton type="submit" bottom="32px">
                                            Update
                                        </RippleButton>
                                    </Padding>
                                </InputBlock>
                            </Form>
                        );
                    }}
                </Formik>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default DeliveryAddress;
