import React, { useState, useEffect, useCallback, Fragment } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { RippleButton, TopHeader } from '../../../../components';
import {
    getCountryByName,
    getStateByCountryId,
    postStores,
} from '../../../../redux/ducks/applications/store-front/actions/store';
import { colors } from '../../../../styles';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';

import DeliveryFeeModal from './deliveryFeeModal';
import FirstStep from './firstStep';
import { ValidationSchema } from './formValidation';
import PaymentModeModal from './paymentModeModal';
import SecondStep from './secondStep';
import SetupStoreFrontFailure from './storeFrontFailure';
import SetupStoreFrontSuccess from './storeFrontSuccess';

const TabWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 67px;
    height: 56px;
    background: ${colors.white};
    padding: 0 16px;
    cursor: pointer;
`;
const TabContainer = styled.div`
    width: 100%;
    height: 40px;
    background: ${({ active }) => (active ? colors.deepBlue : colors.white)};
    border-radius: 40px;
    display: flex;
    align-items: center;
    margin-right: ${({ index }) => index === 0 && '10px'};
`;

const TabStepWrapper = styled.div`
    width: 24.61px;
    height: 24px;
    background: ${({ active }) => (active ? colors.bgBlue : colors.lightGray)};
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.white};
    margin-left: 8.2px;
`;

const TabStep = styled.div`
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: ${({ active }) => (active ? colors.white : colors.boldDark)};
`;

const TabText = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${({ active }) => (active ? colors.white : colors.boldDark)};
    margin-left: 8.2px;
`;

const FormWrapper = styled.div`
    padding: 24px 16px 33px 16px;
    max-height: 85vh;
    overflow-y: scroll;
`;

const FormTitle = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.boldDark};
    margin-top: ${({ chooseAddress }) => chooseAddress && '70px'};
`;

const FormDesc = styled.div`
    line-height: 21px;
    font-size: 14px;
    color: ${colors.lightDark};
    margin: 8px 0 24px 0;
`;

const SetupStoreFront = () => {
    const dispatch = useDispatch();
    const country = useSelector((state) => state.applications.storeFront.country);
    const [buttonText, setButtonText] = useState('Proceed');
    const [showSuccessPage, setShowSuccessPage] = useState(false);
    const [showFailurePage, setShowFailurePage] = useState(false);
    const [steps] = useState(['Store Settings', 'Delivery Settings']);
    const [activeStepTitle] = useState(['Setup your store', 'Delivery Fees']);
    const [activeStepDescription] = useState([
        'Provide the required details and setup your store',
        'Set up your delivery fees for locations you deliver to ',
    ]);
    const [activeStep, setActiveStep] = useState(0);
    const [{ content, wordCount }, setContent] = useState({
        content: '',
        wordCount: 0,
    });
    const [data, setData] = useState({
        name: '',
        description: content,
        link: '',
        businessEmail: '',
        whatsappNumber: '',
        twitterUrl: '',
        facebookUrl: '',
        deliveryFees: [],
        paymentModes: [],
        price: '',
    });
    const [openDelivery, setOpenDelivery] = useState(false);
    const [selectedDelivery, setSelectedDelivery] = useState({});
    const [openPayment, setOpenPayment] = useState(false);
    const [chooseAddress, setChooseAddress] = useState(false);
    const limit = 20;

    const setFormattedContent = useCallback(
        (text) => {
            let words = text.split(' ').filter(Boolean);
            if (words.length > limit) {
                setContent({
                    content: words.slice(0, limit).join(' '),
                    wordCount: limit,
                });
            } else {
                setContent({ content: text, wordCount: words.length });
            }
        },
        [limit, setContent],
    );

    useEffect(() => {
        setFormattedContent(content);
    }, [content, setFormattedContent]);

    const handleButtonText = () => {
        if (activeStep === 1) {
            return;
        } else {
            setActiveStep(activeStep + 1);
            setButtonText('Create Store');
        }
    };

    const handleDeliveryFees = (deliveryFee) => {
        setData({ ...data, deliveryFees: [...data.deliveryFees, deliveryFee] });
    };

    const handleEditDeliveryFees = (updatedDelivery) => {
        const delivery = [...data.deliveryFees];
        const selectedDeliveryIndex = delivery.findIndex((data) => data.id === selectedDelivery.id);
        delivery[selectedDeliveryIndex] = updatedDelivery;
        setData({ ...data, deliveryFees: delivery });
    };

    const handleDeleteDeliveryFees = (id) => {
        const delivery = [...data.deliveryFees];
        const newDeliveries = delivery.filter((data) => data.id !== id);
        setData({ ...data, deliveryFees: newDeliveries });
    };

    const handlePaymentModes = (paymentModes) => {
        setData({ ...data, paymentModes });
    };

    const isDisable = (values) => {
        const conditions = values.name && content && values.whatsappNumber && values.businessEmail;
        const secondConditions = data.deliveryFees.length > 0 && data.paymentModes.length > 0;
        return activeStep === 0 ? !conditions : !secondConditions;
    };

    const handleSubmit = (data) => {
        data.link = data.name.split(' ').join('-');
        data.description = content;
        setButtonText('Creating...');
        dispatch(postStores(data)).then((res) => {
            if (res) {
                setShowSuccessPage(!showSuccessPage);
            } else {
                setButtonText('Create Store');
                setShowFailurePage(!showFailurePage);
            }
        });
    };

    useEffect(() => {
        dispatch(getCountryByName('Nigeria'));
    }, [dispatch]);

    useEffect(() => {
        if (country[0]?.id) {
            dispatch(getStateByCountryId(country[0]?.id));
        }
    }, [country, dispatch]);

    return (
        <Fragment>
            {showSuccessPage && (
                <SetupStoreFrontSuccess open={showSuccessPage} setOpen={() => setShowSuccessPage(!showSuccessPage)} />
            )}
            {showFailurePage && (
                <SetupStoreFrontFailure open={showFailurePage} setOpen={() => setShowFailurePage(!showFailurePage)} />
            )}
            <DesktopBackgroundLayout bgColor="#f7f8f9">
                {!showFailurePage && !showSuccessPage && (
                    <TopHeader
                        backgroundColor={openDelivery || openPayment ? 'transparent' : null}
                        noArrow={openDelivery || openPayment ? true : false}
                        noBorderBottom
                        title={chooseAddress ? 'Add Delivery Fee' : 'Setup Storefront'}
                        backAction={chooseAddress ? () => setChooseAddress(false) : false}
                        backLink={chooseAddress ? '#' : false}
                    />
                )}
                {!chooseAddress && (
                    <TabWrapper>
                        {steps.map((step, index) => (
                            <TabContainer
                                index={index}
                                key={index}
                                active={index === activeStep}
                                onClick={() => {
                                    if (activeStep === 1) {
                                        setActiveStep(0);
                                        setButtonText('Proceed');
                                    }
                                }}
                            >
                                <TabStepWrapper active={index === activeStep}>
                                    <TabStep active={index === activeStep}>{index + 1}</TabStep>
                                </TabStepWrapper>
                                <TabText active={index === activeStep}>{step}</TabText>
                            </TabContainer>
                        ))}
                    </TabWrapper>
                )}
                <FormWrapper>
                    <FormTitle chooseAddress={chooseAddress}>{activeStepTitle[activeStep]}</FormTitle>

                    <FormDesc>{chooseAddress ? 'Add location and fees' : activeStepDescription[activeStep]}</FormDesc>

                    <Formik
                        initialValues={data}
                        validationSchema={ValidationSchema}
                        onSubmit={(values) => {
                            handleSubmit({
                                ...values,
                                deliveryFees: data.deliveryFees,
                                paymentModes: data.paymentModes.map((data) => data[1]),
                            });
                        }}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                {activeStep === 0 ? (
                                    <FirstStep
                                        name={values.name}
                                        content={content}
                                        wordCount={wordCount}
                                        limit={limit}
                                        setFormattedContent={setFormattedContent}
                                    />
                                ) : (
                                    <SecondStep
                                        handleEditDeliveryFees={handleEditDeliveryFees}
                                        handleDeleteDeliveryFees={handleDeleteDeliveryFees}
                                        deliveries={data.deliveryFees}
                                        payments={data.paymentModes}
                                        setOpenDelivery={setOpenDelivery}
                                        openDelivery={openDelivery}
                                        setSelectedDelivery={setSelectedDelivery}
                                        selectedDelivery={selectedDelivery}
                                        setOpenPayment={setOpenPayment}
                                        openAddress={() => setChooseAddress(!chooseAddress)}
                                        chooseAddress={chooseAddress}
                                        price={values.price}
                                        data={data}
                                        setData={setData}
                                        handlePrice={() => {
                                            setFieldValue('price', '');
                                        }}
                                    />
                                )}
                                {!chooseAddress && (
                                    <RippleButton
                                        type="submit"
                                        top={'74px'}
                                        height="56px"
                                        disabled={isDisable(values)}
                                        onClick={() =>
                                            activeStep === 0
                                                ? handleButtonText()
                                                : handleSubmit({
                                                      ...values,
                                                      deliveryFees: data.deliveryFees.map((data) => {
                                                          return {
                                                              startPointId: data.startPointId[1],
                                                              endPointId: data.endPointId[1],
                                                              stateId: data.stateId,
                                                              id: data.id,
                                                              fee: data.fee,
                                                              countryId: data.countryId,
                                                          };
                                                      }),
                                                      paymentModes: data.paymentModes.map((data) => data[1]),
                                                  })
                                        }
                                    >
                                        {buttonText}
                                    </RippleButton>
                                )}
                            </Form>
                        )}
                    </Formik>
                </FormWrapper>
                {openDelivery && (
                    <DeliveryFeeModal
                        handleDeliveryFees={handleDeliveryFees}
                        cancel={() => {
                            setOpenDelivery(false);
                            setSelectedDelivery({});
                        }}
                        open={openDelivery}
                        deliveries={data.deliveryFees}
                        handleEditDeliveryFees={handleEditDeliveryFees}
                        selectedDelivery={selectedDelivery}
                        setSelectedDelivery={setSelectedDelivery}
                    />
                )}
                {openPayment && (
                    <PaymentModeModal
                        handlePaymentModes={handlePaymentModes}
                        cancel={() => {
                            setOpenPayment(false);
                        }}
                        open={openPayment}
                    />
                )}
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default SetupStoreFront;
