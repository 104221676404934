import React, { Fragment, useState } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as ArrowDownIcon } from '../../../../assets/arrow_down.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/arrow_up.svg';
import { CheckPopupDialog, TopHeader } from '../../../../components';
import ItemsFilters from '../../../../components/filters/items-filters';
import { List } from '../../../../containers/ListContainer';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { colors } from '../../../../styles';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import TransactionCard from '../../../home/transactions/transaction-card';
import { ReactComponent as ClosedSvg } from '../assets/closed_icon.svg';
import { ReactComponent as InfoSvg } from '../assets/Info.svg';
import { ReactComponent as PendingSvg } from '../assets/pending_icon.svg';
import CreatePaymentButton from '../create-link/create-payment-botton';

const CreatedLinkText = styled.p`
    font-size: 14px;
    color: ${colors.themeTextColor11};
    font-weight: 400;
    margin: 0px;
    margin-bottom: ${({ bottom }) => bottom || '24px'};
    margin-top: ${({ top }) => top || null};
`;

const LinksDetailsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.themeTextColor11};
    margin: 16px 0;
    gap: 10px;
`;

const AllPaymentLink = () => {
    const transactions = useSelector((state) => state.account.transactions.paymentTransactionsList);

    const [filteredTransactions, setFilteredTransactions] = useState(transactions);
    const [type, setType] = useState('Most Recent');
    const [sortBy, setSortBy] = useState('All Statuses');
    const [title, setTitle] = useState('All Created');
    const [createdLinkText, setCreatedLinkText] = useState(`You have created ${transactions?.length} links`);
    const [openSortByOptions, setOpenSortByOptions] = useState(false);
    const [openFilterOptions, setOpenFilterOptions] = useState(false);

    const filterTransactions = (type = '', sortBy = '') => {
        if (type) {
            setType(type);
            setOpenFilterOptions(false);
            if (type === 'Most Recent') {
                setFilteredTransactions(transactions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
            } else if (type === 'Oldest') {
                setFilteredTransactions(transactions.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)));
            } else if (type === 'Low to High') {
                setFilteredTransactions(transactions.sort((a, b) => a.amount - b.amount));
            } else if (type === 'High to Low') {
                setFilteredTransactions(transactions.sort((a, b) => b.amount - a.amount));
            }
            return;
        } else if (sortBy) {
            setTitle(sortBy);
            setSortBy(sortBy);
            setOpenSortByOptions(false);
            setCreatedLinkText(
                `You have ${filteredTransactions.length} ${sortBy === 'Closed' ? 'Completed' : 'Open'} Links`,
            );
            return setFilteredTransactions(transactions.filter((x) => x.status === sortBy.toUpperCase()));
        }
    };

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader backLink="/actions/payment-links" title={`${title} Links`} />
                <ScreenContainer color={colors.notificationBg}>
                    {sortBy === 'All Statuses' ? (
                        <ItemsFilters
                            sortText={sortBy}
                            filterText={type}
                            sortAction={() => setOpenSortByOptions(true)}
                            filterAction={() => setOpenFilterOptions(true)}
                            noBorderRight
                        />
                    ) : (
                        <LinksDetailsContainer>
                            <InfoSvg />
                            <CreatedLinkText>
                                {`These are links ${
                                    sortBy === 'Closed' ? 'that have received' : 'yet to receive'
                                } payments from the recipient`}
                            </CreatedLinkText>
                        </LinksDetailsContainer>
                    )}

                    <CreatedLinkText top={'24px'} bottom={'16px'}>
                        {createdLinkText}
                    </CreatedLinkText>
                    <List noBorderBottom fullScreen>
                        {filteredTransactions?.map((transaction, index) => (
                            <TransactionCard
                                transaction={transaction}
                                key={index}
                                pathname={'/actions/payment-links/details'}
                                background={colors.white}
                                containerWidth={'90%'}
                            />
                        ))}
                    </List>
                    <CreatePaymentButton />
                </ScreenContainer>
                <CheckPopupDialog
                    open={openFilterOptions}
                    title={'Sort By'}
                    cancel={() => setOpenFilterOptions(!openFilterOptions)}
                    headerPadding={'16px 0 24px'}
                    size={'14px'}
                    buttonWeight={'500'}
                    color={colors.themeTextColor1}
                    buttonColor={colors.themeTextColor11}
                    contentPadding="0 16px"
                    labelItemPadding="14px 0"
                    buttonHeight={'56px'}
                    items={[
                        {
                            Icon: ArrowUpIcon,
                            title: 'Most Recent',
                            click: () => filterTransactions('Most Recent', null),
                        },
                        {
                            Icon: ArrowDownIcon,
                            title: 'Oldest',
                            click: () => filterTransactions('Oldest', null),
                        },
                        {
                            Icon: ArrowUpIcon,
                            title: 'Amount: High to Low',
                            click: () => filterTransactions('High to Low', null),
                        },
                        {
                            Icon: ArrowUpIcon,
                            title: 'Amount: Low to High',
                            click: () => filterTransactions('Low to High', null),
                        },
                    ]}
                />
                <CheckPopupDialog
                    open={openSortByOptions}
                    title={'Filter'}
                    color={colors.themeTextColor1}
                    cancel={() => setOpenSortByOptions(!openSortByOptions)}
                    headerPadding={'16px 0 24px'}
                    size={'14px'}
                    buttonColor={colors.themeTextColor11}
                    buttonWeight={'500'}
                    contentPadding="0 16px"
                    labelItemPadding="14px 0"
                    buttonHeight={'56px'}
                    items={[
                        {
                            Icon: PendingSvg,
                            title: 'Pending Payment Links',
                            click: () => filterTransactions(null, 'Pending'),
                        },
                        {
                            Icon: ClosedSvg,
                            title: 'Closed Payment Links',
                            click: () => filterTransactions(null, 'Closed'),
                        },
                    ]}
                />
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default AllPaymentLink;
