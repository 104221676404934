import React from 'react';

import { addHeaderToRoutes, addNewHeaderToRoutes } from '../../hoc/addHeaderToRoutes';
import {
    MyShop,
    SetupShop,
    ShopInventory,
    ProductDetails,
    EditShop,
    SaleDetails,
    OrdersList,
    OrderDetails,
    CustomersList,
    AddCustomer,
    EditCustomerDetails,
    CustomerSales,
    AddSale,
    AddProduct,
    PaymentReminder,
    PaymentReminderSuccess,
    ShopInventoryEdit,
    ProductOptions,
    AddSingleProductStep1,
    AddSingleProductStep2,
    EditSingleProduct,
    SingleProductSuccess,
    Reports,
    SaleReceipt,
    EditSingleIncompleteProduct,
    UpdatePayment,
    ListDebtors,
    DebtorInfo,
    SalesList,
    VerifyStore,
} from '../../views/actions/my-shop';
import AddCustomerSuccess from '../../views/actions/my-shop/customers/add-customer/success';

const myShopRoutes = [
    {
        path: '/actions/shop_setup',
        exact: true,
        main: () => <SetupShop />,
        public: false,
    },
    {
        path: '/actions/shop_inventory_edit',
        exact: true,
        main: () => <ShopInventoryEdit />,
        public: false,
    },
    {
        path: '/actions/shop_products_add',
        exact: true,
        main: () => <AddProduct />,
        public: false,
    },
    {
        path: '/actions/shop_order_details',
        exact: true,
        main: () => <OrderDetails />,
        public: false,
    },
    {
        path: '/actions/shop_sale_request_payment',
        exact: true,
        main: () => <PaymentReminder />,
        public: false,
    },
    {
        path: '/actions/payment_reminder_success',
        exact: true,
        main: () => <PaymentReminderSuccess />,
        public: false,
    },
    {
        path: '/actions/shop_sale_receipt',
        exact: true,
        main: () => <SaleReceipt />,
        public: false,
    },
    {
        path: '/actions/verify_store',
        exact: true,
        main: () => <VerifyStore />,
        public: false,
    },
];

const myCustomerRoutes = [
    {
        path: '/actions/shop_customers',
        exact: true,
        main: () => <CustomersList />,
        public: false,
    },
    {
        path: '/actions/shop_customers_add',
        exact: true,
        main: () => <AddCustomer />,
        public: false,
    },
    {
        path: '/actions/shop_customers_add_success',
        exact: true,
        main: () => <AddCustomerSuccess />,
        public: false,
    },
    {
        path: '/actions/shop_customers_edit',
        exact: true,
        main: () => <EditCustomerDetails />,
        public: false,
    },
    {
        path: '/actions/shop_customers_sales',
        exact: true,
        main: () => <CustomerSales />,
        public: false,
    },
    {
        path: '/actions/reports',
        exact: true,
        main: () => <Reports />,
        public: false,
    },
    {
        path: '/actions/debtors',
        exact: true,
        main: () => <ListDebtors />,
        public: false,
    },
    {
        path: '/actions/debtors/:debtorId',
        exact: true,
        main: () => <DebtorInfo />,
        public: false,
    },
    {
        path: '/actions/debtors/:debtId/update',
        exact: true,
        main: () => <UpdatePayment />,
        public: false,
    },
];

const myNewShopRoutes = [];

export const myNewShopRoutesWithHeader = addNewHeaderToRoutes(myNewShopRoutes);

export const myShopRoutesWithHeader = [
    ...addHeaderToRoutes(myShopRoutes),
    {
        path: '/actions/shop',
        exact: true,
        main: () => <MyShop />,
        public: false,
    },
    {
        path: '/actions/shop_sales',
        exact: true,
        main: () => <SalesList />,
        public: false,
    },
    {
        path: '/actions/shop_orders',
        exact: true,
        main: () => <OrdersList />,
        public: false,
    },
    {
        path: '/actions/shop_inventory',
        exact: true,
        main: () => <ShopInventory />,
        public: false,
    },
    {
        path: '/actions/shop_edit',
        exact: true,
        main: () => <EditShop />,
        public: false,
    },
    {
        path: '/actions/shop_sale_details',
        exact: true,
        main: () => <SaleDetails />,
        public: false,
    },
    {
        path: '/actions/shop_product_details',
        exact: true,
        main: () => <ProductDetails />,
        public: false,
    },
    {
        path: '/actions/shop_products_options',
        exact: true,
        main: () => <ProductOptions />,
        public: false,
    },
    {
        path: '/actions/shop_sales_add',
        exact: true,
        main: () => <AddSale />,
        public: false,
    },
    {
        path: '/actions/shop_add_single_product',
        exact: true,
        main: () => <AddSingleProductStep1 />,
        public: false,
    },
    {
        path: '/actions/shop_add_single_product_next',
        exact: true,
        main: () => <AddSingleProductStep2 />,
        public: false,
    },
    {
        path: '/actions/shop_edit_single_product',
        exact: true,
        main: () => <EditSingleProduct />,
        public: false,
    },
    {
        path: '/actions/shop_single_product_success',
        exact: true,
        main: () => <SingleProductSuccess />,
        public: false,
    },
    {
        path: '/actions/shop_edit_single_incomplete_product',
        exact: true,
        main: () => <EditSingleIncompleteProduct />,
        public: false,
    },
];

export const myCustomerRoutesWithHeader = myCustomerRoutes;
