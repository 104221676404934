import React, { Fragment, useEffect, useState } from 'react';

import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Loader, TopHeader, PasswordGroup, ResendCodeTimer } from '../../../../components';
import { Message, Title } from '../../../../containers/MessageContainer';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { verifyDeviceOtp } from '../../../../redux/ducks/auth/check/actions';
import { tick, setExpired } from '../../../../redux/ducks/timer/actions';
import { colors } from '../../../../styles';
import { insertZero } from '../../../../utils/inputs/formatPhoneNumber';
import { getInputValues } from '../../../../utils/inputs/getInputValues';
import { ReactComponent as VerificationLogoSvg } from '../assets/verification.svg';

import { ValidationSchema } from './ValidationSchema';

const VerificationLogo = styled(VerificationLogoSvg)`
    margin-top: 59px;
    width: 100%;
`;

const OtpPage = ({ setShowOtpPage, setShowChannelPage, setResend }) => {
    const dispatch = useDispatch();
    const phoneNumber = useSelector((state) => state.user.msisdn);
    const isLoading = useSelector((state) => state.auth.password.isLoading);
    const expired = useSelector((state) => state.timer.expired);
    const counter = useSelector((state) => state.timer.counter);
    const [value, setValue] = useState('');
    const [code, setCode] = useState('');

    useEffect(() => {
        if (code && code.length === 6) {
            dispatch(verifyDeviceOtp(insertZero(phoneNumber), code));
        }
    }, [dispatch, code, phoneNumber]);

    const cancel = () => {
        setShowOtpPage(false);
        setShowChannelPage(true);
    };

    const handleResend = () => {
        setResend(true);
        cancel();
    };

    return isLoading ? (
        <Loader />
    ) : (
        <Fragment>
            <TopHeader backLink={'#'} backAction={cancel} noBorderBottom />
            <ScreenContainer>
                <VerificationLogo />
                <Title textAlign={'left'} top={'30px'} size="24px" weight="bold" color={colors.boldDark}>
                    Enter OTP
                </Title>
                <Message align={'left'} bottom={'24px'} top={'8px'} color={colors.lightDark}>
                    Kindly provide the 6- digit OTP was sent to your phone number{' '}
                    <strong style={{ color: colors.deepBlue }}>{insertZero(phoneNumber)}</strong>
                </Message>
                <Formik
                    initialValues={{
                        code: '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={(values, { resetForm }) => {
                        setTimeout(() => {
                            resetForm({ code: '' });
                        }, 1000);
                    }}
                >
                    {({ values, errors, valid, touched, setFieldValue }) => (
                        <Form>
                            <Message size={'10px'} align="left">
                                OTP
                            </Message>
                            <PasswordGroup
                                count={6}
                                startIndex={1}
                                type="number"
                                valid={valid}
                                name="code"
                                align={'center'}
                                errors={errors}
                                touched={touched}
                                enteredValue={value || ''}
                                handleChange={(event) => {
                                    setValue(event.target.value);
                                    setFieldValue('code', getInputValues('code'));
                                    setCode(getInputValues('code'));
                                }}
                            />
                            <ResendCodeTimer
                                counter={counter}
                                expired={expired}
                                showWhatsAppButton={false}
                                setExpired={(condition) => dispatch(setExpired(condition))}
                                tick={(counterValue) => dispatch(tick(counterValue))}
                                resendCode={handleResend}
                            />
                        </Form>
                    )}
                </Formik>
            </ScreenContainer>
        </Fragment>
    );
};

export default OtpPage;
