import { LOADING_COMPLAINTS, COMPLAINTS_LOADED, COMPLAINTS_LOAD_ERROR } from './constants';

const initialState = {
    isLoading: false,
    errorMsg: '',
};

export default function complaintsReducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_COMPLAINTS: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case COMPLAINTS_LOADED: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case COMPLAINTS_LOAD_ERROR: {
            return {
                ...state,
                isLoading: false,
                errorMsg: action.payload,
            };
        }

        default: {
            return state;
        }
    }
}
