import React, { Fragment, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { PageLogo, RippleButton, RippleLink } from '../../../components';
import { Message } from '../../../containers/MessageContainer';
import { FlexCenteredBlock, ScreenContainer } from '../../../containers/ScreenContainer';
import { colors } from '../../../gamesApp/constants';
import { merchbuyActions } from '../../../redux/ducks/applications/merchbuy/actions';
import { MerchBuyAccount, StoreFrontSettings } from '../../actions/merchbuy';
import { Title } from '../../actions/merchbuy/group-buy/styles';
import DesktopBackgroundLayout from '../../DesktopBackgroundLayout';
import Navigation from '../navigation';
import PendingActivation from '../pending-activation';

import { ReactComponent as AddIcon } from './assets/add-plus.svg';
import ShoppingIcon from './assets/shopper.svg';

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.border.bottom};
`;

const AddSection = styled.div`
    display: flex;
    align-items: center;
`;

const SellText = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.themeTextColor1};
`;

const AddText = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #227eff;
    margin-left: 7px;
`;

const StoreSection = styled.div`
    background-color: #f9fafc;
    height: 45px;
`;

const StoreText = styled.p`
    margin: 0;
    padding: 24px 0 8px 16px;
    color: #718596;
    font-size: 14px;
    font-weight: 400;
`;

const SellOnline = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const categories = useSelector((state) => state.applications.merchbuy.MerchbuyProductCategeries);
    const shops = useSelector((state) => state.applications.myShop.shops);
    const role = useSelector((state) => state.user.role);
    const agentState = useSelector((state) =>
        state.user.agentState ? state.user.agentState.toUpperCase() : 'PENDING',
    );

    const [showPendingActivationPage, setShowPendingActivationPage] = useState(false);

    useEffect(() => {
        if (role === 'ROLE_AGENT' && agentState !== 'APPROVED') {
            setShowPendingActivationPage(true);
        }
    }, [agentState, role]);

    useEffect(() => {
        if (!categories || categories.length === 0) {
            dispatch(merchbuyActions.getProductCategories());
        }
    }, [dispatch, categories]);

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                {showPendingActivationPage ? (
                    <PendingActivation pageTitle="Online Sales" />
                ) : (
                    <ScreenContainer paddingBottom="54px" top={'0'}>
                        {shops[0] && Object.keys(shops[0]).includes('branchId') ? (
                            <Fragment>
                                <HeaderContainer onClick={() => history.push('/actions/shop_products_options')}>
                                    <SellText>Online Sales</SellText>
                                    <AddSection>
                                        <AddIcon />
                                        <AddText>Add Product</AddText>
                                    </AddSection>
                                </HeaderContainer>
                                <MerchBuyAccount />
                                <StoreSection>
                                    <StoreText>Online Store setings</StoreText>
                                </StoreSection>
                                <StoreFrontSettings />
                            </Fragment>
                        ) : (
                            <FlexCenteredBlock top={'25px'}>
                                <PageLogo
                                    Icon={ShoppingIcon}
                                    width={'184px'}
                                    height={'184px'}
                                    iconHeight={'auto'}
                                    iconWidth={'auto'}
                                    margin={'24px auto'}
                                />
                                <Title>No shop setup</Title>
                                <Message bottom={'24px'} top={'8px'} align={'center'} padding={'0 1em'}>
                                    You’ve not setup any shop. Setup a shop to manage your inventory, track your sales
                                    and manage your orders.
                                </Message>
                                <RippleLink
                                    to="/actions/shop_setup"
                                    style={{ width: 'calc(100% - 32px)', marginBottom: '50px' }}
                                >
                                    <RippleButton>Setup a shop</RippleButton>
                                </RippleLink>
                            </FlexCenteredBlock>
                        )}
                    </ScreenContainer>
                )}
                <Navigation />
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default SellOnline;
