import React, { Fragment, useState, useEffect } from 'react';

import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { TopHeader, PageLogo, RippleButton, InputWithLabel, CameraInput, SelectBox } from '../../../../../components';
import { PageTitleWrapper, PageTitle, PageSubTitle } from '../../../../../containers/MessageContainer';
import {
    ScreenContainer,
    PageProgressLine,
    PageProgressWrap,
    PageProgressStep,
} from '../../../../../containers/ScreenContainer';
import { countriesMap } from '../../../../../data/countries';
import { localAreas } from '../../../../../data/countries/nigeria/localAreas';
import { states } from '../../../../../data/countries/nigeria/states';
import { getCategories } from '../../../../../redux/ducks/applications/my-shop/actions/shop';
import { checkExistingEmail, checkReferralCode } from '../../../../../redux/ducks/auth/check/actions';
import { saveUserData, sendUserInfoOnSignUp } from '../../../../../redux/ducks/user/actions';
import { findMapKeyByValues } from '../../../../../utils/inputs/findMapKeyByValues';
import { selectAreasByState } from '../../../../../utils/inputs/selectAreasByState';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';

import { AgentSignUpValidationSchema } from './AgentSignUpValidationSchema';
import UserIcon from './assets/user.svg';

const InputBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const AgentProfileCreation = () => {
    const dispatch = useDispatch();
    const email = useSelector((state) => state.user.email);
    const firstName = useSelector((state) => state.user.firstName);
    const lastName = useSelector((state) => state.user.lastName);
    const phoneNumber = useSelector((state) => state.user.msisdn);
    const userID = useSelector((state) => state.user.userId);
    const regionData = useSelector((state) => state.user.regionData);

    const [referralLinkCode] = useState(localStorage.getItem('referralCode') || '');

    let userData = localStorage.getItem('userData');
    const { /*email, firstName, lastName, msisdn: phoneNumber,*/ referralCode } =
        (userData && JSON.parse(userData)) || {};

    useEffect(() => {
        dispatch(getCategories());
    }, [dispatch]);

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Agent Account'}>
                    <PageProgressWrap>
                        <PageProgressStep>2</PageProgressStep>/6
                    </PageProgressWrap>
                    <PageProgressLine lineWidth={'30%'} />
                </TopHeader>

                <ScreenContainer>
                    <PageLogo
                        top={'26px'}
                        margin={'auto'}
                        borderRadius={'0'}
                        Icon={UserIcon}
                        iconWidth="72px"
                        iconHeight="72px"
                    />
                    <PageTitleWrapper mtop={'10px'} mbottom={'35px'}>
                        <PageTitle size={'16px'} top={'16px'} bottom={'4px'}>
                            Personal information
                        </PageTitle>
                        <PageSubTitle margin={'0'}>Let's get to know more about you</PageSubTitle>
                    </PageTitleWrapper>

                    <Formik
                        initialValues={{
                            firstName: firstName || '',
                            lastName: lastName || '',
                            email: email || '',
                            country: (regionData && findMapKeyByValues(countriesMap, regionData.country)) || '',
                            state: regionData ? regionData.state : '',
                            lga: regionData ? regionData.lga : '',
                            referralCode: referralCode
                                ? referralCode.trim()
                                : referralLinkCode
                                ? referralLinkCode.trim()
                                : '',
                        }}
                        enableReinitialize={true}
                        validationSchema={AgentSignUpValidationSchema}
                        onSubmit={(values, { setErrors }) => {
                            setTimeout(async () => {
                                if (!values.email) values.email = `${phoneNumber}@spaceso2o.com`;

                                const hasExistingEmail = await dispatch(checkExistingEmail(values.email));
                                if (hasExistingEmail === undefined) return;
                                if (hasExistingEmail) return setErrors({ email: 'Email already exists' });

                                if (values.referralCode) {
                                    const refCode = values.referralCode.replace(/\s/g, '');
                                    if (refCode && refCode.length < 11) {
                                        toast.error('Invalid referral code');
                                        return;
                                    }

                                    const refNumber = `0${refCode.substr(refCode.length - 10)}`;
                                    var isSameUser = phoneNumber.localeCompare(refNumber);
                                    if (isSameUser === 0) {
                                        toast.error('You can not use your referral code here');
                                        return;
                                    }

                                    const isValidReferral = await dispatch(checkReferralCode(refNumber));
                                    if (!isValidReferral) {
                                        return;
                                    }
                                }

                                const payload = {
                                    ...values,
                                    country: findMapKeyByValues(countriesMap, values.country),
                                };

                                dispatch(sendUserInfoOnSignUp(userID, payload)).then((data) => {
                                    if (data) dispatch(saveUserData(payload, '/user/create_agent_identity'));
                                });
                            }, 200);
                        }}
                    >
                        {({ errors, touched, setFieldValue, handleChange, values, initialValues }) => (
                            <Form>
                                <InputBlock>
                                    <InputWithLabel
                                        label={'First name'}
                                        type={'text'}
                                        value={values.firstName}
                                        placeholder={'First name'}
                                        name="firstName"
                                        noClearButton={false}
                                        valid={`${!touched.firstName && !errors.firstName}`}
                                        errors={touched && touched.firstName && errors && errors.firstName}
                                        setFieldValue={setFieldValue}
                                        initialValues={initialValues}
                                    />
                                    <InputWithLabel
                                        label={'Last name'}
                                        type={'text'}
                                        value={values.lastName}
                                        placeholder={'Last name'}
                                        name="lastName"
                                        noClearButton={false}
                                        valid={`${touched.lastName && !errors.lastName}`}
                                        errors={touched && touched.lastName && errors && errors.lastName}
                                        setFieldValue={setFieldValue}
                                        initialValues={initialValues}
                                    />
                                    <InputWithLabel
                                        label={'Email'}
                                        type={'email'}
                                        inputMode={'email'}
                                        value={values.email}
                                        placeholder={'Email'}
                                        name="email"
                                        bottom={'0px'}
                                        noClearButton={false}
                                        valid={`${!touched.email && !errors.email}`}
                                        errors={touched && touched.email && errors && errors.email}
                                        setFieldValue={setFieldValue}
                                        initialValues={initialValues}
                                    />

                                    <SelectBox
                                        name={'country'}
                                        placeholder={'Select country'}
                                        value={values && values.country}
                                        options={[{ value: 'NG', label: 'Nigeria' }]}
                                        handleChange={handleChange}
                                        valid={`${!touched.state && !errors.state}`}
                                        error={touched && touched.state && errors && errors.state}
                                    />

                                    <SelectBox
                                        placeholder={'State'}
                                        value={values && values.state}
                                        options={states}
                                        height="90%"
                                        handleChange={(selected) => {
                                            if (values.state !== selected.label) {
                                                setFieldValue('lga', '');
                                            }
                                            setFieldValue('state', selected.label);
                                        }}
                                        valid={`${!touched.state && !errors.state}`}
                                        error={touched && touched.state && errors && errors.state}
                                    />

                                    <SelectBox
                                        name={'lga'}
                                        placeholder={'Local Government Area'}
                                        value={values && values.lga}
                                        height="90%"
                                        options={values.state ? selectAreasByState(values.state, localAreas) : []}
                                        handleChange={handleChange}
                                        valid={`${!touched.lga && !errors.lga}`}
                                        error={touched && touched.lga && errors && errors.lga}
                                    />

                                    <CameraInput
                                        initialValues={initialValues.referralCode}
                                        saveReferralCode={setFieldValue}
                                    />
                                    <RippleButton
                                        type="submit"
                                        disabled={values.firstName === '' || values.lastName === ''}
                                    >
                                        Continue
                                    </RippleButton>
                                </InputBlock>
                            </Form>
                        )}
                    </Formik>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default AgentProfileCreation;
