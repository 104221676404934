import React, { useState } from 'react';

import styled, { keyframes } from 'styled-components';

import { TopHeader } from '../../../../../components/';
import { SearchInputWithCancel } from '../../../../../components/forms/input/search-input/SearchInput';
import { colors } from '../../../../../styles/variables';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import { Main, Container, SkeletalBox, SkeletalContainer } from '../../styles';

const moveAnimation = keyframes`
  0%   {left:0%;}
  100% {left:100%;}
`;

export const BoxShadow = styled.div`
    background-color: ${colors.white};
    height: ${({ height }) => height || '16px'};
    width: ${({ width }) => width || '14px'};
    opacity: 0.5;
    position: relative;
    animation-name: ${moveAnimation};
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
}
`;

const ProductCategorySkeleton = () => {
    const [searchValue, setSearchValue] = useState('');

    return (
        <DesktopBackgroundLayout>
            <TopHeader title={'Product Categories'} size={'14px'} weight={'400'} noBorderBottom />
            <Main>
                <Container top={'5px'}>
                    <SearchInputWithCancel
                        value={searchValue}
                        placeholder="Search for a product"
                        onChange={(e) => setSearchValue(e.target.value)}
                        onCancel={() => setSearchValue('')}
                    />
                    <SkeletalContainer top={'40px'}>
                        <SkeletalBox height={'14px'} width={'100px'}>
                            <BoxShadow></BoxShadow>
                        </SkeletalBox>
                        <SkeletalBox height={'14px'} width={'43px'}>
                            <BoxShadow></BoxShadow>
                        </SkeletalBox>
                    </SkeletalContainer>
                    <SkeletalBox height={'100px'} width={'100%'} radius={'8px'} top={'8px'} />
                    <SkeletalBox height={'14px'} width={'100px'} top={'8px'}>
                        <BoxShadow></BoxShadow>
                    </SkeletalBox>
                    <SkeletalBox height={'14px'} width={'43px'} top={'8px'}>
                        <BoxShadow></BoxShadow>
                    </SkeletalBox>
                    <SkeletalContainer top={'40px'}>
                        <SkeletalBox height={'14px'} width={'100px'}>
                            <BoxShadow></BoxShadow>
                        </SkeletalBox>
                        <SkeletalBox height={'14px'} width={'43px'}>
                            <BoxShadow></BoxShadow>
                        </SkeletalBox>
                    </SkeletalContainer>
                    <SkeletalBox height={'100px'} width={'100%'} radius={'8px'} top={'8px'} />
                    <SkeletalBox height={'14px'} width={'100px'} top={'8px'}>
                        <BoxShadow></BoxShadow>
                    </SkeletalBox>
                    <SkeletalBox height={'14px'} width={'43px'} top={'8px'}>
                        <BoxShadow></BoxShadow>
                    </SkeletalBox>
                    <SkeletalContainer top={'40px'}>
                        <SkeletalBox height={'14px'} width={'100px'}>
                            <BoxShadow></BoxShadow>
                        </SkeletalBox>
                        <SkeletalBox height={'14px'} width={'43px'}>
                            <BoxShadow></BoxShadow>
                        </SkeletalBox>
                    </SkeletalContainer>
                    <SkeletalBox height={'100px'} width={'100%'} radius={'8px'} top={'8px'} />
                    <SkeletalBox height={'14px'} width={'100px'} top={'8px'}>
                        <BoxShadow></BoxShadow>
                    </SkeletalBox>
                    <SkeletalBox height={'14px'} width={'43px'} top={'8px'}>
                        <BoxShadow></BoxShadow>
                    </SkeletalBox>
                </Container>
            </Main>
        </DesktopBackgroundLayout>
    );
};

export default ProductCategorySkeleton;
