import { getPriceFromBulkPrices } from './getPriceFromBulkPrices';

export const getCartItemsSubTotal = (cartItems) => {
    const total =
        cartItems?.length > 0 &&
        [...cartItems].reduce((accum, current) => {
            const price = getPriceFromBulkPrices(current);

            return Number(accum) + Number(price) * Number(current.quantity);
        }, 0);
    return total;
};
