import { USER_LOGOUT } from '../user/constants';

import {
    LOADING_ALL_COMPLAINTS,
    LOADING_COMPLAINTS_CATEGORIES,
    LOADING_FAQ,
    LOADING_FAQ_BY_CATEGORY,
    LOADING_SUPPORT_CONTACT,
    LOADING_COMMENTS,
    LOADING_COMPLAINT,
    LOADING,
} from './constants';

const initialState = {
    isLoading: false,
    faqCategories: [],
    faq: [],
    comments: [],
    complaints: [],
    complaintsCategories: [],
    contactNumber: {},
    specificComplaint: {},
};

export default function helpReducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_FAQ: {
            return {
                ...state,
                faqCategories: action.payload,
            };
        }

        case LOADING_FAQ_BY_CATEGORY: {
            return {
                ...state,
                faq: action.payload,
            };
        }

        case LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }

        case LOADING_SUPPORT_CONTACT: {
            return {
                ...state,
                contactNumber: action.payload,
            };
        }

        case LOADING_ALL_COMPLAINTS: {
            return {
                ...state,
                complaints: action.payload,
            };
        }

        case LOADING_COMPLAINTS_CATEGORIES: {
            return {
                ...state,
                complaintsCategories: action.payload,
            };
        }

        case LOADING_COMMENTS: {
            return {
                ...state,
                comments: action.payload,
            };
        }

        case LOADING_COMPLAINT: {
            return {
                ...state,
                specificComplaint: action.payload,
            };
        }

        case USER_LOGOUT: {
            return {
                isLoading: false,
                comments: [],
                complaints: [],
                complaintsCategories: [],
                specificComplaint: {},
                faqCategories: [],
                faq: [],
                contactNumber: {},
            };
        }

        default: {
            return state;
        }
    }
}
