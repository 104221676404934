import React, { Fragment, useEffect } from 'react';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { matchPath } from 'react-router-dom';
import styled from 'styled-components';
import useRouter from 'use-react-router';

import { TopHeader, Loader } from '../../../../components';
import { getOrdersByPhoneNumber, unsubscribe } from '../../../../redux/ducks/applications/store-front/actions/store';
import { colors } from '../../../../styles';
import { formatCurrency } from '../../../../utils/currency/parseBalance';
import { toTitleCase } from '../../../../utils/toTitleCase';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { ReactComponent as Empty } from '../assets/emptyorder.svg';
import { getStatusText, getCircleColor } from '../customers/utils';

const Body = styled.div`
    margin: 65px 16px 0 16px;
`;

const CartWrapper = styled.div`
    width: 100%;
    height: 118px;
    background: ${colors.white};
    box-shadow: 0px 15px 84px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 8px;
`;

const TopWrapper = styled.div`
    display: flex;
    width: 100%;
`;

const CartImage = styled.img`
    min-width: 44px;
    height: 44px;
    border-radius: 4px;
`;

const TitleWrapper = styled.div`
    margin-left: 15px;
    width: 100%;
`;

const Title = styled.div`
    font-size: 12px;
    line-height: 18px;
    color: ${colors.boldDark};
    opacity: 0.8;
    overflow-wrap: break-word;
    max-width: 100%;
`;

const Amount = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: ${colors.boldDark};
    margin-top: 4px;
`;

const BottomWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StatusWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Text = styled.div`
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: ${colors.themeSubColor1};
    opacity: 0.8;
    margin-left: 4px;

    .black {
        color: ${colors.boldDark};
    }
`;

const CartTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.boldDark};
    margin-bottom: 4px;
`;

const AmountWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Quantity = styled.div`
    font-size: 12px;
    line-height: 18px;
    color: ${colors.themeSubColor1};
`;

const Line = styled.div`
    height: 0px;
    border: 1px solid ${colors.gray4};
`;

const Status = styled.div`
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background: ${({ background }) => background || null};
`;

const EmptyDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: center;
    align-items: center;
`;

const EmptyText = styled.div`
    font-size: 14px;
    font-weight: 700;
    color: ${colors.themeTxtColor10};
    margin-top: 19px;
`;

const StoreFrontOrders = () => {
    const dispatch = useDispatch();
    const store = useSelector((state) => state.applications.storeFront.storeByLink);
    const orders = useSelector((state) => state.applications.storeFront.customersOrders);
    const loading = useSelector((state) => state.applications.storeFront.loadingStore);
    const { location } = useRouter();
    const { pathname } = location;
    const pattern = `(.*)?${'/actions/store_front/store_link/orders/:number'}`;
    const match = matchPath(pathname, { path: pattern }) || {};

    const units = (x) => {
        function amount(item) {
            return item.quantityOrdered;
        }

        function sum(prev, next) {
            return prev + next;
        }

        return x?.productsOrdered?.map(amount).reduce(sum);
    };

    useEffect(() => {
        dispatch(getOrdersByPhoneNumber(match.params.number));
    }, [dispatch, match.params.number]);

    useEffect(() => {
        return () => {
            dispatch(unsubscribe());
        };
    }, [dispatch]);

    if (loading) return <Loader />;

    return (
        <Fragment>
            {!orders || orders.length === 0 ? (
                <DesktopBackgroundLayout>
                    <TopHeader noTitle noBorderBottom />
                    <EmptyDiv>
                        <Empty />
                        <EmptyText>You don’t have any orders at the moment..</EmptyText>
                    </EmptyDiv>
                </DesktopBackgroundLayout>
            ) : (
                <DesktopBackgroundLayout fullHeight bgColor="#F9FAFC">
                    <TopHeader
                        withCancelIcon={true}
                        noBorderBottom
                        title="My Orders"
                        backLink={`/actions/store_front/${store?.link}`}
                    />
                    <Body>
                        <CartTitle>{toTitleCase(store?.name)}</CartTitle>
                        {orders?.map((cart, index) => (
                            <CartWrapper key={index}>
                                <TopWrapper>
                                    <CartImage
                                        src={
                                            cart?.productsOrdered[0]?.storeProductImageList[
                                                cart?.productsOrdered[0]?.storeProductImageList.length - 3
                                            ]?.imageUrl
                                        }
                                    />
                                    <TitleWrapper>
                                        <Title>{cart?.productsOrdered?.map((x) => `${x.name}, `)}</Title>
                                        <AmountWrapper>
                                            <Amount>{formatCurrency(cart.totalCost)}</Amount>
                                            <Quantity>X{units(cart)}</Quantity>
                                        </AmountWrapper>
                                    </TitleWrapper>
                                </TopWrapper>
                                <Line />
                                <BottomWrapper>
                                    <StatusWrapper>
                                        <Status background={getCircleColor(cart.status)} />
                                        <Text>
                                            {toTitleCase(getStatusText(cart?.status))}:{' '}
                                            <span className="black">{cart?.productsOrdered?.length} unit(s)</span> on{' '}
                                            <span className="black">
                                                {moment(
                                                    new Date(cart?.updatedAt ? cart?.updatedAt : cart?.createdAt),
                                                ).format('D-MM-YY')}
                                            </span>
                                        </Text>
                                    </StatusWrapper>
                                </BottomWrapper>
                            </CartWrapper>
                        ))}
                    </Body>
                </DesktopBackgroundLayout>
            )}
        </Fragment>
    );
};

export default StoreFrontOrders;
