import React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { RippleButton } from '../../../../components';
import { colors } from '../../../../styles';
import History from '../../../../utils/History';
import { ReactComponent as SuccessIcon } from '../assets/success.svg';
import StoreFrontHeader from '../header';

const Wrapper = styled.div`
    margin: 50px 16px 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const SuccessText = styled.div`
    margin-top: 24px;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: ${colors.deepBlue};
`;

const SuccessDesc = styled.div`
    margin-top: 9px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #2e4457;
    width: 250px;
`;
const Success = () => {
    const store = useSelector((state) => state.applications.storeFront.storeByLink);

    return (
        <>
            <StoreFrontHeader showMenu noNumber />
            <Wrapper>
                <SuccessIcon />
                <SuccessText>Order Successful</SuccessText>
                <SuccessDesc>
                    You successfully placed an order! You will receive a call once the delivery agent gets to your
                    address.
                </SuccessDesc>
                <RippleButton
                    onClick={() => {
                        History.push(`/actions/store_front/${store.link}`);
                    }}
                    height="56px"
                    top="62px"
                >
                    Continue Shopping
                </RippleButton>
            </Wrapper>
        </>
    );
};

export default Success;
