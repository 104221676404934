import { colors } from '../../styles';

export const getStatusColor = (status) => {
    switch (status) {
        case 'UNVERIFIED':
        case 'FAILED':
            return '#FFC3BD';
        case 'VERIFIED':
            return colors.lightGreen;
        case 'PENDING':
            return colors.lightYellowBg;
        default:
            return null;
    }
};

export const getTextColor = (status) => {
    switch (status) {
        case 'UNVERIFIED':
        case 'FAILED':
            return colors.textRed;
        case 'VERIFIED':
            return colors.textGreen;
        case 'PENDING':
            return colors.textYellow;
        default:
            return null;
    }
};
