import React from 'react';

import { any, string } from 'prop-types';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { ReactComponent as CurvedIcon } from '../../assets/curved.svg';
import FallbackProductImage from '../../assets/productImage.svg';
import {
    AddGridBox,
    AddGridContainer,
    SeeAllBoxWrapper,
    SeeAllBox,
    LinkText,
    ProductImage,
    ListText,
    ButtonWrapper,
} from '../../styles';
import { Product } from '../productItem';

export const ListProducts = ({ data, size, left, searchTerm, setScroll, showStock }) => {
    return (
        <AddGridBox width={size.width}>
            {data &&
                data.map((item, index) => (
                    <Product
                        key={index}
                        gridWidth={size.width}
                        data={item}
                        size={size}
                        left={left}
                        searchTerm={searchTerm}
                        setScroll={setScroll}
                        showStock={showStock}
                    />
                ))}
        </AddGridBox>
    );
};

export const SearchListProducts = ({
    data,
    size,
    left,
    searchTerm,
    setShowCartPopup,
    setSelectedProd,
    setDoAnimate,
    justifyContent,
    showNumber,
    selectedIndex,
    setSelectedIndex,
    addToCartAfterSearch,
    initiateAddToCart,
    setScroll,
    notifyCustomer,
    showStock,
    findProd,
    userEducate,
    setUserEducate,
    setCategoryEducation,
}) => {
    const clickFunction = (selectedIndex, index, item) => {
        if (selectedIndex === index) return 'Loading...';
        return 'Add to cart';
    };
    const handleClose = async (index) => {
        const click = await clickFunction(selectedIndex, index, data[index]);
        const { __queryID } = data[index];
        setSelectedProd(data[index]);

        if (click === 'Add to cart') {
            setSelectedIndex(index);
            if (__queryID) addToCartAfterSearch(data[index]);
            initiateAddToCart(data[index]);
            setTimeout(() => {
                setShowCartPopup(true);
                setDoAnimate(false);
                setSelectedIndex(null);
            }, 300);
        }
    };
    return (
        <div>
            {!showNumber && (
                <ListText>
                    Showing <span style={{ fontWeight: '700' }}>{data.length}</span>{' '}
                    {data.length === 1 ? 'result' : 'results'}
                </ListText>
            )}
            <AddGridBox width={size.width} justifyContent={justifyContent}>
                {data &&
                    data.map((item, index) => (
                        <div key={index}>
                            <Product
                                gridWidth={size.width}
                                data={item}
                                size={size}
                                left={left}
                                searchTerm={searchTerm}
                                setScroll={setScroll}
                                showStock
                            />

                            <div style={{ padding: '0', width: '100%', marginBottom: '16px', position: 'relative' }}>
                                {userEducate &&
                                    index === 0 &&
                                    clickFunction(selectedIndex, index, item) === 'Notify Me' && (
                                        <CurvedIcon
                                            style={{
                                                position: 'absolute',
                                                zIndex: '100000',
                                                left: '40%',
                                                top: '-200%',
                                            }}
                                        />
                                    )}

                                <ButtonWrapper
                                    onClick={() => handleClose(index)}
                                    className={selectedIndex === index ? 'active' : null}
                                    opacity={item?.quantityInStock < item?.bulkPrices[0]?.moq ? '0.5' : null}
                                >
                                    {clickFunction(selectedIndex, index, item)}
                                </ButtonWrapper>
                            </div>
                        </div>
                    ))}
            </AddGridBox>
        </div>
    );
};

export const ListSampleProducts = ({ data, size, left, name, id, gap, fromCategory }) => {
    return (
        <AddGridContainer width={size.width} gap={gap}>
            {data &&
                data
                    .slice(0, 5)
                    .map((item, index) => (
                        <Product key={index} data={item} size={size} left={left} fromCategory={fromCategory} />
                    ))}
            {data?.length > 5 && (
                <SeeAllBoxWrapper>
                    <ProductImage
                        src={FallbackProductImage}
                        alt="product image"
                        width={size.width}
                        height={size.height}
                    />
                    <SeeAllBox to={`/actions/merchbuy/products/${name}/${id}`}>
                        <LinkText>See All</LinkText>
                    </SeeAllBox>
                </SeeAllBoxWrapper>
            )}
        </AddGridContainer>
    );
};

ListProducts.propTypes = {
    data: any,
    size: any,
    justifyContent: string,
};
