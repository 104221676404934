import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import styled from 'styled-components';

import { RippleButton, SelectBox } from '../../../../components';
import { NewAmountInputWithLabel } from '../../../../components/forms/input/new-amount-input';
import { colors } from '../../../../styles';

import StoreSettingsModal from './modal';

const Margin = styled.div`
    margin: 0 16px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
`;

const Space = styled.div`
    width: 10px;
`;

const options = [{ label: 'Lagos' }, { label: 'Abuja' }, { label: 'Kano' }, { label: 'Ebonyi' }];

const DeliveryFeeModal = ({
    open,
    cancel,
    handleDeliveryFees,
    handleEditDeliveryFees,
    deliveries,
    selectedDelivery,
    setSelectedDelivery,
}) => {
    const [location, setLocation] = useState({ label: selectedDelivery?.location || 'Location' });
    const [price, setPrice] = useState(selectedDelivery?.price || '');

    const handleSubmit = (values) => {
        const hasLocation = deliveries.filter((delivery) => delivery?.location === location.label);
        if (selectedDelivery?.location) {
            handleEditDeliveryFees(selectedDelivery?.location, {
                location: location.label,
                price: values.price,
            });
        } else {
            if (hasLocation.length === 0) {
                handleDeliveryFees({
                    location: location.label,
                    price: values.price,
                });
            } else {
                handleEditDeliveryFees(location.label, {
                    location: location.label,
                    price: values.price,
                });
            }
        }

        setPrice('');
        setLocation({ label: 'Location' });
        setSelectedDelivery({});
        cancel();
    };
    return (
        <StoreSettingsModal title="Delivery Fee" description="Add location and fees" open={open}>
            <Margin>
                <Formik
                    initialValues={{ location, price }}
                    onSubmit={(values) => {
                        handleSubmit(values);
                    }}
                >
                    {({ values }) => (
                        <Form>
                            <SelectBox
                                value={location.label}
                                handleChange={setLocation}
                                placeholder="Location"
                                options={options}
                                bottom="16px"
                                height="100%"
                            />
                            <NewAmountInputWithLabel value={price} label={'Price'} name="price" placeholder={'Price'} />
                            <ButtonWrapper>
                                <RippleButton
                                    type="button"
                                    top={'0'}
                                    height="45px"
                                    onClick={cancel}
                                    color={colors.boldDark}
                                    backgroundColor={colors.white}
                                    border={`1px solid ${colors.gray5}`}
                                >
                                    Cancel
                                </RippleButton>
                                <Space />
                                <RippleButton
                                    type="submit"
                                    top={'0'}
                                    height="45px"
                                    disabled={!values.price || !location.label || location.label === 'Location'}
                                >
                                    OK
                                </RippleButton>
                            </ButtonWrapper>
                        </Form>
                    )}
                </Formik>
            </Margin>
        </StoreSettingsModal>
    );
};

export default DeliveryFeeModal;
