import React, { useState } from 'react';

import styled from 'styled-components';

import { RippleButton } from '../../../../components';
import { colors } from '../../../../styles';
import { ReactComponent as BagIcon } from '../assets/bag.svg';
import { ReactComponent as CardIcon } from '../assets/card.svg';
import { ReactComponent as CheckIcon } from '../assets/radioCheck.svg';
import { ReactComponent as UncheckIcon } from '../assets/radioUncheck.svg';

import StoreSettingsModal from './modal';

const Margin = styled.div`
    margin: 0 16px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
`;

const Space = styled.div`
    width: 10px;
`;

const OptionContainer = styled.div`
    height: 56px;
    border: 1px solid ${({ active }) => (active ? colors.deepBlue : colors.gray4)};
    border-radius: 4px;
    background: ${colors.white};
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-top: ${({ index }) => index === 1 && '16px'};
`;

const OptionInnerContainer = styled.div`
    display: flex;
    align-items: center;
`;

const OptionInnerContainerText = styled.div`
    font-size: 14px;
    line-height: 21px;
    color: ${colors.boldDark};
    margin-left: 14px;
`;

const Padding = styled.div`
    padding-bottom: 100px;
`;

const PaymentModeModal = ({ open, cancel, handlePaymentModes, zIndex }) => {
    const [options, setOptions] = useState([
        { title: 'Pay with Credit Card', active: false, name: 'PayOnline' },
        { title: 'Pay on Delivery', active: false, name: 'PayOnDelivery' },
    ]);

    const handleActive = (option) => {
        let newOptions = [...options];
        let selectedOptionIndex = newOptions.findIndex((data) => data.title === option);
        newOptions[selectedOptionIndex].active = !newOptions[selectedOptionIndex].active;
        setOptions(newOptions);
    };

    const handleSubmit = () => {
        const activeOptions = [];
        options.filter((data) => data.active === true && activeOptions.push([data.title, data.name]));
        handlePaymentModes(activeOptions);
        cancel();
    };

    return (
        <StoreSettingsModal title="Payment Mode" description="Select Payment mode" open={open} zIndex={zIndex}>
            <Margin>
                <Padding>
                    {options.map((data, index) => (
                        <OptionContainer
                            active={data.active}
                            index={index}
                            onClick={() => handleActive(data.title)}
                            key={index}
                        >
                            <OptionInnerContainer>
                                {index === 0 ? <CardIcon /> : <BagIcon />}
                                <OptionInnerContainerText>{data.title}</OptionInnerContainerText>
                            </OptionInnerContainer>
                            {data.active ? <CheckIcon /> : <UncheckIcon />}
                        </OptionContainer>
                    ))}
                </Padding>
                <ButtonWrapper>
                    <RippleButton
                        type="button"
                        top={'0'}
                        height="45px"
                        onClick={cancel}
                        color={colors.boldDark}
                        backgroundColor={colors.white}
                        border={`1px solid ${colors.gray4}`}
                    >
                        Cancel
                    </RippleButton>
                    <Space />
                    <RippleButton onClick={() => handleSubmit()} type="submit" top={'0'} height="45px">
                        OK
                    </RippleButton>
                </ButtonWrapper>
            </Margin>
        </StoreSettingsModal>
    );
};

export default PaymentModeModal;
