import React, { useState } from 'react';

import { Form, Formik } from 'formik';
// import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import { NewPasswordGroup, AmountInputWithLabel, TextInputWithLabel, PhoneNumberInput } from '../components';
import { Message } from '../containers/MessageContainer';
import { InputBlock } from '../containers/NewInputContainer';
// import { addCustomer } from "../redux/ducks/account/wallet/actions";
// import { formatPrice } from "../utils/currency/formatPriceWithComma";
// import { formatCurrency } from "../utils/currency/parseBalance";
// import { insertZero } from "../utils/inputs/formatPhoneNumber";
// import { getInputValues } from "../utils/inputs/getInputValues";
import { containsLettersRegExp } from '../utils/regexp/containsLettersRegExp';
import { phoneRegExp } from '../utils/regexp/phoneRegExp';

export const AddCustomerValidationSchema = Yup.object().shape({
    name: Yup.string().min(1, 'Too Short!').max(25, 'Too long!').required('Required'),
    password: Yup.string().min(1, 'Too Short!').max(10, 'Too long!').required('Required'),
    amount: Yup.string().min(1, 'Too Short!').max(10, 'Too long!').required('Required'),
    phoneNumber: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .min(10, 'Must be minimum 10 digits length')
        .test('case 1', 'Phone number is not valid!', (value) => {
            if (value && value.trim().startsWith('0') && value.trim().length !== 11) {
                return false;
            } else return true;
        })
        .test('case 2', 'Phone number is not valid!', (value) => {
            if (
                value &&
                !value.trim().startsWith('234') &&
                !value.trim().startsWith('0') &&
                value.trim().length !== 10
            ) {
                return false;
            } else return true;
        })
        .test('case 3', 'Phone number is not valid!', (value) => {
            if (value && value.trim().startsWith('234') && value.trim().length !== 13) {
                return false;
            } else return true;
        })
        .nullable()
        .required('Required'),
    code: Yup.string().matches(containsLettersRegExp, 'PIN cannot contain letters').nullable().required('Required'),
});

const FormSize = styled.div`
    margin: 20px auto;
    max-width: 375px;
`;

export default function Inputs() {
    // const dispatch = useDispatch();
    const [value] = useState('');

    return (
        <FormSize>
            <Formik
                initialValues={{
                    name: 'hjhjhjhjhj',
                    phoneNumber: '',
                    amount: '',
                    password: '',
                    code: '',
                }}
                validationSchema={AddCustomerValidationSchema}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        actions.setSubmitting(false);
                    }, 1000);
                }}
            >
                <Form>
                    <InputBlock>
                        <p>Default Text Input</p>
                        <TextInputWithLabel
                            label={'First Name'}
                            type={'text'}
                            autoComplete={'false'}
                            placeholder={'First Name'}
                            name={'name'}
                            description={'Name should go here'}
                        />

                        <Message size={'10px'} align="left">
                            OTP
                        </Message>

                        <div style={{ marginBottom: '30px' }}>
                            <NewPasswordGroup
                                count={4}
                                startIndex={1}
                                type="password"
                                name="code"
                                enteredValue={value || ''}
                            />
                        </div>

                        <p>Amount Input</p>

                        <AmountInputWithLabel
                            label={'Amount'}
                            placeholder={'Amount'}
                            name={'amount'}
                            description={'Nigger! How much you got?'}
                        />

                        <p>Phone Number Input</p>

                        <PhoneNumberInput
                            label={'Enter Phone'}
                            placeholder={'Enter Phone'}
                            name={'phoneNumber'}
                            description={"What's your phone number?"}
                        />
                    </InputBlock>
                </Form>
            </Formik>
        </FormSize>
    );
}
