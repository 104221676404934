import React from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';

import { SearchingLoader } from '../loader';

const ScrollParagraph = styled.p`
    text-align: ${({ textAlign }) => textAlign || 'center'};
    font-weight: ${({ fontWeight }) => fontWeight || '500'};
    font-size: ${({ fontSize }) => fontSize || '14px'};
    margin-top: ${({ marginTop }) => marginTop || '30px'};
`;

export const InfiniteScrollList = ({
    data,
    children,
    fetchMore,
    hasMore,
    endMessage,
    textAlign,
    fontWeight,
    fontSize,
    marginTop,
}) => {
    return (
        <InfiniteScroll
            dataLength={data.length}
            next={fetchMore}
            hasMore={hasMore}
            loader={<SearchingLoader />}
            endMessage={
                <ScrollParagraph
                    textAlign={textAlign}
                    fontWeight={fontWeight}
                    fontSize={fontSize}
                    marginTop={marginTop}
                >
                    {endMessage}
                </ScrollParagraph>
            }
            style={{ overflowX: 'hidden' }}
        >
            {children}
        </InfiniteScroll>
    );
};
