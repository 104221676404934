import React from 'react';

import { string, func, bool } from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from '../../styles';

const DisplayIcon = styled.img`
    margin-bottom: 5px;
    align-items: center;
    width: 22px;
    height: 22px;
`;

const NavIcon = styled(Link)`
    display: flex;
    flex-direction: column;
    font-size: 10px;
    width: 25%;
    height: 56px;
    justify-content: flex-end;
    align-items: center;
    padding: 8px;
    color: #718596;
    &.active {
        color: ${colors.deepBlue};
        font-weight: 700;
    }

    @media (max-width: 331px) {
        padding: 8px 4px;
    }
`;

const TextWrapper = styled.div`
    padding-top: 8px;
`;

const ActiveIndicator = styled.hr`
    border-top: 2px solid ${colors.deepBlue};
    width: 70%;
`;

export const NavigationElement = ({
    text,
    nonActiveIcon,
    activeIcon,
    onClick,
    clickState,
    link,
    currentLink,
    showNew,
}) => {
    return (
        <NavIcon
            to={link ? link : currentLink}
            onClick={onClick || null}
            activeclassname="active"
            exact={'true'}
            className={link === currentLink || clickState ? 'active' : ''}
        >
            {link === currentLink ? <ActiveIndicator /> : null}
            <DisplayIcon src={link === currentLink || clickState ? activeIcon : nonActiveIcon} alt={`${text} icon`} />
            <TextWrapper>{text}</TextWrapper>
        </NavIcon>
    );
};

NavigationElement.propTypes = {
    text: string,
    nonActiveIcon: string,
    activeIcon: string,
    onClick: func,
    clickState: bool,
    link: string,
    currentLink: string,
};
