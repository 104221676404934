import React, { Fragment, useEffect, useState, useMemo, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Loader, PageLogo, RippleButton, RippleLink } from '../../../components';
import { FeatureUndergoingFixes } from '../../../components/popup/feature-undergoing-fixes';
import { BlurBackground } from '../../../containers/BlurBackground';
import { Message } from '../../../containers/MessageContainer';
import { FlexCenteredBlock, ScreenContainer } from '../../../containers/ScreenContainer';
import { flags } from '../../../data/countries';
import { getAgencyBankingSignupState } from '../../../redux/ducks/account/agency-banking/actions';
import { getNotifications } from '../../../redux/ducks/account/notifications/actions';
import { SHOW_POPUP } from '../../../redux/ducks/account/notifications/constants';
import { getWalletBalance, getWalletActivationStatus } from '../../../redux/ducks/account/wallet/actions';
import { getAvailableBanksNoAuth } from '../../../redux/ducks/account/wallet/actions/bank-account';
import { getAgentActivationStatus } from '../../../redux/ducks/applications/agents/actions';
import { getBanners } from '../../../redux/ducks/applications/banners/actions';
import { merchbuyActions } from '../../../redux/ducks/applications/merchbuy/actions';
import { getCustomers } from '../../../redux/ducks/applications/my-shop/actions/customers';
import { getTotalDebtAmount } from '../../../redux/ducks/applications/my-shop/actions/debtors';
import { getShopInfoFromMerchapp, getShops } from '../../../redux/ducks/applications/my-shop/actions/shop';
import {
    getOrdersSummaryByStoreId,
    getStoreByUserId,
} from '../../../redux/ducks/applications/store-front/actions/store';
import { getRejectedReasons, getUserAdditionalInfo } from '../../../redux/ducks/user/actions';
import {
    getLastSurvey,
    getSurveyTypes,
    getSurveyWindow,
    getUserNpsDetails,
} from '../../../redux/ducks/user/actions/nps';
import { colors } from '../../../styles';
import History from '../../../utils/History';
import { MODULE_INTERACTION, MYSHOP_LANDING_PAGE } from '../../../utils/mix-panel/constants';
import { mixPanel } from '../../../utils/mix-panel/mixPanel';
import { toTitleCase } from '../../../utils/toTitleCase';
import { Title } from '../../actions/merchbuy/group-buy/styles';
import DesktopBackgroundLayout from '../../DesktopBackgroundLayout';
import { Header, Navigation } from '../../home';
import PendingActivation from '../pending-activation';
import { getStatusColor, getTextColor } from '../utils';

import { ReactComponent as RightArrowSvg } from './assets/new-right-arrow.svg';
import { ReactComponent as ShopSvg } from './assets/shop-new.svg';
import ShopperSvg from './assets/shopper.svg';
import Overview from './overview';
import StoreFront from './storeFront';

const DashboardWrapper = styled(ScreenContainer)`
    animation: fromRightTransform 0.5s ease;
    @keyframes fromRightTransform {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(0%);
        }
    }
`;

const Text = styled.p`
    font-size: ${({ fontSize }) => fontSize || '10px'};
    line-height: ${({ lineHeight }) => lineHeight || '15px'};
    font-weight: ${({ fontWeight }) => fontWeight || '400'};
    color: ${({ color }) => color || colors.white};
    margin: 0;
    margin-top: ${({ top }) => top || 0};
    padding: 0;
    text-align: ${({ align }) => align || null};
`;

const FlexWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: ${({ margin }) => margin || null};
    padding-bottom: 11.4px;
    border-bottom: 1px solid #edf2f7;
`;

const ShopDetailWrapper = styled(FlexWrapper)`
    column-gap: 8px;
    align-items: center;
    padding: 0 1em;
    border-bottom: none;
`;

// const TabWrapper = styled.div`
//     margin-top: 13px;
//     margin-bottom: 16px;
//     border-bottom: 1px solid #e2e9f0;
//     display: flex;
//     justify-content: space-around;
//     align-items: center;
// `;

// const TabInnerWrapper = styled.div`
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     cursor: pointer;
// `;

// const ActiveTab = styled(ActiveTabSvg)`
//     opacity: ${({ active }) => !active && '0'};
// `;

// const ActiveStoreTab = styled(ActiveStoreTabSvg)`
//     opacity: ${({ active }) => !active && '0'};
// `;

// const Tab = styled.div`
//     font-weight: ${({ active }) => active && '500'};
//     line-height: 21px;
//     color: ${({ active }) => (active ? '#071827' : '#5C6F7F')};
//     padding-bottom: 10px;
// `;

const ShoppingOptionWrapper = styled.div`
    height: calc(260px - 50px);
    width: 100%;
    background-color: white;
    position: fixed;
    bottom: 0;
    padding: 24px 0;
    border-radius: 16px 16px 0 0;
    @media (max-width: 576px) {
        height: auto;
    }
`;

const StorefrontOptionWrapper = styled.div`
    height: 50%;
    width: 100%;
    background-color: white;
    position: fixed;
    bottom: 0;
    padding: 24px 0;
    border-radius: 16px 16px 0 0;
    @media (max-width: 576px) {
        height: auto;
    }
`;

const ShoppingOptionItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 16px;
    align-items: ${({ alignItems }) => alignItems || null};
`;

const ItemsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 0;
`;

const ItemText = styled.p`
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    color: ${colors.themeTxtColor10};
`;

const ShoppingOptionTitle = styled.p`
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: ${colors.themeTxtColor10};
    margin: 0 0 18.4px;
`;

const VerificationStatus = styled.div`
    background-color: ${({ background }) => background};
    padding: 4px 8px;
    min-width: 64px;
    height: 24px;
    border-radius: 24px;
    text-align: center;
`;

const Home = ({ activeIndex, selectedIndex }) => {
    const dispatch = useDispatch();
    const avatar = useSelector((state) => state.user.avatar);
    const surveyTypes = useSelector((state) => state.user.surveyTypes);
    const NpsSurveyType = surveyTypes?.find((type) => type.title === 'NPS SURVEY');
    const store = useSelector((state) => state.applications.storeFront.store);
    const role = useSelector((state) => state.user.role);
    const userId = useSelector((state) => state.user.userId);
    const showNotifcationPopup = useSelector((state) => state.account.notifications.showPopUp || false);
    const agentState = useSelector((state) =>
        state.user.agentState ? state.user.agentState.toUpperCase() : 'PENDING',
    );
    const status = useSelector((state) => state.account.wallet.status);
    const notifications = useSelector((state) => state.account.notifications.allNotifications);
    const shops = useSelector((state) => state.applications.myShop.shops);
    const isLoading = useSelector((state) => state.applications.myShop.isLoading);
    const shop = shops[0];
    const agencyBankingState = useSelector(
        (state) =>
            (state.account.agencyBanking &&
                state.account.agencyBanking.agencyBankDetails &&
                state.account.agencyBanking.agencyBankDetails.status) ||
            '',
    );
    const [, setClassNames] = useState('');
    const [notificationPopup, setNotificationPopup] = useState(false);
    const [showPendingActivationPage, setShowPendingActivationPage] = useState(false);
    // const [tabs] = useState(['Overview', 'Online Store']);
    // const [tabs] = useState(['Overview']);
    const [activeTab] = useState(activeIndex ? activeIndex : 0);
    // const [activeTab, setActiveTab] = useState(activeIndex ? activeIndex : 0);
    const [showStoreOptions, setShowStoreOptions] = useState(false);
    const [showStoreFrontOptions, setShowStoreFrontOptions] = useState(false);
    const storeFrontList = ['Update store details', 'Customize sales settings', 'Online store settings'];

    const listOfAvailableCountries = useSelector((state) => state.applications.merchbuy.countries);
    const country = useSelector((state) => state.user.country);
    const [openFeatureFixes, setOpenFeatureFixes] = useState(false);

    useEffect(() => {
        mixPanel.track(MODULE_INTERACTION, {
            'Icon Name': 'My Shop',
            Time: new Date().toLocaleString(),
        });
        mixPanel.track(MYSHOP_LANDING_PAGE);
        window.addEventListener('scroll', handleScroll);
        return (_) => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (role === 'ROLE_AGENT' && agentState !== 'APPROVED') {
            setShowPendingActivationPage(true);
        }
    }, [agentState, role]);

    useEffect(() => {
        dispatch(getAvailableBanksNoAuth());
        dispatch(getUserNpsDetails());
        dispatch(getUserAdditionalInfo());
        dispatch(getNotifications());
        dispatch(getCustomers());
        dispatch(getWalletBalance());
        dispatch(getAgencyBankingSignupState());
        dispatch(getBanners());
        dispatch(getTotalDebtAmount());
        dispatch(getSurveyTypes());
        dispatch(getSurveyWindow());
        dispatch(merchbuyActions.getAVailableContries());
        dispatch(getStoreByUserId());
    }, [dispatch]);

    useEffect(() => {
        if (NpsSurveyType) dispatch(getLastSurvey(NpsSurveyType.id));
    }, [NpsSurveyType, dispatch]);

    const countryName = useMemo(() => {
        if (listOfAvailableCountries?.length > 0) {
            const Countryname = flags?.filter((item) => item.customAbbreviation === country);
            return listOfAvailableCountries?.filter((item) => item.name === Countryname[0]?.label)[0]?.name;
        }
    }, [country, listOfAvailableCountries]);

    useEffect(() => {
        if (countryName) {
            dispatch(merchbuyActions.getAVailableStates(countryName));
        }
    }, [countryName, dispatch]);

    useEffect(() => {
        if (store?.id) {
            dispatch(getOrdersSummaryByStoreId(store?.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, store?.id]);

    useEffect(() => {
        shop && shop.branchId && dispatch(getShopInfoFromMerchapp(shop.id, shop.branchId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useCallback(() => {
        dispatch(getShops());
    }, [dispatch]);

    useEffect(() => {
        if (status !== 'ACTIVE') {
            dispatch(getWalletActivationStatus());
        }
    }, [dispatch, status]);

    useEffect(() => {
        agencyBankingState !== 'APPROVED' && dispatch(getAgentActivationStatus());
        agentState !== 'APPROVED' && dispatch(getRejectedReasons(userId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, agentState, agencyBankingState]);

    useEffect(() => {
        if (showNotifcationPopup) {
            //default  //denied  //granted
            if ('Notification' in window && Notification.permission === 'default') {
                setNotificationPopup(!notificationPopup);
            }
            // Safari/Android webview [call native code]
            else if (window.NativeAndroid && !window.NativeAndroid.getBoolean('notification.permission')) {
                setNotificationPopup(!notificationPopup);
            } else {
                dispatch({ type: SHOW_POPUP, payload: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showNotifcationPopup]);

    const handleScroll = () => {
        const scrollY = window.scrollY;
        if (scrollY < 50) setClassNames('');
        if (scrollY > 50) setClassNames('firstStep');
        if (scrollY > 100) setClassNames('secondStep');
        if (scrollY > 120) setClassNames('thirdStep');
        if (scrollY > 140) setClassNames('fourthStep');
    };
    const tabsComponents = [<Overview />, <StoreFront selectedIndex={selectedIndex} />];

    if (isLoading) return <Loader />;
    else
        return (
            <DesktopBackgroundLayout>
                {showPendingActivationPage ? (
                    <PendingActivation pageTitle="My Shop" />
                ) : (
                    <Fragment>
                        {showStoreOptions && (
                            <BlurBackground position="fixed" onClick={() => setShowStoreOptions(false)}>
                                <ShoppingOptionWrapper>
                                    <ShoppingOptionTitle>My Store Options</ShoppingOptionTitle>
                                    <ShoppingOptionItemWrapper onClick={() => History.push('/user/shop_settings')}>
                                        <ItemsContainer>
                                            <ShopSvg />
                                            <ItemText>Shop settings</ItemText>
                                        </ItemsContainer>
                                        <ItemsContainer>
                                            <VerificationStatus background={getStatusColor(shop?.verificationStatus)}>
                                                {shop?.verificationStatus === 'UNVERIFIED' &&
                                                shop?.verificationComment ? (
                                                    <Text color={getTextColor(shop?.verificationStatus)}>
                                                        Verification Failed
                                                    </Text>
                                                ) : (
                                                    <Text color={getTextColor(shop?.verificationStatus)}>
                                                        {toTitleCase(shop?.verificationStatus?.toLowerCase() || '')}
                                                    </Text>
                                                )}
                                            </VerificationStatus>
                                            <RightArrowSvg />
                                        </ItemsContainer>
                                    </ShoppingOptionItemWrapper>
                                </ShoppingOptionWrapper>
                            </BlurBackground>
                        )}
                        {showStoreFrontOptions && (
                            <BlurBackground position="fixed" onClick={() => setShowStoreFrontOptions(false)}>
                                <StorefrontOptionWrapper>
                                    <ShoppingOptionTitle>My Store Options</ShoppingOptionTitle>
                                    {storeFrontList.map((data, index) => (
                                        <ShoppingOptionItemWrapper
                                            key={index}
                                            alignItems={'center'}
                                            onClick={() => {
                                                if (index === 2) {
                                                    History.push('/actions/storefront_settings');
                                                } else if (index === 0) {
                                                    History.push('/actions/storefront_details');
                                                } else {
                                                    History.push('/actions/storefront_delivery_options');
                                                }
                                            }}
                                        >
                                            <ItemsContainer>
                                                <ShopSvg />
                                                <ItemText>{data}</ItemText>
                                            </ItemsContainer>
                                            <RightArrowSvg />
                                        </ShoppingOptionItemWrapper>
                                    ))}
                                </StorefrontOptionWrapper>
                            </BlurBackground>
                        )}
                        {!showStoreOptions && !showStoreFrontOptions && (
                            <Header
                                avatar={avatar}
                                notifications={notifications}
                                options={true}
                                optionsClick={() => {
                                    if (shop && activeTab === 0) {
                                        setShowStoreOptions(true);
                                    } else {
                                        setShowStoreFrontOptions(true);
                                    }
                                }}
                                setOpenFeatureFixes={setOpenFeatureFixes}
                            />
                        )}
                        <DashboardWrapper padding="1em 0" color="#F9FAFC">
                            {shop && Object.keys(shop).includes('branchId') ? (
                                <Fragment>
                                    <FlexWrapper margin="0px 0 18.4px 0">
                                        <ShopDetailWrapper>
                                            <ShopSvg />
                                            <Text fontSize="14px" lineHeight="21px" fontWeight="500" color="#071827">
                                                {shop && shop.shopName}
                                            </Text>
                                        </ShopDetailWrapper>
                                        <div />
                                    </FlexWrapper>
                                    {/* <TabWrapper>
                                        {tabs.map((tab, index) => (
                                            <TabInnerWrapper onClick={() => setActiveTab(index)} key={index}>
                                                <Tab active={index === activeTab}>{tab}</Tab>
                                                {index === 0 ? (
                                                    <ActiveTab active={index === activeTab} />
                                                ) : (
                                                    <ActiveStoreTab active={index === activeTab} />
                                                )}
                                            </TabInnerWrapper>
                                        ))}
                                    </TabWrapper> */}
                                    {tabsComponents[activeTab]}
                                </Fragment>
                            ) : (
                                <FlexCenteredBlock>
                                    <PageLogo
                                        Icon={ShopperSvg}
                                        width={'108px'}
                                        height={'108px'}
                                        iconHeight={'108px'}
                                        iconWidth={'108px'}
                                        margin={'94px auto 8px'}
                                        borderRadius="0px"
                                    />
                                    <Title
                                        color="#071827"
                                        weight="700"
                                        size="14px"
                                        style={{ textTransform: 'initial' }}
                                    >
                                        No Shop Setup
                                    </Title>
                                    <Message
                                        color="#718596"
                                        weight="400"
                                        size="12px"
                                        bottom={'40px'}
                                        top={'8px'}
                                        align={'center'}
                                        padding={'0 1em'}
                                    >
                                        You’ve not setup any shop. Setup a shop to manage your inventory, track your
                                        sales and manage your orders.
                                    </Message>
                                    <RippleLink
                                        to="/actions/shop_setup"
                                        style={{
                                            width: 'calc(100% - 32px)',
                                            marginBottom: '50px',
                                        }}
                                    >
                                        <RippleButton top={'0px'}>Setup Shop</RippleButton>
                                    </RippleLink>
                                </FlexCenteredBlock>
                            )}
                        </DashboardWrapper>
                        {openFeatureFixes && <FeatureUndergoingFixes cancel={() => setOpenFeatureFixes(false)} />}
                    </Fragment>
                )}
                <Navigation />
            </DesktopBackgroundLayout>
        );
};

export default Home;
