import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ConfirmPopupDialog } from '../../components';
import { Close } from '../../containers/HeaderContainer';
import { Message } from '../../containers/MessageContainer';
import { reloginUser } from '../../redux/ducks/user/actions';
import { formatPrice } from '../../utils/currency/formatPriceWithComma';
import History from '../../utils/History';
import { getInputValues } from '../../utils/inputs/getInputValues';
import { LoginValidationSchema } from '../../views/auth/login/LoginValidationSchema';
import { RippleButton } from '../button';
import { PasswordGroup } from '../forms/password';
import { TopHeader } from '../header';

import { ReactComponent as Coin } from './assets/coin.svg';
import { ReactComponent as Person } from './assets/person.svg';
import { ReactComponent as WalletBadge } from './assets/wallet.svg';
import {
    IconCircle,
    DescArea,
    DescContentArea,
    DescText,
    ContentArea,
    AmountBox,
    Narration,
    TransactionFeeBox,
    TransactionFee,
    Space,
} from './validation.styles';

const ModalContainer = styled.div`
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 93%;
    margin: 0 auto;
    height: ${({ height }) => height || '100vh'};
    padding: 70px 0;
`;

const ValidatePasswordModal = ({
    isLoading,
    setOpen,
    next,
    nextProps = null,
    transactionData,
    type,
    title,
    setResponseData,
}) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const [altLoading, setAltLoading] = useState(false);
    const balance = useSelector((state) => state.account.wallet.balance);
    const [confirmDeletionOpen, setConfirmDeletionOpen] = useState(false);

    return (
        <ModalContainer height={title === 'Confirmation' ? 'auto' : '100vh'}>
            <ConfirmPopupDialog
                open={confirmDeletionOpen}
                title={'Cancel transaction'}
                confirmationText={'Are you sure you want to cancel this transaction?'}
                answers={[
                    {
                        variant: 'No',
                        action: () => setConfirmDeletionOpen(!confirmDeletionOpen),
                    },
                    {
                        variant: 'Yes',
                        action: () => {
                            History.push('/');
                        },
                    },
                ]}
            />

            <>
                <TopHeader title={title}>
                    <Close
                        right={'30px'}
                        onClick={() => {
                            setConfirmDeletionOpen(!confirmDeletionOpen);
                        }}
                    />
                </TopHeader>
                <DescArea>
                    <IconCircle>
                        <WalletBadge />
                    </IconCircle>
                    <DescContentArea>
                        <DescText>Sabi Wallet Balance</DescText>
                        <DescText size={'12px'}>{formatPrice(balance || 0)}</DescText>
                    </DescContentArea>
                </DescArea>
                {transactionData && (
                    <ContentArea>
                        <Space height={'10px'} />
                        <Person />
                        <Space height={'15px'} />
                        <DescText size={'14px'} color={'#56636d'} weight={'600'}>
                            {type === 'bank'
                                ? transactionData.account_name
                                : `${transactionData.lastName} ${transactionData.firstName}`}
                        </DescText>
                        <DescText size={'12px'} weight={'500'} color={'#56636d'}>
                            {type === 'bank' ? transactionData.bank : 'Sabi Wallet'}
                        </DescText>
                        <DescText size={'12px'} weight={'400'} color={'#56636d'}>
                            {type === 'bank' ? transactionData.account_number : transactionData.phoneNumber}
                        </DescText>
                        <Space height={'15px'} />
                        <Narration> {transactionData.narration}</Narration>
                        <Space height={'15px'} />
                        <AmountBox>{formatPrice(transactionData.amount)}</AmountBox>
                        <Space height={'15px'} />

                        {type === 'bank' && (
                            <TransactionFeeBox>
                                <Coin />
                                <TransactionFee>Transaction fee: ₦ 100.00</TransactionFee>
                            </TransactionFeeBox>
                        )}
                    </ContentArea>
                )}
                <Message bottom={'24px'} top={'0'}>
                    Enter your pin to make payment
                </Message>
                <Formik
                    initialValues={{
                        password: '',
                    }}
                    validationSchema={LoginValidationSchema}
                    onSubmit={(values) => {
                        setTimeout(async () => {
                            setAltLoading(true);
                            const res = await dispatch(reloginUser(values.password));
                            setAltLoading(false);
                            if (res.status !== 200) return toast.error("You've provided the wrong password");
                            setAltLoading(true);
                            transactionData.password = values.password;
                            const response = await next(nextProps);
                            setResponseData({
                                next,
                                nextProps,
                                success: response,
                                type,
                                transactionData,
                            });
                            return setOpen(false);
                        }, 400);
                        // return History.push({
                        //         pathname: '/user/wallet_transaction_success',
                        //         state: {
                        //             next,
                        //             nextProps,
                        //             success: response,
                        //             type,
                        //             transactionData,
                        //         },
                        //     });
                    }}
                >
                    {({ errors, valid, values, touched, setFieldValue }) => (
                        <Form>
                            <PasswordGroup
                                align={'center'}
                                count={6}
                                startIndex={1}
                                type="password"
                                valid={valid}
                                name="password"
                                errors={errors}
                                touched={touched}
                                disabled={isLoading}
                                enteredValue={value || null}
                                handleChange={(event) => {
                                    setValue(event.target.value);
                                    setFieldValue('password', getInputValues('password'));
                                }}
                            />
                            <RippleButton
                                type="submit"
                                disabled={values.password.length < 6 || isLoading || altLoading}
                            >
                                {title === 'Confirmation' ? 'Pay' : 'Confirm'}
                            </RippleButton>
                        </Form>
                    )}
                </Formik>
            </>
        </ModalContainer>
    );
};

export default ValidatePasswordModal;
