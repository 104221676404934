import React, { Fragment, useState, useEffect } from 'react';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { TopHeader, OptionsPopupDialog, Loader } from '../../../../components';
import { SearchInput } from '../../../../components/forms/input/search-input/SearchInput';
import { ScreenContainer, FilterAndSortSection, SortFilterCell } from '../../../../containers/ScreenContainer';
import { getComplaints } from '../../../../redux/ducks/help/actions';
import { colors } from '../../../../styles';
import { ReactComponent as Arrow } from '../../../actions/my-shop/sales/sales-list/assets/arrow.svg';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import ComplaintDetails from '../complaint-details';

import { ReactComponent as MostRecent } from './assets/mostrecent.svg';
import { ReactComponent as Oldest } from './assets/oldest.svg';
import { ReactComponent as Others } from './assets/others.svg';
import { ReactComponent as Plus } from './assets/plus.svg';
import { FilterComplaint } from './filterComplaint';
import { SortComplaint } from './sortComplaint';

const InputWrapper = styled.div`
    width: 100%;
    margin-bottom: 16px;
    margin-top: 24px;
`;

const Container = styled.div`
    padding: 0 16px;
    position: relative;
`;

export const LogButton = styled.div`
    background: #227eff;
    width: 179px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 36px;
    border-radius: 44px;
`;

export const IconCover = styled.div`
    display: flex;
    align-items: center;
    margin-right: 13px;
`;

export const Text = styled.h3`
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
`;

const PaddedContainer = styled.div`
    padding: 16px 16px;
`;

const OuterDiv = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const ComplaintBox = styled.div`
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 8px;

    &:nth-of-type(3n + 1) {
        > div {
            &:first-child {
                > div {
                    &:first-child {
                        background: #e9f2ff;
                        color: #64a4ff;
                        border: 1px solid #d1e4ff;
                    }
                }
            }
        }
    }

    &:nth-of-type(3n + 3) {
        > div {
            &:first-child {
                > div {
                    &:first-child {
                        background: #ffece0;
                        color: #ffa470;
                        border: 1px solid #ffd8c2;
                    }
                }
            }
        }
    }

    &:nth-of-type(3n + 2) {
        > div {
            &:first-child {
                > div {
                    &:first-child {
                        background: #ebecff;
                        color: #3c48fc;
                        border: 1px solid #cdd0fe;
                    }
                }
            }
        }
    }
`;

export const CategoryCover = styled.div`
    border-radius: 60px;
    width: 95px;
    height: 24px;
    text-transform: uppercase;
    display: flex;
    background: ${({ background }) => background || null};
    color: ${({ color }) => color || null};
    border: ${({ border }) => border || null};
    align-items: center;
    justify-content: center;
    margin-bottom: ${({ bottom }) => bottom || '20px'};
    font-weight: 700;
    font-size: 10px;
`;

const VerticalLine = styled.div`
    height: 41px;
    border: 1px solid #edf2f7;
    position: absolute;
    left: 47%;
`;

const DescriptionCover = styled.div`
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 14px;
    color: ${colors.themeTxtColor10};
    overflow-wrap: break-word;
`;

const DateCover = styled.div`
    font-weight: 400;
    font-size: 12px;
    color: ${colors.themeSubColor1};
`;

const ComplaintHistory = () => {
    const dispatch = useDispatch();
    const complaints = useSelector((state) => state.help.complaints);
    const isLoading = useSelector((state) => state.help.isLoading);
    const faqCategories = useSelector((state) => state.help.faqCategories);
    const faqFilter = faqCategories.filter((data) => data.status === 'SHOW_IN_HELPCENTER' && data.name !== 'xxxx');
    const [sortType, setSortType] = useState('Most Recent');
    const [filterType, setFilterType] = useState('All types');
    const [openFilterOptions, setOpenFilterOptions] = useState(false);
    const [sortOptionsOpen, setSortOptionsOpen] = useState(false);
    const [selectedComplaint, setSelectedComplaint] = useState({});
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [openComplaintDetails, setOpenComplaintDetails] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const faqMap = faqFilter.map((data) => {
        return {
            src: data.logoUrl,
            title: data.translations[0].name,
            click: () => {
                setOpenFilterOptions(!openFilterOptions);
                filterType !== data?.translations[0].name && setFilterType(data?.translations[0].name);
            },
        };
    });

    const [complaint, setComplaint] = useState(complaints);

    useEffect(() => {
        SortComplaint(sortType, complaints, setComplaint);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortType]);

    useEffect(() => {
        const filteredComplaints =
            searchValue.length === 0
                ? complaints
                : complaints.filter((data) => data?.description.toLowerCase().includes(searchValue.toLowerCase()));
        setComplaint(filteredComplaints);
    }, [complaints, searchValue]);

    useEffect(() => {
        FilterComplaint(filterType, complaints, setComplaint, faqFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterType]);

    useEffect(() => {
        dispatch(getComplaints());
    }, [dispatch]);

    if (isLoading) return <Loader />;

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                {!openComplaintDetails && <TopHeader title={'Complaint History'} backLink={'/help'} />}
                <ScreenContainer top={'0px'} padding={'0px'} color={colors.lightestGray}>
                    <FilterAndSortSection
                        top={'70px'}
                        justifyContent={'space-around'}
                        background={'white'}
                        padding={'6px 0'}
                    >
                        <SortFilterCell
                            onClick={() => setOpenFilterOptions(!openFilterOptions)}
                            style={{ color: colors.themeTxtColor10 }}
                            weight={'500'}
                        >
                            {filterType}
                            <Arrow />
                            <VerticalLine />
                        </SortFilterCell>
                        <SortFilterCell
                            onClick={() => setSortOptionsOpen(!sortOptionsOpen)}
                            style={{ color: colors.themeTxtColor10 }}
                            weight={'500'}
                        >
                            {sortType}
                            <Arrow />
                        </SortFilterCell>
                    </FilterAndSortSection>
                    <Container>
                        <InputWrapper>
                            <SearchInput
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                placeholder="Search for complaint"
                            />
                        </InputWrapper>
                        {complaint.map((data, index) => (
                            <ComplaintBox
                                key={index}
                                onClick={() => {
                                    setSelectedComplaint(data);
                                    setOpenComplaintDetails(!openComplaintDetails);
                                    setSelectedIndex(index);
                                }}
                            >
                                <PaddedContainer>
                                    <CategoryCover>{data.category}</CategoryCover>
                                    <DescriptionCover>{data.description}</DescriptionCover>
                                    <DateCover>
                                        Created on {moment(new Date(data.createdTime)).format('MMMM D, hh:mm A')}
                                    </DateCover>
                                </PaddedContainer>
                            </ComplaintBox>
                        ))}
                        <Link to="/help/log_complaint">
                            <OuterDiv>
                                <LogButton>
                                    <IconCover>
                                        <Plus />
                                    </IconCover>
                                    <Text>Log a Complaint</Text>
                                </LogButton>
                            </OuterDiv>
                        </Link>
                    </Container>
                </ScreenContainer>
                {openFilterOptions && (
                    <OptionsPopupDialog
                        open={openFilterOptions}
                        title={'Filter'}
                        cancelTextColor
                        Image
                        cancel={() => {
                            setFilterType('All types');
                            setOpenFilterOptions(!openFilterOptions);
                        }}
                        items={[
                            {
                                Icon: Others,
                                title: 'All types',
                                click: () => {
                                    setOpenFilterOptions(!openFilterOptions);
                                    filterType !== 'All types' && setFilterType('All types');
                                },
                            },
                            ...faqMap,
                        ]}
                    />
                )}
                {sortOptionsOpen && (
                    <OptionsPopupDialog
                        open={sortOptionsOpen}
                        cancelTextColor
                        title={'Sort By'}
                        cancel={() => {
                            setSortOptionsOpen(!sortOptionsOpen);
                        }}
                        items={[
                            {
                                title: 'Most Recent',
                                Icon: MostRecent,
                                click: () => {
                                    setSortOptionsOpen(!sortOptionsOpen);
                                    sortType !== 'Most Recent' && setSortType('Most Recent');
                                },
                            },
                            {
                                title: 'Oldest',
                                Icon: Oldest,
                                click: () => {
                                    setSortOptionsOpen(!sortOptionsOpen);
                                    sortType !== 'Oldest' && setSortType('Oldest');
                                },
                            },
                        ]}
                    />
                )}
            </DesktopBackgroundLayout>
            {openComplaintDetails && (
                <ComplaintDetails
                    complaint={selectedComplaint}
                    open={openComplaintDetails}
                    setOpen={setOpenComplaintDetails}
                    colorIndex={selectedIndex}
                />
            )}
        </Fragment>
    );
};

export default ComplaintHistory;
