import React from 'react';

import { bool, string, func, object } from 'prop-types';
import styled from 'styled-components';

import { Overlay, ActionBlock } from '../../../containers/OverlayContainer';
import { colors } from '../../../styles';
import { LightButton, RippleButton } from '../../button';
import { PopUp, PopUpContent, InfoMessage, OkayCancelButton, InfoHeader } from '../common';

const UnderlinedContent = styled.h6`
    font-size: 12px;
    font-weight: bolder;
    color: ${colors.blue};
    line-height: 15px;
    letter-spacing: 0.02em;
    padding: 16px;
    margin-bottom: 48px;
    text-transform: uppercase;
`;

const Header = styled(InfoHeader)`
    font-weight: 700;
    margin-bottom: 16px;
    font-size: 20px;
    color: #071827;
`;

const Message = styled(InfoMessage)`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #718596;
    padding: 0px;
`;

export const IntroductionPopup = ({
    open,
    title,
    Logo,
    message,
    underlinedContent,
    cancel,
    confirm,
    withConfirmation,
    confirmText,
    textAlign,
    children,
}) => {
    return (
        <>
            {open && <Overlay bgc={'#00000033'} onClick={cancel} zIndex={'1000'} />}
            <PopUp open={open} zIndex={'100000'}>
                <PopUpContent padding={'32px 16px 40px 16px'}>
                    {Logo && <Logo />}
                    <Header textAlign={textAlign}>{title}</Header>
                    <Message align={textAlign} bottom={underlinedContent ? null : '16px'}>
                        {message}
                    </Message>
                    {underlinedContent && <UnderlinedContent>{underlinedContent}</UnderlinedContent>}
                    {children}
                    {withConfirmation ? (
                        <ActionBlock direction={'row'} top={'32px'}>
                            <LightButton background="#fff" onClick={cancel}>
                                Cancel
                            </LightButton>
                            <RippleButton onClick={confirm}>{confirmText ? confirmText : 'Confirm'}</RippleButton>
                        </ActionBlock>
                    ) : (
                        <OkayCancelButton type="button" onClick={cancel}>
                            Okay, got it
                        </OkayCancelButton>
                    )}
                </PopUpContent>
            </PopUp>
        </>
    );
};

IntroductionPopup.propTypes = {
    open: bool,
    title: string,
    Logo: object,
    message: string,
    underlinedContent: string,
    logoSpacing: string,
    cancel: func,
    confirm: func,
    withConfirmation: bool,
    confirmText: string,
    textAlign: string,
};
