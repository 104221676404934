import React, { useState } from 'react';

import styled from 'styled-components';

import { Overlay } from '../../../../../containers/OverlayContainer';
import { FlexContainer } from '../../../../../containers/ScreenContainer';
import { colors } from '../../../../../styles';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import { PopUpContainer } from '../../styles';
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { ReactComponent as InfoIcon } from '../assets/info-icon.svg';

const Text = styled.div`
    font-weight: ${({ weight }) => weight || 'normal'};
    font-size: ${({ font }) => font || '12px'};
    line-height: 18px;
    color: ${({ color }) => color || colors.lightDark};
    margin: ${({ margin }) => margin || null};
`;

const Wrapper = styled.div`
    background: ${colors.white};
    height: 108px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    padding: 1.2em;
    margin-top: 8px;
`;

const InnerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Div = styled.div`
    display: flex;
    align-items: center;
`;

export const PaymentInfo = ({ priceInfo, deliveryDetails, showDeliveryFee, showLoader, showFailedLoader }) => {
    const [showInfoPopup, setShowInfoPopup] = useState(false);

    return (
        <Wrapper>
            <InnerWrapper>
                <Text>Items Total</Text>
                <Text font="14px" color={colors.boldDark}>
                    {priceInfo && formatPrice(priceInfo.subTotal)}
                </Text>
            </InnerWrapper>
            <InnerWrapper>
                <Div>
                    <Text margin={'0 5px 0 0'}>Delivery Fee</Text>
                    <InfoIcon onClick={() => setShowInfoPopup(true)} />
                </Div>
                <Text font="14px" color={colors.boldDark}>
                    {!showLoader && !showFailedLoader && showDeliveryFee
                        ? formatPrice(priceInfo.deliveryFee)
                        : 'Select a delivery address'}
                </Text>
            </InnerWrapper>
            <InnerWrapper>
                <Text color={colors.lightGray2} weight={500}>
                    TOTAL
                </Text>
                <Text weight="700" font="14px" color={colors.themeTextColor1}>
                    {priceInfo && formatPrice(priceInfo.totalPrice)}
                </Text>
            </InnerWrapper>

            {showInfoPopup && (
                <>
                    {showInfoPopup && (
                        <Overlay onClick={() => setShowInfoPopup(false)} bgc={'rgba(0,0,0,0.4)'} zIndex="99999" />
                    )}
                    <PopUpContainer height={'200px'}>
                        <FlexContainer bottom={'28px'}>
                            <Text color={colors.themeTextColor3} font={'16px'} weight={700}>
                                About Delivery Fee
                            </Text>
                            <CloseIcon onClick={() => setShowInfoPopup(false)} />
                        </FlexContainer>
                        <Text color={colors.boldDark} weight={400} font={'16px'}>
                            Sabi only supports free delivery in supported local governments.
                        </Text>
                    </PopUpContainer>
                </>
            )}
        </Wrapper>
    );
};
