import React, { Fragment } from 'react';

import { shape, string, bool, arrayOf, any, func } from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as CheckmarkIcon } from '../../../assets/checkmark.svg';
import { Overlay } from '../../../containers/OverlayContainer';
import { colors } from '../../../styles';
import { RippleButton, RippleLink } from '../../button';
import { SwitchTrigger } from '../../switch';
import { ConfirmButton, PopUp, PopUpContent, PopUpHeader, OptionList, CancelButton, Item } from '../common';

import { ReactComponent as ArrowIcon } from './assets/arrow.svg';

const ArrowForward = styled(ArrowIcon)`
    position: absolute;
    right: 0;
`;

const Checkmark = styled(CheckmarkIcon)`
    position: absolute;
    right: 0;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 576px) {
        width: 100%;
    }
`;

export const OptionsPopupDialog = ({
    open,
    title,
    items,
    arrows,
    Image,
    cancel,
    confirm,
    selectedStatus,
    cancelTextColor,
    headerPadding,
    popupPadding,
    color,
    size,
    contentPadding,
    itemPadding,
    itemMargin,
}) => {
    return (
        <Fragment>
            {open && <Overlay onClick={cancel} bgc={'rgba(0, 0, 0, 0.4)'} zIndex={'1000'} />}
            <PopUp open={open} zIndex={'10000'} padding={popupPadding}>
                <Container>
                    <PopUpContent radius="8px" padding={contentPadding}>
                        <PopUpHeader color={color} size={size} padding={headerPadding} margin={itemMargin}>
                            {title}
                        </PopUpHeader>
                        <OptionList overflow="none">
                            {items.map(
                                (
                                    {
                                        Icon,
                                        title,
                                        link,
                                        linkProp,
                                        click,
                                        more,
                                        src,
                                        checkStatus,
                                        switchStatus,
                                        outsideLink,
                                        selected,
                                    },
                                    index,
                                ) => (
                                    <Item key={index} padding={itemPadding} margin={itemMargin}>
                                        {link ? (
                                            <RippleLink
                                                onClick={click ? () => click() : null}
                                                to={
                                                    linkProp
                                                        ? {
                                                              pathname: link,
                                                              state: linkProp,
                                                          }
                                                        : link
                                                }
                                            >
                                                {Icon && <Icon />}
                                                {title}
                                                {arrows && <ArrowForward />}
                                            </RippleLink>
                                        ) : outsideLink ? (
                                            <a href={outsideLink}>
                                                {Icon && <Icon />}
                                                {title}
                                                {arrows && <ArrowForward />}
                                                {more && <ArrowForward />}
                                            </a>
                                        ) : (
                                            <RippleButton onClick={click ? () => click() : null}>
                                                {Icon && <Icon />}
                                                {title}
                                                {switchStatus && (
                                                    <SwitchTrigger switchStatus={switchStatus} top={'4px'} />
                                                )}
                                                {arrows && <ArrowForward />}
                                                {selected && <Checkmark />}
                                                {more && <ArrowForward />}
                                            </RippleButton>
                                        )}
                                    </Item>
                                ),
                            )}
                        </OptionList>
                    </PopUpContent>
                    {confirm ? (
                        <ConfirmButton type="submit" onClick={confirm} disabled={!selectedStatus}>
                            Apply
                        </ConfirmButton>
                    ) : (
                        <CancelButton
                            type="button"
                            onClick={cancel}
                            color={cancelTextColor ? colors.themeTextColor11 : colors.themeTextColor12}
                            radius="4px"
                            size="16px"
                            height="56px"
                            weight="500"
                            top="16px"
                            bgColor={colors.white}
                            boxShadow={true}
                        >
                            Cancel
                        </CancelButton>
                    )}
                </Container>
            </PopUp>
        </Fragment>
    );
};

OptionsPopupDialog.propTypes = {
    open: bool,
    cancel: func,
    arrows: bool,
    cancelTextColor: bool,
    title: string,
    confirm: func,
    selectedStatus: bool,
    items: arrayOf(
        shape({
            Icon: any,
            src: any,
            title: string,
            link: string,
            linkProp: any,
            selected: bool,
            click: func,
            more: bool,
        }),
    ),
};
