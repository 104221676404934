import React from 'react';

import { Button, LightButton } from '../../../../../components';
import { BuyButtons } from '../../product-details/styles';
import { FixedBottom } from '../../styles';

export const BottomButton = ({ handleAddToCart, handleBuynowClick }) => {
    return (
        <FixedBottom>
            <BuyButtons>
                <Button
                    top={'10px'}
                    type={'button'}
                    margin={'0 8px 0 0'}
                    backgroundColor="#227EFF"
                    radius="4px"
                    zIndex="2000"
                    onClick={handleAddToCart}
                >
                    Add To Cart
                </Button>

                <LightButton background="#FFFFFF" top="10px" color="#227EFF" radius="4px" onClick={handleBuynowClick}>
                    Buy Now
                </LightButton>
            </BuyButtons>
        </FixedBottom>
    );
};
