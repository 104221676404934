import React from 'react';

import { bool, func, string } from 'prop-types';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Overlay, ActionBlock } from '../../../containers/OverlayContainer';
import { colors } from '../../../styles';
import { toTitleCasePerWord } from '../../../utils/toTitleCase';
import { PopUp, PopUpContent, ShareLinkWrap, ShareLinkBlock } from '../common';

import { ReactComponent as CloseIcon } from './assets/close-icon.svg';
import { ReactComponent as CopyLinkIcon } from './assets/copy-link.svg';
import { ReactComponent as TextMessageIcon } from './assets/text-message-icon.svg';
import { ReactComponent as WhatsAppIcon } from './assets/whatsapp-icon.svg';

const ProductBlock = styled.div`
    display: flex;
    width: 100%;
    margin: 0px 0 36px;
`;

const Title = styled.div`
    color: ${colors.boldDark};
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin: 0 0 8px;
`;

const StyledCloseIcon = styled(CloseIcon)`
    margin-left: auto;
    margin-right: 9px;
`;

const IconTitle = styled.span`
    display: flex;
    color: ${colors.boldDark};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    align-items: center;
`;

const SMSShareLink = styled.a`
    background-color: transparent;
    border: none;
    padding: 0px;
    font: inherit;
    color: inherit;
    cursor: pointer;
    outline: none;
    appearance: button;
    text-transform: none;
`;

export const ProductSharePopup = ({ open, cancel, animate, url, imageURL, productName, productDescription }) => {
    const shareMessage = `Check%20out%20this%20product%20I%20found%20on%20Sabi%20\n`;

    const handleCopyLink = () => {
        navigator.clipboard
            .writeText(`${url}`)
            .then(() => {
                toast.success('Link copied to clipboard');
            })
            .catch(() => {
                toast.error('Oops, unable to copy');
            });
    };

    return (
        <PopUp open={open} padding={'0px'}>
            <Overlay onClick={cancel} bgc={'rgba(0,0,0,.5)'} />
            <PopUpContent
                width={'375px'}
                height={'233px'}
                top={'15px'}
                bottom={'0px'}
                padding={'24px 16px'}
                animate={animate}
            >
                <ProductBlock>
                    <Title>Share product link via</Title>
                    <StyledCloseIcon onClick={cancel} />
                </ProductBlock>

                <ActionBlock direction={'row'} top={'36px'} justify={'space-evenly'}>
                    <ShareLinkWrap>
                        <a href={`https://wa.me/?text=${shareMessage}${url}`} rel="noopener noreferrer" target="_blank">
                            <ShareLinkBlock background="#25D366">
                                <Helmet>
                                    <meta property="og:description" content={productDescription} />
                                    <meta
                                        id="og-title"
                                        property="og:title"
                                        content={`Sabi - ${toTitleCasePerWord(productName)}`}
                                    />
                                    <meta id="og-image" property="og:image" content={imageURL} />
                                    <meta id="og-url" property="og:url" content={url} />
                                    <link rel="canonical" href="https://sabi.am/" />
                                    <link rel="shortcut icon" href={imageURL} />
                                </Helmet>
                                <WhatsAppIcon />
                            </ShareLinkBlock>
                        </a>
                        <IconTitle>Whatsapp</IconTitle>
                    </ShareLinkWrap>

                    <ShareLinkWrap>
                        <SMSShareLink href={`sms:?&body=${shareMessage}${url}`}>
                            <ShareLinkBlock>
                                <TextMessageIcon />
                            </ShareLinkBlock>
                        </SMSShareLink>
                        <IconTitle>Text Message</IconTitle>
                    </ShareLinkWrap>

                    <ShareLinkWrap>
                        <ShareLinkBlock onClick={handleCopyLink}>
                            <CopyLinkIcon />
                        </ShareLinkBlock>
                        <IconTitle>Copy Link</IconTitle>
                    </ShareLinkWrap>
                </ActionBlock>
            </PopUpContent>
        </PopUp>
    );
};

ProductSharePopup.propTypes = {
    open: bool,
    cancel: func,
    url: string.isRequired,
};
