import { toast } from 'react-toastify';

import { currentAPI, axios } from '../../../../../config';
import { referredCommissions } from '../../../../../utils/filtering/commissions';
import { getAllCommissions, getUserLastActiveState } from '../../../user/actions';
import {
    LOADING_MERCHANTS_REFERRALS_DATA,
    LOADING_MERCHANTS_REFERRALS_COMPLETED,
    LOADING_AGENT_REFERRALS_MERCHANTS_DATA,
    LOADING_AGENT_REFERRALS_MERCHANTS_COMPLETED,
    LOADING_MERCHANTS_VERIFICATION_COMPLETED,
    LOADING_MERCHANTS_VERIFICATION_DATA,
    SAVE_AGENTS_MERCHANTS,
    MERCHANT_LAST_SEEN,
    SAVE_MERCHANTS_COMMISSIONS,
    //LOADING_MERCHANTS,
    //LOAD_MERCHANTS_ERROR,
    //LOAD_MERCHANTS_SUCCESS,
    SAVE_MERCHANT_REFERRALS,
    VERIFY_IDENTITY_DETAILS,
    SAVE_REFERRED_MERCHANT_COUNT,
} from '../constants';

export const merchantLastSeen = (id, lastSeen) => (dispatch) => {
    dispatch({ type: MERCHANT_LAST_SEEN, payload: { id, lastSeen } });
};

export const saveMerchantReferral = (referral) => (dispatch) => {
    dispatch({ type: SAVE_AGENTS_MERCHANTS, payload: referral });
};

//Used only for performance
export const getMerchantReferrals = () => async (dispatch, getState) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_AGENT_REFERRALS_MERCHANTS_DATA });
    try {
        const getAgentReferralsResponse = await axios.get(`${currentAPI}/api/users/getReferrals`);

        let referralsData =
            getAgentReferralsResponse &&
            getAgentReferralsResponse.data.filter((referral) => referral.role.name === 'ROLE_USER');

        if (referralsData.length) {
            referralsData = referralsData.map(async (referral) => {
                const lastSeen = await dispatch(getUserLastActiveState(referral.id));
                return {
                    id: referral.id,
                    firstName: referral.data.firstName,
                    lastName: referral.data.lastName,
                    createdAt: referral.createdAt,
                    state: referral.state,
                    lastSeen,
                    avatar: referral.data.image || '',
                    msisdn: referral.data.msisdn,
                    businessProfile: referral.businessData,
                };
            });

            const allData = await Promise.all(referralsData);
            dispatch({
                type: SAVE_AGENTS_MERCHANTS,
                payload: [...allData].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
            });
        }
        dispatch({ type: LOADING_AGENT_REFERRALS_MERCHANTS_COMPLETED });
    } catch (error) {
        dispatch({ type: LOADING_AGENT_REFERRALS_MERCHANTS_COMPLETED });
    }
};

export const getAgentReferralsMerchantData =
    (page = 1, size = 1500) =>
    async (dispatch) => {
        if (!navigator.onLine) return;

        dispatch({ type: LOADING_AGENT_REFERRALS_MERCHANTS_DATA });

        try {
            const getMerchantListResponse = await axios.get(
                `${currentAPI}/api/users/getMerchantsWithActivity/v2?page=${page}&size=${size}`,
            );

            if (getMerchantListResponse && getMerchantListResponse.data) {
                const { activityData, total } = getMerchantListResponse.data || {};

                dispatch({
                    type: SAVE_REFERRED_MERCHANT_COUNT,
                    payload: total,
                });

                if (activityData && activityData.length > 0) {
                    const referralsData = activityData.map((referral) => {
                        const { id, lastActiveDate, createdAt, userData, shopData } = referral || {};
                        const { email, businessPhoneNumber, streetAddress, lga } = shopData || {};
                        const { firstName, lastName } = userData || {};
                        return {
                            id,
                            lastSeen: lastActiveDate,
                            firstName,
                            lastName,
                            createdAt,
                            state: userData?.state,
                            avatar: userData?.avatar || '',
                            msisdn: userData?.msisdn,
                            //role:      referral.role.name,
                            businessProfile: {
                                lga,
                                state: shopData?.countryState,
                                businessName: shopData?.shopName,
                                streetAddress,
                                email,
                                businessPhoneNumber,
                            },
                        };
                    });
                    dispatch({
                        type: SAVE_AGENTS_MERCHANTS,
                        payload: referralsData,
                    });
                }
            }

            dispatch({ type: LOADING_AGENT_REFERRALS_MERCHANTS_COMPLETED });
        } catch (error) {
            dispatch({ type: LOADING_AGENT_REFERRALS_MERCHANTS_COMPLETED });
        }
    };

export const getMerchantReferralsData = () => async (dispatch, getState) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_MERCHANTS_REFERRALS_DATA });

    try {
        const getReferralsResponse = await axios.get(`${currentAPI}/api/users/getReferrals`);

        if (getReferralsResponse && getReferralsResponse.data.length > 0) {
            let referralsData = getReferralsResponse.data.map((referral) => {
                return {
                    id: referral.id,
                    firstName: referral.data.firstName,
                    lastName: referral.data.lastName,
                    avatar: referral.data.avatar || '',
                    msisdn: referral.data.msisdn,
                    role: referral.role.name,
                    createdAt: referral.createdAt,
                };
            });

            dispatch({
                type: SAVE_MERCHANT_REFERRALS,
                payload: [...referralsData].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
            });
        }

        dispatch({ type: LOADING_MERCHANTS_REFERRALS_COMPLETED });
    } catch (error) {
        dispatch({ type: LOADING_MERCHANTS_REFERRALS_COMPLETED });
    }
};

export const updateMerchantCommissions = () => async (dispatch, getState) => {
    const allCommissions = await dispatch(getAllCommissions());
    const merchants = getState().applications.merchants.merchantsList;

    merchants.forEach((merchant) => {
        const id = merchant.id;

        const foundCommissions = allCommissions.transactions.filter((transaction) => {
            return transaction.commissionDetails.generatedByUserID === id;
        });

        const savedMerchantCommissions =
            merchant.commissionsList && merchant.commissionsList.length > 0
                ? merchant.commissionsList.filter((transaction) => {
                      return transaction.commissionDetails.generatedByUserID === id;
                  })
                : [];

        if (foundCommissions.length > savedMerchantCommissions.length) {
            const totalCommission = referredCommissions(allCommissions, merchant).totalAmount;

            dispatch({ type: SAVE_MERCHANTS_COMMISSIONS, payload: { id, foundCommissions, totalCommission } });
        }
    });
};

export const verifyIdentity = (params) => async (dispatch) => {
    try {
        const verifyIdentityResponse = await axios.post(`${currentAPI}/api/users/verify `, JSON.stringify(params));
        if (verifyIdentityResponse.status === 200 || verifyIdentityResponse.status === 201) {
            dispatch(getIdentityDetails());
            return true;
        }
    } catch (error) {
        toast.error(error.response.data.message);
        return false;
    }
};

export const getIdentityDetails = () => async (dispatch) => {
    if (!navigator.onLine) return;

    dispatch({ type: LOADING_MERCHANTS_VERIFICATION_DATA });
    try {
        const getIdentityDetailsResponse = await axios.get(`${currentAPI}/api/users/verificationDetail `);
        if (getIdentityDetailsResponse.status === 200 || getIdentityDetailsResponse.status === 201) {
            dispatch({
                type: VERIFY_IDENTITY_DETAILS,
                payload: getIdentityDetailsResponse.data,
            });
        }
        dispatch({ type: LOADING_MERCHANTS_VERIFICATION_COMPLETED });
    } catch (error) {
        console.log(error);
        dispatch({ type: LOADING_MERCHANTS_VERIFICATION_COMPLETED });
    }
};
