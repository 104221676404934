export const getPriceFromBulkPrices = (product) => {
    const bulk = product?.bulkPrices.find((price, index) => {
        if (!product.bulkPrices[index + 1]) {
            return product?.quantity >= product?.bulkPrices[0]?.quantity || price?.moq;
        }
        return product?.quantity < product?.bulkPrices[index + 1]?.moq;
    });
    const bulkPriceIndex = product?.bulkPrices.indexOf(bulk);
    return product?.bulkPrices[bulkPriceIndex]?.price || product?.price?.retail;
};

export const getNewPriceFromBulkPrices = (product) => {
    const bulk = product?.bulkPrices.find((price, index) => {
        if (!product.bulkPrices[index + 1]) {
            return product?.quantityInStock >= price?.moq;
        }
        return product?.quantityInStock < product?.bulkPrices[index + 1]?.moq;
    });
    const bulkPriceIndex = product?.bulkPrices.indexOf(bulk);
    return product?.bulkPrices[bulkPriceIndex]?.price || product?.price?.retail;
};
