import { USER_LOGOUT } from '../../user/constants';

import { REQUEST_IN_PROGRESS, REQUEST_DONE } from './constants';

const initialState = {
    isLoading: false,
};

export default function merchantsAndAgentsReducer(state = initialState, action) {
    switch (action.type) {
        case REQUEST_IN_PROGRESS: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case REQUEST_DONE: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case USER_LOGOUT: {
            return {
                isLoading: false,
            };
        }

        default: {
            return state;
        }
    }
}
