import React, { Fragment } from 'react';

import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { TopHeader } from '../../../../components';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { colors } from '../../../../styles';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { ReactComponent as BuyIcon } from '../assets/buy.svg';
import { ReactComponent as SearchSvg } from '../assets/search.svg';

const DescriptionTitle = styled.h3`
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    text-align: left;
    color: ${colors.boldDark};
`;
const DescriptionItem = styled.h3`
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: ${colors.boldDark};
    margin: 8px 0 0;
`;
const TopIconWrapper = styled.div`
    display: flex;
    gap: 13px;
    position: absolute;
    right: 15px;
    align-items: center;
    justify-content: center;
`;

const CartCount = styled.div`
    width: 16px;
    height: 16px;
    background-color: ${colors.cartCount};
    border-radius: 26px;
    position: absolute;
    margin-top: -32px;
    margin-left: 14px;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    color: ${colors.white};
    padding-top: 2px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    color: ${colors.white};
    padding: 16px;
    align-items: ${({ align }) => align || null};
    margin: 0 -1em ${({ bottom }) => bottom || '0'};
    margin-top: ${({ top }) => top || '2px'};
    width: 375px;
    height: ${({ height }) => height || '73px'};
`;

const ProductDescription = () => {
    const history = useHistory();
    const location = useLocation();
    const cartItems = useSelector((state) => state.applications.merchbuy.shopCart);
    const product = location?.state || {};

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Product Description'} weight="400" noBorderBottom>
                    <TopIconWrapper>
                        <div onClick={() => history.push('/actions/merchbuy/search')}>
                            <SearchSvg />
                        </div>
                        <div onClick={() => history.push('/actions/merchbuy/cart')}>
                            <BuyIcon />
                            <CartCount>{cartItems ? cartItems.length : 0}</CartCount>
                        </div>
                    </TopIconWrapper>
                </TopHeader>
                <ScreenContainer color={colors.lightestGray} paddingTop="72px">
                    <Container height="auto">
                        <DescriptionTitle>Description</DescriptionTitle>
                        <DescriptionItem>
                            {' '}
                            {product.productDescription && parse(product.productDescription)}{' '}
                        </DescriptionItem>
                    </Container>
                    <Container>
                        <DescriptionTitle>Product category</DescriptionTitle>
                        <DescriptionItem>{product?.categoryName}</DescriptionItem>
                    </Container>
                    <Container>
                        <DescriptionTitle>Product Unit</DescriptionTitle>
                        <DescriptionItem> {product.unitValue} </DescriptionItem>
                    </Container>
                    <Container>
                        <DescriptionTitle>Minimum quantity</DescriptionTitle>
                        <DescriptionItem>
                            {' '}
                            {(product?.moq && product?.moq) ||
                                (product?.bulkPrices.length >= 0 && product?.bulkPrices[0]?.moq)}{' '}
                        </DescriptionItem>
                    </Container>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default ProductDescription;
