import React, { Fragment, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { TopHeader, Button, RippleLink } from '../../../../components';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { remittanceActions } from '../../../../redux/ducks/applications/remittance/actions';
import { colors } from '../../../../styles';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import { parseMonthDateYear } from '../../../../utils/date/parseDateFromStr';
import { VIEW_REMITTANCE_HISTORY } from '../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../utils/mix-panel/mixPanel';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { ReactComponent as Bulb } from '../assets/bulb.svg';
import NoRemittanceIcon from '../assets/no_remittance.svg';
import {
    FilterSection,
    RemittanceBlock,
    // RemittanceImage,
    RemittanceInfo,
    RemitDate,
    RemitOrders,
    FilterChip,
    FlexContainer,
    StatusAndDot,
    StyledHomeIcon,
    StyledRemitIcon,
    Amount,
    ColoredCircle,
    NoRemittanceWrapper,
    NoRemittanceLogo,
    EmptyStateText,
} from '../styles';

const ButtonContainer = styled(RippleLink)`
    position: fixed;
    right: calc(50vw - 180px);
    bottom: 25px;
    width: 110px;
    align-items: center;
    @media (max-width: 576px) {
        right: 16px;
    }
`;

export const MultipleRemittanceHistory = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const outstandingAmount = useSelector((state) => state.applications.remittance.outstandingAmount);
    const [activeFilter, setActiveFilter] = useState('');
    const [remitanceDetails, setRemitanceDetails] = useState([]);
    const [status, setStatus] = useState('');

    useEffect(() => {
        async function fetchPaymentHistory() {
            const response = await dispatch(remittanceActions.getPaymentHistory(activeFilter, status));
            response && setRemitanceDetails(response);
        }

        fetchPaymentHistory();
    }, [dispatch, activeFilter, status]);

    useEffect(() => {
        mixPanel.track(VIEW_REMITTANCE_HISTORY, {
            'Pending remittance on account': outstandingAmount,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setFilter = (name) => {
        setActiveFilter(name);
        if (status !== name) {
            setStatus(name);
        } else {
            setStatus('');
            setActiveFilter('');
        }
    };

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader
                    title="Remittance History"
                    size="14px"
                    withCancelIcon
                    backLink="/actions/remittance/dashboard"
                />
                <ScreenContainer height="100%" top="45px" color={colors.lightestGray}>
                    <FilterSection>
                        <FilterChip
                            height={'30px'}
                            radius={'24px'}
                            active={activeFilter === ''}
                            onClick={() => setFilter('')}
                        >
                            All
                        </FilterChip>
                        <FilterChip
                            height={'30px'}
                            radius={'24px'}
                            active={activeFilter === 'APPROVED'}
                            onClick={() => setFilter('APPROVED')}
                        >
                            Confirmed
                        </FilterChip>
                        <FilterChip
                            height={'30px'}
                            radius={'24px'}
                            active={activeFilter === 'PENDING'}
                            onClick={() => setFilter('PENDING')}
                        >
                            Pending
                        </FilterChip>
                        <FilterChip
                            height={'30px'}
                            radius={'24px'}
                            active={activeFilter === 'REJECTED'}
                            onClick={() => setFilter('REJECTED')}
                        >
                            Rejected
                        </FilterChip>
                    </FilterSection>

                    {!remitanceDetails || remitanceDetails.length === 0 ? (
                        <NoRemittanceWrapper>
                            <NoRemittanceLogo icon={NoRemittanceIcon} />
                            <EmptyStateText top="18px" size="14px" color={colors.boldDark}>
                                You don’t have any orders
                            </EmptyStateText>
                            <Bulb width={'100%'} />
                            <EmptyStateText weight="400" top="8px" lineHeight="18px">
                                When you make a purchase, you can track your items in orders.
                            </EmptyStateText>
                        </NoRemittanceWrapper>
                    ) : (
                        <>
                            {remitanceDetails &&
                                remitanceDetails.map((item, index) => (
                                    <RemittanceBlock
                                        key={index}
                                        onClick={() => {
                                            history.push({
                                                pathname: '/actions/remittance/remittance-details/',
                                                state: item,
                                            });
                                        }}
                                    >
                                        <StyledHomeIcon />

                                        <RemittanceInfo>
                                            <FlexContainer>
                                                <RemitDate>{parseMonthDateYear(item.dateRemitted)}</RemitDate>
                                                <Amount>{formatPrice(item?.totalAmountRemitted || 0)}</Amount>
                                            </FlexContainer>
                                            <FlexContainer>
                                                <RemitOrders>
                                                    {item?.numberOfOrders && `${item.numberOfOrders}`}
                                                    {item?.numberOfOrders > 1 ? ' orders ' : ' order '}
                                                </RemitOrders>
                                                <StatusAndDot>
                                                    <ColoredCircle status={(item.approvalStatus || '').toUpperCase()} />
                                                    {item?.approvalStatus && `${item.approvalStatus}`}
                                                </StatusAndDot>
                                            </FlexContainer>
                                        </RemittanceInfo>
                                    </RemittanceBlock>
                                ))}
                        </>
                    )}

                    <ButtonContainer to="/actions/remittance/remitting-multiple-orders">
                        <Button type={'button'} radius="40px">
                            <StyledRemitIcon /> Remit
                        </Button>
                    </ButtonContainer>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};
